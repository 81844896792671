export const definitions = [
  {
    "word": "о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weather",
        "examples": [
          {
            "sentence": "'''Ом''' изытет Iае",
            "translation": "'''The weather''' is so bad."
          },
          {
            "sentence": "Сэ сыкъэкIощтэп, сыда пIомэ '''ом''' изытет Iэе дэд",
            "translation": "I am not coming because '''the weather''' is so bad."
          }
        ]
      }
    ],

    "cognate": "уэ",
    "type": "noun",
    "ipa": "wa"
  },
  {
    "word": "а",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that, Calling article (o)",
        "examples": [
          {
            "sentence": "'''а''' бэнанэ",
            "translation": "'''that''' banana"
          },
          {
            "sentence": "'''а''' пшъашъэ",
            "translation": "'''that''' girl"
          },
          {
            "sentence": "'''а''' к1алэм е1уэ",
            "translation": "'''that''' boy is saying"
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "ʔaː"
  },
  {
    "word": "е",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "or",
        "examples": [
          {
            "sentence": "к1о '''е''' чъэ",
            "translation": "go '''or''' run"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ja"
  },
  {
    "word": "и",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eight",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "jə"
  },
  {
    "word": "мыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) this (right here)",
        "examples": [
          {
            "sentence": "'''мыр''' мак1о",
            "translation": "'''this''' is going"
          },
          {
            "sentence": "'''мыр''' дахэ",
            "translation": "'''this''' is pretty"
          }
        ],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron",
    "ipa": "mar"
  },
  {
    "word": "нет",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "intention",
        "examples": [
          {
            "sentence": "Сэ '''синетыгъэр''' л1ыр сыук1ыныгъэрэп",
            "translation": "'''My intention''' was not to kill the man."
          },
          {
            "sentence": "'''Нет''' си1эп шъузхэм 1аеу сатегущы1энэу",
            "translation": "I don't have any '''intention'''  to talk badly about women."
          },
          {
            "sentence": "'''Нет''' си1 нэмазэ сыш1ынэу",
            "translation": "I have '''intention''' to do prayer."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "najt"
  },
  {
    "word": "он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit someone or something physically and directly (not by throwing for example)",
        "examples": [
          {
            "sentence": "к1алэр дэпкъым '''ео'''",
            "translation": "the boy '''is hitting''' the wall. (transitive)"
          },
          {
            "sentence": "'''усэоныу''' уфая?",
            "translation": "Do you want '''me to hit you'''? (transitive)"
          },
          {
            "sentence": "К1алэм '''уемэу'''",
            "translation": "'''Don't hit''' the boy. (transitive)"
          },
          {
            "sentence": "А к1алэхэр '''къуотых'''",
            "translation": "Those boys '''will hit you'''. (transitive)"
          },
          {
            "sentence": "К1алэр '''мао'''",
            "translation": "The boy '''is hitting'''. (intransitive)"
          }
        ]
      },
      {
        "meaning": "to eat with gusto",
        "examples": [
          {
            "sentence": "нэк1о пицэ '''ткъеот'''",
            "translation": "Let's '''eat''' a pizza! (with gusto) (transitive)"
          },
          {
            "sentence": "пицэ '''укъеота'''?",
            "translation": "'''Are you going to eat''' a pizza? (with gusto) (transitive)"
          },
          {
            "sentence": "к1алэр пицэ '''ео'''",
            "translation": "The boy '''is eating''' a pizza (with gusto) (transitive)"
          }
        ]
      },
      {
        "meaning": "to play music",
        "examples": [
          {
            "sentence": "кIалэр гитарым '''къео'''",
            "translation": "the boy is playing the guitar (transitive)"
          },
          {
            "sentence": "пяноуым '''шъукъеуа'''?",
            "translation": "Do you (plural) play the piano? (transitive)"
          },
          {
            "sentence": "пшынэм '''еу'''",
            "translation": "'''Play''' the accordion. (transitive)"
          }
        ]
      },
      {
        "meaning": "to shake (milk)",
        "examples": []
      },
      {
        "meaning": "to shoot (к1эрахъомк1э еон)",
        "examples": [
          {
            "sentence": "к1алэр к1эрахъомк1э '''ео''' тгъужъым",
            "translation": "The boy '''is shooting''' the wolf with the gun. (transitive)"
          }
        ]
      },
      {
        "meaning": "to spend money thriftily",
        "examples": []
      },
      {
        "meaning": "to sting",
        "examples": []
      },
      {
        "meaning": "to weed",
        "examples": []
      },
      {
        "meaning": "to smoke",
        "examples": [
          {
            "sentence": "л1эр хьащищ '''ео'''",
            "translation": "The man '''is smoking''' hashish. (transitive)"
          }
        ]
      },
      {
        "meaning": "(mathematics) to multiply",
        "examples": [
          {
            "sentence": "щым пл1ык1э '''уеомэ''' пш1ык1ут1у къыч1экьижьыт",
            "translation": "'''If you multiply''' three times four it will make twelve. (transitive)"
          }
        ],
        "tags": [
          "mathematics"
        ]
      }
    ],
    "type": "verb",
    "ipa": "wan"
  },
  {
    "word": "ко",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thigh",
        "examples": []
      },
      {
        "meaning": "Thigh muscle",
        "examples": []
      }
    ],
    "cognate": "куэ",
    "type": "noun",
    "ipa": "kʷa"
  },
  {
    "word": "арба",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "isn't that right",
        "examples": [
          {
            "sentence": "к1алэр мэк1ошъут '''арба'''?",
            "translation": "The boy can go, '''right'''?"
          },
          {
            "sentence": "к1алэм ук1онэу къуи1уагъ '''арба'''?",
            "translation": "The boy told you to go, '''right'''?"
          },
          {
            "sentence": "'''арба''' к1алэм и1уагъэ?",
            "translation": "'''That is''' what the boy said, '''right'''?"
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "arbaː"
  },
  {
    "word": "мы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "this",
        "examples": [
          {
            "sentence": "'''мы''' бэнанэ",
            "translation": "'''this''' banana"
          },
          {
            "sentence": "'''мы''' пшъашъэ",
            "translation": "'''this''' girl"
          },
          {
            "sentence": "'''мы''' к1алэм е1уэ",
            "translation": "'''this''' boy is saying"
          },
          {
            "sentence": "'''мы''' мэгъэ",
            "translation": "'''this''' year"
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "mə"
  },
  {
    "word": "ты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "father",
        "examples": []
      }
    ],
    "cognate": "адэ",
    "type": "noun",
    "ipa": "tə"
  },
  {
    "word": "тас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cup, bowl",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "/taːs/"
  },
  {
    "word": "Китай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "region/and/country r/East Asia",
        "examples": [],
        "tags": [
          "place",
          "region",
          "and",
          "country"
        ]
      }
    ],
    "type": "name"
  },
  {
    "word": "А",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "The first letter of the Adyghe Cyrillic alphabet.",
        "examples": []
      }
    ],
    "type": "letter"
  },
  {
    "word": "абрикос",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apricot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "əbrʲɪˈkos"
  },
  {
    "word": "ананас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pineapple",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "сын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Abzakh dialect) to be burned",
        "examples": []
      }
    ],
    "synonyms": [
      "стын",
      "----"
    ],
    "type": "verb",
    "ipa": "sən"
  },
  {
    "word": "уае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bad weather, strong weather",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "waːja"
  },
  {
    "word": "бы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "den",
        "examples": []
      },
      {
        "meaning": "haunt",
        "examples": []
      },
      {
        "meaning": "nest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəː"
  },
  {
    "word": "мин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thousand",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "min"
  },
  {
    "word": "корт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "group",
        "examples": []
      },
      {
        "meaning": "party",
        "examples": []
      },
      {
        "meaning": "sentence",
        "examples": []
      },
      {
        "meaning": "troop",
        "examples": []
      },
      {
        "meaning": "flock of sheep",
        "examples": []
      }
    ],
    "cognate": "гуартэ",
    "type": "noun",
    "ipa": "kʷart"
  },
  {
    "word": "ай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Kfar Kama Shapsug dialect) yes",
        "examples": [],
        "tags": [
          "Kfar Kama Shapsug dialect"
        ]
      }
    ],
    "cognate": "ныт1э",
    "type": "noun",
    "ipa": "aːj"
  },
  {
    "word": "ал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expresses an order with a touch of threat",
        "examples": [
          {
            "sentence": "Ал, зэ щыгъэт",
            "translation": "'''Al!''', shut up for a moment."
          },
          {
            "sentence": "Ал, зэ зэ1ун",
            "translation": "'''Al!''', shut up for a moment."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "aːl"
  },
  {
    "word": "шъоущыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sugar",
        "examples": []
      }
    ],

    "cognate": "фошыгъу",
    "type": "noun",
    "ipa": "ʃʷawɕəʁʷ"
  },
  {
    "word": "адыгабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the Adyghe language",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еун\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jawən"
  },
  {
    "word": "бэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "much, a lot (of)",
        "examples": [
          {
            "sentence": "к1алэм ахъчэ '''бэу''' и1",
            "translation": "The boy has '''a lot of''' money."
          },
          {
            "sentence": "'''бэрэ''' уиунэ скъак1о",
            "translation": "I am coming to your house '''a lot'''."
          },
          {
            "sentence": "к1элэ '''бэм''' е1уэ ар",
            "translation": "'''A lot''' of the boys say that."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ba"
  },
  {
    "word": "бэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a lot, a lot of people, a lot of objects",
        "examples": [
          {
            "sentence": "'''бэм''' е1уэ ар",
            "translation": "'''A lot''' of people say that."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ba"
  },
  {
    "word": "цэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tooth",
        "examples": []
      }
    ],
    "cognate": "дзэ",
    "type": "noun",
    "ipa": "t͡sa"
  },
  {
    "word": "дэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "walnut (fruit)",
        "examples": []
      },
      {
        "meaning": "walnut (tree)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "da"
  },
  {
    "word": "пэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nose",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pa"
  },
  {
    "word": "ку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carriage",
        "examples": []
      },
      {
        "meaning": "cart",
        "examples": []
      },
      {
        "meaning": "wagon",
        "examples": []
      }
    ],
    "synonyms": [
      "кухьэ"
    ],
    "cognate": "гу",
    "type": "noun",
    "ipa": "kʷə"
  },
  {
    "word": "тэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "we",
        "examples": []
      }
    ],
    "cognate": "дэ",
    "type": "pron",
    "ipa": "ta"
  },
  {
    "word": "лавэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lava",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laːva"
  },
  {
    "word": "лимон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lemon (fruit)",
        "examples": []
      },
      {
        "meaning": "lemon tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "limɔn"
  },
  {
    "word": "лы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meat",
        "examples": []
      },
      {
        "meaning": "flesh",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɮə"
  },
  {
    "word": "урам",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "street",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wraːm"
  },
  {
    "word": "къу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "male",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷə"
  },
  {
    "word": "ор",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wave",
        "examples": []
      },
      {
        "meaning": "stormy, turbulent, violent",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "псыор",
      "уалъэ",
      "псыуалъэ",
      "хыуалъэ"
    ],
    "type": "noun",
    "ipa": "war"
  },
  {
    "word": "куп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "group",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəp"
  },
  {
    "word": "мамыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peace (peaceful)",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "стын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be burned",
        "examples": []
      },
      {
        "meaning": "to burn, to scorch",
        "examples": []
      }
    ],
    "cognate": "сын",
    "type": "verb",
    "ipa": "stən"
  },
  {
    "word": "сабын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soap",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "/saːbən/"
  },
  {
    "word": "ар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "it, that; he, she, that person (absolutive case)",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "aːr"
  },
  {
    "word": "арап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an Arabic person",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːraːp"
  },
  {
    "word": "ос",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"осы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "was"
  },
  {
    "word": "зао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "war",
        "examples": []
      },
      {
        "meaning": "battle",
        "examples": []
      },
      {
        "meaning": "fight",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zaːwa"
  },
  {
    "word": "зэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wait, a moment",
        "examples": [
          {
            "sentence": "'''Зэ''' къэуцу",
            "translation": "'''Wait''' stop '''for a moment'''."
          },
          {
            "sentence": "'''Зэ''' мары унэм сыкъек1ы",
            "translation": "'''A moment''' here I am getting out of the house."
          },
          {
            "sentence": "'''Зэ''' тыдэ шъок1о?",
            "translation": "'''Wait''' where you guys are going?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "za"
  },
  {
    "word": "гэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "testicle",
        "examples": []
      }
    ],
    "synonyms": [
      "к1энк1э"
    ],
    "type": "noun",
    "ipa": "ɣa"
  },
  {
    "word": "пыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "elephant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pəl"
  },
  {
    "word": "гущы1алъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dictionary",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəɕəʔaːɬ"
  },
  {
    "word": "еры",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "place",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jarə"
  },
  {
    "word": "ау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there, within that area (far away from here)",
        "examples": [
          {
            "sentence": "'''ау''' мак1о",
            "translation": "he is going '''there'''"
          },
          {
            "sentence": "'''ау''' гъэт1ылъ",
            "translation": "put it '''there'''"
          },
          {
            "sentence": "'''ау''' ущымыщх",
            "translation": "don't eat '''there'''"
          }
        ]
      },
      {
        "meaning": "but",
        "examples": [
          {
            "sentence": "Сыгу къео, '''ау''' сэ нэмыкI Iофхэр сиI.",
            "translation": "I am sorry, '''but''' I already have plans."
          },
          {
            "sentence": "сиунэ къак1у, '''ау''' цуакъэ плъыжьыхэр къэхьых",
            "translation": "Come to my house, '''but''' bring the red shoes."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːw"
  },
  {
    "word": "узы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "disease, illness",
        "examples": [
          {
            "sentence": "'''Уз''' горэм уегъэгумэк1а?",
            "translation": "Do you have any pain from a '''disease'''?"
          }
        ]
      }
    ],
    "cognate": "уз",
    "type": "noun",
    "ipa": "wəzə"
  },
  {
    "word": "уз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"узы\"",
        "examples": []
      }
    ],
    "synonyms": [
      "узы"
    ],
    "type": "noun",
    "ipa": "wəz"
  },
  {
    "word": "кой",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "village",
        "examples": []
      }
    ],
    "synonyms": [
      "къуаджэ"
    ],
    "cognate": "къуажэ",
    "type": "noun",
    "ipa": "kʷaj"
  },
  {
    "word": "еон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawan"
  },
  {
    "word": "тын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pay",
        "examples": [
          {
            "sentence": "к1алэр '''матэ'''",
            "translation": "The boy is '''paying'''."
          },
          {
            "sentence": "к1алэм ахъшэ '''итын''' фай",
            "translation": "The boy must '''pay''' money."
          },
          {
            "sentence": "к1алэм ахъшэ '''итэгъ'''",
            "translation": "The boy '''payed''' money."
          },
          {
            "sentence": "'''утын''' фай",
            "translation": "'''You''' must '''pay'''"
          },
          {
            "sentence": "к1алэм ахъшэ '''еты'''",
            "translation": "The boy is '''paying''' money."
          },
          {
            "sentence": "ахъшэ '''ты'''",
            "translation": "'''Pay''' money."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tən"
  },
  {
    "word": "адыгэбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the Adyghe language",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːdəɣabza"
  },
  {
    "word": "лэблэбый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chickpeas",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "lablabəj"
  },
  {
    "word": "натрыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corn",
        "examples": []
      }
    ],
    "synonyms": [
      "натыф",
      "хьамц1ый"
    ],
    "cognate": "нартыху",
    "type": "noun",
    "ipa": "naːtrəf"
  },
  {
    "word": "пыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пындж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pəd͡ʒ"
  },
  {
    "word": "пцэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fish",
        "examples": []
      }
    ],
    "synonyms": [
      "пцэ"
    ],
    "cognate": "бдзэжьей",
    "type": "noun",
    "ipa": "pt͡saʐəja"
  },
  {
    "word": "шъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "head",
        "examples": []
      }
    ],

    "cognate": "щхьэ",
    "type": "noun",
    "ipa": "ʂħa"
  },
  {
    "word": "шъхьэц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hair (on head)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħat͡s"
  },
  {
    "word": "нат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forehead",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːtʼa"
  },
  {
    "word": "нэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eye",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "/na/"
  },
  {
    "word": "напцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eyebrow",
        "examples": []
      }
    ],

    "cognate": "набдзэ",
    "type": "noun",
    "ipa": "naːpt͡sa"
  },
  {
    "word": "нэбзыц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eyelash",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nabzət͡s"
  },
  {
    "word": "нап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eyelid",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "нэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naps"
  },
  {
    "word": "псы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "water",
        "examples": []
      },
      {
        "meaning": "liquid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psə"
  },
  {
    "word": "маш1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fire",
        "examples": []
      }
    ],
    "synonyms": [
      "маф1э"
    ],
    "type": "noun",
    "ipa": "maːʃʷʼa"
  },
  {
    "word": "пэгъуанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nostril",
        "examples": []
      },
      {
        "meaning": "nosehole",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paʁʷaːna"
  },
  {
    "word": "жэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mouth",
        "examples": []
      },
      {
        "meaning": "skateboard",
        "examples": []
      }
    ],
    "cognate": "жьэ",
    "type": "noun",
    "ipa": "ʒa"
  },
  {
    "word": "1упэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lip",
        "examples": []
      },
      {
        "meaning": "front",
        "examples": [
          {
            "sentence": "Унэ '''1упэм''' урам щы1эп",
            "translation": "There is no street '''in front''' of the house."
          },
          {
            "sentence": "К1алэм '''и1упэ''' пшъашъэр шыт",
            "translation": "The girl is standing '''in front''' of the boy."
          },
          {
            "sentence": "'''Си1упэк1э''' къиуцу",
            "translation": "Stand still '''in front of me'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəpa"
  },
  {
    "word": "бзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "language",
        "examples": []
      },
      {
        "meaning": "Bow string",
        "examples": []
      },
      {
        "meaning": "Hypotenuse (in a triangle)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bza"
  },
  {
    "word": "бзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tongue",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaɡʷ"
  },
  {
    "word": "1упс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saliva",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəp"
  },
  {
    "word": "напэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek, face",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːpa"
  },
  {
    "word": "нэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek",
        "examples": []
      }
    ],
    "synonyms": [
      "нэпашъо"
    ],
    "cognate": "нэк1у",
    "type": "noun",
    "ipa": "naɡʷ"
  },
  {
    "word": "нахэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "visage, countenance, face (appearance)",
        "examples": [
          {
            "sentence": "К1алэм '''инахэ''' щыдэхьащхых",
            "translation": "They are laughing at '''the appearance''' of the boy."
          },
          {
            "sentence": "'''Уинахэ''' сыкъхэот сыкъэбгъэгуабжымэ",
            "translation": "I gonna hit '''your face''' if you make me angry."
          },
          {
            "sentence": "К1алэм '''инахэк1э''' къэсэш1эжьы",
            "translation": "I recognize the boy '''by his face'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naːxa"
  },
  {
    "word": "тхьэк1умэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħakʷʼma"
  },
  {
    "word": "дэбзыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sideburn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dabzəqʷ"
  },
  {
    "word": "жэк1апэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chin",
        "examples": []
      }
    ],
    "synonyms": [
      "жэк1ьапэ",
      "жэк1эпэпкъ"
    ],
    "type": "noun",
    "ipa": "ʒatʃʼaːpa"
  },
  {
    "word": "дый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back of the neck",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэб"
    ],
    "type": "noun",
    "ipa": "dəj"
  },
  {
    "word": "тамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoulder",
        "examples": []
      },
      {
        "meaning": "wing",
        "examples": []
      }
    ],

    "cognate": "дамэ",
    "type": "noun",
    "ipa": "taːma"
  },
  {
    "word": "блыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "armpit",
        "examples": []
      },
      {
        "meaning": "angle",
        "examples": []
      },
      {
        "meaning": "corner",
        "examples": []
      },
      {
        "meaning": "solitude",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləɡʷ"
  },
  {
    "word": "1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arm",
        "examples": []
      },
      {
        "meaning": "hand",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔa"
  },
  {
    "word": "пшъэтыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "front of the neck",
        "examples": []
      },
      {
        "meaning": "neck",
        "examples": []
      }
    ],

    "cognate": "пщэдыкъ",
    "type": "noun",
    "ipa": "pʂatəq"
  },
  {
    "word": "1этэгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "high",
        "examples": [
          {
            "sentence": "чъыг 1этгъэм си1эгуау пынагъ",
            "translation": "My ball stuck on the '''high''' tree."
          },
          {
            "sentence": "мы къушъхьэр '''зы1этэгъ'''",
            "translation": "This mountain '''is so high'''."
          },
          {
            "sentence": "'''узэрэ1этгъэ'''",
            "translation": "'''You are so hight'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔataʁ"
  },
  {
    "word": "1элгьанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forearm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔalɡʲaːana"
  },
  {
    "word": "1эшъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "joint of hand",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaʂħa"
  },
  {
    "word": "1эгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrist",
        "examples": []
      },
      {
        "meaning": "yard, courtyard",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaɡʷ"
  },
  {
    "word": "1эдакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "The hand's heel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔadaːqa"
  },
  {
    "word": "1апэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hand",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːpa"
  },
  {
    "word": "1эпхъабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "finger",
        "examples": []
      }
    ],
    "synonyms": [
      "1эхъуамбэ"
    ],
    "cognate": "1эпхъуамбэ",
    "type": "noun",
    "ipa": "ʔapχaːba"
  },
  {
    "word": "1эбжъанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fingernail",
        "examples": []
      }
    ],

    "cognate": "1эбжьанэ",
    "type": "noun",
    "ipa": "ʔabʐaːna"
  },
  {
    "word": "1эпэпс1ий",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fingertip",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпапц1э"
    ],
    "type": "noun",
    "ipa": "ʔapapsʼəj"
  },
  {
    "word": "бгъэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chest",
        "examples": []
      },
      {
        "meaning": "breastbone, sternum",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaɡʷ"
  },
  {
    "word": "тхы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back (body part)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txə"
  },
  {
    "word": "ныбэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stomach",
        "examples": []
      },
      {
        "meaning": "belly",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəba"
  },
  {
    "word": "бгы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "waist",
        "examples": []
      },
      {
        "meaning": "high ground",
        "examples": []
      },
      {
        "meaning": "plateau",
        "examples": []
      },
      {
        "meaning": "hill",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣə"
  },
  {
    "word": "ныбыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "navel",
        "examples": []
      }
    ],

    "cognate": "бынжэ",
    "type": "noun",
    "ipa": "nəbəd͡ʒ"
  },
  {
    "word": "шъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skin",
        "examples": []
      },
      {
        "meaning": "color",
        "examples": []
      }
    ],

    "cognate": "фэ",
    "type": "noun",
    "ipa": "ʃʷa"
  },
  {
    "word": "шъутыпашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "butt cheek",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷətəpaːʃʷa"
  },
  {
    "word": "лъэгуанджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "knee",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэгуаджэ"
    ],
    "cognate": "лъэгуажьэ",
    "type": "noun",
    "ipa": "ɬaɡʷaːnd͡ʒa"
  },
  {
    "word": "лъакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leg",
        "examples": []
      }
    ],

    "cognate": "лъэ",
    "type": "noun",
    "ipa": "ɬaːqʷa"
  },
  {
    "word": "лъашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ankle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaːʂħa"
  },
  {
    "word": "лъапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaːpa"
  },
  {
    "word": "лъэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sole (bottom of the foot or of the shoe)",
        "examples": []
      },
      {
        "meaning": "floor",
        "examples": []
      },
      {
        "meaning": "vale, dale",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эй"
    ],
    "type": "noun",
    "ipa": "ɬaɡʷ"
  },
  {
    "word": "лъэдакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heel (leg)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬadaːqa"
  },
  {
    "word": "лъэпхъабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toe (leg)",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэхъуамбэ"
    ],
    "cognate": "лъэпхъуамбэ",
    "type": "noun",
    "ipa": "ɬapχaːba"
  },
  {
    "word": "лъэбжъанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toenail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬapʐaːna"
  },
  {
    "word": "лъэпэпс1ий",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэпапц1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэпапц1э"
    ],
    "type": "noun",
    "ipa": "ɬapapsʼəj"
  },
  {
    "word": "манэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "penis (body part)",
        "examples": []
      },
      {
        "meaning": "dick",
        "examples": []
      }
    ],

    "cognate": "хьэмашэ",
    "type": "noun",
    "ipa": "maːna"
  },
  {
    "word": "чый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "throat",
        "examples": []
      }
    ],
    "synonyms": [
      "кьый"
    ],
    "type": "noun",
    "ipa": "t͡ʃəj"
  },
  {
    "word": "ныпс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "intestine",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эт1эй"
    ],
    "type": "noun",
    "ipa": "nəpsˤʼa"
  },
  {
    "word": "к1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tail",
        "examples": [
          {
            "sentence": "Чэтыум '''ик1э''' утэмыпк1",
            "translation": "Don't jump/step on the cat's [[tail]]."
          }
        ]
      },
      {
        "meaning": "seed",
        "examples": [
          {
            "sentence": "Ят1эм '''к1эхэр''' халъхьэх",
            "translation": "Put '''the seeds''' in the dirt."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) (vulgar slang) dick (penis)",
        "examples": [],
        "tags": [
          "Kfar Kama dialect",
          "vulgar",
          "slang"
        ]
      },
      {
        "meaning": "new, fresh",
        "examples": [
          {
            "sentence": "К1эла'''к1э'''",
            "translation": "A '''new''' boy."
          },
          {
            "sentence": "Анэ '''к1эр''' къэхь",
            "translation": "Bring the '''new''' table."
          }
        ]
      }
    ],
    "cognate": "щ1э (new)",
    "type": "noun",
    "ipa": "t͡ʃʼa"
  },
  {
    "word": "нэк1эт1эр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rheum (eye)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʲʼatˤʼar"
  },
  {
    "word": "кхъужъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pear",
        "examples": []
      }
    ],
    "synonyms": [
      "къужъы"
    ],
    "cognate": "кхъужьы",
    "type": "noun",
    "ipa": "q͡χʷəʐə"
  },
  {
    "word": "бжьыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "onion",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənə"
  },
  {
    "word": "бжьыныф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garlic",
        "examples": []
      }
    ],

    "cognate": "бжьыныху",
    "type": "noun",
    "ipa": "bʑənəf"
  },
  {
    "word": "к1энк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "egg",
        "examples": []
      },
      {
        "meaning": "testicle",
        "examples": []
      }
    ],
    "synonyms": [
      "к1ьак1ьэ"
    ],
    "cognate": "гэ",
    "type": "noun",
    "ipa": "t͡ʃʼant͡ʃʼa"
  },
  {
    "word": "мые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apple",
        "examples": []
      }
    ],
    "synonyms": [
      "мы1эрыс"
    ],
    "type": "noun",
    "ipa": "məja"
  },
  {
    "word": "нашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "watermelon",
        "examples": []
      }
    ],
    "synonyms": [
      "хъырбыдз"
    ],
    "cognate": "нащэ",
    "type": "noun",
    "ipa": "naːʃa"
  },
  {
    "word": "тыгъэгъэзак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sunflower seeds (food)",
        "examples": []
      }
    ],
    "synonyms": [
      "тыгъэзак1э"
    ],
    "type": "noun",
    "ipa": "taʁzaːkʲʼa"
  },
  {
    "word": "тыгъэзак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sunflower seeds (food)",
        "examples": []
      }
    ],
    "synonyms": [
      "тыгъэгъэзак1э"
    ],
    "type": "noun",
    "ipa": "taʁzaːkʲʼa"
  },
  {
    "word": "нэшак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "watermelon seed (food)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʃaːkʲʼa"
  },
  {
    "word": "къэбык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pumpkin seed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabkʲʼa"
  },
  {
    "word": "хьэлэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bread",
        "examples": []
      }
    ],

    "cognate": "щ1акхъуэ",
    "type": "noun",
    "ipa": "ħalaw"
  },
  {
    "word": "нэшэбэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cucumber",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʃabaɡʷ"
  },
  {
    "word": "шъоу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "honey",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷaw"
  },
  {
    "word": "санэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grape",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːna"
  },
  {
    "word": "щыбжьый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pepper",
        "examples": []
      }
    ],

    "cognate": "шыбжий",
    "type": "noun",
    "ipa": "ɕəbʑəj"
  },
  {
    "word": "к1этыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chicken meat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼatəl"
  },
  {
    "word": "чэмыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beef",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaməl"
  },
  {
    "word": "щыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "salt",
        "examples": []
      }
    ],

    "cognate": "шыгъу",
    "type": "noun",
    "ipa": "ɕəʁʷ"
  },
  {
    "word": "къэбаскъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cabbage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabaːsqa"
  },
  {
    "word": "щэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "milk",
        "examples": []
      },
      {
        "meaning": "bullet",
        "examples": []
      }
    ],
    "cognate": "шэ",
    "type": "noun",
    "ipa": "ɕa"
  },
  {
    "word": "кхъуайэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheese",
        "examples": []
      }
    ],
    "synonyms": [
      "къуае",
      "хъуае"
    ],
    "cognate": "кхъуей",
    "type": "noun",
    "ipa": "q͡χʷaːja"
  },
  {
    "word": "мэтэкхъуайэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian cheese",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mataq͡χʷaːja"
  },
  {
    "word": "к1этал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼataːl"
  },
  {
    "word": "хьантхъупсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soup",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːntχʷəpsə"
  },
  {
    "word": "хьэлжъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "haliva (a Circassian food, fried triangular pasties with mainly Circassian cheese or potato)",
        "examples": []
      }
    ],
    "synonyms": [
      "псх1альыва",
      "хьалвэ"
    ],
    "type": "noun",
    "ipa": "ħalʐʷa"
  },
  {
    "word": "мэтазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mataza: a kind of dumpling eaten in Circassia.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mataːza"
  },
  {
    "word": "тгъэргъаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sunflower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʁarʁaːz"
  },
  {
    "word": "хьэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mushroom",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʔʷə"
  },
  {
    "word": "1эрс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dish",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaps"
  },
  {
    "word": "шъуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cup, glass",
        "examples": [
          {
            "sentence": "[[тIамыпс",
            "translation": "ТIамыпс]] шъуалъэ къахьба"
          },
          {
            "sentence": "[[щаибжъэ",
            "translation": "Щаибжъэ]] шъуалъэ къахьба"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂʷaːɬa"
  },
  {
    "word": "чэрэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cherry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaraz"
  },
  {
    "word": "бахъсымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a Circassian alcoholic drink (Circassian boza)",
        "examples": []
      }
    ],
    "cognate": "махъсымэ",
    "type": "noun",
    "ipa": "baːχsəma"
  },
  {
    "word": "хьалжъогъуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "round haleva",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːlʐʷaʁʷaːn"
  },
  {
    "word": "шэкэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sugar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃakʲar"
  },
  {
    "word": "шы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horse",
        "examples": []
      },
      {
        "meaning": "brother",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃə"
  },
  {
    "word": "хьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dog",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэжъ",
      "штэ",
      "сыда"
    ],
    "type": "noun",
    "ipa": "ħa"
  },
  {
    "word": "мэлы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sheep",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maɮə"
  },
  {
    "word": "тыпсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ram",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "təpsa"
  },
  {
    "word": "пчэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "goat",
        "examples": []
      }
    ],

    "cognate": "бжэн",
    "type": "noun",
    "ipa": "pt͡ʃanə"
  },
  {
    "word": "чэмы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cow",
        "examples": []
      }
    ],

    "cognate": "жэм",
    "type": "noun",
    "ipa": "t͡ʃamə"
  },
  {
    "word": "шк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "calf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃkʼa"
  },
  {
    "word": "атакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rooster, cock",
        "examples": []
      },
      {
        "meaning": "(slang) gay person; a homosexual",
        "examples": [],
        "tags": [
          "slang"
        ]
      }
    ],

    "cognate": "адакъэ",
    "type": "noun",
    "ipa": "ʔataːqa"
  },
  {
    "word": "псычэты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "duck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəkʲʼatə"
  },
  {
    "word": "къаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "goose (Bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːz"
  },
  {
    "word": "бгъэк1ыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁakʲʼəħ"
  },
  {
    "word": "бзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bird",
        "examples": []
      }
    ],
    "synonyms": [
      "къолэбзыу"
    ],
    "cognate": "джэдкъаз",
    "type": "noun",
    "ipa": "bzəw"
  },
  {
    "word": "бгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eagle",
        "examples": []
      },
      {
        "meaning": "chest",
        "examples": []
      },
      {
        "meaning": "sterile mare",
        "examples": []
      }
    ],
    "synonyms": [
      "бгъэжъ"
    ],
    "type": "noun",
    "ipa": "bʁa"
  },
  {
    "word": "тхьак1умэк1эхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьак1умк1ыхь\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħakʷʼmakʲʼaħə"
  },
  {
    "word": "к1эпхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squirrel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼapχ"
  },
  {
    "word": "тыгъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wolf",
        "examples": []
      }
    ],

    "cognate": "дыгъужь",
    "type": "noun",
    "ipa": "təʁʷəʐ"
  },
  {
    "word": "баджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fox",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːd͡ʒa"
  },
  {
    "word": "цыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mouse",
        "examples": []
      }
    ],

    "cognate": "дзыгъуэ",
    "type": "noun",
    "ipa": "t͡səʁʷa"
  },
  {
    "word": "аслъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lion",
        "examples": []
      }
    ],
    "synonyms": [
      "мэзыхьэ"
    ],
    "type": "noun",
    "ipa": "ʔasɬaːn"
  },
  {
    "word": "мышъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bear",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эхэк1ын"
    ],
    "cognate": "мыщэ",
    "type": "noun",
    "ipa": "məʂa"
  },
  {
    "word": "махъушэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"махъчэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχʷəʃa"
  },
  {
    "word": "блэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bla"
  },
  {
    "word": "кукумау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "owl",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəkʷmaːw"
  },
  {
    "word": "бык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "calf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəkʷʼə"
  },
  {
    "word": "ныбгъэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "calf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəbʁaʂʷ"
  },
  {
    "word": "гу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heart",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷə"
  },
  {
    "word": "к1эхэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мышъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мышъэ",
      "мышъыпкъэ"
    ],
    "type": "noun",
    "ipa": "t͡ʃaxat͡ʃən"
  },
  {
    "word": "бжьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑa"
  },
  {
    "word": "махъшэбзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ostrich",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχt͡ʃabzəw"
  },
  {
    "word": "хьэнчэмы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turtle",
        "examples": []
      }
    ],
    "synonyms": [
      "хьадэпчэмы1у"
    ],
    "cognate": "шылъэгу",
    "type": "noun",
    "ipa": "ħant͡ʃaməʔʷ"
  },
  {
    "word": "1эргъуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"аргъой\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔarʁʷəj"
  },
  {
    "word": "тхьэчэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turkey",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħakʲʼat"
  },
  {
    "word": "бадзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "housefly (fly)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːd͡za"
  },
  {
    "word": "шъуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cockroach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷaːpa"
  },
  {
    "word": "быгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ox",
        "examples": []
      },
      {
        "meaning": "bull",
        "examples": []
      },
      {
        "meaning": "steer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəʁʷ"
  },
  {
    "word": "бжъабгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːbʁʷə"
  },
  {
    "word": "бжъау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːw"
  },
  {
    "word": "гъэлъэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lamb",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaɬaχʷ"
  },
  {
    "word": "пыжъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hedgehog",
        "examples": []
      }
    ],

    "cognate": "ц1ыжьбанэ",
    "type": "noun",
    "ipa": "pəʐə"
  },
  {
    "word": "мышъыпкъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мышъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эхэк1ын"
    ],
    "type": "noun",
    "ipa": "məʂəpqa"
  },
  {
    "word": "къолэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "raven",
        "examples": []
      },
      {
        "meaning": "crow",
        "examples": []
      }
    ],
    "synonyms": [
      "цунды"
    ],
    "cognate": "къуагъыщхъуэ",
    "type": "noun",
    "ipa": "qʷalaʐ"
  },
  {
    "word": "шъхьэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flowerpot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaχʷ"
  },
  {
    "word": "къазмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pickaxe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːzma"
  },
  {
    "word": "хьанцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shovel (wooden shovel)",
        "examples": []
      }
    ],
    "synonyms": [
      "къазгъыр"
    ],
    "type": "noun",
    "ipa": "ħant͡sa"
  },
  {
    "word": "ш1уанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hoe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼaːna"
  },
  {
    "word": "уатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hammer",
        "examples": []
      }
    ],
    "cognate": "уадэ",
    "type": "noun",
    "ipa": "waːta"
  },
  {
    "word": "майтэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "axe",
        "examples": []
      }
    ],
    "synonyms": [
      "лэфэужы",
      "отыч",
      "ощы",
      "обзэгъу"
    ],
    "cognate": "джыдэ",
    "type": "noun",
    "ipa": "maːjta"
  },
  {
    "word": "щэмэгь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэмэдж\"",
        "examples": []
      }
    ],

    "cognate": "шэмэдж",
    "type": "noun",
    "ipa": "ɕamaɡʲʼ"
  },
  {
    "word": "гъупчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sickle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷəpt͡ɕ"
  },
  {
    "word": "1унк1ыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "key",
        "examples": []
      }
    ],
    "synonyms": [
      "1ук1ьыбзэ"
    ],
    "type": "noun",
    "ipa": "ʔʷənt͡ʃʼəbza"
  },
  {
    "word": "лъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬə"
  },
  {
    "word": "багьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэджы\"",
        "examples": []
      }
    ],
    "synonyms": [
      "бэджы"
    ],
    "type": "noun",
    "ipa": "baːɡʲa"
  },
  {
    "word": "бэджэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fox's child, cub fox",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡ʒaʐəja"
  },
  {
    "word": "джае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "catfish",
        "examples": []
      },
      {
        "meaning": "whale",
        "examples": []
      }
    ],

    "cognate": "джей",
    "type": "noun",
    "ipa": "d͡ʒaːja"
  },
  {
    "word": "махъчэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "camel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχt͡ʃa"
  },
  {
    "word": "плъыжьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "red",
        "examples": [
          {
            "sentence": "Лъы '''плъыжь'''",
            "translation": "'''a red''' blood."
          },
          {
            "sentence": "Чэрэзыр '''плъыжьы'''",
            "translation": "The cherry is '''red'''."
          },
          {
            "sentence": "Чэрэз '''плъыжьыр''' дахэ",
            "translation": "The '''red''' cherry is pretty."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "pɬəʑə"
  },
  {
    "word": "гъожьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yellow",
        "examples": [
          {
            "sentence": "Тыгъэр '''гъожьы'''",
            "translation": "The sun is '''yellow'''."
          },
          {
            "sentence": "Тыгъэ '''гъожьыр''' дахэ",
            "translation": "The '''yellow''' sun is pretty."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʁʷaʑə"
  },
  {
    "word": "къупышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bone",
        "examples": []
      }
    ],

    "cognate": "къупщхьэ",
    "type": "noun",
    "ipa": "qʷəpəʂħa"
  },
  {
    "word": "лъынтфэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A blood vessel: an artery or vein.",
        "examples": []
      },
      {
        "meaning": "sinew",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəntfa"
  },
  {
    "word": "жак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beard",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaːtʃʼa"
  },
  {
    "word": "нэк1эбыдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rheum (dried mucus in or around the eyes)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʲʼabəd͡z"
  },
  {
    "word": "быдзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breast",
        "examples": []
      },
      {
        "meaning": "teat",
        "examples": []
      },
      {
        "meaning": "bosom",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəd͡zə"
  },
  {
    "word": "быдзып",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tit",
        "examples": []
      },
      {
        "meaning": "teat",
        "examples": []
      },
      {
        "meaning": "nipple",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəd͡zəp"
  },
  {
    "word": "кхъанзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ant",
        "examples": []
      }
    ],
    "synonyms": [
      "хъанзэгу",
      "къамзэгу"
    ],
    "type": "noun",
    "ipa": "q͡χaːnzaɡʷ"
  },
  {
    "word": "жьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air, atmosphere",
        "examples": []
      },
      {
        "meaning": "breath",
        "examples": []
      },
      {
        "meaning": "puke, vomit",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑə"
  },
  {
    "word": "ошъуапщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cloud",
        "examples": [
          {
            "sentence": "'''Ошъуапщэр''' ежьашъо",
            "translation": "'''The cloud''' is grey."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "waʂʷaːpɕa"
  },
  {
    "word": "л1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "death",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬˤʼaːʁa"
  },
  {
    "word": "ахэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "those, those things, they (absolutive case)",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "aːxar"
  },
  {
    "word": "бжым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒəm"
  },
  {
    "word": "бзылъфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "woman",
        "examples": []
      }
    ],

    "cognate": "бзылъхугъэ",
    "type": "noun",
    "ipa": "bzəɬfəʁa"
  },
  {
    "word": "кхъулъфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "man",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "q͡χʷəɬfəʁa"
  },
  {
    "word": "кхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "male",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "q͡χʷə"
  },
  {
    "word": "бзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "female",
        "examples": []
      },
      {
        "meaning": "pyramid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzə"
  },
  {
    "word": "блыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Monday",
        "examples": []
      }
    ],

    "cognate": "блыщхьэ",
    "type": "noun",
    "ipa": "bləpa"
  },
  {
    "word": "былым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cattle, livestock",
        "examples": []
      },
      {
        "meaning": "property, fortune, value things",
        "examples": []
      },
      {
        "meaning": "wealth",
        "examples": []
      },
      {
        "meaning": "treasure",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bələm"
  },
  {
    "word": "бын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family",
        "examples": []
      },
      {
        "meaning": "child",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bən"
  },
  {
    "word": "бэдзэогъумаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "July",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zawaʁʷəmaːz"
  },
  {
    "word": "мазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "month",
        "examples": []
      },
      {
        "meaning": "moon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːza"
  },
  {
    "word": "мафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "day",
        "examples": []
      }
    ],

    "cognate": "махуэ",
    "type": "noun",
    "ipa": "maːfa"
  },
  {
    "word": "нэпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"непэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "непэ"
    ],
    "cognate": "нобэ",
    "type": "adv",
    "ipa": "napa"
  },
  {
    "word": "сэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saber",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "sa"
  },
  {
    "word": "гъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "year",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁa"
  },
  {
    "word": "гъэрэк1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Last year",
        "examples": [
          {
            "sentence": "'''гъэрэк1о''' тк1уагъ йуропа",
            "translation": "'''last year''', we went to Europe."
          },
          {
            "sentence": "'''гъэрэк1о''' пш1ы синыбжьэгъ",
            "translation": "'''last year''' I was ten years old."
          },
          {
            "sentence": "'''гъэрэк1о''' томэр къэк1уагъ",
            "translation": "'''last year''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʁarakʷʼa"
  },
  {
    "word": "илъэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "year",
        "examples": []
      }
    ],
    "cognate": "гъэ",
    "type": "noun",
    "ipa": "jəɬas"
  },
  {
    "word": "тхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "god",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħa"
  },
  {
    "word": "тхьэмафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "week",
        "examples": []
      }
    ],

    "cognate": "тхьэмахуэ",
    "type": "noun",
    "ipa": "tħamaːfa"
  },
  {
    "word": "бэрэскэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Wednesday (day)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baraskaʐəj"
  },
  {
    "word": "бэрэскэф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Friday",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baraskaf"
  },
  {
    "word": "тхьаумаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Sunday",
        "examples": []
      }
    ],

    "cognate": "тхьэмахуэ",
    "type": "noun",
    "ipa": "tħawmaːf"
  },
  {
    "word": "гъубдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Tuesday",
        "examples": []
      }
    ],

    "cognate": "гъубж",
    "type": "noun",
    "ipa": "ʁʷəbd͡ʒ"
  },
  {
    "word": "мэфэку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Thursday",
        "examples": []
      }
    ],

    "cognate": "мэхуэку",
    "type": "noun",
    "ipa": "mafakʷ"
  },
  {
    "word": "мэфэзакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Saturday",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mafazaːqʷ"
  },
  {
    "word": "гужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anger",
        "examples": []
      },
      {
        "meaning": "hatred",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəʐ"
  },
  {
    "word": "гъунгьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъунджэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷənɡʲa"
  },
  {
    "word": "1оныгъомаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "September",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷanəʁʷamaːz"
  },
  {
    "word": "жъуагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boiled, plowed",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐʷaːʁa"
  },
  {
    "word": "къолай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "easy",
        "examples": []
      }
    ],
    "synonyms": [
      "1эш1эх"
    ],
    "cognate": "тынш",
    "type": "adj",
    "ipa": "qʷalaːj"
  },
  {
    "word": "апшъышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oh my god, oh my gosh",
        "examples": [
          {
            "sentence": "'''апшъышъ'''! к1алэр псауа?}}",
            "translation": ""
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʔaːpʂəʂ"
  },
  {
    "word": "пчыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "evening",
        "examples": []
      }
    ],

    "cognate": "пщыхьэщхьэ",
    "type": "noun",
    "ipa": "pt͡ʃəħa"
  },
  {
    "word": "нэчэпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tonight",
        "examples": [
          {
            "sentence": "'''нэчэпэ''' тк1от йуропа",
            "translation": "'''Tonight''', we will go to Europe."
          },
          {
            "sentence": "'''нэчэпэ''' томэр къэк1уагъ",
            "translation": "'''Tonight''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "nat͡ʃapa"
  },
  {
    "word": "нэпчыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "today's evening",
        "examples": [
          {
            "sentence": "'''нэпчыхьэ''' тк1от йуропа",
            "translation": "'''In the evening''', we will go to Europe."
          },
          {
            "sentence": "'''нэпчыхьэ''' томэр къэк1уагъ",
            "translation": "'''In the evening''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "napt͡ʃəħa"
  },
  {
    "word": "пчэдыжьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "morning",
        "examples": []
      }
    ],

    "cognate": "пщэдджыжь",
    "type": "noun",
    "ipa": "pt͡ʃadəʑə"
  },
  {
    "word": "нэпчэдыжьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "this morning, today's morning",
        "examples": [
          {
            "sentence": "'''нэпчэдыжьы''' тк1от йуропа",
            "translation": "'''In today's morning''', we will go to Europe."
          },
          {
            "sentence": "'''нэпчэдыжьы''' томэр къэк1уагъ",
            "translation": "'''In today's morning''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "napt͡ʃadəʑə"
  },
  {
    "word": "щэгьагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noon",
        "examples": [
          {
            "sentence": "'''щэгьагъом''' тк1от йуропа",
            "translation": "'''In the noon''', we will go to Europe."
          },
          {
            "sentence": "'''щэгьагъом''' томэр къэк1уагъ",
            "translation": "'''In the noon''' Tom came."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕaɡʲaːʁʷa"
  },
  {
    "word": "нэущы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"неущы\"",
        "examples": []
      }
    ],
    "synonyms": [
      "неущы"
    ],
    "type": "adv",
    "ipa": "nawɕə"
  },
  {
    "word": "тгъосэнэхьпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тыгъоснахьыпэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "tʁʷasanaħpa"
  },
  {
    "word": "неущымык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "day after tomorrow",
        "examples": [
          {
            "sentence": "'''Неущымык1''' тык1от йуропа",
            "translation": "'''In the day after tomorrow''' we will go to Europe."
          },
          {
            "sentence": "'''Неущымык1''' пш1ы синыбжьыт",
            "translation": "'''In the day after tomorrow''' I will be ten years old."
          }
        ]
      }
    ],
    "synonyms": [
      "нэущымыщк1ь"
    ],
    "type": "adv",
    "ipa": "najwɕəmət͡ʃʼ"
  },
  {
    "word": "гъэрэк1опагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "The year before last year, two years ago",
        "examples": [
          {
            "sentence": "'''гъэрэк1опагъэ''' тк1уагъ йуропа",
            "translation": "'''2 years ago''', we went to Europe."
          },
          {
            "sentence": "'''гъэрэк1опагъэ''' пш1ы синыбжьэгъ",
            "translation": "'''2 years ago''' I was ten years old."
          },
          {
            "sentence": "'''гъэрэк1опагъэ''' томэр къэк1уагъ",
            "translation": "'''2 years ago''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʁarakʷʼapaːʁa"
  },
  {
    "word": "илъэсипш1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "decade, 10 years",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jəɬasipʂʼə"
  },
  {
    "word": "ахъчэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ахъщэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эны",
      "парэ"
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃa"
  },
  {
    "word": "пк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump",
        "examples": [
          {
            "sentence": "к1алэр '''мапк1э'''",
            "translation": "The boy '''is jumping'''."
          },
          {
            "sentence": "к1алэр унэм '''ипк1агъ'''",
            "translation": "The boy '''jumped into''' the house."
          },
          {
            "sentence": "к1алэр гъуанэм '''ипк1эмэ''' зыгорэ зыриш1эжьыт",
            "translation": "If the boy '''will jump into''' the hole he will do something to his self."
          },
          {
            "sentence": "'''сэпк1э'''",
            "translation": "I am '''jumping'''."
          }
        ]
      },
      {
        "meaning": "to be sticky; to be gluey",
        "examples": [
          {
            "sentence": "Пцантхьэр '''мапк1э'''",
            "translation": "The glue '''is sticky'''."
          }
        ]
      }
    ],

    "cognate": "лъэн",
    "type": "verb",
    "ipa": "pt͡ʃʼan"
  },
  {
    "word": "тхьауегъэпсэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thank you",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "tħaːwajʁapsaw"
  },
  {
    "word": "опсэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thanks (a short thank you)",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "wapsaw"
  },
  {
    "word": "дэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "daʁʷə"
  },
  {
    "word": "дэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bad",
        "examples": [
          {
            "sentence": "Псыр '''дэеу''' хъугъэ",
            "translation": "The water became '''bad'''."
          },
          {
            "sentence": "К1алэм ыш1эхэрэ '''дэих'''",
            "translation": "The boy is doing '''bad''' things."
          },
          {
            "sentence": "Гъогу анахь '''дэир''' хэпхыгъэ",
            "translation": "You chose '''the worst''' (the most bad) road."
          },
          {
            "sentence": "Сиянэ ишхын афэдизэу '''дэеп'''",
            "translation": "My mother's food '''isn't''' that '''bad'''."
          }
        ]
      },
      {
        "meaning": "ill",
        "examples": [
          {
            "sentence": "Л1ыр '''дэеу''' хъугъэ",
            "translation": "The man became '''ill'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "dajə"
  },
  {
    "word": "дахэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pretty, beautiful",
        "examples": [
          {
            "sentence": "к1алэр '''дахэ'''",
            "translation": "The boy '''is pretty'''."
          },
          {
            "sentence": "к1элэ '''дахэр'''",
            "translation": "'''The pretty''' boy."
          },
          {
            "sentence": "уик1элэ '''дахэр''' сиунэ къак1о",
            "translation": "Your '''pretty''' boy is coming to my house."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "daːxa"
  },
  {
    "word": "пц1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ап1э\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "pt͡sˤʼaːpˤʼa"
  },
  {
    "word": "хъупхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nice, kind",
        "examples": [
          {
            "sentence": "К1алэ '''хъупхъ''' о",
            "translation": "You are a '''kind''' boy."
          },
          {
            "sentence": "Томым ик1алэ '''хъупхъэ'''",
            "translation": "Tom's boy is '''kind'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "χʷəpχa"
  },
  {
    "word": "гупыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cruel",
        "examples": []
      },
      {
        "meaning": "merciless",
        "examples": []
      }
    ],
    "synonyms": [
      "гук1эгъунчъ",
      "жъалым"
    ],
    "type": "adj",
    "ipa": "ɡʷəpət"
  },
  {
    "word": "дыгьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дыджы\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "dəɡʲə"
  },
  {
    "word": "щыгъущ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "salty",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɕəʁʷəɕ"
  },
  {
    "word": "хафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sour",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэпц1агъ",
      "зэхэш1о1ухьан"
    ],
    "type": "adj",
    "ipa": "xaːfa"
  },
  {
    "word": "хатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garden",
        "examples": []
      }
    ],

    "cognate": "хадэ",
    "type": "noun",
    "ipa": "xaːta"
  },
  {
    "word": "фабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hot, warm",
        "examples": [
          {
            "sentence": "Мы шхыныр '''фабащэ'''",
            "translation": "This food is '''too hot'''."
          },
          {
            "sentence": "Непэ мэфэ '''фабэ'''",
            "translation": "Today '''is a hot''' day."
          }
        ]
      },
      {
        "meaning": "heat",
        "examples": [
          {
            "sentence": "'''Фабэм''' сеук1ы",
            "translation": "'''The heat''' is killing me."
          }
        ]
      }
    ],

    "cognate": "хуабэ",
    "type": "adj",
    "ipa": "faːba"
  },
  {
    "word": "плъыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fever",
        "examples": []
      },
      {
        "meaning": "high fever",
        "examples": []
      },
      {
        "meaning": "hot",
        "examples": []
      },
      {
        "meaning": "hotness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬʲər"
  },
  {
    "word": "делэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fool, foolish, stupid, crazy",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "dajla"
  },
  {
    "word": "ары",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that is it",
        "examples": []
      },
      {
        "meaning": "indeed",
        "examples": []
      },
      {
        "meaning": "yes",
        "examples": []
      }
    ],
    "cognate": "аращ",
    "type": "adv",
    "ipa": "/ʔaːrəː/"
  },
  {
    "word": "хьау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "no",
        "examples": []
      }
    ],
    "synonyms": [
      "хьа1"
    ],
    "type": "adv",
    "ipa": "ħaw"
  },
  {
    "word": "хасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "club",
        "examples": []
      },
      {
        "meaning": "council",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xaːsa"
  },
  {
    "word": "хьак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guest, visitor",
        "examples": []
      }
    ],
    "synonyms": [
      "хьа1ьэ"
    ],
    "cognate": "хьащ1э",
    "type": "noun",
    "ipa": "ħaːt͡ʃa"
  },
  {
    "word": "хэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drink directly from a container (such as a bottle or gallon), rather than from a glass",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʂʷan"
  },
  {
    "word": "к1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go",
        "examples": [
          {
            "sentence": "к1алэр уиунэ '''мак1о'''",
            "translation": "The boy '''is going''' to your house."
          },
          {
            "sentence": "к1алэр '''мак1о'''",
            "translation": "The boy '''is going'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "kʷʼan"
  },
  {
    "word": "гъургьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъунджэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷərɡʲa"
  },
  {
    "word": "нэгъургьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eyeglasses",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʁʷərɡʲa"
  },
  {
    "word": "данэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "silk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːna"
  },
  {
    "word": "джэгъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hated fellow",
        "examples": []
      },
      {
        "meaning": "evil wisher, illwisher, malevolent/spiteful person",
        "examples": []
      }
    ],

    "cognate": "жэгъуэгъу",
    "type": "noun",
    "ipa": "d͡ʒaʁʷəɡʷ"
  },
  {
    "word": "дышъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gold",
        "examples": []
      }
    ],

    "cognate": "дыщэ",
    "type": "noun",
    "ipa": "dəʂa"
  },
  {
    "word": "жьыбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wind",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəbʁa"
  },
  {
    "word": "дзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "military",
        "examples": []
      },
      {
        "meaning": "army",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡za"
  },
  {
    "word": "дзэл1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soldier",
        "examples": []
      }
    ],
    "synonyms": [
      "дзак1о",
      "дзэк1ол1ы"
    ],
    "type": "noun",
    "ipa": "d͡zaɬʼə"
  },
  {
    "word": "л1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "husband",
        "examples": []
      },
      {
        "meaning": "man",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼə"
  },
  {
    "word": "шъуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wife",
        "examples": []
      },
      {
        "meaning": "woman",
        "examples": []
      }
    ],
    "synonyms": [
      "суыз"
    ],
    "cognate": "фыз",
    "type": "noun",
    "ipa": "ʂʷəzə"
  },
  {
    "word": "ины",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "large, big",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jənə"
  },
  {
    "word": "пшъашъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "girl",
        "examples": [
          {
            "sentence": "'''пшъашъэр''' мак1о",
            "translation": "'''The girl''' is going."
          },
          {
            "sentence": "'''пшъэшъэ''' дахэ",
            "translation": "The beautiful '''girl'''."
          },
          {
            "sentence": "'''уипшъашъэ''' дэк1ощт",
            "translation": "'''Your girl''' will go alongside him - '''Your girl''' is going to merry him."
          }
        ]
      },
      {
        "meaning": "young woman, young lady",
        "examples": []
      }
    ],

    "cognate": "пщащэ",
    "type": "noun",
    "ipa": "pʂaːʂa"
  },
  {
    "word": "к1алэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boy",
        "examples": [
          {
            "sentence": "'''К1алэр''' мак1о",
            "translation": "'''The boy''' is going."
          },
          {
            "sentence": "'''К1элэ''' дахэ",
            "translation": "The beautiful '''young man'''."
          },
          {
            "sentence": "'''Уик1алэ''' къишэт",
            "translation": "'''Your boy''' will bring him/her - '''Your young man''' is going to merry her."
          }
        ]
      },
      {
        "meaning": "young man",
        "examples": []
      }
    ],
    "cognate": "щ1алэ",
    "type": "noun",
    "ipa": "t͡ʃʼaːla"
  },
  {
    "word": "къалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "city",
        "examples": []
      },
      {
        "meaning": "town",
        "examples": []
      },
      {
        "meaning": "(Shapsug dialect) wall",
        "examples": [],
        "tags": [
          "Shapsug dialect"
        ]
      },
      {
        "meaning": "(Abzakh dialect) yard",
        "examples": [],
        "tags": [
          "Abzakh dialect"
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaːɮa"
  },
  {
    "word": "къошъобэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "paddle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʂʷabaɕ"
  },
  {
    "word": "бэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rod, staff, cane, stick",
        "examples": [
          {
            "sentence": "'''Л1ыжъым''' бэщ ыIыгъ",
            "translation": "The old man carries a '''cane'''."
          }
        ]
      }
    ],
    "synonyms": [
      "бэщкъорэгъ"
    ],
    "type": "noun",
    "ipa": "baɕ"
  },
  {
    "word": "хьашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rubber",
        "examples": []
      },
      {
        "meaning": "tire",
        "examples": []
      }
    ],
    "synonyms": [
      "хъашъо"
    ],
    "cognate": "техак1уэ",
    "type": "noun",
    "ipa": "ħaːʂʷa"
  },
  {
    "word": "къыцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qət͡sa"
  },
  {
    "word": "лъэмыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bridge",
        "examples": []
      },
      {
        "meaning": "arch",
        "examples": []
      }
    ],

    "cognate": "лъэмыж",
    "type": "noun",
    "ipa": "ɬaməd͡ʒ"
  },
  {
    "word": "лъэпкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kin (race, kind)",
        "examples": []
      },
      {
        "meaning": "ethnicity",
        "examples": []
      },
      {
        "meaning": "ethnic group",
        "examples": []
      },
      {
        "meaning": "tribe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬapq"
  },
  {
    "word": "л1эныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "death",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬˤʼanəʁ"
  },
  {
    "word": "щык1эпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "semen",
        "examples": []
      }
    ],
    "synonyms": [
      "лъфащэ",
      "хъопсащэ"
    ],
    "type": "noun",
    "ipa": "ɕəkʲʼaps"
  },
  {
    "word": "мыжъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rock",
        "examples": []
      },
      {
        "meaning": "stone",
        "examples": []
      }
    ],
    "synonyms": [
      "мызуэ"
    ],
    "cognate": "мывэ",
    "type": "noun",
    "ipa": "məʐʷa"
  },
  {
    "word": "мылы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ice",
        "examples": []
      }
    ],

    "cognate": "мыл",
    "type": "noun",
    "ipa": "məɮə"
  },
  {
    "word": "осы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wasə"
  },
  {
    "word": "мэзай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "February",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazaːj"
  },
  {
    "word": "мэзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazə"
  },
  {
    "word": "мэкъуогъумаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "June",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maqʷəwaʁʷəmaːz"
  },
  {
    "word": "мэлылъфэгъумаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "April",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maləɬfaʁʷəmaːz"
  },
  {
    "word": "ныбжьык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "young",
        "examples": []
      },
      {
        "meaning": "young person",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "nəbʑət͡ʃʼa"
  },
  {
    "word": "нэк1убгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "page",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʷʼbʁʷ"
  },
  {
    "word": "орэд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "song",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "warad"
  },
  {
    "word": "отыч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ax",
        "examples": []
      }
    ],
    "synonyms": [
      "ощы",
      "обзэгъу",
      "майтэ"
    ],
    "cognate": "джыдэ",
    "type": "noun",
    "ipa": "watət͡ʃ"
  },
  {
    "word": "ош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weather",
        "examples": []
      }
    ],
    "synonyms": [
      "уэф1"
    ],
    "type": "noun",
    "ipa": "waʃʷʼə"
  },
  {
    "word": "ощхы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rain",
        "examples": [
          {
            "sentence": "'''Ощх''' къещхы",
            "translation": "It is raining '''rain'''."
          },
          {
            "sentence": "'''Ощхым''' ухэмыхь",
            "translation": "Don't enter '''the rain'''."
          }
        ]
      }
    ],

    "cognate": "уэшх",
    "type": "noun",
    "ipa": "waɕxə"
  },
  {
    "word": "псыгъэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "island",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʁaχʷən"
  },
  {
    "word": "псыхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "river",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəχʷa"
  },
  {
    "word": "псэушъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "animal",
        "examples": []
      }
    ],

    "cognate": "псэущхьэ",
    "type": "noun",
    "ipa": "psawʂħ"
  },
  {
    "word": "пшахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sand",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʃaχʷa"
  },
  {
    "word": "пшъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʂa"
  },
  {
    "word": "тыжьыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "silver",
        "examples": []
      }
    ],

    "cognate": "дыжьын",
    "type": "noun",
    "ipa": "təʑən"
  },
  {
    "word": "тхылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "book",
        "examples": [
          {
            "sentence": "'''тхылъым''' седжэ",
            "translation": "I am reading '''the book'''."
          },
          {
            "sentence": "'''тхылъ''' плъыжьыр къэсфэхь",
            "translation": "Bring me the red '''book'''."
          },
          {
            "sentence": "зэ1ушъух Инджылыбзэ '''тхылъыхэр''' теджэнфэщк1э",
            "translation": "Open your '''books''' that we could study."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "txəɬ"
  },
  {
    "word": "тхылъып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "paper",
        "examples": []
      },
      {
        "meaning": "tissue for cleaning one's nose",
        "examples": []
      },
      {
        "meaning": "kleenex",
        "examples": []
      }
    ],

    "cognate": "тхылъымп1э",
    "type": "noun",
    "ipa": "txəɬəpʼa"
  },
  {
    "word": "тыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sun",
        "examples": []
      }
    ],

    "cognate": "дыгъэ",
    "type": "noun",
    "ipa": "təʁa"
  },
  {
    "word": "тыгъэгъэзэмаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "December",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "təʁaʁazamaːz"
  },
  {
    "word": "уахътэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time",
        "examples": []
      }
    ],
    "synonyms": [
      "чэзыу",
      "игъу"
    ],
    "type": "noun",
    "ipa": "waːχta"
  },
  {
    "word": "уашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sky",
        "examples": []
      }
    ],

    "cognate": "уафэ",
    "type": "noun",
    "ipa": "waːʃʷa"
  },
  {
    "word": "унагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family",
        "examples": []
      }
    ],

    "cognate": "унагъуэ",
    "type": "noun",
    "ipa": "wnaːʁʷa"
  },
  {
    "word": "унэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "house",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wəna"
  },
  {
    "word": "упч1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "question",
        "examples": [
          {
            "sentence": "К1элэегъаджэм бэрэ '''упч1э''' ешъота?",
            "translation": "Do you often ask the teacher '''questions'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "упщ1э"
    ],
    "type": "noun",
    "ipa": "wəpt͡ʃʼa"
  },
  {
    "word": "фыжьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "white",
        "examples": [
          {
            "sentence": "Осыр '''фыжьы'''",
            "translation": "The snow is '''white'''."
          },
          {
            "sentence": "Ос '''фыжьыр''' дахэ",
            "translation": "The '''white''' snow is pretty."
          }
        ]
      }
    ],
    "cognate": "хужьы",
    "type": "adj",
    "ipa": "fəʑə"
  },
  {
    "word": "ш1уц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "black",
        "examples": [
          {
            "sentence": "хы '''ш1уц1э'''",
            "translation": "'''black''' sea."
          },
          {
            "sentence": "сиджанэ '''ш1уц1э'''",
            "translation": "my shirt is '''black'''."
          },
          {
            "sentence": "'''ш1уц1эк1э''' дэпкъыр лэ",
            "translation": "Color the wall '''in black'''."
          }
        ]
      }
    ],
    "cognate": "ф1ыц1э",
    "type": "adj",
    "ipa": "ʃʷʼət͡sʼa"
  },
  {
    "word": "гъожьыплъыжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "orange",
        "examples": []
      }
    ],
    "synonyms": [
      "гъоплъышъо",
      "гъожьышэплъ"
    ],
    "type": "adj",
    "ipa": "ʁʷaʑpɬəʑə"
  },
  {
    "word": "плъыжьгьэф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pink",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьэмбылышъу",
      "шэплъы"
    ],
    "type": "adj",
    "ipa": "pɬəʑɡʲaf"
  },
  {
    "word": "гьэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэфы\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʲafə"
  },
  {
    "word": "къолэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "multi-coloured",
        "examples": []
      },
      {
        "meaning": "motley",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷalan"
  },
  {
    "word": "къолэнсэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very colorful",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷalansalan"
  },
  {
    "word": "къэхьуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къахьэуашъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1агъо",
      "хьаплъы"
    ],
    "type": "adj",
    "ipa": "qaħwaːʂʷa"
  },
  {
    "word": "бгъэк1эхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁakʲʼaħ"
  },
  {
    "word": "тхьэмбылышъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pink",
        "examples": []
      }
    ],
    "cognate": "тхьэмбылыфэ",
    "type": "adj",
    "ipa": "tħambələʂʷ"
  },
  {
    "word": "хабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "culture",
        "examples": []
      },
      {
        "meaning": "custom",
        "examples": []
      },
      {
        "meaning": "habit",
        "examples": []
      },
      {
        "meaning": "the law (in the broadest sense), the culture's laws",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xaːbza"
  },
  {
    "word": "хыгъэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "island",
        "examples": []
      }
    ],
    "synonyms": [
      "псыгу"
    ],
    "type": "noun",
    "ipa": "xəʁaχʷən"
  },
  {
    "word": "хьалыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэлэгъу\"",
        "examples": []
      }
    ],

    "cognate": "щ1акхъуэ",
    "type": "noun",
    "ipa": "ħaːləʁʷ"
  },
  {
    "word": "хьаблыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэблыу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːbləw"
  },
  {
    "word": "хьамп1ырашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "butterfly, moth",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэнт1эрэп1ый",
      "хьэт1рэп1ый"
    ],
    "cognate": "хьэндырабгъуэ",
    "type": "noun"
  },
  {
    "word": "хьарзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swan",
        "examples": []
      }
    ],

    "cognate": "къыу",
    "type": "noun",
    "ipa": "ħaːpza"
  },
  {
    "word": "хэу1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to incise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xawʔan"
  },
  {
    "word": "ц1ыфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "person, human",
        "examples": []
      }
    ],

    "cognate": "ц1ыху",
    "type": "noun",
    "ipa": "t͡sʼəfə"
  },
  {
    "word": "ц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "name",
        "examples": []
      }
    ],
    "synonyms": [
      "с1э"
    ],
    "type": "noun",
    "ipa": "t͡sʼa"
  },
  {
    "word": "чатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sword",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaːta"
  },
  {
    "word": "чъыгы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tree",
        "examples": []
      }
    ],

    "cognate": "жыг",
    "type": "noun",
    "ipa": "t͡ɕəɣə"
  },
  {
    "word": "чъыгай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oak (tree)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʂəɣaːj"
  },
  {
    "word": "чъэпыогъумаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "October",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂapəwaʁʷəmaːz"
  },
  {
    "word": "чэщы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "night",
        "examples": []
      }
    ],

    "cognate": "жэщ",
    "type": "noun",
    "ipa": "t͡ʃaɕə"
  },
  {
    "word": "шъхьангъупчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "window",
        "examples": []
      }
    ],

    "cognate": "щхьэгъубжэ",
    "type": "noun",
    "ipa": "ʂħaːnʁʷəptʂa"
  },
  {
    "word": "шыпхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sister",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəpχʷ"
  },
  {
    "word": "шэк1огъумаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "November",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃakʷʼaʁʷəmaːz"
  },
  {
    "word": "ш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good",
        "examples": [
          {
            "sentence": "Уатхъэ '''ш1оп''' иджы",
            "translation": "It is '''not a good''' time now."
          }
        ]
      },
      {
        "meaning": "well",
        "examples": [
          {
            "sentence": "Щаер '''ш1у''' сыш1ыгъа?",
            "translation": "Did I made the tea '''well'''?"
          }
        ]
      },
      {
        "meaning": "liver",
        "examples": []
      }
    ],
    "cognate": "ф1ы",
    "type": "adv",
    "ipa": "ʃʷʼə"
  },
  {
    "word": "щайуц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tea bags",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaːjwət͡s"
  },
  {
    "word": "щылыч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "steel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕələt͡ʃ"
  },
  {
    "word": "щылэмаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "January",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəlamaːz"
  },
  {
    "word": "щэбзащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arrow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕabzaːɕ"
  },
  {
    "word": "к1уснэщаб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bow",
        "examples": []
      }
    ],
    "synonyms": [
      "щабзэ"
    ],
    "type": "noun",
    "ipa": "kʷʼəsnaɕaːb"
  },
  {
    "word": "нэбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Nest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nabʁa"
  },
  {
    "word": "нэкъыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flower (the flower of the plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naqəʁa"
  },
  {
    "word": "анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1анэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːna"
  },
  {
    "word": "1ашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weapon, arms",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːʃa"
  },
  {
    "word": "л1ыжъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old man",
        "examples": []
      }
    ],

    "cognate": "л1ыжьы",
    "type": "noun",
    "ipa": "ɬʼəʐə"
  },
  {
    "word": "ныо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old woman",
        "examples": []
      }
    ],

    "cognate": "ныуэ",
    "type": "noun",
    "ipa": "nəwa"
  },
  {
    "word": "къамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Qama (Circassian short sword)",
        "examples": []
      },
      {
        "meaning": "dagger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːma"
  },
  {
    "word": "нэфнэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "light",
        "examples": []
      }
    ],
    "synonyms": [
      "нэфын",
      "нэфы"
    ],
    "cognate": "нэху",
    "type": "noun",
    "ipa": "nafna"
  },
  {
    "word": "къэлэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pen",
        "examples": []
      },
      {
        "meaning": "pencil",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qalam"
  },
  {
    "word": "к1эрахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gun",
        "examples": []
      },
      {
        "meaning": "rifle",
        "examples": []
      },
      {
        "meaning": "fire arm",
        "examples": []
      }
    ],

    "cognate": "к1эрахъуэ",
    "type": "noun",
    "ipa": "t͡ʃʼaraːχʷa"
  },
  {
    "word": "налъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slice, piece",
        "examples": [
          {
            "sentence": "Бэнанэ '''нэлъищ''' сфай",
            "translation": "I want three '''slice''' of banana."
          },
          {
            "sentence": "Кек '''налъэ''' горэ сыфай",
            "translation": "I want a '''slice''' of cake."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naːɬa"
  },
  {
    "word": "нэбгы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэбгыр\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nabɣə"
  },
  {
    "word": "пачъыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "king, emperor, caesar, sultan",
        "examples": []
      }
    ],
    "synonyms": [
      "пщыпащ"
    ],
    "cognate": "пащтыхь",
    "type": "noun",
    "ipa": "paːtʂəħ"
  },
  {
    "word": "гуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lady",
        "examples": []
      },
      {
        "meaning": "a woman's mother-in-law: her husband's mother",
        "examples": [
          {
            "sentence": "си '''гуащэ''' къэк1уагъ",
            "translation": "My '''mother-in-law''' came."
          }
        ]
      },
      {
        "meaning": "princess",
        "examples": []
      },
      {
        "meaning": "(Kfar Kama dialect) wife",
        "examples": [],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷaːɕa"
  },
  {
    "word": "нысэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bride",
        "examples": []
      },
      {
        "meaning": "daughter-in-law",
        "examples": []
      },
      {
        "meaning": "sister-in-law",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəsa"
  },
  {
    "word": "малхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brother-in-law",
        "examples": [
          {
            "sentence": "си '''малхъо''' къэк1уагъ",
            "translation": "My '''brother-in-law''' came."
          }
        ]
      }
    ],
    "synonyms": [
      "малъхъо",
      "пщыкъу",
      "л1ымыш",
      "махълъэ"
    ],
    "cognate": "малъхъэ",
    "type": "noun",
    "ipa": "maːlχʷa"
  },
  {
    "word": "мэлхъогъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A man's wife's sister's husband.",
        "examples": [
          {
            "sentence": "си '''мэлхъогъ''' къэк1уагъ",
            "translation": "My '''wife's sister's husband''' came."
          },
          {
            "sentence": "Томымрэ Джонымрэ з'''мэлхъогъ'''ых",
            "translation": "Tom and John are '''\"malkhogs\"'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "malχʷaʁ"
  },
  {
    "word": "ны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mother",
        "examples": []
      }
    ],

    "cognate": "анэ",
    "type": "noun",
    "ipa": "nə"
  },
  {
    "word": "1энэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandma",
        "examples": []
      }
    ],
    "synonyms": [
      "нэнэжъ"
    ],
    "type": "noun",
    "ipa": "ʔanaʐ"
  },
  {
    "word": "1этэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandpa",
        "examples": []
      }
    ],
    "synonyms": [
      "тэтэжъ"
    ],
    "type": "noun",
    "ipa": "ʔataʐ"
  },
  {
    "word": "нэнэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandma",
        "examples": []
      }
    ],
    "synonyms": [
      "1энэжъ"
    ],
    "type": "noun",
    "ipa": "nanaʐ"
  },
  {
    "word": "тэтэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandpa",
        "examples": []
      }
    ],
    "synonyms": [
      "1этэжъ"
    ],
    "type": "noun",
    "ipa": "tataʐ"
  },
  {
    "word": "къугъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1угъо\"",
        "examples": []
      }
    ],

    "cognate": "1угъо",
    "type": "noun",
    "ipa": "qʷəʁʷa"
  },
  {
    "word": "ят1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dirt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːtʼa"
  },
  {
    "word": "сапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dust",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːpa"
  },
  {
    "word": "псапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "redemption, good deed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psaːpa"
  },
  {
    "word": "пахъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "steam",
        "examples": []
      }
    ],

    "cognate": "бахъэ",
    "type": "noun",
    "ipa": "paːχ"
  },
  {
    "word": "хъуанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curse, swear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷaːna"
  },
  {
    "word": "хэку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "homeland, land",
        "examples": [
          {
            "sentence": "адыгэ '''хэку'''",
            "translation": "Circassian homeland."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "xakʷ"
  },
  {
    "word": "махъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"макъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχa"
  },
  {
    "word": "хъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "inn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χaːn"
  },
  {
    "word": "пцантхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glue",
        "examples": []
      }
    ],
    "synonyms": [
      "пцатхьэ"
    ],
    "type": "noun",
    "ipa": "pt͡saːntħa"
  },
  {
    "word": "щао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garden",
        "examples": []
      },
      {
        "meaning": "yard",
        "examples": []
      }
    ],
    "synonyms": [
      "щагу"
    ],
    "type": "noun",
    "ipa": "ʃaːwa"
  },
  {
    "word": "бзыук1алъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slingshot",
        "examples": []
      }
    ],
    "synonyms": [
      "бзыук1ьалъэ"
    ],
    "type": "noun",
    "ipa": "bzəwt͡ʃʼaːɬa"
  },
  {
    "word": "цэлъэк1алъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цэлъэк1\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цэлъэк1"
    ],
    "cognate": "дзэлъэщ1",
    "type": "noun",
    "ipa": "t͡saɬat͡ʃʼaːɬa"
  },
  {
    "word": "хьадэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a dead body, corpse",
        "examples": []
      }
    ],

    "cognate": "хьэдэ",
    "type": "noun",
    "ipa": "ħaːda"
  },
  {
    "word": "пкъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "body",
        "examples": []
      },
      {
        "meaning": "organ; body part",
        "examples": []
      },
      {
        "meaning": "figure (structure)",
        "examples": []
      }
    ],
    "synonyms": [
      "пкъышъоп"
    ],
    "type": "noun",
    "ipa": "pqə"
  },
  {
    "word": "псынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spring, stream, well",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəna"
  },
  {
    "word": "нэпэ1эплъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "napkin",
        "examples": []
      },
      {
        "meaning": "handkerchief",
        "examples": []
      }
    ],

    "cognate": "нэпэ1эплъэщ1",
    "type": "noun",
    "ipa": "napaʔapɬat͡ʃʼ"
  },
  {
    "word": "гогон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pitcher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷaɡʷan"
  },
  {
    "word": "гогонжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small pitcher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷaɡʷanʐəj"
  },
  {
    "word": "дэпкъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fence",
        "examples": []
      },
      {
        "meaning": "rail",
        "examples": []
      },
      {
        "meaning": "wall",
        "examples": [
          {
            "sentence": "'''дэпкъым ''' еплъ",
            "translation": "Look at '''the wall'''."
          },
          {
            "sentence": "'''дэпкъым ''' сурэтэр пылъ",
            "translation": "The picture is on '''the wall'''."
          }
        ]
      }
    ],
    "synonyms": [
      "блыны"
    ],
    "type": "noun",
    "ipa": "dapqə"
  },
  {
    "word": "лъэгъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэугъуае\"",
        "examples": []
      }
    ],
    "synonyms": [
      "дэк1ояп1э",
      "лъэой"
    ],
    "type": "noun",
    "ipa": "ɬaʁʷaːja"
  },
  {
    "word": "пэ1ухъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drape",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "paʔʷəχʷan"
  },
  {
    "word": "п1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mattress",
        "examples": []
      },
      {
        "meaning": "sheath",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʼa"
  },
  {
    "word": "чылмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carpet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəlma"
  },
  {
    "word": "шэчалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "balance (tool)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃat͡ʃaːɬ"
  },
  {
    "word": "ш1оилъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garbage can",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼajəɬ"
  },
  {
    "word": "унашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "roof",
        "examples": []
      }
    ],

    "cognate": "унащхьэ",
    "type": "noun",
    "ipa": "wnaːʂħa"
  },
  {
    "word": "бжъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bowl",
        "examples": []
      },
      {
        "meaning": "cornu",
        "examples": []
      },
      {
        "meaning": "horn",
        "examples": [
          {
            "sentence": "Цум '''бжъэхэ''' готых",
            "translation": "The ox has '''horns'''."
          },
          {
            "sentence": "Ышъхьэ '''бжъит1у''' гот",
            "translation": "It has '''two horns''' on its head."
          }
        ]
      },
      {
        "meaning": "slander",
        "examples": []
      },
      {
        "meaning": "Span of the hand (a measure)",
        "examples": []
      }
    ],

    "cognate": "бжьэ",
    "type": "noun",
    "ipa": "bʐa"
  },
  {
    "word": "мажьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hairbrush",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːʑa"
  },
  {
    "word": "лэныстэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scissors",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "lanəsta"
  },
  {
    "word": "пхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχa"
  },
  {
    "word": "пхъэнк1ыпхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "broom",
        "examples": []
      }
    ],

    "cognate": "жыхапхъэ",
    "type": "noun",
    "ipa": "pχant͡ʃʼəpχ"
  },
  {
    "word": "гъуч1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "iron",
        "examples": []
      }
    ],

    "cognate": "гъущ1",
    "type": "noun",
    "ipa": "ʁʷət͡ʃʼə"
  },
  {
    "word": "шъхьантэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pillow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːnta"
  },
  {
    "word": "хы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sea",
        "examples": []
      },
      {
        "meaning": "six",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xə"
  },
  {
    "word": "1удан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thread",
        "examples": [
          {
            "sentence": "Iудэнэ кIыхь",
            "translation": "long thread"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷədaːn"
  },
  {
    "word": "къэпы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tough",
        "examples": [
          {
            "sentence": "Л1ыр '''къэпэу''' щытын фай",
            "translation": "The man needs to be '''tough'''."
          }
        ]
      },
      {
        "meaning": "rotten, old and broken",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qapə"
  },
  {
    "word": "1уашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mount",
        "examples": []
      },
      {
        "meaning": "hill",
        "examples": []
      }
    ],

    "cognate": "1уащхьэ",
    "type": "noun",
    "ipa": "ʔʷaːʂħa"
  },
  {
    "word": "к1апсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rope",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaːpsa"
  },
  {
    "word": "шыблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thunder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəbla"
  },
  {
    "word": "пчык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lightning",
        "examples": []
      },
      {
        "meaning": "spear channel",
        "examples": []
      },
      {
        "meaning": "thunderbolt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃət͡ʃʼa"
  },
  {
    "word": "къэгъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flower (the plant)",
        "examples": [
          {
            "sentence": "Мы '''къэгъагъэр''' сыгу рехьы.",
            "translation": "I like this '''flower'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaʁaːʁa"
  },
  {
    "word": "гъогу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "road",
        "examples": []
      },
      {
        "meaning": "path",
        "examples": []
      }
    ],

    "cognate": "гъуэгу",
    "type": "noun",
    "ipa": "ʁʷaɡʷ"
  },
  {
    "word": "мэш1оку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "train",
        "examples": []
      },
      {
        "meaning": "(literally) firewagon",
        "examples": [],
        "tags": [
          "literally"
        ]
      }
    ],
    "type": "noun",
    "ipa": "maʃʷʼakʷ"
  },
  {
    "word": "жъапхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐaːpχa"
  },
  {
    "word": "тэпсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tray",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tapsa"
  },
  {
    "word": "плъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''еплъы'''",
            "translation": "The boy '''is looking''' at the girl."
          },
          {
            "sentence": "Унэ плъыжьым '''еплъ'''",
            "translation": "'''Look at''' the red house."
          },
          {
            "sentence": "Зыкъэгъази '''къысэплъ'''",
            "translation": "Turn yourself and '''look at me'''."
          },
          {
            "sentence": "Ныор къэгъагъэхэм '''аплъынэ''' ик1ас",
            "translation": "The old-woman likes '''to look at''' the flowers."
          },
          {
            "sentence": "'''Къэсфеплъ''' сиятэ къэсыгъэмэ",
            "translation": "'''Look for me''' if my father came."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pɬan"
  },
  {
    "word": "лъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to see",
        "examples": [
          {
            "sentence": "мэр '''плъэгъун''' фай",
            "translation": "'''You''' must '''see''' this."
          },
          {
            "sentence": "к1алэм пшъашъэр '''елъэгъу'''",
            "translation": "The boy '''is seeing''' the girl."
          },
          {
            "sentence": "к1алэм пшъашъэр '''елъэгъогъ'''",
            "translation": "The boy '''saw''' the girl."
          },
          {
            "sentence": "'''слъэгъурэп''' пшъашъэр",
            "translation": "'''I don't see''' the girl."
          },
          {
            "sentence": "'''слъэгъушъурэп''' пшъашъэр",
            "translation": "'''I can't see''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pɬaʁʷən"
  },
  {
    "word": "чъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run",
        "examples": [
          {
            "sentence": "к1алэр '''мачъэ'''",
            "translation": "The boy '''is running'''."
          },
          {
            "sentence": "к1алэр '''къачъэ'''",
            "translation": "The boy '''is coming (running)'''."
          }
        ]
      }
    ],

    "cognate": "жэн",
    "type": "verb",
    "ipa": "tʂan"
  },
  {
    "word": "л1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to die",
        "examples": [
          {
            "sentence": "Л1ыжъэр '''мал1э '''",
            "translation": "The old-man '''is dying'''."
          },
          {
            "sentence": "Л1ыжъэр '''мэл1эт'''",
            "translation": "The old-man '''will die'''."
          },
          {
            "sentence": "Я '''ул1эн''' я ул1ын",
            "translation": "'''Die''' or be a man."
          },
          {
            "sentence": "'''Ул1энэу''' уфая?",
            "translation": "Do you want '''to die'''?"
          },
          {
            "sentence": "Чэтыор уфызмэ '''мэл1эщт'''",
            "translation": "If you squeeze the cat '''it will die'''."
          },
          {
            "sentence": "Л1ы бэу заом '''хэл1агъэх'''",
            "translation": "Many men '''died in''' the war."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬʼan"
  },
  {
    "word": "хъонэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swear; to swear at",
        "examples": [
          {
            "sentence": "К1алэр бэрэ '''мэхъуанэ'''",
            "translation": "The boy '''swears''' a lot."
          },
          {
            "sentence": "Л1ыжъым '''хъонэныр''' ихабз",
            "translation": "It is the old man's habit '''to swear'''."
          },
          {
            "sentence": "Л1ыр к1алэм '''ехъоныгъ'''",
            "translation": "The man '''sworn at''' the boy."
          },
          {
            "sentence": "'''Укъысэмыхъон'''",
            "translation": "'''Don't swear at me'''."
          }
        ]
      }
    ],

    "cognate": "хъуэнэн",
    "type": "verb",
    "ipa": "χʷanan"
  },
  {
    "word": "лэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to color, to paint",
        "examples": [
          {
            "sentence": "к1алэм дэпкъэр '''елэ'''",
            "translation": "The boy '''is coloring''' the wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "lan"
  },
  {
    "word": "джэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ежьэжьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaʑən"
  },
  {
    "word": "тэгъэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make an object hit someone or something (usually by throwing)",
        "examples": [
          {
            "sentence": "топымк1э чъыгым '''тэгъаф'''",
            "translation": "'''Hit''' the tree with a ball."
          },
          {
            "sentence": "топэр уыздымэ апк1ым '''тэпгъэфэт'''",
            "translation": "If you throw the ball you will '''hit''' the glass."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁafan"
  },
  {
    "word": "тэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hit",
        "examples": [
          {
            "sentence": "1эгуаум уешъутырмэ 1анэм '''тэфэт'''",
            "translation": "If you kick the ball, it '''will hit''' the table."
          },
          {
            "sentence": "'''тафыу''' рэхъутэп апк1ым",
            "translation": "It must not '''hit''' the glass."
          },
          {
            "sentence": "к1арахъомк1э '''тагъаф''' апк1ым",
            "translation": "'''hit''' the glass with (using) the gun."
          },
          {
            "sentence": "мыжъор апк1ым '''тэфагъ'''",
            "translation": "The rock '''hit''' the glass."
          }
        ]
      },
      {
        "meaning": "to fall (On something or someone)",
        "examples": [
          {
            "sentence": "к1алэр ч1ыгум '''тэфагъ'''",
            "translation": "The boy '''fell on''' the ground."
          },
          {
            "sentence": "къэцхэмэ к1алэр я '''хэфагъ'''",
            "translation": "The boy '''fell on''' the thorns."
          },
          {
            "sentence": "мыжъо уишъхьэ '''тафэмэ''' уишъхьэ иубэтэт",
            "translation": "'''If''' a rock '''hit''' your head, your head will break."
          },
          {
            "sentence": "Исаак Ньютоным ишъхьэ мые '''тэфагъ'''",
            "translation": "An apple fell on Isaac Newton's head."
          }
        ]
      },
      {
        "meaning": "to meet someone (by chance)",
        "examples": [
          {
            "sentence": "к1алэр '''къэстэфэгъагъ''' скъэк1ожьыу",
            "translation": "'''I met''' the boy while i was coming back."
          },
          {
            "sentence": "л1ыжъыр '''къуптафэмэ''' еджэжь",
            "translation": "'''If you will meet''' the old man, run away."
          },
          {
            "sentence": "томым джонэр '''тэфагъ''' еджэжь",
            "translation": "Tom '''met''' John by chance."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tafan"
  },
  {
    "word": "т1эрэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ет1эрэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tʼaraxən"
  },
  {
    "word": "сэнэтхьапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grape leaf",
        "examples": []
      },
      {
        "meaning": "stuffed grape leaf (food) (compare the English concepts of \"dolma\", \"sarma\")",
        "examples": [],
        "tags": [
          "compare the English concepts of \"dolma\", \"sarma\""
        ]
      }
    ],
    "type": "noun",
    "ipa": "sanatħaːpa"
  },
  {
    "word": "гьэгуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэгуалъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʲaɡʷaːɬa"
  },
  {
    "word": "пхъэх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saw (tool)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχax"
  },
  {
    "word": "чъыглэныстэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "secateurs",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʂəɣlanəsta"
  },
  {
    "word": "чэркэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "t͡ʃarkas"
  },
  {
    "word": "адыгэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an Adyghe person, a member of the Adyghe people",
        "examples": [
          {
            "sentence": "'''Адыгэхэр''' адыгэбзэк1э мэгуша1эх",
            "translation": "'''The Adyghe people''' speak Adygabze (Adyghe language)."
          },
          {
            "sentence": "'''Уадыга?'''",
            "translation": "'''Are you an Adyghe?'''"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːdəɣa"
  },
  {
    "word": "шапсыгъэбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the Shapsug language (One of the Adyghe dialects)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ʃapsəʁabza"
  },
  {
    "word": "лъапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barefoot",
        "examples": []
      }
    ],
    "synonyms": [
      "лъапс1э"
    ],
    "type": "adj",
    "ipa": "ɬaːpt͡sʼa"
  },
  {
    "word": "къаунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "muskmelon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːwəna"
  },
  {
    "word": "делэхъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "insane asylum",
        "examples": []
      },
      {
        "meaning": "mental hospital",
        "examples": []
      },
      {
        "meaning": "sanitarium",
        "examples": []
      }
    ],
    "synonyms": [
      "делэщ"
    ],
    "type": "noun",
    "ipa": "dajlaχaːn"
  },
  {
    "word": "зетын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oil",
        "examples": []
      },
      {
        "meaning": "olive",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zajtən"
  },
  {
    "word": "бысылмэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Islam, Muslim",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəsəlman"
  },
  {
    "word": "зэйтынчъыг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "olive tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zajtəntʂəɣ"
  },
  {
    "word": "шъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hunter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷaːkʷʼa"
  },
  {
    "word": "мэлахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shepherd, sheep herdsman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "malaːχʷa"
  },
  {
    "word": "наныу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baby, infant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːnəw"
  },
  {
    "word": "былътырыку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Eurasian lynx",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəɬtərəkʷ"
  },
  {
    "word": "бгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Phragmites",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁan"
  },
  {
    "word": "бынд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A common raven.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bənd"
  },
  {
    "word": "гъаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A lesser spotted eagle.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaːf"
  },
  {
    "word": "джэдгъал1эфлъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fieldfare",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒadʁaːɬʼafɬa"
  },
  {
    "word": "джэнета",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "genet (Genetta spp.)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒanajtaː"
  },
  {
    "word": "дзэлыкъуэбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Old World vulture",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dzaləqʷabʁa"
  },
  {
    "word": "домбай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bison",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "елэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "marbled polecat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jalan"
  },
  {
    "word": "къазмакъбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Güldenstädt's Redstart",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːzmaːqbzəw"
  },
  {
    "word": "къиякъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Eastern Imperial Eagle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qijaːq"
  },
  {
    "word": "къэк1ыгъэхэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plants",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qakʲʼəʁaxar"
  },
  {
    "word": "мэрак1уапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blackberry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maraːkʷʼaːt͡sʼa"
  },
  {
    "word": "пхъэгулъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A cherry plum: a member of a certain species of plum (namely Prunus cerasifera).",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bχaɡʷəɬ"
  },
  {
    "word": "пыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Prunus spinosa",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pəʐ"
  },
  {
    "word": "уашхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "badger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "waːʃxa"
  },
  {
    "word": "бжьэгъал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clematis",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑaʁaːɬʼa"
  },
  {
    "word": "шъуап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуамп1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуамп1э"
    ],
    "type": "noun",
    "ipa": "ʃʷʼaːpʼa"
  },
  {
    "word": "чэмахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cowherd, cow herdsman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃamaːχʷa"
  },
  {
    "word": "тыгъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thief, burglar, robber",
        "examples": []
      }
    ],

    "cognate": "хъунщ1ак1уэ",
    "type": "noun",
    "ipa": "təʁʷaːkʷʼa"
  },
  {
    "word": "пц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a lie",
        "examples": [
          {
            "sentence": "'''Уипц1ыхэмэ''' cазэшэгъ",
            "translation": "I got bored '''from your lies'''."
          },
          {
            "sentence": "К1алэм '''пц1ы''' ехъурэ къипч1ырэп",
            "translation": "The boy doesn't say anything except '''lies'''."
          },
          {
            "sentence": "'''Пц1ыгорэ''' сэ1огъ",
            "translation": "I heard '''a lie'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пс1ы"
    ],
    "type": "noun",
    "ipa": "pt͡sʼə"
  },
  {
    "word": "к1эрхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swing",
        "examples": []
      }
    ],
    "synonyms": [
      "кьэрхъон",
      "хъэрен"
    ],
    "type": "noun",
    "ipa": "t͡ʃʼarχʷan"
  },
  {
    "word": "пщы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A prince.",
        "examples": [
          {
            "sentence": "си '''пщы''' къэк1уагъ",
            "translation": "My '''prince''' came."
          }
        ]
      },
      {
        "meaning": "A woman's father-in-law: her husband's father.",
        "examples": [
          {
            "sentence": "си '''пщы''' къэк1уагъ",
            "translation": "My '''father-in-law''' came."
          }
        ]
      },
      {
        "meaning": "(Abzakh dialect) spear",
        "examples": [],
        "tags": [
          "Abzakh dialect"
        ]
      }
    ],
    "type": "noun",
    "ipa": "pɕə"
  },
  {
    "word": "егъанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scab",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaʁaːna"
  },
  {
    "word": "хьэшъобыдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pacifier",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʂʷabəd͡z"
  },
  {
    "word": "шъхьэгьашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэджашъу\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħaɡʲaːʃʷ"
  },
  {
    "word": "1эгъуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sleeve (of a shirt)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaʁʷaːpa"
  },
  {
    "word": "гъопэк1эхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "long-sleeved shirt",
        "examples": [
          {
            "sentence": "к1алэм '''гъопэк1эхь''' шэгъ",
            "translation": "The boy is wearing a '''long-sleeved shirt'''."
          },
          {
            "sentence": "'''гъопэк1эхь''' шылъ",
            "translation": "Wear a '''long-sleeved shirt'''."
          },
          {
            "sentence": "'''гъопэк1эхьэр''' гъэмафэм фэш, гъопэк1ако к1ымафэм фэш",
            "translation": "'''long-sleeved shirt''' is for the summer and short-sleeved shirt is for winter."
          }
        ]
      }
    ],
    "synonyms": [
      "гъопэк1ьэхь"
    ],
    "type": "noun",
    "ipa": "ʁʷaːpat͡ʃʼaħ"
  },
  {
    "word": "цы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hair (Usually of animal or human's body)",
        "examples": []
      },
      {
        "meaning": "wool",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sə"
  },
  {
    "word": "гьанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug) alternative form of \"джанэ\"",
        "examples": [],
        "tags": [
          "Shapsug",
          "alternative_spelling"
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʲaːna"
  },
  {
    "word": "сак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сако\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːkʷʼa"
  },
  {
    "word": "к1эпхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skirt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼapxən"
  },
  {
    "word": "гъошэгь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъончэдж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaʃaɡʲ"
  },
  {
    "word": "цуакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цуакъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "чъуакъо"
    ],
    "cognate": "вакъэ",
    "type": "noun",
    "ipa": "t͡sʷaːqʷa"
  },
  {
    "word": "па1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hat",
        "examples": []
      },
      {
        "meaning": "cap",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːʔʷa"
  },
  {
    "word": "нэпэталхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэпэтелъхьэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "napataːlħa"
  },
  {
    "word": "пэ1оталъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "helmet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paʔʷataːlħa"
  },
  {
    "word": "лъэпэд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "socks",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬapad"
  },
  {
    "word": "1элъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ring",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaɬən"
  },
  {
    "word": "джэгъуч1элъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "necklace",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaʁʷaɬ"
  },
  {
    "word": "цуэкъок1эхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boots",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʷaqʷakʲʼaħ"
  },
  {
    "word": "1экощ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "umbrella",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔakʷʼaɕ"
  },
  {
    "word": "пщагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fog",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕaːʁʷ"
  },
  {
    "word": "жьао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жьау\"",
        "examples": []
      }
    ],

    "cognate": "жьауэ",
    "type": "noun",
    "ipa": "ʑaːwa"
  },
  {
    "word": "1эбычъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эбыцу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "1эбыцу"
    ],
    "type": "noun",
    "ipa": "ʔabət͡ʃʷ"
  },
  {
    "word": "ахъчалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wallet, purse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃaːɬ"
  },
  {
    "word": "тхылъылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "briefcase",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəɬəɬ"
  },
  {
    "word": "гъошэгь ч1эгъыч1элъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъончэдж ч1эгъыч1элъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaʃaɡʲ t͡ʂʼaʁət͡ʂʼaɬ"
  },
  {
    "word": "цыгьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цыджанэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡səɡʲaːn"
  },
  {
    "word": "пшъэдэлъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scarf (neck)",
        "examples": []
      }
    ],
    "synonyms": [
      "дыйдэлъ"
    ],
    "type": "noun",
    "ipa": "pʂadaɬ"
  },
  {
    "word": "дыйдалхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "necktie",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэдалхь"
    ],
    "type": "noun",
    "ipa": "dəjdaːlħ"
  },
  {
    "word": "дыйдэлъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scarf (neck)",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэдэлъ"
    ],
    "type": "noun",
    "ipa": "dəjdaɬ"
  },
  {
    "word": "пшъэдалхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "necktie",
        "examples": []
      }
    ],
    "synonyms": [
      "дыйдалхь"
    ],
    "type": "noun",
    "ipa": "pʂadaːlħ"
  },
  {
    "word": "тгъэнэгъургьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sunglasses",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʁanaʁʷərɡʲa"
  },
  {
    "word": "къэптан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "caftan (clothes)",
        "examples": []
      },
      {
        "meaning": "jubbah (loose outer garment)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaptaːn"
  },
  {
    "word": "пшъэрылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "task, problem",
        "examples": [
          {
            "sentence": "'''Пшъэрылъ''' зыфэш1ыжьын",
            "translation": "To give oneself '''tasks'''."
          }
        ]
      },
      {
        "meaning": "object, objective, aim, goal, mission, end",
        "examples": []
      },
      {
        "meaning": "assignment, commission, errand",
        "examples": []
      },
      {
        "meaning": "duty",
        "examples": [
          {
            "sentence": "'''Ипшъэрылъхэр''' ыгъэцэк1эных",
            "translation": "He fulfills '''his duties'''."
          }
        ]
      }
    ],
    "synonyms": [
      "дыйрылъ"
    ],
    "type": "noun",
    "ipa": "pʂarəɬ"
  },
  {
    "word": "дыйрылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "task, problem",
        "examples": []
      },
      {
        "meaning": "object, objective, aim, goal, mission, end",
        "examples": []
      },
      {
        "meaning": "assignment, commission, errand",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэрылъ"
    ],
    "type": "noun",
    "ipa": "dəjarəɬ"
  },
  {
    "word": "бырыпх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "belt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pərəpx"
  },
  {
    "word": "быдзылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bra",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəd͡zəɬ"
  },
  {
    "word": "тхьэк1умэпылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "earring",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħakʷʼmapəɬ"
  },
  {
    "word": "лъэбы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mule (footwear)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬabəʔʷ"
  },
  {
    "word": "1эпшъэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bracelet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔapʂaχʷ"
  },
  {
    "word": "сыхьат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "o'clock",
        "examples": [
          {
            "sentence": "'''Сыхьатыр''' зы хъугъэ.",
            "translation": "It is one o’clock."
          },
          {
            "sentence": "'''Сыхьатыр''' щы хъугъэ.",
            "translation": "It is three o’clock."
          }
        ]
      },
      {
        "meaning": "hour",
        "examples": [
          {
            "sentence": "Тхьапш '''сыхьатыр'''?.",
            "translation": "What time/hour is it?"
          },
          {
            "sentence": "'''Сыхьатык1э''' тыдэк1ыт.",
            "translation": "We will go out '''in an hour'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "səħaːt"
  },
  {
    "word": "цу1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "buttons (clothes and pants)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʷəʔ"
  },
  {
    "word": "мэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэзы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maz"
  },
  {
    "word": "бзыуцыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cotton",
        "examples": []
      },
      {
        "meaning": "cotton wool (Sweet)",
        "examples": []
      },
      {
        "meaning": "Cotton (plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwt͡səf"
  },
  {
    "word": "ныбжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "age",
        "examples": [
          {
            "sentence": "хьэпщ '''уиныбжьэ'''",
            "translation": "How '''old''' are you? - (How much your '''age''')."
          },
          {
            "sentence": "'''уиныбжь''' бэ",
            "translation": "You are old - (Your '''age''' is a lot)."
          },
          {
            "sentence": "'''синыбжь''' сэ бгъу",
            "translation": "I am 9 '''years old''' - (My '''age''' is 9)."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nəbʑ"
  },
  {
    "word": "гонэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misdeed",
        "examples": []
      },
      {
        "meaning": "sin",
        "examples": []
      }
    ],
    "synonyms": [
      "псэк1од"
    ],
    "type": "noun",
    "ipa": "ɡʷanaħ"
  },
  {
    "word": "тхьапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leaf",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьэпк1э"
    ],
    "cognate": "тхьэмпэ",
    "type": "noun",
    "ipa": "tħaːpa"
  },
  {
    "word": "коц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"коцы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷat͡s"
  },
  {
    "word": "к1эй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vale, dale",
        "examples": [
          {
            "sentence": "'''к1эйым''' сехыт",
            "translation": "I will go down '''to the vale'''."
          },
          {
            "sentence": "'''к1эйым''' дэсхэр",
            "translation": "the people that are (sitting) '''in the vale'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1ьэй",
      "лъэгу"
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaj"
  },
  {
    "word": "къэц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэцы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qat͡s"
  },
  {
    "word": "дэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deaf",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "daɡʷə"
  },
  {
    "word": "нэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blind",
        "examples": []
      }
    ],

    "cognate": "нэф",
    "type": "adj",
    "ipa": "naʂʷə"
  },
  {
    "word": "к1эхьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ыхьэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃʼaħə"
  },
  {
    "word": "хыгъунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beach",
        "examples": []
      }
    ],
    "synonyms": [
      "хы 1ушъу"
    ],
    "type": "noun",
    "ipa": "xəʁʷəna"
  },
  {
    "word": "тэрэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "correct",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "taraz"
  },
  {
    "word": "бэрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "many times, a lot",
        "examples": [
          {
            "sentence": "К1алэр '''бэрэ''' сиунэ къак1о",
            "translation": "The boy is coming to my house '''a lot'''."
          },
          {
            "sentence": "'''Бэрэ''' тхылъхэмэ саджэ",
            "translation": "I read the books '''many times'''."
          }
        ]
      },
      {
        "meaning": "long time",
        "examples": [
          {
            "sentence": "'''Бэрэ''' сиунэ укъэк1ожьыгъэп",
            "translation": "'''long time''' you didn't come to my house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "bara"
  },
  {
    "word": "иужым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "after",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jəwʒəm"
  },
  {
    "word": "пасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "early",
        "examples": [
          {
            "sentence": "'''пасэу''' скъэк1уагъ",
            "translation": "I came '''early'''."
          },
          {
            "sentence": "'''пасэу''' непэ тагъэлэжэт",
            "translation": "Today they will make us work '''early'''."
          },
          {
            "sentence": "'''пасэ''' дэхьэ",
            "translation": "It's still '''early'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "paːca"
  },
  {
    "word": "лъэжъуагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "late",
        "examples": [
          {
            "sentence": "'''лъэжъуагъэу''' скъэк1уагъ",
            "translation": "I came '''to late'''."
          },
          {
            "sentence": "'''лъэжъуагъэу''' непэ тагъэлэжэт",
            "translation": "Today they will make us work '''late'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ɬaʐʷaːʁ"
  },
  {
    "word": "жъабгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "right (the opposite of left)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐaːbʁʷa"
  },
  {
    "word": "сэмэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "left",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "samaɡʷ"
  },
  {
    "word": "джыбэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pocket",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəba"
  },
  {
    "word": "чызмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boots",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэецуакъо"
    ],
    "type": "noun",
    "ipa": "t͡ʃəzma"
  },
  {
    "word": "фэусын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"усын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "fawsən"
  },
  {
    "word": "1адэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tongs (An instrument or tool used for manipulating things in a fire without touching them with the hands)",
        "examples": []
      },
      {
        "meaning": "pliers",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːda"
  },
  {
    "word": "1азэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skilled",
        "examples": []
      },
      {
        "meaning": "expert",
        "examples": [
          {
            "sentence": "Ар бжьэхъо '''1аз''', ау бжьэхъон 1энат1эм фаеп",
            "translation": "He is an beekeeper '''expert''', but he does not want to be in a beekeeping job."
          }
        ]
      },
      {
        "meaning": "doctor",
        "examples": []
      }
    ],
    "synonyms": [
      "фэ1аз"
    ],
    "type": "adj",
    "ipa": "ʔaːza"
  },
  {
    "word": "1элъмэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaɬmaq"
  },
  {
    "word": "апшъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːpʂ"
  },
  {
    "word": "мэлак1э л1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to starve (To be very hungry, to die from hunger).",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "malaːt͡ʃʼa ɬʼan"
  },
  {
    "word": "псыфал1э л1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be dying of thirst",
        "examples": []
      }
    ],
    "synonyms": [
      "псы фэл1эн"
    ],
    "type": "verb",
    "ipa": "psəfaːɬʼa ɬʼan"
  },
  {
    "word": "фабэ пэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel hot",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "faːba pan"
  },
  {
    "word": "л1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to die (caused by something)",
        "examples": [
          {
            "sentence": "уысым к1алэр '''ил1ык1эгъ'''",
            "translation": "The boy '''died from''' the disease."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬˤʼəkʲʼən"
  },
  {
    "word": "1оталъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wisp",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷataːɬ"
  },
  {
    "word": "1уагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oath",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷaːʁa"
  },
  {
    "word": "щэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sell",
        "examples": [
          {
            "sentence": "К1алэм '''ещэ''' бэнанэ",
            "translation": "The boy ''''is selling''' a banana."
          },
          {
            "sentence": "К1алэм '''ищэрэп''' мыр",
            "translation": "The boy ''''is not selling''' this."
          }
        ]
      },
      {
        "meaning": "to take someone to, to bring someone somewhere",
        "examples": [
          {
            "sentence": "К1алэм '''ешэ''' пшъашъэр еджап1эм",
            "translation": "The boy '''is taking''' the girl to school."
          },
          {
            "sentence": "'''Сыщ''' машинэмк1э хым",
            "translation": "'''Take me''' to the sea with the car."
          },
          {
            "sentence": "Томэр еджап1эм '''щэ'''",
            "translation": "'''Take''' Tom to school."
          },
          {
            "sentence": "К1алэр '''сэшэ''' еджап1эм",
            "translation": "'''I am taking''' the boy to school."
          },
          {
            "sentence": "К1элэц1ык1ухэр '''уашэха''' еджап1эм?",
            "translation": "'''Are you taking''' the little kids to school?"
          }
        ]
      },
      {
        "meaning": "to lead",
        "examples": [
          {
            "sentence": "тхьаматым тэ заом '''тещэ'''",
            "translation": "The leader '''is leading''' us to the war."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕan"
  },
  {
    "word": "зэгожъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get sad, to get upset",
        "examples": [
          {
            "sentence": "К1алэм и ныбджэгъу л1агъи '''зэгожъы'''",
            "translation": "The boy '''is sad''' because his friend died."
          },
          {
            "sentence": "К1элэц1ык1ум и джэгуалъэр ыутэтагъи '''зэгожъыгъ'''",
            "translation": "The boy '''got sad''' because he broke his toy."
          },
          {
            "sentence": "'''Узэгомыжъ''', сэ джэгуалъэ к1э къыпфэсщэфыщт",
            "translation": "'''Don't get sad''', I will buy you a new toy."
          }
        ]
      }
    ],
    "synonyms": [
      "нэшхъэин",
      "чэфынчъэн"
    ],
    "type": "verb",
    "ipa": "zaɡʷaʐən"
  },
  {
    "word": "псыунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toilet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəwəna"
  },
  {
    "word": "къыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "moron, imbecile",
        "examples": [
          {
            "sentence": "К1элэ '''къыгъ'''",
            "translation": "'''An imbecile''' boy."
          },
          {
            "sentence": "'''Укъыгъа''' сэ1о?",
            "translation": "'''Are you a moron?'''"
          }
        ]
      },
      {
        "meaning": "dull",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qəʁa"
  },
  {
    "word": "нэпх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jugglery",
        "examples": []
      },
      {
        "meaning": "magic",
        "examples": []
      },
      {
        "meaning": "hypnotism",
        "examples": []
      },
      {
        "meaning": "wizardry games",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "napx"
  },
  {
    "word": "1оф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1офы\"",
        "examples": []
      }
    ],

    "cognate": "1уэху",
    "type": "noun",
    "ipa": "ʔʷaf"
  },
  {
    "word": "хъохъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plum",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷaχʷ"
  },
  {
    "word": "бэнанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "banana",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "banaːna"
  },
  {
    "word": "тхьэматэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьаматэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħamaːta"
  },
  {
    "word": "ащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "he, that (ergative case)",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "aːɕ"
  },
  {
    "word": "ежь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(colloquial) actually",
        "examples": [
          {
            "sentence": "'''ежь''' фылым к1эр афэдизэу дэгъоп къыса1уагъ",
            "translation": "'''Actually''', I was told that the new movie is not that good."
          },
          {
            "sentence": "'''ежь''' тиджагъа тимыджагъа, экзамыным тыблэк1ыщтыпи тыблэк1ыщтэп",
            "translation": "'''In truth''', either we study or not, we won't pass the exam."
          }
        ],
        "tags": [
          "colloquial"
        ]
      },
      {
        "meaning": "himself",
        "examples": [
          {
            "sentence": "К1алэм ицуакъэ '''ежь''' щэфэ",
            "translation": "The boy's shoe fits '''on him'''."
          }
        ]
      }
    ],
    "cognate": "езы",
    "type": "intj",
    "ipa": "jaʑ"
  },
  {
    "word": "ежьхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "they, themselves",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "jaʑxa"
  },
  {
    "word": "дыуэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that, that person: that person or object (absolutive case)",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "dəwar"
  },
  {
    "word": "уэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "war"
  },
  {
    "word": "мэхэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) these: these people or things right here",
        "examples": [],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron",
    "ipa": "maxar"
  },
  {
    "word": "мэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smell",
        "examples": [
          {
            "sentence": "Пшъашъэм къэгъагъэмэ '''ямэ''' шъу елъэгъу",
            "translation": "The girl loves the flower's '''smell'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ma"
  },
  {
    "word": "дыухэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) those",
        "examples": [],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron",
    "ipa": "dəwar"
  },
  {
    "word": "ащыгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ащгъум\"",
        "examples": []
      }
    ],
    "type": "adv"
  },
  {
    "word": "чъуман",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цуманэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цуманэ"
    ],
    "type": "noun",
    "ipa": "t͡ʃʷəmaːn"
  },
  {
    "word": "ашафы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "millet",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "мэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "here",
        "examples": [
          {
            "sentence": "'''мэу''' къак1у",
            "translation": "come '''here'''"
          },
          {
            "sentence": "'''мэу''' гъэт1ылъ",
            "translation": "put it '''here'''"
          },
          {
            "sentence": "'''мэу''' ущымыщх",
            "translation": "don't eat '''here'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "maw"
  },
  {
    "word": "уыд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there",
        "examples": [
          {
            "sentence": "'''уыд'''э к1о",
            "translation": "go '''there'''"
          },
          {
            "sentence": "'''уыд'''э гъэт1ылъ",
            "translation": "put it '''there'''"
          },
          {
            "sentence": "'''уыд'''э ущымыщх",
            "translation": "don't eat '''there'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "wəd"
  },
  {
    "word": "1а",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that",
        "examples": [
          {
            "sentence": "'''а''' бэнанэ",
            "translation": "'''that''' banana"
          },
          {
            "sentence": "'''а''' пшъашъэ",
            "translation": "'''that''' girl"
          },
          {
            "sentence": "'''а''' к1алэм е1уэ",
            "translation": "'''that''' boy is saying"
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "ʔaː"
  },
  {
    "word": "дыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that",
        "examples": [
          {
            "sentence": "'''дыу''' бэнанэ",
            "translation": "'''that''' banana"
          },
          {
            "sentence": "'''дыу''' пшъашъэ",
            "translation": "'''that''' girl"
          },
          {
            "sentence": "'''дыу''' к1алэм е1уэ",
            "translation": "'''that''' boy is saying"
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "dəw"
  },
  {
    "word": "мэхъагьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэхъаджэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "maχaːɡʲa"
  },
  {
    "word": "мэбагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "it abounds",
        "examples": []
      }
    ],
    "type": "verb"
  },
  {
    "word": "пхъатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "respect",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχaːta"
  },
  {
    "word": "пагэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arrogant, haughty, proud",
        "examples": [
          {
            "sentence": "К1алэм '''пагэу''' и1уагъэ",
            "translation": "The boy said '''arrogantly'''."
          },
          {
            "sentence": "Томэр '''пагэ'''",
            "translation": "The boy '''is arrogant'''."
          },
          {
            "sentence": "'''Зымгъэпаг'''",
            "translation": "'''Don't be arrogant'''."
          }
        ]
      },
      {
        "meaning": "haughty",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "paːɣa"
  },
  {
    "word": "пхъапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a piece of wood",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэчыпэ",
      "пхъэжъые"
    ],
    "cognate": "пхъампэ",
    "type": "noun",
    "ipa": "pχaːpa"
  },
  {
    "word": "цыпкъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chisel",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "мэдрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "this other",
        "examples": [
          {
            "sentence": "'''мэдрэ''' бэнанэ",
            "translation": "'''this other''' banana"
          },
          {
            "sentence": "'''мэдрэ''' пшъашъэ",
            "translation": "'''this other''' girl"
          },
          {
            "sentence": "'''мэдрэ''' к1алэм е1уэ",
            "translation": "'''this other''' boy is saying"
          },
          {
            "sentence": "'''мэдрэ''' мэгъэ",
            "translation": "'''this other''' year"
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "madra"
  },
  {
    "word": "адрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that other",
        "examples": [
          {
            "sentence": "'''адрэ''' бэнанэ",
            "translation": "'''that other''' banana"
          },
          {
            "sentence": "'''адрэ''' пшъашъэ",
            "translation": "'''that other''' girl"
          },
          {
            "sentence": "'''адрэ''' к1алэм е1уэ",
            "translation": "'''that other''' boy is saying"
          },
          {
            "sentence": "'''адрэ''' мэгъэ",
            "translation": "'''that other''' year"
          }
        ]
      }
    ],
    "type": "det"
  },
  {
    "word": "мэдрэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) this other",
        "examples": [
          {
            "sentence": "'''мэдрэр''' мак1о",
            "translation": "'''this other''' is going"
          },
          {
            "sentence": "'''мэдрэр''' дахэ",
            "translation": "'''this other''' is pretty"
          }
        ],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron",
    "ipa": "madra"
  },
  {
    "word": "адрэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) that other",
        "examples": [
          {
            "sentence": "'''адрэр''' мак1о",
            "translation": "'''that other''' is going"
          },
          {
            "sentence": "'''адрэр''' дахэ",
            "translation": "'''that other''' is pretty"
          }
        ],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron"
  },
  {
    "word": "уыдрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that other",
        "examples": [
          {
            "sentence": "'''уыдрэ''' бэнанэ",
            "translation": "'''that other''' banana"
          },
          {
            "sentence": "'''уыдрэ''' пшъашъэ",
            "translation": "'''that other''' girl"
          },
          {
            "sentence": "'''уыдрэ''' к1алэм е1уэ",
            "translation": "'''that other''' boy is saying"
          },
          {
            "sentence": "'''уыдрэ''' мэгъэ",
            "translation": "'''that other''' year"
          }
        ]
      }
    ],
    "type": "det"
  },
  {
    "word": "уыдрэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(absolutive case) that other",
        "examples": [
          {
            "sentence": "'''уыдрэр''' мак1о",
            "translation": "'''that other''' is going"
          },
          {
            "sentence": "'''уыдрэр''' дахэ",
            "translation": "'''that other''' is pretty"
          }
        ],
        "tags": [
          "absolutive case"
        ]
      }
    ],
    "type": "pron"
  },
  {
    "word": "гьары",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джары\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ɡʲaːrə"
  },
  {
    "word": "лъэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pray, to beg",
        "examples": [
          {
            "sentence": "тхьэм '''елъэ1у''' нэхь 1эш1эхыу къупфиш1ныу",
            "translation": "'''Prey/beg''' god to make it easier for you."
          },
          {
            "sentence": "к1алэр тхьэм '''елъэ1о''' псэоныу",
            "translation": "The boy '''is preying/begging''' god to stay healthy."
          },
          {
            "sentence": "к1алэр иятэ '''елъэ1о''' джэгуалъэ къыфищэфныу",
            "translation": "The boy '''is begging''' his father to buy him a toy."
          },
          {
            "sentence": "'''усэлъэ1у''' ащтыу умыш1",
            "translation": "'''I am begging you''' not to do that."
          }
        ]
      }
    ],
    "type": "verb"
  },
  {
    "word": "цумпэ1эрыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strawberry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʷəmpaʔarəs"
  },
  {
    "word": "цыулэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(childish) penis",
        "examples": [],
        "tags": [
          "childish"
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡səwla"
  },
  {
    "word": "хьай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shit",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "гъутхьэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "urine, piss",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ащтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "like that",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːʃto"
  },
  {
    "word": "мэшто",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "like this",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "maʃto"
  },
  {
    "word": "чэщныкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "midnight",
        "examples": [
          {
            "sentence": "'''чэщныкъом''' тк1от йуропа",
            "translation": "'''In midnight''', we will go to Europe."
          },
          {
            "sentence": "'''чэщныкъом''' томэр къэк1уагъ",
            "translation": "'''In midnight''' Tom came."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕənəqʷa"
  },
  {
    "word": "чэщым еныу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "overnight, all night",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "t͡ʃaɕəm janəw"
  },
  {
    "word": "енэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "always",
        "examples": [
          {
            "sentence": "'''Енэу''' тиунэ укъэк1ота?",
            "translation": "Will you '''always''' come to my house?"
          },
          {
            "sentence": "К1алэр '''енэу''' мач1э",
            "translation": "The boy '''always''' runs."
          }
        ]
      }
    ],
    "synonyms": [
      "ренэу"
    ],
    "type": "adv",
    "ipa": "janaw"
  },
  {
    "word": "ебгъэчэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slide someone or something, to made something or someone slide",
        "examples": [
          {
            "sentence": "псым к1алэр '''регъэчэхы'''",
            "translation": "'''he is sliding''' the boy in the water"
          }
        ]
      },
      {
        "meaning": "to insult someone, to offend someone",
        "examples": [
          {
            "sentence": "к1алэр нэпэ '''епгъэчэхэгъ'''",
            "translation": "today, '''you insulted''' the boy"
          },
          {
            "sentence": "сэ ахэмэ '''срагъэчэхы'''",
            "translation": "they are '''insulting me'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jabʁat͡ʃaxən"
  },
  {
    "word": "мыжъодэбыш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "basalt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məʐʷadaməʃ"
  },
  {
    "word": "къолагьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къоладжэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷalaːɡʲa"
  },
  {
    "word": "гын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гыны\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɣən"
  },
  {
    "word": "тхьэм бытырбыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "go to hell",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "tħam bətərbəf"
  },
  {
    "word": "бырсыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clamor",
        "examples": []
      },
      {
        "meaning": "disturbance",
        "examples": []
      },
      {
        "meaning": "riot",
        "examples": []
      },
      {
        "meaning": "roistering",
        "examples": []
      },
      {
        "meaning": "trouble",
        "examples": []
      },
      {
        "meaning": "crowded",
        "examples": [
          {
            "sentence": "гъогуэр '''бырсыр''' нэпэ",
            "translation": "Today, the road is '''crowded'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bərsər"
  },
  {
    "word": "бырсырыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boisterous",
        "examples": []
      },
      {
        "meaning": "rioter",
        "examples": []
      },
      {
        "meaning": "troublemaker",
        "examples": []
      },
      {
        "meaning": "boisterous",
        "examples": []
      },
      {
        "meaning": "rioter",
        "examples": []
      },
      {
        "meaning": "troublemaker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bərsərəl"
  },
  {
    "word": "тхьэл1ык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prophet",
        "examples": []
      }
    ],

    "cognate": "бегъымбар",
    "type": "noun",
    "ipa": "tħaɬˤəkʷʼ"
  },
  {
    "word": "нэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нахь\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "naħ"
  },
  {
    "word": "ибэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "orphan",
        "examples": []
      },
      {
        "meaning": "most (people, objects)",
        "examples": [
          {
            "sentence": "'''ибэм''' и1уэрэ гьары1",
            "translation": "That's what '''most people''' say."
          },
          {
            "sentence": "'''ибэр''' 1офым мак1орэп",
            "translation": "'''Most people''' don't go to work."
          }
        ]
      },
      {
        "meaning": "most",
        "examples": [
          {
            "sentence": "к1алэ '''ибэ''' уиунэ къак1о",
            "translation": "'''Most of''' the boys are coming to your house."
          },
          {
            "sentence": "'''ибэм''' уиунэ ткъак1о",
            "translation": "We come to your house '''mostly'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "jəba"
  },
  {
    "word": "сэпсэуэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "everyone, everybody",
        "examples": [
          {
            "sentence": "'''сэпсэуым''' и1уагъ",
            "translation": "'''everybody''' said."
          },
          {
            "sentence": "'''сэпсэуэр''' къэк1от шъуиунэ",
            "translation": "'''everyone''' will come to your house."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "sapsawa"
  },
  {
    "word": "къуао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bucket",
        "examples": []
      }
    ],
    "synonyms": [
      "щалъ"
    ],
    "type": "noun",
    "ipa": "qʷaːwa"
  },
  {
    "word": "къуамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къутамэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "куамэ",
      "къутамэ"
    ],
    "cognate": "гуанэ",
    "type": "noun",
    "ipa": "qʷaːma"
  },
  {
    "word": "заулэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "several",
        "examples": [
          {
            "sentence": "К1элэ '''заулэ''' тиунэ къэк1уагъ",
            "translation": "'''Several''' boys came to our house."
          },
          {
            "sentence": "Мы1эрыс '''заулэ''' матэм илъ",
            "translation": "There is '''several''' apples in the basket."
          },
          {
            "sentence": "Л1ы '''заулэ''' къызэ1ук1агъ",
            "translation": "'''Several''' men met."
          },
          {
            "sentence": "Тхыдэжъ '''заулэ''' къысфэ1уат",
            "translation": "Tell me '''some''' stories."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zaːwla"
  },
  {
    "word": "ш1упщы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a man's father-in-law: his wife's father",
        "examples": [
          {
            "sentence": "си '''шъупщы''' къэк1уагъ",
            "translation": "My '''father-in-law''' came."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂʷəpɕə"
  },
  {
    "word": "ш1угуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a man's mother-in-law: his wife's mother",
        "examples": [
          {
            "sentence": "си '''шъугуащэ''' къэк1уагъ",
            "translation": "My '''mother-in-law''' came."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂʷəɡʷaːɕa"
  },
  {
    "word": "нэк1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "let's",
        "examples": [
          {
            "sentence": "'''нэк1о''' тк1от",
            "translation": "'''Let's''' go."
          },
          {
            "sentence": "'''нэк1о''' ткъэщхэт",
            "translation": "'''Let's''' eat."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "nakʷʼa"
  },
  {
    "word": "хьатыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "respect",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːtər"
  },
  {
    "word": "шыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "steel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃər"
  },
  {
    "word": "сабый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "child",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːbəj"
  },
  {
    "word": "мастэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "needle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːsta"
  },
  {
    "word": "хьэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pill, drug",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħab"
  },
  {
    "word": "хьэблыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "worm",
        "examples": []
      }
    ],
    "synonyms": [
      "хьамлыу"
    ],
    "cognate": "хьэмбылыу",
    "type": "noun",
    "ipa": "ħabləw"
  },
  {
    "word": "хьац1эбац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьацэп1ацэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хьацэп1ацэ"
    ],
    "type": "noun",
    "ipa": "ħaːt͡sˤʼabaːt͡sˤʼa"
  },
  {
    "word": "шыдкъолэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "zebra",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃədqʷalan"
  },
  {
    "word": "гъуанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hole",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaːna"
  },
  {
    "word": "благъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "near",
        "examples": [
          {
            "sentence": "К1алэм '''иблагъэу''' 1унк1ыбзэр 1улъ",
            "translation": "The key is '''near''' the boy."
          },
          {
            "sentence": "Сиунэ '''благъэу''' 1утэп",
            "translation": "My house is not '''near'''."
          }
        ]
      },
      {
        "meaning": "close friend",
        "examples": []
      },
      {
        "meaning": "relative",
        "examples": []
      },
      {
        "meaning": "(Kfar Kama dialect slang) romantic partner (boyfriend or girlfriend)",
        "examples": [],
        "tags": [
          "Kfar Kama dialect",
          "slang"
        ]
      }
    ],
    "type": "adj",
    "ipa": "blaːʁa"
  },
  {
    "word": "гъусэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Kfar Kama dialect) friend",
        "examples": [
          {
            "sentence": "'''Уигъусэ''' уиунэ къак1о",
            "translation": "'''Your friend''' is coming to your house."
          },
          {
            "sentence": "'''Сигъусэ''' горэ сэлъагъу",
            "translation": "I am seeing some '''friend of mine'''."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''згъусэх'''",
            "translation": "The boy and the girl are '''friends'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      },
      {
        "meaning": "associate",
        "examples": []
      },
      {
        "meaning": "comrade",
        "examples": []
      },
      {
        "meaning": "teammate, one that is in the same group or team.",
        "examples": [
          {
            "sentence": "Томыр сэ '''сигъус'''",
            "translation": "Tom '''is my teammate'''."
          }
        ]
      },
      {
        "meaning": "companion, fellow, accompanier, one that is following or accompanying (for example fellow traveller)",
        "examples": [
          {
            "sentence": "'''Гъусэм''' ущыгугъэу мышъэм уемыбэн",
            "translation": "When fight a bear when you're hoping for '''a companion'''."
          }
        ]
      },
      {
        "meaning": "partner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷəsa"
  },
  {
    "word": "ныбжьэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A peer, an age-mate: a person of approximately the same age as another.",
        "examples": [
          {
            "sentence": "'''Уиныбжьэгъухэр''' уиунэ къак1ох",
            "translation": "'''Your peers''' are coming to your house."
          },
          {
            "sentence": "'''Синыбжьэгъу''' горэ сэлъагъу",
            "translation": "I am seeing a '''peer of mine'''."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэныбжьэгъух'''",
            "translation": "The boy and the girl are '''peers'''."
          },
          {
            "sentence": "К1алэр '''синыбжьэгъу'''",
            "translation": "The boy '''is my peer'''."
          }
        ]
      }
    ],
    "synonyms": [
      "лэгъу"
    ],
    "type": "noun",
    "ipa": "nəbʑaʁʷ"
  },
  {
    "word": "гъунэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neighbor",
        "examples": [
          {
            "sentence": "'''уигъунэгъухэр''' уиунэ къак1ох",
            "translation": "'''Your neighbors''' are coming to your house."
          },
          {
            "sentence": "'''сигъунэгъу''' горэ сэлъагъу",
            "translation": "I am seeing some '''neighbor of mine'''."
          },
          {
            "sentence": "к1алэмрэ пшъашъэмрэ '''згъунэгъух'''",
            "translation": "The boy and the girl are '''neighbors'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷənaʁʷ"
  },
  {
    "word": "нэутх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "diligent, well-behaved; industrious",
        "examples": [
          {
            "sentence": "уикъо '''нэутх'''1",
            "translation": "Your son (your boy) '''is diligent'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "nawətx"
  },
  {
    "word": "янэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "most, most among them",
        "examples": [
          {
            "sentence": "кIалэр '''янэхь''' бау",
            "translation": "The boy is the '''most''' rich (richest)."
          },
          {
            "sentence": "пшъашъэ '''янэхь''' дахэм еIуэ",
            "translation": "The '''most''' beautiful (prettiest) girl says."
          },
          {
            "sentence": "'''янэхь''' пыт мэр",
            "translation": "This is the '''most''' hard (hardest)."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaːnaħ"
  },
  {
    "word": "тхьэпк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leaf",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьапэ"
    ],
    "type": "noun",
    "ipa": "tħapt͡ʃa"
  },
  {
    "word": "тгъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тыгъуак1о\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʁʷaːkʷʼa"
  },
  {
    "word": "дэшхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dinner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daʃxən"
  },
  {
    "word": "унэч1эгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "under the house, lower part of a house",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wənat͡ʃʼaʁ"
  },
  {
    "word": "машинэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "car",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːʃina"
  },
  {
    "word": "ашъоцуакъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Leather shoe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʂʷat͡sʷaːɡʷ"
  },
  {
    "word": "ащгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that time",
        "examples": [
          {
            "sentence": "'''Ащгъум''' укъысэджэшъутэгъ",
            "translation": "'''That time''' you could called me."
          }
        ]
      },
      {
        "meaning": "then",
        "examples": [
          {
            "sentence": "'''Ащгъум''' сыд тыш1эта?",
            "translation": "'''Then''' what we gonna do?"
          },
          {
            "sentence": "'''ащгъум''' нэк1о уиунэ тгъак1у",
            "translation": "'''Then''' let's go to your house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːɕʁʷəm"
  },
  {
    "word": "а1уе",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dahlia",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "а1ууц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lily",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʔʷəwət͡s"
  },
  {
    "word": "абадз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Abaza (Circassian clan)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbaːd͡z"
  },
  {
    "word": "абан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hole",
        "examples": []
      }
    ],
    "synonyms": [
      "гъуанэ"
    ],
    "type": "noun",
    "ipa": "aːbaːn"
  },
  {
    "word": "хъурай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "circle",
        "examples": []
      }
    ],

    "cognate": "хъурей",
    "type": "noun",
    "ipa": "χʷəraːj"
  },
  {
    "word": "абас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(historical) abbasi (Persian coin)",
        "examples": [],
        "tags": [
          "historical"
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːbaːs"
  },
  {
    "word": "абдзах",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Abdzakh (Circassian clan)",
        "examples": []
      }
    ],
    "synonyms": [
      "эбзах"
    ],
    "type": "noun",
    "ipa": "aːbd͡zaːx"
  },
  {
    "word": "абрагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "large, very big",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːbraːʁʷ"
  },
  {
    "word": "абрамыжъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hard rock (Legendary rock mentioned in the Nart saga)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbraːməʐʷ"
  },
  {
    "word": "абрадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lone hero",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbraːd͡ʒ"
  },
  {
    "word": "абхъаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Abkhaz (Circassian clan)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbχaːz"
  },
  {
    "word": "абыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bread of Circassian horses",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbəqʷ"
  },
  {
    "word": "абэгъоплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "An old work: one that is cultural, heritage, hierarchical, inherited.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbaʁʷapɬ"
  },
  {
    "word": "абэдзэкъуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mallow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːbad͡zaqʷaːj"
  },
  {
    "word": "агощ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(mathematics) numerator, dividend",
        "examples": [],
        "tags": [
          "mathematics"
        ]
      },
      {
        "meaning": "divided",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "агощырэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(mathematics) numerator, dividend",
        "examples": [],
        "tags": [
          "mathematics"
        ]
      },
      {
        "meaning": "divided",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ибзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "The middle of (something).",
        "examples": [
          {
            "sentence": "унэм '''ибзэгу''' анэ ит",
            "translation": "In the '''middle of''' the house, there is a table."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "jəvzaɡʷ"
  },
  {
    "word": "ябзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Their middle, the middle of (two or more things).",
        "examples": [
          {
            "sentence": "к1алэмрэ пшъашъэмрэ '''ябзгу''' анэ илъ",
            "translation": "Between the boy and the girl there is a table."
          },
          {
            "sentence": "унэхэмэ '''ябзэгу''' анэ ит",
            "translation": "In the '''middle of''' the houses, there is a table."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jaːvzaɡʷ"
  },
  {
    "word": "агурджашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mullet (fish)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːɡʷərd͡ʒaːʂʷ"
  },
  {
    "word": "агъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mistletoe, wild olive",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʁʷa"
  },
  {
    "word": "агъунэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "edge, margin, side",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʁʷənar"
  },
  {
    "word": "аддэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"адэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːdda"
  },
  {
    "word": "аджал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "death",
        "examples": []
      }
    ],

    "cognate": "ажал",
    "type": "adv",
    "ipa": "aːd͡ʒaːl"
  },
  {
    "word": "аджар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Ajar (a Caucasian people)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːd͡ʒaːr"
  },
  {
    "word": "адрабгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "opposite side, other side",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːdəraːbʁʷ"
  },
  {
    "word": "адыгагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian conduct code",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːdəɣaːʁ"
  },
  {
    "word": "ч1ыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "roots (land)",
        "examples": [
          {
            "sentence": "адыгэ '''ч1ыр'''",
            "translation": "Circassian roots (land)."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼər"
  },
  {
    "word": "адыгэаму",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coriander",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "адыгэкъэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Sweet pumpkin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːdəɣaqab"
  },
  {
    "word": "адырабгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"адрабгъу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːdəraːbʁʷ"
  },
  {
    "word": "адэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cancer (illness)",
        "examples": []
      },
      {
        "meaning": "over there (Invisible, Far)",
        "examples": []
      },
      {
        "meaning": "sure, of course (expresses confidence)",
        "examples": [
          {
            "sentence": "'''Адэ''' тык1от нахь",
            "translation": "'''Sure''' we will go."
          }
        ]
      },
      {
        "meaning": "well (expresses confidence)",
        "examples": [
          {
            "sentence": "'''Адэ''', Пщымаф, гущы1эу птыгъэр гъцэ1эжьба.",
            "translation": "\"Well, Pshimaf, you must keep your word\"."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːda"
  },
  {
    "word": "адэбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cancer, tumor (malignant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːdabz"
  },
  {
    "word": "адэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tumor (benign)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːdaχʷ"
  },
  {
    "word": "азан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Islam) adhan (call to prayer)",
        "examples": [],
        "tags": [
          "Islam"
        ]
      }
    ],
    "type": "noun",
    "ipa": "/aːzaːn/"
  },
  {
    "word": "азыфагу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "between",
        "examples": [
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''азыфаго''' 1ут 1анэ",
            "translation": "'''Between''' the boy and the girl there is a table."
          },
          {
            "sentence": "Ц1ыфыхэмэ '''азыфагок1э''' блэк1",
            "translation": "Pass the people '''in between them'''."
          },
          {
            "sentence": "Анахь псынк1эу ду чъыгыт1ум '''азыфаго''' блэк1рэм егъапшэ",
            "translation": "The one that most quickly passes '''between''' those two trees is the winner."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːzəfaːɡʷ"
  },
  {
    "word": "азэнадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "muezzin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːzanaːd͡ʒ"
  },
  {
    "word": "азэнаджап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "minaret",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːzanaːd͡ʒaːpˤ"
  },
  {
    "word": "акъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wind (southern)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːqʷəʐ"
  },
  {
    "word": "акъуш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bear cub",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːqʷəʃ"
  },
  {
    "word": "акъылыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "reasonable",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːqələʁʷ"
  },
  {
    "word": "Алахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Allah",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːlˤaːħ"
  },
  {
    "word": "алашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drawing horse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːlaːʃa"
  },
  {
    "word": "шак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hunter",
        "examples": []
      },
      {
        "meaning": "honeycomb",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃaːkʷʼa"
  },
  {
    "word": "алмэстышъхьэц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ivy, lablab",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "алтэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Atlas silk",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "алыркъэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pumpkin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːlərqab"
  },
  {
    "word": "алырэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carpet",
        "examples": []
      },
      {
        "meaning": "rug",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːləraʁʷ"
  },
  {
    "word": "алырэгъуш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carpet maker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːləraʁʷʃʼ"
  },
  {
    "word": "алэдышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "diamond (brilliant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːladəʂ"
  },
  {
    "word": "амал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "method; ability; the mean to do something; a way to do something",
        "examples": []
      },
      {
        "meaning": "help, assistance, aid",
        "examples": []
      },
      {
        "meaning": "hope",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːmaːl"
  },
  {
    "word": "амдэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ablution (with Muslims)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːmdaz"
  },
  {
    "word": "амк1ышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nightingale (a bird)",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "амылкъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "white cotton cloth",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːməlqaːn"
  },
  {
    "word": "амыш1эр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unknown, unseen",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːməʃʼar"
  },
  {
    "word": "анай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "maple, Maple tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnaːj"
  },
  {
    "word": "анахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "most",
        "examples": [
          {
            "sentence": "Сэтэнае шъузмэ '''анахь''' дах",
            "translation": "Sataney is the '''most''' beautiful among the women."
          },
          {
            "sentence": "Мы к1алэр чылэмк1э '''анахь''' бай",
            "translation": "This young man is the '''most''' rich in our village."
          },
          {
            "sentence": "Студент '''анахь''' дэгъухэр мыу къак1ох",
            "translation": "The best students came here. Literally: The '''most''' good students come here."
          },
          {
            "sentence": "Тары джэнэ '''анахь''' дахэу уи1эр?",
            "translation": "'''Which''' shirt is the most '''beautiful''' you have."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːnaːħ"
  },
  {
    "word": "анджэрэф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "broomcorn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnd͡ʒaraf"
  },
  {
    "word": "анджэрэф шъоу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sugarcane",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnd͡ʒaraf ʂʷaw"
  },
  {
    "word": "антыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clematis, jasmine",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːntər"
  },
  {
    "word": "нэк1ымазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ramadan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nat͡ʃ maːza"
  },
  {
    "word": "анэбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blackbird",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "anabzəw"
  },
  {
    "word": "анэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(anatomy) birthmark, mole, melanism, nevus (naevus) (dark spot on the skin)",
        "examples": [],
        "tags": [
          "anatomy"
        ]
      }
    ],
    "synonyms": [
      "лыпц1э"
    ],
    "type": "noun",
    "ipa": "aːnal"
  },
  {
    "word": "анэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "partridge (Bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnaw"
  },
  {
    "word": "апс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dish, plate, saucer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːps"
  },
  {
    "word": "апскурб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bowl",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "апк1мыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crystal rock",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːpkʲməʐʷ"
  },
  {
    "word": "апк1халъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glass installer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːpkʲxaːɬħ"
  },
  {
    "word": "апк1хьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lens",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːpkʲħan"
  },
  {
    "word": "апк1шъхьэтэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lamp",
        "examples": []
      }
    ],
    "synonyms": [
      "остыгъ"
    ],
    "type": "noun",
    "ipa": "aːpkʲʂħatat"
  },
  {
    "word": "апшъэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "highest; supreme, high (dominant)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːpʂar"
  },
  {
    "word": "апшъэрэ еджап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "higher education, university",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "апэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "at first",
        "examples": [
          {
            "sentence": "'''Апэрэ''' ц1ыфыр Адэм",
            "translation": "'''The first''' person was Adam."
          },
          {
            "sentence": "'''Апэрэм''' сыкъилъэгъугъ",
            "translation": "'''The first one''' was me."
          }
        ]
      },
      {
        "meaning": "in front",
        "examples": [
          {
            "sentence": "'''Апэ''' итыр сик1ал",
            "translation": "The one standing '''in front''' is my child."
          },
          {
            "sentence": "К1алэр '''апэ''' ит",
            "translation": "The boy is standing '''in front''' of them."
          },
          {
            "sentence": "Ц1ыфыхэм '''апэ''' умыуцу",
            "translation": "Don't stand '''in front''' of people."
          }
        ]
      },
      {
        "meaning": "at the beginning",
        "examples": [
          {
            "sentence": "Фылым '''апэрэ''' дэгъоу къежьагъ, ау ит1уанэ дэеу хъужьыгъ",
            "translation": "'''The beginning''' of the film started good, but it became bad later."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːpa"
  },
  {
    "word": "апэрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"япэрэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːpara"
  },
  {
    "word": "апэрэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firstly, for the first time",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːparaw"
  },
  {
    "word": "хэгъэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "country",
        "examples": []
      },
      {
        "meaning": "state",
        "examples": []
      }
    ],

    "cognate": "къэрал",
    "type": "noun",
    "ipa": "xaʁaɡʷ"
  },
  {
    "word": "аргъой",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mosquito",
        "examples": []
      }
    ],

    "cognate": "аргъуей",
    "type": "noun",
    "ipa": "aːrʁʷaj"
  },
  {
    "word": "аргъойпа1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mosquito net",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrʁʷajaːʔʷ"
  },
  {
    "word": "аргъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "salmon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrʁaj"
  },
  {
    "word": "аргъэйфыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "caviar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrʁajfəɡʷ"
  },
  {
    "word": "арджан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrd͡ʒaːn"
  },
  {
    "word": "армэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bummer",
        "examples": []
      },
      {
        "meaning": "loafer",
        "examples": []
      },
      {
        "meaning": "lazy",
        "examples": []
      }
    ],
    "synonyms": [
      "фэмыф"
    ],
    "cognate": "арму",
    "type": "adj",
    "ipa": "aːrmaw"
  },
  {
    "word": "армэугъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dullness, laziness, uselessness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrmawʁ"
  },
  {
    "word": "архъожъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boa constrictor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrχʷaʐ"
  },
  {
    "word": "архъуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vortex",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrχʷaːn"
  },
  {
    "word": "арыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Artificial channel; brook; irrigation ditch; ravine; rivulet.",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "арышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "therefor",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːrəʂ"
  },
  {
    "word": "арышъхьае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "but, however",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːrəʂħaːja"
  },
  {
    "word": "арыти",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"арти\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːrəti"
  },
  {
    "word": "арэущтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and so",
        "examples": [
          {
            "sentence": "К1алэр бэрэ еджагъ, '''арэущтэу''' экзамыныр дэгъу ыш1ыгъ",
            "translation": "The boy studied a lot, '''and so''' he did the exam well."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːrawɕtaw"
  },
  {
    "word": "аслъаншыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lion cub",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːsɬaːnʃər"
  },
  {
    "word": "аужым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "at last",
        "examples": [
          {
            "sentence": "'''Аужым''' ит к1алэр",
            "translation": "The boy is standing '''last'''."
          },
          {
            "sentence": "Фылымым '''иаужырэ''' дакъэм твист и1агъ",
            "translation": "The '''last''' minute of the film had a twist."
          }
        ]
      },
      {
        "meaning": "in the end",
        "examples": [
          {
            "sentence": "'''Аужырэм''' теплъыжьыгъэп фылымым",
            "translation": "'''In the end''' we didn't watch the film."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːwʒəm"
  },
  {
    "word": "Афы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Afe: the pagan Circassian god of thunder",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːfə"
  },
  {
    "word": "ахъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Ouch, ach: an expression of pain.",
        "examples": [
          {
            "sentence": "'''ахъ!''', мэузы ар",
            "translation": "'''Ouch''', that hurt."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "aːχ"
  },
  {
    "word": "ахъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fig",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʷə"
  },
  {
    "word": "ахъчапс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "miser, stingy person",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːχt͡ʃaːps"
  },
  {
    "word": "ахъчашъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "capital",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃaːʂħ"
  },
  {
    "word": "ахъчэбэг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "inflation",
        "examples": []
      },
      {
        "meaning": "(finance) inflation",
        "examples": [],
        "tags": [
          "finance"
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃabaɣ"
  },
  {
    "word": "ахъчэбэдзэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stock market",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃabad͡zar"
  },
  {
    "word": "ахъчэдэфый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A coin toss, a coin flip.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃadafəj"
  },
  {
    "word": "ахъчэжъгъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small money",
        "examples": []
      },
      {
        "meaning": "coin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃaʐʁaj"
  },
  {
    "word": "зэблэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to replace, to change something with something else",
        "examples": [
          {
            "sentence": "Илъэс къэсы мэлажэхэрэ '''зэблэхъух'''",
            "translation": "Every year the people that are working '''are changing'''."
          },
          {
            "sentence": "К1алэм имащинэ '''зиблыхъогъ'''",
            "translation": "The boy '''has replaced''' his car."
          },
          {
            "sentence": "'''Зэблэхъу''' мы 1анэ убэтагъэ",
            "translation": "'''Replace''' this broken table."
          },
          {
            "sentence": "Пхъэнт1эк1оу узисэ '''зэблэпхъурагъуа?'''",
            "translation": "'''Do you wanna replace''' the chair you are sitting in?"
          },
          {
            "sentence": "Къэгъагъэм ишъо '''зэблэхъу'''",
            "translation": "The flower's color '''changes'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaplaχʷən"
  },
  {
    "word": "ахъчэзэблэхъущ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Exchange office, bank",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃazablaχʷən"
  },
  {
    "word": "ахъчэмык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "counterfeit money",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃaməkʷʼ"
  },
  {
    "word": "ахъчэнэпц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Forfeited money",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃanapt͡sʼ"
  },
  {
    "word": "ахъчэнэпц1ш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forfeiter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃanapt͡sʼʃʼ"
  },
  {
    "word": "ахын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "aːxən"
  },
  {
    "word": "ачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "billy goat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːtʂa"
  },
  {
    "word": "ачъэгъашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "actor, comedian",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːtʂaʁaːʂʷ"
  },
  {
    "word": "ачъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ibex",
        "examples": []
      }
    ],
    "synonyms": [
      "бжъаб"
    ],
    "type": "noun",
    "ipa": "aːχt͡ʃaʐ"
  },
  {
    "word": "аш1омыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "absurd, contemptible, trivial",
        "examples": []
      }
    ],
    "type": "adj"
  },
  {
    "word": "аш1эгъудж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crop, harvest, produce, yield",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʃʼaʁʷəd͡ʒ"
  },
  {
    "word": "ашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chain mail, hauberk",
        "examples": [
          {
            "sentence": "'''Ашъор''' щыгъ, мэ1ур ы1ыгъ",
            "translation": "He wears the '''chain mail''', he holds the shield."
          }
        ]
      },
      {
        "meaning": "armor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːʂʷa"
  },
  {
    "word": "ашъок1ад",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leather, sole",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ашъоку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tank (armoured fighting vehicle)",
        "examples": [],
        "tags": [
          "armoured fighting vehicle"
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːʂʷakʷ"
  },
  {
    "word": "ч1ып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "place",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂʼəpʼa"
  },
  {
    "word": "бгъодэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "side",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷadad"
  },
  {
    "word": "мыжъок1ашъхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gravel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məʐʷakʲaːʂχ"
  },
  {
    "word": "купсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "The soft internal portion of bread which is surrounded by crust; the crumb. (Compare the German term Krume and the French term mie.)",
        "examples": [],
        "tags": [
          "Compare the German term Krume and the French term mie."
        ]
      },
      {
        "meaning": "middle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷapsa"
  },
  {
    "word": "къурнэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷərna"
  },
  {
    "word": "къуахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pitchfork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːχʷa"
  },
  {
    "word": "цыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tip (the extreme end of something, especially when pointed; e.g. the sharp end of a pencil)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡səpa"
  },
  {
    "word": "зы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "zə"
  },
  {
    "word": "т1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "two",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼə"
  },
  {
    "word": "щы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "three",
        "examples": []
      }
    ],
    "synonyms": [
      "шъы",
      "----"
    ],
    "type": "num",
    "ipa": "ɕə"
  },
  {
    "word": "пл1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "four",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "pɬʼə"
  },
  {
    "word": "тфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "five",
        "examples": []
      }
    ],

    "cognate": "тху",
    "type": "num",
    "ipa": "tfə"
  },
  {
    "word": "блы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seven",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "blə"
  },
  {
    "word": "бгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "side",
        "examples": [
          {
            "sentence": "Къэтыс '''сибгъук1э'''",
            "translation": "Sit '''by my side'''."
          },
          {
            "sentence": "Дэпкъым '''ибгъук1э''' блэк1",
            "translation": "Pass the wall '''by its side'''."
          }
        ]
      },
      {
        "meaning": "nine",
        "examples": []
      },
      {
        "meaning": "wide",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷə"
  },
  {
    "word": "пш1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ten",
        "examples": []
      }
    ],

    "cognate": "пщ1ы",
    "type": "num",
    "ipa": "pʃʼə"
  },
  {
    "word": "т1ок1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twenty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃə"
  },
  {
    "word": "щэк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thirty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "ɕat͡ʃə"
  },
  {
    "word": "т1ок1ит1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃitʷʼ"
  },
  {
    "word": "шъэнэкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fifty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "ʂanaqʷa"
  },
  {
    "word": "т1ок1ищ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sixty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃiɕ"
  },
  {
    "word": "т1ок1ищырэ пш1ырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seventy",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃiɕra pʃʼəra"
  },
  {
    "word": "т1ок1ипл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eighty",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃipɬʼ"
  },
  {
    "word": "т1ок1ипл1ырэ пш1ырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ninety",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tʷʼat͡ʃipɬʼəra pʃʼəra"
  },
  {
    "word": "шъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hundred",
        "examples": []
      }
    ],

    "cognate": "щэ",
    "type": "num",
    "ipa": "ʂa"
  },
  {
    "word": "шъит1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "two hundred",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "ʂitʷʼ"
  },
  {
    "word": "шъищ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "three hundred",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "ʂiɕ"
  },
  {
    "word": "ныкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "half",
        "examples": []
      }
    ],

    "cognate": "ныкъуэ",
    "type": "num",
    "ipa": "nəqʷa"
  },
  {
    "word": "минит1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "two thousand",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "minitʷʼ"
  },
  {
    "word": "шэнчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шэнычъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃant͡ʂa"
  },
  {
    "word": "плэлэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пэлэлэжъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "plˠalˠaʐ"
  },
  {
    "word": "емынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plague",
        "examples": [
          {
            "sentence": "'''Емынэ''' елъэгъужьын",
            "translation": "May (s)he get a '''plague'''; literary: May (s)he see a plague."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) (slang) a lot (of)",
        "examples": [
          {
            "sentence": "К1алэм ахъчэ '''емынэ''' и1",
            "translation": "The boy has '''a lot of''' money."
          },
          {
            "sentence": "К1алэ '''емынэ''' е1о ар",
            "translation": "'''a lot''' of boys say that."
          },
          {
            "sentence": "пшъашъэ '''емынэ''' уи1",
            "translation": "You have '''a lot of''' girls."
          }
        ],
        "tags": [
          "Kfar Kama dialect",
          "slang"
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaməna"
  },
  {
    "word": "цу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ox",
        "examples": []
      },
      {
        "meaning": "bull (Animal)",
        "examples": []
      },
      {
        "meaning": "buffalo",
        "examples": []
      }
    ],
    "synonyms": [
      "чъу"
    ],
    "cognate": "вы",
    "type": "noun",
    "ipa": "t͡ʃʷə"
  },
  {
    "word": "пэцуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "booger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pat͡ʃʷəj"
  },
  {
    "word": "иныжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "giant (huge mythical creature)",
        "examples": [],
        "tags": [
          "huge mythical creature"
        ]
      }
    ],
    "type": "noun",
    "ipa": "jənəʐ"
  },
  {
    "word": "дэгъналъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clothespin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daʁnaːɬa"
  },
  {
    "word": "куу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deep",
        "examples": [
          {
            "sentence": "Хыр '''куу'''",
            "translation": "The sea '''is deep'''."
          },
          {
            "sentence": "'''Куоу''' ухэмыхь",
            "translation": "Don't go '''deeply'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "kʷəwə"
  },
  {
    "word": "псао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псэу\"",
        "examples": []
      }
    ],

    "cognate": "псо",
    "type": "adj",
    "ipa": "psaːwa"
  },
  {
    "word": "шэзгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strained yoghurt, yoghurt cheese, labneh",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃazʁa"
  },
  {
    "word": "гъычгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "laundry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁət͡ʃʁa"
  },
  {
    "word": "гъуч11унэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъуч1ы1унэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷət͡ʂʼəʔʷəna"
  },
  {
    "word": "нашъухъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нашхъо\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naːʂʷχʷa"
  },
  {
    "word": "адыгэхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plural of \"адыгэ\"",
        "examples": [],
        "tags": [
          "plural"
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːdəɣaxa"
  },
  {
    "word": "бгъакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arrogant",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʁaːqa"
  },
  {
    "word": "бгъалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blouse, bra, corset",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaːɬa"
  },
  {
    "word": "бгъашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "falcon",
        "examples": []
      },
      {
        "meaning": "hawk",
        "examples": []
      }
    ],
    "synonyms": [
      "бгъашъухъо"
    ],
    "type": "noun",
    "ipa": "bʁaːʃχʷa"
  },
  {
    "word": "бгъодж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prairie, steppe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷad͡ʒ"
  },
  {
    "word": "шъык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shape",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂəkʲa"
  },
  {
    "word": "бгъодэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go away from the side of something",
        "examples": [
          {
            "sentence": "'''Къысбгъодэк1'''.",
            "translation": "'''Go away from me'''."
          },
          {
            "sentence": "Унэм '''къыбгъодэк1и''' къак1у.",
            "translation": "'''Get away from the side''' of the house and come."
          }
        ]
      }
    ],
    "synonyms": [
      "гок1ын"
    ],
    "type": "verb",
    "ipa": "bʁʷadat͡ʃʼən"
  },
  {
    "word": "ба1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chatty, talkative",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baːʔʷa"
  },
  {
    "word": "бадзэпсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "feeble, frail, very weak",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːd͡zapsa"
  },
  {
    "word": "бадэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an almond",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːda"
  },
  {
    "word": "баигъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fortune, riches, wealth",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːjəʁ"
  },
  {
    "word": "бэежъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very rich",
        "examples": [
          {
            "sentence": "Л1ыр '''бэежъ'''",
            "translation": "The man '''is very rich'''."
          },
          {
            "sentence": "Л1ы '''бэежъэр''' мак1о",
            "translation": "'''The very rich''' man is going."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "bajaʐ"
  },
  {
    "word": "бэйкол",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "entourage (of a king), escort",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːkʷal"
  },
  {
    "word": "бэйкъогъуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cockroach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːjqʷaʁʷəz"
  },
  {
    "word": "бакъмэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "full; having eaten enough, having eaten to satisfaction",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baːqmar"
  },
  {
    "word": "бамык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tick (an arachnid)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːmək"
  },
  {
    "word": "ебэнын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэнэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jabanən"
  },
  {
    "word": "мэгъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁən"
  },
  {
    "word": "баргъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a bang (the sound of a shot or impact)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːrʁ"
  },
  {
    "word": "басмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cotton cloth, Print cloth",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːsma"
  },
  {
    "word": "батыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hero",
        "examples": []
      },
      {
        "meaning": "strong, very strong",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːtər"
  },
  {
    "word": "батыргъэгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mustard",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːtərʁaʁ"
  },
  {
    "word": "батыргъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hogweed (Heracleum, any of a group of umbelliferous plants, some of which are poisonous)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːtərʁan"
  },
  {
    "word": "батырыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heroism",
        "examples": []
      },
      {
        "meaning": "strength",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːtərəʁ"
  },
  {
    "word": "баш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "diligent, hard-working",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baːʃʼa"
  },
  {
    "word": "башлыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːʃləq"
  },
  {
    "word": "бащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "too much, very much, too many",
        "examples": [
          {
            "sentence": "к1алэм ахъчэ '''бащэ''' и1",
            "translation": "The boy has '''too much''' money."
          },
          {
            "sentence": "'''бащэрэ''' уиунэ скъак1о",
            "translation": "I am coming to your house '''too much'''."
          },
          {
            "sentence": "к1алэ '''бащэм''' е1уэ ар",
            "translation": "'''Too many''' of the boys say that."
          },
          {
            "sentence": "пшъашъэ '''бащэ''' уи1",
            "translation": "You have '''too many''' girls."
          },
          {
            "sentence": "'''бащэм''' е1уэ ар",
            "translation": "'''Too many people''' say that."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "baːɕa"
  },
  {
    "word": "бгалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curser",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "бганэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bunch, bundle, package",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣaːna"
  },
  {
    "word": "бгъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "building, roofed building, shed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaːʁa"
  },
  {
    "word": "шамйэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kerchief",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃaːmja"
  },
  {
    "word": "гьаур",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джаур\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʲaːwər"
  },
  {
    "word": "ешъок1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person that drinks a lot",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jaʂʷakʷʼ"
  },
  {
    "word": "рэхьэнйэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person from the Israeli village of Rehaniya",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "raħanjal"
  },
  {
    "word": "лъапсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "root",
        "examples": []
      },
      {
        "meaning": "root (Plant's root)",
        "examples": []
      },
      {
        "meaning": "shoelaces",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaːpsa"
  },
  {
    "word": "шъыпкъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "truth",
        "examples": [
          {
            "sentence": "'''Шъыпкъэр''', къэсфэ1уат",
            "translation": "Tell me the '''truth'''."
          },
          {
            "sentence": "Мо к1алэр '''шъыпкъэ''' закъу къы1уатэрэр",
            "translation": "That boy only tells '''truth'''."
          }
        ]
      },
      {
        "meaning": "an expression of suddenly finding a new idea or solution or question, oh",
        "examples": [
          {
            "sentence": "'''Шъыпкъэ''', уиунэ тк1ошъутыба?",
            "translation": "'''Oh''', we could go to your house, right?"
          }
        ]
      }
    ],
    "cognate": "пэж",
    "type": "noun",
    "ipa": "ʂəpqa"
  },
  {
    "word": "зэжъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "narrow, small width; not wide",
        "examples": [
          {
            "sentence": "К1элэ пщэрэр пчъэ '''зэжъум''' блэк1ышъурэп",
            "translation": "The fat boy can't walk pass the '''narrow''' door."
          }
        ]
      }
    ],
    "synonyms": [
      "бгъузэ"
    ],
    "cognate": "зэвы",
    "type": "adj",
    "ipa": "zaʐʷə"
  },
  {
    "word": "тхьэцу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dough",
        "examples": []
      }
    ],

    "cognate": "тхьэв",
    "type": "noun",
    "ipa": "tħat͡ʃʷ"
  },
  {
    "word": "уэрзэ1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a table (on which to make foods with dough), a dough table",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "warzaʔaːna"
  },
  {
    "word": "алъмэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1алъмэкъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːɬmaq"
  },
  {
    "word": "пыдзыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lonely",
        "examples": []
      }
    ],
    "type": "adj"
  },
  {
    "word": "быдзталхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bra",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəd͡ztaːlħa"
  },
  {
    "word": "лыкъумэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "luqma (a Circassian snack)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ləqʷma"
  },
  {
    "word": "бгъотагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rotten",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʁʷataːʁa"
  },
  {
    "word": "бгъотагъэ фыжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "white rot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷataːʁa fəʑ"
  },
  {
    "word": "бгъотэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷatan"
  },
  {
    "word": "бгъотэрым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a rotten smell; stench",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷtarəm"
  },
  {
    "word": "бгъуагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "width",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷaːʁa"
  },
  {
    "word": "бгъузагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "narrowness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷəzaːʁ"
  },
  {
    "word": "бгъузап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "narrow place, strait",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷəzaːpˤʼa"
  },
  {
    "word": "бгъузэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "narrow",
        "examples": []
      }
    ],
    "synonyms": [
      "зэжъу"
    ],
    "type": "adj",
    "ipa": "bʁʷəza"
  },
  {
    "word": "бгъуит1у1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Two-shutter door",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷitʷʼəʔʷ"
  },
  {
    "word": "бгъундж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crooked, inclined, leaning",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʁʷənd͡ʒ"
  },
  {
    "word": "бгъунджырык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "annoying, Leaning while walking, talkative",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷənd͡ʒərəkʷʼ"
  },
  {
    "word": "бгъурыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "attached to the side, hanging, Reserve horse to a wagon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷərəʃʼ"
  },
  {
    "word": "мы1уш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "silly; foolish",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "məʔʷəʃ"
  },
  {
    "word": "бгъусы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "big bed cover, big quilt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷəsə"
  },
  {
    "word": "бгъушы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hay, straw",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁʷəʃə"
  },
  {
    "word": "бгъэ1ас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "falcon (used for hunting)",
        "examples": []
      },
      {
        "meaning": "hawk (domesticated)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaʔaːs"
  },
  {
    "word": "бгъэбзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glade",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁabzə"
  },
  {
    "word": "бгъэгъак1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "new house",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaʁaːt͡ʃ"
  },
  {
    "word": "бгъэджыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breast pocket",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁad͡ʒər"
  },
  {
    "word": "бгъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eagle",
        "examples": []
      }
    ],

    "cognate": "бгъэ",
    "type": "noun",
    "ipa": "bʁaʐ"
  },
  {
    "word": "бгъэк1эс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baby, infant, suckling",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁat͡ʃas"
  },
  {
    "word": "бгъэлыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breast meat (of a bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaləb"
  },
  {
    "word": "бгъэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bulrush (dried)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁanə"
  },
  {
    "word": "бгъэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "belt; string, thread",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaps"
  },
  {
    "word": "бгъэузы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tuberculosis (disease)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁawəzə"
  },
  {
    "word": "бгъэхалъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "decoration",
        "examples": []
      },
      {
        "meaning": "medal",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaxaːɬħ"
  },
  {
    "word": "бгъэхэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pin (for holding the front a shirt)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaxaʔʷ"
  },
  {
    "word": "бгъэхэ1ушъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tip of a pin; pinhead (head of a pin)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaxaʔʷəʂħ"
  },
  {
    "word": "бгъэхэлъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brooch",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʁaxaɬ"
  },
  {
    "word": "бгъэшхъуант1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "parrot",
        "examples": []
      }
    ],
    "synonyms": [
      "бзыукъолэн"
    ],
    "type": "noun",
    "ipa": "bʁaʃχʷaːbtˤʼ"
  },
  {
    "word": "бгыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curse, defamatory poetry, lash at a person",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəbza"
  },
  {
    "word": "бгыгъуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tunnel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəʁʷaːn"
  },
  {
    "word": "бгыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beam, girder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəqʷ"
  },
  {
    "word": "бгыкъупхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rafter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəqʷəpχ"
  },
  {
    "word": "бгылъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountainous region",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəɬa"
  },
  {
    "word": "бгынагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neglected",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bɣənaːʁa"
  },
  {
    "word": "бгынт1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pliable",
        "examples": []
      },
      {
        "meaning": "violated, broken (as: a rule which has been broken)",
        "examples": [],
        "tags": [
          "as: a rule which has been broken"
        ]
      }
    ],
    "type": "adj",
    "ipa": "bɣəntˤʼa"
  },
  {
    "word": "бгынэжьыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "abandoned, deserted, (of a woman) divorced",
        "examples": [],
        "tags": [
          "of a woman"
        ]
      }
    ],
    "type": "adj",
    "ipa": "bɣənaʑəʁa"
  },
  {
    "word": "бгынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to abandon, to desert; to leave",
        "examples": [
          {
            "sentence": "Л1ым иунагъо '''ыбгынагъ'''",
            "translation": "The man '''abandoned''' his family."
          },
          {
            "sentence": "Л1ым ичылэ '''ыбгыни''', къалэм к1уагъэ",
            "translation": "The man '''left''' his village and moved to the city."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bɣənan"
  },
  {
    "word": "бгыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mountain slope.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəpa"
  },
  {
    "word": "бгырыпх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "waistband",
        "examples": []
      },
      {
        "meaning": "belt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣərəpx"
  },
  {
    "word": "бгырыпхышъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "belt buckle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣərəpxəʂħ"
  },
  {
    "word": "бгырыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountaineer",
        "examples": []
      },
      {
        "meaning": "mountainous",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣərəs"
  },
  {
    "word": "бгыуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountain pass",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "бгыч1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mountain base.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣətʂa"
  },
  {
    "word": "бгычап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountainside",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣət͡ʃaːp"
  },
  {
    "word": "бгышъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountain peak",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣəʂħa"
  },
  {
    "word": "бгэрый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curser",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bɣərəj"
  },
  {
    "word": "бедэхьау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for nothing, in vain (to no avail or benefit)",
        "examples": [
          {
            "sentence": "бедэхьау '''уш1эрэ'''",
            "translation": "What you are doing is '''for nothing'''."
          },
          {
            "sentence": "к1алэр '''бедэхьау''' мак1о",
            "translation": "The boy is going '''for nothing'''."
          }
        ]
      }
    ],
    "synonyms": [
      "хьаулый",
      "пк1энчъ"
    ],
    "type": "adv",
    "ipa": "bedaħaːw"
  },
  {
    "word": "жъотхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spade",
        "examples": []
      }
    ],
    "synonyms": [
      "белэ"
    ],
    "type": "noun",
    "ipa": "ʐʷatχʷ"
  },
  {
    "word": "белэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "haybale",
        "examples": []
      },
      {
        "meaning": "hay",
        "examples": []
      }
    ],
    "synonyms": [
      "мэкъу"
    ],
    "type": "noun",
    "ipa": "bajla"
  },
  {
    "word": "бетэман",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "miserable, nothing, trifle, unlucky, useless",
        "examples": []
      }
    ],
    "type": "adj"
  },
  {
    "word": "бжалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "liana, creeper (plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒaːla"
  },
  {
    "word": "бжамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "packthread",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒaːma"
  },
  {
    "word": "бжъаб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ibex",
        "examples": []
      }
    ],
    "synonyms": [
      "ачъэжъ"
    ],
    "type": "noun",
    "ipa": "bʐaːb"
  },
  {
    "word": "бжъако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hornless",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʐaːkʷa"
  },
  {
    "word": "бэкълауэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baklava",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baqlaːwa"
  },
  {
    "word": "гъэшъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to waste (kill) someone",
        "examples": [
          {
            "sentence": "К1алэм л1ыр '''ыгъэшъуягъ'''",
            "translation": "The boy '''wasted''' the man."
          },
          {
            "sentence": "Нэбгит1у '''сыгъэшъуягъэх'''",
            "translation": "'''I wasted''' two people."
          }
        ]
      },
      {
        "meaning": "to made someone whistle",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaʂʷjan"
  },
  {
    "word": "чы1хэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blanket",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəʔxan"
  },
  {
    "word": "насып",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "luck",
        "examples": [
          {
            "sentence": "'''насып''' си1",
            "translation": "I have '''luck''' - I was '''lucky'''."
          },
          {
            "sentence": "'''насыпк1э''' хьа1ор сишхыгъэп",
            "translation": "'''Lucky''' I didn't eat the mushroom."
          }
        ]
      }
    ],
    "synonyms": [
      "тхьэет",
      "----"
    ],
    "type": "noun",
    "ipa": "naːsəp"
  },
  {
    "word": "пый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one's enemy",
        "examples": [
          {
            "sentence": "к1алэм л1эр '''ипый'''",
            "translation": "The man is the boy's [[enemy]]."
          },
          {
            "sentence": "л1ымрэ к1алэмрэ '''зпыйых'''",
            "translation": "The boy and the man are '''enemies'''."
          }
        ]
      }
    ],

    "cognate": "бий",
    "type": "noun",
    "ipa": "pəj"
  },
  {
    "word": "налэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horseshoe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːla"
  },
  {
    "word": "нахъутэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "halter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːχʷta"
  },
  {
    "word": "уанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saddle",
        "examples": [
          {
            "sentence": "ишы '''уанэ''' трелхьэ",
            "translation": "He put '''saddle''' on his horse."
          },
          {
            "sentence": "шым '''уанэ''' тэлъ",
            "translation": "There is a '''saddle''' on the horse."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "waːna"
  },
  {
    "word": "Ахын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Akhen (Circassian (pagan) god of cattle)",
        "examples": [],
        "tags": [
          "Circassian (pagan) god of cattle"
        ]
      }
    ],
    "type": "name",
    "ipa": "aːxən"
  },
  {
    "word": "к1ошын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move away, to live somewhere else",
        "examples": [
          {
            "sentence": "к1алэр '''мэк1ошэгъ'''",
            "translation": "The boy '''moved away'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "kʷʼaʃən"
  },
  {
    "word": "чэк1ошъык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to steal a girl in order to merry her (Adyghe culture)",
        "examples": [
          {
            "sentence": "пшъашъэр '''ячэк1ошъык1эгъ'''",
            "translation": "They '''stole''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃakʷʼaʂəkʲən"
  },
  {
    "word": "мэрсысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сысын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "marsəsən"
  },
  {
    "word": "гъэрсысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэсысын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁarsəsən"
  },
  {
    "word": "мэзэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(to) it shakes",
        "examples": [
          {
            "sentence": "К1алэр ешъуагъи алкохьол '''мэзазы'''",
            "translation": "Because the boy drank alcohol, '''he is shaking'''."
          },
          {
            "sentence": "Ч1ыгор '''мэзазы'''",
            "translation": "The earth '''is shaking'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mazazan"
  },
  {
    "word": "гъэзэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shake, to make someone or something to shake",
        "examples": [
          {
            "sentence": "К1алэм 1анэр '''егъэзазэ'''",
            "translation": "The boy '''is shaking''' the table."
          },
          {
            "sentence": "Иныжъэр ч1ыгум къытаом '''къигъэзэзагъ'''",
            "translation": "When the giant hit the ground '''it shook it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁazazan"
  },
  {
    "word": "мэкъэркъэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tremble, to quake, to shake",
        "examples": [
          {
            "sentence": "Чъыгор '''мэкъэркъатэ'''",
            "translation": "The earth '''is trembling'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maqarqatan"
  },
  {
    "word": "зэгок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be split (caused by something or someone)",
        "examples": [
          {
            "sentence": "Ч1ыгур т1у '''зэгок1ыгъ'''",
            "translation": "The earth '''got split''' into two."
          }
        ]
      },
      {
        "meaning": "to lost one's mind, to get crazy",
        "examples": [
          {
            "sentence": "К1алэр '''зэгок1ыгъ'''",
            "translation": "The boy '''gone crazy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "зэгок1ьын"
    ],
    "type": "verb",
    "ipa": "zaɡʷat͡ʃʼən"
  },
  {
    "word": "зэгогъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to split something or something, to separate",
        "examples": [
          {
            "sentence": "к1алэр анэр '''зэгуигъэк1эгъ''' т1у",
            "translation": "The boy '''split''' the table into two."
          },
          {
            "sentence": "л1эм к1алит1у зэзауэхэрэ '''зэгуигъэк1ыжьгъэх'''",
            "translation": "The man '''separated''' the two boy that were fighting."
          }
        ]
      },
      {
        "meaning": "to make someone to go mad, to make someone to go crazy",
        "examples": [
          {
            "sentence": "мэ к1алэцыкухэмэ '''зэгуагъэк1ы'''",
            "translation": "Those little boys '''are making me go mad'''."
          },
          {
            "sentence": "к1алэм л1эр '''зэгуегъэк1ы'''",
            "translation": "The boy '''is making''' the man '''to go mad'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaɡʷaʁat͡ʃʼən"
  },
  {
    "word": "гъунэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ask a girl's parent for permission to merry her",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁʷənafən"
  },
  {
    "word": "такъыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "piece",
        "examples": [
          {
            "sentence": "шъоущыгъу '''такъыр'''",
            "translation": "'''lump of''' sugar."
          },
          {
            "sentence": "хьалыгъу '''такъыр'''",
            "translation": "'''piece of''' bread."
          }
        ]
      },
      {
        "meaning": "dot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "taːqər"
  },
  {
    "word": "пчэдыжь ш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good morning",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "pt͡ʃadəʑ ʃʷʼə"
  },
  {
    "word": "пчыхьэ ш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good evening",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "pt͡ʃəħa ʃʷʼə"
  },
  {
    "word": "тхьашъуегъэпсэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thank you (plural)",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "tħaːʂʷeʁpsaw"
  },
  {
    "word": "койыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "villager",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷajəl"
  },
  {
    "word": "чылэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "village, settlement",
        "examples": [
          {
            "sentence": "сыд '''ч1ылэ''' къуздэк1рэ",
            "translation": "What '''village community''' you come from?"
          },
          {
            "sentence": "'''ч1ылэм''' къэп1уэрэ ебэгъа1уы",
            "translation": "'''The whole village''' hears what you are saying. (Said to someone that talks very loud)"
          },
          {
            "sentence": "'''тич1ылэ''' дэсхэр адыгэх",
            "translation": "'''The people that live in our village''' are Circassians."
          }
        ]
      }
    ],
    "synonyms": [
      "къуаджэ"
    ],
    "cognate": "жылэ",
    "type": "noun",
    "ipa": "t͡ʃəɮa"
  },
  {
    "word": "къашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Qafe (a Circassian dance)",
        "examples": []
      },
      {
        "meaning": "dance",
        "examples": []
      }
    ],

    "cognate": "къафэ",
    "type": "noun",
    "ipa": "qaːʂʷa"
  },
  {
    "word": "шэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Shashan (a Circassian dance)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃaʃan"
  },
  {
    "word": "шэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шэны\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃan"
  },
  {
    "word": "ашъыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "er, uh; I mean (said when one has made a mistake in speech, before one corrects it)",
        "examples": [
          {
            "sentence": "Уатэр къэсфэхь, '''Ашъыу''', отычэр къэсфэхь",
            "translation": "Bring me the hammer, '''er, I mean,''' bring me the ax."
          }
        ]
      },
      {
        "meaning": "argh, ugh (an expression of bad mood or vexation or dissatisfaction)",
        "examples": [
          {
            "sentence": "'''Ашъыу!''', зэ щыгъэт",
            "translation": "'''Ugh!''', shut up for a moment."
          },
          {
            "sentence": "'''Ашъыу!''', о тучаным ук1ошъущтыба уизакъоу?",
            "translation": "'''Argh!''', Can't you go to the shop alone?"
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "aːʃʷʼəw"
  },
  {
    "word": "цагэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rib",
        "examples": []
      }
    ],
    "synonyms": [
      "цадльэ"
    ],
    "cognate": "дзажэ",
    "type": "noun",
    "ipa": "t͡saːɣa"
  },
  {
    "word": "анэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"анахь\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːnaħ"
  },
  {
    "word": "гьэнэпылъап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэнэпылъап1\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʲanapəɬaːpʼ"
  },
  {
    "word": "тары",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "which",
        "examples": [
          {
            "sentence": "'''тэры''' уиунэ?",
            "translation": "'''which one''' is your house?"
          },
          {
            "sentence": "'''тэры ''' усфайэ?",
            "translation": "'''which one''' do you want?"
          },
          {
            "sentence": "'''тэры ''' къы1эпхырагъо?",
            "translation": "'''which one''' do you want to take from him?"
          }
        ]
      }
    ],
    "type": "pron",
    "ipa": "taːrə"
  },
  {
    "word": "к1этыщх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a Jewish person, a Jew",
        "examples": []
      }
    ],
    "synonyms": [
      "чыфыт"
    ],
    "type": "noun",
    "ipa": "t͡ʃatəɕx"
  },
  {
    "word": "лъэкъуапц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person Arabic, a arab",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaqʷaːpt͡sʼ"
  },
  {
    "word": "нэзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэз\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nazə"
  },
  {
    "word": "парчэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "piece",
        "examples": [
          {
            "sentence": "Лы '''парчэ''' мэхь",
            "translation": "Here take a '''piece''' of meat."
          },
          {
            "sentence": "Зхьэлэгъу '''парчэ''' сфай",
            "translation": "I want a '''slice''' of bread."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "paːrt͡ʃa"
  },
  {
    "word": "дэхьагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэхьэгъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daħaːʁʷa"
  },
  {
    "word": "лалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "something that is loosed",
        "examples": []
      },
      {
        "meaning": "meat",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "laːla"
  },
  {
    "word": "псыжъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boiling water",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʐʷa"
  },
  {
    "word": "пхъэтакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stump",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχataːaqa"
  },
  {
    "word": "п1аст1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oatmeal",
        "examples": []
      },
      {
        "meaning": "porridge",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pˤʼaːstˤʼa"
  },
  {
    "word": "1ужъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thick, dense",
        "examples": []
      }
    ],

    "cognate": "1увы",
    "type": "adj",
    "ipa": "ʔʷaʐʷə"
  },
  {
    "word": "псыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thin",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "psəʁʷa"
  },
  {
    "word": "шъуабгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуамбгъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуамбгъо"
    ],
    "type": "adj",
    "ipa": "ʃʷaːbʁʷaː"
  },
  {
    "word": "лъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to brush",
        "examples": [
          {
            "sentence": "Бзылъфыгъэр '''мэлъак1э'''",
            "translation": "The woman '''is brushing'''. (intransitive)"
          },
          {
            "sentence": "Бзылъфыгъэм '''елъэк1ы''' джэхашъо джанэк1э",
            "translation": "The woman '''is brushing''' the floor with a shirt. (transitive)"
          },
          {
            "sentence": "К1алэм ицэхэр '''елъэк1ых'''",
            "translation": "The boy '''is brushing''' his teeth."
          },
          {
            "sentence": "Уицэхэр '''лъэк1ых'''",
            "translation": "'''Brush''' your teeth."
          },
          {
            "sentence": "Псым ухэк1ыжьмэ 1эплъэк1ык1э '''зылъэк1ыжь'''",
            "translation": "When you will come out from the water '''brush yourself''' with a towel."
          }
        ]
      },
      {
        "meaning": "to wipe off",
        "examples": [
          {
            "sentence": "К1алэм ы1эхэр 1аплъэк1ымк1э '''елъэк1ых'''",
            "translation": "The boy '''wipes''' his hands with a towel."
          }
        ]
      },
      {
        "meaning": "to be able to, to manage to do, to be capable of doing",
        "examples": [
          {
            "sentence": "Бзылъфыгъэр къеджэн '''ылъэк1ын'''",
            "translation": "The woman '''is able to''' read."
          },
          {
            "sentence": "Л1ым шымк1э к1эхьан '''ылъэк1ыгъэп'''",
            "translation": "The man '''was not able to''' catch up with him."
          },
          {
            "sentence": "Л1ыжъыр псынк1эу к1онэу '''фэлъэк1ы'''",
            "translation": "The old man '''is capable of''' going fast."
          }
        ]
      }
    ],
    "cognate": "лъэк1ын (to be capable of); лъэщ1ын (to brush)",
    "type": "verb",
    "ipa": "ɬat͡ʃʼən"
  },
  {
    "word": "бжъакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːqʷa"
  },
  {
    "word": "бжъакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hornless",
        "examples": []
      }
    ],
    "synonyms": [
      "бжъако"
    ],
    "type": "adj",
    "ipa": "bʐaːqa"
  },
  {
    "word": "бжъалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian cheese, cheese mold",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːɬ"
  },
  {
    "word": "бжъанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "claw",
        "examples": []
      },
      {
        "meaning": "nail (Body)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːna"
  },
  {
    "word": "бжъапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hillock, knoll",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːpa"
  },
  {
    "word": "бжъащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Crooked horn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaːɕa"
  },
  {
    "word": "бжъэдыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bjadugh (a Circassian clan tribe)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐadəʁʷ"
  },
  {
    "word": "бжъэкумыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horn core",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐakʷəməl"
  },
  {
    "word": "бжъэкъожъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Gun powder horn",
        "examples": []
      },
      {
        "meaning": "Small horn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaqʷaʐəj"
  },
  {
    "word": "бжъэмый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Horn (musical instrument), tube",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaməj"
  },
  {
    "word": "бжъэмыяпщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bugler",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaməjaːpɕ"
  },
  {
    "word": "бжъэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "feeding bottle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaʂʷ"
  },
  {
    "word": "уасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "price; cost",
        "examples": [
          {
            "sentence": "Тхьэпш мыщ '''иуасэ'''",
            "translation": "how much this '''cost?'''"
          },
          {
            "sentence": "Мы1эрэсым '''иуасэ''' пл1ы долар",
            "translation": "the apple's '''price''' is four dollars."
          },
          {
            "sentence": "Мы мащинэм '''уасэ''' и1эп",
            "translation": "This car has no '''value'''."
          },
          {
            "sentence": "Мы мащинэм '''иуасэ''' бэ",
            "translation": "This car's '''price''' is a lot."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "waːsa"
  },
  {
    "word": "бэхьал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expensive",
        "examples": [
          {
            "sentence": "бэнанэр '''бэхьал'''",
            "translation": "the banana '''is expensive'''."
          },
          {
            "sentence": "бэнэнэ '''бэхьал''' къэсчэфэгъ",
            "translation": "i bought the '''expensive''' banana."
          },
          {
            "sentence": "сиунэ уиунэ нэхь '''бэхьал'''",
            "translation": "my house is more '''expensive''' than your house."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "baħaːl"
  },
  {
    "word": "бжыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handful; fistful",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʒəb"
  },
  {
    "word": "1эбжыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handful; fistful",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔabʒəb"
  },
  {
    "word": "бжыдзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flea (an insect)",
        "examples": []
      }
    ],

    "cognate": "бжьыдзэ",
    "type": "noun",
    "ipa": "bʒəd͡za"
  },
  {
    "word": "бжыдзэгъал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flea killer (a plant), insecticide",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒəd͡zaʁaːɬˤʼa"
  },
  {
    "word": "бжымы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fist",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʒəmə"
  },
  {
    "word": "1эбжым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔabʒəmə"
  },
  {
    "word": "бжымы зао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boxing",
        "examples": []
      }
    ],
    "synonyms": [
      "бжыхьак1э"
    ],
    "type": "noun",
    "ipa": "bʒəmə zaːwa"
  },
  {
    "word": "зэуэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to battle, to fight",
        "examples": [
          {
            "sentence": "к1алэр '''мэзауэ''' зауымк1э",
            "translation": "the boy '''is fighting''' in the war."
          },
          {
            "sentence": "адыгэхэмрэ урысхэмрэ '''зэзэуэгъагъэх'''",
            "translation": "the Circassians and the Russian '''had fought each other once'''."
          },
          {
            "sentence": "к1алэр л1ым '''езауэ'''",
            "translation": "the boy '''is fighting''' the man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zawan"
  },
  {
    "word": "бжыны ц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "green onion",
        "examples": []
      },
      {
        "meaning": "leek",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒənə t͡sən"
  },
  {
    "word": "бжыхьак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boxing",
        "examples": []
      }
    ],
    "synonyms": [
      "бжымы зауэ"
    ],
    "type": "noun",
    "ipa": "bʒəħaːt͡ʃa"
  },
  {
    "word": "бжыхьапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Autumn beginning",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒəħaːpa"
  },
  {
    "word": "бжыхьас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Autumn culture, Field (autumn),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒəħaːs"
  },
  {
    "word": "бжыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "autumn",
        "examples": []
      }
    ],

    "cognate": "бжьыхьэ",
    "type": "noun",
    "ipa": "bʒəħa"
  },
  {
    "word": "гъэмафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "summer",
        "examples": []
      }
    ],

    "cognate": "гъэмахуэ",
    "type": "noun",
    "ipa": "ʁamaːfa"
  },
  {
    "word": "к1ымафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "winter",
        "examples": []
      }
    ],

    "cognate": "щ1ымахуэ",
    "type": "noun",
    "ipa": "t͡ʃʼəmaːfa"
  },
  {
    "word": "гъатхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spring",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaːtxa"
  },
  {
    "word": "бжыхьэбадзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "autumn fly",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʒəħabaːd͡za"
  },
  {
    "word": "бжьа1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apiary (a place where bees and hives are kept)",
        "examples": [
          {
            "sentence": "'''бжьа1ом''' бжьэматэхэр дэтых",
            "translation": "The beehives are in '''the apiary'''."
          },
          {
            "sentence": "бжьахъор '''бжьа1ом''' дэт",
            "translation": "The beekeeper is in '''the apiary'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bʑaːʔʷa"
  },
  {
    "word": "бжьахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beekeeper",
        "examples": [
          {
            "sentence": "'''бжьахъор''' бжьа1ом дэт",
            "translation": "'''The beekeeper''' is in the apiary."
          },
          {
            "sentence": "Ар '''бжьэхъо''' 1аз, ау бжьэхъон 1энат1эм фаеп",
            "translation": "He is an '''beekeeper''' expert, but he does not want to be in a beekeeping job."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bʑaːχʷa"
  },
  {
    "word": "бжьаш1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "male bee",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑaːʃʷʼa"
  },
  {
    "word": "бжьащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sting (bee), stinger (bee),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑaːɕa"
  },
  {
    "word": "бжьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yoke",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑə"
  },
  {
    "word": "бжьыго",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʑaːɡʷa"
  },
  {
    "word": "бжьыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blot, spot, stain",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑəʁa"
  },
  {
    "word": "бжьыко",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бжьыго\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bʑaːkʷa"
  },
  {
    "word": "бжьынык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "onion seed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənəkʲʼ"
  },
  {
    "word": "бжьыныуц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild garlic",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənəwts"
  },
  {
    "word": "бжьыныфщыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "salted garlic",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənəfɕəʁʷ"
  },
  {
    "word": "бжьыныфык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garlic seed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənəfəkʲʼ"
  },
  {
    "word": "бжьыныфыц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clove (garlic)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑənəfəts"
  },
  {
    "word": "бжьыш1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slender, slim, svelte,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑəʃʷʼa"
  },
  {
    "word": "бжьэбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee family, swarm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑabən"
  },
  {
    "word": "бжьэмат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бжьэматэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑamaːt"
  },
  {
    "word": "бжьэпащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee queen",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑapaːɕ"
  },
  {
    "word": "бжьэпч1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee swarm",
        "examples": []
      }
    ],
    "synonyms": [
      "бжьэцы"
    ],
    "type": "noun",
    "ipa": "bʑapt͡ʃ"
  },
  {
    "word": "бжьэцы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee swarm",
        "examples": []
      }
    ],
    "synonyms": [
      "бжьэпч1"
    ],
    "type": "noun",
    "ipa": "bʑat͡sə"
  },
  {
    "word": "бжьэхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to beekeep; to maintain hives and keep bees, especially for the production of honey",
        "examples": [
          {
            "sentence": "Сиятэ '''мэбжьахъо'''",
            "translation": "My father '''is beekeeping'''."
          },
          {
            "sentence": "Ар бжьэхъо 1аз, ау '''бжьэхъон''' 1энат1эм фаеп",
            "translation": "He is an beekeeper expert, but he does not want to be in a '''beekeeping''' job."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bʑaχʷan"
  },
  {
    "word": "бжьэшх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bee-eater (a bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʑaʃx"
  },
  {
    "word": "бзагьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзаджэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzaːɡʲa"
  },
  {
    "word": "бзак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dress designer, organizer",
        "examples": []
      },
      {
        "meaning": "tailor's cutter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaːkʷʼa"
  },
  {
    "word": "бзако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dumb, mute (unable to speak)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzaːkʷa"
  },
  {
    "word": "бзалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quiver (for arrows)",
        "examples": []
      }
    ],
    "synonyms": [
      "бзэкъулъ"
    ],
    "type": "noun",
    "ipa": "bzaːɬ"
  },
  {
    "word": "бзэкъулъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quiver (for arrows)",
        "examples": []
      }
    ],
    "synonyms": [
      "бзалъ"
    ],
    "type": "noun",
    "ipa": "bzaqʷəɬ"
  },
  {
    "word": "бзэмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barley (wild), grass, quack grass,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaːma"
  },
  {
    "word": "амэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an expression of anger",
        "examples": [
          {
            "sentence": "'''амэ!''', сыд шъуш1эра",
            "translation": "'''Ama!''' what you (plural) are doing?"
          },
          {
            "sentence": "'''амэ!''', узэгок1гъа",
            "translation": "'''Ama!''' have you gone mad?"
          }
        ]
      },
      {
        "meaning": "if that",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "aːma"
  },
  {
    "word": "к1элэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "some sort of expression that is used when someone wants to point something angrily or sarcastically or from excitement",
        "examples": [
          {
            "sentence": "'''К1элэ!''', сэры къыпдэ1эпы1агъэ",
            "translation": "'''Chala!''' I was the one that was helping you."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "t͡ʃala"
  },
  {
    "word": "абэуйэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an expression when someone messes up",
        "examples": [
          {
            "sentence": "'''абэуйэй!''', хьыдж сыд уш1эш1тэ",
            "translation": "'''Abuyay!''' now what are you gonna do?"
          },
          {
            "sentence": "'''абэуйэй!''', к1алэр зуакэу къупгоуцот",
            "translation": "'''Abuyay!''', the boy so gonna beat you."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "abawjaj"
  },
  {
    "word": "бзаш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eloquent",
        "examples": []
      },
      {
        "meaning": "well-spoken",
        "examples": []
      }
    ],
    "synonyms": [
      "жаш1у",
      "жэмачыу",
      "гущы1эгъал"
    ],
    "type": "adj",
    "ipa": "bzaːʃʷʼ"
  },
  {
    "word": "хьэкуку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэкуаку\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħakʷəkʷ"
  },
  {
    "word": "гудэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vulva",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəda"
  },
  {
    "word": "къэхьпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whore",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaħpa"
  },
  {
    "word": "шэнтэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bag",
        "examples": []
      },
      {
        "meaning": "schoolbag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃanta"
  },
  {
    "word": "джадэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "big",
        "examples": []
      },
      {
        "meaning": "gigantic",
        "examples": []
      }
    ],
    "synonyms": [
      "шъэджашъ"
    ],
    "cognate": "фо",
    "type": "adj",
    "ipa": "d͡ʒaːda"
  },
  {
    "word": "ш1у лъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to love",
        "examples": [
          {
            "sentence": "пшъашъэр '''ш1у сэлъэгъу'''",
            "translation": "'''I love''' the girl."
          },
          {
            "sentence": "'''ш1у осэлъэгъу'''",
            "translation": "'''I love you'''."
          }
        ]
      },
      {
        "meaning": "to see someone or something in a positive way, to like",
        "examples": [
          {
            "sentence": "ущ иш1эрэ '''ш1у сэлъэгъу'''",
            "translation": "'''in see in a positive way''' the thing that person doing."
          }
        ]
      }
    ],

    "cognate": "ф1ы лъэгъун",
    "type": "verb",
    "ipa": "ʃʷʼə ɬaʁʷən"
  },
  {
    "word": "1ус",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "food for animals; fodder",
        "examples": [
          {
            "sentence": "К1алэм '''1ус''' шк1эм реты",
            "translation": "The boy is giving '''food''' to the calf."
          },
          {
            "sentence": "Чэтмэ '''1ус''' аритыгъ",
            "translation": "He gave '''food''' to the chickens."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəs"
  },
  {
    "word": "кугъачъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "driver",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəʁaːt͡ʃ"
  },
  {
    "word": "сэкъат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "defective, out of order, not functioning/working properly",
        "examples": []
      },
      {
        "meaning": "disabled",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "saqaːt"
  },
  {
    "word": "къэлэсэрай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fortress, palace, Palace (fenced),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qalasaraːj"
  },
  {
    "word": "пытап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "сrucifixion place",
        "examples": []
      },
      {
        "meaning": "fortress",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pətːpˤʼa"
  },
  {
    "word": "куцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "knitting needle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷət͡sa"
  },
  {
    "word": "гущы1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "word",
        "examples": [
          {
            "sentence": "А '''гущы1эр''' къызгуры1орэп",
            "translation": "I don’t understand the '''word'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гуща1э"
    ],
    "cognate": "псалъэ",
    "type": "noun",
    "ipa": "ɡʷəɕəʔa"
  },
  {
    "word": "азэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1азэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːza"
  },
  {
    "word": "атэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a lot, A large amount.",
        "examples": [
          {
            "sentence": "Мые '''атэ''' къэсдэхь",
            "translation": "Bring '''a lot of''' apples with you."
          },
          {
            "sentence": "Томым к1элэ '''атэ''' и1",
            "translation": "Tom have '''a lot of''' boys."
          }
        ]
      },
      {
        "meaning": "stack",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːta"
  },
  {
    "word": "пхъэу1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "woodpecker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχawʔʷə"
  },
  {
    "word": "1офы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "work",
        "examples": [
          {
            "sentence": "Иджы '''1офы''' бэ сыш1эн фай",
            "translation": "Now I need to do a lot of '''work'''."
          },
          {
            "sentence": "'''1офы''' бэ си1",
            "translation": "I have a lot of '''work'''."
          },
          {
            "sentence": "'''1офым''' мак1о к1алэр",
            "translation": "The boy is going to '''work'''."
          },
          {
            "sentence": "'''1офы''' бэм тыхэбдзагъ о",
            "translation": "You gave us a lot of '''work'''"
          }
        ]
      },
      {
        "meaning": "job",
        "examples": [
          {
            "sentence": "'''1офы''' си1",
            "translation": "I have '''a job'''"
          }
        ]
      },
      {
        "meaning": "problem",
        "examples": [
          {
            "sentence": "Ар '''1офа'''?",
            "translation": "Is that '''a problem'''?"
          },
          {
            "sentence": "Ар '''1офэп'''",
            "translation": "That is not '''a problem'''."
          }
        ]
      }
    ],

    "cognate": "1уэху",
    "type": "noun",
    "ipa": "ʔʷafə"
  },
  {
    "word": "идзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to desert",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəd͡zan"
  },
  {
    "word": "дзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw",
        "examples": [
          {
            "sentence": "К1алэм '''едзы''' мыжъор",
            "translation": "The boy '''is throwing''' the rock."
          },
          {
            "sentence": "К1алэм мыжъор '''къэстедзэ'''",
            "translation": "The boy '''is throwing on me''' the rock."
          },
          {
            "sentence": "Мы мыжъор '''умыдз'''",
            "translation": "'''Don't throw''' this rock."
          },
          {
            "sentence": "К1алэм мыжъор хым '''хедзэ'''",
            "translation": "The boy '''is throwing''' the stone '''across''' the sea."
          },
          {
            "sentence": "К1алэм мыжъор щагум '''дедзэ'''",
            "translation": "The boy '''is throwing''' the stone at the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "d͡zən"
  },
  {
    "word": "къэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come into existence",
        "examples": []
      },
      {
        "meaning": "to born (Humans, Plants, Animals, Fish, Birds, etc.)",
        "examples": [
          {
            "sentence": "пшъашъэм к1алэ '''къыфэхъогъ'''",
            "translation": "The boy was born for the girl (The girl gave a birth to a boy)."
          },
          {
            "sentence": "нэкъгъэхэр шауым '''къыдахъох'''",
            "translation": "The flowers '''are born (growing)''' in the yard."
          },
          {
            "sentence": "хьэм ишыр '''къэхъу'''",
            "translation": "The dog's cub is born (present)."
          },
          {
            "sentence": "пц1эжъйэр хы ч1эгъым '''къыч1ахъо'''",
            "translation": "The fish '''is born (present)''' under the sea."
          },
          {
            "sentence": "хьэм ишыр '''къэхъу'''",
            "translation": "The dog's cub is born (present)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaχʷən"
  },
  {
    "word": "мэхъут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "permitted, allowed, lawful, permissible",
        "examples": [
          {
            "sentence": "'''рэхъута''' сыдэк1ыу?",
            "translation": "'''Can''' i go out? - '''Is it permitted for me''' to go out."
          },
          {
            "sentence": "к1алэр мащхыу '''рэхъта'''?",
            "translation": "'''Can''' the boy eat? - '''Is''' the boy '''permitted''' to eat?"
          },
          {
            "sentence": "к1алэр мащхыу '''рэхъут'''",
            "translation": "The boy '''can''' eat - The boy '''is permitted''' to eat."
          },
          {
            "sentence": "мащинэр ч1ык1айыу угъач1эу '''рэхъута?'''",
            "translation": "'''Is it permitted''' to run the car fast?"
          },
          {
            "sentence": "мащинэр ч1ык1айыу угъач1эу '''рэхъутэп'''",
            "translation": "'''Is it not allowed (forbidden)''' to run the car fast."
          },
          {
            "sentence": "ар '''мэхъут''', мэр '''мэхъутэп'''",
            "translation": "That '''is permitted''', this is '''not permitted (forbidden)'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maχʷət"
  },
  {
    "word": "мэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "χʷən"
  },
  {
    "word": "цызэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squirrel",
        "examples": []
      },
      {
        "meaning": "marten",
        "examples": []
      }
    ],

    "cognate": "дзыдзэ",
    "type": "noun",
    "ipa": "t͡səza"
  },
  {
    "word": "пцы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chisel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡səza"
  },
  {
    "word": "сэрай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "latticework, trellis",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saraːj"
  },
  {
    "word": "чэц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃat͡sˤʼə"
  },
  {
    "word": "пц1ашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swallow",
        "examples": []
      }
    ],
    "synonyms": [
      "пс1ашъухъо"
    ],
    "type": "noun",
    "ipa": "pt͡sˤʼaːʃχʷa"
  },
  {
    "word": "хьамц1ый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corn",
        "examples": []
      }
    ],
    "synonyms": [
      "натрыф"
    ],
    "type": "noun",
    "ipa": "ħaːmt͡sˤʼəj"
  },
  {
    "word": "пц1эгъоплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bay",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡sˤʼaʁʷapɬ"
  },
  {
    "word": "цыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trust",
        "examples": []
      }
    ],
    "synonyms": [
      "цэхь"
    ],
    "type": "noun",
    "ipa": "t͡səħa"
  },
  {
    "word": "цэхь тэлъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цыхьэ ш1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цэхь Фэш1ын",
      "цыхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "t͡saħ taɬħan"
  },
  {
    "word": "пцелы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "willow",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "чы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twig",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃə"
  },
  {
    "word": "пак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whisker, mustache",
        "examples": []
      },
      {
        "meaning": "direction",
        "examples": [
          {
            "sentence": "К1алэр тиунэ '''пак1э''' мак1о",
            "translation": "The boy is going to our home '''direction'''."
          },
          {
            "sentence": "А '''пак1эм''' умык1у",
            "translation": "Don't go that '''way/direction'''."
          },
          {
            "sentence": "Хым '''ипак1эк1э''' жьыбгъэ гъак1о",
            "translation": "The wind is coming from the sea's '''direction'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пак1ьэ"
    ],
    "type": "noun",
    "ipa": "paːt͡ʃʼa"
  },
  {
    "word": "ебэджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall on the ground",
        "examples": [
          {
            "sentence": "К1алэр '''ебэджэгъ'''",
            "translation": "The boy '''fell on the ground'''."
          },
          {
            "sentence": "К1алэцык1ор '''ебэджы'''",
            "translation": "The little boy '''is falling on the ground'''."
          },
          {
            "sentence": "Джыхашъо ц1ашъутэм '''утэбэджэт'''",
            "translation": "'''You will fall''' on the slip floor."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jabad͡ʒən"
  },
  {
    "word": "ланчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "maple",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laːnt͡ʃa"
  },
  {
    "word": "чъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sleep",
        "examples": [
          {
            "sentence": "К1алэп '''мэчъые'''",
            "translation": "The boy '''sleeps'''."
          },
          {
            "sentence": "Мыщ '''ущычъые''' пшIоигъуа?",
            "translation": "Do you want '''to sleep''' here?"
          },
          {
            "sentence": "Сэ сычъые '''сшIоигъу'''",
            "translation": "I want '''to sleep'''."
          },
          {
            "sentence": "К1алэп '''мэчъыеп'''",
            "translation": "The boy '''doesn't sleep'''."
          },
          {
            "sentence": "К1алэп '''мэчъыешъурэп'''",
            "translation": "The boy '''can't get to sleep'''."
          },
          {
            "sentence": "'''сычъагъ'''",
            "translation": "'''I slept'''."
          },
          {
            "sentence": "П1эм '''стэчъыехьэгъ'''",
            "translation": "'''I slept''' on the bed."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂəjan"
  },
  {
    "word": "къуанч1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jackdaw",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːnt͡ʃa"
  },
  {
    "word": "ч1ыбжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bumble-bee",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəbʑ"
  },
  {
    "word": "ч1ыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "badger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəqʷ"
  },
  {
    "word": "ч1апц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dark",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaːpt͡sˤʼa"
  },
  {
    "word": "мыч1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jewel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mət͡ʃə"
  },
  {
    "word": "хъуч1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ball",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷət͡ʃaːna"
  },
  {
    "word": "пчъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "date",
        "examples": []
      },
      {
        "meaning": "number",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃaːʁa"
  },
  {
    "word": "пчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "door",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃa"
  },
  {
    "word": "пчъэпсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "door handle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃapsə"
  },
  {
    "word": "гуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pleasant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷaːpa"
  },
  {
    "word": "губчъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "field",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "щагу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yard",
        "examples": []
      }
    ],
    "synonyms": [
      "щао"
    ],
    "type": "noun",
    "ipa": "ɕaɡʷ"
  },
  {
    "word": "куандэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bush",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷaːnfa"
  },
  {
    "word": "к1осэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to turn off (lamp, fire, etc.)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼasan"
  },
  {
    "word": "кушъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cradle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəʂa"
  },
  {
    "word": "куамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twig",
        "examples": []
      },
      {
        "meaning": "branch",
        "examples": []
      },
      {
        "meaning": "knot (in a tree)",
        "examples": []
      }
    ],
    "synonyms": [
      "къуамэ",
      "къутамэ"
    ],
    "cognate": "гуанэ",
    "type": "noun",
    "ipa": "kʷaːma"
  },
  {
    "word": "анэбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mother's language",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnabza"
  },
  {
    "word": "анэдэлъфыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mother's language",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːnadaɬfəbza"
  },
  {
    "word": "бзыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bread crumb, notch,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəʁ"
  },
  {
    "word": "бзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut (with scissors, knife or sword), to slice",
        "examples": [
          {
            "sentence": "К1алэм '''ебзы''' тхылъып1эр",
            "translation": "The boy '''is cutting''' the paper."
          },
          {
            "sentence": "К1алэм псынк1эу лыр '''ебзы'''",
            "translation": "The boy '''cuts''' the meat fast."
          },
          {
            "sentence": "Мы къуаер '''бзы'''",
            "translation": "'''Slice''' this cheese."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bzən"
  },
  {
    "word": "бзыиплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Golden red",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəjəpɬ"
  },
  {
    "word": "псэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soul",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psa"
  },
  {
    "word": "бзый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beam, feather, plume, quill,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəj"
  },
  {
    "word": "бзэгуп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peninsula",
        "examples": []
      },
      {
        "meaning": "tongue tip",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaɡʷəp"
  },
  {
    "word": "бзэгурацу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Speaks with difficulty, tongue-tied",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzaɡʷəraːt͡ʃʷ"
  },
  {
    "word": "бзэджай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "evil, harmful, playful, quarrelsome",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzad͡ʒaːj"
  },
  {
    "word": "бзэджап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brothel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzad͡ʒaːpˤʼa"
  },
  {
    "word": "бзэджаш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "criminal, rogue",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzad͡ʒaːʃʼa"
  },
  {
    "word": "бзэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get naughty; to make a mischief",
        "examples": [
          {
            "sentence": "К1алэр '''мэбзаджэ'''.",
            "translation": "The boy '''misbehaves'''."
          },
          {
            "sentence": "Шъэожъыем '''бзэджэныр''' ишэн",
            "translation": "It is the boy's behavior to '''get naughty'''."
          }
        ]
      },
      {
        "meaning": "to frolic, to play about, to fool around, to get up to monkey tricks",
        "examples": []
      },
      {
        "meaning": "to horse around",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ык1эен"
    ],
    "type": "verb",
    "ipa": "bzad͡ʒan"
  },
  {
    "word": "бзэджэнрылъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзэджэрылъф\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzad͡ʒanrəɬf"
  },
  {
    "word": "бзэджэтелъхьап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scapegoat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzad͡ʒaːtajɬħaːpˤʼ"
  },
  {
    "word": "хьэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaps"
  },
  {
    "word": "бзэил",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lap, lick",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzajəl"
  },
  {
    "word": "бзэмкъэш1эныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "linguistics",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzamqaʃʼanəʁ"
  },
  {
    "word": "бзэмай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Talk with difficulty (adj)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzamaːj"
  },
  {
    "word": "1этэчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arm wrestling",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔatat͡ʃan"
  },
  {
    "word": "тхьауысхако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wake; in Adyghe culture, the practice of visiting a person's house on three nights following his death.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħaːwəsxaːkʷa"
  },
  {
    "word": "бзыпхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arrangement, Cut (dress), example, model, order, pattern, sample,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəpχa"
  },
  {
    "word": "бзытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trim",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzətan"
  },
  {
    "word": "бзыужъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small bird",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwʐəj"
  },
  {
    "word": "кукунэгъэплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"кукунэгъэбылъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "кукунэгъэбылъ",
      "къангъэбылъ",
      "къан-къангъэбылъ"
    ],
    "type": "noun",
    "ipa": "kʷəkʷnaʁapɬ"
  },
  {
    "word": "бзыук1эплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "redstart (a bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwkʲapɬ"
  },
  {
    "word": "бзыукъолэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "parrot",
        "examples": []
      }
    ],
    "synonyms": [
      "бгъэшхъуант1"
    ],
    "type": "noun",
    "ipa": "bzəwqʷalan"
  },
  {
    "word": "бзыукъуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rose mallow,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwqʷaːj"
  },
  {
    "word": "бзыулъахъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Chick-peas (wild plant),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwɬaːχ"
  },
  {
    "word": "бзыупц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blackbird",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwpt͡sˤʼ"
  },
  {
    "word": "бзыуубыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bird hunter, Bird trap,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwwəbət"
  },
  {
    "word": "убытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to catch",
        "examples": [
          {
            "sentence": "'''къэубыт''' к1алэр!",
            "translation": "'''Catch''' the boy!."
          },
          {
            "sentence": "ахэр тыгъэделэхи як1ыбк1э '''къэтыубытгъэх'''",
            "translation": "We fooled them, and '''we caught them''' behind."
          },
          {
            "sentence": "к1алэм ишынэхьыжъ '''къышъоубытэгъ''', ишынэхьык1 '''къышъоубытгъэп'''",
            "translation": "The boy '''could catch''' his older brother but '''couldn't catch''' his younger brother."
          },
          {
            "sentence": "к1алэм '''къеубыты''' л1эр",
            "translation": "The boy is '''catching''' the man."
          },
          {
            "sentence": "к1алэм '''къиубытыт''' л1эр",
            "translation": "The boy '''will catch''' the man."
          },
          {
            "sentence": "к1алэм '''къиубытэгъ''' л1эр",
            "translation": "The boy '''caught''' the man."
          }
        ]
      },
      {
        "meaning": "to hold with strength, to grip",
        "examples": [
          {
            "sentence": "'''къэубыт''' мыжъор",
            "translation": "'''Hold''' the rock."
          },
          {
            "sentence": "'''къэубытыгу''' хьанцэ",
            "translation": "'''Hold''' the shovel '''for now'''."
          },
          {
            "sentence": "джэгуалъэхэр '''къэсыубытрагъох'''",
            "translation": "'''I wanna hold''' the toys."
          }
        ]
      },
      {
        "meaning": "to capture, to make something yours (Like a room, house, territory, vehicle, animal, any object)",
        "examples": [
          {
            "sentence": "ныпыр '''къэубытын'''",
            "translation": "'''Capture''' the flag."
          },
          {
            "sentence": "къэбэртайхэмэ мы к1ыпэ яубытэгъ",
            "translation": "The Kabardians '''hold''' this area."
          },
          {
            "sentence": "яунэ '''сыубытыт'''",
            "translation": "'''I will capture''' their house."
          }
        ]
      }
    ],
    "synonyms": [
      "зы1эк1эубытэн",
      "етхъон",
      "пхъотэн"
    ],
    "type": "verb",
    "ipa": "wbətən"
  },
  {
    "word": "шъэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quiet, silence",
        "examples": [
          {
            "sentence": "К1алэ '''шъэфэр''' мэшхагъ",
            "translation": "'''The quiet''' boy ate."
          },
          {
            "sentence": "К1алэр '''шъэфэу''' мак1о",
            "translation": "The boy is going '''quietly'''."
          },
          {
            "sentence": "'''Шъэфэу''' шхэ",
            "translation": "Eat '''quietly'''."
          }
        ]
      }
    ],

    "cognate": "щэху",
    "type": "adj",
    "ipa": "ʂafə"
  },
  {
    "word": "къушъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountain",
        "examples": []
      }
    ],

    "cognate": "къущхьэ",
    "type": "noun",
    "ipa": "qʷəʂħa"
  },
  {
    "word": "зэкъошныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "friendship",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zaqʷaʃnəʁ"
  },
  {
    "word": "шъофы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "field",
        "examples": []
      }
    ],
    "synonyms": [
      "губгъо"
    ],
    "type": "noun",
    "ipa": "ʂʷafə"
  },
  {
    "word": "къуаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "village",
        "examples": []
      }
    ],
    "synonyms": [
      "кой",
      "чылэ"
    ],
    "cognate": "къуажэ",
    "type": "noun",
    "ipa": "qʷaːd͡ʒa"
  },
  {
    "word": "шъхьафитныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэфитынгъэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʂħaːfitnəʁ"
  },
  {
    "word": "л1эш1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "century (100 years)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬˤʼaʃʼaʁʷ"
  },
  {
    "word": "бзыухьакуаку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Lark (a bird)",
        "examples": []
      }
    ],
    "synonyms": [
      "бзыушъхьэтэкъыц"
    ],
    "type": "noun",
    "ipa": "bzəwħaːkʷaːkʷ"
  },
  {
    "word": "бзыушыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "young bird",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwʃər"
  },
  {
    "word": "бзыфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "linden (plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəfə"
  },
  {
    "word": "бзыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trim (the edges)",
        "examples": [
          {
            "sentence": "Л1ым чъыгыхэр '''ебзыхьэх'''",
            "translation": "The man '''trims''' the trees."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bzəħaːn"
  },
  {
    "word": "бзыхьаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clip (of paper), cuttings,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəħaːf"
  },
  {
    "word": "бзыхьафынчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "integrated, Not needy, Self sufficient,",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzəħaːfəntʂ"
  },
  {
    "word": "бзэбзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to warble",
        "examples": []
      },
      {
        "meaning": "to modulate",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bzabzan"
  },
  {
    "word": "бзэгужъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uvula",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaɡʷʐəj"
  },
  {
    "word": "бзэгохь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзэгухь\"",
        "examples": []
      }
    ],
    "synonyms": [
      "бзэгуит1у",
      "бзэгук1ыхь",
      "бзэгут1аркъу",
      "бзэгуибл"
    ],
    "type": "noun",
    "ipa": "bzaɡʷaħ"
  },
  {
    "word": "гъэлъэжъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone or something late, to delay",
        "examples": [
          {
            "sentence": "к1алэм пшъашъэр '''егъэлъажъо'''",
            "translation": "The boy '''is making''' the girl '''late'''."
          },
          {
            "sentence": "к1алэм '''сегъэлъэжъуагъ'''",
            "translation": "The boy '''made me late'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɬaʐʷan"
  },
  {
    "word": "губгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəbʁa"
  },
  {
    "word": "гъуамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaːma"
  },
  {
    "word": "шъумэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stench, stink",
        "examples": []
      }
    ],

    "cognate": "бамэ",
    "type": "noun",
    "ipa": "ʂʷəːma"
  },
  {
    "word": "хъэдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "16 dunam",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χad͡z"
  },
  {
    "word": "хъопсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to envy",
        "examples": []
      },
      {
        "meaning": "to lust",
        "examples": []
      }
    ],
    "synonyms": [
      "ешъугъун",
      "шхъогъон",
      "шъугъон",
      "ехьагъушъугъун",
      "шъухъогъон"
    ],
    "cognate": "енэц1ын",
    "type": "verb",
    "ipa": "χʷapsan"
  },
  {
    "word": "енэц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look lustfully",
        "examples": [
          {
            "sentence": "Тигъунэгъум ыпшъашъэ '''сенэцIы'''",
            "translation": "'''I am lusting for ''' my neighbor's daughter."
          }
        ]
      },
      {
        "meaning": "to look with a lot of desire",
        "examples": []
      },
      {
        "meaning": "to look enviously",
        "examples": []
      },
      {
        "meaning": "to long for",
        "examples": [
          {
            "sentence": "КIалэм иджанэхэм '''санэцIы'''",
            "translation": "'''I am longing for''' the boy's clothes."
          },
          {
            "sentence": "Гонахьхэм '''уамынэц1'''",
            "translation": "'''Don't long for''' (to do) the sins."
          }
        ]
      }
    ],
    "synonyms": [
      "хъопсэн",
      "----"
    ],
    "type": "verb",
    "ipa": "janat͡sʼən"
  },
  {
    "word": "нэгъуч1ыц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэгъуч1ыцэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "уды"
    ],
    "type": "noun",
    "ipa": "naʁʷt͡ʂʼət͡sa"
  },
  {
    "word": "нэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to touch",
        "examples": [
          {
            "sentence": "Дэпкъым '''нэс'''",
            "translation": "'''Touch''' the wall."
          },
          {
            "sentence": "Анэм уи1э шъоик1э '''унэмыс'''",
            "translation": "'''Don't touch''' the table with your dirty hands."
          },
          {
            "sentence": "К1алэр анэм '''нэсы'''",
            "translation": "The boy '''is touching''' the table."
          },
          {
            "sentence": "К1алэр къэцым '''нэсрэп'''",
            "translation": "The boy '''is not touching''' the thorn."
          }
        ]
      },
      {
        "meaning": "to reach",
        "examples": [
          {
            "sentence": "Къалэм '''унэсыгъа?'''",
            "translation": "'''Have you reached''' the city?."
          },
          {
            "sentence": "Уиунэ '''сыкъынэсымэ''' къик1",
            "translation": "'''If I reach''' your house come out."
          },
          {
            "sentence": "К1уи унэ плъыжьым '''унэсымэ''' къэуцу",
            "translation": "Go and '''when you reach''' the red house stop."
          },
          {
            "sentence": "Левил 4-м '''сыкънэсышъурэп'''",
            "translation": "'''I can't reach''' level 4."
          }
        ]
      }
    ],

    "cognate": "е1усын",
    "type": "verb",
    "ipa": "nasən"
  },
  {
    "word": "мэстын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"стын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mastən"
  },
  {
    "word": "гъэстын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to burn, to make something burn",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁastən"
  },
  {
    "word": "гъэпсык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпск1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэпсык1ьын",
      "зыгъэпсык1ын"
    ],
    "type": "verb",
    "ipa": "ʁapsət͡ʃʼən"
  },
  {
    "word": "ягъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "harm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːʁa"
  },
  {
    "word": "ягъэ егъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to harm someone, to disturb someone, to get in someone's way, to annoy someone",
        "examples": [
          {
            "sentence": "к1алэм '''ягъэ''' пшъашъэм '''регъэк1ы'''",
            "translation": "the boy '''is harming''' the girl."
          },
          {
            "sentence": "к1алэм '''ягъэ ебэгъэк1ы'''",
            "translation": "you '''are harming''' the boy."
          },
          {
            "sentence": "к1алэм '''ягъэ езгъэк1рэп'''",
            "translation": "I '''am not harming''' the boy."
          },
          {
            "sentence": "'''ягъэ''' к1алэхэмэ '''ямгък1'''",
            "translation": "'''don't harm''' the kids."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaːʁa jaʁakʲən"
  },
  {
    "word": "ягъэ къэгъэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to interrupt, to interfere with, to annoy",
        "examples": [
          {
            "sentence": "к1алэм '''ягъэ къегъэк1о'''",
            "translation": "the boy '''is bringing harm'''"
          },
          {
            "sentence": "'''ягъэ къэмгъак1у''', к1алэр еджэн е1уэ",
            "translation": "'''do not disturb''', the boy is trying to learn."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaːʁa qaʁakʷʼan"
  },
  {
    "word": "еупчын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ask someone",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''еупчэгъ''' дэк1рагъомэ",
            "translation": "the boy '''asked''' the girl if she wanted to go out."
          },
          {
            "sentence": "'''уысэупчы''' сыд уиц1э",
            "translation": "'''I am asking you''' what your name is"
          },
          {
            "sentence": "'''уыкъчэмыупч''' тэдэ так1о",
            "translation": "'''don't ask''' where we are going"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jawpt͡ʃən"
  },
  {
    "word": "пчын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(dialectal) alternative spelling of \"пчъын\"",
        "examples": [],
        "tags": [
          "dialectal",
          "alternative_spelling"
        ]
      }
    ],
    "type": "verb"
  },
  {
    "word": "хъэпрашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χapraːʂʷa"
  },
  {
    "word": "ук1эпхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trim",
        "examples": [
          {
            "sentence": "Сэ чъыгыр '''сэук1апхъэ'''",
            "translation": "I am trimming the tree."
          },
          {
            "sentence": "Л1ыхэр мэзым хахьэх чъыгыхэр'''яук1эпхъэнхэу'''",
            "translation": "The men are entering the forest to trim the trees."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wt͡ʃʼapχan"
  },
  {
    "word": "бзэмы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dumb (someone that can not communicate due to ignorance of one or another language)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bzaməʔʷ"
  },
  {
    "word": "гъэпсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to plan",
        "examples": [
          {
            "sentence": "К1алэм '''егъэпсы''' зэонэу",
            "translation": "The boy '''is planning''' to fight."
          },
          {
            "sentence": "Къэк1орэ илъэсым Америкэ сык1онэу '''сэгъэпсы'''",
            "translation": "'''I am planning''' to go to America next year."
          }
        ]
      },
      {
        "meaning": "to aim",
        "examples": [
          {
            "sentence": "К1алэм к1эрахъо '''егъэпсы'''",
            "translation": "The boy '''is aiming''' the gun."
          },
          {
            "sentence": "К1алэм мыжъор чъыгым '''фегъэпсы'''",
            "translation": "The boy '''is aiming''' the rock on the tree."
          }
        ]
      },
      {
        "meaning": "to arrange, to organize",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapsən"
  },
  {
    "word": "зэ1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to organize",
        "examples": [
          {
            "sentence": "к1алэм иунэ '''зэ1ехы'''",
            "translation": "The boy '''is organizing''' his house."
          },
          {
            "sentence": "к1алэм унэр '''зэ1ихэгъ'''",
            "translation": "The boy '''is organized''' the house."
          }
        ]
      },
      {
        "meaning": "to clean and organize",
        "examples": [
          {
            "sentence": "уиунэ '''зэ1эх'''",
            "translation": "'''Clean and organize''' your room."
          }
        ]
      },
      {
        "meaning": "to change hands",
        "examples": []
      },
      {
        "meaning": "to Cut (sacrifice)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaxən"
  },
  {
    "word": "зэгъэпэшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to organize",
        "examples": []
      },
      {
        "meaning": "to arrange",
        "examples": []
      },
      {
        "meaning": "to engineer",
        "examples": []
      },
      {
        "meaning": "to prepare",
        "examples": []
      },
      {
        "meaning": "to suit",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔapaʃən"
  },
  {
    "word": "зэгъэфэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to arrange",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʁafaʁan"
  },
  {
    "word": "зэгъэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pretty up (to make oneself or someone else look better by dressing up, for example)",
        "examples": [
          {
            "sentence": "К1алэм '''зегъафэ''' тиунэ къэк1оти",
            "translation": "The boy '''was dressed smartly''' because he will come to my house."
          }
        ]
      },
      {
        "meaning": "to engineer",
        "examples": []
      },
      {
        "meaning": "to beautify",
        "examples": []
      },
      {
        "meaning": "to organize,",
        "examples": [
          {
            "sentence": "К1алэм унэр '''зегъафэ'''",
            "translation": "The boy '''is organizing''' the house."
          },
          {
            "sentence": "Мы блокхэр '''зэгъафэх'''",
            "translation": "'''Organize''' these blocks."
          },
          {
            "sentence": "Сиянэ сиджанэхэр '''зигъэфагъэх'''",
            "translation": "My mother '''organized''' my clothes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʁafan"
  },
  {
    "word": "еузэпкъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to arrange",
        "examples": []
      },
      {
        "meaning": "to educate",
        "examples": []
      },
      {
        "meaning": "to change",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawzapqən"
  },
  {
    "word": "еузэнк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to arrange",
        "examples": []
      },
      {
        "meaning": "to make something straight",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawzant͡ʃən"
  },
  {
    "word": "егъэк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something or someone fit (Can be in some group or place)",
        "examples": []
      },
      {
        "meaning": "to make something suitable",
        "examples": []
      },
      {
        "meaning": "to put in place",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁakʷʼən"
  },
  {
    "word": "ек1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come up to, to come towards, to approach",
        "examples": [
          {
            "sentence": "К1алэр тхьэматэм '''ек1угъ''' еупч1ынэу",
            "translation": "The boy '''came up to''' the leader to ask him."
          },
          {
            "sentence": "Ныом узы 1ае '''къек1угъ'''",
            "translation": "A horrible illness '''came to''' the old woman."
          }
        ]
      },
      {
        "meaning": "to fit",
        "examples": [
          {
            "sentence": "К1алэм джанэр '''ек1у'''",
            "translation": "The shirt '''fits''' the boy."
          },
          {
            "sentence": "Костюмэр сэ къысэкIурэп, сш1оин",
            "translation": "The suit '''does not fit''' me, it's too large for me."
          }
        ]
      },
      {
        "meaning": "to be appropriate",
        "examples": [
          {
            "sentence": "1офымк1э ухъонэныр '''ек1урэп'''",
            "translation": "Cursing in work '''is not appropriate'''."
          }
        ]
      },
      {
        "meaning": "to be suitable for, to suit",
        "examples": [
          {
            "sentence": "К1алэм унэр '''ек1урэп'''",
            "translation": "The house '''is not suitable''' for the boy."
          },
          {
            "sentence": "К1алэм унэр '''ек1у'''",
            "translation": "The house '''is suitable''' for the boy."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэк1ух'''",
            "translation": "The boy and the girl '''are suitable for each other'''."
          },
          {
            "sentence": "Ешъонэ алкохол нысашэм '''ек1урэп''' тихабзэ",
            "translation": "Drinking alcohol in weddings '''is not suitable''' for our traditions."
          },
          {
            "sentence": "Уцышъор машинэм '''ек1урэп'''",
            "translation": "The color green '''is not suitable''' for cars."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jakʷʼən"
  },
  {
    "word": "бзэныб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Center of bow string",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzanəb"
  },
  {
    "word": "бзэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bow string, chord",
        "examples": []
      },
      {
        "meaning": "chord",
        "examples": []
      },
      {
        "meaning": "Hypotenuse (in a triangle),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaps"
  },
  {
    "word": "бзэрэбзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chirp",
        "examples": []
      },
      {
        "meaning": "Sing (a bird),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzarabzan"
  },
  {
    "word": "бзэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deer",
        "examples": []
      },
      {
        "meaning": "stag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaw"
  },
  {
    "word": "бзэутэ",
    "lang": "Adyghe",
    "definitions": [],
    "type": "noun",
    "ipa": "bzawta"
  },
  {
    "word": "бзэхабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grammar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaxaːbz"
  },
  {
    "word": "бзэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disappear, to vanish; to fade",
        "examples": [
          {
            "sentence": "К1алэр уфае зыхъукIэ '''мэбзэхы'''",
            "translation": "The boy '''disappears''' when you need him."
          }
        ]
      }
    ],
    "synonyms": [
      "к1одын"
    ],
    "type": "verb",
    "ipa": "bzaxən"
  },
  {
    "word": "мэмэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to faint, to pass out, to swoon, to keel over",
        "examples": [
          {
            "sentence": "к1алэр '''мэмахы'''",
            "translation": "The boy '''is passing out'''."
          },
          {
            "sentence": "к1алэр '''мэмэхэгъ'''",
            "translation": "The boy '''passed out'''."
          },
          {
            "sentence": "'''укъэмэхыт''' уэр уыщхымэ",
            "translation": "'''You will pass out''' if you eat that."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mamaxən"
  },
  {
    "word": "къэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to puke, to vomit",
        "examples": [
          {
            "sentence": "к1алэр '''къэжьы'''",
            "translation": "The boy '''is puking'''."
          },
          {
            "sentence": "к1алэр '''къэжьэгъ'''",
            "translation": "The boy '''puked'''."
          },
          {
            "sentence": "'''укъэжьыт''' уэр уыщхымэ",
            "translation": "'''You will puke''' if you eat that."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʑən"
  },
  {
    "word": "къешхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rain",
        "examples": [
          {
            "sentence": "'''къешхы'''",
            "translation": "'''It is raining'''."
          },
          {
            "sentence": "нэушы '''къешхыт'''",
            "translation": "Tomorrow '''will rain'''."
          },
          {
            "sentence": "унэм '''къешхэ'''",
            "translation": "It is raining in the house (The rain is entering the house)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qajʃxən"
  },
  {
    "word": "мэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to vote",
        "examples": [
          {
            "sentence": "к1алэр '''матэ'''",
            "translation": "The boy is voting."
          }
        ]
      },
      {
        "meaning": "to pay",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matan"
  },
  {
    "word": "бзэш1эныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "language science; linguistics",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaʃʼanəʁ"
  },
  {
    "word": "бзэш1эныгъэл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "linguist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaʃʼanəʁaɬʼ"
  },
  {
    "word": "бзэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Linotype, literature",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzaʃan"
  },
  {
    "word": "блабжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Scale of fish",
        "examples": []
      },
      {
        "meaning": "Skin of snake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːbʐ"
  },
  {
    "word": "благъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dragon",
        "examples": []
      }
    ],
    "synonyms": [
      "блэгъожъ"
    ],
    "type": "noun",
    "ipa": "blaːʁʷa"
  },
  {
    "word": "еблэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go near, to get close",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''еблагъэ'''",
            "translation": "The boy '''is going near''' the girl."
          },
          {
            "sentence": "к1алэр '''къэблагъэ'''",
            "translation": "The boy '''is approaching (here)'''."
          },
          {
            "sentence": "тиунэ '''къеблагъ'''",
            "translation": "'''Come near''' my house (You are welcome to come to my house)."
          },
          {
            "sentence": "к1алэр пшъашъэм '''еблэгъагъэп'''",
            "translation": "The boy '''didn't gone near''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jablaʁan"
  },
  {
    "word": "блантхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forearm (for animals)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːntχa"
  },
  {
    "word": "бланэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fallow deer",
        "examples": []
      },
      {
        "meaning": "moose",
        "examples": []
      },
      {
        "meaning": "courageous, brave, gutsy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blanɜ"
  },
  {
    "word": "л1ыблан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hero",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼəbɮaːn"
  },
  {
    "word": "пл1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quarter",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "pɬʼaːna"
  },
  {
    "word": "щанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one third",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "ɕaːna"
  },
  {
    "word": "тфанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one fifth",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "tfaːna"
  },
  {
    "word": "ханэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one six",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "xaːna"
  },
  {
    "word": "янэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mother",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːna"
  },
  {
    "word": "ятэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "father",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːta"
  },
  {
    "word": "бгъуанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one ninth",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "bʁʷaːna"
  },
  {
    "word": "пш1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one ten",
        "examples": []
      }
    ],
    "type": "num",
    "ipa": "pʃʼaːna"
  },
  {
    "word": "блапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "black snake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːpt͡sʼa"
  },
  {
    "word": "блахэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Shoulder blade base,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːxa"
  },
  {
    "word": "блашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Poisonous snake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːʃχʷa"
  },
  {
    "word": "блашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake skin",
        "examples": []
      }
    ],
    "synonyms": [
      "блэгьан"
    ],
    "type": "noun",
    "ipa": "blaːʂʷa"
  },
  {
    "word": "блашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake head",
        "examples": []
      },
      {
        "meaning": "Sea shell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaːʂħa"
  },
  {
    "word": "блегъэзык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэгъэзык1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajʁazəkʲən"
  },
  {
    "word": "блегъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэгъэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajʁakʲʼən"
  },
  {
    "word": "бледзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэдзын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajd͡zən"
  },
  {
    "word": "гъэлэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to loose, to let loose (to free)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁalalan"
  },
  {
    "word": "т1упщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to release",
        "examples": [
          {
            "sentence": "топыр '''т1упщ'''",
            "translation": "'''release''' the cannon."
          },
          {
            "sentence": "дзак1охэр '''ат1упщыгъэх'''",
            "translation": "'''they released''' the soldiers."
          }
        ]
      },
      {
        "meaning": "to launch",
        "examples": [
          {
            "sentence": "торпеда '''т1упщын'''",
            "translation": "'''to launch''' a torpedo."
          }
        ]
      },
      {
        "meaning": "to set free; to let go",
        "examples": [
          {
            "sentence": "пщыл1ыхэр '''т1упщыных'''",
            "translation": "'''to set free''' slaves."
          }
        ]
      },
      {
        "meaning": "to leave someone (from harm or annoyance)",
        "examples": [
          {
            "sentence": "к1алэр '''т1упщ'''",
            "translation": "'''Leave''' the boy '''alone'''."
          },
          {
            "sentence": "к1алэм пшъашъэр ыт1упщырэп",
            "translation": "The boy '''is not leaving''' the girl '''alone'''."
          },
          {
            "sentence": "'''сыт1упщыба!'''",
            "translation": "'''Leave me alone already!'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tʷʼəpɕən"
  },
  {
    "word": "блефын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэфын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajfən"
  },
  {
    "word": "блехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pass quickly and unnoticed, to slip by",
        "examples": [
          {
            "sentence": "К1арахъор '''къысблихыгъ'''",
            "translation": "The gun shot '''passed (missed)''' me."
          },
          {
            "sentence": "Мыжъор к1алэм '''блихыгъ'''",
            "translation": "The rock '''passed (missed)''' the boy."
          }
        ]
      },
      {
        "meaning": "to take something pass something",
        "examples": [
          {
            "sentence": "Дзыо ушъагъэ ытамэ телъэу '''блихыгъ'''",
            "translation": "'''He passed''' trotting while carrying a bag on his shoulder."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blajxən"
  },
  {
    "word": "блещын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэщын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajɕən"
  },
  {
    "word": "блыгуч1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "armpit root",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləɡʷt͡ʃ"
  },
  {
    "word": "къабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clean",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qaːbza"
  },
  {
    "word": "1урт1агъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1онтагъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "1онтагъ"
    ],
    "type": "adj",
    "ipa": "ʔʷəntʼaːʁ"
  },
  {
    "word": "т1эт1эй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slow",
        "examples": []
      }
    ],
    "synonyms": [
      "жъажъэ"
    ],
    "type": "adj",
    "ipa": "tʼatʼaj"
  },
  {
    "word": "к1уач1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strong",
        "examples": []
      }
    ],
    "synonyms": [
      "къарыу"
    ],
    "type": "adj",
    "ipa": "kʷʼaːt͡ʃʼa"
  },
  {
    "word": "абакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a way a person walks (a person that walks while his right foot point right and left foot left).",
        "examples": [
          {
            "sentence": "к1алэр '''абакъэу''' мак1о",
            "translation": "The boy '''is walking''' abaqa (His right foot pointing right and left foot left)."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "aːbaːqa"
  },
  {
    "word": "ц1ынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wet",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tsʼəna"
  },
  {
    "word": "мэгъушъыжъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dry",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁʷʂəʐən"
  },
  {
    "word": "мэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be heated",
        "examples": [
          {
            "sentence": "Гъуч1эр '''мэплъы'''",
            "translation": "The iron '''is heating up'''."
          },
          {
            "sentence": "Шхынэр '''мэплъыгъа?'''",
            "translation": "'''Did''' the food '''heated up?'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mapɬən"
  },
  {
    "word": "гъэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to heat",
        "examples": [
          {
            "sentence": "К1алэм гъуч1эр '''егъэплъы''' маш1ок1э",
            "translation": "The boy '''is heating''' the iron with fire."
          },
          {
            "sentence": "Шхынэр '''угъэплъыгъа?'''",
            "translation": "'''Did you heat''' the food?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁapɬən"
  },
  {
    "word": "пыдзэгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "isolated, loner",
        "examples": [
          {
            "sentence": "К1алэр '''пыдзэгъ'''",
            "translation": "The boy is a '''loner'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "pəd͡zʁ"
  },
  {
    "word": "зэпедзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to separate (a group to singles)",
        "examples": [
          {
            "sentence": "к1алэхэр '''зэпысдзыгъэх'''",
            "translation": "'''I separated''' the boys."
          },
          {
            "sentence": "л1ым к1алэхэр '''зэпидзыгъэх'''",
            "translation": "The man '''separated''' the boys."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapajd͡zən"
  },
  {
    "word": "пц1ырый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "liar",
        "examples": [
          {
            "sentence": "К1алэр '''пц1ырый'''",
            "translation": "The boy '''is a liar'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пц1ыус"
    ],
    "type": "adj",
    "ipa": "pt͡sʼərəj"
  },
  {
    "word": "псышъонт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hippopotamus",
        "examples": []
      }
    ],

    "cognate": "псыфэнд",
    "type": "noun",
    "ipa": "psəʂʷant"
  },
  {
    "word": "к1урэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cart, cart for babies",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷʼəraʐəj"
  },
  {
    "word": "пшагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fog",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʃaːʁʷa"
  },
  {
    "word": "матэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "basket",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːta"
  },
  {
    "word": "итын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pay",
        "examples": [
          {
            "sentence": "к1алэм '''еты'''",
            "translation": "The boy is paying."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jətən"
  },
  {
    "word": "лэжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to work",
        "examples": [
          {
            "sentence": "К1алэр мафэм '''мэлажьэ'''",
            "translation": "The boy '''is working''' in the day."
          },
          {
            "sentence": "К1алэр л1ым '''фэлажьэ'''",
            "translation": "The boy '''is working for''' the man."
          },
          {
            "sentence": "К1алэр '''къэсфэлажьэ'''",
            "translation": "The boy '''is working for me'''."
          },
          {
            "sentence": "К1алэр '''мэлэжьэрагъоп''' нэпэ",
            "translation": "The boy '''don't want to work''' today."
          },
          {
            "sentence": "Л1ыхэмэ '''сафэлажьэ'''",
            "translation": "'''I am working for''' the men."
          },
          {
            "sentence": "'''Сафэлажьэ'''",
            "translation": "'''I am working for them'''."
          },
          {
            "sentence": "'''Уысфэлажьэ'''",
            "translation": "'''I am working for you'''."
          },
          {
            "sentence": "'''Уыкъэсфэлажьэ'''",
            "translation": "'''You are working for me'''."
          }
        ]
      },
      {
        "meaning": "to function, to work",
        "examples": [
          {
            "sentence": "Мащинэр '''къэлажьа?'''",
            "translation": "'''Is''' the car '''working?'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "laʑan"
  },
  {
    "word": "ебэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bawan"
  },
  {
    "word": "ук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kill",
        "examples": [
          {
            "sentence": "к1алэм '''еук1ы'''",
            "translation": "The boy '''is killing'''."
          },
          {
            "sentence": "к1алэр '''еук1ы'''",
            "translation": "The boy '''is getting killed'''."
          },
          {
            "sentence": "к1алэм л1эр '''еук1ы'''",
            "translation": "The boy '''is killing''' the man."
          },
          {
            "sentence": "'''саук1ыт'''",
            "translation": "'''They gonna kill me'''."
          },
          {
            "sentence": "к1алэр '''яук1ы'''",
            "translation": "'''They are killing''' the boy."
          },
          {
            "sentence": "к1алэр '''къеук1ы'''",
            "translation": "'''He are killing''' the boy."
          },
          {
            "sentence": "заум ухахьэмэ '''уаук1ыт'''",
            "translation": "If you enter the war '''they will kill you'''."
          },
          {
            "sentence": "дзэл1ымэ '''саук1ы'''",
            "translation": "The soldiers '''are killing me'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ук1ьын"
    ],
    "type": "verb",
    "ipa": "wət͡ʃʼən"
  },
  {
    "word": "ч1эбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drown",
        "examples": [
          {
            "sentence": "К1алэр  '''ч1эбы'''",
            "translation": "The boy '''is drowning'''."
          }
        ]
      },
      {
        "meaning": "to sink",
        "examples": [
          {
            "sentence": "Къохьэ '''ч1эбы'''",
            "translation": "The ship '''is sinking'''."
          },
          {
            "sentence": "Къохьэр псы мэ1эу мэхъуи '''ч1эбэгъ'''",
            "translation": "The ship became full of water and '''it sank'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼabən"
  },
  {
    "word": "хэук1эхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to die (among other people) (For example in a car accident, war, etc).",
        "examples": [
          {
            "sentence": "к1алэр зауэм '''хэук1эхьагъ'''",
            "translation": "The boy '''died''' in the fight (war)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawt͡taħan"
  },
  {
    "word": "мэл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"л1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maɬʼan"
  },
  {
    "word": "мэунэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"унэзэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawnazan"
  },
  {
    "word": "мэпрэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get dizzy",
        "examples": [
          {
            "sentence": "К1алэр '''мэпразэ'''",
            "translation": "The boy '''is getting dizzy'''."
          },
          {
            "sentence": "'''Сэпразэ'''",
            "translation": "'''I am getting dizzy'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maprazan"
  },
  {
    "word": "ешъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drink",
        "examples": [
          {
            "sentence": "К1алэр рсы '''ешъо'''",
            "translation": "The boy '''is drinking''' water."
          },
          {
            "sentence": "Сэ щай '''сешъо'''",
            "translation": "I '''drink''' tea."
          },
          {
            "sentence": "Сыда '''узэшъонэу''' уик1асэр?",
            "translation": "What would you like '''to drink'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʂʷan"
  },
  {
    "word": "мэчэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэрэзын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэкьэрэзын"
    ],
    "cognate": "мэджэрэзын",
    "type": "verb",
    "ipa": "mat͡ʃarazən"
  },
  {
    "word": "мэгуш1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуш1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maɡʷəʃʷʼan"
  },
  {
    "word": "щыгуш1ук1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щыгуш1ук1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "щыдыхьэщхын"
    ],
    "cognate": "щыдыхьэшхын",
    "type": "verb",
    "ipa": "ʃəɡʷəʃʷʼkʲʼən"
  },
  {
    "word": "штэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pick up",
        "examples": [
          {
            "sentence": "'''къашт''' уатэ",
            "translation": "'''Pick up''' the hammer."
          },
          {
            "sentence": "к1алэм мыер '''къештэ'''",
            "translation": "The boy '''is picking up''' the apple."
          }
        ]
      },
      {
        "meaning": "to conquer",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃtan"
  },
  {
    "word": "мэщынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щынэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maɕənan"
  },
  {
    "word": "мэлыгьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэлыджын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maləɡʲən"
  },
  {
    "word": "гъэлыгьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэлыджын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaləɡʲən"
  },
  {
    "word": "гопк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump on the side of",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷapt͡ʃʼan"
  },
  {
    "word": "гьэгун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэгун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʲaɡʷən"
  },
  {
    "word": "шыфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fit",
        "examples": [
          {
            "sentence": "к1алэр джанэр '''шэфэ'''",
            "translation": "The shirt '''is fitting''' the boy."
          }
        ]
      },
      {
        "meaning": "to smear, to rub in, to daub",
        "examples": [
          {
            "sentence": "псы фабэ уилъакъо '''шыф'''",
            "translation": "'''Smear''' hot water on your leg."
          },
          {
            "sentence": "к1алэм ипкъы '''шефэ'''",
            "translation": "The boy is '''smearing''' on his body."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəfan"
  },
  {
    "word": "шифэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smear, to rub in, to daub",
        "examples": [
          {
            "sentence": "тхьэмгъупчъэм рсы '''шэсафэ'''",
            "translation": "'''I am rubbing''' the windows with water."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃifan"
  },
  {
    "word": "тифэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spread, to smear",
        "examples": [
          {
            "sentence": "к1алэм псыжъ иджанэ '''тифэхьэгъ'''",
            "translation": "The child '''smeared''' mud on his shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tifaħən"
  },
  {
    "word": "псыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псынжъы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʐ"
  },
  {
    "word": "псыжъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псынжъы\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "psəʐə"
  },
  {
    "word": "тик1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill, to pour",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tikʲan"
  },
  {
    "word": "к1эгъожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to regret",
        "examples": [
          {
            "sentence": "к1алэр '''к1эгъожьы'''",
            "translation": "The boy '''is regretting'''."
          },
          {
            "sentence": "'''ск1эгъожьы''' зэрэ-уысэуагъэ",
            "translation": "'''I am regretting''' i hit you."
          },
          {
            "sentence": "'''ук1эмгъожь''' уызэрэкъэк1уагъэм фэшк1э",
            "translation": "'''Don't regret''' you came."
          }
        ]
      }
    ],

    "cognate": "щ1егъуэжын",
    "type": "verb",
    "ipa": "t͡ʃaʁʷaʑən"
  },
  {
    "word": "гъэтэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to correct",
        "examples": [
          {
            "sentence": "К1элэегъаджэм калэм иджэуапхэр '''егъэтэрэзых'''",
            "translation": "The teacher '''corrects''' the boy's answers."
          }
        ]
      },
      {
        "meaning": "to repair",
        "examples": [
          {
            "sentence": "К1алэм мащинэр '''егъэтэрэзы'''",
            "translation": "The boy '''repairs''' the car."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэцэк1эжьын",
      "ш1ыжьын"
    ],
    "type": "verb",
    "ipa": "ʁatarazən"
  },
  {
    "word": "мэуэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hitting",
        "examples": [
          {
            "sentence": "к1алэр '''мауэ'''",
            "translation": "the boy '''is hitting'''."
          }
        ]
      },
      {
        "meaning": "to be destroyed, to be ruined.",
        "examples": [
          {
            "sentence": "унэр '''мауэ'''",
            "translation": "I house '''is getting''' ruined."
          }
        ]
      },
      {
        "meaning": "to play music",
        "examples": [
          {
            "sentence": "кIалэр гитарым '''къеуэ'''",
            "translation": "the boy is playing the guitar"
          },
          {
            "sentence": "пяноуым '''шъукъеуа'''",
            "translation": "do you (plural) play the piano?"
          }
        ]
      },
      {
        "meaning": "to shoot",
        "examples": [
          {
            "sentence": "к1алэр к1эрахъомк1э '''мауэ'''",
            "translation": "the boy is '''shooting''' with the gun."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawan"
  },
  {
    "word": "феуэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit for someone",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''феуагъ'''",
            "translation": "the boy '''hit''' for the girl."
          }
        ]
      },
      {
        "meaning": "to call (a phone)",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''феуэ''' тэлефонымк1э",
            "translation": "the boy '''calling''' the girl with the telephone."
          },
          {
            "sentence": "'''къэсфеу''' телефонк1э",
            "translation": "'''Call me''' with (using) a phone."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fajwan"
  },
  {
    "word": "гъэуэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawan"
  },
  {
    "word": "мэунэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be ruined",
        "examples": [
          {
            "sentence": "к1алэм '''мэунэхъогъ'''",
            "translation": "The boy '''is ruined'''."
          },
          {
            "sentence": "мащинэр '''мэунэхъу'''",
            "translation": "The car '''is getting ruined'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawnaχʷʁn"
  },
  {
    "word": "гъэунэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ruin; to make someone/something ruined",
        "examples": [
          {
            "sentence": "Пшъашъэм к1алэр '''егъэунэхъу'''",
            "translation": "The girl '''is ruining''' the boy."
          },
          {
            "sentence": "К1алэм мащинэр '''егъэунэхъугъ'''",
            "translation": "The boy '''ruined''' the car."
          },
          {
            "sentence": "Алкохолым '''угъэунэхъущт'''",
            "translation": "Alcohol '''will make you ruined'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawnaχʷʁn"
  },
  {
    "word": "мэубэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be completely broken, to be destroyed, to be shattered,",
        "examples": [
          {
            "sentence": "анэр '''мэубэтагъ'''",
            "translation": "The table '''is destroyed'''."
          },
          {
            "sentence": "анэр '''мэубатэ'''",
            "translation": "The table '''is being destroyed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawəbatan"
  },
  {
    "word": "зэпытхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear off",
        "examples": [
          {
            "sentence": "К1апсэр '''зэпытхъэгъ'''",
            "translation": "The rope '''is torn'''."
          },
          {
            "sentence": "К1алэм к1апсэр '''зэпетхъы'''",
            "translation": "The boy '''is tearing''' the rope."
          },
          {
            "sentence": "Уиджанэ '''зэпымытхъ'''",
            "translation": "'''Don't tear''' your shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapətχən"
  },
  {
    "word": "зэпитхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear (a rope)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapitχən"
  },
  {
    "word": "мэк1этхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1этхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʃatχan"
  },
  {
    "word": "чэтхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear to shreds",
        "examples": [
          {
            "sentence": "Тхылъып1эр '''мэк1атхъэ'''",
            "translation": "The paper '''is being torn'''."
          },
          {
            "sentence": "К1алэм тхьапэр '''ек1атхъэ'''",
            "translation": "The boy '''is tearing''' the paper."
          }
        ]
      },
      {
        "meaning": "to rip something apart",
        "examples": [
          {
            "sentence": "К1алэм иджынэ '''мэк1этхъагъ'''",
            "translation": "The boy's shirt '''is ripped apart'''."
          },
          {
            "sentence": "К1элэц1ык1ум джанэхэр '''ык1атхъэх'''",
            "translation": "The little boy '''is ripping''' the shirts '''apart'''."
          }
        ]
      }
    ],
    "synonyms": [
      "кьэтхъэн"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼatχan"
  },
  {
    "word": "ч1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чъэн\"",
        "examples": []
      }
    ],

    "cognate": "жэн",
    "type": "verb",
    "ipa": "t͡ʃʼan"
  },
  {
    "word": "л1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "death location",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɬʼaːpʼa"
  },
  {
    "word": "жъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old",
        "examples": []
      }
    ],

    "cognate": "жьы",
    "type": "adj",
    "ipa": "ʐə"
  },
  {
    "word": "зэпычын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break off, to crack",
        "examples": [
          {
            "sentence": "К1алэм пхъэр '''зэпычыгъ'''",
            "translation": "The boy '''cracks''' the wood."
          },
          {
            "sentence": "Гъуч1ыр къинэ '''зэпыпчыныр'''",
            "translation": "It is difficult '''to break off''' an iron."
          }
        ]
      },
      {
        "meaning": "to get over",
        "examples": [
          {
            "sentence": "Гъогур к1алэм '''зэпычыгъ'''",
            "translation": "The boy '''passed over''' the road."
          }
        ]
      },
      {
        "meaning": "to overcome",
        "examples": [
          {
            "sentence": "Гъогу къин '''зэпычын'''",
            "translation": "'''To overcome '''the hard path."
          },
          {
            "sentence": "Гъинхэр '''зэпычыных'''",
            "translation": "'''To overcome''' difficulties."
          }
        ]
      }
    ],
    "synonyms": [
      "зэпыкьын"
    ],
    "type": "verb",
    "ipa": "zapət͡ʃən"
  },
  {
    "word": "зэпичын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэпычын\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zapit͡ʃən"
  },
  {
    "word": "уыбэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to completely break, to destroy, to shatter,",
        "examples": [
          {
            "sentence": "к1алэм анэр '''еубэтагъ'''",
            "translation": "The boy '''destroyed''' the table."
          },
          {
            "sentence": "к1алэм анэр '''еубатэ'''",
            "translation": "The boy '''is destroying''' the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəbatan"
  },
  {
    "word": "зандэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "straight standing",
        "examples": [
          {
            "sentence": "К1алэр '''зандэу''' щыт",
            "translation": "The boy is standing '''straight'''."
          }
        ]
      },
      {
        "meaning": "steep",
        "examples": []
      },
      {
        "meaning": "vertical",
        "examples": [
          {
            "sentence": "Пхъэр '''зандэ'''",
            "translation": "The wood is '''vertical'''."
          },
          {
            "sentence": "Пхъэр '''зандэу''' щыт",
            "translation": "The wood is standing '''vertically'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zaːnda"
  },
  {
    "word": "занк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "straight",
        "examples": [
          {
            "sentence": "Мыр '''занк1эу''' мак1уа?",
            "translation": "Is this going '''straight'''?"
          },
          {
            "sentence": "К1адэн '''занк1эу''' щыт?",
            "translation": "The boy is standing '''straight'''."
          }
        ]
      }
    ],
    "synonyms": [
      "зак1э"
    ],
    "cognate": "занщ1э",
    "type": "adj",
    "ipa": "zaːnt͡ʃa"
  },
  {
    "word": "лагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plate",
        "examples": [
          {
            "sentence": "Here are the glasses, the '''plates''' and the napkins",
            "translation": "Мары стаканхэр, '''лагъэхэр''' ык1и 1эплъэк1хэр."
          }
        ]
      },
      {
        "meaning": "colored",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laːʁa"
  },
  {
    "word": "ф1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэ\"",
        "examples": []
      }
    ],

    "cognate": "шхуэ",
    "type": "adj",
    "ipa": "fˤa"
  },
  {
    "word": "у1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wound",
        "examples": [
          {
            "sentence": "К1алэм л1Ыр '''ыу1агъ'''",
            "translation": "The boy '''wounded''' the man."
          },
          {
            "sentence": "К1алэм л1Ыр '''еу1э'''",
            "translation": "The boy '''is wounding''' the man."
          },
          {
            "sentence": "Апч ыубытагъ чаным '''укъеу1эщт'''",
            "translation": "The broken sharp window '''will wound you'''."
          },
          {
            "sentence": "'''Уу1агъа?'''",
            "translation": "'''Are you wounded?'''"
          },
          {
            "sentence": "Шъэжъыем '''сыкъиу1агъэ'''",
            "translation": "The knife '''wounded me'''."
          }
        ]
      },
      {
        "meaning": "to incise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəʔan"
  },
  {
    "word": "мэу1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get wounded",
        "examples": [
          {
            "sentence": "К1алэр '''мэу1агъ'''",
            "translation": "The boy '''is wounded'''."
          },
          {
            "sentence": "К1алэр '''мау1э'''",
            "translation": "The boy '''is being wounded'''."
          },
          {
            "sentence": "Си1э '''мэу1агъ'''",
            "translation": "My hand '''was wounded'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawʔan"
  },
  {
    "word": "жъажъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slow",
        "examples": [
          {
            "sentence": "л1ыжъэр '''жъажъэ'''",
            "translation": "The old-man '''is slow'''."
          },
          {
            "sentence": "л1ыжъэр '''жъажъэу''' мак1о",
            "translation": "The old-man is walking '''slowly'''."
          }
        ]
      },
      {
        "meaning": "sluggish",
        "examples": []
      }
    ],
    "synonyms": [
      "т1эт1эй"
    ],
    "type": "adj",
    "ipa": "ʐaːʐa"
  },
  {
    "word": "блыгуч1ад",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wadding",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləɡʷt͡ʃʼaːd"
  },
  {
    "word": "блыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fence",
        "examples": []
      },
      {
        "meaning": "rail",
        "examples": []
      },
      {
        "meaning": "wall",
        "examples": []
      }
    ],
    "synonyms": [
      "дэпкъы"
    ],
    "type": "noun",
    "ipa": "blənə"
  },
  {
    "word": "гъэсэфытхыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэсэпэтхыдэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁasafətxəda"
  },
  {
    "word": "блынык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pavement",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blənət͡ʃ"
  },
  {
    "word": "блыпкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "upper arm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləpq"
  },
  {
    "word": "1элджанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forearm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔald͡ʒaːana"
  },
  {
    "word": "блыпкъыжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "collarbone",
        "examples": []
      }
    ],
    "synonyms": [
      "тэмэжъый"
    ],
    "type": "noun",
    "ipa": "bləpqəʐəj"
  },
  {
    "word": "тэмэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "collarbone",
        "examples": []
      }
    ],
    "synonyms": [
      "блыпкъыжъый"
    ],
    "type": "noun",
    "ipa": "bləpqəʐəj"
  },
  {
    "word": "жъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small, tiny",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐəj"
  },
  {
    "word": "блыпкъышъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "upper arm bone tip",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləpqəʂħ"
  },
  {
    "word": "блыпкъыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoulder (slanting),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bləpqəɕ"
  },
  {
    "word": "блырыбл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seven by seven",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blərəbl"
  },
  {
    "word": "блэбгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoulder bone",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blabʁʷə"
  },
  {
    "word": "блэбгъуп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoulder bone tip",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blabʁʷəp"
  },
  {
    "word": "блэбгъуплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "astrologer (uses the shoulder bone for astrology)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blabʁʷəpɬ"
  },
  {
    "word": "блэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pass something, to bypass, to cross",
        "examples": [
          {
            "sentence": "К1алэр унэм '''блэк1ыгъ'''",
            "translation": "The boy '''passed''' the house."
          },
          {
            "sentence": "К1алэр лъэмыджым псынк1эу '''блэк1ыгъ'''",
            "translation": "The boy '''passed''' the bridge fast."
          },
          {
            "sentence": "Мэзит1у '''блэк1ымэ''' хым тык1ощт",
            "translation": "After two month we will go to the sea."
          },
          {
            "sentence": "Псыхъом псынк1эу '''къэблэк1'''",
            "translation": "'''Pass''' the river fast."
          },
          {
            "sentence": "Пщэрэр пчъэм '''блэк1ышъурэп'''",
            "translation": "The fat one '''can't pass''' the door."
          }
        ]
      }
    ],
    "synonyms": [
      "блэк1ьын"
    ],
    "type": "verb",
    "ipa": "blat͡ʃʼən"
  },
  {
    "word": "хэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get through, to go through",
        "examples": [
          {
            "sentence": "К1алэр псым '''хэк1ыгъ'''",
            "translation": "The boy '''got through''' the water."
          },
          {
            "sentence": "Л1эр дэпкъым '''хэк1ы'''",
            "translation": "The man '''is getting through''' the wall."
          },
          {
            "sentence": "К1алэр ц1ыфхэмэ '''яхэк1ы'''",
            "translation": "The boy '''is getting through''' the people."
          },
          {
            "sentence": "Мы ливылым '''ухэк1ышъурэба'''",
            "translation": "'''You can't pass through''' this level?"
          }
        ]
      },
      {
        "meaning": "to get out within a mass (liquid, gas or solid)",
        "examples": [
          {
            "sentence": "К1алэр псым '''хэк1ыгъ'''",
            "translation": "The boy '''got out of''' the water."
          },
          {
            "sentence": "К1алэр маш1ом '''хэк1ыгъ'''",
            "translation": "The boy '''got out of''' the fire."
          }
        ]
      },
      {
        "meaning": "to leave a group, to come out from a group.",
        "examples": [
          {
            "sentence": "Томэр к1алэхэмэ '''къахэк1эгъ'''",
            "translation": "Tom '''is coming out''' from the boys' group."
          }
        ]
      },
      {
        "meaning": "to emerge",
        "examples": []
      },
      {
        "meaning": "to get decreased",
        "examples": [
          {
            "sentence": "Температурэр '''хэк1эгъ'''",
            "translation": "The temperature '''decreased'''."
          },
          {
            "sentence": "К1элэ пщэрым '''хэк1эгъ''' къишэк1ырэ",
            "translation": "The fat boy '''lost''' some weight."
          },
          {
            "sentence": "Хым и1этгъак1э '''хэк1ы''' къемыщхэу",
            "translation": "The sea's height '''is decreasing''' when it doesn't rain."
          }
        ]
      }
    ],
    "cognate": "хэщ1ын (to get decreased)",
    "type": "verb",
    "ipa": "xakʲʼən"
  },
  {
    "word": "блэбыбык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly past something",
        "examples": [
          {
            "sentence": "Унэм бзыур '''къэблэбыбык1ыгъ'''",
            "translation": "The bird '''flew past ''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blabəbət͡ʃʼən"
  },
  {
    "word": "блэгъагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nearness",
        "examples": []
      },
      {
        "meaning": "family related",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaʁaːʁ"
  },
  {
    "word": "блэгъожъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dragon",
        "examples": []
      }
    ],
    "synonyms": [
      "благъо"
    ],
    "type": "noun",
    "ipa": "blaʁʷaʐ"
  },
  {
    "word": "блэгъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cloth friend",
        "examples": []
      },
      {
        "meaning": "old friend",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blaʁaʐ"
  },
  {
    "word": "блэгьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake skin",
        "examples": []
      }
    ],
    "synonyms": [
      "блашъо"
    ],
    "type": "noun",
    "ipa": "blaɡʲaːn"
  },
  {
    "word": "блэлъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cross quickly, to pass quickly; to rush past",
        "examples": [
          {
            "sentence": "шъэожъыер унэм '''блэлъэтыгъ'''",
            "translation": "The boy '''rushed past''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blaɬatən"
  },
  {
    "word": "блэмыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake (non-poisonous)",
        "examples": []
      }
    ],
    "synonyms": [
      "блэхьадж"
    ],
    "type": "noun",
    "ipa": "blaməw"
  },
  {
    "word": "блэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to glow (a fire or a lamp)",
        "examples": [
          {
            "sentence": "Ламбэр дэгъоу '''къаблэ'''",
            "translation": "The lamp '''glows''' good."
          }
        ]
      },
      {
        "meaning": "to braid, to weave together, intertwine (strands of fibers, ribbons, hair, etc.)",
        "examples": [
          {
            "sentence": "Пшъашъэм ышъхьэц '''еблэ'''",
            "translation": "The girl '''braiding''' her hair."
          },
          {
            "sentence": "К1апсэр '''блэ'''",
            "translation": "'''Braid''' the rope."
          }
        ]
      }
    ],

    "cognate": "блын",
    "type": "verb",
    "ipa": "blan"
  },
  {
    "word": "блэнагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boldness",
        "examples": []
      },
      {
        "meaning": "courage",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "blanaːʁ"
  },
  {
    "word": "блэнашк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Stag calf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blanaːʃkʼa"
  },
  {
    "word": "блэнэукъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grass fodder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blanawq"
  },
  {
    "word": "блэнэуц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thyme (a plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blanawt͡s"
  },
  {
    "word": "блэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look over something; to look past",
        "examples": [
          {
            "sentence": "К1алэр унэм '''блэплъы'''",
            "translation": "The boy '''is looking over''' the house."
          },
          {
            "sentence": "К1элэ к1акор дэпкъым '''блэплъышъурэп'''",
            "translation": "The short boy '''can't see over''' the wall."
          }
        ]
      },
      {
        "meaning": "to overlook",
        "examples": [
          {
            "sentence": "Хэукъоныгъэм '''блэплъын'''",
            "translation": "'''To overlook''' a mistake."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blapɬən"
  },
  {
    "word": "блэпцэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blapt͡ʃaʐəj"
  },
  {
    "word": "блэрыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chain (braided silver or gold)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blarəps"
  },
  {
    "word": "блэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to miss a target (to fail to hit)",
        "examples": [
          {
            "sentence": "К1алэр к1эрахъомк1э '''блэогъ''' тхьэк1умэк1эхьым",
            "translation": "The boy '''is missed''' the rabbit with the gun."
          },
          {
            "sentence": "К1алэр '''къэсблэуы'''",
            "translation": "The boy '''is missing me'''."
          },
          {
            "sentence": "Мыжъо сэдзым '''сыблэуи''' апчым тэзгъэфагъэ",
            "translation": "When I threw a rock, '''I missed and''' hit the glass."
          },
          {
            "sentence": "'''Ублэмыу'''",
            "translation": "'''Don't miss'''."
          },
          {
            "sentence": "'''Укъэсблэуы'''",
            "translation": "'''You are missing me'''."
          },
          {
            "sentence": "'''Блэу'''",
            "translation": "'''Miss'''."
          },
          {
            "sentence": "К1алэхэр '''блэуытых'''",
            "translation": "The boys '''will miss'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blawən"
  },
  {
    "word": "блэуц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hedge",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "blawt͡s"
  },
  {
    "word": "блэхъушъутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run past, to run through",
        "examples": [
          {
            "sentence": "конторам '''блэхъушъутын'''",
            "translation": "'''To run past''' the office."
          }
        ]
      }
    ],
    "synonyms": [
      "блэшъутын"
    ],
    "type": "verb",
    "ipa": "blaχʷəʂʷtən"
  },
  {
    "word": "ехъушъутэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to despise, to underrate, to disregard, to look down on",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''ехъушъутао'''",
            "translation": "The boy '''is despising''' the man."
          },
          {
            "sentence": "Тихабзэ '''уехъушъутао'''",
            "translation": "You are '''disregarding''' our culture."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "χʷəʂʷtawan"
  },
  {
    "word": "блэхьадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snake (non-poisonous)",
        "examples": []
      }
    ],
    "synonyms": [
      "блэмыу"
    ],
    "type": "noun",
    "ipa": "blaħaːd͡ʒ"
  },
  {
    "word": "быбыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly off",
        "examples": [
          {
            "sentence": "Бзыур'''мэбыбжьы'''",
            "translation": "The bird '''is flying away'''."
          },
          {
            "sentence": "Бжьэр унэм '''1убыбжьы'''",
            "translation": "The bee '''is flying away''' from the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bəbəʑən"
  },
  {
    "word": "быбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly",
        "examples": [
          {
            "sentence": "Бжьэр '''мэбыбы'''",
            "translation": "The bee '''is flying'''."
          },
          {
            "sentence": "Бжьэр унэм '''ибыбагъ'''",
            "translation": "The bee '''flow''' inside the house."
          },
          {
            "sentence": "Ц1ыфэр '''быбышъутэп'''",
            "translation": "The human '''can't fly'''."
          },
          {
            "sentence": "Къухьэбыбыр Америкэм '''щыбыбыщт'''",
            "translation": "The airplane '''will fly to''' America."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bəbən"
  },
  {
    "word": "быбылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airplane",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəbəɬ"
  },
  {
    "word": "быбып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airport",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəbəpʼ"
  },
  {
    "word": "быбэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crowbar",
        "examples": []
      },
      {
        "meaning": "lever",
        "examples": []
      }
    ],
    "synonyms": [
      "бэмб"
    ],
    "type": "noun",
    "ipa": "bəba"
  },
  {
    "word": "быбэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become scattered",
        "examples": []
      },
      {
        "meaning": "to flap",
        "examples": []
      },
      {
        "meaning": "to flutter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəbatan"
  },
  {
    "word": "быдзашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nursling",
        "examples": []
      },
      {
        "meaning": "suckling",
        "examples": []
      },
      {
        "meaning": "sucker",
        "examples": []
      }
    ],
    "cognate": "быдзафэ",
    "type": "noun",
    "ipa": "bəd͡zaːʂʷ"
  },
  {
    "word": "быдзыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breastmilk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəd͡zəɕ"
  },
  {
    "word": "быжъутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be excited",
        "examples": []
      },
      {
        "meaning": "to boil",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəʐʷətan"
  },
  {
    "word": "былъытырык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trot",
        "examples": []
      }
    ],
    "synonyms": [
      "мэушъы",
      "мэлъэхъу"
    ],
    "type": "noun",
    "ipa": "bəɬətərəkʷʼ"
  },
  {
    "word": "мэушъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trot",
        "examples": []
      }
    ],
    "synonyms": [
      "былъытырык1у",
      "мэлъэхъу"
    ],
    "type": "noun",
    "ipa": "mawʂə"
  },
  {
    "word": "мэлъэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trot",
        "examples": []
      }
    ],
    "synonyms": [
      "мэушъы",
      "былъытырык1у"
    ],
    "type": "noun",
    "ipa": "maɬaχʷ"
  },
  {
    "word": "былым 1аз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "veterinarian",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bələm ʔaːz"
  },
  {
    "word": "былымахъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "herdsman",
        "examples": []
      },
      {
        "meaning": "shepherd",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bələmaːχʷ"
  },
  {
    "word": "былымбэлыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turnip",
        "examples": []
      }
    ],
    "synonyms": [
      "мэзэбэлыдж"
    ],
    "type": "noun",
    "ipa": "bələmbaləd͡ʒ"
  },
  {
    "word": "мэзэбэлыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turnip",
        "examples": []
      }
    ],
    "synonyms": [
      "былымбэлыдж"
    ],
    "type": "noun",
    "ipa": "mazabaləd͡ʒ"
  },
  {
    "word": "былымтыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Cattle theft",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bələmtəʁʷ"
  },
  {
    "word": "тыгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to steal",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "təʁʷən"
  },
  {
    "word": "тыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to steal",
        "examples": [
          {
            "sentence": "к1алэр '''мэтыгъо'''",
            "translation": "The boy '''is stealing'''."
          },
          {
            "sentence": "'''умтыгъу'''",
            "translation": "'''Don't steal'''."
          },
          {
            "sentence": "шъоптыгъумэ къушъуатыгъут",
            "translation": "If you steal from him, they will steal from you."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "təʁʷan"
  },
  {
    "word": "былымыл1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pestilence, plague",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bələməɬʼa"
  },
  {
    "word": "былымыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "covetous, greedy",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bələməps"
  },
  {
    "word": "былыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "penis (as spoken by children)",
        "examples": []
      }
    ],
    "synonyms": [
      "цыулэ"
    ],
    "type": "noun",
    "ipa": "bələχʷ"
  },
  {
    "word": "быны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family",
        "examples": []
      },
      {
        "meaning": "Family children",
        "examples": []
      },
      {
        "meaning": "group",
        "examples": []
      },
      {
        "meaning": "spouse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bənə"
  },
  {
    "word": "бынынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "childless, a person without family,",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bənənt͡ʃa"
  },
  {
    "word": "быныуын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bənəwən"
  },
  {
    "word": "быраб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curly, Frizzled (the hair)",
        "examples": []
      }
    ],
    "synonyms": [
      "бырыб",
      "зэгъэлъагъэ"
    ],
    "type": "adj",
    "ipa": "bəraːb"
  },
  {
    "word": "нып",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəp"
  },
  {
    "word": "бырамыты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slave (fugitive)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəraːmətə"
  },
  {
    "word": "бырат1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat",
        "examples": []
      }
    ],
    "synonyms": [
      "быт1ы"
    ],
    "type": "adj",
    "ipa": "bəraːtʼ"
  },
  {
    "word": "быт1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat",
        "examples": []
      }
    ],
    "synonyms": [
      "бырат1"
    ],
    "cognate": "пщэры",
    "type": "adj",
    "ipa": "bətʼə"
  },
  {
    "word": "бырацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fluffy",
        "examples": [
          {
            "sentence": "чэтыуым ишъхьэц '''бырацэ'''",
            "translation": "The cat's hair is '''fluffy'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "bəraːt͡sa"
  },
  {
    "word": "быргусыргу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coarse",
        "examples": []
      },
      {
        "meaning": "crude",
        "examples": []
      },
      {
        "meaning": "rough",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bərɡʷəsərɡʷ"
  },
  {
    "word": "быргыт1ырг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rough",
        "examples": []
      },
      {
        "meaning": "rugged",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bərɣətʼərɣ"
  },
  {
    "word": "быркусын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tapeworm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bərkʷʁsən"
  },
  {
    "word": "бырсырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make trouble",
        "examples": []
      }
    ],
    "synonyms": [
      "мыхъо-мыш1агъэ зехьан"
    ],
    "type": "verb",
    "ipa": "bərsərən"
  },
  {
    "word": "зэгъэлъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curly, folded",
        "examples": []
      }
    ],
    "synonyms": [
      "быраб"
    ],
    "type": "adj",
    "ipa": "zaʁaɬaːʁa"
  },
  {
    "word": "бырыукъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fine, good",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bərəwq"
  },
  {
    "word": "быслымэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Islam, Muslim",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəsləman"
  },
  {
    "word": "бысым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "host",
        "examples": []
      },
      {
        "meaning": "owner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəsəm"
  },
  {
    "word": "бытхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flimsy, insignificant, ragged",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bətχa"
  },
  {
    "word": "быты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arch, arched, bow, convex, hunchback,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bətə"
  },
  {
    "word": "быун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "moo, roar,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəwən"
  },
  {
    "word": "быяуп1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Quiet place, refuge,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəjaːwpʼ"
  },
  {
    "word": "быяужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"быяун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bəjaːwʑən"
  },
  {
    "word": "бэ1ул",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chatty, garrulous, talkative,",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baʔʷəl"
  },
  {
    "word": "бэбахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Be lost, disappear",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "babaːħ"
  },
  {
    "word": "бэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "itch, mange",
        "examples": []
      },
      {
        "meaning": "scabby",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baɡʷ"
  },
  {
    "word": "бэгъаш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "long-lived",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baʁaːʃʼa"
  },
  {
    "word": "бэгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reproduce, to breed, to spawn, to propagate",
        "examples": []
      },
      {
        "meaning": "to multiply",
        "examples": [
          {
            "sentence": "Цыгъохэр '''мэбагъох'''",
            "translation": "The mice are '''multiplying'''."
          },
          {
            "sentence": "Ш1ухэр '''шъугъэбагъох''', дэихэр шъугъэмак1эх",
            "translation": "'''Multiply''' the goods and decrease the bad."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "baʁʷan"
  },
  {
    "word": "бэгъэгус",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Very sad",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baʁaɡʷəs"
  },
  {
    "word": "бэгыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swelled",
        "examples": []
      },
      {
        "meaning": "inflated",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baɣəʁa"
  },
  {
    "word": "бэгын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swell",
        "examples": [
          {
            "sentence": "Мыжъор инэу '''бэгыгъэ'''",
            "translation": "The rock largely '''swell'''."
          },
          {
            "sentence": "Мыжъор '''мэбэгы'''",
            "translation": "The rock '''is swelling'''."
          },
          {
            "sentence": "Слъакъо '''бэгыгъ'''",
            "translation": "My leg '''swell'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "baɣən"
  },
  {
    "word": "бэджак1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Tobacco leaves bundle",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "бэджыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rock (from time to time)",
        "examples": []
      },
      {
        "meaning": "to stagger, to reel, to sway on one's feet",
        "examples": [
          {
            "sentence": "Ешъуагъэр '''мэбэджыджэ'''.",
            "translation": "The who drank (alcohol) '''staggers'''."
          }
        ]
      },
      {
        "meaning": "to oscillate",
        "examples": []
      },
      {
        "meaning": "to totter",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bad͡ʒəd͡ʒan"
  },
  {
    "word": "бэджыхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spider net",
        "examples": []
      }
    ],
    "synonyms": [
      "бэгьэхъ"
    ],
    "type": "noun",
    "ipa": "bad͡ʒəχ"
  },
  {
    "word": "бэджэбы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fox den",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡ʒabə"
  },
  {
    "word": "бэджанд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lease, rent",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡ʒaːnd"
  },
  {
    "word": "бэджэндыку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Rent car",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡ʒandakʷ"
  },
  {
    "word": "бэджэндэутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rent",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "baʒandawtən"
  },
  {
    "word": "бэджэщыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jackal",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡ʒaɕər"
  },
  {
    "word": "бэдзау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Fly whisk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡aːw"
  },
  {
    "word": "бэдзэгъал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Fly killer (a plant), insecticide",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zaʁaːɬʼa"
  },
  {
    "word": "бэдзэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grow",
        "examples": []
      },
      {
        "meaning": "to increase",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bad͡zaʑən"
  },
  {
    "word": "бэдзэнэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mosquito, stinging",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zanaʂʷ"
  },
  {
    "word": "бэдзэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bazaar, market",
        "examples": [
          {
            "sentence": "'''Бэдзэрыр''' тыдэ щы1?",
            "translation": "Where is '''the market'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bad͡zar"
  },
  {
    "word": "бэдзэрык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dealer",
        "examples": []
      },
      {
        "meaning": "merchant",
        "examples": []
      },
      {
        "meaning": "trader",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zarəkʷʼ"
  },
  {
    "word": "бэдзэубыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Insectivore (bird), Insectivorous (bird),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zawbət"
  },
  {
    "word": "бэдзэук1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Fly killing tool",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zawəkʲ"
  },
  {
    "word": "бэжъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat",
        "examples": []
      },
      {
        "meaning": "thick body",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baʐʷ"
  },
  {
    "word": "бэк1ай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "abundant, kinda very much, kinda a lot",
        "examples": [
          {
            "sentence": "к1алэ '''бэк1айэ''' сиунэ къэк1уагъ",
            "translation": "'''A lot''' boys came to my house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "bakʲaːj"
  },
  {
    "word": "бэк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "long living plant",
        "examples": []
      }
    ],
    "type": "adj"
  },
  {
    "word": "бэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "more than",
        "examples": []
      }
    ],
    "type": "adv"
  },
  {
    "word": "бэкъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Stage (in time), step,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baqʷaʁw"
  },
  {
    "word": "бэкъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stride, to step (move on the feet)",
        "examples": [
          {
            "sentence": "Псы лъэбанэмэ занэск1э, '''бэкъон''' фае хъущтыгъэ",
            "translation": "When he reached puddles, he used to have to '''stride'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "baqʷan"
  },
  {
    "word": "бэкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cunt",
        "examples": []
      },
      {
        "meaning": "pudendum",
        "examples": []
      },
      {
        "meaning": "vulva",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baqʷ"
  },
  {
    "word": "бэкъудадз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "diaper, nappy",
        "examples": []
      }
    ],
    "synonyms": [
      "бэкъудэщ"
    ],
    "type": "noun",
    "ipa": "baqʷdaːd͡z"
  },
  {
    "word": "бэлъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "woman (fertile),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baɬf"
  },
  {
    "word": "бэлыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "radish",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baləd͡ʒ"
  },
  {
    "word": "бэлыджак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "radish seed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baləd͡ʒaːkʼa"
  },
  {
    "word": "бэлыджэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "radish juice",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baləd͡ʒaps"
  },
  {
    "word": "бэлэрыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neglectful, negligent, uninterested,",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "balarəʁ"
  },
  {
    "word": "бэнак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "assailant, fighter, wrestler,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "banaːkʷʼa"
  },
  {
    "word": "бэнжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small grave",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "banʐəj"
  },
  {
    "word": "бэнтехъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Burial cover",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bantajχʷ"
  },
  {
    "word": "бэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burial, grave, tomb",
        "examples": []
      }
    ],
    "synonyms": [
      "къэ",
      "хъэ"
    ],
    "cognate": "бэн",
    "type": "noun",
    "ipa": "banə"
  },
  {
    "word": "бэныт1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gravedigger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "banətʼ"
  },
  {
    "word": "бэнэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrestle (to the fullest)",
        "examples": []
      }
    ],
    "type": "verb"
  },
  {
    "word": "бэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fight (with fists and kicks), to wrestle, to brawl, to tussle",
        "examples": [
          {
            "sentence": "К1алэм '''себэны'''",
            "translation": "'''I am fighting''' with the boy."
          },
          {
            "sentence": "К1алэм '''мэбанэ'''",
            "translation": "The boy '''is brawling'''."
          },
          {
            "sentence": "К1алит1ор '''зэбэныжьых'''",
            "translation": "The two boys are '''fighting each other'''."
          },
          {
            "sentence": "К1алэмрэ Томымрэ '''зэбэных'''",
            "translation": "The boy and Top '''are brawling'''."
          },
          {
            "sentence": "Уишъыпкъэу л1ыжъым '''уебэныщта?'''",
            "translation": "Are you seriously '''gonna brawl''' an old-man?."
          }
        ]
      },
      {
        "meaning": "to struggle",
        "examples": [
          {
            "sentence": "К1алэр узым '''ебэны'''",
            "translation": "The boy '''is fighting (struggling)''' the disease."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "banan"
  },
  {
    "word": "бэнэныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "attack, duel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bananəʁ"
  },
  {
    "word": "бэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kiss",
        "examples": [
          {
            "sentence": "Мыгъак1о, '''усэгъэбэу'''",
            "translation": "Come '''let me kiss you'''."
          },
          {
            "sentence": "К1алэр игъусэ пшъашъэ '''ебэу'''",
            "translation": "The boy '''is kissing''' his girlfriend."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэбэух'''",
            "translation": "The boy and the girl '''are kissing'''."
          },
          {
            "sentence": "С1э '''къебыу'''",
            "translation": "'''Kiss''' my hand."
          }
        ]
      }
    ],

    "cognate": "бэуэн",
    "type": "verb",
    "ipa": "bawan"
  },
  {
    "word": "бэрауты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bless your dress",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "baraːwtə"
  },
  {
    "word": "бэрбэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barber, cupper",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "barbar"
  },
  {
    "word": "бэрджахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cunning, sly, wily",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "bard͡ʒaːħ"
  },
  {
    "word": "бэрэ1умаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blessed, fortunate, lucky,",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baraʔʷəmaːf"
  },
  {
    "word": "бэрэкъащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A person that frequently marrying, A person that merry a lot",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "baraqaːɕ"
  },
  {
    "word": "бэрэчэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blessing, boon, good",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "barakat"
  },
  {
    "word": "бэскъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fatten",
        "examples": []
      },
      {
        "meaning": "to plump",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "basqan"
  },
  {
    "word": "бэслъыный",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Beslenei (a Circassian clan),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "basɬənəj"
  },
  {
    "word": "бэткэл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a Cossack, Cossacks (people), stingy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "batkaɬʼ"
  },
  {
    "word": "бэты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "generous, liberal, openhanded",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "batə"
  },
  {
    "word": "бэхъу апщи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "god bless you",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "baχʷ aːpɕi"
  },
  {
    "word": "бэш1агъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Since a long time",
        "examples": [
          {
            "sentence": "'''бэш1агъи''' к1алэр сиунэ къэк1ожьгъэп",
            "translation": "'''Since a long time''' the boy haven't come back to my house."
          },
          {
            "sentence": "'''бэш1агъи''' услъэгъужьгъэп",
            "translation": "'''Since a long time''' I didn't see you."
          },
          {
            "sentence": "'''бэш1агъ''' хъугъэ узэрэлэжьэжьгъэ",
            "translation": "'''It have been a long time''' since you worked."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "baʃʼaːʁ"
  },
  {
    "word": "бэшэрэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bottle (glass), pitcher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baʃarab"
  },
  {
    "word": "бэщк1эрау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mace",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "бэщкъорэгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stick",
        "examples": []
      }
    ],
    "synonyms": [
      "бэщ"
    ],
    "type": "noun",
    "ipa": "baɕqʷaraʁ"
  },
  {
    "word": "бэщыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stick maker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baɕəʃʼ"
  },
  {
    "word": "бэщы1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forbearing, patient",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baɕəʔ"
  },
  {
    "word": "бэщэч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Heavy body, Heavy weight, patient,",
        "examples": []
      }
    ],
    "type": "adj"
  },
  {
    "word": "къэбэртай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Kabartay (a Circassian clan tribe)",
        "examples": []
      },
      {
        "meaning": "a Kabardian person",
        "examples": []
      }
    ],

    "cognate": "къэбэрдей",
    "type": "noun",
    "ipa": "qabartaːj"
  },
  {
    "word": "лъэ1уак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beggar (A poor person who begs for money)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaʔʷaːkʷʼa"
  },
  {
    "word": "хьэбадзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flea",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħabaːd͡za"
  },
  {
    "word": "папс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"папц1э\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "paːpsʼa"
  },
  {
    "word": "п1уак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thin",
        "examples": []
      }
    ],

    "cognate": "п1ащ1э",
    "type": "adj",
    "ipa": "pʷʼaːt͡ʃa"
  },
  {
    "word": "п1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise, to adopt",
        "examples": [
          {
            "sentence": "сэ мы к1алэ '''сып1ут'''",
            "translation": "'''I will adopt''' this boy."
          },
          {
            "sentence": "томэр аслъаным '''ип1огъ'''",
            "translation": "Aslan '''raised''' Tom."
          },
          {
            "sentence": "мы к1алэр '''яп1огъ'''",
            "translation": "'''They adopted/raised''' this boy - this boy '''was adopted'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pʷʼən"
  },
  {
    "word": "п1уаблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʷʼaːbɮa"
  },
  {
    "word": "п1ур",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pupil",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʷʼər"
  },
  {
    "word": "п1уанк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1уак1э\"",
        "examples": []
      }
    ],

    "cognate": "п1ащ1э",
    "type": "adj",
    "ipa": "pʷʼaːnt͡ʃa"
  },
  {
    "word": "п1эстхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch (With fingernails or foot-nails)",
        "examples": [
          {
            "sentence": "чэтыур '''мэп1астхъэ'''",
            "translation": "The cat '''is scratching'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pʼastχan"
  },
  {
    "word": "п1эсхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1эстхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pʼasχan"
  },
  {
    "word": "шу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шыу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəw"
  },
  {
    "word": "мэтк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тк1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matkʷʼən"
  },
  {
    "word": "мэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be trained",
        "examples": [
          {
            "sentence": "кIалэр '''мэсагъ'''",
            "translation": "The boy is '''trained'''."
          },
          {
            "sentence": "кIалэр '''мэсагъэп'''",
            "translation": "The boy is '''is not trained'''."
          }
        ]
      },
      {
        "meaning": "to be taught how to behave well.",
        "examples": [
          {
            "sentence": "кIалэр '''мэсэрагъоп'''",
            "translation": "The boy '''does not want to learn how to behave-well'''."
          },
          {
            "sentence": "кIалэр '''мэсагъ'''",
            "translation": "The boy '''taught how to behave-well'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "masan"
  },
  {
    "word": "мэш1ык1эен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ык1эен\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэш1ык1ьэен"
    ],
    "type": "verb",
    "ipa": "maʃʼət͡ʃʼajan"
  },
  {
    "word": "щы1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "exist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəʔ"
  },
  {
    "word": "ш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to know",
        "examples": [
          {
            "sentence": "'''Сэш1э''' ар",
            "translation": "'''I know''' that."
          },
          {
            "sentence": "Урысыбзэ '''сш1эрэп'''",
            "translation": "'''I don't know''' the Russian language."
          },
          {
            "sentence": "'''Сэш1э''' къысэп1уагъэ",
            "translation": "'''I know''' what you told me."
          },
          {
            "sentence": "'''Сымыш1эрэ''' а к1алэм къуи1уагъэхэр",
            "translation": "'''I don't know''' the thing that boy told you."
          },
          {
            "sentence": "Сэ дзэмк1э сыш1ыхэрэр '''яш1эу''' хъутыхэп",
            "translation": "'''They''' are not allowed '''to know''' what I am doing in the army."
          },
          {
            "sentence": "Тхьапщ '''ош1а''' пл1ым щы хэбгъахъомэ?",
            "translation": "'''Do you know''' how much if you add three to four."
          }
        ]
      },
      {
        "meaning": "to do (an action)",
        "examples": [
          {
            "sentence": "Сэлам, сыд джы '''пш1эрэр'''?",
            "translation": "Hello, what '''you're doing''' now?"
          },
          {
            "sentence": "Л1ым зи '''ыш1эрэп'''",
            "translation": "The man '''does nothing'''."
          },
          {
            "sentence": "Джы сыд мыщ '''епш1эщтыр'''?",
            "translation": "Now what '''you gonna do with''' this?"
          },
          {
            "sentence": "Сыд '''шъуш1эрэр'''?",
            "translation": "What '''you (pl.) are doing'''?"
          },
          {
            "sentence": "Мыщтэу '''умыш1'''",
            "translation": "'''Don't do it''' like this."
          },
          {
            "sentence": "Л1ымэ 1офы '''аш1эрэр'''",
            "translation": "The men '''are doing''' work."
          },
          {
            "sentence": "1офы мы щэджагъом '''сыш1агъ'''.",
            "translation": "'''I did''' work this noon."
          },
          {
            "sentence": "К1алэм 1офы дэгъу '''ыш1эрэр'''.",
            "translation": "The boy '''does''' good work."
          },
          {
            "sentence": "Джырэк1э зыгорэ '''сэш1э'''.",
            "translation": "'''I am doing''' something for now."
          }
        ]
      }
    ],
    "synonyms": [
      "щкъэн"
    ],
    "cognate": "щ1эн",
    "type": "verb",
    "ipa": "ʃˈan"
  },
  {
    "word": "къаплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tiger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːpɬan"
  },
  {
    "word": "ч1ыдагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "petroleum",
        "examples": []
      }
    ],
    "synonyms": [
      "кудэ",
      "шъотэгын"
    ],
    "type": "noun",
    "ipa": "t͡ʃədaːʁ"
  },
  {
    "word": "кудэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "petroleum",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1ыдагъ",
      "шъотэгын"
    ],
    "type": "noun",
    "ipa": "kʷəda"
  },
  {
    "word": "шъотэгын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "petroleum",
        "examples": []
      }
    ],
    "synonyms": [
      "кудэ",
      "ч1ыдагъ"
    ],
    "type": "noun",
    "ipa": "ʂʷataɣən"
  },
  {
    "word": "ч1ык1ухьныкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "longitude",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəkʷˈəħnəqʷ"
  },
  {
    "word": "ч1ылъэныкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "district, province, territory",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəɬanəqʷ"
  },
  {
    "word": "лъэныкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one side of",
        "examples": [
          {
            "sentence": "Итамэ '''лъэныкъоми''' осышхор къыщесымэ",
            "translation": "On '''one side of''' his shoulder huge snow is snowing."
          }
        ]
      },
      {
        "meaning": "side",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬanəqʷ"
  },
  {
    "word": "ч1ылъэтеплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "landscape, territory",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəɬatajpɬ"
  },
  {
    "word": "ч1ымэтх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "geology",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəmatx"
  },
  {
    "word": "ч1ыопс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Natural ambience, nature",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəwaps"
  },
  {
    "word": "ч1ыопс к1ыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "metaphysics",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ч1ыплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beet",
        "examples": []
      }
    ],
    "synonyms": [
      "гыныплъ"
    ],
    "type": "noun",
    "ipa": "t͡ʃəpɬ"
  },
  {
    "word": "гыныплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beet",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1ыплъ"
    ],
    "type": "noun",
    "ipa": "ɣənəpɬ"
  },
  {
    "word": "ч1ыпчъынш1эныгъэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "geodesy",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ч1ытхъыцэхэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coordinates",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃətχət͡saxar"
  },
  {
    "word": "ч1ыгъык1элъыбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "code",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəʁət͡ʃaɬəbz"
  },
  {
    "word": "чъыг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чъыгы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʂəɣ"
  },
  {
    "word": "чы1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "buttonhole",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəʔʷən"
  },
  {
    "word": "чыдан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Iron cable, Iron wire",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃədaːn"
  },
  {
    "word": "лъэецуакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boots",
        "examples": []
      }
    ],
    "synonyms": [
      "чызмэ"
    ],
    "type": "noun",
    "ipa": "ɬajat͡sʷaːqʷa"
  },
  {
    "word": "чылэдэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "resident",
        "examples": []
      },
      {
        "meaning": "village dweller, villager",
        "examples": []
      }
    ],
    "synonyms": [
      "коил"
    ],
    "cognate": "жылэдэс",
    "type": "noun",
    "ipa": "t͡ʃəɮadas"
  },
  {
    "word": "чыфыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Jewish",
        "examples": []
      }
    ],
    "synonyms": [
      "чэтыщх",
      "к1этыщх"
    ],
    "type": "noun",
    "ipa": "t͡ʃəfət"
  },
  {
    "word": "чэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barracks, kiosk",
        "examples": []
      },
      {
        "meaning": "shelter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃal"
  },
  {
    "word": "чэпзэхэогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dusk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃapzaxawaʁʷ"
  },
  {
    "word": "чэрэуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "caravan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "karawaːn"
  },
  {
    "word": "чэсэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cotton cloth, Print cloth, White gauze,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃasaj"
  },
  {
    "word": "чэц1ыбжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dates, palm",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "чэщ щыгъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pajamas",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕ ɕəʁən"
  },
  {
    "word": "фэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tendon (tough band of inelastic fibrous tissue that connects a muscle with its bony attachment)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "fa"
  },
  {
    "word": "нэгъэц1ыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "money",
        "examples": []
      }
    ],
    "synonyms": [
      "ахъщэ"
    ],
    "cognate": "ахъшэ",
    "type": "noun",
    "ipa": "naʁat͡sʼəw"
  },
  {
    "word": "шэкэрхафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "candy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃakʲarxaːfa"
  },
  {
    "word": "хьандзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rick",
        "examples": []
      },
      {
        "meaning": "stack",
        "examples": []
      }
    ],

    "cognate": "хьэвэ",
    "type": "noun",
    "ipa": "ħaːnd͡zʷə"
  },
  {
    "word": "хьандзуач1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lower rick",
        "examples": []
      },
      {
        "meaning": "stack's bottom",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːnd͡zʷaːt͡ʃ"
  },
  {
    "word": "ебз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "waste",
        "examples": [
          {
            "sentence": "Чэмым и '''ебз'''",
            "translation": "A cow's '''waste'''."
          },
          {
            "sentence": "Унэр '''ебзыхэм''' яфэдэу къэлъагъо",
            "translation": "The house looks like a '''waste'''."
          }
        ]
      },
      {
        "meaning": "dung, manure (fertilizer)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jabz"
  },
  {
    "word": "хьэлъэбэкъищ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "three steps (Said to places that are very close)",
        "examples": [
          {
            "sentence": "Тиунэ '''хьэлъэбэкъищ''', псынк1эу к1о",
            "translation": "My house is '''three steps close (very close)''', go quickly."
          },
          {
            "sentence": "Яунэ к1о псынк1эу. Яунэ '''хьэлъэбэкъищ''' нэ1э",
            "translation": "Go to their house quickly, their house is only '''three steps close'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaɬabaqiɕ"
  },
  {
    "word": "пэпскъик1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cooled, chilled",
        "examples": [
          {
            "sentence": "'''спэпскъик1'''",
            "translation": "'''I am cooled'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "papsqit͡ʃ"
  },
  {
    "word": "к1элъыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1элъыон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1элъыон"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaɬəwən"
  },
  {
    "word": "хьэлъэубакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэубакъу\"",
        "examples": []
      }
    ],

    "cognate": "лъэбакъуэ",
    "type": "noun",
    "ipa": "ħaɬawbaːq"
  },
  {
    "word": "хъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug dialect) pig",
        "examples": [],
        "tags": [
          "Shapsug dialect"
        ]
      },
      {
        "meaning": "(very rare) herd",
        "examples": [],
        "tags": [
          "very rare"
        ]
      }
    ],
    "synonyms": [
      "къо"
    ],
    "cognate": "кхъуэ",
    "type": "noun",
    "ipa": "χʷa"
  },
  {
    "word": "къо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "son",
        "examples": []
      },
      {
        "meaning": "pig",
        "examples": []
      }
    ],
    "synonyms": [
      "хъо (Shapsug)"
    ],
    "cognate": "къуэ (son); кхъуэ (pig)",
    "type": "noun",
    "ipa": "qʷa"
  },
  {
    "word": "жъалым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cruel",
        "examples": []
      },
      {
        "meaning": "unjust",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐaːləm"
  },
  {
    "word": "сыд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "what",
        "examples": [
          {
            "sentence": "'''сыд''' о уиц1э?",
            "translation": "'''what''' is your name?"
          },
          {
            "sentence": "'''сыд''' пш1ырэ?",
            "translation": "'''what''' are you doing?"
          },
          {
            "sentence": "'''сыд''' к1алэм къы1уагъэр?",
            "translation": "'''What''' did the boy say?"
          }
        ]
      }
    ],
    "synonyms": [
      "л1эу",
      "шъыд"
    ],
    "cognate": "сыт",
    "type": "pron",
    "ipa": "səd"
  },
  {
    "word": "лъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"л1эу\"",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "ɬaw"
  },
  {
    "word": "хьапщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьапш\"",
        "examples": []
      }
    ],
    "type": "pron",
    "ipa": "ħapɕ"
  },
  {
    "word": "тэщтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how",
        "examples": [
          {
            "sentence": "'''тэщту''' ар уш1э?",
            "translation": "'''how''' you do that?"
          },
          {
            "sentence": "'''тэщту''' уэк1о?",
            "translation": "'''how''' you walk?"
          }
        ]
      }
    ],
    "synonyms": [
      "сыдэущтэу"
    ],
    "type": "pron",
    "ipa": "taɕtaw"
  },
  {
    "word": "сыдэущтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how",
        "examples": [
          {
            "sentence": "'''сыдэущтэу''' ар уш1э?",
            "translation": "'''how''' you do that?"
          },
          {
            "sentence": "'''сыдэущтэу''' уэк1о?",
            "translation": "'''how''' you walk?"
          }
        ]
      }
    ],
    "synonyms": [
      "тэщтэу"
    ],
    "type": "pron",
    "ipa": "sədawɕtaw"
  },
  {
    "word": "пхъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carrot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχə"
  },
  {
    "word": "къэбастхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cauliflower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabaːstχa"
  },
  {
    "word": "къэбы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "zucchini",
        "examples": []
      },
      {
        "meaning": "marrow (vegetables)",
        "examples": []
      },
      {
        "meaning": "squash",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabə"
  },
  {
    "word": "къоны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "parsley",
        "examples": []
      },
      {
        "meaning": "coriander (especially leaves)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷanə"
  },
  {
    "word": "бэрэт1ин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mint",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baratˈij"
  },
  {
    "word": "ч1ырык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "potato",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃˈərəkʷˈ"
  },
  {
    "word": "къэбыпц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eggplant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabəpt͡sˈa"
  },
  {
    "word": "цугу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tomato",
        "examples": []
      }
    ],

    "cognate": "бэдрэжан",
    "type": "noun",
    "ipa": "t͡ʃʷəɡʷ"
  },
  {
    "word": "къэлъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lettuce",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaɬə"
  },
  {
    "word": "къужъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pear",
        "examples": []
      }
    ],
    "synonyms": [
      "кхъужъы"
    ],
    "cognate": "кхъужьы",
    "type": "noun",
    "ipa": "qʷəʐə"
  },
  {
    "word": "мышк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "acorn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məʃkʷˈ"
  },
  {
    "word": "цумпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strawberry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʷəmpa"
  },
  {
    "word": "пчэдыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cherry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃadəʁʷa"
  },
  {
    "word": "хъурмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "date palm",
        "examples": []
      },
      {
        "meaning": "date palm tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷərma"
  },
  {
    "word": "къо1ус",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "banana",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʔʷəs"
  },
  {
    "word": "къыпц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plum",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qəpt͡ʃˈa"
  },
  {
    "word": "нарэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pomegranate",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːra"
  },
  {
    "word": "т1амы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "orange (Fruit)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tˈaːmə"
  },
  {
    "word": "мы1эрыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apple",
        "examples": []
      }
    ],
    "synonyms": [
      "мые"
    ],
    "type": "noun",
    "ipa": "məʔarəs"
  },
  {
    "word": "зэндалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apricot",
        "examples": []
      },
      {
        "meaning": "apricot tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zandaːla"
  },
  {
    "word": "хьэкъухъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mulberry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaqʷəʐ"
  },
  {
    "word": "нэшэгъожь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "muskmelon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʃaʁʷaʑ"
  },
  {
    "word": "къэплъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tiger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qapɬaːn"
  },
  {
    "word": "махъушэчэтыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheetah",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχʷəʃat͡ʃatəw"
  },
  {
    "word": "бэджэшъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "[]",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "baːd͡ʒaʂ"
  },
  {
    "word": "1эндыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hyaena",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔandəq"
  },
  {
    "word": "маймун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "monkey",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "majmun"
  },
  {
    "word": "бжъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bʐaw"
  },
  {
    "word": "мэзыпчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэзпчэн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazəpt͡ʃan"
  },
  {
    "word": "тхыцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rhino",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txət͡ʃa"
  },
  {
    "word": "чэтыушыумыш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bobcat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲatəwʃəeməʃ"
  },
  {
    "word": "мэзчэтыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leopard",
        "examples": []
      },
      {
        "meaning": "wildcat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazkʲatəw"
  },
  {
    "word": "шъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷaːja"
  },
  {
    "word": "псысамыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beaver",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəsaːmər"
  },
  {
    "word": "хыкъохъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "elephant seal",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəqʷaχʷ"
  },
  {
    "word": "псыцу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "water buffalo",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psət͡sʷa"
  },
  {
    "word": "хьэдэпчэмы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turtle",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэнчэмы1у"
    ],
    "cognate": "шылъэгу",
    "type": "noun",
    "ipa": "ħadapt͡ʃaməʔʷ"
  },
  {
    "word": "хымац1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lobster",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəmaːt͡sˈ"
  },
  {
    "word": "хытыгъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shark",
        "examples": []
      }
    ],
    "synonyms": [
      "акул",
      "хыхьэ"
    ],
    "type": "noun",
    "ipa": "xətəʁʷəʐ"
  },
  {
    "word": "къатыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mule",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːtər"
  },
  {
    "word": "чъыгшъхьацыз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squirrel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəɣʂħaːt͡ʃəz"
  },
  {
    "word": "кукуу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cuckoo",
        "examples": []
      }
    ],

    "cognate": "к1ыгуугу",
    "type": "noun",
    "ipa": "kʷəkʷəw"
  },
  {
    "word": "махъчэбзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ostrich",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχt͡ʃabzəw"
  },
  {
    "word": "махъушэбзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ostrich",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːχʷʃabzəw"
  },
  {
    "word": "хыбзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "albatross",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəbzəw"
  },
  {
    "word": "къэрэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stork (bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaraw"
  },
  {
    "word": "хьарз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swan (Bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħarz"
  },
  {
    "word": "хьэдэкъаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swan (Bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħadaqaːz"
  },
  {
    "word": "жэгъуулал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pelican",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaʁʷəwlaːl"
  },
  {
    "word": "бзушхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "[]",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwʃχʷ"
  },
  {
    "word": "бзудэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grouse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwdaɡʷ"
  },
  {
    "word": "мэзчэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Black partridge (bird)",
        "examples": []
      },
      {
        "meaning": "pheasant",
        "examples": []
      }
    ],

    "cognate": "мэзджэд",
    "type": "noun",
    "ipa": "mazt͡ʃat"
  },
  {
    "word": "хьэбзу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "[]",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħabzəw"
  },
  {
    "word": "бзук1эзэгох",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Bird with split tail,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzəwt͡ʃazaɡʷax"
  },
  {
    "word": "дышъэчэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peafowl (Bird)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dəʐat͡ʃat"
  },
  {
    "word": "псычэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "duck",
        "examples": []
      }
    ],
    "synonyms": [
      "псыкьэт"
    ],
    "cognate": "псыджэд",
    "type": "noun",
    "ipa": "psət͡ʃat"
  },
  {
    "word": "ч1ыбзу",
    "lang": "Adyghe",
    "definitions": [],
    "type": "noun",
    "ipa": "t͡ʃˈəbzəw"
  },
  {
    "word": "пхъац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bug",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχaːt͡sˈa"
  },
  {
    "word": "чъыгы шъуамп1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "[]",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "мац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "locust",
        "examples": []
      },
      {
        "meaning": "cricket",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːt͡sʼa"
  },
  {
    "word": "хьэнт1эрэп1ый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "butterfly",
        "examples": []
      }
    ],
    "synonyms": [
      "хьамп1ырашъу",
      "мэш1уахь",
      "хьэт1рэп1ый"
    ],
    "cognate": "хьэндырабгъуэ",
    "type": "noun",
    "ipa": "ħantˈarapˈəj"
  },
  {
    "word": "щыбжьэдум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hornet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəbʑadum"
  },
  {
    "word": "бэджы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spider",
        "examples": []
      }
    ],
    "synonyms": [
      "багьэ"
    ],
    "type": "noun",
    "ipa": "bad͡ʒə"
  },
  {
    "word": "п1эст1эк1эшып",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cockroach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pˈastˈat͡ʃaʃəp"
  },
  {
    "word": "пк1ау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grasshopper",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃaːw"
  },
  {
    "word": "ц1ы1ргъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tsˈəʔrʁ"
  },
  {
    "word": "хьант1аркъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "frog",
        "examples": []
      }
    ],
    "synonyms": [
      "хьантыкъуакъу"
    ],
    "cognate": "хьэндыркъуакъуэ",
    "type": "noun",
    "ipa": "ħantˈaːrqʷ"
  },
  {
    "word": "хьамплъыжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lizard, chameleon",
        "examples": []
      }
    ],
    "synonyms": [
      "щыдырхъуае"
    ],
    "type": "noun",
    "ipa": "ħaːmpɬəʑ"
  },
  {
    "word": "лэжьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person that works a lot, a person that works hard",
        "examples": []
      },
      {
        "meaning": "worker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laʑaːkʷʼa"
  },
  {
    "word": "лэгъупкъопс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лэгъупэкъопс\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laʁʷəpʕʷaps"
  },
  {
    "word": "щыуанкъопс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rainbow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəwaːnqʷaps"
  },
  {
    "word": "къопс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handle",
        "examples": []
      },
      {
        "meaning": "knob",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпэпс",
      "1эубытып1э"
    ],
    "type": "noun",
    "ipa": "qʷaps"
  },
  {
    "word": "дакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handle",
        "examples": [
          {
            "sentence": "к1эрэхъо '''дакъ'''",
            "translation": "revolver '''handle'''."
          }
        ]
      },
      {
        "meaning": "root",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːqa"
  },
  {
    "word": "къазгъыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spade, shovel",
        "examples": []
      }
    ],

    "cognate": "хьэнцэ",
    "type": "noun",
    "ipa": "qaːzʁər"
  },
  {
    "word": "пщэры",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat",
        "examples": [
          {
            "sentence": "к1алэр '''пщэры'''",
            "translation": "The boy '''is fat'''."
          },
          {
            "sentence": "'''зыгъэпщэры'''",
            "translation": "'''Make yourself fat'''."
          },
          {
            "sentence": "бэрэ шхи '''пщэрэу''' хъут",
            "translation": "Eat a lot and you will become '''fat'''."
          },
          {
            "sentence": "'''упщэрынэу''' уфая?",
            "translation": "Do you want to be '''fat'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "быт1ы"
    ],
    "type": "adj",
    "ipa": "pɕarə"
  },
  {
    "word": "цэлы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gum (The flesh round the teeth.)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡salə"
  },
  {
    "word": "мак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Literary) few",
        "examples": [
          {
            "sentence": "к1элэ '''мак1''' и1э",
            "translation": "He have '''few''' boys."
          },
          {
            "sentence": "ахъшэ '''мак1э''' къыситгъэ",
            "translation": "He gave me '''few''' money."
          },
          {
            "sentence": "к1элэхэр '''мак1эу''' екъух",
            "translation": "The boys are '''few'''."
          },
          {
            "sentence": "ахъшэ мак1э къыситгъэ",
            "translation": "The beautiful '''boy'''."
          },
          {
            "sentence": "'''мак1э''' иш1эрэ",
            "translation": "'''Few''' what he knows - '''Few''' what he does."
          },
          {
            "sentence": "л1ыжъым иныбжьэ '''мак1эп'''",
            "translation": "The age of the old man '''is not little'''."
          },
          {
            "sentence": "к1элэхэмэ сибэнанэ яшхыгъым фэшк1э, бэнанэу си1э нэхь '''мак1эу''' мэхъогъ",
            "translation": "Because the boys ate my bananas, my bananas that I have become '''fewer'''."
          }
        ],
        "tags": [
          "Literary"
        ]
      }
    ],
    "synonyms": [
      "тэк1у"
    ],
    "type": "adv",
    "ipa": "maːt͡ʃa"
  },
  {
    "word": "ц1ыфхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "people",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʼəfxa"
  },
  {
    "word": "ныбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nest",
        "examples": []
      }
    ],
    "synonyms": [
      "набгъу"
    ],
    "type": "noun",
    "ipa": "nəbʁa"
  },
  {
    "word": "мыджыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "introvert",
        "examples": []
      },
      {
        "meaning": "secluded",
        "examples": []
      },
      {
        "meaning": "unripe",
        "examples": [
          {
            "sentence": "бэнанэр '''мыджыр'''",
            "translation": "unripe banana."
          },
          {
            "sentence": "зэйтын '''мыджыр''' сик1ас",
            "translation": "I like unripe olive (bitter olive)."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "məd͡ʒər"
  },
  {
    "word": "мыгьыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мыджыр\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "məɡʲər"
  },
  {
    "word": "ехъунк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rip off",
        "examples": []
      },
      {
        "meaning": "to steal",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaχʷənt͡ʃən"
  },
  {
    "word": "мэт1ыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comb",
        "examples": []
      },
      {
        "meaning": "Curly (become) (hair)",
        "examples": []
      },
      {
        "meaning": "ripen",
        "examples": [
          {
            "sentence": "бэнанэр '''мэтIыгъо'''",
            "translation": "the banana is ripen."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "matˈəʁʷa"
  },
  {
    "word": "джэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Djagu (a Circassian dance party)",
        "examples": []
      },
      {
        "meaning": "game",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaɡʷ"
  },
  {
    "word": "гьэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэгу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʲaɡʷ"
  },
  {
    "word": "джэгуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaɡʷaːɬa"
  },
  {
    "word": "игъо хъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become ripen",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəʁʷa χʷən"
  },
  {
    "word": "мэзахэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "darkness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazaːxa"
  },
  {
    "word": "джаур",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "infidel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːwər"
  },
  {
    "word": "сымагьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сымаджэ\"",
        "examples": []
      }
    ],

    "cognate": "сымаджэ",
    "type": "adj",
    "ipa": "səmaːɡʲa"
  },
  {
    "word": "сымаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sick, ill",
        "examples": []
      }
    ],
    "synonyms": [
      "сымагьэ"
    ],
    "type": "adj",
    "ipa": "səmaːd͡ʒa"
  },
  {
    "word": "пс1ырый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пц1ырый\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "psʼərəj"
  },
  {
    "word": "с1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1э\"",
        "examples": []
      }
    ],

    "cognate": "ц1э",
    "type": "noun",
    "ipa": "sʼa"
  },
  {
    "word": "ш1ус1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1уц1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1уц1э"
    ],
    "cognate": "ф1ыц1э",
    "type": "noun",
    "ipa": "ʃʷʼəsʼa"
  },
  {
    "word": "ренэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "always",
        "examples": [
          {
            "sentence": "Адыгэ лъэпкъэ '''ренэу''' шы1энэу",
            "translation": "The Adyghe people to be exist '''forever'''."
          },
          {
            "sentence": "'''Ренэу''' бгыузым сегъэгумэкIы",
            "translation": "I '''always''' have back pain."
          }
        ]
      }
    ],
    "synonyms": [
      "енэу"
    ],
    "type": "adv",
    "ipa": "rajnaw"
  },
  {
    "word": "ашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1ашэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːʃa"
  },
  {
    "word": "джары",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that is",
        "examples": [
          {
            "sentence": "'''джары''' и1уагъэ",
            "translation": "'''That is''' what he said."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːrə"
  },
  {
    "word": "яжьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ash",
        "examples": []
      }
    ],
    "synonyms": [
      "стаф",
      "----"
    ],
    "type": "noun",
    "ipa": "jaːʑa"
  },
  {
    "word": "кьэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug) alternative form of \"чэты\"",
        "examples": [],
        "tags": [
          "Shapsug",
          "alternative_spelling"
        ]
      }
    ],
    "type": "noun",
    "ipa": "kʲat"
  },
  {
    "word": "к1ьэрахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эрахъо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲʼaraːχʷa"
  },
  {
    "word": "к1ьапсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1апсэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲʼaːpsa"
  },
  {
    "word": "к1ьэхьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ыхьэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "kʲʼaħə"
  },
  {
    "word": "кьэтыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cat",
        "examples": []
      }
    ],
    "synonyms": [
      "чэтыу"
    ],
    "cognate": "джэду",
    "type": "noun",
    "ipa": "kʲatəw"
  },
  {
    "word": "пк1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pkʲʼan"
  },
  {
    "word": "к1ьак1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1энк1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1энк1э"
    ],
    "type": "noun",
    "ipa": "kʲʼaːkʲʼa"
  },
  {
    "word": "псынк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fast",
        "examples": [
          {
            "sentence": "Жьым фэдэу '''псынк1э'''",
            "translation": "as '''fast''' as wind."
          }
        ]
      },
      {
        "meaning": "nimble",
        "examples": []
      },
      {
        "meaning": "light (Of low weight; not heavy)",
        "examples": []
      }
    ],
    "synonyms": [
      "псык1э",
      "к1ык1ьай"
    ],
    "cognate": "псынщ1э",
    "type": "adj",
    "ipa": "psənt͡ʃʼa"
  },
  {
    "word": "жъоку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firebrand",
        "examples": []
      },
      {
        "meaning": "ember",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷakʷ"
  },
  {
    "word": "гъэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to turn someone/something to; to make someone/something become",
        "examples": [
          {
            "sentence": "Тхьэматэу '''узгъэхъущт'''",
            "translation": "'''I will turn you into''' a boss."
          },
          {
            "sentence": "Cэ к1алэр л1эу '''сыгъэхъугъ'''",
            "translation": "I '''turned''' the boy to a man."
          },
          {
            "sentence": "Дахэу '''узгъэхъущт'''",
            "translation": "I '''will make you''' pretty."
          },
          {
            "sentence": "Бзылъфыгъэм къэгъагъэхэр дахэу '''егъэхъух'''",
            "translation": "The woman '''is turning''' the flowers to beautiful flowers."
          },
          {
            "sentence": "Гъожьырэ шхъуант1эрэ зэхэбгъахьэхэмэ уцышъоу '''хъущтых'''",
            "translation": "If you mix yellow and blue, '''They will become''' green."
          }
        ]
      },
      {
        "meaning": "to grow plants (to make plants \"appear\")",
        "examples": [
          {
            "sentence": "К1алэм къэгъагъэхэр '''егъэхъух'''",
            "translation": "The boy '''is growing''' the flowers."
          }
        ]
      },
      {
        "meaning": "to herd",
        "examples": [
          {
            "sentence": "Чэмыр  '''гъэхъун ''' фае",
            "translation": "The cows need '''to be herded'''."
          },
          {
            "sentence": "Шыр дэщыгъэу  '''гъэхъун ''' фае",
            "translation": "The horse must '''be herded''' while leaded."
          },
          {
            "sentence": "Чэм 1эхъогъур '''гъэхъун'''",
            "translation": "'''To herd''' a herd of cows"
          }
        ]
      },
      {
        "meaning": "to create from nothing (usually by God)",
        "examples": [
          {
            "sentence": "Тхьэм дунаир мэфихык1э '''къыгъэхъугъ'''",
            "translation": "God '''has created''' the world in six days."
          }
        ]
      },
      {
        "meaning": "to make someone/something appear from nowhere",
        "examples": [
          {
            "sentence": "Удым л1ыр унэм '''къыригъэхъуагъ'''",
            "translation": "The sorcerer '''made''' the man '''appear inside''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaχʷən"
  },
  {
    "word": "1умпэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A person that doesn't eat",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔʷampam"
  },
  {
    "word": "мэзкьэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэзчэт\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazkʲat"
  },
  {
    "word": "лъащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lame",
        "examples": [
          {
            "sentence": "Л1ыжъыр '''лъащэу''' мак1о",
            "translation": "The old man goes '''lamely'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬaːɕa"
  },
  {
    "word": "гъуаплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "copper",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaːpɬa"
  },
  {
    "word": "жъагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lung",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐaːʁ"
  },
  {
    "word": "жъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fried, grilled",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐaːʁa"
  },
  {
    "word": "жъамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Smell of frying, Smell of grilling,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐaːma"
  },
  {
    "word": "жъанч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Sound of smack or hitting",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐaːnt͡ʃ"
  },
  {
    "word": "жъгъырыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bell, Bell (small), cowbell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʁərəw"
  },
  {
    "word": "жъгъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coin",
        "examples": [
          {
            "sentence": "'''Жъгъэй''' си1эп",
            "translation": "I have '''no change''' (no coin)."
          }
        ]
      },
      {
        "meaning": "small, tiny",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхъэи",
      "шъхъэ (Shapsug)"
    ],
    "cognate": "ц1ык1у",
    "type": "noun",
    "ipa": "ʐʁaj"
  },
  {
    "word": "жъогъо щы1эныгъэл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "astronomer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷa ʃˈʔanəʁapɬʼ"
  },
  {
    "word": "жъогъобын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "candelabra",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷabən"
  },
  {
    "word": "жъогъок1эк1ахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷat͡ʃat͡ʃaːħ"
  },
  {
    "word": "жъогъопл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "astrology",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁapɬˈan"
  },
  {
    "word": "жъогъозек1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "planet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷazekʷˈ"
  },
  {
    "word": "жъогъорыплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "telescope",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷarəpɬ"
  },
  {
    "word": "жъогъофах",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meteor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷafaːx"
  },
  {
    "word": "жъогъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Easy to boil, Farmable land",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐʷaʁʷaʃʷˈ"
  },
  {
    "word": "жъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Plowing comrade",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷ"
  },
  {
    "word": "жъогъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Difficult to boil, Unfarmable land,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷaːja"
  },
  {
    "word": "жъогъуаплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "astrologer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁaːpɬa"
  },
  {
    "word": "жъогъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Plowed land",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "жъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to plow, to plough",
        "examples": [
          {
            "sentence": "Ч1ыгур '''жъон'''",
            "translation": "'''To plow''' the land."
          }
        ]
      },
      {
        "meaning": "to boil",
        "examples": [
          {
            "sentence": "Псыр '''мажъо'''",
            "translation": "'''To boil''' the water."
          }
        ]
      },
      {
        "meaning": "(slang) to grind (to repeat a task a large number of times in a row to achieve a specific goal)",
        "examples": [],
        "tags": [
          "slang"
        ]
      }
    ],

    "cognate": "вэн",
    "type": "verb",
    "ipa": "ʐʷan"
  },
  {
    "word": "гъэсэныгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cultivation, culture, training",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁasanəʁa"
  },
  {
    "word": "жъонак1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷanaːkʷˈ"
  },
  {
    "word": "жъоныгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Farming season",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷanəʁʷ"
  },
  {
    "word": "жъоныгъуак1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "End of farming season",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷanəʁʷaːt͡ʃ"
  },
  {
    "word": "жъоплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "reddish brown",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷapɬ"
  },
  {
    "word": "жъопхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Daughter of foster mother,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷapχʷ"
  },
  {
    "word": "жъоркъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "warm (weather)",
        "examples": [
          {
            "sentence": "Гъэмафэм '''жъоркъы'''",
            "translation": "The summer is '''warm'''."
          },
          {
            "sentence": "Непэ '''жъоркъ'''",
            "translation": "It is '''warm''' today."
          }
        ]
      },
      {
        "meaning": "hot air, hot weather, hot spell",
        "examples": []
      }
    ],
    "synonyms": [
      "фэбае"
    ],
    "type": "adj",
    "ipa": "ʐʷarq"
  },
  {
    "word": "ык1и",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and",
        "examples": [
          {
            "sentence": "К1алэр к1эхьы '''ык1и''' к1уач1э",
            "translation": "The boy is long '''and''' strong."
          },
          {
            "sentence": "Сэ сышхэт '''ык1и''' псы сешъот",
            "translation": "I will eat '''and''' drink water."
          },
          {
            "sentence": "Ц1ыфхэр санэпсы '''ык1и''' пивэ ешъох",
            "translation": "The people are drinking wine '''and''' beer."
          }
        ]
      },
      {
        "meaning": "by the way",
        "examples": []
      }
    ],
    "synonyms": [
      "гьи"
    ],
    "type": "adv",
    "ipa": "ət͡ʃʼəj"
  },
  {
    "word": "щэгьэгъожь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэджэгъоуж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaɡʲaʁʷaʒ"
  },
  {
    "word": "цуакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoes",
        "examples": []
      },
      {
        "meaning": "footwear",
        "examples": []
      }
    ],
    "synonyms": [
      "чъуакъо",
      "щырыкъу"
    ],
    "cognate": "вакъэ",
    "type": "noun",
    "ipa": "t͡sʷaːqa"
  },
  {
    "word": "гьи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ык1и\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ык1и"
    ],
    "type": "adv",
    "ipa": "ɡʲəj"
  },
  {
    "word": "жъот",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noise",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷat"
  },
  {
    "word": "жъотын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be happy",
        "examples": []
      },
      {
        "meaning": "to make a noise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʐʷatən"
  },
  {
    "word": "жъуагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "star",
        "examples": [
          {
            "sentence": "уашъом '''жъуагъохэр''' тэтых",
            "translation": "'''The stars''' are on the sky."
          },
          {
            "sentence": "еплъ еплъ '''жъуагъор''' ет1эрэхы",
            "translation": "Look Look, '''the star''' is falling."
          }
        ]
      }
    ],
    "synonyms": [
      "зуагъуэ"
    ],
    "cognate": "вагъуэ",
    "type": "noun",
    "ipa": "ʒʷaːʁʷa"
  },
  {
    "word": "жъуаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Residue (of grain),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaːf"
  },
  {
    "word": "жъугъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crowd; mass",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷəʁ"
  },
  {
    "word": "жъугъэ къэрэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "republic",
        "examples": []
      }
    ],
    "synonyms": [
      "жъугъэхэку"
    ],
    "type": "noun",
    "ipa": "ʐʷəʁa qaral"
  },
  {
    "word": "жъугъэ хат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "park",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷəʁa xaːt"
  },
  {
    "word": "жъугъэпащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "president",
        "examples": []
      },
      {
        "meaning": "President of republic,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷəʁapaːɕ"
  },
  {
    "word": "к1эщак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leader",
        "examples": []
      },
      {
        "meaning": "president",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕaːkʷʼa"
  },
  {
    "word": "пашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chief",
        "examples": []
      },
      {
        "meaning": "leader",
        "examples": []
      },
      {
        "meaning": "in front of",
        "examples": [
          {
            "sentence": "К1алэр 1анэм и '''пашъхьэ''' ит",
            "translation": "The boy is '''in front of''' the table."
          },
          {
            "sentence": "Унэ '''пашъхьэм''' хэтэц1ык1у 1ут",
            "translation": "There is a little garden '''in front of''' the house."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "paːʂħa"
  },
  {
    "word": "жъугъэхэку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "republic",
        "examples": []
      }
    ],
    "synonyms": [
      "жъугъэ къэрэл"
    ],
    "type": "noun",
    "ipa": "ʐʷəʁaxakʷ"
  },
  {
    "word": "жъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to melt",
        "examples": [
          {
            "sentence": "Мыжъо '''жъун'''",
            "translation": "The snow '''melts'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тк1ун"
    ],
    "type": "verb",
    "ipa": "ʐʷən"
  },
  {
    "word": "гъэтк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to melt",
        "examples": [
          {
            "sentence": "Гъуч1ыр '''гъэтк1у'''",
            "translation": "'''Melt''' the iron."
          },
          {
            "sentence": "Удэхагъэм сыгу '''егъэтк1у'''",
            "translation": "Your beauty '''is melting''' my heart."
          },
          {
            "sentence": "Лавэм ухахьэмэ '''угъэтк1ущт'''",
            "translation": "If you enter lava, '''it will melt you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁatkʷʼən"
  },
  {
    "word": "жъы1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Relatively old",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐəʔʷa"
  },
  {
    "word": "сурэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "picture",
        "examples": []
      },
      {
        "meaning": "photo",
        "examples": []
      },
      {
        "meaning": "drawing",
        "examples": []
      },
      {
        "meaning": "painting",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "səwrat"
  },
  {
    "word": "чэтыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cat",
        "examples": []
      }
    ],
    "synonyms": [
      "кьэтыу"
    ],
    "cognate": "джэду",
    "type": "noun",
    "ipa": "t͡ʃatəw"
  },
  {
    "word": "чэты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Literary) chicken",
        "examples": [],
        "tags": [
          "Literary"
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃatə"
  },
  {
    "word": "апч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glass",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːpt͡ʃ"
  },
  {
    "word": "апкь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"апч\"",
        "examples": []
      }
    ],

    "cognate": "абдж",
    "type": "noun",
    "ipa": "aːpkʲ"
  },
  {
    "word": "уцышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "green",
        "examples": [
          {
            "sentence": "Уцыр '''уцышъо'''",
            "translation": "The grass is '''green'''."
          },
          {
            "sentence": "Уцы '''уцышъор''' дахэ",
            "translation": "The '''green''' grass is pretty."
          }
        ]
      }
    ],
    "cognate": "удзыфэ",
    "type": "adj",
    "ipa": "wət͡səʂʷa"
  },
  {
    "word": "ш1омык1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coal",
        "examples": []
      }
    ],

    "cognate": "ф1амыщ1",
    "type": "noun",
    "ipa": "ʃʷʼamət͡ʃʼ"
  },
  {
    "word": "макъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "voice",
        "examples": [
          {
            "sentence": "Пшъашъэм '''имакъэ''' дахэ",
            "translation": "The girl has a beautiful '''voice'''."
          },
          {
            "sentence": "'''Уимакъэ''' сэ1урэп",
            "translation": "I don't hear your '''voice'''."
          }
        ]
      },
      {
        "meaning": "sound (Can also be noise)",
        "examples": [
          {
            "sentence": "'''Мэкъэ''' башэ сэ1у",
            "translation": "To hear too much '''noise'''."
          },
          {
            "sentence": "Пшынэм '''имакъэ''' дахэ",
            "translation": "The accordion '''sound''' is beautiful."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "maːqa"
  },
  {
    "word": "къуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheese",
        "examples": []
      }
    ],
    "synonyms": [
      "кхъуайэ",
      "хъуае",
      "кхъуае"
    ],
    "cognate": "кхъуей",
    "type": "noun",
    "ipa": "qʷaːja"
  },
  {
    "word": "хъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къуае\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къуае",
      "кхъуайэ"
    ],
    "cognate": "кхъуей",
    "type": "noun",
    "ipa": "χʷaːja"
  },
  {
    "word": "пытэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tough",
        "examples": [
          {
            "sentence": "Мыжъор '''пытэ'''",
            "translation": "The rock is '''tough'''."
          },
          {
            "sentence": "Уи1эхэр '''пытэх'''",
            "translation": "You arms are '''tough'''."
          }
        ]
      }
    ],

    "cognate": "быдэ",
    "type": "adj",
    "ipa": "pəta"
  },
  {
    "word": "панэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thorn",
        "examples": []
      }
    ],
    "synonyms": [
      "къэцы"
    ],
    "cognate": "банэ",
    "type": "noun",
    "ipa": "paːna"
  },
  {
    "word": "гъунджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mirror",
        "examples": []
      }
    ],

    "cognate": "гъуджэ",
    "type": "noun",
    "ipa": "ʁʷərd͡ʒa"
  },
  {
    "word": "тыгъуасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yesterday",
        "examples": []
      }
    ],

    "cognate": "дыгъуасэ",
    "type": "adv",
    "ipa": "təʁʷaːsa"
  },
  {
    "word": "псыкьэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псычэт\"",
        "examples": []
      }
    ],
    "synonyms": [
      "псычэт"
    ],
    "cognate": "псыджэд",
    "type": "noun",
    "ipa": "psəkʲat"
  },
  {
    "word": "тхьаматэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foreman (of a village)",
        "examples": []
      },
      {
        "meaning": "boss",
        "examples": []
      },
      {
        "meaning": "master (superior person in status or rank)",
        "examples": []
      },
      {
        "meaning": "chairman",
        "examples": []
      },
      {
        "meaning": "(dated possibly archaic) religious leader",
        "examples": [],
        "tags": [
          "archaic",
          "possibly archaic"
        ]
      },
      {
        "meaning": "(possibly dated) husband",
        "examples": [],
        "tags": [
          "possibly dated"
        ]
      }
    ],
    "type": "noun",
    "ipa": "tħaːmaːta"
  },
  {
    "word": "1ук1ьыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1унк1ыбзэ\"",
        "examples": []
      }
    ],

    "cognate": "1унк1ыбзэ",
    "type": "noun",
    "ipa": "ʔʷəkʲʼəbza"
  },
  {
    "word": "хъанзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ant",
        "examples": []
      }
    ],
    "synonyms": [
      "къамзэгу"
    ],
    "cognate": "хъумп1эц1эдж",
    "type": "noun",
    "ipa": "χaːnzaɡʷ"
  },
  {
    "word": "къамзэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ant",
        "examples": []
      },
      {
        "meaning": "anthill",
        "examples": []
      }
    ],
    "synonyms": [
      "хъанзэгу"
    ],
    "cognate": "хъумп1эц1эдж",
    "type": "noun",
    "ipa": "qaːmzaɡʷ"
  },
  {
    "word": "джанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Literary) chemise, shirt",
        "examples": [],
        "tags": [
          "Literary"
        ]
      },
      {
        "meaning": "(Literary) dress, robe",
        "examples": [],
        "tags": [
          "Literary"
        ]
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːna"
  },
  {
    "word": "парчъыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пачъыхь\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пщыпащ"
    ],
    "cognate": "пащтыхь",
    "type": "noun",
    "ipa": "paːrtʂəħ"
  },
  {
    "word": "пщыпащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "king, emperor, caesar, sultan",
        "examples": []
      }
    ],
    "synonyms": [
      "пачъыхь"
    ],
    "type": "noun",
    "ipa": "pɕəpaːɕ"
  },
  {
    "word": "чы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "button",
        "examples": []
      }
    ],

    "cognate": "щ1ы1у",
    "type": "noun",
    "ipa": "t͡ʃʼəʔʷ"
  },
  {
    "word": "хъырбыдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "watermelon",
        "examples": []
      }
    ],
    "synonyms": [
      "нашэ"
    ],
    "cognate": "хъарбыз",
    "type": "noun",
    "ipa": "χərbəd͡ʒ"
  },
  {
    "word": "хьаджыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flour",
        "examples": []
      }
    ],

    "cognate": "хьэжыгъэ",
    "type": "noun",
    "ipa": "ħaːd͡ʒəʁa"
  },
  {
    "word": "хьаджыгъэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hajgeps (A Circassian food made from flour)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːd͡ʒəʁaps"
  },
  {
    "word": "лъэгуаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэгуанджэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэгуанджэ"
    ],
    "cognate": "лъэгуажьэ",
    "type": "noun",
    "ipa": "ɬaɡʷaːd͡ʒa"
  },
  {
    "word": "хьацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shovel (Shapsug)",
        "examples": []
      },
      {
        "meaning": "dog hair",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːt͡sa"
  },
  {
    "word": "шъухъуат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъуант1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэушхъонтын"
    ],
    "type": "noun",
    "ipa": "ʂʷəχʷaːtˤʼa"
  },
  {
    "word": "натыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"натрыф\"",
        "examples": []
      }
    ],
    "synonyms": [
      "натрыф"
    ],
    "cognate": "нартыху",
    "type": "noun",
    "ipa": "naːtəf"
  },
  {
    "word": "псык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псынк1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "псынк1э"
    ],
    "cognate": "псынщ1э",
    "type": "adj",
    "ipa": "psət͡ʃʼa"
  },
  {
    "word": "псынк1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quickly",
        "examples": [
          {
            "sentence": "К1алэр '''псынк1эу''' к1уагъэ",
            "translation": "The boy went '''quickly'''."
          },
          {
            "sentence": "Фылымыр '''псынк1эу''' къиухыгъ",
            "translation": "The film ended '''quickly'''."
          },
          {
            "sentence": "'''Псынк1эу''' 1офыр зэш1ок1ыгъэ",
            "translation": "The work was done '''quickly'''."
          }
        ]
      },
      {
        "meaning": "lightly",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1эхэу",
      "псык1эу"
    ],
    "cognate": "псынщ1эу",
    "type": "adv",
    "ipa": "psənt͡ʃʼaw"
  },
  {
    "word": "онтэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heavy",
        "examples": []
      }
    ],
    "synonyms": [
      "отэгъу"
    ],
    "cognate": "уэндэгъу",
    "type": "adj",
    "ipa": "wantaʁʷ"
  },
  {
    "word": "отэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"онтэгъу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "онтэгъу"
    ],
    "type": "adj",
    "ipa": "wataʁʷ"
  },
  {
    "word": "уэнтэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heavy",
        "examples": []
      }
    ],
    "synonyms": [
      "отэгъу"
    ],
    "type": "adj",
    "ipa": "wantaʁʷ"
  },
  {
    "word": "пк1ык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пчык1э\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃət͡ʃa"
  },
  {
    "word": "хьантыкъуакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "frog",
        "examples": []
      }
    ],
    "synonyms": [
      "хьант1аркъу"
    ],
    "cognate": "хьэндыркъуакъуэ",
    "type": "noun",
    "ipa": "ħantˈəqʷaːqʷ"
  },
  {
    "word": "хьэт1рэп1ый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "butterfly",
        "examples": []
      }
    ],
    "synonyms": [
      "хьамп1ырашъу",
      "хьэнт1эрэп1ый"
    ],
    "cognate": "хьэндырабгъуэ",
    "type": "noun",
    "ipa": "ħatˈrapˈəj"
  },
  {
    "word": "пс1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пц1анэ\"",
        "examples": []
      }
    ],

    "cognate": "пц1анэ",
    "type": "adj",
    "ipa": "psʼaːna"
  },
  {
    "word": "пц1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "naked",
        "examples": []
      }
    ],
    "synonyms": [
      "пс1анэ",
      "----"
    ],
    "type": "adj",
    "ipa": "pt͡sʼaːna"
  },
  {
    "word": "жъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grill",
        "examples": []
      },
      {
        "meaning": "to fry",
        "examples": []
      }
    ],

    "cognate": "жьэн",
    "type": "verb",
    "ipa": "ʐan"
  },
  {
    "word": "жьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ежьэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʑan"
  },
  {
    "word": "шынахьык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "younger brother",
        "examples": []
      }
    ],

    "cognate": "шынэхъыщ1",
    "type": "noun",
    "ipa": "ʃənaːħət͡ʃʼ"
  },
  {
    "word": "шынахьыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "older brother",
        "examples": []
      }
    ],

    "cognate": "шынэхъыжь",
    "type": "noun",
    "ipa": "ʃənaːħəʐ"
  },
  {
    "word": "жак1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug) alternative form of \"жак1э\"",
        "examples": [],
        "tags": [
          "Shapsug",
          "alternative_spelling"
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʒaːkʲa"
  },
  {
    "word": "гъаш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "life",
        "examples": []
      }
    ],

    "cognate": "гъащ1э",
    "type": "noun",
    "ipa": "ʁaːʃʼa"
  },
  {
    "word": "шэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat or oil from the body of an animal",
        "examples": []
      }
    ],
    "synonyms": [
      "дагъэ",
      "------ щэ ще"
    ],
    "type": "noun",
    "ipa": "ʃa"
  },
  {
    "word": "ахъщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "money",
        "examples": []
      },
      {
        "meaning": "currency",
        "examples": []
      },
      {
        "meaning": "fund",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эны",
      "нэгъэц1ыу",
      "парэ"
    ],
    "cognate": "ахъшэ",
    "type": "noun",
    "ipa": "aːχʃa"
  },
  {
    "word": "щынагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dangerous",
        "examples": [
          {
            "sentence": "Iофыр щынагъо хъугъэ",
            "translation": "The work became dangerous"
          }
        ]
      },
      {
        "meaning": "fearsome, menacing",
        "examples": [
          {
            "sentence": "Ащ щынэгъошхо иI, цIыфхэр щэщынэх",
            "translation": "He is fearsome, people are afraid of him."
          }
        ]
      }
    ],
    "synonyms": [
      "гуих"
    ],
    "cognate": "шынагъуэ",
    "type": "adj",
    "ipa": "ɕənaʁʷa"
  },
  {
    "word": "шъынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lamp",
        "examples": []
      }
    ],

    "cognate": "щынэ",
    "type": "noun",
    "ipa": "ʂəna"
  },
  {
    "word": "фо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug) big",
        "examples": [],
        "tags": [
          "Shapsug"
        ]
      }
    ],
    "synonyms": [
      "джадэ",
      "шъэджашъ",
      "----"
    ],
    "type": "adj",
    "ipa": "fo"
  },
  {
    "word": "тхъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхъурыжъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tχʷəʐ"
  },
  {
    "word": "Амыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Amesh: Initially god of fauna, then god of sheep.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːməɕ"
  },
  {
    "word": "Аушыджэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Awishijer, Awischjerjiy, Dawischjerjiy: God of courage and bravery.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːwʃəd͡ʒar"
  },
  {
    "word": "Хьэдрыхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "afterworld, hereafter",
        "examples": []
      },
      {
        "meaning": "Hedrixe, Hedirix: Protector of the dead.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħadrəxa"
  },
  {
    "word": "Хьэдрыхэтхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hedrixethe: God of the hereafter (Abzakh)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħadrəxatħa"
  },
  {
    "word": "Хьэк1ущтащхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hek'wschtaschhe, Hekwschtix': Protector of oxen (Shapsugh).",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħakʷʼɕtaːɕħa"
  },
  {
    "word": "Хьэкусташ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Patron of horsemanship.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħakʷəstaːʃ"
  },
  {
    "word": "Хьэнцийгуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hentsiygwasche, Hentsegwasche: Goddess of rain.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħant͡sijɡʷaːɕa"
  },
  {
    "word": "Хьэуцхьэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hewits-Hesh: God of seas and demi-gods.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħawt͡sħaʃ"
  },
  {
    "word": "Джэдыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Jedipe: God of rivers and seas (literally: ‘hen's beak').",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "d͡ʒadəpa"
  },
  {
    "word": "Лъэпщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Lhepsch, Tlepsh: Patron of smiths, iron, weapons and fire. Corresponds to the Roman god Vulcan and to Hephæstus in the Greek Pantheon.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ɬapɕ"
  },
  {
    "word": "Мамыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mamisch, Mamish: Patron of fortunetellers, specifically of scapula readers.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "aːfə"
  },
  {
    "word": "Мэзгуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mezgwasche: Goddess of forests and trees.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "mazɡʷaːɕa"
  },
  {
    "word": "Мэзытхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mezithe: God of forests, trees, the hunt and beasts. He disposed of the fate of beasts, and brought good luck to the hunt. He is depicted as riding a golden-bristled boar. He took his deer to a meadow wherein a group of virgins milked them.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "mazətħa"
  },
  {
    "word": "Пэкъуэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peqwe: 'False' demi-god - creator of the fields - debunked by Wezirmes.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "paqʷa"
  },
  {
    "word": "Псатхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Psathe: God of the soul or life. Also denotes icon of Christian Circassians (in Mozdok).",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "psaːtħa"
  },
  {
    "word": "Псытхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Psithe: God of water.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "psətħa"
  },
  {
    "word": "Псытхьэгуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Psithegwasche, Psigwasche: Goddess of water. Popular tradition had her portrayed as a beautiful maid.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "psətħaɡʷaːɕa"
  },
  {
    "word": "Псыхъуэгуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Psix'wegwasche: Goddess of rivers (river valleys).",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "psəχʷaɡʷaːɕa"
  },
  {
    "word": "Къуэдэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Qwedes: God of sea, in form of fish (literally: ‘living in a depression').",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "qʷadas"
  },
  {
    "word": "Щыблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Shyble: Circassian God of thunder (storms) and lightning; also of war and justice. Circassians Both loved and feared Shyble. Death by lightning considered to be a good and clean death and those who died by lightning were revered as saints blessed by Shyble.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ɕəbla"
  },
  {
    "word": "Сотрэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Sotresh: God of gaiety and holidays.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "sotraʃ"
  },
  {
    "word": "Созэрэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Sozerash: God of fertility, family hearth, well-being and illness. He was a great voyager and controlled the winds and waters. He was also the protector of herds (Shawzerish Шаузэрыш in Shapsugh).",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "sozaraʃ"
  },
  {
    "word": "Тэтэртуп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Tetertup: God of war and bloodshed. Equivalent to Grecian Ares and to Roman Mars.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "tatartup"
  },
  {
    "word": "Тхьэгъэлэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Theghelej: God of fertility and plants.",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "tħaʁalad͡ʒ"
  },
  {
    "word": "Тхьэгъуичу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Theghwiychu: Protector of people. Intermediary between gods and people.",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "Тхьэгуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Thegwasche: Protectress of women (literally: ‘Matron of the gods').",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "tħaɡʷaːɕa"
  },
  {
    "word": "Тхьэшырыпхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Theshiripx'w: Patron of beautiful and intelligent women.",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "Тхьэшу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Theshu: Protector of horsemen.",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "Уащхъуэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Washwa: One of the supreme cosmic deities; god of the skies (literally: ‘blue sky').",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "waːɕχʷa"
  },
  {
    "word": "Жьытхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Zhitha: God of wind.",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "1асэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meekly",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʔaːsaw"
  },
  {
    "word": "чъуакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цуакъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цуакъэ"
    ],
    "cognate": "вакъэ",
    "type": "noun",
    "ipa": "t͡ʃʷaːqʷa"
  },
  {
    "word": "жъышъхьэмаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "happy old age",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəʂħamaːf"
  },
  {
    "word": "жъышъхьэмыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sad old age",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəʂħamaəʁʷ"
  },
  {
    "word": "жъэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kidney",
        "examples": []
      }
    ],

    "cognate": "жьэжьэй",
    "type": "noun",
    "ipa": "ʐaʐəj"
  },
  {
    "word": "жъэжъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to act slowly",
        "examples": []
      },
      {
        "meaning": "to get weaker",
        "examples": []
      },
      {
        "meaning": "to slow down",
        "examples": []
      },
      {
        "meaning": "to take one's time,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʐaʐam"
  },
  {
    "word": "жъэрым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Smell of frying, Smell of grilling,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐarəm"
  },
  {
    "word": "жъэгъуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Hard to fry, Hard to grill",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐaʁʷaj"
  },
  {
    "word": "жъэгъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Fried easily, Grilled easily",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "къэбэртайбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the Kabardian language",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "qabartaːjbza"
  },
  {
    "word": "1асэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "well-behaved",
        "examples": []
      },
      {
        "meaning": "tamed",
        "examples": [
          {
            "sentence": "Хьэр '''1асэ'''",
            "translation": "The dog '''is tamed'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔaːsa"
  },
  {
    "word": "жъыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      },
      {
        "meaning": "seniority",
        "examples": []
      }
    ],
    "synonyms": [
      "жъышъхьэ",
      "л1ыжъыгъу",
      "л1ыжъып1",
      "л1ыжъышъ"
    ],
    "type": "noun",
    "ipa": "ʐəʁʷa"
  },
  {
    "word": "жъыгъуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly old age",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəʁʷaːj"
  },
  {
    "word": "жъыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      }
    ],
    "synonyms": [
      "жъышъхьэ",
      "л1ыжъыгъу",
      "л1ыжъып1",
      "л1ыжъышъ"
    ],
    "type": "noun",
    "ipa": "ʐəʁa"
  },
  {
    "word": "жъыгъэ к1агъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Age classification",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəʁa t͡ʃaːʁ"
  },
  {
    "word": "жъыкъат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very old",
        "examples": []
      }
    ],
    "synonyms": [
      "жъышъыпкъ"
    ],
    "type": "adj",
    "ipa": "ʐəqaːt"
  },
  {
    "word": "жъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become old, to become obsolete",
        "examples": [
          {
            "sentence": "анэр '''мэжъы'''",
            "translation": "The table is '''getting old'''."
          },
          {
            "sentence": "мые '''мэжъыгъэ''' уымышх",
            "translation": "Don't eat the apple '''that got old'''."
          },
          {
            "sentence": "псык1эу шхы бэнанэр '''мэмжъыгъуу'''",
            "translation": "Eat the banana fast '''before it becomes obsolete'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʐən"
  },
  {
    "word": "жъып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "infirmary (for old people)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəpʼa"
  },
  {
    "word": "жъырку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dumpy",
        "examples": []
      },
      {
        "meaning": "long-lived",
        "examples": []
      },
      {
        "meaning": "squat",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐərkʷ"
  },
  {
    "word": "жъыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Refrain (chorus)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəw"
  },
  {
    "word": "жъыуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chorus",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəwaːkʷʼa"
  },
  {
    "word": "жъыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shine, to glitter, to sparkle, to flash",
        "examples": [
          {
            "sentence": "Дышъэр '''мэжъыу'''",
            "translation": "The gold '''shines'''."
          },
          {
            "sentence": "Жъуагъохэр '''мэжъыух'''",
            "translation": "The stars '''are shining'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ц1ыун"
    ],
    "type": "verb",
    "ipa": "ʐəwən"
  },
  {
    "word": "жъышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Old age look",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐəʂʷa"
  },
  {
    "word": "жъышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      }
    ],
    "synonyms": [
      "жъыгъэ",
      "жъыгъо",
      "л1ыжъыгъу",
      "л1ыжъып1",
      "л1ыжъышъ"
    ],
    "type": "noun",
    "ipa": "ʐəʂħa"
  },
  {
    "word": "л1ыжъышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      }
    ],
    "synonyms": [
      "жъыгъэ",
      "жъыгъо",
      "л1ыжъыгъу",
      "л1ыжъып1",
      "жъышъхьэ"
    ],
    "type": "noun",
    "ipa": "ɬʼəʐəʂ"
  },
  {
    "word": "л1ыжъып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      }
    ],
    "synonyms": [
      "жъыгъэ",
      "жъыгъо",
      "л1ыжъыгъу",
      "л1ыжъышъ",
      "жъышъхьэ"
    ],
    "type": "noun",
    "ipa": "ɬʼəʐəpʼ"
  },
  {
    "word": "л1ыжъыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old age",
        "examples": []
      }
    ],
    "synonyms": [
      "жъыгъэ",
      "жъыгъо",
      "л1ыжъышъ",
      "л1ыжъып1",
      "жъышъхьэ"
    ],
    "type": "noun",
    "ipa": "ɬʼəʐəʁʷ"
  },
  {
    "word": "лъагэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "high",
        "examples": [
          {
            "sentence": "Къушъхьэ анахь '''лъагэм''' сэк1о",
            "translation": "I am going to the '''high'''est mountain."
          },
          {
            "sentence": "1уашъхьэмафэр '''лъагэ'''",
            "translation": "The Mount Elbrus is '''high'''"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬaːɣa"
  },
  {
    "word": "осыпсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snowflake",
        "examples": []
      },
      {
        "meaning": "melt water",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wasəpsə"
  },
  {
    "word": "къарэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dark color (like black and brown) (Usually said to animals)",
        "examples": [
          {
            "sentence": "Си пщынэ '''къарэ''' къызэкlэсэщ,",
            "translation": "t=When I hold my dark accordion."
          },
          {
            "sentence": "шы '''къарэ'''",
            "translation": "t=Black horse."
          },
          {
            "sentence": "чэщы '''къарэ'''",
            "translation": "t=Dark night."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "qaːra"
  },
  {
    "word": "урысыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the Russian language",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "инджылыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the English language",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "1эшъхьэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bracelet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaʂħaχʷ"
  },
  {
    "word": "шэкэрыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sugar water",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃakʲarəps"
  },
  {
    "word": "енэс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"енэц1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "janasʼən"
  },
  {
    "word": "шхъуант1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blue",
        "examples": [
          {
            "sentence": "Уашъор '''шхъуантIэ'''",
            "translation": "The sky is '''blue'''."
          },
          {
            "sentence": "Уашъо '''шхъуантIэр''' дахэ",
            "translation": "The '''blue''' sky is pretty."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʃχʷaːntʼa"
  },
  {
    "word": "гъоплъашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brass color",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷapɬaːʂʷ"
  },
  {
    "word": "гъожьышэплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "orange",
        "examples": []
      }
    ],
    "synonyms": [
      "гъоплъышъо",
      "гъожьыплъыжь"
    ],
    "type": "adj",
    "ipa": "ʁʷaʑəʃapɬ"
  },
  {
    "word": "шэплъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pink",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьэмбылышъу",
      "плъыжьгьэф"
    ],
    "type": "adj",
    "ipa": "ʃapɬə"
  },
  {
    "word": "ек1ол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to approach",
        "examples": [
          {
            "sentence": "К1алэр псым '''ек1уал1э'''",
            "translation": "The boy '''is approaching''' the water."
          },
          {
            "sentence": "Маш1ом '''уемык1уал1'''",
            "translation": "'''Don't approach''' the fire."
          },
          {
            "sentence": "Нэфнэм бадзэхэ '''къек1уал1эх'''",
            "translation": "The flies '''are approaching''' the light."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jakʷʼaɬʼan"
  },
  {
    "word": "пхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "daughter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχʷə"
  },
  {
    "word": "гоон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bear a grudge against, to spite",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷawan"
  },
  {
    "word": "мэгънэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъынэнэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁnanan"
  },
  {
    "word": "хымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stranger",
        "examples": []
      },
      {
        "meaning": "foreign",
        "examples": [
          {
            "sentence": "Ч1ыгу '''хымэ'''",
            "translation": "'''Foreign''' land."
          }
        ]
      }
    ],

    "cognate": "хамэ",
    "type": "noun",
    "ipa": "xəma"
  },
  {
    "word": "жьыуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "storm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəwaːj"
  },
  {
    "word": "щамсый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "umbrella",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaːmsəj"
  },
  {
    "word": "щтыргъук1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "frost; freezing weather",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕtərʁʷət͡ʃ"
  },
  {
    "word": "гъушъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dry",
        "examples": []
      }
    ],

    "cognate": "гъущы",
    "type": "adj",
    "ipa": "ʁʷəʂə"
  },
  {
    "word": "ш1ыхьагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fence",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʼəħaːʁa"
  },
  {
    "word": "джэныкъохьаку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chimney",
        "examples": []
      },
      {
        "meaning": "fireplace",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒanəqʷaħaːkʷ"
  },
  {
    "word": "дэк1ояп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ladder",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэугъуае",
      "лъэой"
    ],
    "type": "noun",
    "ipa": "dakʷʼajaːpʼa"
  },
  {
    "word": "п1эк1ор",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed",
        "examples": []
      }
    ],
    "synonyms": [
      "гъолъып1э",
      "ошэкур"
    ],
    "type": "noun",
    "ipa": "pʼakʷʼar"
  },
  {
    "word": "остыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lamp",
        "examples": []
      }
    ],
    "synonyms": [
      "апк1шъхьэтэт"
    ],
    "type": "noun",
    "ipa": "wastəʁ"
  },
  {
    "word": "пхъэнт1эк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chair",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэт1эк1у"
    ],
    "type": "noun",
    "ipa": "pχantʼakʷʼ"
  },
  {
    "word": "щалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bucket",
        "examples": []
      },
      {
        "meaning": "pail",
        "examples": []
      }
    ],
    "synonyms": [
      "къуао"
    ],
    "type": "noun",
    "ipa": "ɕaːɬ"
  },
  {
    "word": "къухьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ship",
        "examples": []
      }
    ],
    "synonyms": [
      "къохь",
      "жьыкъухь"
    ],
    "cognate": "кхъухь",
    "type": "noun",
    "ipa": "qʷəħa"
  },
  {
    "word": "къуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːʂʷa"
  },
  {
    "word": "кушъхьарыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "van",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "щэрыхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэрэхъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "кущэрэхъ"
    ],
    "type": "noun",
    "ipa": "ɕarəχ"
  },
  {
    "word": "кушъхьэфачъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"кушъхьэфачъэ\"",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "темыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "north",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tajmər"
  },
  {
    "word": "къыблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "south",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qəbla"
  },
  {
    "word": "тыгъэкъок1ып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "east",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "təʁaqʷat͡ʃəpʼ"
  },
  {
    "word": "тыгъэкъохьап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "west",
        "examples": []
      }
    ],
    "synonyms": [
      "къохьап1э"
    ],
    "type": "noun",
    "ipa": "təʁaqʷaħaːpʼ"
  },
  {
    "word": "ет1агъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clay",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jatʼaːʁʷ"
  },
  {
    "word": "уцы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grass",
        "examples": []
      },
      {
        "meaning": "medicine",
        "examples": []
      }
    ],

    "cognate": "удз",
    "type": "noun",
    "ipa": "wət͡sə"
  },
  {
    "word": "хэлъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to plant",
        "examples": []
      },
      {
        "meaning": "to lie/put somewhere within a mass (gas, solid or liquid)",
        "examples": []
      },
      {
        "meaning": "to lie/put somewhere among other objects or people",
        "examples": [
          {
            "sentence": "пIэм '''хэлъын'''",
            "translation": "'''to lie''' in bed."
          }
        ]
      },
      {
        "meaning": "to be situated",
        "examples": []
      },
      {
        "meaning": "to be screwed; to beset with unfortunate circumstances that seem difficult or impossible to overcome",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaɬħaːn"
  },
  {
    "word": "хьак1экъуак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "predatory",
        "examples": []
      }
    ],

    "cognate": "хьак1экхъуак1э",
    "type": "noun",
    "ipa": "ħaːt͡ʃʼaqʷaːt͡ʃʼa"
  },
  {
    "word": "1угъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smoke",
        "examples": []
      }
    ],
    "synonyms": [
      "къугъо",
      "----"
    ],
    "type": "noun",
    "ipa": "ʔʷəʁʷa"
  },
  {
    "word": "хыкум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lake",
        "examples": []
      }
    ],
    "synonyms": [
      "псыхъурай",
      "хыкъумэ"
    ],
    "cognate": "гуэл",
    "type": "noun",
    "ipa": "xəkʷəm"
  },
  {
    "word": "псыхъурай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lake",
        "examples": []
      }
    ],
    "synonyms": [
      "хыкум"
    ],
    "type": "noun",
    "ipa": "psəχʷəraːj"
  },
  {
    "word": "орыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swamp",
        "examples": []
      },
      {
        "meaning": "marsh",
        "examples": []
      }
    ],
    "synonyms": [
      "темэн"
    ],
    "type": "noun",
    "ipa": "warəʐ"
  },
  {
    "word": "темэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swamp",
        "examples": []
      },
      {
        "meaning": "marsh, meadowland, flooded areas",
        "examples": []
      }
    ],
    "synonyms": [
      "орыжъ"
    ],
    "type": "noun",
    "ipa": "tajman"
  },
  {
    "word": "хъуп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meadow",
        "examples": []
      },
      {
        "meaning": "grassland",
        "examples": []
      }
    ],
    "synonyms": [
      "мэкъуп1"
    ],
    "type": "noun",
    "ipa": "χʷəpʼa"
  },
  {
    "word": "мэкъуп1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meadow",
        "examples": []
      },
      {
        "meaning": "grassland",
        "examples": []
      }
    ],
    "synonyms": [
      "хъуп1э"
    ],
    "type": "noun",
    "ipa": "maqʷpʼ"
  },
  {
    "word": "губгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild",
        "examples": []
      }
    ],
    "synonyms": [
      "шъофы"
    ],
    "type": "adj",
    "ipa": "ɡʷəbʁʷa"
  },
  {
    "word": "шъхьалы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mill",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːlə"
  },
  {
    "word": "оры",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ор\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "warə"
  },
  {
    "word": "хы 1ушъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хы1ушъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хыгъунэ"
    ],
    "type": "noun",
    "ipa": "xə ʔʷəʂʷ"
  },
  {
    "word": "мыжъуак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pebble",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məʐʷaːt͡ʃa"
  },
  {
    "word": "тхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oil",
        "examples": []
      },
      {
        "meaning": "petroleum",
        "examples": []
      },
      {
        "meaning": "butter (melted)",
        "examples": [
          {
            "sentence": "Сэ тостым '''тхъу''' дэсэшхы",
            "translation": "I am eating toast with '''butter'''."
          }
        ]
      }
    ],
    "synonyms": [
      "дагъэ"
    ],
    "type": "noun",
    "ipa": "tχʷə"
  },
  {
    "word": "дагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vegetable oil",
        "examples": []
      },
      {
        "meaning": "petroleum",
        "examples": []
      },
      {
        "meaning": "fat or oil from the body of an animal",
        "examples": []
      }
    ],
    "synonyms": [
      "тхъу"
    ],
    "cognate": "шэ",
    "type": "noun",
    "ipa": "daːʁa"
  },
  {
    "word": "хак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stallion",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xaːkʷʼa"
  },
  {
    "word": "шыбзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mare",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəbzə"
  },
  {
    "word": "шык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foal",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃət͡ʃa"
  },
  {
    "word": "т1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ram",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʼə"
  },
  {
    "word": "мэлыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lamb",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maləl"
  },
  {
    "word": "мэзыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild boar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazəqʷ"
  },
  {
    "word": "къощыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "piglet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaɕər"
  },
  {
    "word": "лыпц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(anatomy) birthmark, mole, melanism, nevus (naevus) (dark spot on the skin)",
        "examples": [],
        "tags": [
          "anatomy"
        ]
      }
    ],
    "synonyms": [
      "анэл"
    ],
    "type": "noun",
    "ipa": "ləpt͡sʼa"
  },
  {
    "word": "шъыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "doe",
        "examples": []
      },
      {
        "meaning": "hind",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂəħ"
  },
  {
    "word": "набгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nest",
        "examples": []
      },
      {
        "meaning": "quail",
        "examples": []
      }
    ],
    "synonyms": [
      "ныбгъэ"
    ],
    "type": "noun",
    "ipa": "naːbʁʷə"
  },
  {
    "word": "цунды",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "raven",
        "examples": []
      },
      {
        "meaning": "crow",
        "examples": []
      }
    ],
    "synonyms": [
      "къолэжъ"
    ],
    "cognate": "къуагъыщхъуэ",
    "type": "noun",
    "ipa": "t͡sʷəndə"
  },
  {
    "word": "тхьаркъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pigeon",
        "examples": []
      },
      {
        "meaning": "dove",
        "examples": []
      }
    ],

    "cognate": "тхьэрыкъу",
    "type": "noun",
    "ipa": "tħaːrqʷa"
  },
  {
    "word": "лъэхъомбэшх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "centipede",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1эшъэхъу"
    ],
    "type": "noun",
    "ipa": "ɬaχʷambaʃx"
  },
  {
    "word": "пк1эшъэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "centipede",
        "examples": []
      },
      {
        "meaning": "caterpillar",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэхъомбэшх"
    ],
    "type": "noun",
    "ipa": "pt͡ʃaʂaχʷ"
  },
  {
    "word": "ожъубанэхьэмлыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "earthworm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "waʐʷbaːnaħamləw"
  },
  {
    "word": "мэш1уахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firefly",
        "examples": []
      }
    ],
    "synonyms": [
      "мэш1охь"
    ],
    "cognate": "хьэндырабгъуэ",
    "type": "noun"
  },
  {
    "word": "коны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beehive",
        "examples": []
      },
      {
        "meaning": "bin",
        "examples": []
      },
      {
        "meaning": "granary",
        "examples": []
      },
      {
        "meaning": "money box",
        "examples": []
      }
    ],
    "synonyms": [
      "конэгъу",
      "----"
    ],
    "type": "noun",
    "ipa": "kʷanə"
  },
  {
    "word": "къэцыгъуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hornet",
        "examples": []
      },
      {
        "meaning": "wasp",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qat͡səʁʷaːn"
  },
  {
    "word": "хыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shark",
        "examples": []
      }
    ],
    "synonyms": [
      "акул",
      "хытыгъужъ"
    ],
    "type": "noun",
    "ipa": "xəħa"
  },
  {
    "word": "акул",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shark",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːkʷəl"
  },
  {
    "word": "псыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dolphin",
        "examples": []
      }
    ],
    "synonyms": [
      "хыкъу"
    ],
    "type": "noun",
    "ipa": "psəqʷ"
  },
  {
    "word": "псылъэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "octopus",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəɬaχʷ"
  },
  {
    "word": "дыо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leech",
        "examples": []
      }
    ],

    "cognate": "псыдыуэ",
    "type": "noun",
    "ipa": "dəwa"
  },
  {
    "word": "шъотехьэтек1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chameleon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷatajħatajt͡ʃ"
  },
  {
    "word": "архъуажъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crocodile",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːrχʷaːʐ"
  },
  {
    "word": "джэмышх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spoon",
        "examples": [
          {
            "sentence": "Нэмк1ыр '''джэмышх''' къысэт",
            "translation": "Give me a '''different''' spoon."
          }
        ]
      }
    ],
    "synonyms": [
      "лошк1э"
    ],
    "cognate": "бжэмышх",
    "type": "noun",
    "ipa": "d͡ʒaməʃx"
  },
  {
    "word": "цацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fork",
        "examples": []
      }
    ],

    "cognate": "дзасэ",
    "type": "noun",
    "ipa": "t͡saːt͡sa"
  },
  {
    "word": "къамлыбжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bowl",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "пыут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыуты\"",
        "examples": []
      }
    ],

    "cognate": "пуд",
    "type": "adj",
    "ipa": "pəwət"
  },
  {
    "word": "к1ышъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skin",
        "examples": []
      },
      {
        "meaning": "crust (of Earth)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəʂʷ"
  },
  {
    "word": "шъхьацы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hair",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːt͡sə"
  },
  {
    "word": "куц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brain",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷət͡sʼə"
  },
  {
    "word": "нэк1ушъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1ушъхь"
    ],
    "type": "noun",
    "ipa": "nakʷʼəʂʷ"
  },
  {
    "word": "нэк1ушъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1ушъу"
    ],
    "type": "noun",
    "ipa": "nakʷʼəʂħ"
  },
  {
    "word": "1упш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jaw",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəpʃʼa"
  },
  {
    "word": "жэк1ьапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэк1апэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "жэк1апэ"
    ],
    "type": "noun",
    "ipa": "ʒakʲʼaːpa"
  },
  {
    "word": "тхьабыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lung",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħaːbəl"
  },
  {
    "word": "к1эт1эй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "intestine",
        "examples": []
      }
    ],
    "synonyms": [
      "ныпс1э"
    ],
    "type": "noun",
    "ipa": "t͡ʃatʼaj"
  },
  {
    "word": "лыпцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "muscle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ləpt͡sa"
  },
  {
    "word": "зыу1эшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zəwʔaʃən"
  },
  {
    "word": "1энтэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "elbow",
        "examples": []
      }
    ],
    "synonyms": [
      "1этэгъу"
    ],
    "type": "noun",
    "ipa": "ʔantaʁʷ"
  },
  {
    "word": "1апшъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrist",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːpʂ"
  },
  {
    "word": "лъэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ankle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬat͡ʃan"
  },
  {
    "word": "тхьамык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "poor (A person with little/no money or possessions)",
        "examples": [
          {
            "sentence": "Ахъщэ ч1этыфэу '''тхьамык1эу''' тыхъужьыщт",
            "translation": "While we are spending money, we will become '''poor'''."
          },
          {
            "sentence": "'''К1алэр''' тхьамык1",
            "translation": "'''The boy''' is poor."
          }
        ]
      },
      {
        "meaning": "poor (A person or animal to be pitied)",
        "examples": [
          {
            "sentence": "Л1ыжъы нэшъор '''зытхьамык1'''",
            "translation": "the blind old man is '''so poor'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тхьэмыщк1ь"
    ],
    "cognate": "тхьэмыщк1э",
    "type": "adj",
    "ipa": "tħaːmət͡ʃ"
  },
  {
    "word": "тхьэмыщк1ь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьамык1\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьамык1"
    ],
    "cognate": "тхьэмыщк1э",
    "type": "adj",
    "ipa": "tħaməɕkʲʼ"
  },
  {
    "word": "уды",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "witch",
        "examples": []
      },
      {
        "meaning": "sorcerer",
        "examples": []
      },
      {
        "meaning": "magician",
        "examples": []
      },
      {
        "meaning": "wizard",
        "examples": []
      }
    ],
    "synonyms": [
      "нэгъуч1ыцэ",
      "ушхъухьак1о",
      "----"
    ],
    "cognate": "уд",
    "type": "noun",
    "ipa": "wədə"
  },
  {
    "word": "набгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "myopic (having bad eye-sight)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naːbʁa"
  },
  {
    "word": "напсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "medlar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːpsa"
  },
  {
    "word": "ебгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be angry",
        "examples": []
      },
      {
        "meaning": "to take offense at",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jabʁan"
  },
  {
    "word": "дэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to permit, to accept, to consent, to assent",
        "examples": [
          {
            "sentence": "'''Ода''' 50$ къыостынэу?",
            "translation": "t='''Do you accept''' that I will give you $50?"
          },
          {
            "sentence": "Сыдэрэп мо к1алэм '''уригъусэу'''.",
            "translation": "t='''I forbid''' you to be with that boy."
          },
          {
            "sentence": "Уиянэ '''еда''' укъыдэк1ынэу?",
            "translation": "t=Does your mother '''allow you''' to go out?"
          },
          {
            "sentence": "Тихатэ шъушыджэгоу '''сыдэрэп'''.",
            "translation": "t='''I forbid''' you (plural) to play in our garden."
          },
          {
            "sentence": "Сидоктор '''ыдэрэп''' бэнанэ сышхэу.",
            "translation": "t=My doctor '''forbade''' me to eat a banana."
          },
          {
            "sentence": "Уиянэ '''ебгъадэшъущта''' уиунэ тыкъэк1онэу.",
            "translation": "t='''Can you get''' your mother '''to permit''' us to come to your house."
          }
        ]
      },
      {
        "meaning": "to sew",
        "examples": []
      }
    ],
    "synonyms": [
      "дын",
      "----"
    ],
    "type": "verb",
    "ipa": "dan"
  },
  {
    "word": "цэф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tin",
        "examples": []
      }
    ],
    "synonyms": [
      "тенэч"
    ],
    "cognate": "дзэху",
    "type": "noun",
    "ipa": "t͡saf"
  },
  {
    "word": "цуабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ploughshare",
        "examples": []
      },
      {
        "meaning": "share",
        "examples": []
      }
    ],

    "cognate": "вабдзэ",
    "type": "noun",
    "ipa": "t͡sʷaːbza"
  },
  {
    "word": "пщыл1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slave",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕəɬʼ"
  },
  {
    "word": "пщыл1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bow down to",
        "examples": [
          {
            "sentence": "к1алэр пщым '''фэпщыл1ы'''",
            "translation": "t=The boy '''is bowing down to''' the prince."
          },
          {
            "sentence": "муслимхэр тхьэм фэлъа1охэмэ '''фэпщыл1ых'''",
            "translation": "t=When the Muslims pray to god '''they bow down to him'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pɕəɬʼən"
  },
  {
    "word": "псыбыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bubble",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəbəb"
  },
  {
    "word": "цые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian male traditional clothes, Chokha",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡səja"
  },
  {
    "word": "пцае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian female traditional clothes",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡saːja"
  },
  {
    "word": "къэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dance",
        "examples": [
          {
            "sentence": "К1алэр дахэу '''къашъо'''",
            "translation": "The boy '''is dancing''' beautifully."
          },
          {
            "sentence": "'''Укъэшъонэу''' уш1а?",
            "translation": "Do you know how '''to dance'''?."
          },
          {
            "sentence": "Синыбджэгъухэр сич1ылэ '''къэшъотых'''",
            "translation": "My friends '''are gonna dance''' in our village."
          }
        ]
      }
    ],
    "synonyms": [
      "къеш1эн",
      "еш1эн"
    ],
    "cognate": "джэгун",
    "type": "verb",
    "ipa": "qaʂʷan"
  },
  {
    "word": "пхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tie",
        "examples": [
          {
            "sentence": "К1апсэк1э '''пхы'''",
            "translation": "'''Tie''' with a rope."
          },
          {
            "sentence": "К1алэм ицуакъэ лъапсэхэр '''епхых'''",
            "translation": "The boy '''is tying''' his shoelaces."
          },
          {
            "sentence": "'''Зэпхык1э''' зэгъаш1",
            "translation": "Learn to '''tie'''."
          },
          {
            "sentence": "К1алэм хьэр '''ипхэгъ'''",
            "translation": "The boy '''tied''' the dog."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pxən"
  },
  {
    "word": "жьыгъэбыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kite",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʁabəb"
  },
  {
    "word": "жьыгъэбыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boaster, braggart",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэщытхъужь"
    ],
    "type": "noun",
    "ipa": "ʑəʁabəw"
  },
  {
    "word": "гъэпц1ак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheater, deceiver, fraud, faker, trickster",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁapt͡sʼaːkʷʼa"
  },
  {
    "word": "жьыгъэшъуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bellflower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʁaʂʷəj"
  },
  {
    "word": "жьызалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air filter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəzaːɬ"
  },
  {
    "word": "жьызэпеу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air current",
        "examples": []
      },
      {
        "meaning": "air flow",
        "examples": []
      },
      {
        "meaning": "draft",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəzapajw"
  },
  {
    "word": "жьыкорен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "antenna",
        "examples": []
      },
      {
        "meaning": "tornado",
        "examples": []
      }
    ],
    "synonyms": [
      "мэкъэкъащ",
      "жьыхъырз",
      "жьыхьарз"
    ],
    "type": "noun",
    "ipa": "ʑəkʷarajn"
  },
  {
    "word": "жьыхьарз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tornado",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыкорен",
      "жьыхъырз"
    ],
    "type": "noun",
    "ipa": "ʑəħaːrz"
  },
  {
    "word": "жьыхъырз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tornado",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыкорен",
      "жьыхьарз"
    ],
    "type": "noun",
    "ipa": "ʑəχərz"
  },
  {
    "word": "мэкъэкъащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "antenna",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыкорен"
    ],
    "type": "noun",
    "ipa": "maqaqaːɕ"
  },
  {
    "word": "жьыкъуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "atmospheric pressure",
        "examples": []
      },
      {
        "meaning": "air pressure",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыкъузалъ"
    ],
    "type": "noun",
    "ipa": "ʑəqʷəz"
  },
  {
    "word": "къохь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ship",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэ",
      "жьыкъухь"
    ],
    "cognate": "кхъухь",
    "type": "noun",
    "ipa": "qʷaħ"
  },
  {
    "word": "жьыкъухь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ship",
        "examples": []
      },
      {
        "meaning": "Sail boat",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэ",
      "къохь"
    ],
    "cognate": "кхъухь",
    "type": "noun",
    "ipa": "ʑəqʷəħ"
  },
  {
    "word": "жьыкъыдэгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to exhale",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʑəqədaʁat͡ʃən"
  },
  {
    "word": "икъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suffice, to be sufficient / enough",
        "examples": [
          {
            "sentence": "'''Икъущт''' ахъщэ си1эр",
            "translation": "The money I have '''will be enough'''"
          },
          {
            "sentence": "'''Икъугъ''' непэ шъузэрэчъагъэр",
            "translation": "You (pl.) ran today '''enough'''."
          },
          {
            "sentence": "'''Икъурэп''' ц1ыфэу зэрэтикъурэр джэгум тихьэн фэш1ык1э",
            "translation": "'''We are not enough''' people to enter the game."
          },
          {
            "sentence": "Мы1эрысэу къэпхьыгъэр '''Икъущт''' ц1ыфыхэм яфэш1ык1э",
            "translation": "The apples you brought '''will be enough''' for the people."
          },
          {
            "sentence": "Ахъщэу у1ыгъэр '''екъу'''",
            "translation": "The amount of money you have '''is enough'''."
          },
          {
            "sentence": "Ахъщэу сы1ыгъэр '''Икъущт''' мащинэ к1э фэш1ык1э",
            "translation": "The money I have '''will be enough''' for a new car."
          },
          {
            "sentence": "Псэу къуаом къибгъэхъуагъэр '''екъу'''",
            "translation": "The water you poured into the bucket '''is enough'''."
          },
          {
            "sentence": "Ц1ыфэу къэпщагъэр '''икъущтэп'''",
            "translation": "The amount of people you brought '''will not be enough'''."
          },
          {
            "sentence": "Ахъщэу къысэптыгъэр '''икъугъэп''' мащинэ к1эм фэш1ык1э",
            "translation": "The amount of money you gave me '''was not enough''' for the new car."
          },
          {
            "sentence": "Ады, шхынэу къэтхьыгъэр '''икъущта''' мы пщэрымэ яфэш1ык1э",
            "translation": "Of course, '''will''' the amount of food we brought '''be enough''' for these fat ones."
          },
          {
            "sentence": "'''Икъунэ''' экзамыным уфеджагъэп, ащ фэш1ык1э '''икъун''' къипхыгъэп",
            "translation": "Of course, '''will''' the amount of food we brought '''be enough''' for these fat ones."
          },
          {
            "sentence": "Cэ зы пцэжъые '''къэсфекъу'''",
            "translation": "One fish '''is enough''' for me."
          }
        ]
      },
      {
        "meaning": "to be of that amount",
        "examples": [
          {
            "sentence": "Тэ пл1эу '''текъу''', шъо т1оу '''шъуекъу'''",
            "translation": "'''We are''' four, you (pl.) '''are two'''."
          },
          {
            "sentence": "Тиунэ къэк1ощтыхэр тфэу '''екъух'''",
            "translation": "The ones that will come to my house '''are''' five."
          },
          {
            "sentence": "Ц1ыфэхэу экзамэным ихьэщтыхэр пш1эу '''екъух'''",
            "translation": "The people that will enter the exam '''are''' ten."
          },
          {
            "sentence": "Ц1ыфэхэу экзамэным имыхьэщтыхэр щэу '''екъущтых'''",
            "translation": "The people that will not enter the exam '''will be''' three."
          },
          {
            "sentence": "Умыгуа1, тэ мащинэмк1э тфэу '''тикъоу''' тисыщтэп, пл1ы ны1эп зэрэтикъущтыр",
            "translation": "Don't worry, we '''won't be''' sitting as five people in the car, '''we will be''' only four people."
          },
          {
            "sentence": "Тиунэ къэк1ощтыхэр тфэу '''екъух'''",
            "translation": "The ones that will come to my house '''are''' five."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəqʷən"
  },
  {
    "word": "жьыкъэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to inhale; to breathe",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʑəqaɕan"
  },
  {
    "word": "жьыкъэщэуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "asthma",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыуз"
    ],
    "type": "noun",
    "ipa": "ʑəqaɕawz"
  },
  {
    "word": "жьыуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "asthma",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыкъэщэуз"
    ],
    "type": "noun",
    "ipa": "ʑəwz"
  },
  {
    "word": "мэт1ыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ripen",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mətʼəʁʷam"
  },
  {
    "word": "жьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to comb",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʑən"
  },
  {
    "word": "жьынэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Air front",
        "examples": []
      },
      {
        "meaning": "Wind front,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑənaʔʷ"
  },
  {
    "word": "жьыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Air front",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəpa"
  },
  {
    "word": "жьырытэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Riser (early)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑərətad͡ʒ"
  },
  {
    "word": "жьыуап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "windward place",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəwaːpʼa"
  },
  {
    "word": "жьыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəf"
  },
  {
    "word": "лъытфэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъынтфэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬətfa"
  },
  {
    "word": "п1ак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1уак1э\"",
        "examples": []
      }
    ],

    "cognate": "п1ащ1э",
    "type": "adj",
    "ipa": "pʼaːt͡ʃa"
  },
  {
    "word": "п1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pʼən"
  },
  {
    "word": "псэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fir",
        "examples": []
      },
      {
        "meaning": "evergreen",
        "examples": []
      }
    ],

    "cognate": "псей",
    "type": "noun",
    "ipa": "psajə"
  },
  {
    "word": "бае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rich, wealthy",
        "examples": [
          {
            "sentence": "Л1ыр '''бае'''",
            "translation": "The man '''is rich'''."
          },
          {
            "sentence": "Л1ы '''баeр''' мак1о",
            "translation": "'''the rich''' man is going."
          },
          {
            "sentence": "Л1ыр нэхь '''баеу''' хъугъэ",
            "translation": "The man became more '''rich'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "baːja"
  },
  {
    "word": "тхъубэ къегъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foam",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "налкъут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "emerald",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naːlqʷət"
  },
  {
    "word": "бэлахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "martyr (бэлахь зытелъыр)",
        "examples": []
      },
      {
        "meaning": "ordeal (бэлахь хэтыныр)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "balaːħ"
  },
  {
    "word": "зафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "straight, just (good person)",
        "examples": [
          {
            "sentence": "л1эр '''зафэ'''",
            "translation": "The man is '''straight'''."
          },
          {
            "sentence": "л1ы '''зафэм''' идэжь шъук1у",
            "translation": "Go to the '''straight''' man. (Said to plural)"
          }
        ]
      }
    ],

    "cognate": "захуэ",
    "type": "adj",
    "ipa": "zaːfa"
  },
  {
    "word": "нэкъокъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be arguing",
        "examples": [
          {
            "sentence": "К1алэр '''мэнэкъуакъо'''",
            "translation": "The boy is '''arguing'''."
          },
          {
            "sentence": "'''Умынэкъуакъу'''",
            "translation": "'''Don't argue'''."
          },
          {
            "sentence": "'''Умынэкъуакъуу''' къас1орэ ш1э",
            "translation": "'''Without arguing''', do as I said."
          },
          {
            "sentence": "Уш ик1алэ бэрэ '''мэнэкъуакъо'''",
            "translation": "That person's boy '''argue''' a lot."
          },
          {
            "sentence": "Зымафэгорэм тыгъэмрэ мазэмрэ '''зэнэкъокъугъэх'''",
            "translation": "One day the sun and the moon '''argued'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naqʷaqʷan"
  },
  {
    "word": "т1ысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sit down",
        "examples": [
          {
            "sentence": "'''т1ыс'''",
            "translation": "'''sit down'''."
          },
          {
            "sentence": "к1алэр '''мэт1ысы'''",
            "translation": "The boy is '''sitting down'''."
          },
          {
            "sentence": "'''умыт1ыс'''",
            "translation": "'''Don't sit down'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tʼəsən"
  },
  {
    "word": "хьацэп1ацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "insect",
        "examples": []
      }
    ],
    "synonyms": [
      "хьац1эбац1э"
    ],
    "type": "noun",
    "ipa": "ħaːt͡sapʼaːt͡sa"
  },
  {
    "word": "шъхьащэ фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bow",
        "examples": [
          {
            "sentence": "шъо '''шъхьащэ зыфэшъуш1ырэм''' сэ '''шъхьащэ фэсш1ыщтэп'''",
            "translation": "I do not bow what you bow (I do not worship what you worship) (Said to plural)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʂħaːɕa faʃʼən"
  },
  {
    "word": "еджап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "school",
        "examples": [
          {
            "sentence": "к1алэр '''еджап1эм''' мак1о",
            "translation": "The boy is going '''to school'''."
          }
        ]
      }
    ],
    "synonyms": [
      "егьап1э",
      "----"
    ],
    "type": "noun",
    "ipa": "jad͡ʒaːpʼa"
  },
  {
    "word": "егьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еджап1э\"",
        "examples": []
      }
    ],

    "cognate": "еджап1э",
    "type": "noun",
    "ipa": "jaɡʲaːpʼa"
  },
  {
    "word": "к1элэегъадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "teacher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaɮaʁaːd͡ʒ"
  },
  {
    "word": "к1элэегъагь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1элэегъадж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼalaʁaːɡʲ"
  },
  {
    "word": "жэхэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit someone's head",
        "examples": [
          {
            "sentence": "к1алэр л1ым '''жэхэуагъ'''",
            "translation": "The boy '''hit''' the man's '''face'''."
          },
          {
            "sentence": "'''усжэхэот'''",
            "translation": "'''I will hit your face'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʒaxawan"
  },
  {
    "word": "хэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit someone's face",
        "examples": [
          {
            "sentence": "к1алэр л1ым '''хэуагъ'''",
            "translation": "The boy '''hit''' the man's '''face'''."
          },
          {
            "sentence": "'''усхэот'''",
            "translation": "'''I will hit your face'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawan"
  },
  {
    "word": "л1ык1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "messenger",
        "examples": []
      },
      {
        "meaning": "mediator, intermediary, negotiator",
        "examples": []
      },
      {
        "meaning": "legate; ambassador",
        "examples": [
          {
            "sentence": "Хэгъэгум '''л1ык1о''' ыгъэк1уагъ",
            "translation": "The country sent a '''ambassador'''."
          }
        ]
      },
      {
        "meaning": "delegate; representative",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''ил1ык1о'''",
            "translation": "The boy is the man's '''delegate'''."
          },
          {
            "sentence": "Мыр тхьэм '''ил1ык1о'''",
            "translation": "This is God's '''delegate'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɬʼəkʷʼa"
  },
  {
    "word": "жабз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Language (spoken)",
        "examples": []
      },
      {
        "meaning": "speech",
        "examples": []
      },
      {
        "meaning": "talk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒabz"
  },
  {
    "word": "жае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stubborn",
        "examples": []
      },
      {
        "meaning": "unruly",
        "examples": []
      },
      {
        "meaning": "obscene",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒaːja"
  },
  {
    "word": "жакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person or animal that have a curved mouth",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒaːqa"
  },
  {
    "word": "жалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "epiglottis",
        "examples": []
      },
      {
        "meaning": "Gill of a fish",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaːla"
  },
  {
    "word": "жант1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Front position,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaːntʼ"
  },
  {
    "word": "жаш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eloquent",
        "examples": []
      },
      {
        "meaning": "fluent",
        "examples": []
      },
      {
        "meaning": "well-spoken",
        "examples": []
      }
    ],
    "synonyms": [
      "бзаш1у"
    ],
    "type": "adj",
    "ipa": "ʒaːʃʷʼ"
  },
  {
    "word": "жашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "membrance",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaːʂʷ"
  },
  {
    "word": "жыкъузалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "compressor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒəqʷəzaːɬ"
  },
  {
    "word": "жын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disgorge, to vomit, to puke, to throw up",
        "examples": [
          {
            "sentence": "К1алэр '''къэжы'''.",
            "translation": "The boy '''is vomiting'''."
          },
          {
            "sentence": "'''укъэжырагъомэ''' псыунэм ихь",
            "translation": "'''If you want to throw up''', go to the toilet."
          },
          {
            "sentence": "'''Къэжырэ''' мэшхэн фай.",
            "translation": "'''One who vomits''' must eat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʒən"
  },
  {
    "word": "жьаугъуадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "refuge (a place protected from wind or sun)",
        "examples": []
      }
    ],
    "synonyms": [
      "жьауш1"
    ],
    "type": "noun",
    "ipa": "ʑaːwʁʷaːd͡ʒ"
  },
  {
    "word": "жьауш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "refuge (a place protected from wind or sun)",
        "examples": []
      }
    ],
    "synonyms": [
      "жьаугъуадж"
    ],
    "type": "noun",
    "ipa": "ʑaːwʃʼ"
  },
  {
    "word": "жьы1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Early (relatively)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʑəʔʷa"
  },
  {
    "word": "1эш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweet",
        "examples": []
      },
      {
        "meaning": "tasty",
        "examples": []
      },
      {
        "meaning": "something sweet (e.g. candy, cage)",
        "examples": []
      }
    ],
    "synonyms": [
      "го1у",
      "мыщыу"
    ],
    "cognate": "1эф1",
    "type": "adj",
    "ipa": "ʔaʃʷʼə"
  },
  {
    "word": "жьы1эш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A pleasant breeze",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʔaʃʷʼ"
  },
  {
    "word": "жьыбгъэос",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Snow brought by wind",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəbʁawas"
  },
  {
    "word": "жьыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "One pass of a comb in the hair,",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʁʷa"
  },
  {
    "word": "жьыгъогу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airline",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʁʷaɡʷ"
  },
  {
    "word": "ае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1ае\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːja"
  },
  {
    "word": "пэ1ухъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curtain",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paʔʷəχʷa"
  },
  {
    "word": "жыфлъат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "helicopter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəfɬaːt"
  },
  {
    "word": "кухьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cart",
        "examples": []
      },
      {
        "meaning": "cartload",
        "examples": []
      }
    ],
    "synonyms": [
      "ку"
    ],
    "type": "noun",
    "ipa": "kʷəħa"
  },
  {
    "word": "унэ шъхьаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "room",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wəna ʂħaːf"
  },
  {
    "word": "ошэкур",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed",
        "examples": []
      },
      {
        "meaning": "mattress",
        "examples": []
      }
    ],
    "synonyms": [
      "гъолъып1э",
      "ошэкур"
    ],
    "type": "noun",
    "ipa": "waʃakʷər"
  },
  {
    "word": "гъолъып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed",
        "examples": []
      },
      {
        "meaning": "bedroom",
        "examples": []
      },
      {
        "meaning": "couch",
        "examples": []
      }
    ],
    "synonyms": [
      "п1эк1ор",
      "ошэкур",
      "чъыяп1э"
    ],
    "type": "noun",
    "ipa": "ʁʷaɬəpʼa"
  },
  {
    "word": "нафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clear",
        "examples": []
      },
      {
        "meaning": "clarity",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naːfa"
  },
  {
    "word": "пхъэнтэк1у к1ыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bench",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχantakʷʼ t͡ʃəħ"
  },
  {
    "word": "онэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "top of the saddle (where is the rider sits)",
        "examples": [
          {
            "sentence": "'''Онэгум''' къиутын",
            "translation": "To knock down him from the saddle."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wanaɡʷ"
  },
  {
    "word": "бегъымбар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prophet",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьэл1ык1у",
      "----"
    ],
    "type": "noun",
    "ipa": "beʁəmbaːr"
  },
  {
    "word": "парэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "money",
        "examples": []
      }
    ],
    "synonyms": [
      "ахъщэ",
      "ахъчэ"
    ],
    "cognate": "ахъшэ",
    "type": "noun",
    "ipa": "paːra"
  },
  {
    "word": "хъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grave",
        "examples": []
      }
    ],
    "synonyms": [
      "къэ"
    ],
    "cognate": "кхъэ",
    "type": "noun",
    "ipa": "χa"
  },
  {
    "word": "ч1ыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "land",
        "examples": []
      },
      {
        "meaning": "earth",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1ылъэ"
    ],
    "cognate": "щ1ылъэ",
    "type": "noun",
    "ipa": "t͡ʃʼəɡʷə"
  },
  {
    "word": "шъэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "knife",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂaʐəja"
  },
  {
    "word": "обзэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ax",
        "examples": []
      }
    ],
    "synonyms": [
      "отыч",
      "ощы",
      "майтэ"
    ],
    "cognate": "джыдэ",
    "type": "noun",
    "ipa": "wabzaʁʷ"
  },
  {
    "word": "ощы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ax",
        "examples": []
      }
    ],
    "synonyms": [
      "отыч",
      "обзэгъу",
      "майтэ"
    ],
    "cognate": "джыдэ",
    "type": "noun",
    "ipa": "waɕə"
  },
  {
    "word": "ожьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weather",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "waʑə"
  },
  {
    "word": "бэрэскэшху",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Friday",
        "examples": []
      }
    ],

    "cognate": "мэрем",
    "type": "noun",
    "ipa": "baraskaʃxʷ"
  },
  {
    "word": "нэмазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prayer (The act of praying)",
        "examples": []
      }
    ],

    "cognate": "нэмэз",
    "type": "noun",
    "ipa": "namaːza"
  },
  {
    "word": "ошъогу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sky",
        "examples": []
      },
      {
        "meaning": "zenith",
        "examples": []
      }
    ],

    "cognate": "уэфэгу",
    "type": "noun",
    "ipa": "waʂʷaɡʷ"
  },
  {
    "word": "псалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "word",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1э",
      "----"
    ],
    "type": "noun",
    "ipa": "/psaːɬa/"
  },
  {
    "word": "ятэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uncle (Father's brother)",
        "examples": []
      }
    ],
    "synonyms": [
      "атэш"
    ],
    "type": "noun",
    "ipa": "jaːtaʃ"
  },
  {
    "word": "янэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uncle (Mother's brother)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːnaʃ"
  },
  {
    "word": "узыныгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pain",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wəzənəʁa"
  },
  {
    "word": "мэузын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel pain or to ache in a specific part of the body",
        "examples": [
          {
            "sentence": "Сэ си1и силъакъуи '''мэузых'''",
            "translation": "My hand and foot '''also hurt'''."
          },
          {
            "sentence": "Синыбэ '''мэузы'''",
            "translation": "My stomach '''hurts'''."
          },
          {
            "sentence": "Маш1ом унэсымэ уи1э къистыни '''мэузыт'''",
            "translation": "If you touch the fire, it will burn your hand and '''it will hurt'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawzən"
  },
  {
    "word": "хэузык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel pain or to ache (In general)",
        "examples": [
          {
            "sentence": "Л1ым зыгори '''хэузык1ырэп'''",
            "translation": "The man '''doesn't feel pain''' at all."
          },
          {
            "sentence": "'''Къэсхэузык1ы'''",
            "translation": "'''I am aching''' (feel pain)."
          },
          {
            "sentence": "Къэцым унэсымэ '''къупхэузык1ыт'''",
            "translation": "If you touch the thorn '''you will get hurt'''."
          }
        ]
      }
    ],
    "synonyms": [
      "хэузык1ьын"
    ],
    "type": "verb",
    "ipa": "xawzət͡ʃən"
  },
  {
    "word": "хэузык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэузык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хэузык1ын"
    ],
    "type": "verb",
    "ipa": "xawzəkʲən"
  },
  {
    "word": "гъэузын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone feel pain or to hurt someone (in a specific part in his body)",
        "examples": [
          {
            "sentence": "Къэцым '''егъэузы''' унэсымэ",
            "translation": "The thorn '''hurts''' if you touch it."
          },
          {
            "sentence": "К1алэм '''егъэузы''' с1э",
            "translation": "The boy '''hurts''' my arm."
          },
          {
            "sentence": "Л1ы лъэшым ы1э '''сыгъэузышъурэп'''",
            "translation": "'''I can't hurt''' the arm of the strong man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawzən"
  },
  {
    "word": "хьэп1ац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "worm",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэблыу",
      "хьамлыу",
      "----"
    ],
    "cognate": "хьэмбылыу",
    "type": "noun",
    "ipa": "ħapʼaːt͡sʼa"
  },
  {
    "word": "ц1ыфыбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "woman",
        "examples": []
      }
    ],

    "cognate": "ц1ыхубз",
    "type": "noun",
    "ipa": "/t͡sʼəfəbz/"
  },
  {
    "word": "ц1ыфыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "man",
        "examples": []
      }
    ],

    "cognate": "ц1ыхухъу",
    "type": "noun",
    "ipa": "/t͡sʼəfəχʷ/"
  },
  {
    "word": "шъофбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Steppe bird",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂʷafbzəw"
  },
  {
    "word": "бэгьэхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэджыхъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "бэджыхъ"
    ],
    "type": "noun",
    "ipa": "baɡʲaχ"
  },
  {
    "word": "шхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to eat",
        "examples": [
          {
            "sentence": "К1алэр '''машхэ'''",
            "translation": "The boy '''is eating'''."
          },
          {
            "sentence": "К1алэм бананэ '''ешхы'''",
            "translation": "The boy '''is eating''' a banana."
          },
          {
            "sentence": "К1алэм лы '''фэшхырэп'''",
            "translation": "The boy '''can't eat''' meat."
          },
          {
            "sentence": "К1алэ тхьамышк1эм шхын игъотрэп '''ишхынэу'''",
            "translation": "The poor boy can't find food '''to eat'''."
          },
          {
            "sentence": "Шхынэр '''шхы'''",
            "translation": "'''Eat''' the food."
          },
          {
            "sentence": "Якъубэм ра1уагъ Иусыфэр тыгъужъмэ '''яшхэгъ'''",
            "translation": "They told Jacob the wolfs '''ate''' Joseph."
          }
        ]
      }
    ],

    "cognate": "щхэн",
    "type": "verb",
    "ipa": "ʃxan"
  },
  {
    "word": "щэнаут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "poison",
        "examples": []
      }
    ],

    "cognate": "щхъухь",
    "type": "noun",
    "ipa": "/ɕanaːwt/"
  },
  {
    "word": "япэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"апэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːpa"
  },
  {
    "word": "шэк1уахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hound",
        "examples": []
      },
      {
        "meaning": "hunting dog",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэджашъу"
    ],
    "type": "noun",
    "ipa": "ʃakʷʼaːħ"
  },
  {
    "word": "хьэджашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hound",
        "examples": []
      },
      {
        "meaning": "hunting dog",
        "examples": []
      }
    ],
    "synonyms": [
      "шэк1уахь"
    ],
    "type": "noun",
    "ipa": "ħad͡ʒaːʂʷ"
  },
  {
    "word": "хэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill a liquid to another liquid.",
        "examples": [
          {
            "sentence": "Санэ псым '''хапк1эмэ''' егъэплъыжьыт",
            "translation": "If you spill grape on water it will make it red."
          },
          {
            "sentence": "Шхъуант1эрэ гъожьырэ '''зэхапк1эхэмэ''' уцышъоу мэхъут",
            "translation": "'''If you spill''' yellow and blue '''into each other''' it will turn to green."
          },
          {
            "sentence": "Псыхъ псым '''хапк1эмэ''' мэуш1оит",
            "translation": "'''If you spill''' mud on water it will make it dirty."
          }
        ]
      },
      {
        "meaning": "to count (to be included)",
        "examples": [
          {
            "sentence": "Футболистым голэу ригъэк1ыгъэр '''хапк1эрэп'''",
            "translation": "The goal that the football-player scored '''doesn't count'''."
          },
          {
            "sentence": "Ар '''хапк1эрэп'''",
            "translation": "That '''doesn't count'''."
          },
          {
            "sentence": "Футболымк1э шъхьэк1э гол ибгъахьэмэ '''хапк1э''' голэр",
            "translation": "In football if you score a goal using your head, '''it counts''' as a goal."
          }
        ]
      }
    ],
    "synonyms": [
      "хэпк1ьэн"
    ],
    "type": "verb",
    "ipa": "xapt͡ʃan"
  },
  {
    "word": "мэуш1оин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become dirty",
        "examples": [
          {
            "sentence": "псыжъым ухахьэмэ '''уыушъоит'''",
            "translation": "If you go into the mud, '''you will become dirty'''."
          },
          {
            "sentence": "лъым джанэ '''еуш1ои'''",
            "translation": "The blood '''is making''' the shirt '''dirty'''."
          },
          {
            "sentence": "псыжъым '''укъиуш1оит'''",
            "translation": "The mud '''will make you dirty'''."
          },
          {
            "sentence": "псыжъ псым хапк1эмэ '''мэуш1оит'''",
            "translation": "If you spill mud on water '''it will become dirty'''."
          }
        ]
      },
      {
        "meaning": "to get deleted",
        "examples": [
          {
            "sentence": "Фаилэр '''мэуш1оегъ'''",
            "translation": "The file '''is deleted'''."
          },
          {
            "sentence": "Тхыгъэр '''мэуш1ои'''",
            "translation": "The written '''is getting deleted'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawʂʷajən"
  },
  {
    "word": "ш1ои",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dirty, unclean; covered with or containing unpleasant substances such as dirt or grime or mud.",
        "examples": [
          {
            "sentence": "джэнэ '''шъои'''",
            "translation": "'''Dirty''' shirt."
          },
          {
            "sentence": "псэр '''шъои'''",
            "translation": "The water is '''Dirty'''."
          },
          {
            "sentence": "к1алэм иджэнэ '''шъои'''",
            "translation": "The boy's shirt is '''Dirty'''."
          }
        ]
      },
      {
        "meaning": "dirty, Morally unclean; obscene or indecent, especially sexually.",
        "examples": [
          {
            "sentence": "Л1ыр фылым '''шъоихэмэ''' яплъы",
            "translation": "The man is watching the '''dirty''' films."
          }
        ]
      },
      {
        "meaning": "crud",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1уэи"
    ],
    "cognate": "ф1ей",
    "type": "adj",
    "ipa": "ʃʷʼajə"
  },
  {
    "word": "Урысый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Russia",
        "examples": []
      }
    ],
    "type": "name"
  },
  {
    "word": "Адыгэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "republic c/Russia",
        "examples": [],
        "tags": [
          "place",
          "republic"
        ]
      }
    ],
    "type": "name"
  },
  {
    "word": "кущэрэхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wheel (cart, vehicle)",
        "examples": []
      }
    ],
    "synonyms": [
      "щэрыхъ"
    ],
    "type": "noun",
    "ipa": "kʷəɕaraχ"
  },
  {
    "word": "дэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to become stuck (unable to progress)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (should have the prefix къы- /qə-/)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "danan"
  },
  {
    "word": "1эгуау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ball",
        "examples": []
      }
    ],
    "synonyms": [
      "п1ырагу"
    ],
    "type": "noun",
    "ipa": "ʔaɡʷaːw"
  },
  {
    "word": "п1ырагу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ball",
        "examples": []
      }
    ],
    "synonyms": [
      "1эгуау"
    ],
    "type": "noun",
    "ipa": "pʼəraːɡʷ"
  },
  {
    "word": "тэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тенэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tanan"
  },
  {
    "word": "ч1энэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to become stuck (unable to progress)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (should have the prefix къы- /qə-/)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃanan"
  },
  {
    "word": "1унэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to become stuck (unable to progress)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (usually the prefix къы- /qə-/ is added)",
        "examples": []
      },
      {
        "meaning": "to leave someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔʷənan"
  },
  {
    "word": "хэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to become stuck (unable to progress)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (should have the prefix къы- /qə-/)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xanan"
  },
  {
    "word": "маш1о хэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ignite, to get on fire",
        "examples": [
          {
            "sentence": "'''маш1о хэнагъ'''",
            "translation": "'''It got on fire'''."
          },
          {
            "sentence": "чъыгым '''маш1о хэнагъ'''",
            "translation": "the tree '''got on fire'''."
          },
          {
            "sentence": "газ пхъэхэмэ яхэпк1эмэ '''маш1о хэнэт'''",
            "translation": "If you spell gas on the woods '''they will get on fire'''."
          },
          {
            "sentence": "'''маш1о хэгъан!'''",
            "translation": "'''sit it on fire'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maːʂʷa xanan"
  },
  {
    "word": "пынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to become stuck (unable to progress)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (should have the prefix къы- /qə-/)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pənan"
  },
  {
    "word": "пк1ант1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweat",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ат1э"
    ],
    "cognate": "пщ1ант1эпс",
    "type": "noun",
    "ipa": "pt͡ʃaːntʼa"
  },
  {
    "word": "шъуты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "butt, buttock",
        "examples": [
          {
            "sentence": "К1алэм '''ишъуты''' ч1эшъутыр",
            "translation": "Kick the boy in the '''butt'''."
          },
          {
            "sentence": "'''Уишъуты''' къыч1эщы",
            "translation": "'''Your butt''' is visible."
          }
        ]
      }
    ],
    "synonyms": [
      "пхэк1"
    ],
    "cognate": "пхэ",
    "type": "noun",
    "ipa": "ʃʷətə"
  },
  {
    "word": "п1алъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "appointment",
        "examples": []
      },
      {
        "meaning": "date (A pre-arranged meeting)",
        "examples": []
      }
    ],
    "synonyms": [
      "уахътэ",
      "лъэхъан",
      "----"
    ],
    "type": "noun",
    "ipa": "pʼaːɬa"
  },
  {
    "word": "дао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lawsuit",
        "examples": []
      },
      {
        "meaning": "grievance",
        "examples": []
      },
      {
        "meaning": "complaint",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːwa"
  },
  {
    "word": "кьэрхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swing",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эрхъон",
      "хъэрен"
    ],
    "type": "noun",
    "ipa": "kʲarχʷan"
  },
  {
    "word": "бзыук1ьалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзыук1алъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "бзыук1алъэ"
    ],
    "type": "noun",
    "ipa": "bzəwkʲʼaːɬa"
  },
  {
    "word": "пхъэк1ыпхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пхъэнк1ыпхъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχat͡ʃəpχ"
  },
  {
    "word": "бзаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "despicable, evil, mean",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1ап1э",
      "----"
    ],
    "type": "adj",
    "ipa": "bzaːd͡ʒa"
  },
  {
    "word": "ныкъол1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "undead",
        "examples": []
      },
      {
        "meaning": "half dead",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəqʷaɬʼaːʁa"
  },
  {
    "word": "чыжьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "far",
        "examples": [
          {
            "sentence": "Сиунэ '''чыжьэ'''",
            "translation": "The house is '''far'''."
          },
          {
            "sentence": "Мэзыр зыш1у '''чыжьэ'''",
            "translation": "The forest is really '''far away'''."
          },
          {
            "sentence": "Америка Еуропэм нахь '''чыжьэ'''",
            "translation": "America '''is further''' than Europe."
          }
        ]
      }
    ],

    "cognate": "жыжьэ",
    "type": "adj",
    "ipa": "t͡ʃəʑa"
  },
  {
    "word": "гъэ1улэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fool",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэделэн",
      "----"
    ],
    "type": "verb",
    "ipa": "ʁaʔʷəlan"
  },
  {
    "word": "гъэделэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fool, to deceive",
        "examples": [
          {
            "sentence": "К1алэм '''л1ыр''' ыгъэделагъ",
            "translation": "The boy '''fooled''' the man."
          },
          {
            "sentence": "Ащ '''сигъэдели''' телефон убэтагъэ къысишагъ",
            "translation": "He '''fooled me''' and sold me a broken mobile phone."
          },
          {
            "sentence": "1анэм иуасэмк1э '''сыгъэделэшъугъэп'''",
            "translation": "'''I couldn't fool him''' with the table's price."
          },
          {
            "sentence": "Мо к1алэхэмк1э нэ1э тегъэт, '''уагъэделэни''' уи ахъщэ зэк1эр къып1ахыщт",
            "translation": "Take care from those boys over there, '''they will deceive you''' and take all your money."
          },
          {
            "sentence": "Футболистым ипэ итэ '''къигъэдели''' блэк1ыгъ",
            "translation": "The soccer player '''fooled''' the one that was in front of him and he passed him."
          },
          {
            "sentence": "Ахэр '''тыгъэделэхи''' як1ыбк1э къэтыубытгъэх",
            "translation": "'''We fooled''' them, and we caught them behind."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁadajlan"
  },
  {
    "word": "цадльэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rib",
        "examples": []
      }
    ],
    "synonyms": [
      "цагэ"
    ],
    "cognate": "дзажэ",
    "type": "noun",
    "ipa": "t͡saːd͡ɮa"
  },
  {
    "word": "нэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bright",
        "examples": []
      },
      {
        "meaning": "light",
        "examples": []
      }
    ],
    "synonyms": [
      "нэфнэ"
    ],
    "cognate": "нэху",
    "type": "adj",
    "ipa": "nafən"
  },
  {
    "word": "махълъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "son-in-law",
        "examples": []
      }
    ],
    "synonyms": [
      "малъхъо",
      "пщыкъу",
      "л1ымыш",
      "малхъо"
    ],
    "cognate": "малъхъэ",
    "type": "noun",
    "ipa": "maːχɬa"
  },
  {
    "word": "ш1уалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foreleg",
        "examples": []
      }
    ],

    "cognate": "ф1алъэ",
    "type": "noun",
    "ipa": "ʃʷʼaːɬa"
  },
  {
    "word": "мызуэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rock",
        "examples": []
      },
      {
        "meaning": "stone",
        "examples": []
      }
    ],
    "synonyms": [
      "мыжъо"
    ],
    "cognate": "мывэ",
    "type": "noun",
    "ipa": "məzʷa"
  },
  {
    "word": "пчэндэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "starling",
        "examples": []
      }
    ],

    "cognate": "пжэндэхъу",
    "type": "noun",
    "ipa": "pt͡ʃandaχʷ"
  },
  {
    "word": "чан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sharp (Able to cut easily)",
        "examples": [
          {
            "sentence": "Чатэр '''чан'''",
            "translation": "The sword '''is sharp'''."
          },
          {
            "sentence": "Шъэжъыер '''чан'''",
            "translation": "The knife '''is sharp'''."
          }
        ]
      },
      {
        "meaning": "sharp (Intelligent)",
        "examples": []
      }
    ],
    "synonyms": [
      "чыян (Shapsug)"
    ],
    "cognate": "жан",
    "type": "adj",
    "ipa": "t͡ʃaːn"
  },
  {
    "word": "ц1ак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nit",
        "examples": []
      }
    ],
    "synonyms": [
      "с1ак1ьэ"
    ],
    "type": "noun",
    "ipa": "t͡sʼaːt͡ʃa"
  },
  {
    "word": "с1ак1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nit",
        "examples": []
      }
    ],

    "cognate": "ц1ак1э",
    "type": "noun",
    "ipa": "sʼaːkʲʼa"
  },
  {
    "word": "къамц1ыч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wart",
        "examples": []
      }
    ],

    "cognate": "хьамц1ырачэ",
    "type": "noun",
    "ipa": "qaːmt͡sʼət͡ʃ"
  },
  {
    "word": "сысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shake",
        "examples": [
          {
            "sentence": "Жьыбгъэр къепщэмэ чъыг тхьапэхэр '''сысынэу''' рагъажьэ.",
            "translation": "When the wind blows, the tree leaves start '''to shake'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "səsən"
  },
  {
    "word": "шъомп1эжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beetle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷampʼaʐ"
  },
  {
    "word": "пхъэц1ак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "charred log",
        "examples": []
      },
      {
        "meaning": "firebrand",
        "examples": []
      }
    ],

    "cognate": "пхъэдзак1э",
    "type": "noun",
    "ipa": "pχat͡sʼaːt͡ʃa"
  },
  {
    "word": "ш1унк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "darkness",
        "examples": []
      },
      {
        "meaning": "haze",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼənt͡ʃə"
  },
  {
    "word": "ц1уэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dirty",
        "examples": []
      },
      {
        "meaning": "crud",
        "examples": []
      },
      {
        "meaning": "dark",
        "examples": [
          {
            "sentence": "Унэм '''ш1унк1ы''' дэд",
            "translation": "The room is too '''dark'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ф1эи",
      "ш1ои"
    ],
    "type": "adj",
    "ipa": "ʃʷʼajə or t͡sʷʼajə"
  },
  {
    "word": "пык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to leave someone, to refrain from, to separate from",
        "examples": [
          {
            "sentence": "К1алэм зэзаохэрэ '''зэпигъэк1ыжьгъэх'''",
            "translation": "The boy '''separated''' those that fought."
          },
          {
            "sentence": "Ду к1элит1у зэзаохэрэр '''зэпигъэк1ыжьхэба'''",
            "translation": "'''Separate''' those two boys that are fighting each other '''already'''."
          },
          {
            "sentence": "'''Узпык1ыжьыт'''",
            "translation": "'''I will leave you'''."
          },
          {
            "sentence": "К1алэр пшъашъэм '''пык1ыжьыгъ'''",
            "translation": "The boy '''left''' the girl."
          },
          {
            "sentence": "USB-эр сомпыютэрым '''къыпык1ырэп'''",
            "translation": "The USB '''doesn't come off''' the computer."
          }
        ]
      },
      {
        "meaning": "to divorce from",
        "examples": [
          {
            "sentence": "Мо л1ыр ишъуз '''пык1ыжьыгъ'''",
            "translation": "That man '''divorced from''' his wife."
          }
        ]
      }
    ],
    "synonyms": [
      "пык1ьын"
    ],
    "type": "verb",
    "ipa": "pəkʲʼən"
  },
  {
    "word": "егьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еджэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɡʲan"
  },
  {
    "word": "еджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to call someone",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''къеджэ'''",
            "translation": "The boy '''is calling''' the girl."
          },
          {
            "sentence": "пшъашъэр '''къоджэ'''",
            "translation": "The girl '''is calling you'''."
          },
          {
            "sentence": "к1алэхэмэ '''шъукъадж'''",
            "translation": "'''Call''' the boys (Said to plural)"
          }
        ]
      },
      {
        "meaning": "to name someone",
        "examples": [
          {
            "sentence": "тик1алэ Томк1э '''теджэт'''",
            "translation": "We will '''name''' our boy Tom."
          }
        ]
      },
      {
        "meaning": "to call someone by name",
        "examples": [
          {
            "sentence": "делэк1э '''едж'''",
            "translation": "'''Call him''' stupid."
          }
        ]
      },
      {
        "meaning": "to read",
        "examples": [
          {
            "sentence": "к1алэр тхылъым '''еджэ'''",
            "translation": "The boy '''is reading''' the book."
          },
          {
            "sentence": "тхылъым '''cеджэ'''",
            "translation": "'''I am reading''' the book."
          },
          {
            "sentence": "'''къедж'''",
            "translation": "'''Read'''."
          },
          {
            "sentence": "'''къедж''' тхылъым",
            "translation": "'''Read''' the book."
          }
        ]
      },
      {
        "meaning": "to study",
        "examples": [
          {
            "sentence": "'''едж'''",
            "translation": "'''Study'''."
          },
          {
            "sentence": "'''едж''' дэгъу",
            "translation": "'''Study''' well."
          },
          {
            "sentence": "к1алэр тхылъык1э '''еджэ'''",
            "translation": "The boy '''is studying''' with (using) the book."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jad͡ʒan"
  },
  {
    "word": "тэджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stand up",
        "examples": [
          {
            "sentence": "к1элэр '''мэтэджы'''",
            "translation": "The boy '''is standing'''."
          },
          {
            "sentence": "'''къэтэдж'''",
            "translation": "'''Stand'''."
          },
          {
            "sentence": "л1ыжъэр'''мэтэджышъурэп'''",
            "translation": "The old man '''can't stand'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tad͡ʒən"
  },
  {
    "word": "тэгьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тэджын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "taɡʲən"
  },
  {
    "word": "угьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dance",
        "examples": [
          {
            "sentence": "К1алэр '''къэугьы'''",
            "translation": "The boy '''is dancing'''."
          },
          {
            "sentence": "К1алэр дахэу '''угьы'''",
            "translation": "The boy '''is dancing''' beautifully."
          },
          {
            "sentence": "'''Угьынэу''' уш1а?",
            "translation": "Do you know how '''to dance'''?."
          },
          {
            "sentence": "Синыбджэгъухэр сич1ылэ '''шыугьытых'''",
            "translation": "My friends '''are gonna dance''' in our village."
          }
        ]
      }
    ],
    "synonyms": [
      "къэшъон"
    ],
    "type": "verb",
    "ipa": "wəɡʲən"
  },
  {
    "word": "уджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dance",
        "examples": [
          {
            "sentence": "К1алэр '''къэуджы'''",
            "translation": "The boy '''is dancing'''."
          },
          {
            "sentence": "К1алэр дахэу '''уджы'''",
            "translation": "The boy '''is dancing''' beautifully."
          },
          {
            "sentence": "'''Уджынэу''' уш1а?",
            "translation": "Do you know how '''to dance'''?."
          },
          {
            "sentence": "Синыбджэгъухэр сич1ылэ '''шыуджытых'''",
            "translation": "My friends '''are gonna dance''' in our village."
          }
        ]
      }
    ],
    "synonyms": [
      "еш1эн",
      "къэшъон",
      "----"
    ],
    "cognate": "джэгун",
    "type": "verb",
    "ipa": "wd͡ʒən"
  },
  {
    "word": "лъапс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъапц1э\"",
        "examples": []
      }
    ],

    "cognate": "лъапц1э",
    "type": "adj",
    "ipa": "ɬaːpsʼa"
  },
  {
    "word": "непэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "today",
        "examples": [
          {
            "sentence": "'''непэ''' тк1от йуропа",
            "translation": "'''Today''', we will go to Europe."
          },
          {
            "sentence": "'''непэ''' томэр къэк1уагъ",
            "translation": "'''Today''' Tom came."
          }
        ]
      }
    ],
    "synonyms": [
      "нэпэ"
    ],
    "cognate": "нобэ",
    "type": "adv",
    "ipa": "najpa"
  },
  {
    "word": "ныбгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quail (bird)",
        "examples": []
      }
    ],

    "cognate": "ныбгъуэ",
    "type": "noun",
    "ipa": "nəbʁʷə"
  },
  {
    "word": "пчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to count",
        "examples": [
          {
            "sentence": "К1алэм '''епчъы'''.",
            "translation": "t=The boy '''is counting'''."
          },
          {
            "sentence": "Сэ Адыгэбзэк1э '''сэпчъы'''.",
            "translation": "t='''I am counting''' in Circassian."
          },
          {
            "sentence": "К1алэм бэнанэхэр '''епчъых'''.",
            "translation": "t=The boy '''is counting''' the bananas."
          },
          {
            "sentence": "Хьэпщ бэнанэу ти1э '''пчъы'''.",
            "translation": "t='''Count''' how many bananas we have."
          },
          {
            "sentence": "К1элэцык1ум шъэ нэс '''къипчъышъут'''.",
            "translation": "t=The little boy '''can count''' to a hundred."
          }
        ]
      },
      {
        "meaning": "to calculate",
        "examples": []
      },
      {
        "meaning": "to enumerate, to list (to specify each member of a sequence individually in incrementing order)",
        "examples": []
      }
    ],
    "synonyms": [
      "лъытэн"
    ],
    "type": "verb",
    "ipa": "pt͡ʃən"
  },
  {
    "word": "джабгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "right (the opposite of left)",
        "examples": []
      }
    ],
    "synonyms": [
      "жъабгъо"
    ],
    "cognate": "ижь",
    "type": "noun",
    "ipa": "d͡ʒaːbʁʷa"
  },
  {
    "word": "1ыгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hold",
        "examples": [
          {
            "sentence": "1унк1ыбзэр '''у1ыгъа'''?",
            "translation": "'''Do you hold''' the key?"
          },
          {
            "sentence": "К1элэцык1у '''сы1эгъ'''",
            "translation": "'''I am holding''' a little boy."
          },
          {
            "sentence": "'''Сы1ыгъагъ''' мые",
            "translation": "'''I was holding''' an apple."
          },
          {
            "sentence": "Пшъашъэм тхылъ бэ '''и1ыгъ'''",
            "translation": "The girl '''holds''' a lot books."
          },
          {
            "sentence": "Къэлэм '''у1ыгъэмк1э''' тхэ",
            "translation": "Write '''with (using)''' the pencil '''you holding'''."
          },
          {
            "sentence": "Зы тон '''сы1ыгъэшъутэп'''",
            "translation": "'''I can't hold''' 1 ton."
          },
          {
            "sentence": "Мыехэр тэрэзэу 1ыгъэх '''къуп1эпызытых'''",
            "translation": "Hold the apples correctly '''you will drop them'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔəʁan"
  },
  {
    "word": "гъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burrow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷə"
  },
  {
    "word": "гъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to thunder (to make a noise like thunder)",
        "examples": []
      },
      {
        "meaning": "to rattle (to make a rattling noise)",
        "examples": []
      },
      {
        "meaning": "(Kfar Kama dialect) to bark",
        "examples": [
          {
            "sentence": "Хьэр '''мэгъуагъо'''",
            "translation": "The dog '''is barking'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "synonyms": [
      "хьакъун"
    ],
    "cognate": "гъуэгъуэн",
    "type": "verb",
    "ipa": "ʁʷaʁʷan"
  },
  {
    "word": "гъумы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thick",
        "examples": [
          {
            "sentence": "Чъыг '''гъум'''",
            "translation": "Thick tree."
          },
          {
            "sentence": "К1эпсэ '''гъум'''",
            "translation": "Thick rope."
          },
          {
            "sentence": "1удэнэ '''гъумк1э''' дыгъэ",
            "translation": "Sew with a thick string."
          }
        ]
      },
      {
        "meaning": "pregnant",
        "examples": [
          {
            "sentence": "Шъуз '''гъумы'''",
            "translation": "Pregnant wife."
          }
        ]
      }
    ],
    "synonyms": [
      "лъэрымыхь",
      "лъэшыдж"
    ],
    "type": "adj",
    "ipa": "ʁʷəmə"
  },
  {
    "word": "гъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be dehydrate; to dry out",
        "examples": [
          {
            "sentence": "Уцым псы емытымэ '''гъущт'''",
            "translation": "If you don't give water to the plant, '''it will dry'''."
          },
          {
            "sentence": "Къэгъагъхэр '''мэгъух'''",
            "translation": "The flower '''is drying'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷən"
  },
  {
    "word": "коцы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wheat",
        "examples": []
      }
    ],

    "cognate": "гуэдз",
    "type": "noun",
    "ipa": "kʷat͡sə"
  },
  {
    "word": "куахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pitchfork, hayfork",
        "examples": []
      },
      {
        "meaning": "winnow",
        "examples": []
      }
    ],

    "cognate": "гуахъуэ",
    "type": "noun",
    "ipa": "kʷaːχʷa"
  },
  {
    "word": "мэкьэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэчэрэзын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэчэрэзын"
    ],
    "cognate": "мэджэрэзын",
    "type": "verb",
    "ipa": "makʲarazən"
  },
  {
    "word": "к1ак1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "short",
        "examples": []
      }
    ],
    "synonyms": [
      "кьак1ы",
      "----"
    ],
    "type": "adj",
    "ipa": "t͡ʃaːt͡ʃə"
  },
  {
    "word": "лъэк1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shin",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэк1ьап1э"
    ],
    "cognate": "лъэнк1ап1э",
    "type": "noun",
    "ipa": "ɬat͡ʃaːpʼ"
  },
  {
    "word": "ук1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1ын\"",
        "examples": []
      }
    ],

    "cognate": "ук1ын",
    "type": "verb",
    "ipa": "wkʲʼən"
  },
  {
    "word": "ук1орэин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall, to roll down",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wkʷʼarajən"
  },
  {
    "word": "лъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump",
        "examples": [
          {
            "sentence": "КIалэр '''лъэнкIэ''' Iазэ",
            "translation": "The boy skilled '''with jumping'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬan"
  },
  {
    "word": "лъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leg",
        "examples": []
      }
    ],
    "synonyms": [
      "лъакъо"
    ],
    "type": "noun",
    "ipa": "ɬa"
  },
  {
    "word": "лъэхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rivulet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaħa"
  },
  {
    "word": "лъынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bird's fat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəna"
  },
  {
    "word": "зэхэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to understand clearly (To perceive sounds through the ear clearly), to hear well, to make out sound clearly",
        "examples": [
          {
            "sentence": "Макъэ башэ шы1 уимакъэ '''зэхэсхышъурэп'''",
            "translation": "There is too much noise, '''I can't understand you'''."
          },
          {
            "sentence": "Тэлэфонымк1э къап1орэ '''зэхэсэхы'''",
            "translation": "In the phone '''I hear you well'''."
          },
          {
            "sentence": "Чыжьэу к1алэу 1утхымэ симакъэ '''зэхахрэп'''",
            "translation": "The boys that are far '''can't hear''' my voice '''well'''."
          },
          {
            "sentence": "Мэзым л1ыгорэ мэкуоу '''зэхэсыхэгъ'''",
            "translation": "'''I heard''' some man screaming on the forest."
          }
        ]
      },
      {
        "meaning": "to disassemble, To take to pieces; to reverse the process of assembly.",
        "examples": [
          {
            "sentence": "Сомпыютэрэр '''зэхэх'''",
            "translation": "'''Disassemble''' the computer."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxaxən"
  },
  {
    "word": "фын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drive",
        "examples": [
          {
            "sentence": "К1алэм '''ефы'''.",
            "translation": "The boy '''is driving'''."
          },
          {
            "sentence": "Машинэр '''фын'''.",
            "translation": "'''To drive''' a car."
          },
          {
            "sentence": "Iэхъогъур псыхъом '''фын'''.",
            "translation": "'''To drive''' the herd to the river."
          }
        ]
      },
      {
        "meaning": "to drive off, to drive away (To force someone to leave or go away.)",
        "examples": []
      }
    ],
    "cognate": "хун",
    "type": "verb",
    "ipa": "fən"
  },
  {
    "word": "ехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go down",
        "examples": [
          {
            "sentence": "'''ех'''",
            "translation": "'''Go down'''."
          },
          {
            "sentence": "к1алэр '''ехы'''",
            "translation": "The boy '''is going down'''."
          },
          {
            "sentence": "лъэгъуаем '''къех'''",
            "translation": "'''Come get down''' from the stair."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaxə"
  },
  {
    "word": "лъфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to give birth",
        "examples": [
          {
            "sentence": "Бзылъфыгъэр '''малъфэ'''",
            "translation": "The woman '''is giving birth'''."
          },
          {
            "sentence": "Бзылъфыгъэм к1алэ '''къылъфыгъ'''",
            "translation": "The woman '''gave birth to''' a boy."
          },
          {
            "sentence": "Шъэожъые ц1ык1у ш1агъо '''къылъфыгъ'''",
            "translation": "She '''gave birth to''' a pretty boy."
          }
        ]
      },
      {
        "meaning": "to calve; to lamb; to farrow; to give birth to an animal",
        "examples": [
          {
            "sentence": "Чэмыр лъфагъэ",
            "translation": "The '''cow calved'''."
          }
        ]
      },
      {
        "meaning": "to beget",
        "examples": []
      }
    ],
    "synonyms": [
      "къэхьын"
    ],
    "cognate": "лъхуэн",
    "type": "verb",
    "ipa": "ɬfan"
  },
  {
    "word": "шэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wax",
        "examples": []
      }
    ],

    "cognate": "шэху",
    "type": "noun",
    "ipa": "ʃafə"
  },
  {
    "word": "пхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anus",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуты",
      "пхэк1",
      "----"
    ],
    "type": "noun",
    "ipa": "pxa"
  },
  {
    "word": "кукунэгъэбылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hide and seek (game)",
        "examples": []
      }
    ],
    "synonyms": [
      "къангъэбылъ",
      "къан-къангъэбылъ"
    ],
    "type": "noun",
    "ipa": "kʷəkʷnaʁabəɬ"
  },
  {
    "word": "лъыхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to search for, to look for",
        "examples": [
          {
            "sentence": "Л1ым '''лъэхъух'''",
            "translation": "They are '''searching for''' the man."
          },
          {
            "sentence": "К1алэр ихьэ '''лъэхъу'''",
            "translation": "The boy '''is looking for''' his dog."
          },
          {
            "sentence": "Унэмк1э '''лъыхъу''' 1эгуаум",
            "translation": "'''Search''' in the house '''for''' the ball."
          },
          {
            "sentence": "Зыгъэбылъи '''услъыхъут'''",
            "translation": "Hide yourself and '''I will search you'''."
          },
          {
            "sentence": "Къусигъусэу былым '''къуздэлъыхъута?'''",
            "translation": "'''Will you search''' accompanying me '''for''' the treasure?."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬəχʷən"
  },
  {
    "word": "гъэбылъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide",
        "examples": [
          {
            "sentence": "К1алэм джэгуалъэр '''егъэбылъы'''",
            "translation": "The boy '''is hiding''' the toy."
          },
          {
            "sentence": "Сабыим джэгуалъэр '''егъэбылъышъурэп'''",
            "translation": "The child '''can't hide''' the toy."
          },
          {
            "sentence": "Джэгуалъэр '''гъэбылъыгъ'''",
            "translation": "The toy '''is hidden'''."
          },
          {
            "sentence": "'''Зыгъэбылъ''' псынк1эу",
            "translation": "'''Hide yourself''' fast."
          },
          {
            "sentence": "К1алэхэмэ '''загъэбылъыгъ'''",
            "translation": "The boys '''hide themselves'''."
          },
          {
            "sentence": "'''Зышъугъэбылъ'''",
            "translation": "'''Hide yourselves'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁabəɬən"
  },
  {
    "word": "хъожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to exchange",
        "examples": []
      }
    ],

    "cognate": "хъуэжэн",
    "type": "verb",
    "ipa": "χʷaʑən"
  },
  {
    "word": "хъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "male",
        "examples": []
      },
      {
        "meaning": "itch",
        "examples": [
          {
            "sentence": "'''Хъум''' егъал1э",
            "translation": "'''The itch''' is killing him."
          }
        ]
      }
    ],
    "synonyms": [
      "хъупц1",
      "шъохъу-т1ыхъу"
    ],
    "type": "noun",
    "ipa": "χʷə"
  },
  {
    "word": "изы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "full",
        "examples": [
          {
            "sentence": "матэр '''изы'''",
            "translation": "The basket is '''full'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jəzə"
  },
  {
    "word": "шъоны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drink",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷanə"
  },
  {
    "word": "зуагъуэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "star",
        "examples": []
      }
    ],
    "synonyms": [
      "жъуагъо"
    ],
    "cognate": "вагъуэ",
    "type": "noun",
    "ipa": "zʷaːʁʷa"
  },
  {
    "word": "псэлъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk",
        "examples": [
          {
            "sentence": "К1алэр '''мэпсалъэ'''",
            "translation": "The boy '''is talking'''."
          },
          {
            "sentence": "Адыгэбзэк1э '''опсалъа?'''",
            "translation": "'''Do you speak''' Circassian?"
          },
          {
            "sentence": "Ары Адыгэбзэк1э '''сэпсалъэ'''",
            "translation": "Indeed, i am speaking Circassian."
          },
          {
            "sentence": "К1алэм '''сыдэпсалъэ'''",
            "translation": "'''I am talking with''' the boy."
          },
          {
            "sentence": "Телефонымк1э '''сыпсалъэу укъэсдэмыпсалъ'''",
            "translation": "'''While I am talking''' in the phone, '''don't talk with me'''."
          },
          {
            "sentence": "К1алэр телефонымк1э '''мэпсалъэ''' непенэу",
            "translation": "The boy '''is talking''' in the phone the whole day."
          },
          {
            "sentence": "'''Усдэпсалъэмэ''' синэ къыч1аплъ",
            "translation": "'''When I am talking to you''' look into my eye."
          },
          {
            "sentence": "К1алэр мэук1ытэу пшъашъэм '''дэпсалъэшъурэп'''",
            "translation": "The boy while being shy '''can't talk''' to the girl."
          },
          {
            "sentence": "Умыук1ытэу '''къэсдэпсалъ'''",
            "translation": "'''Talk to me''' while not being shy."
          },
          {
            "sentence": "К1алэм '''мэпсалъэнэ''' ик1ас",
            "translation": "The boy loves '''to talk'''."
          }
        ]
      }
    ],
    "synonyms": [
      "мэгущы1эн"
    ],
    "type": "verb",
    "ipa": "psaɬan"
  },
  {
    "word": "шъхьэгъусэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spouse, marriage partner",
        "examples": []
      },
      {
        "meaning": "(dated) wife",
        "examples": [],
        "tags": [
          "archaic"
        ]
      }
    ],

    "cognate": "щхьэгъусэ",
    "type": "noun",
    "ipa": "ʂħaʁʷəsa"
  },
  {
    "word": "мэпщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be defeated",
        "examples": [
          {
            "sentence": "к1алэр '''мэпщэт'''",
            "translation": "The boy '''will get defeated'''."
          },
          {
            "sentence": "'''cэпщэ'''",
            "translation": "'''I am getting defeated'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mapɕan"
  },
  {
    "word": "гъэпщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to win against",
        "examples": [
          {
            "sentence": "К1алэм '''егъапщэ'''",
            "translation": "The boy '''is winning'''."
          },
          {
            "sentence": "'''Сыгъэпщэщт'''",
            "translation": "'''i will win'''."
          },
          {
            "sentence": "К1алэхэмэ '''укъагъэпщэщт'''",
            "translation": "The boys '''will defeat you'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тек1он",
      "гъэмартын"
    ],
    "type": "verb",
    "ipa": "ʁapɕan"
  },
  {
    "word": "пхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to extend unexpectedly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pχʷan"
  },
  {
    "word": "пцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fish",
        "examples": []
      }
    ],

    "cognate": "бдзэ",
    "type": "noun",
    "ipa": "pt͡sa"
  },
  {
    "word": "пс1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lie",
        "examples": []
      }
    ],

    "cognate": "пц1ы",
    "type": "noun",
    "ipa": "pt͡sˤʼə"
  },
  {
    "word": "къарэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crane",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːraw"
  },
  {
    "word": "1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lift, to raise",
        "examples": [
          {
            "sentence": "Л1ым ык1алэ лъагэу '''е1эты'''",
            "translation": "The man '''lifts''' his child high."
          },
          {
            "sentence": "Тэ адыгэхэмк1э '''зыкъэт1этыжьын''' фай",
            "translation": "We Circassians need '''to lift ourselves''' up."
          },
          {
            "sentence": "'''Къэ1эт''' 1анэр",
            "translation": "'''Lift''' the table."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect slang vulgar) to have sexual intercourse; to fuck",
        "examples": [],
        "tags": [
          "Kfar Kama dialect",
          "slang",
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔaːtən"
  },
  {
    "word": "хьакъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bark",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ħaːqʷən"
  },
  {
    "word": "пц1эун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to howl",
        "examples": []
      }
    ],

    "cognate": "къугъын",
    "type": "verb",
    "ipa": "pt͡sʼawəm"
  },
  {
    "word": "цэкъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bite",
        "examples": [
          {
            "sentence": "бэнанэм '''ецакъ'''",
            "translation": "'''Bite''' the banana."
          },
          {
            "sentence": "хьэр '''мэцакъэ'''",
            "translation": "The dog '''is biting'''."
          },
          {
            "sentence": "хьэм уеблагъэмэ '''къоцэкъэт'''",
            "translation": "If you get close to the dog '''he will bite you'''."
          },
          {
            "sentence": "мыжъом '''уецакъэмэ''' уицэхэ 1уигъэтэкъут",
            "translation": "'''If you bite''' the rock, it will break your tooth."
          }
        ]
      }
    ],

    "cognate": "дзэкъэн",
    "type": "verb",
    "ipa": "t͡saqan"
  },
  {
    "word": "к1ыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "long",
        "examples": []
      },
      {
        "meaning": "tall",
        "examples": []
      }
    ],

    "cognate": "к1ыхь",
    "type": "adj",
    "ipa": "t͡ʃʼəħa"
  },
  {
    "word": "1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to say something",
        "examples": []
      },
      {
        "meaning": "to say something to someone",
        "examples": []
      },
      {
        "meaning": "to attempt, to try",
        "examples": []
      }
    ],

    "cognate": "жы1эн",
    "type": "verb",
    "ipa": "ʔʷan"
  },
  {
    "word": "1эф1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweet",
        "examples": []
      },
      {
        "meaning": "tasty",
        "examples": []
      }
    ],
    "synonyms": [
      "1эш1у"
    ],
    "type": "adj",
    "ipa": "ʔafʼə"
  },
  {
    "word": "къуащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lurch",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːɕa"
  },
  {
    "word": "есын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swim",
        "examples": [
          {
            "sentence": "к1алэр хым '''есы'''",
            "translation": "The boy '''is swimming''' in the sea."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "jasən"
  },
  {
    "word": "суэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "honey",
        "examples": []
      }
    ],
    "synonyms": [
      "шъоу"
    ],
    "cognate": "фо",
    "type": "noun",
    "ipa": "sʷaw"
  },
  {
    "word": "суыз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wife",
        "examples": []
      },
      {
        "meaning": "woman",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуз"
    ],
    "cognate": "фыз",
    "type": "noun",
    "ipa": "sʷəz"
  },
  {
    "word": "сахъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hoar-frost",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːχa"
  },
  {
    "word": "щхъырыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gooseberry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕχərəb"
  },
  {
    "word": "тыркъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wound",
        "examples": []
      },
      {
        "meaning": "scar",
        "examples": []
      }
    ],

    "cognate": "дыркъуэ",
    "type": "noun",
    "ipa": "tərqʷa"
  },
  {
    "word": "такъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stump",
        "examples": []
      },
      {
        "meaning": "block",
        "examples": []
      }
    ],

    "cognate": "дакъэ",
    "type": "noun",
    "ipa": "taːqa"
  },
  {
    "word": "тхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gray hair; dun",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tχʷa"
  },
  {
    "word": "тфэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beech",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tfajə"
  },
  {
    "word": "тхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to clean (out, up)",
        "examples": []
      },
      {
        "meaning": "to shovel out, to rake out, to remove by shoveling or raking",
        "examples": [
          {
            "sentence": "'''тхъу''' ят1эр",
            "translation": "'''Shovel out''' the dirt."
          },
          {
            "sentence": "к1алэм ч1ыгор '''етхъу'''",
            "translation": "The boy '''is shoveling out''' the ground."
          }
        ]
      }
    ],

    "cognate": "къитхъун",
    "type": "verb",
    "ipa": "tχʷən"
  },
  {
    "word": "тыгъурыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eagle-owl",
        "examples": []
      }
    ],

    "cognate": "дыгъурыгъу",
    "type": "noun",
    "ipa": "təʁʷrəʁʷə"
  },
  {
    "word": "т1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dig",
        "examples": [
          {
            "sentence": "Къазгъырымк1э '''мат1эх'''",
            "translation": "They are '''digging''' with the shovel."
          }
        ]
      }
    ],
    "synonyms": [
      "тхъун"
    ],
    "type": "verb",
    "ipa": "tʼən"
  },
  {
    "word": "ужы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weasel",
        "examples": []
      },
      {
        "meaning": "track",
        "examples": [
          {
            "sentence": "тыгъужъым ужхэр",
            "translation": "wolf tracks"
          }
        ]
      },
      {
        "meaning": "footprint, footstep",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэуж",
      "----"
    ],
    "cognate": "ужьэ",
    "type": "noun",
    "ipa": "wʒə"
  },
  {
    "word": "нэк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "empty",
        "examples": [
          {
            "sentence": "матэр '''нэк1ы'''",
            "translation": "The basked '''is empty'''."
          },
          {
            "sentence": "Хьалыгъор '''нэкIы'''",
            "translation": "The bread '''is empty'''."
          },
          {
            "sentence": "Т1ысып1э '''нэк1хэр''' джыри щы1а?",
            "translation": "Are seats still available? - Are there still '''empty seats'''?."
          }
        ]
      }
    ],

    "cognate": "нэщ1",
    "type": "adj",
    "ipa": "nat͡ʃʼə"
  },
  {
    "word": "хэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "who",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "xat"
  },
  {
    "word": "щэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to buy",
        "examples": [
          {
            "sentence": "к1алэм бэнанэ '''ещэфы'''",
            "translation": "The boy '''is buying''' a banana."
          },
          {
            "sentence": "бэ зиуасэр '''сщэфтэп'''",
            "translation": "'''I don't buy''' what costs a lot."
          },
          {
            "sentence": "'''къэсфэщэф''' мые",
            "translation": "'''Buy for me''' an apple."
          }
        ]
      }
    ],

    "cognate": "щэхун",
    "type": "verb",
    "ipa": "ɕafən"
  },
  {
    "word": "хъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tie up, to bind",
        "examples": []
      },
      {
        "meaning": "to knit",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "χan"
  },
  {
    "word": "хьамлыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "worm",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэблыу"
    ],
    "cognate": "хьэмбылыу",
    "type": "noun",
    "ipa": "ħamləw"
  },
  {
    "word": "ет1эхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch an itchy place",
        "examples": [
          {
            "sentence": "Сныбэ мэхъурэм '''сет1эхъу'''",
            "translation": "'''I am scratching''' my itchy stomach"
          },
          {
            "sentence": "'''Уемыт1эхъу''' ащ хъуми",
            "translation": "'''Don't scratch''' that even if it's itchy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jatʼaχʷən"
  },
  {
    "word": "шъутыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(slang) sexy (Of a person, very physically or sexually attractive)",
        "examples": [
          {
            "sentence": "сигъусэ пшъашъэ '''шъутыжъ'''",
            "translation": "My girlfriend is '''hot'''."
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʃʷətəʐ"
  },
  {
    "word": "дыщыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stew",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dəɕəps"
  },
  {
    "word": "гык1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wash (wash clothes), to launder",
        "examples": []
      }
    ],
    "synonyms": [
      "гык1ын"
    ],
    "cognate": "жьыщ1эн",
    "type": "verb",
    "ipa": "ɣət͡ʃʼan"
  },
  {
    "word": "гык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wash (wash clothes), to launder",
        "examples": [
          {
            "sentence": "Джанэхэр бзылъфыгъэм '''егык1ых'''",
            "translation": "The woman '''is washing''' the clothes."
          },
          {
            "sentence": "Джанэр '''гык1'''",
            "translation": "'''Wash''' the shirt."
          }
        ]
      }
    ],
    "synonyms": [
      "гык1эн"
    ],
    "cognate": "жьыщ1эн",
    "type": "verb",
    "ipa": "ɣət͡ʃən"
  },
  {
    "word": "к1ако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "short",
        "examples": [
          {
            "sentence": "к1алэ '''к1ако'''",
            "translation": "'''Short''' boy."
          },
          {
            "sentence": "мы пхъэр '''к1экуащэ'''",
            "translation": "This wood '''is too short'''."
          },
          {
            "sentence": "ТIэкIу нахь '''кIакоу''', хъущтмэ",
            "translation": "A bit '''shorter''', please."
          }
        ]
      },
      {
        "meaning": "jacket",
        "examples": []
      }
    ],
    "synonyms": [
      "к1ьако (short)",
      "сако (jacket)"
    ],
    "cognate": "к1агуэ (short); цакуэ (jacket)",
    "type": "adj",
    "ipa": "t͡ʃʼaːkʷa"
  },
  {
    "word": "фыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mallet",
        "examples": []
      }
    ],

    "cognate": "хугу",
    "type": "noun",
    "ipa": "fəɡʷ"
  },
  {
    "word": "хьэшхъурэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mad",
        "examples": []
      }
    ],

    "cognate": "хьэщхьэры1уэ",
    "type": "adj",
    "ipa": "ħaʃχʷəraʔʷ"
  },
  {
    "word": "гъэцэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to carry out, to fulfill",
        "examples": [
          {
            "sentence": "К1алэм '''егъэцак1э''' къэс1орэ",
            "translation": "The boy '''is doing''' what I am saying."
          },
          {
            "sentence": "Сымыджэгугъэу сиянэ 1офэу къыстелъхьагъэхэр '''сыгъэцэк1эн''' фаех",
            "translation": "Before I play, I must '''do''' the tasks my mother gave me."
          },
          {
            "sentence": "Тхьэматэм е1орэ '''зимгъэцак1эрэ''' хьапсым ч1адзэщт",
            "translation": "A person that '''doesn't obey''' as the commands of the captain will be thrown to jail."
          },
          {
            "sentence": "Уиянэ е1орэ '''угъэцэк1эн''' фай",
            "translation": "You need '''to do''' what your mother tells you."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэсэк1эн"
    ],
    "cognate": "гъэзэщ1эн",
    "type": "verb",
    "ipa": "ʁat͡sat͡ʃan"
  },
  {
    "word": "гъэсэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэцэк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁasat͡ʃʼan"
  },
  {
    "word": "джэнчы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "haricot",
        "examples": []
      },
      {
        "meaning": "beans",
        "examples": []
      }
    ],
    "synonyms": [
      "гьэч",
      "гьэш"
    ],
    "cognate": "джэш",
    "type": "noun",
    "ipa": "d͡ʒant͡ʃə"
  },
  {
    "word": "къонц1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "inclined",
        "examples": []
      },
      {
        "meaning": "crooked",
        "examples": []
      }
    ],

    "cognate": "къуаншэ",
    "type": "adj",
    "ipa": "qʷant͡sʼaw"
  },
  {
    "word": "стыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burned (usually food)",
        "examples": []
      },
      {
        "meaning": "hot (food and water)",
        "examples": []
      },
      {
        "meaning": "spicy",
        "examples": []
      }
    ],

    "cognate": "пщтыр",
    "type": "adj",
    "ipa": "stər"
  },
  {
    "word": "ш1ык1аш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good manners",
        "examples": []
      }
    ],

    "cognate": "щ1ык1аф1э",
    "type": "noun",
    "ipa": "ʃʼət͡ʃʼaːʃʷʼ"
  },
  {
    "word": "ш1ык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "manner, method",
        "examples": [
          {
            "sentence": "'''Ш1ык1э''' нахь дэгъу уи1а?",
            "translation": "Do you have a better '''method?'''"
          },
          {
            "sentence": "1анэр '''зэрэпш1ык1э''' дэгъоп",
            "translation": "'''The manner you are using''' for the table is not good."
          }
        ]
      },
      {
        "meaning": "the way/manner/method something is structured",
        "examples": [
          {
            "sentence": "Унэм '''иш1ык1э''' пытэу шыт",
            "translation": "'''The way''' the house '''is structured''' is hard."
          },
          {
            "sentence": "1анэм '''иш1ык1э''' айэ",
            "translation": "'''The way''' the table '''is structured''' is ugly."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ык1ьэ"
    ],
    "cognate": "щ1ык1э",
    "type": "noun",
    "ipa": "ʃʼət͡ʃʼa"
  },
  {
    "word": "ш1уш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noble",
        "examples": []
      },
      {
        "meaning": "benefactor",
        "examples": []
      }
    ],

    "cognate": "ф1ыщ1э",
    "type": "noun",
    "ipa": "ʃʷʼəʃʼa"
  },
  {
    "word": "гужъуак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Arm pit",
        "examples": []
      }
    ],

    "cognate": "гуф1ак1э",
    "type": "noun",
    "ipa": "ɡʷəʒʷaːt͡ʃa"
  },
  {
    "word": "ш1эныгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "knowledge",
        "examples": []
      }
    ],

    "cognate": "щ1эныгъэ",
    "type": "noun",
    "ipa": "ʃʼanəʁa"
  },
  {
    "word": "гъэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэджэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁad͡ʒan"
  },
  {
    "word": "гъэгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэджэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaɡʲan"
  },
  {
    "word": "мэгущы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гущы1эн\"",
        "examples": []
      }
    ],

    "cognate": "мэпсэлъэн",
    "type": "verb",
    "ipa": "maɡʷɕəʔan"
  },
  {
    "word": "фылым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "film",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "fələm"
  },
  {
    "word": "яжьашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ежьашъо\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jaːʑaːʂʷ"
  },
  {
    "word": "пызы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a tree or plant that is full of fruits and vegetables",
        "examples": [
          {
            "sentence": "чъыгыр '''пызы'''",
            "translation": "The tree '''is full of fruits'''."
          },
          {
            "sentence": "чъыгыр '''пызыу''' мэхъут гъэмафэм",
            "translation": "The tree will become '''full of fruits''' in the summer."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "pəzə"
  },
  {
    "word": "блэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "блэк1ын"
    ],
    "type": "verb",
    "ipa": "blakʲʼən"
  },
  {
    "word": "Иусыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Josef (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "jəwsəf"
  },
  {
    "word": "Ибрахьим",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Abraham (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "jəbraːħim"
  },
  {
    "word": "Якъуб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Jacob (name)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaːqʷəb"
  },
  {
    "word": "Адэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Adam (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ʔaːdam"
  },
  {
    "word": "Хьао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Eve (name)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːwa"
  },
  {
    "word": "Мусэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Moses (name)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məwsa"
  },
  {
    "word": "Исхьэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Isaac (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "jəsħaq"
  },
  {
    "word": "Нухь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Noah (name)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəwħ"
  },
  {
    "word": "къаум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a few, several, some",
        "examples": [
          {
            "sentence": "Л1ы '''къаумэ''' къызэ1ук1агъ",
            "translation": "'''Several''' men met."
          },
          {
            "sentence": "Тхыдэжъ '''къаумэ''' къысфэ1уат",
            "translation": "Tell me '''some''' stories."
          }
        ]
      }
    ],
    "synonyms": [
      "заулэ"
    ],
    "type": "pron",
    "ipa": "qaːwəm"
  },
  {
    "word": "хьэубыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "police; cop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħawbət"
  },
  {
    "word": "хьэят",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corridor, passage, passageway",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħajaːt"
  },
  {
    "word": "шэмбэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Saturday",
        "examples": []
      }
    ],

    "cognate": "щэбэт",
    "type": "noun",
    "ipa": "ʃambat"
  },
  {
    "word": "гъэтхапэмаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "March",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁatxaːpamaːz"
  },
  {
    "word": "жъоныгъуак1маз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "May",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒʷanəʁʷaːt͡ʃʼmaːz"
  },
  {
    "word": "шышъхьэ1умаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "August",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəʂħaʔʷəmaːz"
  },
  {
    "word": "лъытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to count",
        "examples": [
          {
            "sentence": "Сэ '''къэсэлъытэ'''",
            "translation": "I '''count'''."
          },
          {
            "sentence": "Пш1ым нэс '''лъытэн'''",
            "translation": "'''To count''' to ten."
          },
          {
            "sentence": "Сэ лъык1отагъэу '''къэсэлъытэ'''",
            "translation": "I '''count''' further."
          },
          {
            "sentence": "Ащ ахъщэ '''къелъытэ'''",
            "translation": "(s)he '''counts''' money."
          }
        ]
      },
      {
        "meaning": "to respect, to honor",
        "examples": [
          {
            "sentence": "К1алэм иянэ '''елъытэ'''",
            "translation": "The boy '''respects''' his mother."
          },
          {
            "sentence": "Ахэм к1элэеджэк1о дэгъоу '''алъытэ'''",
            "translation": "They '''honor''' the teacher well."
          },
          {
            "sentence": "Тэ нахыжъхэр '''тэлъытэ'''",
            "translation": "We '''respect''' the elders."
          }
        ]
      }
    ],
    "synonyms": [
      "пчъын"
    ],
    "type": "verb",
    "ipa": "ɬətan"
  },
  {
    "word": "гулъытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to understand (Shapsug and Natukhai dialects) (See гуры1он)",
        "examples": [
          {
            "sentence": "Сэ '''гулъэсэтэ'''",
            "translation": "I '''understand'''."
          },
          {
            "sentence": "Сэ '''гулъыстэрэп''' тызрагъаджэрэ",
            "translation": "I '''don't understand''' what they are teaching us."
          },
          {
            "sentence": "О '''гулъыптагъэ''' къас1орэ?",
            "translation": "Did you '''understand''' what I said."
          },
          {
            "sentence": "К1элэцык1ум еп1орэ '''гулъитэтэп'''",
            "translation": "The little boy '''won't understand''' what you are telling him."
          },
          {
            "sentence": "Л1ым къари1уагъэ '''лъатагъ'''",
            "translation": "'''They understood''' what the man told them."
          }
        ]
      },
      {
        "meaning": "to notice",
        "examples": [
          {
            "sentence": "'''Гулъэсэтэ''' сишхын узэрэхэ1абэрэ",
            "translation": "'''I notice''' how you are taking my food."
          },
          {
            "sentence": "'''Гулъышъутагъэ''' чэтыор зэрэмэк1одыгъэ?",
            "translation": "Did you (plural) '''notinec''' that the cat gone (Disappear)."
          },
          {
            "sentence": "К1алэм '''гулъитагъ''' иунэ зэрэихьагъхэ",
            "translation": "The boy '''noticed''' they entered into house - The boy '''noticed''' they broke into his house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəɬətan"
  },
  {
    "word": "хъулъфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "man",
        "examples": []
      }
    ],

    "cognate": "хъулъхугъэ",
    "type": "noun",
    "ipa": "χʷəɬfəʁa"
  },
  {
    "word": "къулъфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъулъфыгъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəɬfəʁa"
  },
  {
    "word": "1эхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bracelet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaχʷ"
  },
  {
    "word": "жъогъо ш1эныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "astronomy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʐʷaʁʷa ʃˈanəʁ"
  },
  {
    "word": "жъокужъоустхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Red ember",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "гъоплъышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brass",
        "examples": []
      },
      {
        "meaning": "color of copper",
        "examples": []
      }
    ],
    "synonyms": [
      "гъоплъашъо"
    ],
    "type": "adj",
    "ipa": "ʁʷapɬəʂʷa"
  },
  {
    "word": "къашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "green",
        "examples": [
          {
            "sentence": "Уцыр '''къашхъо'''",
            "translation": "The grass is '''green'''."
          },
          {
            "sentence": "'''къашхъок1э''' дэпкъыр лэ",
            "translation": "Color the wall '''in green'''."
          }
        ]
      }
    ],
    "synonyms": [
      "уцышъо"
    ],
    "type": "adj",
    "ipa": "qaːʃχʷa"
  },
  {
    "word": "ет1агъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brown",
        "examples": [
          {
            "sentence": "Ч1ыгур '''ет1агъо'''",
            "translation": "The earth is '''brown'''."
          },
          {
            "sentence": "Ч1ыгу '''ет1агъор''' дахэ",
            "translation": "The '''brown''' earth is pretty."
          },
          {
            "sentence": "'''ет1агъорк1э''' дэпкъыр лэ",
            "translation": "Color the wall '''in brown'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къэхьуашъо"
    ],
    "type": "adj",
    "ipa": "jatʼaːʁʷa"
  },
  {
    "word": "къэхьо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэхьыо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaħwa"
  },
  {
    "word": "ежьашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grey",
        "examples": [
          {
            "sentence": "Ошъуапщэр '''ежьашъо'''",
            "translation": "The cloud is '''grey'''."
          },
          {
            "sentence": "Ошъуапщ '''ежьашъор''' дахэ",
            "translation": "The '''grey''' cloud is pretty."
          },
          {
            "sentence": "'''ежьашъок1э''' дэпкъыр лэ",
            "translation": "Color the wall '''in grey'''."
          }
        ]
      }
    ],
    "synonyms": [
      "яжьашъу"
    ],
    "type": "adj",
    "ipa": "jaʑaːʂʷa"
  },
  {
    "word": "щэрэхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wheel",
        "examples": [
          {
            "sentence": "Машинэр ежьэмэ щэрэхъхэр '''чэрэгъун''' фае",
            "translation": "When the car starts, the wheels must '''whirl'''."
          }
        ]
      }
    ],
    "synonyms": [
      "кущэрэхъ"
    ],
    "cognate": "шэрэхъ",
    "type": "noun",
    "ipa": "ɕaraχ"
  },
  {
    "word": "хъашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tire",
        "examples": []
      }
    ],
    "synonyms": [
      "хьашъо"
    ],
    "type": "adj",
    "ipa": "χaːʃʷa"
  },
  {
    "word": "хыкъумэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lake",
        "examples": []
      }
    ],
    "synonyms": [
      "псыхъурай",
      "хыкум"
    ],
    "cognate": "гуэл",
    "type": "noun",
    "ipa": "xəqʷəma"
  },
  {
    "word": "чъыгхатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garden (Usually one with tress and plants)",
        "examples": [
          {
            "sentence": "Мо '''чъыгхатэр''' сыгу рехьы",
            "translation": "I like that '''garden'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂəɣxaːta"
  },
  {
    "word": "пщэрыхьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kitchen",
        "examples": [
          {
            "sentence": "О '''пщэрыхьап1э''' кIэу уиIа?",
            "translation": "Do you have a new kitchen?"
          },
          {
            "sentence": "Мыщ дэжьым '''пщэрыхьап1эр''' ыкIи гъэпскIып1эр щыIэх",
            "translation": "The '''kitchen''' and bathroom are here."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pɕarəħaːpʼa"
  },
  {
    "word": "жьышъаб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breeze",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʂaːb"
  },
  {
    "word": "жьышъхьал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "windmill",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəʂħaːl"
  },
  {
    "word": "жэхэлъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collide with",
        "examples": []
      },
      {
        "meaning": "to jump in the face of",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxaɬadan"
  },
  {
    "word": "жэхэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump in the face of",
        "examples": []
      },
      {
        "meaning": "to examine a face",
        "examples": []
      },
      {
        "meaning": "to scrutinize",
        "examples": []
      },
      {
        "meaning": "to jump on someone (for assault)",
        "examples": [
          {
            "sentence": "Аслъанэ къачъи пчэным '''жэхэпк1агъ'''",
            "translation": "The lion came running and '''jumped''' on the goat"
          },
          {
            "sentence": "К1алэр сымылъэгъоу '''къэсжэхэпк1агъ'''",
            "translation": "The boy '''jumped''' on me without me seeing him."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʒaxapkʲan"
  },
  {
    "word": "жэхэплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to glare at a face",
        "examples": []
      },
      {
        "meaning": "to google at a face",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxapɬan"
  },
  {
    "word": "жэхэпхъэнк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sweep in front of a face",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxapχant͡ʃan"
  },
  {
    "word": "жэхэтхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch a face",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxatχʷan"
  },
  {
    "word": "жэхэупц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stain a face",
        "examples": []
      },
      {
        "meaning": "to splash forcefully onto someone or something",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxawpt͡sʼan"
  },
  {
    "word": "жьыщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very early",
        "examples": []
      },
      {
        "meaning": "too early",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʑəɕa"
  },
  {
    "word": "жьэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Before season",
        "examples": []
      },
      {
        "meaning": "early",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʑaw"
  },
  {
    "word": "жэ1онт1агъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bent",
        "examples": []
      },
      {
        "meaning": "crooked",
        "examples": []
      },
      {
        "meaning": "curved",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒaʔʷantʼaːʁ"
  },
  {
    "word": "жэ1улэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foolish",
        "examples": []
      },
      {
        "meaning": "silly",
        "examples": []
      },
      {
        "meaning": "stupid",
        "examples": []
      },
      {
        "meaning": "Loose jaw",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒaʔʷəlal"
  },
  {
    "word": "1улэлын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hang and bounce (freely)",
        "examples": [
          {
            "sentence": "хьэм ибзэгу '''1улэлы'''",
            "translation": "The dog's tongue '''is hanging freely'''."
          },
          {
            "sentence": "1упс '''къуп1олэлы'''",
            "translation": "'''Your''' saliva '''is dropping from you (freely)'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəlalən"
  },
  {
    "word": "гъопэк1ьэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъопэк1эхь\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъопэк1эхь"
    ],
    "type": "noun",
    "ipa": "ʁʷaːpakʲʼaħ"
  },
  {
    "word": "1эпэгъуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sleeve, The part of the shirt that covers the arm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔapaʁʷaːpa"
  },
  {
    "word": "жэ1улэлыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foolishness",
        "examples": []
      },
      {
        "meaning": "insanity",
        "examples": []
      },
      {
        "meaning": "lunacy",
        "examples": []
      },
      {
        "meaning": "madness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaʔʷəlaləʁ"
  },
  {
    "word": "к1оч1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strength",
        "examples": [
          {
            "sentence": "Л1ым '''к1оч1агъэ''' бэ хэлъ",
            "translation": "The man have a lot '''strength'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "kʷʼat͡ʃʼaːʁa"
  },
  {
    "word": "жэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaʁʷ"
  },
  {
    "word": "жэгъуб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dewlap",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaʁʷəb"
  },
  {
    "word": "жэдэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall from the mouth",
        "examples": []
      },
      {
        "meaning": "to utter",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadazən"
  },
  {
    "word": "жэдэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour into the mouth",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadat͡ʃan"
  },
  {
    "word": "жэдэкон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wedge into the mouth",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadakʷan"
  },
  {
    "word": "жэдэлъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bring out of the mouth,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadaxən"
  },
  {
    "word": "жэдэлъхьажьын",
    "lang": "Adyghe",
    "definitions": [],
    "type": "verb",
    "ipa": "ʒadaɬħaːʑən"
  },
  {
    "word": "жэдэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to beat on the mouth",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadawan"
  },
  {
    "word": "жэдэплъыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to examine the mouth",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadapɬəħaːn"
  },
  {
    "word": "жэдэтэкъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw inside the mouth",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadazən"
  },
  {
    "word": "жэдэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chew",
        "examples": []
      },
      {
        "meaning": "to fall into the mouth",
        "examples": []
      },
      {
        "meaning": "to masticate",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒadafan"
  },
  {
    "word": "упэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chew",
        "examples": [
          {
            "sentence": "Дэшхынэр '''сэупэш1ы'''",
            "translation": "'''I am chewing''' the food."
          },
          {
            "sentence": "Шэкэрхафэ дахэу '''упыш1'''",
            "translation": "'''Chew''' the candy with good manners."
          }
        ]
      }
    ],
    "synonyms": [
      "уцэ1ун",
      "гъэунэшк1ун"
    ],
    "type": "verb",
    "ipa": "wəpaʃʼən"
  },
  {
    "word": "жэк1агъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person with gray beard",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒat͡ʃaːʁʷ"
  },
  {
    "word": "жэк1эпэпкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэк1апэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "жэк1ьапэ",
      "жэк1апэ"
    ],
    "type": "noun",
    "ipa": "ʒatʃʼapapq"
  },
  {
    "word": "жэк1эпэсыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person that have a trembling bread",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒat͡ʃapasəs"
  },
  {
    "word": "жэк1эупс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barber",
        "examples": []
      },
      {
        "meaning": "hairdresser",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒat͡ʃawps"
  },
  {
    "word": "жэк1эупсып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barbershop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒat͡ʃawpsəpʼ"
  },
  {
    "word": "щап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaːpʼa"
  },
  {
    "word": "жэк1эф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person with white beard",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒat͡ʃaf"
  },
  {
    "word": "жэк1эшху",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "big beard",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒat͡ʃaʃxʷ"
  },
  {
    "word": "жэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to belch",
        "examples": []
      },
      {
        "meaning": "to burp",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaqʷən"
  },
  {
    "word": "жэлъак1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Ski season",
        "examples": []
      },
      {
        "meaning": "Ski trace",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaɬaːkʷʼ"
  },
  {
    "word": "жэлый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fishhook",
        "examples": []
      }
    ],
    "synonyms": [
      "пцэкъэнтф"
    ],
    "type": "noun",
    "ipa": "ʒaləj"
  },
  {
    "word": "пцэкъэнтф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fishhook",
        "examples": []
      }
    ],
    "synonyms": [
      "жэлый"
    ],
    "type": "noun",
    "ipa": "pt͡saqantf"
  },
  {
    "word": "к1эрэхъоцык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handgun, pistol",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaraχʷat͡səkʷʼ"
  },
  {
    "word": "жэмай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Blessed talker",
        "examples": []
      },
      {
        "meaning": "Good talker",
        "examples": []
      },
      {
        "meaning": "silent",
        "examples": []
      },
      {
        "meaning": "taciturn",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒamaːj"
  },
  {
    "word": "жэмачыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "talkative",
        "examples": []
      },
      {
        "meaning": "eloquent",
        "examples": []
      },
      {
        "meaning": "talker",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1ал",
      "1оржъор",
      "гущы1эрые",
      "жэмы1ан",
      "шъхьарыгущы1"
    ],
    "type": "adj",
    "ipa": "ʒamaːt͡ʃəw"
  },
  {
    "word": "гущы1эгъал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "talkative",
        "examples": []
      },
      {
        "meaning": "eloquent",
        "examples": []
      },
      {
        "meaning": "talking in an ornate way",
        "examples": []
      }
    ],
    "synonyms": [
      "бзаш1у",
      "жэмачыу",
      "жаш1у"
    ],
    "type": "adj",
    "ipa": "ɡʷəɕəʔaʁaːɮ"
  },
  {
    "word": "жэмы1ан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "talkative",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1ал",
      "1оржъор",
      "гущы1эрые",
      "жэмачыу",
      "шъхьарыгущы1"
    ],
    "type": "adj",
    "ipa": "ʒaməʔaːn"
  },
  {
    "word": "жэпкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "palate",
        "examples": []
      },
      {
        "meaning": "lower jaw",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒapq"
  },
  {
    "word": "жэпхъэмыбгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jaw",
        "examples": []
      },
      {
        "meaning": "beard",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒapχaməbʁʷ"
  },
  {
    "word": "жэры1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "verbal",
        "examples": []
      },
      {
        "meaning": "oral",
        "examples": []
      },
      {
        "meaning": "spoken",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒarəʔʷ"
  },
  {
    "word": "жэры1уабз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Colloquial language",
        "examples": []
      },
      {
        "meaning": "Prose writing",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒarəʔʷaːbz"
  },
  {
    "word": "жэрыплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stupid",
        "examples": []
      },
      {
        "meaning": "wandering",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒarəpɬ"
  },
  {
    "word": "жэрыплъагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unmarked",
        "examples": []
      },
      {
        "meaning": "unidentified",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒarəpɬaːʁ"
  },
  {
    "word": "жэукъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yawn",
        "examples": []
      },
      {
        "meaning": "stupid person",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒawq"
  },
  {
    "word": "жэукъыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yawner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒawqəʁ"
  },
  {
    "word": "жэ 1ук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to yawn",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒa ʔʷət͡ʃʼən"
  },
  {
    "word": "жэ1ук1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "yawn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaʔʷət͡ʃʼ"
  },
  {
    "word": "жэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "an overly talkative person, chatterbox",
        "examples": []
      },
      {
        "meaning": "rough",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒaχʷ"
  },
  {
    "word": "жэхъуагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rudeness",
        "examples": []
      },
      {
        "meaning": "ugliness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʒaχʷaːʁ"
  },
  {
    "word": "жэхэбэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to attack a face",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxabanən"
  },
  {
    "word": "жэхэзыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wedge",
        "examples": []
      },
      {
        "meaning": "to push",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxazəħaːn"
  },
  {
    "word": "жэхэк1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go ahead",
        "examples": []
      },
      {
        "meaning": "to go forward",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxakʷʼatan"
  },
  {
    "word": "мыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unfortunate, ill-fated, ill-starred",
        "examples": [
          {
            "sentence": "л1ыжъэр '''мыгъо'''",
            "translation": "The old man '''is unfortunate'''."
          },
          {
            "sentence": "а л1ыжъэр зыш1у '''мыгъожъ'''",
            "translation": "That old man '''is''' very '''unfortunate'''."
          }
        ]
      }
    ],
    "synonyms": [
      "насыпынчъэ",
      "тхьамык1эгъо",
      "игъуадж",
      "гъэны"
    ],
    "cognate": "мыгъуэ",
    "type": "adj",
    "ipa": "məʁʷa"
  },
  {
    "word": "гъопэк1аку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "short-sleeved shirt",
        "examples": [
          {
            "sentence": "к1алэм '''гъопэк1аку''' шэгъ",
            "translation": "The boy is wearing a '''short-sleeved shirt'''."
          },
          {
            "sentence": "'''гъопэк1аку''' шылъ",
            "translation": "Wear a '''short-sleeved shirt'''."
          },
          {
            "sentence": "гъопэк1эхьэр гъэмафэм фэш, '''гъопэк1ако''' к1ымафэм фэш",
            "translation": "long-sleeved shirt is for the summer and '''short-sleeved''' shirt is for winter."
          }
        ]
      }
    ],
    "synonyms": [
      "гъопэк1ьаку"
    ],
    "type": "noun",
    "ipa": "ʁʷaːpat͡ʃʼaːkʷ"
  },
  {
    "word": "гъопэк1ьаку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъопэк1аку\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъопэк1аку"
    ],
    "type": "noun",
    "ipa": "ʁʷaːpakʲʼaːkʷ"
  },
  {
    "word": "джэущтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "like that",
        "examples": [
          {
            "sentence": "'''джэущтэу''' ушхэн фай",
            "translation": "'''You''' must eat '''like that'''."
          },
          {
            "sentence": "'''джэущтэу''' шIэ",
            "translation": "Do it '''like that'''!."
          },
          {
            "sentence": "'''джэущтэу''' лIым иныжъхэ ригъэджэжьгъэх",
            "translation": "'''Like that''', the man made the giants run."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒawɕtaw"
  },
  {
    "word": "гьэщту",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэущтэу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ɡʲaɕtəw"
  },
  {
    "word": "къэвкъаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Caucasus, Kavkaz",
        "examples": []
      }
    ],
    "synonyms": [
      "къэФкъаз",
      "къэукъаз",
      "кавказ"
    ],
    "type": "noun",
    "ipa": "qavqaːz"
  },
  {
    "word": "дэж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "near",
        "examples": [
          {
            "sentence": "к1алэм '''идэж''' к1о",
            "translation": "Go '''near''' the boy."
          },
          {
            "sentence": "унэм '''идэж''' 1ухь",
            "translation": "Go '''near''' the house."
          },
          {
            "sentence": "'''шъуидэж''' мыр къэсхьыт",
            "translation": "I will bring this '''near you (plural)'''."
          },
          {
            "sentence": "'''садэжы''' 1ук1",
            "translation": "Go away '''from (near) me'''."
          }
        ]
      },
      {
        "meaning": "one's place",
        "examples": [
          {
            "sentence": "к1алэм '''идэж''' к1о",
            "translation": "Go '''to''' the boy's '''place'''."
          },
          {
            "sentence": "'''шъуидэж''' мыр къэсхьыт",
            "translation": "I will bring this '''to your place (plural)'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "daʒ"
  },
  {
    "word": "лъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expensive",
        "examples": [
          {
            "sentence": "К1алэм и1анэ '''лъап1э'''",
            "translation": "The boy's table is '''expensive'''."
          },
          {
            "sentence": "К1алэм иунэ '''лъап1эу''' ишэфэгъ",
            "translation": "The boy bought his house '''expensively'''."
          },
          {
            "sentence": "'''Лъап1эхэр''' умышэфых",
            "translation": "Don't buy the '''expensive''' things."
          }
        ]
      },
      {
        "meaning": "dear",
        "examples": [
          {
            "sentence": "Тихьак1э '''лъап1'''",
            "translation": "Our '''dear''' guest."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬaːpʼa"
  },
  {
    "word": "пыуты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheap",
        "examples": [
          {
            "sentence": "Нахь '''пыутэу''' зыгорэ шъуи1а?",
            "translation": "Do you have anything '''cheaper'''?"
          },
          {
            "sentence": "Мыер '''пыут'''",
            "translation": "The apple '''is cheap'''."
          },
          {
            "sentence": "Мыер нахь '''пыут'''",
            "translation": "The apple '''is cheaper'''."
          }
        ]
      }
    ],

    "cognate": "пуд",
    "type": "adj",
    "ipa": "pəwtə"
  },
  {
    "word": "махэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak, fragile (usually said about objects)",
        "examples": [
          {
            "sentence": "Iудэнэ '''мах'''",
            "translation": "'''Fragile''' thread."
          },
          {
            "sentence": "ШэкI '''мах'''",
            "translation": "'''Fragile''' fabric."
          },
          {
            "sentence": "КIапсэр '''махэ''' хъугъэ",
            "translation": "The rope became '''fragile'''"
          }
        ]
      },
      {
        "meaning": "something not strong, solid durable",
        "examples": []
      }
    ],
    "synonyms": [
      "мыпытэ",
      "къарыунчъ",
      "к1оч1адж",
      "----"
    ],
    "type": "adj",
    "ipa": "maːxa"
  },
  {
    "word": "хъумэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cover",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "χʷəman"
  },
  {
    "word": "пычын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pick (fruit, ticket)",
        "examples": [
          {
            "sentence": "К1алэм мы1эрысыр чъыгым '''къыпречы'''",
            "translation": "The boy '''is picking''' the apple from the tree."
          },
          {
            "sentence": "Пхъэшъхьэмышъхьэхэр чъыгым '''къыпымычых'''",
            "translation": "'''Don't pick''' the fruits from the tree."
          }
        ]
      }
    ],
    "synonyms": [
      "пыкьын"
    ],
    "type": "verb",
    "ipa": "pət͡ʃən"
  },
  {
    "word": "гъэжъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to boil (water)",
        "examples": [
          {
            "sentence": "бзылъфыгъэм псыр '''егъажъо'''",
            "translation": "The woman is '''boiling''' the water."
          },
          {
            "sentence": "'''гъажъу''' мыр",
            "translation": "'''Boil''' this."
          }
        ]
      },
      {
        "meaning": "to plow, to plough",
        "examples": [
          {
            "sentence": "Ч1ыгур '''гъэжъон'''",
            "translation": "'''To plow''' the land."
          }
        ]
      }
    ],

    "cognate": "гъэвэн",
    "type": "verb",
    "ipa": "ʁaʒʷan"
  },
  {
    "word": "джэгун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to play (a game)",
        "examples": [
          {
            "sentence": "К1алэр '''мэджэгу '''",
            "translation": "The boy '''is playing'''."
          },
          {
            "sentence": "К1алэр джэгуалъэм '''реджэгу '''",
            "translation": "The boy '''is playing''' with the toy."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэдэджэгух'''",
            "translation": "The boy and the girl '''are playing together'''."
          },
          {
            "sentence": "К1алэр '''къэсдэджэгу'''",
            "translation": "The boy '''is playing with me'''."
          },
          {
            "sentence": "К1алэр '''джэгунэу''' фаеп",
            "translation": "The boy '''doesn't want to play'''."
          },
          {
            "sentence": "Хатэм '''щыджэгу'''",
            "translation": "'''Play''' in the garden."
          }
        ]
      },
      {
        "meaning": "to play, to act (To act as the indicated role, especially in a performance)",
        "examples": [
          {
            "sentence": "Л1ыр филмымк1э докторэу '''къэджэгу '''",
            "translation": "The man '''is playing''' as a doctor in the film."
          }
        ]
      },
      {
        "meaning": "(rare) to dance in a Djagu (a Circassian dance party)",
        "examples": [
          {
            "sentence": "К1алэр '''мэджэгу '''",
            "translation": "The boy '''dances'''."
          }
        ],
        "tags": [
          "rare"
        ]
      }
    ],
    "synonyms": [
      "къеш1эн",
      "еш1эн",
      "къэшъон",
      "----"
    ],
    "cognate": "уджын",
    "type": "verb",
    "ipa": "d͡ʒaɡʷən"
  },
  {
    "word": "дыджы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bitter",
        "examples": []
      }
    ],

    "cognate": "дыдж",
    "type": "adj",
    "ipa": "dəd͡ʒə"
  },
  {
    "word": "1эзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "same",
        "examples": [
          {
            "sentence": "Мафэкъэс '''1эзэ''' уш1эрэ.",
            "translation": "Everyday you do the '''same''' thing."
          },
          {
            "sentence": "'''1эзымк1э''' тестым ухаукъэ.",
            "translation": "You are mistaking '''with the same thing''' in the test."
          },
          {
            "sentence": "Уатэ '''1эзымк1э''' пхъэм еу.",
            "translation": "Hit the wood '''with the same''' hammer."
          },
          {
            "sentence": "Ныор '''1эз''' унэм ис.",
            "translation": "The old-woman is in the '''same''' house."
          },
          {
            "sentence": "'''1эз''' тучаным ч1эплъэгъот мыехэр.",
            "translation": "You will see in the '''same''' shop the apples."
          },
          {
            "sentence": "Уибзэрэ сибзэрэ '''1эзэ'''.",
            "translation": "Your language and my language '''are the same'''."
          },
          {
            "sentence": "'''1эзэрары''' хым ук1уагъэми я есып1эм ук1уагъэми.",
            "translation": "'''It's the same thing''' if you went to the sea or went to the pool."
          },
          {
            "sentence": "'''1эзэрары''' мэуи уш1эн фае.",
            "translation": "'''It's the same thing''' you need to do here."
          },
          {
            "sentence": "Къап1орэмрэ къас1орэмрэ '''1эзыхэп'''.",
            "translation": "'''It's not the same''' what you say and what I say."
          },
          {
            "sentence": "'''1эзэп''' сэхьат уеджэнэрэ сэхьатит1у уеджэнэрэ.",
            "translation": "'''It's not the same''' to study one hour and to study two hours."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʔaza"
  },
  {
    "word": "нэущымыщк1ь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"неущымык1\"",
        "examples": []
      }
    ],
    "synonyms": [
      "неущымык1"
    ],
    "type": "adv",
    "ipa": "nawɕəməɕkʲʼ"
  },
  {
    "word": "къэк1орэгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэк1орэ гъэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "qakʷʼaraʁa"
  },
  {
    "word": "щэгьэгъож",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэджэгъоуж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaɡʲaʁʷaʒ"
  },
  {
    "word": "щэджэгъоуж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "afternoon",
        "examples": [
          {
            "sentence": "'''щэджэгъоужым''' тк1от йуропа",
            "translation": "'''In the afternoon''', we will go to Europe."
          },
          {
            "sentence": "'''щэджэгъоужым''' томэр къэк1уагъ",
            "translation": "'''In the afternoon''' Tom came."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕad͡ʒaʁʷawʒ"
  },
  {
    "word": "къэк1орэтхьэмафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "next week",
        "examples": [
          {
            "sentence": "'''къэк1орэтхьэмафэм''' тк1от йуропа",
            "translation": "'''Next week''' we will go to Europe."
          },
          {
            "sentence": "'''къэк1орэтхьэмафэм''' пш1ы синыбжьыт",
            "translation": "'''Next week''' I will be ten years old."
          },
          {
            "sentence": "'''къэк1орэтхьэмафэм''' томэр къэк1от",
            "translation": "'''Near week''' Tom will come."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "qakʷʼaratħamaːfa"
  },
  {
    "word": "ипэтхьэмафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "last week",
        "examples": [
          {
            "sentence": "'''ипэтхьэмафэм''' тк1уагъ йуропа",
            "translation": "'''last week''', we went to Europe."
          },
          {
            "sentence": "'''ипэтхьэмафэм''' пш1ы синыбжьэгъ",
            "translation": "'''last week''' I was ten years old."
          },
          {
            "sentence": "'''ипэтхьэмафэм''' томэр къэк1уагъ",
            "translation": "'''last week''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jəpatħamaːfa"
  },
  {
    "word": "шъоуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whistle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷawaːɬa"
  },
  {
    "word": "к1ьэнлъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1энлъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲʼanɬa"
  },
  {
    "word": "к1одын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disappear, to vanish",
        "examples": [
          {
            "sentence": "К1алэр уфае зыхъукIэ '''мэк1оды'''",
            "translation": "The boy '''disappears''' when you need him."
          }
        ]
      },
      {
        "meaning": "to go missing",
        "examples": [
          {
            "sentence": "к1алэр '''к1одыгъэ''', къагъотыжьырэп",
            "translation": "The boy '''is missing''', they can't find him"
          }
        ]
      },
      {
        "meaning": "to get lost",
        "examples": [
          {
            "sentence": "К1алэр мэзым '''хэк1одагъ'''",
            "translation": "The boy '''got lost''' in the forest."
          },
          {
            "sentence": "К1элэц1ык1ум нэ1э тэтымыгъэтымэ '''къэтш1ок1одыщт'''",
            "translation": "If we don't protest the little boy '''he will get lost from us'''."
          },
          {
            "sentence": "Cи къэлэм дахэ '''к1одыгъэ'''",
            "translation": "My beautiful pencil '''got lost'''."
          }
        ]
      }
    ],
    "synonyms": [
      "бзэхын"
    ],
    "cognate": "к1уэдын",
    "type": "verb",
    "ipa": "kʷʼadən"
  },
  {
    "word": "хэк1одэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get lost (in an area)",
        "examples": [
          {
            "sentence": "К1алэр мэзым '''хэк1одэхьыгъ'''",
            "translation": "The boy '''got lost''' in the forest."
          },
          {
            "sentence": "Уинэ1э зтегъэт, мэзым '''ухэк1одэхьыщт'''",
            "translation": "Take care of yourself, '''you will get lost''' in the forest."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xakʷʼadaħən"
  },
  {
    "word": "дэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sew",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dawan"
  },
  {
    "word": "арми",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "even if that, even if so",
        "examples": [
          {
            "sentence": "'''Арми''' л1ым ыш1агъэр, хьапсым ч1агъэт1ысхьэн фэягъэп",
            "translation": "'''Even if that''' what the man did, they shouldn't have put him in jail."
          },
          {
            "sentence": "'''Арми''' п1орэр, шъофым тыхэгъахь",
            "translation": "'''Even if that''' what you say, let us enter the field."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːma"
  },
  {
    "word": "к1эупк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ask",
        "examples": [
          {
            "sentence": "к1алэр пшъашъэм '''еупк1ы''' сыд ыц1э",
            "translation": "The boy '''is asking''' the girl what her name is?"
          },
          {
            "sentence": "к1алэр л1ыхэмэ '''яупк1ы''' рсы фаехэмэ",
            "translation": "The boy is '''asking''' the men of they want water."
          },
          {
            "sentence": "'''к1эупк1''' сыд к1алэм ыц1э",
            "translation": "'''Ask''' what the boy's name."
          },
          {
            "sentence": "'''уcэупк1ы''' тэдэ ок1о",
            "translation": "'''I am asking you''' where are you going."
          },
          {
            "sentence": "'''къык1эупк1''' л1ыхэр рсы фаехэмэ",
            "translation": "'''Ask''' if the men want water."
          },
          {
            "sentence": "'''укъсэмыупк1''' тэщтэу шытэгъ тестэр",
            "translation": "'''Don't ask me''' how was the test."
          }
        ]
      }
    ],

    "cognate": "щ1эупщ1эн",
    "type": "verb",
    "ipa": "t͡ʃʼawpt͡ʃʼan"
  },
  {
    "word": "усын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to name (someone)",
        "examples": [
          {
            "sentence": "бзылъфыгъэм и к1алэ Том '''фиусэгъ'''.",
            "translation": "The woman '''named''' her son Tom."
          },
          {
            "sentence": "ахэмэ я пшъашъэхэмэ '''афаусыгъэхэр''' ц1э дахэх.",
            "translation": "The names '''they gave''' their daughters are beautiful."
          }
        ]
      },
      {
        "meaning": "to compose",
        "examples": [
          {
            "sentence": "л1ыхъужъым орэд '''фаусы'''.",
            "translation": "'''They compose''' a song about the brave person."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wsən"
  },
  {
    "word": "гу тэпсэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу тепсэфэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷə tapsafan"
  },
  {
    "word": "ерагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak, poor (Of low quality)",
        "examples": [
          {
            "sentence": "синэхэ '''ерагъэх'''",
            "translation": "My eyes '''are poor'''."
          },
          {
            "sentence": "синэхэмэ '''ерагъэу''' ялъэгъу",
            "translation": "My eyes '''barley''' see."
          },
          {
            "sentence": "л1ыжъэр '''ерагъэу''' мак1о",
            "translation": "The old man '''barley''' goes."
          },
          {
            "sentence": "'''ерагъэм''' 1эпы1эгъу фэхъу",
            "translation": "Help '''the weak people'''."
          },
          {
            "sentence": "мы мащинэр '''ерагъ'''",
            "translation": "This car is '''poor'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaraːʁ"
  },
  {
    "word": "гъолъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie down",
        "examples": [
          {
            "sentence": "Л1ыр п1эм '''хэгъолъыхьагъ'''",
            "translation": "The man '''lied down''' into the bed."
          },
          {
            "sentence": "К1алэр '''гъолъыгъ'''",
            "translation": "The boy '''lied down'''."
          },
          {
            "sentence": "К1алэр '''мэгъолъырэп'''",
            "translation": "The boy '''is not lying down'''."
          },
          {
            "sentence": "'''Гъолъ'''",
            "translation": "'''lie down!'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷaɬən"
  },
  {
    "word": "техак1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "aggressor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "texaːkʷʼ"
  },
  {
    "word": "гъэк1эрэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to adorn, to decorate",
        "examples": [
          {
            "sentence": "Классыр дахэу '''бгъэк1эрэк1эн''' фае",
            "translation": "You need to '''decorate''' the classroom beautifully."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡ʃarat͡ʃan"
  },
  {
    "word": "шхъомч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chestnut",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃχʷamt͡ʃ"
  },
  {
    "word": "ашхырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "edible",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːʃxəra"
  },
  {
    "word": "щынэ зымыш1эрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fearless",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃəna zəməʃʼara"
  },
  {
    "word": "щынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fear, to be afraid",
        "examples": [
          {
            "sentence": "сэ '''сэщынэ'''",
            "translation": "'''I am afraid'''."
          },
          {
            "sentence": "сэ '''сыщынэрэп'''",
            "translation": "'''I am not afraid'''."
          },
          {
            "sentence": "к1алэр л1ым '''шэщынэ'''",
            "translation": "The boy '''is afraid''' of the man."
          },
          {
            "sentence": "к1алэр '''мэщынэ'''",
            "translation": "The boy '''is scared'''."
          },
          {
            "sentence": "'''укъагъэщынагъа?'''",
            "translation": "'''Did they scared you'''."
          },
          {
            "sentence": "к1алэр мэз мэзахым хэхьэнэ '''мэщынэ'''",
            "translation": "The boy '''is scared''' to enter the dark forest."
          },
          {
            "sentence": "'''умщын''' ары мы блэр къоцэкъэтэп",
            "translation": "'''Don't fear''', indeed this snake won't bit you."
          },
          {
            "sentence": "сэ тыгъужъым '''сегъэщынэ'''",
            "translation": "The wolfs make me '''scare'''."
          }
        ]
      }
    ],
    "synonyms": [
      "щтэн",
      "тхыон"
    ],
    "type": "verb",
    "ipa": "ɕənan"
  },
  {
    "word": "щтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fear, to get afraid",
        "examples": [
          {
            "sentence": "к1алэр '''мащтэ'''",
            "translation": "The boy '''is getting afraid'''."
          },
          {
            "sentence": "к1алэр л1ым '''шэщтэ'''",
            "translation": "The boy '''is afraid''' of the man."
          },
          {
            "sentence": "'''укъагъэщтагъа?'''",
            "translation": "'''Did they scared you'''."
          },
          {
            "sentence": "к1алэр мэз мэзахым хэхьэнэ '''мэщтэ'''",
            "translation": "The boy '''is scared''' to enter the dark forest."
          },
          {
            "sentence": "'''умыщт''' ары мы блэр къоцэкъэтэп",
            "translation": "'''Don't fear''', indeed this snake won't bit you."
          },
          {
            "sentence": "сэ тыгъужъым '''сегъэщтэ'''",
            "translation": "The wolfs make me '''scare'''."
          }
        ]
      },
      {
        "meaning": "to be frightened (of), to be startled (of), to take fright (at); to shy (at)",
        "examples": []
      }
    ],
    "synonyms": [
      "щынэн",
      "тхыон"
    ],
    "type": "verb",
    "ipa": "ɕtan"
  },
  {
    "word": "гъоу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "herald",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaw"
  },
  {
    "word": "шъхьарыхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂəħaːrəχʷan"
  },
  {
    "word": "гъэлагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "painted",
        "examples": [
          {
            "sentence": "дэпкъ '''гъэлагъэ'''",
            "translation": "'''The painted''' wall."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʁalaːʁa"
  },
  {
    "word": "мыхъомыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "patchwork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məχʷaməʃʼ"
  },
  {
    "word": "орэда1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "singer",
        "examples": [
          {
            "sentence": "к1алэр '''орэда1у'''",
            "translation": "The boy is a '''singer'''."
          }
        ]
      }
    ],
    "synonyms": [
      "орэды1у"
    ],
    "type": "noun",
    "ipa": "waradaːʔʷ"
  },
  {
    "word": "пыск1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыск1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəskʲan"
  },
  {
    "word": "хъагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mesh (For example window mesh)",
        "examples": [
          {
            "sentence": "Ныор '''хъагъэм''' къыхэплъы",
            "translation": "The old-woman is looking through '''the mesh (window)'''."
          }
        ]
      },
      {
        "meaning": "wire net (Usually wire fence)",
        "examples": [
          {
            "sentence": "к1алэр '''хъагъэм''' блэпк1ы",
            "translation": "The boy jumps over the '''wire net'''."
          },
          {
            "sentence": "'''хъагъэм''' чэмхэр блэк1ышъухэрэп",
            "translation": "The cows can't pass the '''wire net'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "χaːʁa"
  },
  {
    "word": "хэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look through",
        "examples": [
          {
            "sentence": "к1алэр дэпкъым '''хэплъы'''",
            "translation": "The boy '''is looking through''' the wall."
          },
          {
            "sentence": "хъагъэм '''схэплъы'''",
            "translation": "'''I am looking through''' the net."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xapɬən"
  },
  {
    "word": "хэплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look (among them)",
        "examples": [
          {
            "sentence": "к1алэр мазым '''хаплъэ'''",
            "translation": "The boy '''is looking''' at the forest."
          },
          {
            "sentence": "к1алэр ц1ыфхэмэ '''къахэплъы'''",
            "translation": "The boy is '''looking''' from the people."
          },
          {
            "sentence": "псым '''хаплъ'''",
            "translation": "'''Look at''' the water."
          },
          {
            "sentence": "'''яхаплъи''' къыса1у томэр яхэтмэ",
            "translation": "'''Look at them''' and tell me if Tom among them."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xapɬan"
  },
  {
    "word": "гъэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to train",
        "examples": [
          {
            "sentence": "Тхьэматэм идзак1охэр '''егъасэх'''",
            "translation": "The caption is '''training''' his soldiers."
          },
          {
            "sentence": "КIалэр сэ '''сэгъасэ''' шэк1онэу",
            "translation": "'''I am training''' the boy to be a hunter."
          },
          {
            "sentence": "КIалэр '''гъэсагъэп''' икъунэу",
            "translation": "The boy '''is not trained''' enough."
          },
          {
            "sentence": "КIалэр дзэм хэхьан фэш1к1э '''зигъэсэн''' фай",
            "translation": "In order for '''the boy''' to join the army he need to train himself."
          },
          {
            "sentence": "К1алэм '''зегъасэ'''",
            "translation": "The boy '''is training (himself)'''."
          },
          {
            "sentence": "Дзэмк1э к1алэхэр '''къагъасэх'''",
            "translation": "In the army '''they train''' the boys."
          },
          {
            "sentence": "К1алэр экзамыным '''фагъэсэ'''",
            "translation": "'''They train''' the boy '''for''' the exam."
          }
        ]
      },
      {
        "meaning": "to teach good manners, to teach good behavior",
        "examples": [
          {
            "sentence": "Мы к1алэр '''агъэсагъэп'''",
            "translation": "This boy '''was not taught how to behave'''."
          },
          {
            "sentence": "Томым ик1алэ ш1у '''ыгъасэ'''",
            "translation": "Tom '''is teaching''' his boy '''how to behave well'''."
          },
          {
            "sentence": "Томым к1алэр '''егъасэ'''",
            "translation": "Tom '''is teaching''' the boy '''how to behave well'''."
          },
          {
            "sentence": "К1алэр '''гъэсагъэп'''",
            "translation": "The boy '''not taught how to behave well'''."
          },
          {
            "sentence": "Тэрэзу уиянэ '''угъэсагъэба'''",
            "translation": "'''Didn't''' your mother '''taught you (how to behave)''' correctly?"
          }
        ]
      },
      {
        "meaning": "to tame",
        "examples": [
          {
            "sentence": "Л1ым хьэр '''егъасэ'''",
            "translation": "The man '''is tinning''' the dog."
          },
          {
            "sentence": "К1алэм ичэтыухэр '''ыгъэсагъэх'''",
            "translation": "The boy '''tined ''' his cats."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэ1эсэн"
    ],
    "type": "adj",
    "ipa": "ʁasan"
  },
  {
    "word": "гъэсагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trained",
        "examples": [
          {
            "sentence": "дзэл1эр '''гъэсагъ'''",
            "translation": "The soldier '''is trained'''."
          },
          {
            "sentence": "хьэ '''гъэсагъэм''' мэлхэмэ нэ1э ятигъэтыт",
            "translation": "A '''trained''' dog will protect the sheep's."
          }
        ]
      },
      {
        "meaning": "taught to behave-well",
        "examples": [
          {
            "sentence": "к1алэр '''гъэсагъ'''",
            "translation": "The boy '''taught to behave-well'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʁasaːʁ"
  },
  {
    "word": "да1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "listener, a person that listens",
        "examples": []
      },
      {
        "meaning": "obedient",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "daːʔʷa"
  },
  {
    "word": "дэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go with him/her, to go alongside him/her",
        "examples": [
          {
            "sentence": "пшъашъэр к1алым '''дэк1от'''",
            "translation": "The girl '''will go with''' the boy."
          },
          {
            "sentence": "'''укъэсдэк1ота?'''",
            "translation": "'''Will you go with me?'''"
          },
          {
            "sentence": "л1ит1ор мэзым '''зэдэк1уагъэх'''",
            "translation": "The two men '''went to''' to the forest '''together'''."
          },
          {
            "sentence": "иропым уиунагъо '''удэк1ота?'''",
            "translation": "'''Will you go''' to Europe with your family?"
          },
          {
            "sentence": "томыр нартым дак1о",
            "translation": "Tom '''is going with''' Nart."
          }
        ]
      },
      {
        "meaning": "to marry a man/boy.",
        "examples": [
          {
            "sentence": "пшъашъэр к1алым '''дэк1от'''",
            "translation": "The girl '''will marry''' the boy."
          },
          {
            "sentence": "'''укъэсдэк1ота?'''",
            "translation": "'''Will you marry me?''' (Can only said to women)"
          },
          {
            "sentence": "нартым ыпшъашъэ '''дэк1от'''",
            "translation": "Nart's daughter '''will get marry'''."
          },
          {
            "sentence": "томым ыпшъашъит1у '''дэк1уагъэх'''",
            "translation": "Tom's both daughters '''are married'''."
          },
          {
            "sentence": "дэянэ томым '''дак1о'''",
            "translation": "Diana '''is marrying''' Tom."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dakʷʼan"
  },
  {
    "word": "къэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bring someone",
        "examples": [
          {
            "sentence": "к1алым пшъашъэр '''къищагъ'''",
            "translation": "The boy '''brought''' the girl."
          },
          {
            "sentence": "'''сыкъэпщэта?'''",
            "translation": "'''Will you bring me?'''"
          },
          {
            "sentence": "тиунэ укъак1омэ уишы '''къэздащ'''",
            "translation": "When you come to my house, '''bring''' your brother."
          },
          {
            "sentence": "томэр '''къащэба'''",
            "translation": "'''Bring''' Tom."
          },
          {
            "sentence": "нартым томыр къещэ",
            "translation": "Nart '''is bringing''' Tom."
          }
        ]
      },
      {
        "meaning": "to marry a woman/girl.",
        "examples": [
          {
            "sentence": "к1алым пшъашъэр '''къищагъ'''",
            "translation": "The boy '''married''' the girl."
          },
          {
            "sentence": "'''сыкъэпщэта?'''",
            "translation": "'''Will you marry me?''' (Can only said to men)."
          },
          {
            "sentence": "нартым ыпшъашъэ '''къэсщэт'''",
            "translation": "'''I will marry''' Nart's daughter."
          },
          {
            "sentence": "томым ыпшъашъит1у '''къэщагъэх'''",
            "translation": "Tom's both daughters '''are married'''."
          },
          {
            "sentence": "томым дэянэр '''къещэ'''",
            "translation": "Tom '''is marrying''' Diana."
          }
        ]
      },
      {
        "meaning": "fiancee",
        "examples": []
      },
      {
        "meaning": "romantic partner (boyfriend or girlfriend)",
        "examples": [
          {
            "sentence": "'''Уикъэщэн''' уиунэ къак1о",
            "translation": "'''Your romantic partner''' is coming to your house."
          },
          {
            "sentence": "'''Сикъэщэн''' сэлъагъу",
            "translation": "I am seeing '''my romantic partner'''."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэкъэщэных'''",
            "translation": "The boy and the girl are '''romantic partners'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaɕan"
  },
  {
    "word": "дае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "walnut (fruit and tree)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːja"
  },
  {
    "word": "дадыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Flower tea",
        "examples": []
      },
      {
        "meaning": "Marjoram (a plant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːdəw"
  },
  {
    "word": "дагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lack, deficiency, deficit, shortage",
        "examples": []
      },
      {
        "meaning": "defect, drawback, flaw",
        "examples": []
      }
    ],
    "synonyms": [
      "щык1агъэ",
      "нэц1эк1"
    ],
    "type": "noun",
    "ipa": "daːʁʷa"
  },
  {
    "word": "дап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sewing workshop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːpʼa"
  },
  {
    "word": "пхъэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "checkers",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχat͡ʃan"
  },
  {
    "word": "дамп1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Slap sound",
        "examples": []
      },
      {
        "meaning": "fall sound",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːmpʼ"
  },
  {
    "word": "дак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sewing",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːt͡ʃa"
  },
  {
    "word": "даргу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expression of Crashing/Falling sound",
        "examples": [
          {
            "sentence": "мыжъор едзым '''дыргу''' къыригъэ1уагъ",
            "translation": "When he throw the rock, it made a '''dirg sound''' ('''Crashing/Falling sound''')."
          }
        ]
      }
    ],
    "synonyms": [
      "жъгъау"
    ],
    "type": "intj",
    "ipa": "daːrɡʷ"
  },
  {
    "word": "дыргу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expression of Crashing/Falling sound",
        "examples": [
          {
            "sentence": "мыжъор едзым '''дыргу''' къыригъэ1уагъ",
            "translation": "When he throw the rock, it made a '''dirg sound''' ('''Crashing/Falling sound''')."
          }
        ]
      }
    ],
    "synonyms": [
      "жъгъау"
    ],
    "type": "intj",
    "ipa": "dərɡʷ"
  },
  {
    "word": "дауш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "praise",
        "examples": []
      }
    ],
    "synonyms": [
      "щытхъу"
    ],
    "type": "noun",
    "ipa": "daːwəʃ"
  },
  {
    "word": "дасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "creeping",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːsa"
  },
  {
    "word": "даш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sympathy,",
        "examples": []
      },
      {
        "meaning": "Conjunctive letter (grammar)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daːʃʼa"
  },
  {
    "word": "де1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to assist",
        "examples": []
      },
      {
        "meaning": "to be in favor of, to root for",
        "examples": [
          {
            "sentence": "Заомк1э адыгэмэ '''саде1э'''",
            "translation": "In war '''I am in favor of''' the Circassians."
          },
          {
            "sentence": "'''Усде1эт'''",
            "translation": "'''You will be in favor of mine'''."
          },
          {
            "sentence": "Джанэ плъыжь щыгъэхэмэ '''саде1э'''",
            "translation": "'''I am in favor of''' those that wear red shirts."
          },
          {
            "sentence": "Футболк1э хэт '''узде1эра'''?",
            "translation": "In soccer who '''do you cheer'''?"
          }
        ]
      },
      {
        "meaning": "to help to push with someone",
        "examples": [
          {
            "sentence": "Мыгъак1уи мащинэм '''къэсде1'''",
            "translation": "Come and '''help me push ''' this car."
          },
          {
            "sentence": "К1алэм '''уде1эми''' мащинэ шъугъэхъыешъутэп",
            "translation": "Even if '''you push with''' the boy you couldn't move the car."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dajʔan"
  },
  {
    "word": "дегъэбзыхык1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to exterminate, to hide completely,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapsən"
  },
  {
    "word": "гъотын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to find",
        "examples": [
          {
            "sentence": "'''Къэгъот''' си1анэ",
            "translation": "'''Find''' my table."
          },
          {
            "sentence": "К1алэм мыехэр '''егъотых'''",
            "translation": "The boy '''finds''' apples."
          },
          {
            "sentence": "Томым иунэ '''къэсдэгъот'''",
            "translation": "'''Find''' Tom's house '''with me'''."
          },
          {
            "sentence": "Томым иунэ '''къэсдэпгъотэгъ'''",
            "translation": "'''You found with me''' Tom's house."
          },
          {
            "sentence": "Томым ихьэ '''къагъотышъурэп'''",
            "translation": "'''They can't find''' Tom's dog."
          },
          {
            "sentence": "К1алэм зыфае '''ыгъотрэп'''",
            "translation": "The boy '''isn't finding''' what he wants."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷatən"
  },
  {
    "word": "хэгъощэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lose one's way, to go astray, to get lost (by going the wrong way)",
        "examples": [
          {
            "sentence": "К1алэр мэзым '''хэгъощагъ'''",
            "translation": "The boy '''got lost''' in the forest."
          }
        ]
      }
    ],
    "synonyms": [
      "хэгъуэщэн"
    ],
    "type": "verb",
    "ipa": "xaʁʷaɕan"
  },
  {
    "word": "гъэнэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to appoint",
        "examples": [
          {
            "sentence": "Зыщызэ1ук1эщтхэ ч1ып1эр '''гъэнэфэн'''",
            "translation": "'''To appoint''' the meeting place."
          }
        ]
      },
      {
        "meaning": "to determine",
        "examples": [
          {
            "sentence": "Хьасэм игъунапкъэ '''угъэнэфэн''' фае",
            "translation": "'''You''' need '''to determine''' the field's borders."
          },
          {
            "sentence": "Шъхьадж '''и1офш1эн''' гъэнэфэн фае",
            "translation": "Evey one's assignment should be '''determined'''."
          }
        ]
      },
      {
        "meaning": "to clarify",
        "examples": []
      },
      {
        "meaning": "to illuminate",
        "examples": [
          {
            "sentence": "Л1ым унэр '''къегъэнэфы'''",
            "translation": "The man '''illuminates''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁanafan"
  },
  {
    "word": "гощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to distribute",
        "examples": [
          {
            "sentence": "Гъомылапхъэр '''гощын'''",
            "translation": "'''To distribute''' food."
          },
          {
            "sentence": "Сиянэ мыехэр '''къетфеугощы'''",
            "translation": "My mother '''spreads''' the apples '''for us'''."
          },
          {
            "sentence": "К1алэм ахъщэ '''еугощы'''",
            "translation": "The boy '''spreads''' money."
          },
          {
            "sentence": "Картыхэр '''къэугощых'''",
            "translation": "'''Distribute''' the cards."
          }
        ]
      },
      {
        "meaning": "to divide",
        "examples": [
          {
            "sentence": "Л1ым ич1ыгу ик1алэхэм '''ауфиугощыгъ'''",
            "translation": "The man '''divided''' his land to his boys."
          },
          {
            "sentence": "Пчъагъэр пчъагъэкIэ '''гощыныр'''",
            "translation": "'''To divided''' number with number."
          }
        ]
      },
      {
        "meaning": "to split",
        "examples": [
          {
            "sentence": "Мыер т1у '''гощ'''",
            "translation": "'''Split''' the apple to two."
          },
          {
            "sentence": "У1эк1э '''уугощышъущта''' пхъэр?",
            "translation": "'''can you split''' the wood with your hands?"
          },
          {
            "sentence": "Томым пхъэр '''еугощы'''",
            "translation": "Tom '''splits''' the wood."
          },
          {
            "sentence": "Мыер т1у '''угощи''' к1алит1ум ет",
            "translation": "'''Split''' the apple into two and give them to the two boys."
          }
        ]
      }
    ],
    "synonyms": [
      "угощын"
    ],
    "cognate": "гуэшын",
    "type": "verb",
    "ipa": "ɡʷaɕən"
  },
  {
    "word": "деблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "braid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "debla"
  },
  {
    "word": "дебзык1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut between",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "debzət͡ʃan"
  },
  {
    "word": "гупшысэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to think of, to think about",
        "examples": [
          {
            "sentence": "К1алэр '''егупшысэ'''",
            "translation": "The boy '''thinks'''."
          },
          {
            "sentence": "К1алэр '''егупшысэ''' пшъашъэм",
            "translation": "The boy '''thinks''' about the girl."
          },
          {
            "sentence": "К1алэр чъыгы ч1эгъым '''ч1егупшысэ'''",
            "translation": "The boy '''thinks under''' the tree."
          },
          {
            "sentence": "'''Сегъэгупшыс'''",
            "translation": "'''Let me think'''."
          },
          {
            "sentence": "Мэкъэшхом '''сыригъэгупшысэрэп'''",
            "translation": "The big noise '''doesn't let me think'''."
          },
          {
            "sentence": "Матэматыкамк1э дэгъу '''уегупшысэн''' фай",
            "translation": "In math you need '''to think''' good."
          },
          {
            "sentence": "О ехъурэм '''сегупшысэрэп'''",
            "translation": "'''I don't think of''' anything expect you."
          },
          {
            "sentence": "К1алэр '''егупшысэ''' сыд ыш1эщтыр",
            "translation": "The boy '''is thinking''' what he gonna do."
          },
          {
            "sentence": "'''Егупшыс'''",
            "translation": "'''Think'''."
          },
          {
            "sentence": "Аш '''cегупшысагъ'''",
            "translation": "'''I thought about''' that."
          },
          {
            "sentence": "Томым т1урэ '''емыгупшысэу''' еп1орэ еш1э",
            "translation": "Tom does that you tell him '''without thinking''' twice."
          }
        ]
      }
    ],

    "cognate": "гупсысэн",
    "type": "verb",
    "ipa": "ɡʷəpʃəsan"
  },
  {
    "word": "гук1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егук1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷət͡ʃan"
  },
  {
    "word": "гъэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to destroy, to ruin",
        "examples": [
          {
            "sentence": "К1алэм машинэр '''егъао'''",
            "translation": "The boy '''destroys''' the car."
          },
          {
            "sentence": "Унэр '''къэгъау'''",
            "translation": "'''Destroy''' the house."
          },
          {
            "sentence": "Мощ уикомпыютер '''ыгъэощт'''",
            "translation": "He '''gonna destroy''' your computer."
          }
        ]
      },
      {
        "meaning": "to make something explode",
        "examples": [
          {
            "sentence": "Унэр '''къэгъау'''",
            "translation": "'''Explode''' the house."
          },
          {
            "sentence": "Къумбыл гъоч1эгъым ч1адзи '''къагъэуагъ'''",
            "translation": "They threw a bomb under the cave '''and made it explode'''."
          }
        ]
      },
      {
        "meaning": "to make/allow someone to hit",
        "examples": [
          {
            "sentence": "К1алэр чъыгым '''есэгъао'''",
            "translation": "'''I am making''' the boy '''hit''' the tree."
          },
          {
            "sentence": "Л1ым к1алэр '''егъао'''",
            "translation": "The man '''is allowing/making''' the boy hit."
          },
          {
            "sentence": "Л1ым к1алэр чъыгым '''регъао''' отычк1э",
            "translation": "The man '''is allowing/making''' the boy hit the tree"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawan"
  },
  {
    "word": "гъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to turn, to rotate",
        "examples": [
          {
            "sentence": "Моу '''къэгъаз'''",
            "translation": "'''Turn back''' here."
          },
          {
            "sentence": "1анэр '''къыопшъогъэзэщтэп'''",
            "translation": "'''You can't turn''' the table."
          },
          {
            "sentence": "1анэр '''гъэз'''",
            "translation": "'''Turn''' the table."
          },
          {
            "sentence": "К1алэм 1анэр '''къегъазэ'''",
            "translation": "The boy '''is turning''' the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁazan"
  },
  {
    "word": "гъэжъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make food by heat, for example: to bake, to grill, to fry.",
        "examples": [
          {
            "sentence": "сиянэ пц1эжъыехэр '''егъэжъэх'''",
            "translation": "My mother '''is making''' fishes."
          },
          {
            "sentence": "томым к1энк1э '''егъажъэ'''",
            "translation": "Tom '''is making''' eggs."
          },
          {
            "sentence": "к1энк1э '''гъажъ'''",
            "translation": "'''Make''' eggs."
          },
          {
            "sentence": "Пцэжъые '''зэрыбгъажъэрэр''' мы табэр ара?",
            "translation": "'''Are you frying''' the fish using this pan?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʐan"
  },
  {
    "word": "матхъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be happy",
        "examples": [
          {
            "sentence": "к1алэр '''матхъажьы'''",
            "translation": "The boy '''is happy'''."
          },
          {
            "sentence": "к1алэр '''мэчтхъэжьэгъ''', пшъашъэр '''мэтхъэжьыт'''",
            "translation": "The boy '''was happy''', the girl '''will be happy'''."
          },
          {
            "sentence": "Иропым '''ушытхъэжьыт'''",
            "translation": "'''You will be happy''' in Europa."
          },
          {
            "sentence": "'''утхъэжьрэба?'''",
            "translation": "'''Aren't you happy?'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maːtχaʑən"
  },
  {
    "word": "гъэтхъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone happy",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''егъэтхъэжьы'''",
            "translation": "The boy '''is making''' the girl '''happy'''."
          },
          {
            "sentence": "К1алэц1ык1ор '''гъэтхъэжь'''",
            "translation": "'''Make''' the little boy '''happy'''."
          },
          {
            "sentence": "'''Усгъэтхъэжьыщт'''",
            "translation": "'''I will make you happy'''."
          },
          {
            "sentence": "А к1алэр '''угъэтхъэжьышъущтэп'''",
            "translation": "'''You can't make''' that boy '''happy'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaːtχaʑən"
  },
  {
    "word": "дигъэтк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэгъэтк1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "diʁatkʷʼan"
  },
  {
    "word": "дэпт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get something stuck",
        "examples": [
          {
            "sentence": "си1эпхъабэхэр анэм '''дэпт1агъэх'''",
            "translation": "My fingers '''are stuck''' in the table."
          },
          {
            "sentence": "к1алэм и1э '''дэпт1агъ''' чъыгым",
            "translation": "The boy's arm '''got stuck''' in the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daptʼan"
  },
  {
    "word": "дэгъэпт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get something stuck",
        "examples": [
          {
            "sentence": "си1эпхъабэхэр анэм '''дэпгъапт1агъэх'''",
            "translation": "'''You made''' my finger '''stuck''' in the table."
          },
          {
            "sentence": "к1алэм и1э '''дэсгъэпт1агъ''' чъыгым",
            "translation": "'''I made''' the boy's arm '''to stuck''' in the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁaptʼan"
  },
  {
    "word": "т1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to implant",
        "examples": [
          {
            "sentence": "к1алэм къэгъагъэхэ '''ет1эх'''",
            "translation": "the boy '''is implanting''' the flowers."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tʼan"
  },
  {
    "word": "дэгъэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something fit into somewhere",
        "examples": [
          {
            "sentence": "к1алэм анэм '''тигъэфэт''' тхылъыр",
            "translation": "The boy '''will fit the book above''' the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁafan"
  },
  {
    "word": "гъэпсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to illuminate, to brighten, to shine",
        "examples": [
          {
            "sentence": "Унэр '''къэгъапс'''",
            "translation": "'''Illuminate''' the house."
          },
          {
            "sentence": "Томым тигъогу '''къегъапсэ''' нэфнэк1э",
            "translation": "Tom '''is illuminating''' our road with light."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷatən"
  },
  {
    "word": "дэгъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэгъэбылъхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁabəɬħan"
  },
  {
    "word": "дэгъэбыбык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly out, to speed away,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁabəbət͡ʃən"
  },
  {
    "word": "дэгъэбыбэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to launch high;",
        "examples": [
          {
            "sentence": "к1алэм мыжъор '''дигъэбыбэягъ'''",
            "translation": "the boy '''launched''' the rock."
          }
        ]
      },
      {
        "meaning": "to make/let something fly upward",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁabəbajan"
  },
  {
    "word": "дэбыбэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly (upward)",
        "examples": [
          {
            "sentence": "бадзэ '''дэбыбыягъ'''",
            "translation": "The fly '''flew high'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dabəbajan"
  },
  {
    "word": "дэгъэк1оен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise (hold up)",
        "examples": [
          {
            "sentence": "л1ым ык1алэ '''дигъэк1оягъ'''",
            "translation": "The man '''raised''' his boy."
          },
          {
            "sentence": "радиюум имакъэ '''дэгъэк1уай'''",
            "translation": "'''raise''' the radio's sound (volume)."
          }
        ]
      },
      {
        "meaning": "to make someone/something go up",
        "examples": [
          {
            "sentence": "л1ым ик1алэ унэм '''дигъэк1оягъ'''",
            "translation": "The man '''made''' the boy '''to go up''' to the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁakʷʼajan"
  },
  {
    "word": "фэгъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to forgive",
        "examples": [
          {
            "sentence": "'''Къэсфэгъэгъу'''",
            "translation": "'''Forgive me'''."
          },
          {
            "sentence": "О ук1элэ дэгъумэ '''къупфагъэгъут'''",
            "translation": "If you are a good boy '''they gonna forgive you'''."
          },
          {
            "sentence": "Улъа1омэ тхьэм '''къупфигъэгъут'''",
            "translation": "If you prey god '''will forgive you'''."
          },
          {
            "sentence": "Томым '''фэгъэгъу'''",
            "translation": "'''Forgive''' Tom."
          },
          {
            "sentence": "Томым '''яфигъэгъурэп'''",
            "translation": "Tom '''isn't forgiving them'''."
          }
        ]
      }
    ],

    "cognate": "хуэгъэгъун",
    "type": "verb",
    "ipa": "faʁaʁʷən"
  },
  {
    "word": "гъэгъушъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something dry (something that should not be wet such as clothes, roads, body parts).",
        "examples": [
          {
            "sentence": "Джанэр '''гъэгъушъ'''",
            "translation": "'''Dry''' the shirt."
          },
          {
            "sentence": "К1алэм джанэр '''егъэгъушъы'''",
            "translation": "The boy is '''drying out''' the shirt."
          },
          {
            "sentence": "Джанэр япэу '''гъэгъушъ'''",
            "translation": "'''Dry''' the shirt first."
          },
          {
            "sentence": "Хьапшэрэ дезертымк1э тык1от? '''тыпгъэгъушъыта?'''",
            "translation": "How much are we gonna walk in the desert? '''are you gonna dry us out'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʁʷʂən"
  },
  {
    "word": "дэгъэдыдык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone leave quicklyl",
        "examples": [
          {
            "sentence": "к1алэм л1ыр '''дигъэдыдык1ыгъ'''",
            "translation": "the boy '''made''' the man '''to leave quickly'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁadədəkʲən"
  },
  {
    "word": "дэгъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something fall out of another object",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁazən"
  },
  {
    "word": "дэгъэзен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to send someone upwards",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁazajan"
  },
  {
    "word": "дэгъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make/allow someone or something to stay (In some area)",
        "examples": [
          {
            "sentence": "к1алэм ищау анэ '''дегъэты'''",
            "translation": "The boy '''is keeping''' the table in his yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁatən"
  },
  {
    "word": "дэгъэт1ысхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make/allow someone or something to sit (In some area)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁatʼəsħan"
  },
  {
    "word": "дэгъэт1ыт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill some liquid into somewhere",
        "examples": [
          {
            "sentence": "к1алэм мащинэм '''дегъэт1ат1э''' псы",
            "translation": "The boy '''is spilling''' water into the car."
          },
          {
            "sentence": "к1алэм ихьэлэгъу '''дегъэт1ат1э''' кетчуп",
            "translation": "The boy '''is spilling''' ketchup into his bread."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁatʼətʼan"
  },
  {
    "word": "гъэгожъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sad",
        "examples": [
          {
            "sentence": "'''сэбэгъэгужъы'''",
            "translation": "'''You are making me sad'''."
          },
          {
            "sentence": "к1алэм пшъашъэр '''егъэгожъы'''",
            "translation": "The boy '''is making''' the girl '''sad'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɡʷaʐən"
  },
  {
    "word": "дэгъэчъыхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to freeze (in some area)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daʁat͡ʃəħan"
  },
  {
    "word": "дэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take somethings with oneself",
        "examples": [
          {
            "sentence": "томым хым '''дихьэгъ''' бэнанэрэ мыерэ",
            "translation": "Tom '''took with him''' an apple and a banana to the sea."
          },
          {
            "sentence": "сиунэ укъак1омэ '''къэздахь''' сидиск",
            "translation": "When you come to my house '''bring with you''' the disk."
          },
          {
            "sentence": "дезэртым псы '''дахь'''",
            "translation": "'''Take''' water '''with you''' in the desert."
          },
          {
            "sentence": "томым '''дехьы''' псы дезэртым",
            "translation": "Tom '''is taking''' water '''with him''' in the desert."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daħən"
  },
  {
    "word": "деупсэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut the hair upwards,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dewpsajan"
  },
  {
    "word": "деухьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to turn around",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dewħan"
  },
  {
    "word": "деутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to Blunge, to spray",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dewtan"
  },
  {
    "word": "деутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pull out, to tear out",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajtən"
  },
  {
    "word": "деусэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to find a solution together with",
        "examples": []
      },
      {
        "meaning": "to write a poem together with,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajsan"
  },
  {
    "word": "деусын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie (say a lie) together with",
        "examples": []
      },
      {
        "meaning": "to name together with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajsən"
  },
  {
    "word": "дэялъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "walnut garden,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajaːɬ"
  },
  {
    "word": "деушъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drive away or out,",
        "examples": []
      },
      {
        "meaning": "to expel",
        "examples": []
      },
      {
        "meaning": "to fill together with",
        "examples": []
      },
      {
        "meaning": "to pile together with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajʂan"
  },
  {
    "word": "дефыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to expel upwards",
        "examples": []
      },
      {
        "meaning": "to launch upwards",
        "examples": []
      },
      {
        "meaning": "to raise price",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajfəjan"
  },
  {
    "word": "дехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be taken out",
        "examples": []
      },
      {
        "meaning": "to go down together with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajxəx"
  },
  {
    "word": "дехьыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэхьыен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajħəjan"
  },
  {
    "word": "дехьыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэхьыхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajħəxən"
  },
  {
    "word": "ецэгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bite, to gnaw",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jat͡səʁʷan"
  },
  {
    "word": "ечъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run down a slope, to slide down a slope",
        "examples": [
          {
            "sentence": "Псыр '''ечъэхы''' гъогум",
            "translation": "The water '''slides down''' from the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jat͡ʂaxən"
  },
  {
    "word": "дэш1ыхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to build in",
        "examples": [
          {
            "sentence": "'''дэсш1ыхьагъ''' унэ къалэм",
            "translation": "'''I build''' a house in the city."
          }
        ]
      }
    ],
    "type": "verb"
  },
  {
    "word": "шын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to measure",
        "examples": [
          {
            "sentence": "к1алэм '''ешы''' ик1эхьыгъэ к1апсэм.",
            "translation": "The boy '''measures''' the rope's length."
          },
          {
            "sentence": "'''шы''' хьапщ сик1эхьыгъэ",
            "translation": "'''measure''' my height."
          },
          {
            "sentence": "къишэк1ыгъэ килэу-гырамк1э '''яшы''', к1эхьыгъэ метерк1э '''яшы'''",
            "translation": "'''They measure''' weight with Kilograms and '''they measure''' length with meters."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃən"
  },
  {
    "word": "дещыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise (hold up)",
        "examples": []
      },
      {
        "meaning": "to take someone up",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajɕəjan"
  },
  {
    "word": "дэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take someone with oneself",
        "examples": [
          {
            "sentence": "томэр хым '''дэпщэрагъоба?'''",
            "translation": "'''Don't you want to bring''' Tom '''with you''' to the sea?"
          },
          {
            "sentence": "томым '''дищагъ''' хым ик1алит1у",
            "translation": "Tom '''took''' both his boys to the sea."
          },
          {
            "sentence": "сиунэ укъак1омэ '''къыздащ''' к1алэр",
            "translation": "When you come to my house '''bring''' the boy '''with you'''."
          },
          {
            "sentence": "дезэртым к1алэр '''дащ'''",
            "translation": "'''Take''' the boy '''with you''' in the desert."
          },
          {
            "sentence": "томым '''дещэ''' махък1эхэр дезэртым",
            "translation": "Tom '''is taking''' the camels '''with him''' in the desert."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daɕan"
  },
  {
    "word": "дэгъэуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to place/put somethings somewhere",
        "examples": [
          {
            "sentence": "томым псы '''дигъэуцуагъ''' фриджым",
            "translation": "Tom '''put''' water in the fridge."
          },
          {
            "sentence": "томым анэ '''дегъэуцо''' сишау",
            "translation": "Tom '''is placing''' table in my yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁawat͡sʷan"
  },
  {
    "word": "гъэуцун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to place",
        "examples": [
          {
            "sentence": "Томым псы '''егъэуцу'''",
            "translation": "Tom '''is placing''' water."
          },
          {
            "sentence": "Томым шъхьэхъор '''егъэуцу'''",
            "translation": "Tom '''is placing''' the flowerpot."
          },
          {
            "sentence": "Томым 1анэм псы '''тегъэуцо'''",
            "translation": "Tom '''is placing''' water on the table."
          },
          {
            "sentence": "Псы '''гъэуцу'''",
            "translation": "'''Place''' a water."
          }
        ]
      },
      {
        "meaning": "to stop someone/something",
        "examples": [
          {
            "sentence": "'''Гъэуцу'''",
            "translation": "'''stop it'''."
          },
          {
            "sentence": "'''Гъэуцуба'''",
            "translation": "'''stop it already'''."
          },
          {
            "sentence": "К1алэм пшъашъэр '''къегъэуцу'''",
            "translation": "the boy '''is stopping''' the girl."
          },
          {
            "sentence": "К1элэр '''къэпгъэуцугъ'''",
            "translation": "'''you stopped''' the boy."
          },
          {
            "sentence": "Мащинэр '''къэсэгъэуцу'''",
            "translation": "'''I am stopping''' the cat."
          },
          {
            "sentence": "К1алэр гъогум темыхьагъэу '''къэгъэуцу'''",
            "translation": "'''stop''' the boy before he goes on the road."
          },
          {
            "sentence": "Мэш1окур '''къэсгъэуцушъущтэп'''",
            "translation": "'''I will not be capable to stop''' the train."
          },
          {
            "sentence": "К1алэм пшъашъэр '''къыгъэуцугъ'''",
            "translation": "The boy '''stopped''' the girl."
          },
          {
            "sentence": "К1алэм сомпыютэрэр '''къегъэуцу'''",
            "translation": "The boy '''is stopping''' the computer."
          },
          {
            "sentence": "Полисым '''укъигъэуцущт'''",
            "translation": "The police '''gonna stop you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawət͡ʃʷən"
  },
  {
    "word": "дэщтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to agree upon with",
        "examples": [
          {
            "sentence": "Томым '''къэсдещтэ'''",
            "translation": "Tom '''is agreeing with me'''."
          },
          {
            "sentence": "Томэр к1алым '''дещтэ'''",
            "translation": "Tom '''is agreeing''' with the boy."
          },
          {
            "sentence": "К1алэр пшъашъэм '''дрищтагъ'''",
            "translation": "The boy '''agreed''' with what girl."
          },
          {
            "sentence": "'''Къыпдэсыщтэнэу''' сыфаеп",
            "translation": "'''I don't want to agree with you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daɕtan"
  },
  {
    "word": "ежьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to start, to begin",
        "examples": [
          {
            "sentence": "К1алэр '''ежьэ'''",
            "translation": "The boy '''is starting'''."
          },
          {
            "sentence": "К1алэр '''ежьэ''' мак1о",
            "translation": "The boy '''is starting''' to go."
          },
          {
            "sentence": "'''Уемыжь'''",
            "translation": "'''Don't start'''."
          },
          {
            "sentence": "К1алэхэр мэджэгухэу '''ежьагъэх'''",
            "translation": "The boys '''started''' playing."
          },
          {
            "sentence": "Л1ыр мэлажэу '''ежьагъэ'''",
            "translation": "The man '''started''' to work."
          },
          {
            "sentence": "Филымыр '''ежьагъ'''",
            "translation": "the film '''has started'''."
          }
        ]
      },
      {
        "meaning": "to go off (to the road), to depart",
        "examples": []
      },
      {
        "meaning": "to set out on a journey.",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʑan"
  },
  {
    "word": "дежьыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to comb the hair up, to raise the hair,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deʑəjan"
  },
  {
    "word": "дежъыуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chorus",
        "examples": []
      },
      {
        "meaning": "flatterer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dajʐəwaːkʷa"
  },
  {
    "word": "дежъыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to flatter, to cheer",
        "examples": [
          {
            "sentence": "тхьэматэм дежъыун",
            "translation": "To cheer the leader"
          }
        ]
      },
      {
        "meaning": "to chant with",
        "examples": [
          {
            "sentence": "орэдым дежъыун",
            "translation": "To chant in the song"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dajʐəwan"
  },
  {
    "word": "декъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to quarrel with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajqəjan"
  },
  {
    "word": "декъузэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to squeeze",
        "examples": []
      },
      {
        "meaning": "to tighten",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajqʷəzan"
  },
  {
    "word": "дэкьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dakʲan"
  },
  {
    "word": "делъэшъоен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pull up, to pull upwards",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajɬaʂʷajan"
  },
  {
    "word": "делъэсык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump away, to sweep away,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajɬasət͡ʃən"
  },
  {
    "word": "делъэсэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sweep upwards",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajɬasajan"
  },
  {
    "word": "дэлъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put in",
        "examples": [
          {
            "sentence": "Сэ гык1элъэ машинэм гык1ынхэр '''дэсэлъхьэх'''.",
            "translation": "'''I am putting''' the laundry in the washing machine."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daɬħan"
  },
  {
    "word": "делашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Crazy looking, foolish",
        "examples": [
          {
            "sentence": "о к1алэр '''делашъу'''",
            "translation": "That boy '''is foolish'''."
          },
          {
            "sentence": "о к1алэр '''делашъогор'''",
            "translation": "That boy '''is some foolish'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "dajlaːʃʷ"
  },
  {
    "word": "делагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "craziness, foolishness",
        "examples": [
          {
            "sentence": "о к1алэм '''иделагъэ''' бэ",
            "translation": "That boy's '''foolishness''' is alot."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "dajlaːʁa"
  },
  {
    "word": "делэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "insane asylum",
        "examples": []
      },
      {
        "meaning": "mental hospital",
        "examples": []
      },
      {
        "meaning": "sanitarium",
        "examples": []
      }
    ],
    "synonyms": [
      "делэхъан"
    ],
    "type": "noun",
    "ipa": "dajlaɕ"
  },
  {
    "word": "делэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very foolish, very stupid, very crazy",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "dajlaʐ"
  },
  {
    "word": "деок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit from the side",
        "examples": []
      },
      {
        "meaning": "to shift aside, to shift from",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajwat͡ʃən"
  },
  {
    "word": "тэтхык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тэтхык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тэтхык1ын"
    ],
    "type": "verb",
    "ipa": "tatxəkʲʼən"
  },
  {
    "word": "тэтхык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тетхык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тэтхык1ьын"
    ],
    "type": "verb",
    "ipa": "tatxət͡ʃʼən"
  },
  {
    "word": "дэтхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pull out (usually a page)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "datχən"
  },
  {
    "word": "деджэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "school friend",
        "examples": []
      }
    ],
    "synonyms": [
      "десэгъу",
      "дестэгъу"
    ],
    "type": "noun",
    "ipa": "dajd͡ʒaʁʷ"
  },
  {
    "word": "десэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "school friend",
        "examples": []
      }
    ],
    "synonyms": [
      "деджэгъу",
      "дестэгъу"
    ],
    "type": "noun",
    "ipa": "dajsaʁʷ"
  },
  {
    "word": "дестэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "school friend",
        "examples": []
      }
    ],
    "synonyms": [
      "десэгъу",
      "деджэгъу"
    ],
    "type": "noun",
    "ipa": "dajstaʁʷ"
  },
  {
    "word": "дестэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lesson (School hour)",
        "examples": []
      }
    ],
    "synonyms": [
      "дес"
    ],
    "type": "noun",
    "ipa": "dajsta"
  },
  {
    "word": "дес",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lesson (School hour)",
        "examples": []
      }
    ],
    "synonyms": [
      "дестэ"
    ],
    "type": "noun",
    "ipa": "dajs"
  },
  {
    "word": "деузэндын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to provide with (arms in particular), to supply with (material),",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajwzandən"
  },
  {
    "word": "деугъоен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather into somewhere with",
        "examples": [
          {
            "sentence": "к1алэм мыехэр '''деугъуех''' ишагу",
            "translation": "The boy '''gathers''' apples into his yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dewʁʷajan"
  },
  {
    "word": "мэгъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be throwing stuff",
        "examples": [
          {
            "sentence": "К1алэр '''мэгъуе'''",
            "translation": "The boy '''is throwing'''."
          },
          {
            "sentence": "К1алэр мыжъок1э '''мэгъуе'''",
            "translation": "The boy '''is throwing''' with (using) a rock."
          },
          {
            "sentence": "'''Умгъуй'''",
            "translation": "'''don't throw'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maʁʷəjan"
  },
  {
    "word": "егъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъуин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁʷajən"
  },
  {
    "word": "къэугъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather",
        "examples": [
          {
            "sentence": "к1алэм мыехэр '''къеугъоих'''",
            "translation": "The boy '''gathers''' apples."
          },
          {
            "sentence": "к1алэм мыехэр '''къиугъойгъэх'''",
            "translation": "The boy '''gathered''' apples."
          },
          {
            "sentence": "мыехэр '''къэугъоих'''",
            "translation": "'''Gathers''' apples."
          },
          {
            "sentence": "'''къэугъой''' джанэхэр, '''къэмыугъой''' гъошэджыхэр",
            "translation": "'''Gather''' the shirts, '''don't gather''' the pants."
          }
        ]
      },
      {
        "meaning": "to collect",
        "examples": [
          {
            "sentence": "мыехэр '''къэугъоих'''",
            "translation": "'''Gathers''' apples."
          }
        ]
      }
    ],
    "synonyms": [
      "угъоин",
      "зэхэугъоен",
      "зэк1эугъоен"
    ],
    "type": "verb",
    "ipa": "qawʁʷajən"
  },
  {
    "word": "дэубытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to arrest in, to catch in, to close someone in",
        "examples": [
          {
            "sentence": "к1алэм томэр '''диубэтагъ''' дэпкъым",
            "translation": "The boy '''closed''' Tom on the wall."
          },
          {
            "sentence": "Боксэр Мухьамэд Али ц1ыфыхэр къурнэм '''деубытэшъух'''",
            "translation": "The boxer Mohammad Ali '''can close''' people in the corner."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dawbətan"
  },
  {
    "word": "деуп1эп1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pile in",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajwpʼapʼan"
  },
  {
    "word": "деукъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to abolish, to annul an agreement,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajqʷan"
  },
  {
    "word": "деузэнк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rectify with, to reform with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajwzant͡ʃən"
  },
  {
    "word": "мэш1уащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэш1уач\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maʃʷʼaːɕa"
  },
  {
    "word": "нэмк1ыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэмык1\"",
        "examples": []
      }
    ],

    "cognate": "нэмщ1ыр",
    "type": "adj",
    "ipa": "namt͡ʃʼər"
  },
  {
    "word": "пэмк1ыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэмк1ыр\"",
        "examples": []
      }
    ],
    "synonyms": [
      "нэмк1ыр"
    ],
    "type": "noun",
    "ipa": "pamt͡ʃər"
  },
  {
    "word": "ибэдалэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "instead of",
        "examples": [
          {
            "sentence": "к1алэр '''ибэдалэу''' къащагъ",
            "translation": "'''Instead''' they brought the boy."
          },
          {
            "sentence": "к1алэр мак1о пшъашъэм '''ибэдалэу'''",
            "translation": "The boy went '''instead''' of the girl."
          },
          {
            "sentence": "ущ '''ибэдалэу''' мыепсы къэхь",
            "translation": "'''Instead''' of that bring apple juice."
          },
          {
            "sentence": "мыехэмэ '''ябэдалэу''', бэнанэхэр къэтэгъэхь",
            "translation": "'''Instead''' of the apples, let us bring bananas."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jəbadaːlaw"
  },
  {
    "word": "шыхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to friction",
        "examples": [
          {
            "sentence": "пхъэр анэм '''шэхъо'''",
            "translation": "the wood '''is fractioning''' the table."
          },
          {
            "sentence": "анэр дэпкъым '''шыхъуагъ'''",
            "translation": "the table '''fractioned''' with the wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəχʷan"
  },
  {
    "word": "к1элъэтэкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slip and fall",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃaɬatakʲən"
  },
  {
    "word": "шыдзыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go away",
        "examples": [
          {
            "sentence": "дэпкъым к1алэр '''шэдзиегъ'''",
            "translation": "The boy '''got away''' from wall."
          },
          {
            "sentence": "дэпкъым '''шэдзий'''",
            "translation": "'''Get away''' from the wall."
          },
          {
            "sentence": "'''сыкъышэдзый'''",
            "translation": "'''Get away from me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəd͡ʒəjan"
  },
  {
    "word": "шашыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "veil",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃaːʃəf"
  },
  {
    "word": "шамгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prostitute",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃamɡʲan"
  },
  {
    "word": "лышъут1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beauty spot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ləʃʷtʼa"
  },
  {
    "word": "быбхалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "helicopter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bəbxaːɬ"
  },
  {
    "word": "1этэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "elbow",
        "examples": []
      }
    ],
    "synonyms": [
      "1энтэгъу"
    ],
    "type": "noun",
    "ipa": "ʔataʁʷ"
  },
  {
    "word": "1ынджыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fig",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔənd͡ʒər"
  },
  {
    "word": "гъэкьэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэчэрэзын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэчэрэзын"
    ],
    "cognate": "гъэджэрэзын",
    "type": "verb",
    "ipa": "ʁakʲarazən"
  },
  {
    "word": "мэлэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be loosed (freed)",
        "examples": [
          {
            "sentence": "хьэр '''мэлэлагъ'''",
            "translation": "The dog '''is loosed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "malalan"
  },
  {
    "word": "илъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rush in, to enter quickly",
        "examples": [
          {
            "sentence": "к1алэр унэм '''илъэдагъ'''",
            "translation": "The boy '''entered''' the house '''quickly'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəɬadan"
  },
  {
    "word": "тэгъэт1ысхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sit on something",
        "examples": [
          {
            "sentence": "'''тэгъэт1ысхь'''",
            "translation": "'''Make him sit'''."
          },
          {
            "sentence": "к1алэр '''тэгъэт1ысхь''' шым",
            "translation": "'''Sit''' the boy on the horse."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁatʼəsħan"
  },
  {
    "word": "мэбзэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзэен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mabzajan"
  },
  {
    "word": "ебзэин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзэен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jabzajən"
  },
  {
    "word": "фэд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "like (Similar)",
        "examples": [
          {
            "sentence": "к1алэр '''къэсфэд'''",
            "translation": "The boy is '''like me'''."
          },
          {
            "sentence": "синэмэ '''яфэд'''",
            "translation": "'''Like''' my eyes."
          },
          {
            "sentence": "'''а фэдэу''' зымыш1",
            "translation": "Don't be/act '''like that'''."
          },
          {
            "sentence": "'''cэфэд'''",
            "translation": "'''Like me'''."
          }
        ]
      }
    ],

    "cognate": "хуэд",
    "type": "adv",
    "ipa": "fad"
  },
  {
    "word": "пыс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыц1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəsʼan"
  },
  {
    "word": "изакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alone",
        "examples": [
          {
            "sentence": "К1алэм '''изакъу'''",
            "translation": "The boy '''is alone'''."
          },
          {
            "sentence": "К1алэм '''изакъоу''' къащ",
            "translation": "Bring the boy '''alone'''."
          },
          {
            "sentence": "К1алэхэмэ '''язакъу'''",
            "translation": "The boys '''are alone'''."
          },
          {
            "sentence": "К1алэм '''изакъоу''' иунэ мэк1ожьэгъ",
            "translation": "The boy returned to his home '''alone'''."
          },
          {
            "sentence": "'''Уизакъоу''' къэбэк1охьы",
            "translation": "You are going around '''alone'''."
          },
          {
            "sentence": "'''Уизакъуа''' унэм исэ?",
            "translation": "'''Are you alone''' in the house?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jəzaːqʷ"
  },
  {
    "word": "закъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "only",
        "examples": [
          {
            "sentence": "к1элэ '''закъу''' уи1э",
            "translation": "you '''only''' have boys."
          },
          {
            "sentence": "а '''закъорэ''' нэ1э уи1э?",
            "translation": "Is that '''the only''' thing you have?"
          },
          {
            "sentence": "мые '''закъу''' сышхырэ",
            "translation": "I '''only''' eat apple."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zaːqʷ"
  },
  {
    "word": "пылъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hanging, to be attached",
        "examples": [
          {
            "sentence": "1ук1ыбзэхэр пк1эм '''пылъых'''",
            "translation": "The keys '''are hanging''' on the door."
          }
        ]
      },
      {
        "meaning": "to be dealing with someone",
        "examples": [
          {
            "sentence": "К1алэр маш1о хэгъэнэным '''пылъ'''",
            "translation": "The boy '''is dealing with''' igniting fire."
          },
          {
            "sentence": "Сэ сомпыютэрмэ '''сапылъ'''",
            "translation": "'''I am dealing with''' computers."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəɬən"
  },
  {
    "word": "пытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hanging, to be attached (Usually said about Fruits and Vegetables)",
        "examples": [
          {
            "sentence": "бэнанэ чыгым '''пыт'''",
            "translation": "There is a banana '''on''' the tree."
          },
          {
            "sentence": "мыехэр чыгым '''пытых'''",
            "translation": "The apples '''are on''' the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pətən"
  },
  {
    "word": "пысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hanging, to be attached (Usually said about Humans and Animals)",
        "examples": [
          {
            "sentence": "к1алэр чыгым '''пыс'''",
            "translation": "The boy is '''on''' the tree."
          },
          {
            "sentence": "маймунхэр чыгым '''пысых'''",
            "translation": "The monkeys '''are on''' the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəsən"
  },
  {
    "word": "атэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uncle (Father's brother)",
        "examples": []
      }
    ],
    "synonyms": [
      "ятэш"
    ],
    "type": "noun",
    "ipa": "ʔaːtaʃ"
  },
  {
    "word": "анэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uncle (Mother's brother)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːnaʃ"
  },
  {
    "word": "атэшыпхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "aunt (Father's sister)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːtaʃəpχʷ"
  },
  {
    "word": "анэшыпхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "aunt (Mother's sister)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːnaʃəpχʷ"
  },
  {
    "word": "хьак1эщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hotel; inn",
        "examples": []
      },
      {
        "meaning": "guest room; living room",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэк1ап1э"
    ],
    "type": "noun",
    "ipa": "ħaːt͡ʃʼaɕ"
  },
  {
    "word": "сыд фэдиз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how much, how long",
        "examples": [
          {
            "sentence": "'''Сыд фэдиз''' хъугъа мыщ шъузыщы1эр?",
            "translation": "'''How long''' have you been here for?"
          },
          {
            "sentence": "'''Сыд фэдизрэ''' мыщ шъущы1эщт?",
            "translation": "'''How long''' will you be staying?"
          },
          {
            "sentence": "'''Сыд фэдиз''' мыщ тафэрэ?",
            "translation": "'''How much''' this cost?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "səd fadiz"
  },
  {
    "word": "гу рихьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу рихьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷərəjħən"
  },
  {
    "word": "гъэпсэфак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vacation",
        "examples": [
          {
            "sentence": "Мыщ '''гъэпсэфак1о''' шъукъэк1уагъа?",
            "translation": "Are you here on '''vacation'''? (Said to plural)"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁapsafaːkʷʼa"
  },
  {
    "word": "дэжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "near",
        "examples": [
          {
            "sentence": "к1алэм '''идэжь''' къак1у",
            "translation": "Come '''near''' the boy."
          },
          {
            "sentence": "унэм '''идэжь''' 1ут",
            "translation": "He/it is standing '''near''' the house."
          },
          {
            "sentence": "тэ '''тадэжь''' къак1у",
            "translation": "Come '''near''' us."
          },
          {
            "sentence": "тэ '''тадэжь''' о усхьыт",
            "translation": "I will take you '''near us'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "daʑ"
  },
  {
    "word": "оды",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slim",
        "examples": [
          {
            "sentence": "К1алэр '''оды'''",
            "translation": "The boy '''is slim'''."
          },
          {
            "sentence": "'''Зыгъэоды'''",
            "translation": "'''Make yourself slim'''."
          },
          {
            "sentence": "Бэрэ чъи '''одэу''' хъут",
            "translation": "Run a lot and you will become '''slim'''."
          },
          {
            "sentence": "'''Уодынэу''' уфая?",
            "translation": "Do you want to be '''slim'''?"
          },
          {
            "sentence": "К1алэр пшъашъэм нахь '''оды'''",
            "translation": "The boy is much more '''slim''' than the girl."
          }
        ]
      }
    ],
    "cognate": "уэд",
    "type": "adj",
    "ipa": "wadə"
  },
  {
    "word": "хъус1ысый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "on purpose",
        "examples": [
          {
            "sentence": "'''хъус1ысыиу''' к1алэр еуагъ",
            "translation": "The boy hit him '''on purpose'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "χʷəsʼəsəj"
  },
  {
    "word": "гущы1эухыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sentence",
        "examples": [
          {
            "sentence": "Сэ '''гущы1эухыгъэм''' сыкъеджэ",
            "translation": "I am reading '''the sentence'''."
          },
          {
            "sentence": "Сэ '''гущы1эухыгъэ''' сэтхы",
            "translation": "I write '''a sentence'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəɕəʔawxəʁa"
  },
  {
    "word": "тхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to write",
        "examples": [
          {
            "sentence": "Сэ гущы1эухыгъэ '''сэтхы'''",
            "translation": "I '''write''' a sentence."
          },
          {
            "sentence": "Тхылъ '''тхы'''",
            "translation": "'''Write''' a book."
          },
          {
            "sentence": "К1алэм гущы1э '''итхэгъ'''",
            "translation": "The boy '''wrote''' a letter."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "txən"
  },
  {
    "word": "тхьапш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how much",
        "examples": [
          {
            "sentence": "Сыхьатыр '''тхьапш''' хъугъэ, къыса1оба?",
            "translation": "Can you tell me what is the time alright?"
          },
          {
            "sentence": "'''Тхьапш''' мыщ иуасэ?",
            "translation": "'''How much''' this cost?"
          },
          {
            "sentence": "'''Тхьапш''' шыхэу уи1э?",
            "translation": "'''How much''' brothers you have?"
          },
          {
            "sentence": "'''Тхьапш''' сыхьатыр?",
            "translation": "What time is it?"
          }
        ]
      }
    ],
    "synonyms": [
      "шыдфэдиз"
    ],
    "type": "adv",
    "ipa": "tħapʃ"
  },
  {
    "word": "такъикъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "minute",
        "examples": [
          {
            "sentence": "Зы сыхьатыр '''такъикъ''' тIокIищ мэхъу.",
            "translation": "An hour has sixty minutes. ."
          },
          {
            "sentence": "'''Такъикъит1ук1э''' унэм сыкъик1ыт",
            "translation": "'''In two minute''' I will come out from the house."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "taːqiq"
  },
  {
    "word": "гъэш1эгъоны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "something out of the ordinary",
        "examples": [
          {
            "sentence": "'''гъэш1эгъоныба''' мы к1алэр? тучаным уатэ къихьынэу сэгъак1уи отыч къэсфехьы.",
            "translation": "'''Isn't''' this kid '''out of the ordinary'''? I send him to bring a hammer, and he brings me an ax."
          },
          {
            "sentence": "'''зыгъэш1эгъон''' къэсщыш1ыгъэр. унэм сыдэк1ынэу зэрэсыкъик1эу, къещхэу къиублагъ.",
            "translation": "Something '''so out of the ordinary''' happened to me. while I was exiting the house to go out, it started to rain."
          },
          {
            "sentence": "ш1ухьафтын '''гъэш1эгъон''' горэ сэ къыпфэсхьыщт.",
            "translation": "I will bring you '''an out of the ordinary''' gift."
          }
        ]
      },
      {
        "meaning": "strange",
        "examples": []
      },
      {
        "meaning": "weird",
        "examples": [
          {
            "sentence": "Ц1ыф '''гъэш1эгъоныба''' ор",
            "translation": "That is a '''weird''' person '''right'''?"
          },
          {
            "sentence": "Ц1ыфым джэнэ '''гъэш1эгъоныхэр''' щыгъэх",
            "translation": "The person wears '''weird''' clothes."
          }
        ]
      },
      {
        "meaning": "interesting",
        "examples": [
          {
            "sentence": "Фильмыр '''гъэш1эгъоныгъэ'''",
            "translation": "The film was '''interesting'''."
          },
          {
            "sentence": "Сызэреплъырэмк1э, ар '''гъэш1эгъоны'''",
            "translation": "I find that '''interesting'''."
          },
          {
            "sentence": "Сэ архитектурэр '''сш1огъэш1эгъон'''",
            "translation": "'''I’m interested''' in architecture."
          }
        ]
      },
      {
        "meaning": "stunning, astounding, overwhelming",
        "examples": [
          {
            "sentence": "'''Гъэш1эгъоны''' горэ сылъэгъогъ",
            "translation": "I saw something '''overwhelming'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэш1эгъон"
    ],
    "cognate": "гъэщ1эгъуэн",
    "type": "adj",
    "ipa": "ʁaʃʼaʁʷanə"
  },
  {
    "word": "штэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъыда\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хьа",
      "сыда"
    ],
    "type": "adv",
    "ipa": "ɕta"
  },
  {
    "word": "фэдиз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "equal",
        "examples": []
      },
      {
        "meaning": "much",
        "examples": [
          {
            "sentence": "Сыд '''фэдиз''' хъугъа мыщ шъузыщы1эр?",
            "translation": "How '''long/much''' have you been here for?"
          },
          {
            "sentence": "Сыд '''фэдизрэ''' мыщ шъущы1эщт?",
            "translation": "How '''long/much''' will you be staying?"
          },
          {
            "sentence": "Сыд '''фэдиз''' мыщ тафэрэ?",
            "translation": "How '''much''' this cost?"
          },
          {
            "sentence": "А '''фэдизыу'''",
            "translation": "That '''much'''."
          },
          {
            "sentence": "Тэ '''фэдиз''' мыщ тафэрэ?",
            "translation": "How '''much''' this cost?"
          },
          {
            "sentence": "А '''фэдизыу''' дахэ пшъашъэр?",
            "translation": "Is the girl pretty that '''much'''?"
          },
          {
            "sentence": "Къэсхьыгъэр мыщ '''фэдизэп'''",
            "translation": "What I brought '''is not as much''' as this."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "fadiz"
  },
  {
    "word": "ныбджэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "friend",
        "examples": [
          {
            "sentence": "Томым Мартэр '''иныбджэгъу'''",
            "translation": "Marta is Tom's '''friend'''."
          },
          {
            "sentence": "К1алэм '''иныбджэгъухэр''' къек1уалъэх",
            "translation": "The boy's '''friends''' are coming close."
          },
          {
            "sentence": "Мыр '''синыбджэгъу'''",
            "translation": "This is '''my friend'''."
          }
        ]
      }
    ],
    "synonyms": [
      "шъэогъу"
    ],
    "cognate": "гъусэ",
    "type": "noun",
    "ipa": "nəbd͡ʒaʁʷ"
  },
  {
    "word": "чэщдэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "party",
        "examples": [
          {
            "sentence": "Мыщ '''чэщдэс''' щыIа?",
            "translation": "There is a party here?"
          }
        ]
      }
    ],
    "synonyms": [
      "пчыхьэзэхахь"
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕdas"
  },
  {
    "word": "дэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sit (In some specific area)",
        "examples": [
          {
            "sentence": "К1алэр шаум '''дэс'''",
            "translation": "The boy '''is sitting''' in the yard."
          }
        ]
      },
      {
        "meaning": "to settle (In some specific area)",
        "examples": [
          {
            "sentence": "К1алэр чылэм '''дэс'''",
            "translation": "The boy '''is settling''' in the village."
          },
          {
            "sentence": "К1алэр къалэм '''дэсыгу'''",
            "translation": "The boy '''is settling in''' the city '''for now'''."
          }
        ]
      },
      {
        "meaning": "to be in some specific area",
        "examples": [
          {
            "sentence": "Шаум '''дэс'''",
            "translation": "'''Be''' in the yard."
          },
          {
            "sentence": "Къуаджэм '''дэс'''",
            "translation": "'''Be''' in the village."
          },
          {
            "sentence": "К1алэр къалэм '''дэсыгу'''",
            "translation": "The boy '''is in''' the city '''for now'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dasən"
  },
  {
    "word": "шъон к1уач1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alcohol drink",
        "examples": [
          {
            "sentence": "О '''шъон к1уач1э''' уешъуа?",
            "translation": "Do you drink alcohol?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʃʷan kʷʼaːt͡ʃa"
  },
  {
    "word": "мы1эрысэпсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "apple juice",
        "examples": [
          {
            "sentence": "К1элэц1ык1ум '''мы1эрысэпс''' ик1ас",
            "translation": "The little boy likes '''apple juice'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "məʔarəsapsə"
  },
  {
    "word": "тыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "where",
        "examples": [
          {
            "sentence": "'''тыдэ''' мак1о к1алэр?",
            "translation": "'''Where''' is the boy going?"
          },
          {
            "sentence": "'''тыдэ''' так1о?",
            "translation": "'''Where''' are we going?"
          },
          {
            "sentence": "'''тыдэ''' шы1эх мыехэр",
            "translation": "'''Where''' are the apples?"
          },
          {
            "sentence": "'''тыдэ''' усхьыт",
            "translation": "'''Where''' I will take you?"
          }
        ]
      }
    ],
    "synonyms": [
      "тэдэ"
    ],
    "cognate": "дэнэ",
    "type": "adv",
    "ipa": "təda"
  },
  {
    "word": "шыдгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъыдгъо\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʃədʁʷa"
  },
  {
    "word": "дэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to listen",
        "examples": [
          {
            "sentence": "К1алэр '''мэда1о'''",
            "translation": "The boy '''is listening'''."
          },
          {
            "sentence": "К1алэр '''мэда1орэп'''",
            "translation": "The boy '''is not listening'''."
          },
          {
            "sentence": "'''Шъукъэда1у''' ц1ыфыхэр, '''шъукъэда1у''' псэори",
            "translation": "'''Listen''' people, '''listen''' all of you."
          },
          {
            "sentence": "Ц1ыфыхэр '''къэда1ох'''",
            "translation": "The people '''are listening'''."
          }
        ]
      },
      {
        "meaning": "to listen and obey",
        "examples": [
          {
            "sentence": "Сик1алэ '''мэда1орэп'''",
            "translation": "My boy '''is not obeying'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʔʷan"
  },
  {
    "word": "пындж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rice",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pənd͡ʒ"
  },
  {
    "word": "сыд джыри",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "what else",
        "examples": [
          {
            "sentence": "'''Сыд джыри''' тищык1агъэр?",
            "translation": "'''What else''' do we need?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "səd d͡ʒəri"
  },
  {
    "word": "лъэхъаны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "season",
        "examples": [
          {
            "sentence": "Мыхэр илъэсым '''илъэхъаных'''",
            "translation": "These are the '''seasons''' of the year."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɬaχaːnə"
  },
  {
    "word": "жъоркъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жъоркъ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʒʷarqə"
  },
  {
    "word": "къипсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shine into",
        "examples": [
          {
            "sentence": "Тыгъэр унэм '''къепсэ'''",
            "translation": "The sun '''shines''' the house."
          },
          {
            "sentence": "Тыгъэр унэм '''къипсэшъурэп'''",
            "translation": "The sun '''can't shine''' the house."
          },
          {
            "sentence": "Гъэмафэм тыгъэр '''къепсы'''",
            "translation": "The sun '''shines''' in summer."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qinpsan"
  },
  {
    "word": "къесын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall snow, to snow",
        "examples": [
          {
            "sentence": "К1ымафэм ос '''къесы'''",
            "translation": "It '''snows''' in winter."
          },
          {
            "sentence": "Нэущы '''къесыт'''",
            "translation": "Tomorrow '''it will snow'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qesən"
  },
  {
    "word": "къещхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rain",
        "examples": [
          {
            "sentence": "Ощх '''къещхы'''",
            "translation": "It is '''raining''' rain."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qeɕxən"
  },
  {
    "word": "тыгъэпсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sunny",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "təʁapsə"
  },
  {
    "word": "ышъхьагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "above",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "əʂħaːʁ"
  },
  {
    "word": "ыч1эгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "below",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ət͡ʃʼaʁ"
  },
  {
    "word": "к1ыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "behind",
        "examples": []
      }
    ],

    "cognate": "щ1ыб",
    "type": "noun",
    "ipa": "t͡ʃʼəb"
  },
  {
    "word": "гъэпск1ып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bathroom",
        "examples": [
          {
            "sentence": "Мыщ дэжьым пщэрыхьап1эр ыкIи '''гъэпскIып1эр''' щыIэх",
            "translation": "The kitchen and '''bathroom''' are here."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁapst͡ʃəpʼa"
  },
  {
    "word": "чъыяп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bedroom",
        "examples": [
          {
            "sentence": "МодыкIэ унэшхор ык1и '''чъыяп1эр''' щы1эх",
            "translation": "The living room and '''bedroom''' are there."
          }
        ]
      }
    ],
    "synonyms": [
      "гъолъып1э"
    ],
    "type": "noun",
    "ipa": "t͡ʂəjaːpʼa"
  },
  {
    "word": "унапчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "front door",
        "examples": [
          {
            "sentence": "'''Унапчъэр''' егъэтыгъ",
            "translation": "The '''front door''' is closed."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wənaːpt͡ʂa"
  },
  {
    "word": "егъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lock",
        "examples": [
          {
            "sentence": "Пчъэр '''егъэт'''",
            "translation": "'''Lock''' the door.."
          },
          {
            "sentence": "Пчъэр '''егъэтыгъ'''",
            "translation": "The door '''is locked'''."
          },
          {
            "sentence": "К1алэм пчъэр '''регъэты'''",
            "translation": "The boy is '''locking''' the door."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁatən"
  },
  {
    "word": "1ухын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to open",
        "examples": [
          {
            "sentence": "пчъэр '''1ух'''",
            "translation": "'''Open''' the door.."
          },
          {
            "sentence": "пчъэр '''1ухэгъ'''",
            "translation": "The door '''is open'''."
          },
          {
            "sentence": "к1алэм пчъэр '''1уехы'''",
            "translation": "The boy is '''opening''' the door."
          },
          {
            "sentence": "к1алэм пчъэр '''1уихэгъ'''",
            "translation": "The boy is '''opened''' the door."
          },
          {
            "sentence": "к1алэм пчъэр '''къу1урегъэх'''",
            "translation": "'''Make''' the boy '''open''' the door."
          }
        ]
      },
      {
        "meaning": "to take something away from near soemthing",
        "examples": [
          {
            "sentence": "1анэр чъыгым '''1ух'''",
            "translation": "'''Take''' the table '''away''' from the tree."
          },
          {
            "sentence": "К1алэм 1эгуаоу чъыгым '''1уехы'''",
            "translation": "The boy '''is talking''' the ball '''away''' from the tree."
          },
          {
            "sentence": "Пхъэнт1эк1ухэр '''1умыхых'''",
            "translation": "'''Don't take away''' the chairs from there."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷəxən"
  },
  {
    "word": "пхъэнт1эк1ущыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "armchair",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχantʼakʷʼɕəħa"
  },
  {
    "word": "к1экъэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brand new",
        "examples": [
          {
            "sentence": "Телевизорыр '''к1экъэпс'''",
            "translation": "The TV set is '''brand new'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃaqaps"
  },
  {
    "word": "укъэбзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to clean",
        "examples": [
          {
            "sentence": "К1алэм унэр '''еукъэбзы'''",
            "translation": "The boy is '''cleaning''' the house."
          },
          {
            "sentence": "Унэр сэ '''сэукъэбзы'''",
            "translation": "'''I am cleaning''' the house."
          },
          {
            "sentence": "'''Укъэбз''' унэр",
            "translation": "'''Clean''' the house."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэкъэбзэн"
    ],
    "type": "verb",
    "ipa": "wqabzən"
  },
  {
    "word": "тхьак1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wash",
        "examples": [
          {
            "sentence": "Сил1ы машинэр '''етхьак1ы'''",
            "translation": "My husband '''is washing''' the car."
          },
          {
            "sentence": "Тхьэмгъупчэр '''сэтхьэк1эгъ'''",
            "translation": "'''I washed''' the window."
          },
          {
            "sentence": "Бзылъфыгъэр '''мэтхьак1э'''",
            "translation": "The woman '''is washing'''."
          },
          {
            "sentence": "Хэта шъхьангъупчъэхэр '''зытхьакIрэр?'''",
            "translation": "Who '''washes''' the windows?"
          }
        ]
      }
    ],

    "cognate": "тхьэщ1ын",
    "type": "verb",
    "ipa": "tħat͡ʃən"
  },
  {
    "word": "кушъхьэфачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bicycle",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэрыгъачъэ",
      "лъэкъорыгъачъэ"
    ],
    "cognate": "лъакъуэрыгъажэ",
    "type": "noun",
    "ipa": "kʷəʂħafaːt͡ʂa"
  },
  {
    "word": "пылъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hang up",
        "examples": [
          {
            "sentence": "Сэ гык1ыгъэхэр '''пысэлъэх'''",
            "translation": "I am '''hanging up''' the laundry."
          },
          {
            "sentence": "гык1ыгъэхэр '''пылъэх''' чъыгым",
            "translation": "'''Hang up''' the laundry on the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəɬan"
  },
  {
    "word": "джэхашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "floor",
        "examples": [
          {
            "sentence": "'''Джэхашъор''' ш1ои",
            "translation": "'''The floor''' is dirty."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaxaːʃʷa"
  },
  {
    "word": "шыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dish",
        "examples": [
          {
            "sentence": "'''Шыкъухэр''' шIоих",
            "translation": "'''The dishes''' are dirty."
          },
          {
            "sentence": "Хэта '''шыкъухэр''' зытхьак1рэр?",
            "translation": "Who does the '''dishes'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʃəqʷ"
  },
  {
    "word": "пщэрыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cook",
        "examples": [
          {
            "sentence": "Сыда непэ '''упщэрыхьащтыр?'''",
            "translation": "What '''you gonna cook''' today?"
          },
          {
            "sentence": "О электричества хьаумэ гьаза '''узэрыпщэрыхьэрэр?'''",
            "translation": "'''Do you cook''' on an electric or a gas stove?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pɕarəħaːn"
  },
  {
    "word": "шъхьэ1ух",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corkscrew",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaʔʷəx"
  },
  {
    "word": "хэтэрык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vegetable",
        "examples": [
          {
            "sentence": "Are you grilling the '''vegetables''' on this grill?",
            "translation": "'''Хэтэрык1хэр''' зэрыбгъажъэхэрэр мы грилыр ара?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "xatarət͡ʃ"
  },
  {
    "word": "пхъэшъхьэмышъхьэхэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fruits",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχaʂħaməʂħaxar"
  },
  {
    "word": "1эплъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "towel",
        "examples": [
          {
            "sentence": "'''1эплъэк1''' уи1а?",
            "translation": "Do you have a '''towel'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "нэ1аплъэк1"
    ],
    "cognate": "нэ1аплъэщ1",
    "type": "noun",
    "ipa": "ʔapɬat͡ʃ"
  },
  {
    "word": "пхъэшъхьэмышъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fruit",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ышъхьэмышъхьэ",
      "къуе-мый"
    ],
    "type": "noun",
    "ipa": "pχaːʂħaməʂħ"
  },
  {
    "word": "к1эсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to like",
        "examples": [
          {
            "sentence": "Музыкэр '''уик1аса?'''",
            "translation": "Do you '''like''' music?"
          },
          {
            "sentence": "Сэ к1алэр '''сик1ас'''",
            "translation": "'''I like''' the boy."
          },
          {
            "sentence": "К1алэм пшъашъэр '''ик1ас'''",
            "translation": "The boy '''likes''' the girl."
          },
          {
            "sentence": "Сэ '''усик1ас'''",
            "translation": "'''I like you'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гу рихьан",
      "ш1улъэгъун",
      "гопэн"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼasan"
  },
  {
    "word": "и1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have",
        "examples": [
          {
            "sentence": "Мые '''уи1а?'''",
            "translation": "Do you '''have''' an apple."
          },
          {
            "sentence": "Сабыйхэр '''уи1эха?'''",
            "translation": "Do you '''have''' children?"
          },
          {
            "sentence": "'''Си1''' унэ дахэ",
            "translation": "'''I have''' a beautiful house."
          },
          {
            "sentence": "'''Си1эп''' колэ",
            "translation": "'''I don't have''' a cola."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʔan"
  },
  {
    "word": "нэгуш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nice",
        "examples": [
          {
            "sentence": "Ц1ыфхэри '''нэгуш1ох'''",
            "translation": "The people '''are nice'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "naɡʷəʃʷʼ"
  },
  {
    "word": "зэдзэк1ак1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "translator",
        "examples": [
          {
            "sentence": "Сэ '''сызэдзэк1ак1у'''",
            "translation": "I am a '''translator'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "zad͡zat͡ʃaːkʷʼ"
  },
  {
    "word": "зэдзэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to translate",
        "examples": [
          {
            "sentence": "Сэ тхылъхэр '''зэсэдзэк1ых'''",
            "translation": "I '''translate''' books."
          },
          {
            "sentence": "УрысыбзэкIэ тхылъыр адыгабзэкIэ зэдзэкIын",
            "translation": "'''To translate''' a Russian book to Adyghe."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zad͡zat͡ʃən"
  },
  {
    "word": "хэк1отагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "became late, became old",
        "examples": [
          {
            "sentence": "бзылъфыгъэ '''хэк1отагъ'''",
            "translation": "old woman."
          },
          {
            "sentence": "чэщыр '''хэк1отагъ'''",
            "translation": "the night became late."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xakʷʼataːʁ"
  },
  {
    "word": "тутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cigarette",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "təwtən"
  },
  {
    "word": "ш1оигъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to want",
        "examples": [
          {
            "sentence": "'''тш1оигъу'''",
            "translation": "'''We want'''."
          },
          {
            "sentence": "'''тш1оигъоп'''",
            "translation": "'''We don't want'''."
          },
          {
            "sentence": "Сэ сычъые '''сш1оигъу'''",
            "translation": "'''I want''' to sleep."
          },
          {
            "sentence": "Тэ теджэ '''тшIоигъу'''",
            "translation": "'''We want''' to read."
          },
          {
            "sentence": "Тутын уешъо '''пш1оигъуа?'''",
            "translation": "'''Would you want''' to smoke?"
          },
          {
            "sentence": "Сэ тутын сешъо сш1оигъуагъ",
            "translation": "'''I would like''' to smoke."
          },
          {
            "sentence": "Зыгорэ сшхы '''сш1оигъу'''",
            "translation": "'''I want''' to eat something."
          },
          {
            "sentence": "Сэ зыгорэм сыкъык1эупч1э '''сш1оигъу'''",
            "translation": "'''I want''' to ask you something."
          },
          {
            "sentence": "Тэ унэм тыкIожьы '''тш1оигъуагъ'''",
            "translation": "'''We want''' to drive home."
          },
          {
            "sentence": "Мыщ ущышхэ '''пш1оигъуа?'''",
            "translation": "'''Do you want''' to eat here?"
          },
          {
            "sentence": "Ахэр телефонкIэ теохэ '''ашIоигъуагъ'''",
            "translation": "'''They want''' to make a call."
          },
          {
            "sentence": "Шъуиныбджэгъухэр зэжъугъэлъэгъухэ '''шъуш1оигъуа?'''",
            "translation": "'''Do you want''' to visit friends? (said to plural)"
          },
          {
            "sentence": "Мыщк1э сыублэмэ '''сш1оигъу'''",
            "translation": "'''I want''' to start with this."
          },
          {
            "sentence": "Сыда пчэдыжьым шъушхымэ '''шъуш1оигъор?'''",
            "translation": "What '''would you like''' for breakfast?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷajəʁʷan"
  },
  {
    "word": "фэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to want",
        "examples": [
          {
            "sentence": "Тэ '''тфае'''",
            "translation": "'''We want'''."
          },
          {
            "sentence": "Тэ '''тыфаеп'''",
            "translation": "'''We don't want'''."
          },
          {
            "sentence": "Сыд '''шъузыфаер?'''",
            "translation": "What '''do you want to do'''?"
          },
          {
            "sentence": "Ащ сыкIонэу '''сыфаеп'''",
            "translation": "'''I don’t want''' to go there."
          },
          {
            "sentence": "Дискотекэм шъукIонэу '''шъуфая?'''",
            "translation": "'''Do you want''' to go to the disco? (said to plural)"
          },
          {
            "sentence": "Бананэ '''сыфай'''",
            "translation": "'''I want''' a banana."
          },
          {
            "sentence": "К1алэр мые '''фай'''",
            "translation": "The boy '''wants''' an apple."
          }
        ]
      }
    ],

    "cognate": "хуеин",
    "type": "verb",
    "ipa": "fajan"
  },
  {
    "word": "фае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "must",
        "examples": [
          {
            "sentence": "Сык1он '''фай'''",
            "translation": "I '''must''' go."
          },
          {
            "sentence": "Сэ хьак1эщыпк1эр стын '''фае'''",
            "translation": "I '''must''' pay the hotel."
          },
          {
            "sentence": "О Iофыбэ пшIэн '''фае'''",
            "translation": "You '''must''' do a lot work."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "faːja"
  },
  {
    "word": "гъэохъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to misguide, to mislead",
        "examples": []
      },
      {
        "meaning": "to put obstacles to; to hinder",
        "examples": []
      },
      {
        "meaning": "to mix, to mingle, to blend",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawaχʷən"
  },
  {
    "word": "нахьыш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "better",
        "examples": [
          {
            "sentence": "'''нахьыш1у''' т1эк1у ушхымэ",
            "translation": "'''It is better''' if you eat less."
          },
          {
            "sentence": "'''нахьыш1у''' тызэдак1омэ",
            "translation": "'''It is better''' if we go together."
          },
          {
            "sentence": "Метромк1э ук1омэ '''нахьыш1у'''",
            "translation": "'''It is best''' if you take the underground / subwayю"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naːħəʃʷʼ"
  },
  {
    "word": "нахьыбэрэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mostly",
        "examples": [
          {
            "sentence": "'''нахьыбэрэм''' синанэжъ иунэ сэк1о",
            "translation": "I go to my grandmother's house '''mostly'''."
          },
          {
            "sentence": "'''нахьыбэрэм''' мые сэшхы",
            "translation": "I eat apple '''mostly'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naːħəbaram"
  },
  {
    "word": "нахьыбэрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "more often",
        "examples": [
          {
            "sentence": "'''нахьыбэрэ''' синанэжъ иунэ сык1он фак",
            "translation": "I need to go to my grandmother's house '''more often'''."
          },
          {
            "sentence": "'''нахьыбэрэ''' мые сышхын фае",
            "translation": "I need to eat apple '''more often'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naːħəbara"
  },
  {
    "word": "егъэблэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone close to something",
        "examples": [
          {
            "sentence": "к1алэр тиунэ егъэблэгъ",
            "translation": "'''Make''' the boy '''to come near to our house'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁablaʁan"
  },
  {
    "word": "къегъэблэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to welcome, to invite",
        "examples": [
          {
            "sentence": "Ары, тэри '''тыкъырагъэблэгъагъ'''",
            "translation": "Indeed, '''we''' have also '''been invited'''."
          },
          {
            "sentence": "'''шъукъеблагъ'''",
            "translation": "'''Welcome'''. (said to plural)."
          },
          {
            "sentence": "к1алэр тиунэ '''къегъэблагъ'''",
            "translation": "'''Invite''' the boy to our house."
          }
        ]
      },
      {
        "meaning": "to make someone close to oneself",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajʁablaʁan"
  },
  {
    "word": "хьылъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hard, difficult",
        "examples": [
          {
            "sentence": "Ау урыгущыIэныр ыкIи утхэныр '''хьылъэ'''",
            "translation": "But speaking and writing '''is difficult'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къины"
    ],
    "cognate": "гугъу",
    "type": "adj",
    "ipa": "ħəɬa"
  },
  {
    "word": "гуры1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to understand",
        "examples": [
          {
            "sentence": "Ахэр дэгъоу '''къызгуры1ох'''",
            "translation": "'''I can understand''' them well."
          },
          {
            "sentence": "Къэс1орэ о '''къыбгуры1уа'''?",
            "translation": "'''Do you understand''' what I am saying?"
          },
          {
            "sentence": "Къэп1орэ сэ '''сыгуры1урэп'''",
            "translation": "'''I don't understand''' what you are saying."
          },
          {
            "sentence": "Адыгабзэ '''къыгуры1о''''",
            "translation": "'''I understand''' Circassian."
          },
          {
            "sentence": "'''Къызгуры1уагъэп''' п1уагъэр",
            "translation": "'''I did not understand''' what you said."
          },
          {
            "sentence": "Къас1орэр '''къыбгуры1ошъуа'''?",
            "translation": "'''Can you understand''' what I am saying."
          },
          {
            "sentence": "Математика '''къызгуры1ошъурэп'''",
            "translation": "'''I can't understand''' math."
          }
        ]
      }
    ],

    "cognate": "гуры1уэн",
    "type": "verb",
    "ipa": "ɡʷərəʔʷan"
  },
  {
    "word": "хэш1ык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэш1ык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хэш1ык1ын"
    ],
    "type": "verb",
    "ipa": "xaʃʼəkʲən"
  },
  {
    "word": "хэш1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have knowledge about some technical stuff or subject, to have an understanding about a certain subject",
        "examples": [
          {
            "sentence": "К1алэр машинэмэ '''яхеш1ык1ы'''",
            "translation": "The boy '''is familiar with''' cars."
          },
          {
            "sentence": "Докторым узхэмэ яхеш1ык1ы",
            "translation": "The doctor '''is familiar with''' the diseases."
          },
          {
            "sentence": "Докторым узхэмэ яхиш1ык1эгъ",
            "translation": "The doctor '''was familiar with''' the diseases."
          },
          {
            "sentence": "хэсэш1ык1ы сомпйутэрым",
            "translation": "'''I am familiar with''' the computer."
          }
        ]
      },
      {
        "meaning": "to feel",
        "examples": [
          {
            "sentence": "Синыбэк1э 1аеу '''хэсэш1ык1ы'''",
            "translation": "'''I feel''' awful with my stomach."
          },
          {
            "sentence": "К1алэм чъы1эу '''хеш1ык1ы'''",
            "translation": "The boy '''is feeling''' cold."
          }
        ]
      }
    ],
    "synonyms": [
      "хэш1ык1ьын"
    ],
    "type": "verb",
    "ipa": "xaʃʼət͡ʃʼən"
  },
  {
    "word": "щыгъупшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to forget",
        "examples": [
          {
            "sentence": "'''Сыпщыгъупшэжьи''' ащ зыфэбгъэзагъ",
            "translation": "'''You forgot me''' and turned yourself to him."
          },
          {
            "sentence": "'''Усыщыгъупшэгъ''' хэт о?",
            "translation": "'''I forgot you''' who you are?"
          },
          {
            "sentence": "'''Сщыгъупшагъ'''",
            "translation": "'''I forgot'''."
          },
          {
            "sentence": "'''Сщыгъупшагъ'''",
            "translation": "'''I forgot'''."
          },
          {
            "sentence": "'''Къупщымгъупшыгъэу''' псы ешъу",
            "translation": "Drink water '''before you forget'''."
          },
          {
            "sentence": "'''Къэсщыгъупшэгъ''' псы къэсхьынэу",
            "translation": "'''I forgot''' to bring water."
          },
          {
            "sentence": "К1алэм '''щыгъупшэгъ''' тиунэ къэк1онэу",
            "translation": "The boy '''forgot''' to come our house."
          },
          {
            "sentence": "Л1ыжъым '''щыгъупшы''' сиц1э",
            "translation": "The old man '''forgets''' my name."
          },
          {
            "sentence": "'''Къушъущыгъупшыт''' шъуеджэжьнэу",
            "translation": "'''You will forget''' to read/study again. (said to plural)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəʁʷəpʃən"
  },
  {
    "word": "къыщинэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be late",
        "examples": [
          {
            "sentence": "Автобусым '''укъыщинагъа?'''",
            "translation": "'''Did you miss''' the bus?"
          },
          {
            "sentence": "К1алэр 1офым '''къыщинагъ'''",
            "translation": "The boy '''got late''' to work."
          },
          {
            "sentence": "Л1эр 1офым '''къыщенэ'''",
            "translation": "The man '''is becoming late''' to work."
          },
          {
            "sentence": "Л1ыжъэр 1офым '''къыщинэт'''",
            "translation": "The old man '''will be late''' to work."
          },
          {
            "sentence": "1офым '''укъыщымнэрагъомэ''' псынч1эу к1о",
            "translation": "'''If you don't want to late''' to work go fast."
          }
        ]
      },
      {
        "meaning": "to surpass someone by far",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''къыщинагъ'''",
            "translation": "The boy '''surpassed''' the girl '''by far'''."
          },
          {
            "sentence": "Псынк1эу умч1эмэ '''укъыщанэт'''",
            "translation": "If you don't run fast '''they will surpass you by far'''."
          },
          {
            "sentence": "Ливилк1э Томым '''сыкъыщинагъ'''",
            "translation": "Tom '''surpassed''' me with the levels '''by far'''."
          },
          {
            "sentence": "'''Укъыщэсанэ'''",
            "translation": "'''I am surpassing you by far'''."
          },
          {
            "sentence": "Йусыфым ишыхэмэ '''къышани''' тыгъужъым ишхэгъ",
            "translation": "Joseph's brothers '''surpassed him by far''' and as a result the wolf ate him."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəɕinan"
  },
  {
    "word": "дырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swallow",
        "examples": [
          {
            "sentence": "Уцы такъыр '''дыр'''",
            "translation": "'''Swallow''' a pill."
          },
          {
            "sentence": "Уцы такъыр '''къэсфэдырэп'''",
            "translation": "'''I can't swallow''' a pill."
          },
          {
            "sentence": "К1алэм уцы такъыр '''едыры'''",
            "translation": "The boy '''is swallowing''' a pill."
          }
        ]
      },
      {
        "meaning": "to devour",
        "examples": [
          {
            "sentence": "Иныжъым '''удырыщт'''",
            "translation": "The giant '''will devour you'''."
          },
          {
            "sentence": "Мэзым чэщэу ухахьэмэ тыгъужъмэ '''уадырыщт'''",
            "translation": "If you enter the forest in night the wolfs '''will devour you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dərən"
  },
  {
    "word": "1ук1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to meet",
        "examples": [
          {
            "sentence": "Неущ '''тызэ1ук1эта?'''",
            "translation": "'''Will we meet''' tomorrow?"
          },
          {
            "sentence": "К1алэм пшъашъэр '''1ук1агъ'''",
            "translation": "The boy '''met''' the girl."
          },
          {
            "sentence": "Пшъашъэр '''къэс1ук1агъ'''",
            "translation": "I '''met''' the girl."
          },
          {
            "sentence": "Л1ым итхьэмамэ '''1ок1э'''",
            "translation": "The man '''is meeting''' with his boss."
          },
          {
            "sentence": "Докторым '''сы1ук1эшъутэп'''",
            "translation": "'''I can't meet''' the doctor."
          },
          {
            "sentence": "Докторым '''сы1ук1эшъурэп'''",
            "translation": "'''I can't meet''' the doctor. (Present)"
          }
        ]
      },
      {
        "meaning": "to overtake, to sidestep, To catch up with someone but not pass",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''1ук1агъ'''",
            "translation": "The boy '''caught up''' with the girl."
          },
          {
            "sentence": "Нэхь псынч1эу сычъэмэ '''ус1ук1эт'''",
            "translation": "If i will run faster '''i will overtake''' you."
          },
          {
            "sentence": "К1элэц1ык1ор '''къэс1ук1эшъурэп'''",
            "translation": "The little boy '''can't catch up''' with me."
          },
          {
            "sentence": "Ливилк1э Томэр '''къэс1ук1агъ'''",
            "translation": "Tom '''caught up''' with me with the level."
          },
          {
            "sentence": "Ливилк1э Томым '''с1ук1агъ'''",
            "translation": "'''I caught up''' with Tom with the level."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷət͡ʃan"
  },
  {
    "word": "тэчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to surpass",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''тэчъэгъ'''",
            "translation": "The boy '''surpassed''' the girl."
          },
          {
            "sentence": "Псынч1эу умч1эмэ '''къуптэчъытых'''",
            "translation": "If you don't run fast '''they will surpass you'''."
          },
          {
            "sentence": "Ливилк1э Томэр '''къэстэчъы'''",
            "translation": "Tom '''is surpassing''' me with the levels."
          },
          {
            "sentence": "'''Къэстэчъы'''",
            "translation": "'''Surpass me'''."
          },
          {
            "sentence": "Тестыхымк1э пшъашъэмэ '''сатэчъыт'''",
            "translation": "'''I will surpass''' the girls in the tests."
          },
          {
            "sentence": "К1элэц1ык1ор '''къэстэчъышъурэп'''",
            "translation": "The little boy '''can't surpass me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tat͡ʂən"
  },
  {
    "word": "1ук1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эхьан\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эхьан"
    ],
    "type": "verb",
    "ipa": "ʔʷət͡ʃəħaːn"
  },
  {
    "word": "зыгъэпсэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rest, to take a break",
        "examples": [
          {
            "sentence": "Л1ым '''зегъэпсэфы''' 1офым икъынэужь",
            "translation": "The man '''is resting''' after work."
          },
          {
            "sentence": "Седжэгъахэмэ т1эк1у '''зысгъэпсэфынэу''' щагум сыкъик1ыщт",
            "translation": "After I have finished studying, I will come out to the yard '''to rest''' briefly."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəʁapsafən"
  },
  {
    "word": "псы1ушъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʔʷəʃʷa"
  },
  {
    "word": "т1эк1угъаш1эм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soon",
        "examples": [
          {
            "sentence": "'''т1эк1угъаш1эмэ''' сышхэт",
            "translation": "I will eat '''soon'''."
          },
          {
            "sentence": "'''т1эк1угъаш1эмэ''' филмэр къиулэт",
            "translation": "'''Soon''' the film will start."
          },
          {
            "sentence": "'''т1эк1угъаш1эм'''",
            "translation": "'''soon'''."
          }
        ]
      }
    ],
    "synonyms": [
      "т1эк1уш1эмэ"
    ],
    "type": "adv",
    "ipa": "tʼakʷʼʁaːʃʼam"
  },
  {
    "word": "уцуп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A stopping place, station",
        "examples": [
          {
            "sentence": "Автобус '''уцуп1эм''' дэжь сыкъыплъы1ухьащт",
            "translation": "I will pick you up at the bus '''stop'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wət͡sʷəpʼam"
  },
  {
    "word": "щык1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to need something required",
        "examples": [
          {
            "sentence": "Такси '''сищык1эт'''",
            "translation": "'''I will need''' a taxi."
          },
          {
            "sentence": "Хьанцэ '''ущэк1э'''",
            "translation": "'''you need''' a shovel."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕət͡ʃʼan"
  },
  {
    "word": "къухьэуцуп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "harbor",
        "examples": [
          {
            "sentence": "'''Къухьэуцуп1эм''' шъук1у",
            "translation": "Go to the harbour / harbor (am.)"
          }
        ]
      }
    ],
    "synonyms": [
      "бэгъуаз",
      "кутлэ"
    ],
    "cognate": "кхъухьтедзап1э",
    "type": "noun",
    "ipa": "qʷəħawt͡sʷəpʼa"
  },
  {
    "word": "щэчанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tower",
        "examples": [
          {
            "sentence": "Мо '''щэчанэр''' олъэгъуа?",
            "translation": "Do you see the '''tower''' there?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕat͡ʃaːna"
  },
  {
    "word": "хьалэмэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tower",
        "examples": [
          {
            "sentence": "СызэреплъырэмкIэ, ар '''хьалэмэт'''",
            "translation": "I find that '''gorgeous'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ħaːlamat"
  },
  {
    "word": "теплъаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly, freaky",
        "examples": [
          {
            "sentence": "СызэреплъырэмкIэ, ар '''теплъаджэ'''",
            "translation": "I find that '''ugly'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "tepɬaːd͡ʒa"
  },
  {
    "word": "зэщыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boring",
        "examples": [
          {
            "sentence": "СызэреплъырэмкIэ, ар '''зэщыгъо'''",
            "translation": "I find that '''boring'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zaɕəʁʷa"
  },
  {
    "word": "гомыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "terrible",
        "examples": [
          {
            "sentence": "СызэреплъырэмкIэ, ар '''гомыхь'''",
            "translation": "I find that '''terrible'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɡʷaməħ"
  },
  {
    "word": "пчэдыжьышхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breakfast",
        "examples": [
          {
            "sentence": "Сыхьатыр тхьапшым '''пчэдыжьышхэ''' арагъэшIыра?",
            "translation": "What time do you serve '''breakfast'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃadəʑəʃxa"
  },
  {
    "word": "щэджэгъуашхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "launch",
        "examples": [
          {
            "sentence": "Сыхьатыр тхьапшым '''щэджэгъуашхэ''' арагъэшIыра?",
            "translation": "What time do you serve '''launch'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕad͡ʒaʁʷaːʃxa"
  },
  {
    "word": "пчыхьэшъхьашхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dinner for evening",
        "examples": [
          {
            "sentence": "Сыхьатыр тхьапшым '''пчыхьэшъхьашхэ''' арагъэшIыра?",
            "translation": "What time do you serve '''dinner for evening'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃəħaʂħaːʃxa"
  },
  {
    "word": "гъэцэк1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to repair",
        "examples": [
          {
            "sentence": "Мыр '''бгъэцэк1эжьын''' плъэк1ыщтба?",
            "translation": "Can you get it '''repaired'''?"
          },
          {
            "sentence": "Сыхьатыр '''бгъэцэкIэжьын''' плъэкIыщта?",
            "translation": "Can '''you fix''' the clock?"
          }
        ]
      }
    ],
    "synonyms": [
      "гъэтэрэзыжьын",
      "гъэтэрэзын",
      "ш1ыжьын"
    ],
    "type": "verb",
    "ipa": "ʁat͡sat͡ʃaʑən"
  },
  {
    "word": "щыбырысыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noisy",
        "examples": [
          {
            "sentence": "Унэм '''щыбырысыр''' дэд",
            "translation": "The room is too '''noisy'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɕəbərəsər"
  },
  {
    "word": "гъэфэбап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heater",
        "examples": [
          {
            "sentence": "'''ГъэфэбапIэм''' мэлажэрэп",
            "translation": "The '''heater''' isn’t working."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁafabaːpʼa"
  },
  {
    "word": "жьыгъэкъабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air-conditioning",
        "examples": [
          {
            "sentence": "'''Жьыгъэкъабзэ''' мэлажэрэп",
            "translation": "The '''air-conditioning''' isn’t working."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʑəʁaqaːbza"
  },
  {
    "word": "лъэп1а1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very expensive",
        "examples": [
          {
            "sentence": "Ар сэркIэ '''лъэп1а1о'''",
            "translation": "That’s '''too expensive''' for me."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬapʼaːʔʷa"
  },
  {
    "word": "щаибжъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tea",
        "examples": []
      }
    ],
    "synonyms": [
      "щае"
    ],
    "type": "noun",
    "ipa": "ɕaːjəbʐa"
  },
  {
    "word": "былымылы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beef",
        "examples": [
          {
            "sentence": "'''Былымылыр''' уикIаса?",
            "translation": "Do you like '''beef'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bələmələ"
  },
  {
    "word": "къолы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pork",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷalə"
  },
  {
    "word": "гохьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tasty (having a pleasant flavor)",
        "examples": [
          {
            "sentence": "Мыр '''гохьэп'''",
            "translation": "That '''doesn’t taste good'''."
          }
        ]
      },
      {
        "meaning": "pleasant, charming",
        "examples": [
          {
            "sentence": "Мэ '''гохь'''",
            "translation": "'''pleasant''' smell."
          },
          {
            "sentence": "Ц1ыф '''гохь'''",
            "translation": "'''pleasant''' person."
          },
          {
            "sentence": "'''Гохьэу''' мэщхы",
            "translation": "laughs '''pleasantly'''."
          },
          {
            "sentence": "Мыр '''гохьэп'''",
            "translation": "That '''doesn’t taste good'''."
          }
        ]
      },
      {
        "meaning": "lovely person, likable person",
        "examples": []
      }
    ],
    "synonyms": [
      "го1у"
    ],
    "type": "adj",
    "ipa": "ɡʷaħə"
  },
  {
    "word": "сымэджэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hospital",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "səmad͡ʒaɕ"
  },
  {
    "word": "упсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shave",
        "examples": [
          {
            "sentence": "К1алэм мэлыр '''еупсы'''",
            "translation": "The boy '''is shaving''' the sheep."
          },
          {
            "sentence": "Мэлыр '''упсы'''",
            "translation": "'''Shave''' the sheep."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəpsən"
  },
  {
    "word": "стырыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soup",
        "examples": [
          {
            "sentence": "Сэ '''стырыпс''' сыфай",
            "translation": "I would like a '''soup'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "stərəps"
  },
  {
    "word": "пк1ышъхьэмышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fruit",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэшъхьэмышъхь",
      "къуе-мый"
    ],
    "type": "noun",
    "ipa": "pt͡ʃəʂħaməʂħa"
  },
  {
    "word": "хьаумэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "if not, else if",
        "examples": [
          {
            "sentence": "О электричества '''хьаумэ''' гьаза узэрыпщэрыхьэрэр?",
            "translation": "Do you cook on an electric '''or''' a gas stove?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ħaːwma"
  },
  {
    "word": "нэкулъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sausage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʷəɬ"
  },
  {
    "word": "к1энк1эжъапхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "omelette",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃant͡ʃaʐaːpχa"
  },
  {
    "word": "къэбэскъэ ш1о1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sauerkraut",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabasqa ʃʷʼaʔʷ"
  },
  {
    "word": "т1ысып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seat",
        "examples": [
          {
            "sentence": "Енэгуягъо, мы '''т1ысып1эр''' сэсый",
            "translation": "I think this is my '''seat'''."
          },
          {
            "sentence": "Енэгуягъо, сэ '''сит1ысып1э''' утес}} I think you’re sitting in '''my seat'''.",
            "translation": ""
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "tʼəsəpʼa"
  },
  {
    "word": "гъунапкъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "border",
        "examples": []
      },
      {
        "meaning": "boundary, border, borderline, frontier (the line or frontier area separating regions)",
        "examples": []
      }
    ],
    "synonyms": [
      "гъунэ"
    ],
    "cognate": "нэз",
    "type": "noun",
    "ipa": "ʁʷənaːpqa"
  },
  {
    "word": "къэгужъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to linger",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaɡʷəʒʷan"
  },
  {
    "word": "лъасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "base",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɬaːsa"
  },
  {
    "word": "пцыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ski",
        "examples": []
      },
      {
        "meaning": "ski",
        "examples": []
      },
      {
        "meaning": "Snow shoe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡səqʷ"
  },
  {
    "word": "гузэжъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be in a hurry",
        "examples": [
          {
            "sentence": "К1алэр '''мэгузажъо'''",
            "translation": "The boy '''is in hurry'''."
          },
          {
            "sentence": "'''Умыгузажъу'''",
            "translation": "'''Don't hurry'''."
          },
          {
            "sentence": "Ащ 1оф и1, '''гузэжъон''' фае",
            "translation": "He has work, he needs '''to hurry up'''."
          }
        ]
      },
      {
        "meaning": "to be anxious (about), to be uneasy (about)",
        "examples": [
          {
            "sentence": "Машинэм пае '''угузэжъон''' фае",
            "translation": "You need '''to be anxious''' about the car."
          }
        ]
      }
    ],
    "synonyms": [
      "тхъытхъын",
      "ш1эхын",
      "хъыжъэн"
    ],
    "cognate": "гузэвэн",
    "type": "verb",
    "ipa": "ɡʷəzaʒʷan"
  },
  {
    "word": "т1эк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a bit (few)",
        "examples": [
          {
            "sentence": "К1алэм шхын '''т1эк1у''' ышхыгъэ",
            "translation": "The boy ate '''a bit of''' food."
          },
          {
            "sentence": "К1алэм '''т1эк1у''' тхылъэу и1э",
            "translation": "The boy have '''a bit of''' books."
          },
          {
            "sentence": "Шъоущыгъу '''т1эк1у''' къыхалъхь и шае",
            "translation": "Put '''a bit of''' sugar in my tea."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "tʼakʷʼ"
  },
  {
    "word": "к1ык1ьай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fast",
        "examples": []
      }
    ],
    "synonyms": [
      "псык1э",
      "псынк1э"
    ],
    "cognate": "псынщ1э",
    "type": "adj",
    "ipa": "t͡ʃəkʲaːj"
  },
  {
    "word": "бензин игъэхъоп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gas station",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "benzin jəʁaχʷapʼa"
  },
  {
    "word": "мэухын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run out, to get finished",
        "examples": [
          {
            "sentence": "Бензиныр '''къыухыгъ'''",
            "translation": "The gas '''ran out'''."
          },
          {
            "sentence": "Mыехэр '''мэухыгъэх'''",
            "translation": "The apples '''ran out'''."
          },
          {
            "sentence": "Уахътэр '''мэухы'''",
            "translation": "The time '''is running out'''."
          },
          {
            "sentence": "Уахътэр '''мэухыт'''",
            "translation": "The time '''will run out'''."
          },
          {
            "sentence": "Фылымэ '''мэухэгъ'''",
            "translation": "The film '''is over'''."
          },
          {
            "sentence": "Фылымэ '''мэухымэ''' тыгъэк1ожь",
            "translation": "'''When''' the film '''is over''' let's return."
          },
          {
            "sentence": "Псэр '''мэухы'''",
            "translation": "The water '''is running out'''."
          },
          {
            "sentence": "Колэ '''мэухыгъэп'''",
            "translation": "The cola '''didn't run out'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawxən"
  },
  {
    "word": "гъэцэк1эжьып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁat͡sət͡ʃaʑəpʼa"
  },
  {
    "word": "зэутэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bump between each other",
        "examples": [
          {
            "sentence": "Машинэхэр '''зэутэк1ыгъэх'''",
            "translation": "The cars '''clashed'''."
          },
          {
            "sentence": "Чъы1э сыл1эмэ, сцэхэр '''зэутэк1ых'''",
            "translation": "When I am cold, my teeth '''are clashing'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zawtat͡ʃən"
  },
  {
    "word": "еутэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bump into, to knock (against), to bump (against)",
        "examples": [
          {
            "sentence": "К1алэм '''сеутэк1эгъ'''",
            "translation": "'''I bumped into''' the boy."
          },
          {
            "sentence": "псынк1эу учъымэ к1алэм '''уеутэк1ыт'''",
            "translation": "If you run fast '''you will bump into''' the boy."
          },
          {
            "sentence": "Къэуцу! дэпкъым '''уеутэк1ы'''",
            "translation": "Stop! '''You are bumping into''' the wall."
          },
          {
            "sentence": "К1алэр '''еутэк1эгъ'''",
            "translation": "The boy '''clashed'''."
          }
        ]
      }
    ],
    "synonyms": [
      "еутэк1ьын"
    ],
    "type": "verb",
    "ipa": "jawtat͡ʃʼən"
  },
  {
    "word": "еутэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еутэк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "еутэк1ын"
    ],
    "type": "verb",
    "ipa": "jawtakʲʼən"
  },
  {
    "word": "1эпы1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "help; aid; assistance",
        "examples": [
          {
            "sentence": "'''1эпы1эгъоу''' укъысфэхъун плъэк1ыщта?",
            "translation": "Can you '''help''' me?"
          },
          {
            "sentence": "К1алэм еджэнымк1э '''1эпы1эгъоу''' фэхъу'''",
            "translation": "'''Help''' the boy with the studies."
          },
          {
            "sentence": "'''1эпы1эгъоу''' сыгорэ къэсфэхъущта?",
            "translation": "'''Can''' someone '''help me'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔapəʔaʁʷ"
  },
  {
    "word": "ет1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and then",
        "examples": [
          {
            "sentence": "Шхи '''ет1анэ''' еджап1эм к1о",
            "translation": "Eat '''and then''' go to school."
          },
          {
            "sentence": "'''Ет1анэ''' джабгъумк1э жъугъази метришъэ шъук1у",
            "translation": "'''And then''' go right for a hundred meters."
          }
        ]
      }
    ],
    "synonyms": [
      "ет1уанэ"
    ],
    "type": "adv",
    "ipa": "jatʼaːna"
  },
  {
    "word": "гъозэнэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "traffic light",
        "examples": [
          {
            "sentence": "Ящэнэрэ '''гъозэнэфым''' нэсэ шъук1у",
            "translation": "Drive until you reach the third '''traffic light'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷazanafə"
  },
  {
    "word": "чылысы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cathedral",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃələsə"
  },
  {
    "word": "бэдзэры",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэдзэр\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bad͡zarə"
  },
  {
    "word": "къэлэсэраи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэлэсэрай\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qalasaraːjə"
  },
  {
    "word": "ч1эхьапк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "entrance",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaħaːpt͡ʃa"
  },
  {
    "word": "купэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"куп\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəpa"
  },
  {
    "word": "пэбжъакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rhino",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pabʐaːqʷa"
  },
  {
    "word": "щагуик1ып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toilet, restroom",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaːɡʷit͡ʃəpʼa"
  },
  {
    "word": "джыри",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "again",
        "examples": [
          {
            "sentence": "'''джыри''' ощхыр къыублагъ",
            "translation": "it began to rain '''again'''."
          },
          {
            "sentence": "ар '''джыри''' къэгужъуагъ",
            "translation": "he was late '''again'''."
          },
          {
            "sentence": "'''джыри''' тхылъит1у ыштагъ",
            "translation": "he took two books '''again'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒəri"
  },
  {
    "word": "къэгъэлъэгъоны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "show",
        "examples": [
          {
            "sentence": "'''Къэгъэлъэгъоныр''' сыдигъуа зырагъажьэрэр?",
            "translation": "When does '''the show''' begin?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaʁaɬaʁʷanə"
  },
  {
    "word": "есып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pool",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jasəpʼa"
  },
  {
    "word": "лъэшы1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very loud",
        "examples": [
          {
            "sentence": "Т1эк1у '''лъэшы1о'''",
            "translation": "A little '''too loud'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬaʃəʔʷa"
  },
  {
    "word": "т1эк1уш1эмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1эк1угъаш1эм\"",
        "examples": []
      }
    ],
    "synonyms": [
      "т1эк1угъаш1эм"
    ],
    "type": "adv",
    "ipa": "tʼakʷʼːʃʼama"
  },
  {
    "word": "егъэлъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to show",
        "examples": [
          {
            "sentence": "Сэ '''къыозгъэлъэгъущт'''",
            "translation": "'''I’ll show you'''."
          },
          {
            "sentence": "К1алэм ышыпхъу фылым '''регъэлъэгъу'''",
            "translation": "The boy '''shows''' his sister the film."
          },
          {
            "sentence": "'''Егъэлъэгъу''' гъогор",
            "translation": "'''Show''' him the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁaɬaʁʷən"
  },
  {
    "word": "пэплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wait",
        "examples": [
          {
            "sentence": "Зыгорэм '''упэплъа?'''",
            "translation": "'''Are you waiting''' for someone?"
          },
          {
            "sentence": "К1алэм пшъашъым '''паплъэ'''",
            "translation": "The boy '''is waiting''' for the girl."
          },
          {
            "sentence": "'''Шъукъэспаплъ'''",
            "translation": "'''Wait for me''' (said to plural)."
          },
          {
            "sentence": "К1алэм '''cпэплъэрагъоп'''",
            "translation": "'''I don't want to wait''' for the boy."
          },
          {
            "sentence": "К1алэм '''упэмыплъ'''",
            "translation": "'''Don't wait''' for the boy."
          },
          {
            "sentence": "К1алэр '''пэплъагъ''' Томым, пшъашъэр '''пэплъэт''' Дэянэм",
            "translation": "The boy '''waited''' for Tom, the girl '''will wait''' for Diana."
          }
        ]
      }
    ],
    "synonyms": [
      "ежэн"
    ],
    "type": "verb",
    "ipa": "papɬan"
  },
  {
    "word": "гъогурыгъуазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "travel guide",
        "examples": [
          {
            "sentence": "'''Гъогурыгъуазэр''' зыдапштэ пшIоигъуа?",
            "translation": "Do you want to take a '''travel guide'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaɡʷrəʁʷaːza"
  },
  {
    "word": "гъончэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pants",
        "examples": []
      }
    ],
    "synonyms": [
      "гъошэгь"
    ],
    "cognate": "гъуэншэдж",
    "type": "noun",
    "ipa": "ʁʷant͡ʃad͡ʒ"
  },
  {
    "word": "к1ак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "felt cloak",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaːkʷʼa"
  },
  {
    "word": "щазымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boot",
        "examples": []
      }
    ],
    "synonyms": [
      "щызымэ"
    ],
    "type": "noun",
    "ipa": "ɕaːzəma"
  },
  {
    "word": "щырыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoe",
        "examples": []
      }
    ],
    "synonyms": [
      "цуакъэ"
    ],
    "type": "noun",
    "ipa": "ɕərəqʷ"
  },
  {
    "word": "1эплъэк1жъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handkerchiefs",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔapɬat͡ʃʐəja"
  },
  {
    "word": "1эбжъэнэпыупк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nail clipper",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔabʐanapəwpt͡ʃə"
  },
  {
    "word": "шъхьэмажьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comb",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħamaːʑa"
  },
  {
    "word": "цэлъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toothbrush",
        "examples": []
      }
    ],
    "synonyms": [
      "цэлъэк1алъэ"
    ],
    "cognate": "дзэлъэщ1",
    "type": "noun",
    "ipa": "t͡saɬat͡ʃ"
  },
  {
    "word": "зыгъэпсык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыгъэпск1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "зыгъэпсык1ьын"
    ],
    "type": "verb",
    "ipa": "zʁapst͡ʃʼən"
  },
  {
    "word": "зыгъэпсык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыгъэпсык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "зыгъэпсык1ын"
    ],
    "type": "verb",
    "ipa": "zʁapskʲən"
  },
  {
    "word": "к1асэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "late",
        "examples": [
          {
            "sentence": "'''КIасэу''' сыкъэкIожьы сшIоигъоп",
            "translation": "I don’t want to arrive '''late'''."
          },
          {
            "sentence": "'''КIасэ''' хъугъэ",
            "translation": "It is already '''late'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1ьасэ"
    ],
    "type": "adj",
    "ipa": "t͡ʃʼaːsa"
  },
  {
    "word": "фит",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "permission, authorization, approval",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "fit"
  },
  {
    "word": "шъхьаф-шъхьафэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "separately",
        "examples": [
          {
            "sentence": "'''Шъхьаф-шъхьафэу''' ыпкIэ къэтты хъущта?",
            "translation": "May we pay '''separately'''?"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʂħaːf ʂħaːfaw"
  },
  {
    "word": "мэпшъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пшъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mapʂən"
  },
  {
    "word": "к1ежьэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to return",
        "examples": [
          {
            "sentence": "'''СызыкIежьэжьыгъэр''' кIасэ хъугъэшъ ары",
            "translation": "'''I’m going''' because it is already late."
          },
          {
            "sentence": "'''СызыкIежьэжьырэр''' сыпшъыгъэшъ ары",
            "translation": "'''I’m going''' because I’m tired."
          },
          {
            "sentence": "Сыд '''шъузыкIежьэжьырэр?'''",
            "translation": "Why '''are you going already'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷarəʃʼa"
  },
  {
    "word": "гъойщэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become unwell; to become slightly ill",
        "examples": [
          {
            "sentence": "'''Сыгъойщэягъ'''",
            "translation": "'''I became ill'''."
          },
          {
            "sentence": "'''Сыгъойщай'''",
            "translation": "'''I am ill'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷajɕajan"
  },
  {
    "word": "лымышх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vegetarian",
        "examples": [
          {
            "sentence": "Сэ '''сылымышх'''",
            "translation": "'''I am a vegetarian'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ləməʃx"
  },
  {
    "word": "щыгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be wearing",
        "examples": [
          {
            "sentence": "К1алэм джанэ '''щыгъ'''",
            "translation": "The boy '''is wearing''' a shirt."
          },
          {
            "sentence": "Джанэ '''къэсщыгъ'''",
            "translation": "'''I am wearing''' a shirt."
          },
          {
            "sentence": "К1алэм джанэ '''щыгъэп'''",
            "translation": "The boy '''is not wearing''' a shirt."
          },
          {
            "sentence": "Гъончэджэр '''щыгъ'''",
            "translation": "The pants '''is on him'''."
          },
          {
            "sentence": "К1алэмэ яджанэхэр '''ящыгъэх'''",
            "translation": "The boys '''are wearing''' their shirts."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəʁan"
  },
  {
    "word": "щылъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wear a",
        "examples": [
          {
            "sentence": "К1алэм джанэ '''щелъэ'''",
            "translation": "The boy '''is wearing''' a shirt."
          },
          {
            "sentence": "Джанэ '''щэсэлъэ'''",
            "translation": "'''I am wearing''' a shirt."
          },
          {
            "sentence": "К1алэм джанэ '''щилъэрэп'''",
            "translation": "The boy '''is not wearing''' a shirt."
          },
          {
            "sentence": "Джанэ джадэр '''щыслъэшъурэп'''",
            "translation": "'''I can't wear''' the big shirt."
          },
          {
            "sentence": "Гъончэджэр '''щылъ'''",
            "translation": "'''Wear''' the pants."
          },
          {
            "sentence": "К1алэмэ яджанэхэр '''щалъэх'''",
            "translation": "The boys '''are wearing''' their shirts."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəɬan"
  },
  {
    "word": "ч1ырыгъыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dive",
        "examples": [
          {
            "sentence": "'''Ч1ырыгъыс''' уеш1а?",
            "translation": "Can you '''dive'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼərəʁəs"
  },
  {
    "word": "нэгъунджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eyeglasses",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʁʷənd͡ʒa"
  },
  {
    "word": "чъы1э л1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be cold, to freeze from cold",
        "examples": [
          {
            "sentence": "Чэщым удэк1мэ '''чъы1э ул1эт'''",
            "translation": "If you go out at night '''you will be cold'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃəʔa ɬʼan"
  },
  {
    "word": "чъы1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cold",
        "examples": []
      }
    ],

    "cognate": "щ1ы1э",
    "type": "noun",
    "ipa": "t͡ʂəʔa"
  },
  {
    "word": "чъы1а1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very cold",
        "examples": [
          {
            "sentence": "Псыр '''чъы1а1о'''.",
            "translation": "The water is '''too cold'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡ʂəʔaːʔʷa"
  },
  {
    "word": "мэтэкъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crumble",
        "examples": [
          {
            "sentence": "Хьалыгъор '''мэтакъо'''",
            "translation": "The bread is '''crumbling'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mataqʷan"
  },
  {
    "word": "мэпхъэпхъэн",
    "lang": "Adyghe",
    "definitions": [],
    "type": "verb",
    "ipa": "mapχapχan"
  },
  {
    "word": "тхылъ1ыгъып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "library",
        "examples": [
          {
            "sentence": "'''Тхылъ1ыгъып1эм''' сык1онэу сыфай",
            "translation": "I want to go to the '''library'''."
          },
          {
            "sentence": "'''Тхылъ1ыгъып1эм''' сык1онышъ, тхылъ къисхынэу сыфай",
            "translation": "I want to go to '''the library''' to borrow a book."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "txəɬʔəʁəpʼa"
  },
  {
    "word": "гъэзет",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "newspaper",
        "examples": [
          {
            "sentence": "Сэ '''гъэзет''' сщэфынэу сыфай",
            "translation": "I want to buy '''a newspaper'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁazjat"
  },
  {
    "word": "гъэзетщап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "newspaper stand",
        "examples": [
          {
            "sentence": "'''Гъэзетщап1эм''' сык1онышъ, гъэзет къэсщэфынэу сыфай.",
            "translation": "I want to go to the '''newspaper stand''' to buy a newspaper."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁazjatɕaːpʼa"
  },
  {
    "word": "нэ1азэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "optician",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʔaːza"
  },
  {
    "word": "хьалыгъущап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bakery",
        "examples": [
          {
            "sentence": "'''Хьалыгъущап1эм''' сык1онэу сыфай",
            "translation": "I want to go to '''the bakery'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaːləʁʷɕaːpʼa"
  },
  {
    "word": "хьалыжъощыхьагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "roll",
        "examples": [
          {
            "sentence": "'''Хьалыжъощыхьагъэхэмрэ''' хьалыгъурэ къэсщэфынэу сыфай.",
            "translation": "I want to buy '''rolls''' and bread."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaːləʐʷaɕəħaːʁa"
  },
  {
    "word": "офис псэуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "office supple",
        "examples": [
          {
            "sentence": "'''Офис псэуалъэхэр''' тыдэ щыIэха?",
            "translation": "Where are the '''office supplies'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔofic psawaːɬa"
  },
  {
    "word": "унэгъо псэуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "furniture",
        "examples": [
          {
            "sentence": "'''Унэгъо псэуалъэхэр''' тыдэ щы1эха?",
            "translation": "Where is '''the furniture'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wnaʁʷa psawaːɬa"
  },
  {
    "word": "нысхъапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "doll",
        "examples": [
          {
            "sentence": "'''Нысхъапэрэ''' мышъэ шъабэрэ сищык1агъэх.",
            "translation": "I need '''a doll''' and a teddy bear."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nəsχaːpa"
  },
  {
    "word": "мышъэ шъабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "teddy bear",
        "examples": [
          {
            "sentence": "Нысхъапэрэ '''мышъэ шъабэрэ''' сищык1агъэх.",
            "translation": "I need a doll and '''a teddy bear'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "məʂa ʂaːba"
  },
  {
    "word": "хьэшъотоп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "football",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʂʷatop"
  },
  {
    "word": "1эмэпсымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tool",
        "examples": [
          {
            "sentence": "'''1эмэ-псымэхэр''' тыдэ щы1эха?",
            "translation": "Where are '''the tools'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔamapsəma"
  },
  {
    "word": "бырыугъэчэрэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drill",
        "examples": [
          {
            "sentence": "'''Бырыугъэчэрэгъурэ''' джамбырыугъазэрэ сищыкIагъ",
            "translation": "I need a '''drill''' and a screwdriver."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bərəwʁat͡ʃaraʁʷ"
  },
  {
    "word": "джамбырыугъазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "screwdriver",
        "examples": [
          {
            "sentence": "Бырыугъэчэрэгъурэ '''джамбырыугъазэрэ''' сищыкIагъ",
            "translation": "I need a drill and a '''screwdriver'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːmbərəwʁaːza"
  },
  {
    "word": "джамбырыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "screw",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːmbərəw"
  },
  {
    "word": "уц 1эзэгъу щап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pharmacy, drugstore",
        "examples": [
          {
            "sentence": "'''Уц 1эзэгъу щап1эм''' тылъэхъу",
            "translation": "We’re looking for a pharmacy / drugstore"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wət͡s ʔazaʁʷ ɕaːpʼa"
  },
  {
    "word": "уц 1эзэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "medicine",
        "examples": [
          {
            "sentence": "'''Уц 1эзэгъухэр''' тщэфы тш1оигъуагъ",
            "translation": "We want to buy '''medicine'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wət͡s ʔazaʁʷ"
  },
  {
    "word": "дышъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jeweler",
        "examples": [
          {
            "sentence": "Сэ '''дышъэк1''' сылъэхъу",
            "translation": "I’m looking for a '''jeweler'''."
          },
          {
            "sentence": "'''Дышъэк1''' сылъэхъу, 1алъын сщэфыным пае",
            "translation": "I’m looking for a '''jeweler''' to buy a ring."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "dəʂat͡ʃ"
  },
  {
    "word": "1аш1у 1уш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1аш1у-1уш1у\"",
        "examples": []
      }
    ],
    "synonyms": [
      "1аш1у-1уш1у"
    ],
    "type": "noun",
    "ipa": "ʔaːʃʷʼə ʔʷəʃʷʼ"
  },
  {
    "word": "1алъмэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bag, sack",
        "examples": []
      },
      {
        "meaning": "handbag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːɬmaq"
  },
  {
    "word": "хьаплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьаплъы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħapɬa"
  },
  {
    "word": "1эшъхьэтет",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boss, manager",
        "examples": [
          {
            "sentence": "Ар '''ти1эшъхьэтет'''",
            "translation": "That is '''our boss'''."
          }
        ]
      }
    ],
    "synonyms": [
      "л1ышъхьэ",
      "пащ"
    ],
    "type": "noun",
    "ipa": "ʔaʂħatajt"
  },
  {
    "word": "1офш1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "colleague",
        "examples": [
          {
            "sentence": "'''1офш1эгъу''' дэгъухэр си1эх",
            "translation": "I have nice '''colleagues'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafʃʼaʁʷ"
  },
  {
    "word": "1офш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "work; job",
        "examples": [
          {
            "sentence": "Сэ '''1офш1эн сылъэхъу'''",
            "translation": "I am looking for a '''job'''."
          },
          {
            "sentence": "Илъэс хъугъэ 1офш1эн зысымыгъотрэр",
            "translation": "It have already been a year since I couldn't find a '''job'''."
          }
        ]
      },
      {
        "meaning": "assignment, task",
        "examples": [
          {
            "sentence": "К1алэм '''и1офш1эн''' ыгъэцэк1агъ",
            "translation": "The boy committed '''his tasks'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafʃʼan"
  },
  {
    "word": "1офш1энынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unemployed",
        "examples": [
          {
            "sentence": "'''1офш1энынчъэу''' мы къэралыгъом исыр бэ дэд",
            "translation": "There are too many '''unemployed''' people in this country."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafʃʼanənt͡ʂa"
  },
  {
    "word": "зэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be bored",
        "examples": [
          {
            "sentence": "Ар '''мэзэщы'''",
            "translation": "He is '''bored'''."
          },
          {
            "sentence": "Ар '''зэщырэп'''",
            "translation": "He is '''not bored'''."
          },
          {
            "sentence": "'''Себыгъэзащы'''",
            "translation": "'''You bored me'''."
          },
          {
            "sentence": "К1алэм пшъашъэр '''регъэзащы'''",
            "translation": "The boy is '''boring''' the girl."
          },
          {
            "sentence": "К1алэм '''Серегъэзащы'''",
            "translation": "The boy '''is boring''' me."
          },
          {
            "sentence": "Л1ыжъхэмэ '''урагъэзэщыт'''",
            "translation": "The old men '''will bore''' you."
          }
        ]
      }
    ],

    "cognate": "зэшын",
    "type": "verb",
    "ipa": "zaɕən"
  },
  {
    "word": "мэлэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be hungry",
        "examples": [
          {
            "sentence": "Шъо '''шъумэлак1а'''?",
            "translation": "Are you '''hungry'''? (said to plural)"
          },
          {
            "sentence": "Шъо '''шъумэлак1эба'''?",
            "translation": "Aren’t you '''hungry'''? (said to plural)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "malat͡ʃan"
  },
  {
    "word": "псы фэл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be thirsty",
        "examples": [
          {
            "sentence": "Ахэр '''псы фэл1эх'''",
            "translation": "'''They are thirsty'''."
          },
          {
            "sentence": "Ахэр '''псы фал1эхэрэп'''",
            "translation": "'''They are not thirsty'''."
          }
        ]
      }
    ],
    "synonyms": [
      "псыфал1э л1эн"
    ],
    "type": "verb",
    "ipa": "psə faɬʼan"
  },
  {
    "word": "еблэгъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "waiting room",
        "examples": [
          {
            "sentence": "'''Еблэгъап1эм''' къыщысаж, хъущтмэ",
            "translation": "Please take a seat in the '''waiting room'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "jablaʁaːpʼa"
  },
  {
    "word": "шъхьэузы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "headache",
        "examples": [
          {
            "sentence": "'''Шъхьэузым''' бэрэ сегъэгумэкIы",
            "translation": "I often have '''headaches'''."
          },
          {
            "sentence": "А пшъашъэм '''шъхьэузым''' къысеты",
            "translation": "That girl gives me a '''headache'''."
          },
          {
            "sentence": "Узым '''шъхьэузым''' къысеты",
            "translation": "That illness gives me a '''headache'''."
          },
          {
            "sentence": "Чъы1эм '''шъхьэузым''' къуитыт",
            "translation": "The cold will give you a '''headache'''."
          },
          {
            "sentence": "'''Шъхьэузы''' си1",
            "translation": "I have a '''headache'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂħawəzə"
  },
  {
    "word": "ныбэузы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stomach ache",
        "examples": [
          {
            "sentence": "А пшъашъэм ишхын '''ныбэузы''' къысеты",
            "translation": "That girl's dinner gives me a '''stomach ache'''."
          },
          {
            "sentence": "Узым '''ныбэузы''' къысеты",
            "translation": "That illness gives me a '''stomach ache'''."
          },
          {
            "sentence": "'''Шъхьэузы''' си1",
            "translation": "I have a '''stomach ache'''."
          },
          {
            "sentence": "Загъорэ '''ныбэузым''' сегъэгумэкIы",
            "translation": "I sometimes have '''stomach aches'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nəbawəzə"
  },
  {
    "word": "лъыдэк1ое-лъыкъеох",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blood pressure",
        "examples": [
          {
            "sentence": "'''Уилъыдэк1ое-лъыкъеох''' дэгъу",
            "translation": "Your '''blood pressure''' is okay."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɬədakʷʼaja-ɬəqewax"
  },
  {
    "word": "тхыц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txət͡sʼa"
  },
  {
    "word": "лъэшы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strong, powerful",
        "examples": [
          {
            "sentence": "Ы1эхэр '''лъэшых'''",
            "translation": "His arms '''are strong'''."
          }
        ]
      },
      {
        "meaning": "great",
        "examples": [
          {
            "sentence": "Макъэр '''лъэшэу''' къэ1у",
            "translation": "The voice is heard '''greatly'''."
          },
          {
            "sentence": "К1алэр '''лъэшэу''' егупшысагъ",
            "translation": "The boy thought '''greatly'''."
          }
        ]
      },
      {
        "meaning": "excellent",
        "examples": []
      }
    ],

    "cognate": "лъэщ",
    "type": "adj",
    "ipa": "ɬaʃə"
  },
  {
    "word": "палътэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːɬtaw"
  },
  {
    "word": "осыл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snowman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wasəɬʼ"
  },
  {
    "word": "зэгъэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to learn",
        "examples": [
          {
            "sentence": "'''Зэгъаш1!'''",
            "translation": "'''Learn!'''."
          },
          {
            "sentence": "К1алэм Адыгэбзэр '''зэрегъаш1э'''",
            "translation": "The boy '''is learning''' the Adyghe language."
          },
          {
            "sentence": "'''Зэсэгъаш1э'''",
            "translation": "'''I am learning'''."
          },
          {
            "sentence": "'''Зэтэгъаш1э''' урысыбзэ",
            "translation": "'''We are learning''' the Russian language.."
          },
          {
            "sentence": "'''Зэшъогъаш1э''' шъупчъык1э",
            "translation": "'''You are learning''' to count. (said to plural)."
          },
          {
            "sentence": "'''Зэбгъэш1агъэ''' укъэшъонэ?",
            "translation": "'''Did you learned''' to dance?"
          },
          {
            "sentence": "К1алэм '''зыригъэш1агъ''' мэтхэнэ",
            "translation": "the boy '''learned''' to write."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʁaʃʼan"
  },
  {
    "word": "упч1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ask a question",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''еупч1ы''' сыд ыц1э",
            "translation": "The boy '''is asking''' the girl what is her name."
          },
          {
            "sentence": "'''усэупч1ы''' сыд уфае?",
            "translation": "'''I am asking you''' what you want?"
          },
          {
            "sentence": "Томэр '''къысэупч1эгъ''' шыдгъо сыдак1ы",
            "translation": "Tom '''asked''' me when i am going out."
          },
          {
            "sentence": "Пшъашъэм '''сеупч1ырагъу''' сырик1асэмэ",
            "translation": "'''I wanna ask''' the girl if she likes me."
          },
          {
            "sentence": "'''еупч1ы''' мэшхэтмэ",
            "translation": "'''Ask''' if he gonna eat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəpt͡ʃʼan"
  },
  {
    "word": "джэуап тын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reply, to answer",
        "examples": [
          {
            "sentence": "Пшъашъэм к1алэм '''джэуап ритыгъ'''",
            "translation": "The girl '''replied''' the boy."
          },
          {
            "sentence": "'''Джэуап къысэт''' сыд уфаемк1э?",
            "translation": "'''reply me''' in what you want?"
          },
          {
            "sentence": "Томэр '''джэуап''' к1алэм '''ритыгъэп''' сыдигъо дэк1ыщтых",
            "translation": "Tom '''didn't reply''' the boy about when they gonna go out."
          },
          {
            "sentence": "'''Джэуап къысэмыт!'''",
            "translation": "'''Don't reply me!'''."
          },
          {
            "sentence": "Пшъашъэм '''джэуап реты''' к1алэм ик1асэмэ",
            "translation": "The girl '''is replying''' the boy if she likes him."
          },
          {
            "sentence": "'''Джэуап естыщт''' шхэщтымэ",
            "translation": "'''I will answer him''' if he gonna eat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒawaːp tən"
  },
  {
    "word": "джэуап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "answer",
        "examples": [
          {
            "sentence": "Тестымк1э '''джэуап''' тэрэзэр къэшъугъэк1эхь.",
            "translation": "In the test circle the right '''answer'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пэгъодз"
    ],
    "type": "noun",
    "ipa": "d͡ʒawaːp"
  },
  {
    "word": "къэгъэк1эхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to circle",
        "examples": [
          {
            "sentence": "Тестымк1э джэуап тэрэзэр '''къэшъугъэк1эхь'''",
            "translation": "In the test circle the right answer."
          },
          {
            "sentence": "'''къэгъэк1эхь''' мэлхэр",
            "translation": "'''Circle''' the sheeps."
          },
          {
            "sentence": "'''къэсэгъэк1эхьы''' чъыгыхэр",
            "translation": "'''I circle''' the trees."
          },
          {
            "sentence": "К1алэм чъыгыхэр '''къегъэк1эхьы'''",
            "translation": "The boy '''circles''' the trees."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁat͡ʃaħən"
  },
  {
    "word": "псэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to live (To have permanent residence somewhere, to inhabit)",
        "examples": [
          {
            "sentence": "'''Узыщыпсэурэр''' Берлина?",
            "translation": "Do '''you live''' in Berlin?"
          },
          {
            "sentence": "Ары, '''сызыщыпсэурэр''' Берлин.",
            "translation": "Yes, '''I live''' in Berlin."
          },
          {
            "sentence": "Т1эк1у ш1агъа мыщ '''узыщыпсэурэр?'''",
            "translation": "Have '''you lived''' here long?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "psawən"
  },
  {
    "word": "пшъэшъэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "girlfriend",
        "examples": [
          {
            "sentence": "'''пшъэшъэгъу''' уи1а?",
            "translation": "Do you have a '''girlfriend'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "гъусэ пшъашъэ"
    ],
    "type": "noun",
    "ipa": "pʂaʂaʁʷ"
  },
  {
    "word": "хьазырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be ready",
        "examples": [
          {
            "sentence": "К1алэр дэк1нэу '''хьазыр'''.",
            "translation": "The boy '''is ready''' to go out."
          },
          {
            "sentence": "К1алэр дэхьэ '''сыхьазырэп'''.",
            "translation": "The boy still '''isn't ready'''."
          },
          {
            "sentence": "Мары '''сыхьазырыт'''.",
            "translation": "In just a moment '''I will be ready'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħaːzərən"
  },
  {
    "word": "гъэухьазырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something ready; to prepare",
        "examples": [
          {
            "sentence": "К1алэм дэк1ынэу '''зигъэухьазырыгъ'''",
            "translation": "The boy '''prepared''' to go out."
          },
          {
            "sentence": "К1алэм чэщдэс '''егъэухьазыры'''",
            "translation": "The boy '''preparing''' a party."
          },
          {
            "sentence": "Мары '''сызгъэухьазырыщт'''",
            "translation": "A moment, '''I will get myself prepared'''."
          },
          {
            "sentence": "К1алэм еджап1эм къыщи1ущтыхэр '''егъэухьазырых'''",
            "translation": "The boy '''prepares''' the things he gonna say in school."
          },
          {
            "sentence": "Тэ еджап1эм къыщыт1ощтыхэр '''тыгъэухьазырыгъэхэп'''",
            "translation": "We didn't '''prepare''' the things we gonna say in school."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħaːzərən"
  },
  {
    "word": "зыгъэухьазырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get ready",
        "examples": [
          {
            "sentence": "'''згъэухьазыр'''",
            "translation": "'''Get ready'''."
          },
          {
            "sentence": "К1алэм дэк1нэу '''зигъэухьазырэгъ'''",
            "translation": "The boy '''got ready''' to go out."
          },
          {
            "sentence": "'''зысгъэухьазыры''' сышхынэу",
            "translation": "'''I got ready''' to eat."
          },
          {
            "sentence": "К1алэр дэхьэ '''зегъэухьазыры'''",
            "translation": "The boy still '''getting ready'''."
          },
          {
            "sentence": "Мары '''зысзгъэухьазырыт'''",
            "translation": "'''I will get ready''' soon."
          },
          {
            "sentence": "'''зытгъэухьазыргъэп''' непэ",
            "translation": "'''We didn't got ready''' today."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħaːzərən"
  },
  {
    "word": "псэлъыхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boyfriend",
        "examples": [
          {
            "sentence": "Ау '''псэлъыхъо''' иI",
            "translation": "But she already has '''a boyfriend'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psaɬəχʷa"
  },
  {
    "word": "пэк1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pakʷʼa"
  },
  {
    "word": "шъхьафы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "different, separated",
        "examples": [
          {
            "sentence": "'''шъхьафы''' ар",
            "translation": "That's '''different'''."
          },
          {
            "sentence": "'''шъхьафы''' горэ къахь",
            "translation": "Bring something '''different'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʂħaːfə"
  },
  {
    "word": "к1эупхъухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take a haircut",
        "examples": [
          {
            "sentence": "Сшъхьац '''къысфыкIэупхъухьан''' плъэкIыщта?",
            "translation": "Can you '''cut''' my hair?"
          },
          {
            "sentence": "К1алэм '''к1иупхъухьагъ'''",
            "translation": "The boy '''took a haircut'''."
          },
          {
            "sentence": "'''к1эсыупхъухьат'''",
            "translation": "'''I will take a haircut'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃawpχʷəħaːn"
  },
  {
    "word": "дысы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "steep, precipitous",
        "examples": [
          {
            "sentence": "Бгы дыс",
            "translation": "Steep waist."
          }
        ]
      },
      {
        "meaning": "sharp, abrupt (offensive, critical, or acrimonious)",
        "examples": [
          {
            "sentence": "джэуап дыс",
            "translation": "Sharp answer."
          },
          {
            "sentence": "гущыIэ дыс",
            "translation": "Harsh word."
          }
        ]
      },
      {
        "meaning": "cheeky, naughty, rude",
        "examples": [
          {
            "sentence": "кIэлэцIыкIу '''дысых'''",
            "translation": "'''cheeky''' children"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "dəsə"
  },
  {
    "word": "1орыш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "well behaved",
        "examples": [
          {
            "sentence": "кIэлэцIыкIу '''IорышIэх'''",
            "translation": "'''well behaved''' child."
          }
        ]
      },
      {
        "meaning": "obedient, dutiful, a person that obeys",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔʷarəʃʼa"
  },
  {
    "word": "брацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hairy, furry",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "braːt͡sa"
  },
  {
    "word": "убэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shatter, to destroy (cannot be repaired anymore)",
        "examples": [
          {
            "sentence": "К1алэм анэр '''иубэтагъ'''",
            "translation": "The boy '''broke''' the table."
          },
          {
            "sentence": "Апчэр '''сэубатэ'''",
            "translation": "I am '''breaking''' the window."
          },
          {
            "sentence": "Унэр '''умыубат'''",
            "translation": "Don't '''destroy''' the house."
          },
          {
            "sentence": "К1алэр мащинэмк1э дафи мащинэр '''иубэтагъ'''",
            "translation": "The boy '''destroyed''' the car by crashing with/using it."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəbatan"
  },
  {
    "word": "псэлъыхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk a conversation with a potential romantic partner",
        "examples": [
          {
            "sentence": "К1алэр '''мэпсалъыхъо'''",
            "translation": "The boy '''is talking'''."
          },
          {
            "sentence": "К1алэр пшъашъэм '''дэпсалъыхъо'''",
            "translation": "The boy '''is talking with''' the girl."
          }
        ]
      },
      {
        "meaning": "to flirt",
        "examples": []
      }
    ],

    "cognate": "псэлъыхъун",
    "type": "verb",
    "ipa": "psaɬəχʷən"
  },
  {
    "word": "хъэхалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "graveyard",
        "examples": []
      }
    ],
    "synonyms": [
      "къэхалъэ"
    ],
    "type": "noun",
    "ipa": "χaxaːɬa"
  },
  {
    "word": "занк1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "right away; straight away",
        "examples": [
          {
            "sentence": "'''Занк1эу''' к1о",
            "translation": "Go '''right away'''."
          },
          {
            "sentence": "К1алэр '''занк1эу''' еджагъэ",
            "translation": "The boy studied''' right away'''."
          }
        ]
      }
    ],

    "cognate": "занщ1эу",
    "type": "adv",
    "ipa": "zaːnt͡ʃaw"
  },
  {
    "word": "зэплъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look around",
        "examples": [
          {
            "sentence": "К1алэр '''зеплъэхьы'''",
            "translation": "The boy '''is looking around'''."
          },
          {
            "sentence": "'''Зэсэплъахьы'''",
            "translation": "'''I am looking around'''."
          },
          {
            "sentence": "'''Зыплъэхьи''' къэгъот джанэр",
            "translation": "'''Look around''' and find the shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapɬaħən"
  },
  {
    "word": "фэмэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пэмэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пэмэн"
    ],
    "cognate": "епэмын",
    "type": "verb",
    "ipa": "faman"
  },
  {
    "word": "пэмэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to try to smell (to try to sense a smell)",
        "examples": [
          {
            "sentence": "К1алэр '''мэпамэ'''",
            "translation": "The boy '''is trying to smell'''."
          },
          {
            "sentence": "Хьэр к1алэм '''епэмы'''",
            "translation": "The dog '''is trying to smell''' the boy."
          },
          {
            "sentence": "'''Умпым'''",
            "translation": "'''Don't try to smell'''."
          },
          {
            "sentence": "Къэгъагъэм '''епэм'''",
            "translation": "'''Try to smell''' the flower."
          }
        ]
      }
    ],
    "synonyms": [
      "фэмэн"
    ],
    "cognate": "епэмын",
    "type": "verb",
    "ipa": "paman"
  },
  {
    "word": "еун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smell (To sense a smell or smells).",
        "examples": [
          {
            "sentence": "К1алэм '''еуы'''",
            "translation": "The boy '''is sensing a smell'''."
          },
          {
            "sentence": "Шхынэр '''еуы'''",
            "translation": "'''He is sensing the smell''' of the food."
          },
          {
            "sentence": "Хьэм '''еогъ''' к1алэм игъуамэ",
            "translation": "The dog '''sensed''' the boy's '''smell'''."
          },
          {
            "sentence": "Къэгъагъэхэр '''сэуых'''",
            "translation": "'''I smell''' the flowers."
          },
          {
            "sentence": "Маш1о гъуамэ '''шъоуа?'''",
            "translation": "'''Do you smell''' fire (said to plural)."
          },
          {
            "sentence": "Маш1о гъуамэ '''сэуырэп'''",
            "translation": "'''I don't smell''' fire."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jawən"
  },
  {
    "word": "ш1ок1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ок1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ок1ын"
    ],
    "type": "verb",
    "ipa": "ʃʷʼakʲʼən"
  },
  {
    "word": "ш1ок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to evade; to get away from",
        "examples": [
          {
            "sentence": "К1алэр мыжъом '''ш1ок1ыгъ'''",
            "translation": "The boy '''evaded''' the rock."
          },
          {
            "sentence": "К1алэр хьэхэмэ '''аш1ок1ы'''",
            "translation": "The boy '''evaded''' the dogs."
          },
          {
            "sentence": "'''Укъысш1ок1ыщтэп'''",
            "translation": "'''You will not evade me'''."
          },
          {
            "sentence": "К1алэр пшъэдэк1ыжъым '''ш1ок1ыгъ'''",
            "translation": "The boy '''evaded''' the punishment."
          },
          {
            "sentence": "К1алэр псынк1и иныжъым '''ш1ок1ышъу'''",
            "translation": "Because the boy is fast '''he can get away from''' the giant."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ок1ьын"
    ],
    "type": "verb",
    "ipa": "ʃʷʼat͡ʃʼən"
  },
  {
    "word": "пшъэдэк1ыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пшъэдэк1ыжь\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пщынэжь"
    ],
    "type": "noun",
    "ipa": "pʂadat͡ʃəʐ"
  },
  {
    "word": "пщынэжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "punishment",
        "examples": [
          {
            "sentence": "К1алэр '''пщынэжьым''' ш1ок1эгъ",
            "translation": "The boy avoided '''the punishment'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пшъэдэк1ыжъ"
    ],
    "type": "noun",
    "ipa": "pɕənaʑ"
  },
  {
    "word": "хэ1уагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "famous",
        "examples": [
          {
            "sentence": "Л1ыжъэр '''хэ1уагъ'''",
            "translation": "The old man '''is famous'''."
          },
          {
            "sentence": "Мы рестырантэр '''хэ1уагъ'''",
            "translation": "This restaurant '''is famous'''."
          },
          {
            "sentence": "А орэдда1охэр '''хэ1уагъэх'''",
            "translation": "Those singers '''are famous'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "xaʔʷaʁ"
  },
  {
    "word": "хэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pierce",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʔʷan"
  },
  {
    "word": "хамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stranger",
        "examples": []
      },
      {
        "meaning": "foreigner",
        "examples": [
          {
            "sentence": "Ч1ыгу '''хамэ'''",
            "translation": "'''Foreign''' land."
          }
        ]
      }
    ],
    "synonyms": [
      "хымэ",
      "----"
    ],
    "type": "adj",
    "ipa": "xaːma"
  },
  {
    "word": "гу къеон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one's heart to beat; to be having heartbeats",
        "examples": [
          {
            "sentence": "'''Сыгу къео'''",
            "translation": "'''My heart is beating'''."
          },
          {
            "sentence": "Л1ыжъым '''ыгу къеожьырэп'''",
            "translation": "The old-man's '''heart isn't beating anymore'''."
          },
          {
            "sentence": "'''Сыгу''' джыри '''къео'''",
            "translation": "'''My heart''' is still '''beating'''."
          },
          {
            "sentence": "К1алэм мэзым '''ыгу къеуи''' хэт",
            "translation": "The boy is in the forest, '''with a heart beating'''."
          }
        ]
      },
      {
        "meaning": "to be anxious",
        "examples": []
      },
      {
        "meaning": "to get very scared",
        "examples": [
          {
            "sentence": "Фылым уигъэштэтым '''сыгу къыригъао'''",
            "translation": "The horror film '''scares''' me."
          },
          {
            "sentence": "'''Сыгу къебгъэуагъ'''",
            "translation": "'''You scared the hell out of me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷə jawan"
  },
  {
    "word": "дэхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "still",
        "examples": [
          {
            "sentence": "Сигу '''дэхьэ''' къео",
            "translation": "My heart is '''still''' beating."
          },
          {
            "sentence": "'''Дэхьэ''' еджап1эм ук1уагъэба?",
            "translation": "Have you '''still''' not went to school?"
          },
          {
            "sentence": "'''Дэхьэ''' сышхырэ сыухыгъэп",
            "translation": "I '''still''' haven't finished what I am eating."
          },
          {
            "sentence": "'''Дэхьэ''' седжагъэп",
            "translation": "I '''still''' haven't studied."
          }
        ]
      },
      {
        "meaning": "keep (Only in Imperative mood)",
        "examples": [
          {
            "sentence": "'''Дэхьэ''' еу пхъэм",
            "translation": "'''Keep''' hitting the wood."
          },
          {
            "sentence": "'''Дэхьэ''' к1о",
            "translation": "'''Keep''' going."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "daħa"
  },
  {
    "word": "дэхьэзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "again",
        "examples": [
          {
            "sentence": "Сигу '''дэхьэзэ''' къео",
            "translation": "My heart is beating '''again'''."
          },
          {
            "sentence": "'''Дэхьэзэ''' еджап1эм ук1уагъэба?",
            "translation": "Have you not gone to school '''again'''?"
          },
          {
            "sentence": "'''Дэхьэзэ''' седжагъэп",
            "translation": "I haven't studied '''again'''."
          },
          {
            "sentence": "'''Дэхьэзэ''' к1о",
            "translation": "Go '''again'''."
          },
          {
            "sentence": "'''Дэхьэзэ''' еу пхъэм",
            "translation": "Hit the wood '''again'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "daħaza"
  },
  {
    "word": "джыдэдэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "right now, immediately, at once",
        "examples": [
          {
            "sentence": "Сигу '''джыдэдэм''' къео",
            "translation": "My heart is beating '''right now'''."
          },
          {
            "sentence": "'''Джыдэдэм''' еджап1эм ук1уагъэба?",
            "translation": "Have you not went to school '''right now'''?"
          },
          {
            "sentence": "'''Джыдэдэм''' седжагъэп",
            "translation": "I haven't studied '''right now'''."
          },
          {
            "sentence": "'''Джыдэдэм''' к1о",
            "translation": "Go '''immediately'''."
          },
          {
            "sentence": "'''Джыдэдэм''' еу пхъэм",
            "translation": "Hit the wood '''immediately'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒədadam"
  },
  {
    "word": "джы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "now",
        "examples": [
          {
            "sentence": "Сигу '''джы''' къео",
            "translation": "My heart is beating '''now'''."
          },
          {
            "sentence": "'''джы''' еджап1эм ук1уагъэба?",
            "translation": "Have you not gone to school '''now'''?"
          },
          {
            "sentence": "'''джы''' седжагъэп",
            "translation": "I haven't studied '''now'''."
          },
          {
            "sentence": "'''джы''' к1о",
            "translation": "Go '''now'''."
          },
          {
            "sentence": "'''джы''' еу пхъэм",
            "translation": "Hit the wood '''now'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒə"
  },
  {
    "word": "ухын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to finish, to run out",
        "examples": [
          {
            "sentence": "К1алэм Колэ '''еухы'''",
            "translation": "The boy '''is finishing''' the cola."
          },
          {
            "sentence": "Псэр к1алэхэмэ '''яухыщт'''",
            "translation": "The boys '''gonna finish''' the water."
          }
        ]
      },
      {
        "meaning": "to finish doing something, to done doing something",
        "examples": [
          {
            "sentence": "Сышхырэ '''сыухыгъэп'''",
            "translation": "'''I haven't done''' eating."
          },
          {
            "sentence": "'''Ух''' шхынэр",
            "translation": "'''Finish''' the food."
          },
          {
            "sentence": "Шхынэр '''сэгъэух'''",
            "translation": "'''Let me finish''' the food."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wxən"
  },
  {
    "word": "хэукъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make mistake",
        "examples": [
          {
            "sentence": "К1алэр '''хэукъуагъ'''",
            "translation": "The boy '''made a mistake'''."
          },
          {
            "sentence": "'''Сыхэукъорэп'''",
            "translation": "'''I don't mistake'''."
          },
          {
            "sentence": "'''Хэукъорэ''' хэт1эры",
            "translation": "The '''one that mistakes''' is getting out."
          },
          {
            "sentence": "Ц1ыфы щы1эп '''хэмыукъоу'''",
            "translation": "There is no person '''that doesn't mistake'''."
          },
          {
            "sentence": "Тестымк1э '''ухаукъо'''",
            "translation": "You are '''mistaking''' in the test."
          },
          {
            "sentence": "'''Ухэмыукъоу''' джэгу",
            "translation": "Play '''without mistaking'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawqʷan"
  },
  {
    "word": "хэти",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "any, everyone",
        "examples": [
          {
            "sentence": "'''Хэти''' мэу къэблак1ы",
            "translation": "'''Everyone''' is passing here."
          },
          {
            "sentence": "'''Хэти''' мыер зишхыгъэр мэу къэрэк1у",
            "translation": "'''Everyone''' that ate an apple come here."
          },
          {
            "sentence": "'''Хэти''' мыер къерэшт",
            "translation": "'''Everyone''' pick an apple."
          },
          {
            "sentence": "'''Хэти''' зыфае е1оба",
            "translation": "'''Everyone''' says what he wants."
          },
          {
            "sentence": "'''Хэтими''' хъущт",
            "translation": "'''It can be anybody'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "xaːti"
  },
  {
    "word": "пыск1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cough",
        "examples": [
          {
            "sentence": "к1алэр '''мэпыск1э'''",
            "translation": "The boy '''is coughing'''."
          },
          {
            "sentence": "'''сыпыск1агъ'''",
            "translation": "'''I cough'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəst͡ʃan"
  },
  {
    "word": "шъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get wet (usually by wet/soaked clothes)",
        "examples": [
          {
            "sentence": "К1алэр '''шъугъэ'''",
            "translation": "The boy '''got wet'''."
          },
          {
            "sentence": "К1алэр ощхым '''егъэшъу'''",
            "translation": "The rain is '''wetting''' the boy."
          },
          {
            "sentence": "'''Сэшъу'''",
            "translation": "'''I am getting wet'''."
          },
          {
            "sentence": "'''гъэшъу''' к1алэр",
            "translation": "'''Wet''' the boy."
          },
          {
            "sentence": "Тыдэк1и '''сышъугъ'''",
            "translation": "'''I got wet''' everywhere."
          },
          {
            "sentence": "Сиджанэ '''шъущт'''",
            "translation": "My shirt '''will get wet'''."
          },
          {
            "sentence": "К1элэц1ык1умэ '''загъэшъужьы'''",
            "translation": "The children '''are wetting''' each other."
          }
        ]
      },
      {
        "meaning": "to get soaked",
        "examples": []
      },
      {
        "meaning": "to get rot; to decompose",
        "examples": [
          {
            "sentence": "Уцыр '''мэшъу'''",
            "translation": "The plant '''is getting rot'''."
          },
          {
            "sentence": "Мы1эрысыхэр '''шъугъэх'''",
            "translation": "The apples '''got rot'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷən"
  },
  {
    "word": "тэдэгьи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "everywhere, every place",
        "examples": [
          {
            "sentence": "Мыехэр '''тэдэгьи''' 1утых",
            "translation": "There are apples '''everywhere'''."
          },
          {
            "sentence": "'''Тэдэгьи''' ск1уагъми усэгупсэ",
            "translation": "'''Everywhere''' I go I think of you."
          },
          {
            "sentence": "'''Тэдэгьи''' сышъогъ",
            "translation": "I got wet '''everywhere'''."
          },
          {
            "sentence": "Мазым '''тэдэгьи''' чъыг шыплъэгъу",
            "translation": "you will see tree '''everywhere''' in the forest."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "tadaɡʲi"
  },
  {
    "word": "егупсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гупсэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɡʷəpsan"
  },
  {
    "word": "лъэхъуамбэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toe (leg)",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэпхъабэ"
    ],
    "cognate": "лъэпхъуамбэ",
    "type": "noun",
    "ipa": "ɬaχʷaːmba"
  },
  {
    "word": "1эхъуамбэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "finger",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпхъабэ"
    ],
    "cognate": "1эпхъуамбэ",
    "type": "noun",
    "ipa": "ʔaχʷaːmba"
  },
  {
    "word": "1аш1у1уш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1аш1у-1уш1у\"",
        "examples": []
      }
    ],
    "synonyms": [
      "1аш1у-1уш1у"
    ],
    "type": "noun",
    "ipa": "ʔaːʃʷʼə ʔʷəʃʷʼ"
  },
  {
    "word": "дыухьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prayer (request)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dəwħa"
  },
  {
    "word": "къолэбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bird",
        "examples": []
      }
    ],
    "synonyms": [
      "бзыу"
    ],
    "cognate": "джэдкъаз",
    "type": "noun",
    "ipa": "qʷalabzəw"
  },
  {
    "word": "малъхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "son-in-law",
        "examples": []
      },
      {
        "meaning": "brother-in-law",
        "examples": []
      }
    ],
    "synonyms": [
      "махълъэ",
      "пщыкъу",
      "л1ымыш",
      "малхъо"
    ],
    "cognate": "малъхъэ",
    "type": "noun",
    "ipa": "maːɬχʷa"
  },
  {
    "word": "пщыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "son-in-law",
        "examples": []
      }
    ],
    "synonyms": [
      "малъхъо",
      "махълъэ",
      "л1ымыш",
      "малхъо"
    ],
    "cognate": "малъхъэ",
    "type": "noun",
    "ipa": "pɕəqʷə"
  },
  {
    "word": "л1ымыш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "son-in-law",
        "examples": []
      }
    ],
    "synonyms": [
      "малъхъо",
      "махълъэ",
      "пщыкъу",
      "малхъо"
    ],
    "cognate": "малъхъэ",
    "type": "noun",
    "ipa": "ɬʼəməʃ"
  },
  {
    "word": "шхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "food",
        "examples": [
          {
            "sentence": "К1алэ тхьамышк1эм '''шхын''' игъотрэп",
            "translation": "The poor boy can't find '''food'''."
          },
          {
            "sentence": "'''Шхынэр''' шхы",
            "translation": "Eat the '''food'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʃxəːn"
  },
  {
    "word": "фэлъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be capable of, to be able, to manage",
        "examples": [
          {
            "sentence": "к1алэм бэ '''фэлъэк1ыгъ'''",
            "translation": "The boy '''was capable''' of doing a lot."
          },
          {
            "sentence": "'''сыфэлъэк1ыщт''' ар",
            "translation": "'''I can do''' that."
          },
          {
            "sentence": "к1алэм сымаджэу '''къыфэлъэк1ыщтэп''' мэхъыенэ",
            "translation": "the boy '''is not capable''' to move while he is sick."
          },
          {
            "sentence": "нэхьыбэ мыщ ащ '''фэлъэк1ыщтэп'''",
            "translation": "'''he is not capable of''' more than this."
          },
          {
            "sentence": "сымышхэнэ '''къэсфэлъэк1ыщт'''",
            "translation": "'''I can bear''' without eating."
          },
          {
            "sentence": "бэрэ сычъыныр '''къэсфэлъак1ы'''",
            "translation": "'''I capable''' of running a lot."
          }
        ]
      },
      {
        "meaning": "to bear",
        "examples": [
          {
            "sentence": "'''сыфэлъэк1ыщт''' ар",
            "translation": "'''I can stand/bear''' that."
          },
          {
            "sentence": "л1ыжъым '''къыфэмылъэк1ыжьэу''' к1алэхэмэ ащыхьагъ",
            "translation": "The old man '''while couldn't bear''' the boys '''anymore''' yelled at them."
          }
        ]
      }
    ],
    "synonyms": [
      "фэлъэк1ьын",
      "фэш1эн",
      "ипш1э къик1ын"
    ],
    "type": "verb",
    "ipa": "ɬat͡ʃʼən"
  },
  {
    "word": "фэлъэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэлъэк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "фэлъэк1ын"
    ],
    "type": "verb",
    "ipa": "ɬakʲʼən"
  },
  {
    "word": "уцун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stop",
        "examples": [
          {
            "sentence": "'''Уцу'''",
            "translation": "'''stop'''."
          },
          {
            "sentence": "'''Уцуба'''",
            "translation": "'''stop already'''."
          },
          {
            "sentence": "'''Уцу''' ук1оу",
            "translation": "'''stop''' going."
          },
          {
            "sentence": "К1элэр '''мэуцу'''",
            "translation": "the boy '''is stopping'''."
          },
          {
            "sentence": "О '''ууцугъэп'''",
            "translation": "'''you didn't stop'''."
          },
          {
            "sentence": "К1элэр '''мэуцушъурэп''' тутын ешъонэу",
            "translation": "the boy '''can't stop''' smoking."
          },
          {
            "sentence": "К1элэр '''къэуцогъ'''",
            "translation": "the boy '''stopped'''."
          },
          {
            "sentence": "Мащинэр '''къэуцурэп'''",
            "translation": "the car '''is not stopping'''.."
          },
          {
            "sentence": "Мэш1окор '''къэуцушъутэп'''",
            "translation": "the train '''can't stop'''."
          }
        ]
      },
      {
        "meaning": "to standstill",
        "examples": []
      }
    ],
    "synonyms": [
      "зэпыун"
    ],
    "cognate": "увын",
    "type": "verb",
    "ipa": "wt͡ʃʷən"
  },
  {
    "word": "нэплъэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "view",
        "examples": [
          {
            "sentence": "Си '''нэплъэгъу''' ик1",
            "translation": "Get out from my '''view'''."
          },
          {
            "sentence": "Си '''нэплъэгъу''' тыгъэр",
            "translation": "The sun is on my '''view'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "napɬaʁʷ"
  },
  {
    "word": "нэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "facade, frontage, front",
        "examples": [
          {
            "sentence": "'''Синэ1у''' ик1",
            "translation": "Move away '''from my sight'''."
          },
          {
            "sentence": "'''Синэ1у''' илъ бананэ",
            "translation": "The banana '''is in my front'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naʔʷ"
  },
  {
    "word": "ук1ытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be ashamed",
        "examples": [
          {
            "sentence": "К1алэр '''мэук1ытэ'''",
            "translation": "The boy '''is ashamed'''."
          },
          {
            "sentence": "'''Сэук1ытэ'''",
            "translation": "'''I am ashamed'''."
          },
          {
            "sentence": "'''Уук1ытэрэба'''",
            "translation": "'''Are you not ashamed'''."
          }
        ]
      },
      {
        "meaning": "to be shy",
        "examples": [
          {
            "sentence": "К1алэр '''мэук1ытэу''' пшъашъэм дэгущы1эшъурэп",
            "translation": "The boy while being shy '''can't talk''' to the girl."
          },
          {
            "sentence": "К1алэр '''мэук1ытэ'''",
            "translation": "The boy '''is shy'''."
          },
          {
            "sentence": "'''Умыук1ытэу''' къэсдэгуща1",
            "translation": "Talk to me '''while not being shy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ук1ьытэн"
    ],
    "type": "verb",
    "ipa": "wət͡ʃʼətan"
  },
  {
    "word": "ук1ьытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1ытэн\"",
        "examples": []
      }
    ],

    "cognate": "ук1ытэн",
    "type": "verb",
    "ipa": "wəkʲʼətan"
  },
  {
    "word": "шыук1ытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be ashamed of",
        "examples": [
          {
            "sentence": "'''Усшэук1ытэ'''",
            "translation": "'''I am ashamed of you'''."
          },
          {
            "sentence": "К1алэм иш1агъэм '''шыук1ытэ'''",
            "translation": "The boy '''is ashamed of''' what he done."
          }
        ]
      },
      {
        "meaning": "to be shy from",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''шэук1ытэ'''",
            "translation": "The boy '''is shy''' from the girl."
          }
        ]
      }
    ],
    "synonyms": [
      "шыук1ьытэн"
    ],
    "type": "verb",
    "ipa": "ʃəwt͡ʃʼətan"
  },
  {
    "word": "шыук1ьытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шыук1ытэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шыук1ытэн"
    ],
    "type": "verb",
    "ipa": "ʃəwkʲʼətan"
  },
  {
    "word": "мэбэгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to multiply, to become bigger (in numbers)",
        "examples": []
      },
      {
        "meaning": "to breed",
        "examples": [
          {
            "sentence": "чэтхэр '''мэбэгъох'''",
            "translation": "The chickens '''are breeding'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mabaʁʷan"
  },
  {
    "word": "ц1ашъутэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slippery, low-friction surface",
        "examples": [
          {
            "sentence": "Джыхашъор '''ц1ашъутэ'''",
            "translation": "The floor is '''slip'''."
          }
        ]
      },
      {
        "meaning": "smooth, flat",
        "examples": [
          {
            "sentence": "1анэр '''ц1ашъутэ'''",
            "translation": "The floor is '''flat'''."
          }
        ]
      },
      {
        "meaning": "(slang) hairless",
        "examples": [
          {
            "sentence": "Ц1ыфыр ылъакъо '''ц1ашъутэ'''",
            "translation": "The man's leg '''is hairless'''."
          }
        ],
        "tags": [
          "slang"
        ]
      },
      {
        "meaning": "(slang) beardless and moustacheless",
        "examples": [
          {
            "sentence": "Ц1ыфыр '''ц1ашъутэ'''",
            "translation": "The man '''is beardless'''."
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡sʼaːʃʷta"
  },
  {
    "word": "ц1эшъутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slip (on flat slippery surface with low friction like wet floor, wet road, slide and etc.)",
        "examples": [
          {
            "sentence": "Джыхашъом '''утэц1эшъутэт'''",
            "translation": "'''You will slip''' on the floor."
          },
          {
            "sentence": "К1алэр '''мэц1ашъутэ'''",
            "translation": "The boy '''is slipping'''."
          },
          {
            "sentence": "си1э бэнанэр '''къыц1эшъутык1ы'''",
            "translation": "The banana '''is slipping''' from my hand."
          },
          {
            "sentence": "к1алэр бэнанэм таучи, '''мэц1эшъутагъ'''",
            "translation": "The boy step on the banana and '''he slipped'''."
          }
        ]
      },
      {
        "meaning": "to slip-up",
        "examples": []
      },
      {
        "meaning": "to loss the right way, to do the wrong things",
        "examples": [
          {
            "sentence": "к1алэр '''мэц1эшъути''' гонэхьхэр елэжьгъэх",
            "translation": "The boy '''lost the right way''' and communed sins."
          },
          {
            "sentence": "к1алэм и1иман куачэби, псычыу '''мэц1эшъутэ'''",
            "translation": "Because the boy's faith isn't strong, '''he losses the right way''' fast."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼaʃʷtan"
  },
  {
    "word": "пхъашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bold, brave, hard",
        "examples": []
      },
      {
        "meaning": "rough, coarse, surface with high friction (opposite of soft)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "pχaːʃa"
  },
  {
    "word": "къэущын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wake up",
        "examples": [
          {
            "sentence": "К1алэр '''къэущы'''",
            "translation": "The boy '''is waking up'''."
          },
          {
            "sentence": "К1алэр '''къэущыжьышъурэп''' бэрэ чэщым щысымэ",
            "translation": "The boy '''can't wake up''' when he stays too late at night."
          },
          {
            "sentence": "Неущы пасэу '''укъэущыжьын''' фай",
            "translation": "You must '''wake up''' early tomorrow."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qapɕən"
  },
  {
    "word": "къэгъэущын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to awake someone",
        "examples": [
          {
            "sentence": "К1алэр '''къэcгъэущы'''",
            "translation": "I am '''is awaking''' the boy."
          },
          {
            "sentence": "К1алэм пшъашъэр '''къегъэущы'''",
            "translation": "The boy '''is awaking''' the girl."
          },
          {
            "sentence": "Бэрэ ушысмэ чэщым '''укъэсгъэущышъутэп'''",
            "translation": "If you stay late night '''I will not be able to awake you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁawɕən"
  },
  {
    "word": "зэхэтэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be torn into pieces",
        "examples": [
          {
            "sentence": "Лъэмыджэр '''зэхэтэкъу'''",
            "translation": "The bridge '''is tearing apart'''."
          },
          {
            "sentence": "Унэм рокет тафэмэ '''зэхэтэкъущт'''",
            "translation": "If a rocket hits the house, '''it will be torn into pieces'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxataqʷən"
  },
  {
    "word": "зэгоутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be torn apart emotionally, to fall apart (To be emotionally in crisis)",
        "examples": [
          {
            "sentence": "Бзылъфыгъэм ик1алэ заум хэук1ахьэм '''зэгоутэгъ'''",
            "translation": "When the woman's child was killed in war '''she was in crisis'''."
          }
        ]
      },
      {
        "meaning": "to split with strength, to cleave, to rive",
        "examples": [
          {
            "sentence": "К1алэм чъыгыр '''зэгуеуты'''",
            "translation": "The boy '''is splitting''' the tree"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaɡʷawtən"
  },
  {
    "word": "таплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"теплъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "taːpɬa"
  },
  {
    "word": "зэгъэфагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "arranged, organized",
        "examples": [
          {
            "sentence": "К1алэр '''зэгъэфагъэм''' итаплъэ дахэ",
            "translation": "The '''organized''' boy looks beautiful."
          },
          {
            "sentence": "Унэр '''зэгъэфагъэп'''",
            "translation": "The house '''is not organized'''."
          },
          {
            "sentence": "'''Зэгъэфагъэу''' удэк1ын фае",
            "translation": "You need to be '''organized while''' going out."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zaʁafaːʁ"
  },
  {
    "word": "къэпш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэш1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qapʃʼan"
  },
  {
    "word": "къэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to figure out the correct answer",
        "examples": [
          {
            "sentence": "Тестымк1э, к1алэм джэуапхэр '''къиш1агъэх'''",
            "translation": "In the test, The boy '''figured out''' the answers."
          },
          {
            "sentence": "Къэсш1эщт хэт мы1эрысэр зышхыгъэр",
            "translation": "'''I will figure out''' who ate the apple."
          },
          {
            "sentence": "Джэуап тэрэзэр '''къэш1эн''' 1о",
            "translation": "Try '''to figure out''' the right answer."
          },
          {
            "sentence": "'''Къаш1''' тэры сиунэ",
            "translation": "'''Figure out''' which one is my house."
          }
        ]
      },
      {
        "meaning": "to recognize",
        "examples": [
          {
            "sentence": "Джы '''укъэсш1эжьыгъ'''",
            "translation": "Now '''I recognized you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʃʼan"
  },
  {
    "word": "къэш1эжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to remember",
        "examples": [
          {
            "sentence": "Мы к1алэр '''къэспш1эжрэп'''",
            "translation": "'''I can't remember''' this boy."
          },
          {
            "sentence": "к1алэм иц1э '''къэсш1эжэгъ'''",
            "translation": "'''I remembered''' the boy's name."
          },
          {
            "sentence": "'''Сыкъэбэш1эжа'''?",
            "translation": "'''Do you remember me'''."
          },
          {
            "sentence": "Сиджанэ плъыжь '''къэш1эж'''",
            "translation": "'''Remember''' my red shirt."
          },
          {
            "sentence": "Гущы1эхэр '''къэш1эжых'''",
            "translation": "'''Remember''' the words."
          },
          {
            "sentence": "'''Къасш1эмэ''' мые къысэт",
            "translation": "'''If I figure the right answer''' give me an apple."
          },
          {
            "sentence": "Джэуап тэрэзэр '''къэпш1эрагъоба'''?",
            "translation": "'''You wanna figure''' the right answer right?"
          }
        ]
      },
      {
        "meaning": "to recognize",
        "examples": [
          {
            "sentence": "Мы к1алэр '''къэспш1эжрэп'''",
            "translation": "'''I can't recognize''' this boy."
          },
          {
            "sentence": "к1алэм иц1э '''къэсш1эжэгъ''' тхылъымк1э",
            "translation": "'''I recognized''' the boy's name in the book."
          },
          {
            "sentence": "к1алэм иц1э '''къэсэш1эжы'''",
            "translation": "'''I recognizing''' the boy's name."
          },
          {
            "sentence": "'''Сыкъэбэш1эжа'''?",
            "translation": "'''Do you recognize me'''."
          },
          {
            "sentence": "Гущы1эхэр '''къэш1эжых'''",
            "translation": "'''Recognize''' the words."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʃʼaʑən"
  },
  {
    "word": "къэпш1эжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэш1эжын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qapʃʼaʑən"
  },
  {
    "word": "гукъэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гукъэк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гукъэк1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqakʲʼən"
  },
  {
    "word": "гукъэк1ьыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гукъэк1ыжьын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гукъэк1ыжьын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqakʲʼəʑən"
  },
  {
    "word": "гъэк1осэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to extinguish (a fire)",
        "examples": [
          {
            "sentence": "К1алэм ыгу къэк1ыжьыгъ маш1ор '''зэримыгъэк1осагъэ'''",
            "translation": "The boy suddenly remembered he didn't '''extinguished''' the fire."
          },
          {
            "sentence": "Маш1ор '''гъэк1уас'''",
            "translation": "'''Extinguish''' the fire."
          },
          {
            "sentence": "К1алэм маш1ор '''егъэк1уасэ'''",
            "translation": "The boy '''is extinguishing''' the fire."
          }
        ]
      },
      {
        "meaning": "to turn off the light",
        "examples": [
          {
            "sentence": "К1алэм нэфынэр '''егъэк1уасэ'''",
            "translation": "The boy '''turning off''' the light."
          },
          {
            "sentence": "Тыгъэр '''сыгъэк1осэшъущтэп'''",
            "translation": "'''I can't turning off''' the sun."
          }
        ]
      },
      {
        "meaning": "to turn off",
        "examples": [
          {
            "sentence": "Мащинэр '''гъэк1уас'''",
            "translation": "'''Turn off''' the car."
          },
          {
            "sentence": "Сомпйутэрэр к1алэм '''егъэк1уасэ'''",
            "translation": "The boy '''is turning''' the computer '''off'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁakʷʼasan"
  },
  {
    "word": "мэк1осэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be extinguished",
        "examples": [
          {
            "sentence": "Маш1ор '''мэк1уасэ'''",
            "translation": "The fire '''is shuts down'''."
          },
          {
            "sentence": "Тыгъэр '''мэк1осэтэп'''",
            "translation": "The sun '''will not extinguish'''."
          }
        ]
      },
      {
        "meaning": "light to be turned off",
        "examples": [
          {
            "sentence": "Нафнэр '''мэк1уасэ'''",
            "translation": "The light '''is turning off'''."
          },
          {
            "sentence": "Тыгъэр '''мэк1осэтэп'''",
            "translation": "The sun '''will not turn off'''."
          }
        ]
      },
      {
        "meaning": "to be turned off",
        "examples": [
          {
            "sentence": "Мащинэр '''мэк1уасэ'''",
            "translation": "The car '''is turning off'''."
          },
          {
            "sentence": "Сомпйутэрэр '''къэк1осагъ'''",
            "translation": "The computer '''turned off'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "makʷʼasan"
  },
  {
    "word": "пщыгъупшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щыгъупшын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pɕəʁʷəpʃən"
  },
  {
    "word": "зэгоожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to blame oneself",
        "examples": [
          {
            "sentence": "К1алэр '''зэгоожьы''' пшъашъэр мэл1агъи",
            "translation": "The boy '''is blaming himself''' because the girl died."
          },
          {
            "sentence": "'''Узэмгуужь'''",
            "translation": "'''Don't blame yourself'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaɡʷawaʑən"
  },
  {
    "word": "дыйшъоутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "neck slap; slap in the neck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dəjʃʷawtən"
  },
  {
    "word": "т1у1угъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrinkly",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tʷʼəʔʷəʁa"
  },
  {
    "word": "щыдырхъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lizard",
        "examples": []
      }
    ],
    "synonyms": [
      "хьамплъыжь"
    ],
    "type": "noun",
    "ipa": "[]"
  },
  {
    "word": "сако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jacket; coat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːkʷa"
  },
  {
    "word": "дэщыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise",
        "examples": [
          {
            "sentence": "уиджанэ и1эпэгъуапэхэ '''дэщыех'''",
            "translation": "'''Raised''' your shirt's sleeves.."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daɕəjan"
  },
  {
    "word": "къык1ышын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise, to hold higher",
        "examples": [
          {
            "sentence": "Къык1ыш уишъхьэ '''ук1омэ'''",
            "translation": "'''Raise''' your head when you walk."
          },
          {
            "sentence": "Сыкъык1ыши дэпкъым '''сыблэгъэк1'''",
            "translation": "'''Raise me''' and I will pass over the wall."
          }
        ]
      },
      {
        "meaning": "to bring something to a higher place",
        "examples": [
          {
            "sentence": "Уишыхэр унашъхьэм '''къык1ышых'''",
            "translation": "'''Bring''' your brothers to the roof."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qət͡ʃəʃən"
  },
  {
    "word": "шъхьэтепхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "headgear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħatajpχʷ"
  },
  {
    "word": "сае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Circassian female traditional clothes",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːja"
  },
  {
    "word": "епыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stitch, to strike with pointy or sharp things, to stab (Usually done to annoy or bug someone thus it can also be to bug someone)",
        "examples": [
          {
            "sentence": "Томэр к1алэм '''епыджы'''",
            "translation": "Tom '''is bugging''' the boy."
          },
          {
            "sentence": "Уи1эбжъанэхэмк1э '''укъсэмпыдж'''",
            "translation": "'''Don't stab me''' with your nails."
          },
          {
            "sentence": "К1алэр 1эпхъабэхэмк1э '''късэпыджы'''",
            "translation": "The boy is '''stabbing me''' with his fingers."
          }
        ]
      }
    ],
    "synonyms": [
      "епыгьын"
    ],
    "type": "verb",
    "ipa": "japəd͡ʒən"
  },
  {
    "word": "епыгьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"епыджын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "епыджын"
    ],
    "type": "verb",
    "ipa": "japəɡʲən"
  },
  {
    "word": "пыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"епыджын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəd͡ʒan"
  },
  {
    "word": "мэпыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to strike with pointy things, to stab (Usually done to annoy or bug someone thus it can also be to bug someone)",
        "examples": [
          {
            "sentence": "Томэр '''мэпыджэ'''",
            "translation": "Tom '''is bugging'''."
          },
          {
            "sentence": "К1алэм и1эбжъанэхэмк1э '''мэпыджэ'''",
            "translation": "The boy '''is stabbing''' with his nails."
          }
        ]
      }
    ],
    "synonyms": [
      "мэпыгьэн"
    ],
    "type": "verb",
    "ipa": "mapəd͡ʒən"
  },
  {
    "word": "мэпыгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэпыджэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэпыджэн"
    ],
    "type": "verb",
    "ipa": "mapəɡʲan"
  },
  {
    "word": "хэпыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stab someone or something",
        "examples": [
          {
            "sentence": "Томэр к1алэм къэлэм папц1эк1э '''хэпыджэ'''",
            "translation": "Tom '''is stabbing''' the boy with a sharp pencil."
          },
          {
            "sentence": "Уи1эбжъанэхэмк1э '''укъсэхэмпыдж'''",
            "translation": "'''Don’t stab me''' with your nails."
          },
          {
            "sentence": "Цыф ц1апэм '''хэпыджы''' шъэжъыек1э иук1эгъ",
            "translation": "The mean person '''stabbed''' him with a knife and killed him."
          }
        ]
      }
    ],
    "synonyms": [
      "хэпыгьэн"
    ],
    "type": "verb",
    "ipa": "xapəd͡ʒan"
  },
  {
    "word": "хэпыгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэпыджэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хэпыджэн"
    ],
    "type": "verb",
    "ipa": "xapəɡʲan"
  },
  {
    "word": "нэмаздэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "carpet for prayer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "namaːzdaq"
  },
  {
    "word": "к1ьэхьылэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ыхьэлыхьэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "kʲʼaħəlaħ"
  },
  {
    "word": "зегъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get lost, to go away and disappear (to beat it, to fuck off, to piss off, to bugger off) (Used to tell somebody to go away)",
        "examples": [
          {
            "sentence": "'''Зегъэхь''' о чыжьэу",
            "translation": "'''Get lost''' far from here."
          },
          {
            "sentence": "'''Зегъэхь''' мэу",
            "translation": "'''Get lost''' from here."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zajʁaħən"
  },
  {
    "word": "чыжьэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "far away",
        "examples": [
          {
            "sentence": "к1алэр '''чыжьэу''' мэк1уагъ",
            "translation": "The boy went '''far away'''."
          },
          {
            "sentence": "сиунэ '''чыжьэу''' 1ут",
            "translation": "The house is '''far away'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃəʑaw"
  },
  {
    "word": "зыш1оу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very, really",
        "examples": [
          {
            "sentence": "Шхынэр '''зыш1оу''' аш1у",
            "translation": "The food '''is very''' tasty/sweet."
          },
          {
            "sentence": "Мэзыр '''зыш1оу''' мэзах",
            "translation": "The forest is '''really''' dark."
          },
          {
            "sentence": "К1алэр '''зыш1оу''' к1уач1э",
            "translation": "The boy '''is really''' strong."
          },
          {
            "sentence": "Анэр '''зыш1оу''' цык1у",
            "translation": "The table '''is really''' small."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "zəʃʷʼəw"
  },
  {
    "word": "щыгъэзыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put away something to another place/spot (usually to a small distance away)",
        "examples": [
          {
            "sentence": "К1алэм анэр '''шегъэзые'''",
            "translation": "The boy '''is moving''' the table."
          },
          {
            "sentence": "Анэр хьак1эщым '''шыгъэзый'''",
            "translation": "'''Move''' the table to the living room."
          },
          {
            "sentence": "Анэр '''шызгъэзыешъурэп''' унашъхьэм",
            "translation": "'''I am not capable of moving''' the table to the roof."
          },
          {
            "sentence": "Анэу о '''шыбгъэзыйгъэм''' сыфай",
            "translation": "I want the table '''you moved'''."
          },
          {
            "sentence": "Мы шхынхэр шъо '''шышъугъэзыещт'''.",
            "translation": "'''You (plural) gonna move''' these dinners."
          },
          {
            "sentence": "Анэр пык1эм '''шыгъэзыий''' сыблэгъэк1",
            "translation": "'''Move''' the table away from the door and let me pass."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəʁazəjan"
  },
  {
    "word": "зыщыгъэзыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move oneself to another place/spot (Usually move a small distance from the spot oneself is standing)",
        "examples": [
          {
            "sentence": "К1алэм '''зышыгъэзыегъ''' гъогум",
            "translation": "The boy '''moved''' from the road."
          },
          {
            "sentence": "'''Зышыгъэзыегу'''",
            "translation": "'''Move a bit for now'''."
          },
          {
            "sentence": "Гъогум сыблэк1ышъурэп, ц1ыфыхэмэ '''зышагъэзыерэп'''",
            "translation": "I can't pass the road, the people '''aren't moving''' from there."
          },
          {
            "sentence": "Тэк1у '''шыгъэзыий''' сыблэгъэк1",
            "translation": "'''Move''' a bit and let me pass."
          },
          {
            "sentence": "Компьютерым '''зышыгъэзый'''",
            "translation": "'''Move''' from the computer."
          },
          {
            "sentence": "'''Зышышъугъэзыий''' сыблэшъугъэк1 гъогум псык1эу!",
            "translation": "'''Move a bit''' and let me pass the road fast! (said to plural)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəʃəʁazəjan"
  },
  {
    "word": "блэгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to let someone past, to let someone cross",
        "examples": [
          {
            "sentence": "К1алэр унэм '''блэгъэк1'''",
            "translation": "'''Let''' the boy '''past''' the house."
          },
          {
            "sentence": "К1алэр пшъашъэм '''блигъэк1ырэп'''",
            "translation": "The girl '''doesn't let''' the boy '''past'''."
          },
          {
            "sentence": "Псыхъом псынк1эу '''сыблэгъэк1'''",
            "translation": "'''Let me past''' the river fast."
          },
          {
            "sentence": "К1элэ пщэрэр пчъэм '''благъэк1ырэп''', о '''ублагъэк1ы'''",
            "translation": "'''They aren't letting''' the fat boy '''past''' the door, '''they let you pass it'''."
          },
          {
            "sentence": "Зыщышъугъэзыий '''сыблэшъугъэк1''' гъогум псынк1эу!",
            "translation": "Move aside '''and let me past''' the road fast (said to plural)."
          }
        ]
      },
      {
        "meaning": "to let an opportunity past",
        "examples": []
      },
      {
        "meaning": "to let time past",
        "examples": [
          {
            "sentence": "Уахътэ к1алэм '''блегъэк1ы'''",
            "translation": "The boy '''let's time past'''."
          }
        ]
      }
    ],
    "synonyms": [
      "блэгъэк1ьын"
    ],
    "type": "verb",
    "ipa": "blaʁat͡ʃən"
  },
  {
    "word": "мэзэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get along",
        "examples": [
          {
            "sentence": "К1алэр унэм '''мэзэгъэу''' исэп",
            "translation": "The boy '''doesn't get along''' in the house."
          },
          {
            "sentence": "К1алэр '''мэзагъэ'''",
            "translation": "The boy '''is getting along'''."
          },
          {
            "sentence": "К1алэр '''мэзагъэрэп'''",
            "translation": "The boy '''is not getting along'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mazaʁan"
  },
  {
    "word": "езэгъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get along with, to be fine with, to be enough with (To be enough/fine with what oneself got)",
        "examples": [
          {
            "sentence": "К1алэр унэм '''изагъэрэп'''",
            "translation": "The boy '''doesn't get along''' inside the house."
          },
          {
            "sentence": "К1алэр пшъашъэм '''езэгъырэп'''",
            "translation": "The boy '''doesn't get along with''' the girl - The boy '''isn't fine with''' the girl."
          },
          {
            "sentence": "К1алэр мащинэм '''езэгъырэп'''",
            "translation": "The boy '''isn't fine with''' the car."
          },
          {
            "sentence": "Уиянэ къыуитыгъэм '''езыгъ'''",
            "translation": "'''Get along with''' that your mother gave you - '''Be fine/enough with''' that your mother gave you."
          },
          {
            "sentence": "Тестым къисхыгъэм '''сезэгъы'''",
            "translation": "'''I am getting fine with''' what i got in the test."
          },
          {
            "sentence": "Л1ымрэ шъузымрэ '''зэзэгъыхэрэп'''",
            "translation": "The man and the woman '''aren't getting along with each other'''."
          },
          {
            "sentence": "'''Зегъэзэгъ''' къыуатыгъэм",
            "translation": "'''Get yourself along with''' what you got."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jazaʁən"
  },
  {
    "word": "мэгубжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"губжын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maɡʷəbʒən"
  },
  {
    "word": "губжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get angry, to get mad",
        "examples": [
          {
            "sentence": "'''Угуабжа?'''",
            "translation": "'''Are you getting angry?'''"
          },
          {
            "sentence": "К1алэр '''мэгуабжы'''",
            "translation": "The boy '''is getting angry'''."
          },
          {
            "sentence": "Л1ыжъыр псынк1эу '''мэгуабжы'''",
            "translation": "The old-man '''is getting angry''' fast."
          },
          {
            "sentence": "Л1ыжъыр '''гуабжэу''' дэк1ыгъ",
            "translation": "The old-man went out '''angry'''."
          },
          {
            "sentence": "Л1ыжъыр ренэу '''мэгубжы'''",
            "translation": "The old-man is always '''mad'''."
          },
          {
            "sentence": "Мы хьэр '''мэгуабжырэп'''",
            "translation": "This dog '''doesn't get mad'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəbʒən"
  },
  {
    "word": "фэгубжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get angry at, to get mad at",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''фэгуабжэгъ'''",
            "translation": "The boy '''got mad at''' the girl."
          },
          {
            "sentence": "К1алэр пшъашъэм '''фэгубжы'''",
            "translation": "The boy '''is getting mad at''' the girl."
          },
          {
            "sentence": "'''Укъэсфэгуабжа?'''",
            "translation": "'''Are you mad at me?'''."
          },
          {
            "sentence": "К1элэцык1ум '''уфэмгуабж''', иш1агъэ гулъитэрэп",
            "translation": "'''Don't get angry at''' the little boy, he doesn't understand what he done."
          },
          {
            "sentence": "'''Усфэгуабжы'''",
            "translation": "'''I am mad at you'''."
          }
        ]
      },
      {
        "meaning": "to get angry for someone, to get mad for someone,",
        "examples": [
          {
            "sentence": "'''Усфэгуабжы'''",
            "translation": "'''I am mad for you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faɡʷəbʒən"
  },
  {
    "word": "гъэгубжын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone angry or mad",
        "examples": [
          {
            "sentence": "К1алэр '''гъэгуабж'''",
            "translation": "'''Make''' the boy '''angry'''."
          },
          {
            "sentence": "К1алэм пшъашъэр '''егъэгубжы'''",
            "translation": "The boy '''making''' the girl '''angry'''."
          },
          {
            "sentence": "Л1ыжъэр '''къэшъумыгъэгубж'''",
            "translation": "'''Don't make''' the old-man '''angry''' (said to plural)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɡʷəbʒən"
  },
  {
    "word": "шъхьатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьантэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːta"
  },
  {
    "word": "1эщхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sleeve",
        "examples": [
          {
            "sentence": "О '''уи1ащхьит1укlэ''' къысфэщэфыжь",
            "translation": "Buy for me '''with your both arms'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔaɕħa"
  },
  {
    "word": "псэк1од",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misdeed",
        "examples": []
      }
    ],
    "synonyms": [
      "гонэхь"
    ],
    "type": "noun",
    "ipa": "psakʷʼad"
  },
  {
    "word": "гунахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гонэхь\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷənaːħ"
  },
  {
    "word": "гуе1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to like something, to be pleased with",
        "examples": [
          {
            "sentence": "Мы к1алэр '''сыгу е1у'''",
            "translation": "'''I like''' this boy; '''I am pleased with''' this boy."
          },
          {
            "sentence": "Къап1орэ '''тыгу е1у'''",
            "translation": "'''We like''' what you say."
          },
          {
            "sentence": "Мы к1алэм иш1эрэ '''сыгу е1урэп'''",
            "translation": "'''I don't like''' what this boy does; '''I am not pleased with''' what this boy does."
          },
          {
            "sentence": "К1алэм шхынэр '''игу е1огъ'''",
            "translation": "The boy '''liked''' the dinner."
          },
          {
            "sentence": "Анэу янахь '''уигу е1урэ''' къашт",
            "translation": "Pick the table '''you are''' most '''pleased with'''."
          },
          {
            "sentence": "К1алэр пшъашъэр '''игу е1огъ'''",
            "translation": "The boy '''was pleased with''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷjaʔʷən"
  },
  {
    "word": "е1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʔʷən"
  },
  {
    "word": "1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hear",
        "examples": [
          {
            "sentence": "Мы к1алэм и1орэ '''сэ1у'''",
            "translation": "'''I hear''' what this boy is saying."
          },
          {
            "sentence": "Къап1орэ '''тэ1урэп'''",
            "translation": "'''We don't hear''' what you are saying."
          },
          {
            "sentence": "Къас1орэ '''о1урэба?'''",
            "translation": "'''Don't you hear''' what I am saying?"
          },
          {
            "sentence": "Л1ыжъым имакъэ '''шъо1умэ''' шъуч1",
            "translation": "'''If you hear''' the old-man's voice run (said to plural)."
          },
          {
            "sentence": "Къас1орэ к1алэхэмэ '''я1у''' дэгъу",
            "translation": "The boys '''hear''' what i am saying cleverly."
          },
          {
            "sentence": "Мэзымк1э ц1ыфмэ уимакъэ '''я1утэп'''",
            "translation": "In the forest people '''will not hear''' your voice."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷən"
  },
  {
    "word": "къэгъэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make a sound/noise or make something do noise (Like using a device or instrument to make noise/sound for example radio)",
        "examples": [
          {
            "sentence": "Макъэ '''къэмгъэ1у''' блэк1",
            "translation": "Pass '''without making noise'''."
          },
          {
            "sentence": "К1алэм макъэ башэ '''къегъэ1у'''",
            "translation": "The boy '''is making''' too much '''noise'''."
          },
          {
            "sentence": "Пшынэр имакъэ нахь '''къэгъэ1у'''",
            "translation": "'''Make''' the accordion's '''noise higher'''."
          },
          {
            "sentence": "К1алэм орэдыхэр '''къирегъа1о'''",
            "translation": "The boy '''is playing''' the songs (with a device/instrument)."
          },
          {
            "sentence": "Мыш макъэ бэу '''къегъэ1у'''",
            "translation": "This thing '''making''' too much '''noise'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁaʔʷən"
  },
  {
    "word": "чэтжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chick",
        "examples": []
      },
      {
        "meaning": "nestling",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼatʐəj"
  },
  {
    "word": "кьэтжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chick",
        "examples": []
      },
      {
        "meaning": "nestling",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲatʐəj"
  },
  {
    "word": "шъэжъыяц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blade",
        "examples": []
      }
    ],
    "synonyms": [
      "отычыцэ"
    ],
    "type": "noun",
    "ipa": "ʂaʐəjaːt͡s"
  },
  {
    "word": "отычыцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "axe blade",
        "examples": []
      },
      {
        "meaning": "blade",
        "examples": []
      }
    ],
    "synonyms": [
      "шъэжъыяц"
    ],
    "type": "noun",
    "ipa": "watət͡ʃət͡sa"
  },
  {
    "word": "гъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cry",
        "examples": [
          {
            "sentence": "К1алэр '''мэгъы'''",
            "translation": "The boy '''is crying'''."
          },
          {
            "sentence": "К1алэр л1ым '''фэгъы'''",
            "translation": "The boy '''is crying''' for the man."
          },
          {
            "sentence": "'''Умыгъ'''",
            "translation": "'''Don't cry'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁən"
  },
  {
    "word": "гъэгъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone cry",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''егъэгъы'''",
            "translation": "The boy '''is making''' the girl '''cry'''."
          },
          {
            "sentence": "К1алэр '''умгъэгъ'''",
            "translation": "'''Don't make''' the boy '''cry'''."
          },
          {
            "sentence": "К1алэц1ык1ор '''угъэгъы'''",
            "translation": "'''You are making''' the little boy '''cry'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʁən"
  },
  {
    "word": "мэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get destroyed, to get ruined",
        "examples": [
          {
            "sentence": "к1алэм унэ пшахъоу иш1ыгъэ '''мэот''' къещхымэ",
            "translation": "The boy's house sand '''will get ruined''' when it will rain."
          },
          {
            "sentence": "уикомпыйотэр '''мэуагъ'''",
            "translation": "Your computer '''is ruined'''."
          }
        ]
      },
      {
        "meaning": "(slang) to go all out and do something straightforward that might be embarrassing",
        "examples": [],
        "tags": [
          "slang",
          "alternative_spelling"
        ]
      },
      {
        "meaning": "(slang) something to be really noticeable to the point you can't ignore it",
        "examples": [],
        "tags": [
          "slang",
          "alternative_spelling"
        ]
      },
      {
        "meaning": "to hit",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawan"
  },
  {
    "word": "к1ьасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1асэ\"",
        "examples": []
      }
    ],

    "cognate": "к1асэ",
    "type": "adj",
    "ipa": "kʲʼaːsa"
  },
  {
    "word": "тэк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1эк1у\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мак1э",
      "т1эк1у"
    ],
    "type": "adv",
    "ipa": "takʷʼ"
  },
  {
    "word": "тэк1урэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "few times",
        "examples": [
          {
            "sentence": "К1алэр '''тэк1урэ''' сиунэ къак1о",
            "translation": "The boy is coming to my house '''few times'''."
          },
          {
            "sentence": "'''Тэк1урэ''' тхылъхэмэ саджэ",
            "translation": "I read the books '''few times'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "takʷʼra"
  },
  {
    "word": "тэк1у гъаш1эмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soon",
        "examples": [
          {
            "sentence": "'''Тэк1у гъаш1эмэ''' к1алэр сиунэ къэк1от",
            "translation": "'''Soon''' the boy will come to my house."
          },
          {
            "sentence": "'''Тэк1у гъаш1эмэ''' тышхэт, укъэдэшхэрагъуа?",
            "translation": "We gonna eat '''soon''', do you want to eat with us?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "takʷʼ ʁaːʃʼama"
  },
  {
    "word": "1эпапс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fingertip",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпапц1э"
    ],
    "type": "noun",
    "ipa": "ʔapaːpsʼa"
  },
  {
    "word": "папц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pointy (having a comparatively sharp point); sharp-pointed",
        "examples": [
          {
            "sentence": "Къэлэмэр '''папц1э'''",
            "translation": "The pencil '''is sharp'''."
          },
          {
            "sentence": "Шъэжъыем ицыпэ '''папц1э'''",
            "translation": "The knife's edge '''is sharp'''."
          },
          {
            "sentence": "Цацэр '''папц1э'''",
            "translation": "The fork '''is sharp'''."
          },
          {
            "sentence": "Къэцымрэ Кактусымрэ '''папц1эх'''",
            "translation": "The thorn and the cactus '''are sharps'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "paːpt͡sʼa"
  },
  {
    "word": "чэщы рэхьат къыок1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good night",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "t͡ʃaɕə raħaːt qəwakʷʼ"
  },
  {
    "word": "рэхьат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comfortable",
        "examples": [
          {
            "sentence": "'''Рэхьатэу''' ушыса?",
            "translation": "Are you sitting '''comfortably'''?"
          },
          {
            "sentence": "'''Рэхьата''' унэ?",
            "translation": "Is the home '''comfortable'''?"
          },
          {
            "sentence": "Еры сфае '''рэхьатэу''' сышхэнэу",
            "translation": "I want a place to be able to eat '''comfortably'''."
          },
          {
            "sentence": "Джанэр '''рэхьатэп'''",
            "translation": "The shirt '''is not comfortable'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гупсэф"
    ],
    "type": "adj",
    "ipa": "raħaːt"
  },
  {
    "word": "чэщы рэхьат къышъок1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good night (said to plural)",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "t͡ʃaɕə raħaːt qəʃʷakʷʼ"
  },
  {
    "word": "фэрэхьатын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be comfortable for",
        "examples": [
          {
            "sentence": "'''Къупфэрэхьата''' зэрэ ушысэ",
            "translation": "'''Are you comfortable''' with the way you are sitting?"
          },
          {
            "sentence": "'''Къэсфэрэхьат''' унэр",
            "translation": "The house is '''comfortable for me'''."
          },
          {
            "sentence": "К1алэм джанэр '''фэрэхьат'''",
            "translation": "The shirt '''is comfortable''' for the boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faraħaːtən"
  },
  {
    "word": "шъхьэ1атынгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pride",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaʔaːtənʁa"
  },
  {
    "word": "гъэуш1оин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "To make something dirty.",
        "examples": [
          {
            "sentence": "Джанэр '''умыгъэуш1ой'''",
            "translation": "'''Don't make''' the shirt '''dirty'''."
          },
          {
            "sentence": "Джанэр '''егъэуш1ои'''",
            "translation": "'''He is making''' the shirt '''dirty'''."
          }
        ]
      },
      {
        "meaning": "to delete, to erase",
        "examples": [
          {
            "sentence": "Фаилэр '''гъэуш1ой'''",
            "translation": "'''Delete''' the file."
          },
          {
            "sentence": "К1алэм тхыгъэр '''егъэуш1ои'''",
            "translation": "The boy '''is erasing''' the written."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawʂʷajən"
  },
  {
    "word": "иуш1оин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "To dirty.",
        "examples": [
          {
            "sentence": "лъым джанэ '''еуш1ои'''",
            "translation": "The blood '''dirty''' the shirt."
          },
          {
            "sentence": "псыжъым '''укъиуш1оит'''",
            "translation": "The mud '''will dirty you'''."
          },
          {
            "sentence": "чэтыум '''уиуш1оит'''",
            "translation": "The cat '''will dirty you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəwʃʷajən"
  },
  {
    "word": "дэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crash, to drive off",
        "examples": [
          {
            "sentence": "Мащинэр '''дафи''' исхэр мэук1ыгъэх",
            "translation": "The car '''crashed''' and the people inside died."
          },
          {
            "sentence": "Мащинэр псык1эу угъак1эмэ '''удэфэт'''",
            "translation": "If you drive the car fast '''you will crash'''."
          },
          {
            "sentence": "К1алэм байсикылымк1э '''дэфагъ'''",
            "translation": "The boy '''crashed''' with (using) the bicycle."
          }
        ]
      },
      {
        "meaning": "fall into some area",
        "examples": [
          {
            "sentence": "1эгуаур унэм ык1ыб '''дафагъ'''",
            "translation": "The ball '''fell into''' behind the house."
          }
        ]
      }
    ],
    "synonyms": [
      "дэфын"
    ],
    "type": "verb",
    "ipa": "dafan"
  },
  {
    "word": "дэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crash, to drive off",
        "examples": [
          {
            "sentence": "Мащинэр '''дафи''' исхэр мэук1ыгъэх",
            "translation": "The car '''crashed''' and the people inside died."
          },
          {
            "sentence": "Мащинэр псык1эу угъак1эмэ '''удэфэт'''",
            "translation": "If you drive the car fast '''you will crash'''."
          },
          {
            "sentence": "К1алэм байсикылымк1э '''дэфагъ'''",
            "translation": "The boy '''crashed''' with (using) the bicycle."
          }
        ]
      }
    ],
    "synonyms": [
      "дэфэн"
    ],
    "type": "verb",
    "ipa": "dafən"
  },
  {
    "word": "фапшэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breath",
        "examples": [
          {
            "sentence": "К1алэм '''фапщэ''' ыгъотырэп",
            "translation": "The boy can't find '''breath'''."
          },
          {
            "sentence": "Псым '''фапщэ''' шыбгъощтэп",
            "translation": "You will find '''breath''' in the water."
          }
        ]
      },
      {
        "meaning": "oxygen",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "faːpʃa"
  },
  {
    "word": "фэпшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэпщэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "жьы къэщэн"
    ],
    "type": "verb",
    "ipa": "fapʃan"
  },
  {
    "word": "нахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "more",
        "examples": []
      },
      {
        "meaning": "rather, though",
        "examples": []
      }
    ],

    "cognate": "нэхъ",
    "type": "adv",
    "ipa": "naːħ"
  },
  {
    "word": "нэхьыш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "better",
        "examples": [
          {
            "sentence": "'''Нэхьш1уа'''?",
            "translation": "'''Is it better?'''"
          },
          {
            "sentence": "Мэжъгъум тестэр '''нэхьш1у''' уш1ыгъа?",
            "translation": "Did you do the test '''better''' this time?"
          },
          {
            "sentence": "Тгъуасэ сэсмэджэгъагъ, непэ '''нэхьыш1у'''",
            "translation": "Yesterday I was sick, today it's better."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naħəʃʷʼə"
  },
  {
    "word": "мыщгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "this time",
        "examples": [
          {
            "sentence": "'''Мыщгъум''' тестэр нэхьш1у уш1ыгъа?",
            "translation": "Did you do the test better '''this time'''?"
          },
          {
            "sentence": "'''Мыщгъум''' уиунэ тыгъак1у",
            "translation": "'''This time''' walk us to your house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "məɕʁʷəm"
  },
  {
    "word": "джэщгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "at that time",
        "examples": [
          {
            "sentence": "'''Джэщгъум''' укъысэджэшъутэгъ",
            "translation": "'''At that time''' you could called me."
          }
        ]
      },
      {
        "meaning": "then",
        "examples": [
          {
            "sentence": "Сиянэ дэк1ымэ, '''джэщгъум''' тиунэ къак1у",
            "translation": "When my mother leaves, '''then''' come to our house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaɕʁʷəm"
  },
  {
    "word": "зтэгъэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lean on",
        "examples": [
          {
            "sentence": "К1алэм дэпкъым '''зтигъэк1агъ'''",
            "translation": "The boy '''leaned''' on the wall."
          },
          {
            "sentence": "Машинэ ш1оим '''зтэмгъак1'''",
            "translation": "'''Don't lean''' on the dirty car."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ztaʁat͡ʃan"
  },
  {
    "word": "мэтхъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхъэжьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matχaʑən"
  },
  {
    "word": "гъэлыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tickle",
        "examples": [
          {
            "sentence": "к1алэм пшъашъэр '''егъэлыджы'''",
            "translation": "The boy '''is tickling''' the girl."
          },
          {
            "sentence": "чэтыухэмэ '''сагъэлыджы'''",
            "translation": "The cats '''are tickling''' me."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaləd͡ʒən"
  },
  {
    "word": "мэлыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лыджын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maləd͡ʒən"
  },
  {
    "word": "мэчъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чъыен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʃəjan"
  },
  {
    "word": "тхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to write",
        "examples": [
          {
            "sentence": "К1алэр '''матхэ'''",
            "translation": "The boy is '''writing'''."
          },
          {
            "sentence": "Ар '''мэтхагъ'''",
            "translation": "He '''wrote'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "txan"
  },
  {
    "word": "мэтхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matxan"
  },
  {
    "word": "еплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"плъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "japɬən"
  },
  {
    "word": "мэплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look",
        "examples": [
          {
            "sentence": "К1алэр '''маплъэ'''",
            "translation": "The boy '''is looking'''."
          },
          {
            "sentence": "Л1ыжъ нэш1ур '''мэплъэшъурэп'''",
            "translation": "The blind old man '''can't look'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mapɬən"
  },
  {
    "word": "утэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get drunk",
        "examples": [
          {
            "sentence": "К1алэр '''мэутэшъуагъ'''",
            "translation": "The boy '''got drunk'''."
          },
          {
            "sentence": "К1алэр бирэ ешъи '''мэутэшъуагъ'''",
            "translation": "The boy drank beer and '''got drunk'''."
          }
        ]
      },
      {
        "meaning": "to get intoxicated",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wtaʃʷan"
  },
  {
    "word": "мэутэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"утэшъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wtaʃʷan"
  },
  {
    "word": "гъэутэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to intoxicate, to make someone drunk",
        "examples": [
          {
            "sentence": "К1алэр '''гъэуташъу'''",
            "translation": "'''Make the''' boy '''drunk'''."
          },
          {
            "sentence": "К1алэм Томэр '''игъэуташъуагъ'''",
            "translation": "The boy '''made''' Tom '''drunk'''."
          }
        ]
      },
      {
        "meaning": "to make someone spoiled in personality",
        "examples": [
          {
            "sentence": "Мо к1алэр '''агъэутэшъуагъ''', шхын лъап1э ехъурэ ышхыжьырэп",
            "translation": "'''They spoiled''' that kid, he doesn't eat anything than expensive food anymore."
          }
        ]
      },
      {
        "meaning": "to make someone attracted to you (usually by humor)",
        "examples": [
          {
            "sentence": "Пшъэшъэ дахэр '''къэгъэуташъу'''",
            "translation": "'''Make''' the beautiful girl '''attracted to you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawtaʃʷan"
  },
  {
    "word": "шъуты лэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar slang) to fool someone",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэм '''ишъуты илагъ'''",
            "translation": "The boy '''fooled''' the girl."
          },
          {
            "sentence": "'''Сишъуты улэшъутэп'''",
            "translation": "'''You can't fool me'''."
          },
          {
            "sentence": "К1алэхэмэ '''яшъуты ралагъ'''",
            "translation": "The boys '''were fooled'''."
          },
          {
            "sentence": "нэк1о '''ишъуты итлэни''' тышъуеджэжьыт",
            "translation": "Lets '''fool him''' and run from him."
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətə lan"
  },
  {
    "word": "мэш1ык1ьэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэш1ык1эен\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэш1ык1эен"
    ],
    "type": "verb",
    "ipa": "maʃʼəkʲʼajan"
  },
  {
    "word": "зегъэунэжъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "One that doesn't behave well, do naughty and mischievous things. One that does trouble.",
        "examples": [
          {
            "sentence": "КIалэм '''зегъэунэжъые'''",
            "translation": "The boy '''is does trouble'''."
          }
        ]
      }
    ],
    "synonyms": [
      "мэш1к1эен"
    ],
    "type": "verb",
    "ipa": "zeʁawnaʐəjan"
  },
  {
    "word": "гъэш1ык1эен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to allow or make someone to be naughty and do mischievous things; to allow or make someone to do trouble",
        "examples": [
          {
            "sentence": "'''Умыгъэш1ык1ай'''.",
            "translation": "'''Don't make him do naughty things'''."
          },
          {
            "sentence": "К1алэм ишынэхьык1 '''игъэш1ык1эягъ'''.",
            "translation": "The boy '''made''' his brother '''do trouble'''."
          },
          {
            "sentence": "К1алэр '''умыгъэш1ык1ай'''.",
            "translation": "'''Don't allow''' the boy '''to make trouble'''."
          },
          {
            "sentence": "Л1ым к1алэр '''егъэш1ык1аерэп'''.",
            "translation": "The man '''doesn't allow''' the boy '''to make trouble'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэш1ык1ьэен"
    ],
    "type": "verb",
    "ipa": "ʁaʃʼət͡ʃʼajan"
  },
  {
    "word": "мылъку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "assets, stuff, goods, property, wealth",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məɬəkʷ"
  },
  {
    "word": "лэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peer, contemporary, age-mate (one who is the same age as another)",
        "examples": [
          {
            "sentence": "'''Уилэгъухэр''' уиунэ къак1ох",
            "translation": "'''Your peers''' are coming to your house."
          },
          {
            "sentence": "'''Силэгъу''' горэ сэлъагъу",
            "translation": "I am seeing a '''peer of mine'''."
          },
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэлэгъух'''",
            "translation": "The boy and the girl are '''peers'''."
          },
          {
            "sentence": "К1алэр '''силэгъу'''",
            "translation": "The boy '''is my peer'''."
          }
        ]
      }
    ],

    "cognate": "ныбжьэгъу",
    "type": "noun",
    "ipa": "laʁʷ"
  },
  {
    "word": "гъэлэжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone work, to employee",
        "examples": [
          {
            "sentence": "Тхьэматэм к1алэхэр мафэм '''егъэлажьэх'''",
            "translation": "The boss '''is making''' the boys '''to work''' in the day."
          },
          {
            "sentence": "Л1ым е1уи '''уигъэлэжьэщт'''",
            "translation": "Tell the man '''and he will make you work''' - Tell the man and '''he will employee you'''."
          },
          {
            "sentence": "К1алэр '''сэгъэлажьэ'''",
            "translation": "'''I am making''' the boy '''work''' - '''I am employing''' the boy."
          },
          {
            "sentence": "Сик1алэ '''угъэлэжьэшъущта?'''",
            "translation": "'''Can you employee''' my boy?"
          }
        ]
      },
      {
        "meaning": "to make something function, to make something work",
        "examples": [
          {
            "sentence": "Сомпыютэрэр '''къыпфэгъэлажьа?'''",
            "translation": "'''Are you capable of making''' the computer '''to work'''?."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁalaʑan"
  },
  {
    "word": "яужым",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"аужым\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jaːwʒəm"
  },
  {
    "word": "дышъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gold",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "тэуц1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"теуц1элэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tawt͡sʼalan"
  },
  {
    "word": "мэджэгун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэгун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mad͡ʒaɡʷən"
  },
  {
    "word": "бзэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lick",
        "examples": [
          {
            "sentence": "Хьэр '''мэбзае'''",
            "translation": "The dog '''is licking'''."
          },
          {
            "sentence": "Хьэр к1алэм '''ебзэи'''",
            "translation": "The dog '''is licking''' the boy."
          },
          {
            "sentence": "Чэтыор '''къыопзэищт'''",
            "translation": "The cat '''will lick you'''."
          },
          {
            "sentence": "К1алэр айскыримым '''ебзэи'''",
            "translation": "The boy '''is licking''' the ice-cream."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bzajan"
  },
  {
    "word": "гуш1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rejoice",
        "examples": [
          {
            "sentence": "К1алэр '''мэгуш1о'''",
            "translation": "The boy '''is rejoicing'''."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) to laugh",
        "examples": [
          {
            "sentence": "К1алэр '''мэгуш1о'''",
            "translation": "The boy '''is laughing'''."
          },
          {
            "sentence": "'''Гуш1у''' тэк1урэ",
            "translation": "'''Laugh''' a bit."
          },
          {
            "sentence": "К1алэр '''мэгуш1орэп'''",
            "translation": "The boy '''is not laughing'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],

    "cognate": "гуф1эн",
    "type": "verb",
    "ipa": "ɡʷəʃʷʼan"
  },
  {
    "word": "кьэтхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэтхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʲʼatχan"
  },
  {
    "word": "шыгугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trust",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''шыгугъу'''",
            "translation": "The boy '''is trusting''' the girl."
          },
          {
            "sentence": "К1алэр '''къэсшыгугъу'''",
            "translation": "The boy '''is trusting me'''."
          },
          {
            "sentence": "'''Усшыгугъушъута''' къэсфэпхьынэу чатэр",
            "translation": "'''Can I trust you''' to bring the sword to me."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəɡʷʁʷan"
  },
  {
    "word": "ешъухъугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be jealous of",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''ешъухъуагъо'''",
            "translation": "The boy '''is jealous of''' the girl."
          },
          {
            "sentence": "К1алэр '''къысэшъухъуагъо'''",
            "translation": "The boy '''is jealous of me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʃʷχʷəʁʷan"
  },
  {
    "word": "мэшъухъугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be jealous",
        "examples": [
          {
            "sentence": "К1алэр '''мэшъухъуагъо'''",
            "translation": "The boy '''is jealous'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maʃʷχʷəʁʷan"
  },
  {
    "word": "еш1ужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make up with (to resolve, forgive or smooth over an argument or fight)",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''ешъужьы'''",
            "translation": "The boy '''is making up with ''' the girl."
          },
          {
            "sentence": "К1алэр '''яшъужьэгъ'''",
            "translation": "The boy '''made up with them'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʃʷʼəʑən"
  },
  {
    "word": "ехъырхъышэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ехъырэхъышэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaχərχəʃan"
  },
  {
    "word": "етын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to give to",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэм '''ритэгъ''' 1элъын",
            "translation": "The young-man '''gave''' the young-woman a ring."
          },
          {
            "sentence": "'''Къысэт''' 1эгуаор",
            "translation": "'''Give me''' the ball."
          },
          {
            "sentence": "Сэ джэгуалъэр Томым '''есэты'''",
            "translation": "'''I give''' the toy to Tom."
          },
          {
            "sentence": "Дахэу узак1омэ бэнанэ '''къостыщт'''",
            "translation": "If you behave well '''i will give you''' a banana."
          },
          {
            "sentence": "Уишы джэгуалъэр '''етба'''",
            "translation": "'''Give''' the toy to your brother already."
          },
          {
            "sentence": "Томым '''ет''' мые",
            "translation": "'''Give''' Tom an apple."
          },
          {
            "sentence": "К1алэм джэгуалъэр '''къыситрэп'''",
            "translation": "The boy '''doesn't give me''' the toy."
          },
          {
            "sentence": "К1элэцык1ум цацэр '''емыт'''",
            "translation": "'''Don't give''' the fork to the little boy."
          },
          {
            "sentence": "Л1ым тхьэмык1эмэ ахъщэ '''яреты'''",
            "translation": "The man '''gives''' the poor money."
          },
          {
            "sentence": "К1алэхэмэ '''ямыт''' тимыехэр",
            "translation": "'''Don't give''' the boys our apples."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jatən"
  },
  {
    "word": "хьэнцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьанцэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къазгъыр",
      "----"
    ],
    "type": "noun",
    "ipa": "ħant͡sa"
  },
  {
    "word": "гугъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "difficult",
        "examples": []
      }
    ],
    "synonyms": [
      "къины",
      "хьылъэ",
      "----"
    ],
    "type": "adj",
    "ipa": "ɡʷəʁʷ"
  },
  {
    "word": "пк1ыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dream",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ыхьап1"
    ],
    "cognate": "пщ1ыхьап1э",
    "type": "noun",
    "ipa": "pt͡ʃəħ"
  },
  {
    "word": "лъэхъан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time",
        "examples": []
      },
      {
        "meaning": "period",
        "examples": []
      },
      {
        "meaning": "moment",
        "examples": []
      }
    ],
    "synonyms": [
      "уахътэ"
    ],
    "cognate": "п1алъэ",
    "type": "noun",
    "ipa": "ɬaχaːn"
  },
  {
    "word": "пк1ыхьап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dream",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ыхь"
    ],
    "cognate": "пщ1ыхьап1э",
    "type": "noun",
    "ipa": "pt͡ʃəħaːpʼ"
  },
  {
    "word": "тхьаусхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to complain",
        "examples": [
          {
            "sentence": "К1алэр '''мэтхьаусхэ'''",
            "translation": "The boy '''is complaining'''."
          },
          {
            "sentence": "К1алэм иянэ '''фэтхьаусхэ'''",
            "translation": "The boy's mother '''is complaining for him'''."
          },
          {
            "sentence": "'''Умытхьаусхэу''' къуа1орэ ш1э",
            "translation": "'''Without complaining''' do what you told."
          },
          {
            "sentence": "Штэ '''шъотхьаусхэ''' енэу?",
            "translation": "Why '''you (plural)''' always '''complaining'''."
          }
        ]
      },
      {
        "meaning": "to allege",
        "examples": []
      }
    ],
    "synonyms": [
      "1оен"
    ],
    "type": "verb",
    "ipa": "tħaːwəsxan"
  },
  {
    "word": "мэтхьаусхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьаусхэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matħaːwəsxan"
  },
  {
    "word": "еубзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to curry favor with, to ingratiate oneself with, to flatter",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawəbzan"
  },
  {
    "word": "ехъопсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъопсэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaχʷapsan"
  },
  {
    "word": "гъэсмэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэсымэджэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэсмэгьэн"
    ],
    "type": "verb",
    "ipa": "ʁasmad͡ʒan"
  },
  {
    "word": "1упэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lipstick",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəpalan"
  },
  {
    "word": "пак1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "direction",
        "examples": [
          {
            "sentence": "К1алэр тиунэ '''пакьэ''' мак1о",
            "translation": "The boy is going to our home '''direction'''."
          },
          {
            "sentence": "А '''пакьэм''' умык1у",
            "translation": "Don't go that '''way/direction'''."
          },
          {
            "sentence": "Хым '''ипакьэк1э''' жьыбгъэ гъак1о",
            "translation": "The wind is coming from the sea's '''direction'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пак1э"
    ],
    "type": "noun",
    "ipa": "paːkʲʼa"
  },
  {
    "word": "фэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to permit",
        "examples": [
          {
            "sentence": "Уы к1алэм '''фэздэрэп''' дэк1ынэу.",
            "translation": "t='''I forbid''' that boy to go out."
          },
          {
            "sentence": "К1алэм '''фидагъ''' ишынахьык1 сомпыютэрэп 1уц1хьэнэу.",
            "translation": "t=The boy '''permit''' his younger brother to sit on the computer."
          },
          {
            "sentence": "Мэу сык1у '''къэсфэбэда'''?",
            "translation": "t=Do you '''permit me to go''' this way?"
          },
          {
            "sentence": "Уиянэ '''къупфеда''' къудэк1ыу?",
            "translation": "t=Does your mother '''permit you''' to go out?"
          },
          {
            "sentence": "Тихатэ шъушыджэгоу '''къушъуфаздэрэп'''.",
            "translation": "t='''I forbid''' you (plural) to play in our garden."
          },
          {
            "sentence": "Сидоктэр '''къэсфидэрэп''' бэнанэ сишхэу.",
            "translation": "t=My doctor '''forbade''' me to eat a banana."
          },
          {
            "sentence": "Том, к1алэм '''фад''' 1аш1у ишэфнэу",
            "translation": "t=Tom, '''permit''' the boy to buy a candy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fadan"
  },
  {
    "word": "1аш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эш1у\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaʃʷʼə"
  },
  {
    "word": "сырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "line",
        "examples": []
      },
      {
        "meaning": "column",
        "examples": []
      },
      {
        "meaning": "row",
        "examples": []
      },
      {
        "meaning": "queue (A line of people, vehicles or other objects)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "səra"
  },
  {
    "word": "нэк1э-нашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wink (a blinking of only one eye)",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1ао",
      "нак1э"
    ],
    "type": "noun",
    "ipa": "nakʲa naːʂħa"
  },
  {
    "word": "нэк1э-нашъхьэ фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wink to",
        "examples": []
      }
    ],
    "synonyms": [
      "енэк1эон",
      "нашъхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "nakʲa naːʂħa faʃʼən"
  },
  {
    "word": "нашъхьэ фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wink to",
        "examples": []
      }
    ],
    "synonyms": [
      "енэк1эон",
      "нэк1э-нашъхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "naːʂħa faʃʼən"
  },
  {
    "word": "енэк1эон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэк1эон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "janat͡ʃawan"
  },
  {
    "word": "нэк1ао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wink",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1э-нашъхьэ"
    ],
    "type": "noun",
    "ipa": "nat͡ʃʼawan"
  },
  {
    "word": "нэк1эон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wink at someone",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''енэк1ао'''",
            "translation": "t=The boy '''winks''' at the girl."
          }
        ]
      }
    ],
    "synonyms": [
      "нэк1э-нашъхьэ фэш1ын",
      "нэк1ьэон"
    ],
    "type": "verb",
    "ipa": "nat͡ʃʼawan"
  },
  {
    "word": "нак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wink (a blinking of only one eye)",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1ао",
      "нэк1э-нашъхьэ"
    ],
    "type": "noun",
    "ipa": "naːt͡ʃʼa"
  },
  {
    "word": "к1ьэлъыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1элъыон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1элъыон"
    ],
    "type": "verb",
    "ipa": "kʲʼaɬəwən"
  },
  {
    "word": "к1элъык1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to follow, to walk after",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''к1элъык1о'''",
            "translation": "The boy '''is following''' the man."
          }
        ]
      }
    ],
    "synonyms": [
      "лъык1он"
    ],
    "type": "verb",
    "ipa": "t͡ʃaɬəkʷʼan"
  },
  {
    "word": "псэо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "everyone, everything",
        "examples": [
          {
            "sentence": "'''Псэор''' къупшъошхыта?",
            "translation": "t=Can you eat '''everything?'''"
          },
          {
            "sentence": "'''Псэомк1и''' шъукъэда1у",
            "translation": "t='''Everyone''' listen."
          },
          {
            "sentence": "'''Псэуми''' о пшъашъэр дахэ я1о",
            "translation": "t='''Everyone''' says that girl is pretty."
          },
          {
            "sentence": "'''Псэум''' и1орэм уемдэ1у",
            "translation": "t=Don't listen to what '''everyone''' says."
          },
          {
            "sentence": "Унашъхьэм '''псэури''' къепк1эмэ укъепк1эта?",
            "translation": "t=If '''everyone''' jumps from then root will you jump?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psawa"
  },
  {
    "word": "лъык1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to follow, to walk after",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''лъэк1о'''",
            "translation": "The boy '''is following''' the man."
          },
          {
            "sentence": "Сач1ыб удэтыу '''къэслъык1у'''",
            "translation": "'''Follow me''' while you are behind me."
          },
          {
            "sentence": "Заом дзэл1ыхэр ятхьэматэ '''лъэк1ох'''",
            "translation": "In war the soldiers '''are following''' their leader."
          },
          {
            "sentence": "У к1алэм '''улъымык1у'''",
            "translation": "'''Don't walk after''' that boy."
          }
        ]
      }
    ],
    "synonyms": [
      "к1элъык1он"
    ],
    "type": "verb",
    "ipa": "ɬəkʷʼan"
  },
  {
    "word": "лъык1ошъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stalk (To approach slowly and quietly in order not to be discovered when getting closer)",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''лъэк1уашэ'''",
            "translation": "The boy '''is stalking''' the man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬəkʷʼaʂan"
  },
  {
    "word": "нэк1ьэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэк1эон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "нэк1э-нашъхьэ",
      "нэк1эон",
      "нак1э"
    ],
    "type": "verb",
    "ipa": "nakʲʼawan"
  },
  {
    "word": "енэк1ьэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэк1ьэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "janakʲawan"
  },
  {
    "word": "чъыетхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to snore",
        "examples": [
          {
            "sentence": "К1алэр '''мэчъыетхъу'''",
            "translation": "The boy '''snoring'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maʃt͡əjatχʷən"
  },
  {
    "word": "мэчъыетхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чъыетхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃt͡əjatχʷən"
  },
  {
    "word": "егупшысэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гупшысэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɡʷəpʃəsan"
  },
  {
    "word": "гупшысэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thought",
        "examples": [
          {
            "sentence": "Си '''гупшысэк1э''' машинэ дахэ си1",
            "translation": "'''In''' my '''thought''' I have a beautiful car."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəpʃəsa"
  },
  {
    "word": "ч1ыфэ ч1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be in debt",
        "examples": [
          {
            "sentence": "К1алэр '''ч1ыфэ ч1эт'''",
            "translation": "The boy '''is in debt'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃəfa t͡ʃʼatən"
  },
  {
    "word": "мэкьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "makʲan"
  },
  {
    "word": "дек1эк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be in favor of someone, to support someone",
        "examples": [
          {
            "sentence": "К1алэр '''къесдек1эк1ьы'''",
            "translation": "The boy '''is supporting me'''."
          },
          {
            "sentence": "Сэсымаджэм сиянэ '''къэсдек1эк1ьaгъ'''",
            "translation": "When i was sick my mother '''supported me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dait͡ʃakʲən"
  },
  {
    "word": "хьэинап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьайнапэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħajənaːp"
  },
  {
    "word": "дэхьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "entrance, entry",
        "examples": []
      }
    ],
    "synonyms": [
      "дэхьэгъу"
    ],
    "type": "noun",
    "ipa": "daħaːpʼa"
  },
  {
    "word": "къэлапчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gate",
        "examples": [
          {
            "sentence": "К1алэр '''къэлапчъэм''' блэк1ышъурэп",
            "translation": "The boy is not capable of passing '''the gate'''."
          }
        ]
      }
    ],
    "synonyms": [
      "чэучъ",
      "кьэучъ"
    ],
    "type": "noun",
    "ipa": "qalaːpt͡ʃ"
  },
  {
    "word": "пчъэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пчъэ1упэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃaʔʷə"
  },
  {
    "word": "пчъэ1упэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "In front of the entrance, the entrance door",
        "examples": [
          {
            "sentence": "'''Пчъэ1упэм''' шъу1утысхь",
            "translation": "Sit '''in front of the house entrance'''."
          }
        ]
      },
      {
        "meaning": "porch",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэгуц"
    ],
    "type": "noun",
    "ipa": "pt͡ʃaʔʷəpa"
  },
  {
    "word": "мэук1ытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1ытэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ук1ьытэн"
    ],
    "type": "verb",
    "ipa": "mawt͡ʃətan"
  },
  {
    "word": "къузмэзадэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one that is resting after a great meal",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷəzmazaːda"
  },
  {
    "word": "жъэбэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tubby, one that is short and fat",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐabaq"
  },
  {
    "word": "ч1ыфэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "debt",
        "examples": [
          {
            "sentence": "К1алэр '''ч1ыфэ''' ч1эт",
            "translation": "The boy is in '''debt'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂʼəfa"
  },
  {
    "word": "хэ1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break the fast",
        "examples": [
          {
            "sentence": "Муслымхэр нэк1мазэм пч1ыхьэм '''хэ1ажьых'''",
            "translation": "The Muslim's in the Ramadan '''break the fast''' in the evening."
          },
          {
            "sentence": "Нэпч1ыхьэ лык1э '''тыхэ1эжьыт'''",
            "translation": "Tonight '''we gonna break the fast''' with meat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʔaʑən"
  },
  {
    "word": "гупсэф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comfortable",
        "examples": [
          {
            "sentence": "Унэ '''гупсэф'''",
            "translation": "'''Сomfortable''' house."
          },
          {
            "sentence": "Мы мащинэр '''гупсэфа?'''",
            "translation": "Is this car '''comfortable'''."
          }
        ]
      },
      {
        "meaning": "comfort",
        "examples": []
      }
    ],
    "synonyms": [
      "рэхьат"
    ],
    "type": "adj",
    "ipa": "ɡʷəpsaf"
  },
  {
    "word": "гупсэфып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comfortable place",
        "examples": [
          {
            "sentence": "Унэр '''гупсэфып1'''",
            "translation": "The house is a '''comfortable place'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəpsafəpʼ"
  },
  {
    "word": "ады",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"адэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʔaːdə"
  },
  {
    "word": "хъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move",
        "examples": [
          {
            "sentence": "К1алэр '''мэхъые'''",
            "translation": "The boy '''is going'''."
          },
          {
            "sentence": "К1элэцык1ор '''мэхъыешъуа?'''",
            "translation": "'''Can''' the little boy '''move?'''."
          },
          {
            "sentence": "Л1ыжъэр '''мэхъыешъурэп'''",
            "translation": "The old man '''can't move'''."
          },
          {
            "sentence": "'''Шъухъыеба''' сэ1о",
            "translation": "'''Move''' I say (said to plural)."
          },
          {
            "sentence": "Нэущынэс мыжъом уеплъыгъэми '''мэмыхъыен'''",
            "translation": "Even if you look at the rock until tomorrow '''it won't move'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "χəjan"
  },
  {
    "word": "мэхъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъыен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maχəjan"
  },
  {
    "word": "зэхъок1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэхъок1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaχʷakʲʼən"
  },
  {
    "word": "зэхъок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to change something, to make something different",
        "examples": [
          {
            "sentence": "К1алэм имащинэ '''зихъук1эгъ'''",
            "translation": "The boy '''has changed''' his car."
          },
          {
            "sentence": "Гьирэ '''зэхъок1'''",
            "translation": "'''Change''' the gear."
          }
        ]
      },
      {
        "meaning": "to become different",
        "examples": [
          {
            "sentence": "Л1ыжъымэ яшъхьэц ишъо фыжьэу '''зэхъок1ы'''",
            "translation": "The old-men's hair's color '''are changing to''' white."
          },
          {
            "sentence": "Томэр зыш1у '''зэхъок1эгъ'''",
            "translation": "Tom '''has changed''' a lot."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaχʷat͡ʃʼən"
  },
  {
    "word": "пхъэт1эк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пхъэнт1эк1у\"",
        "examples": []
      }
    ],
    "synonyms": [
      "онэгу",
      "пхъэнт1эк1у"
    ],
    "type": "noun",
    "ipa": "pχatʼakʷʼ"
  },
  {
    "word": "къэшъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dancer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaʃʷaːkʷʼa"
  },
  {
    "word": "ешъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drinker; a person that drinks alcohol often",
        "examples": []
      }
    ],
    "synonyms": [
      "ешъорый"
    ],
    "cognate": "ефак1уэ",
    "type": "noun",
    "ipa": "jaʃʷʼaːkʷʼa"
  },
  {
    "word": "мэхъаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cruel",
        "examples": []
      },
      {
        "meaning": "murderer",
        "examples": []
      }
    ],
    "synonyms": [
      "ук1ак1о",
      "л1ыук1"
    ],
    "type": "adj",
    "ipa": "maχaːd͡ʒa"
  },
  {
    "word": "еджак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pupil, student",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jad͡ʒaːkʷʼa"
  },
  {
    "word": "мылъыку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мылъку\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məɬəkʷ"
  },
  {
    "word": "ахъщэнэпц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Forfeited money",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχɕanapt͡sʼ"
  },
  {
    "word": "ахъщэмык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "counterfeit money",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃaməkʷʼ"
  },
  {
    "word": "ахъщэнэпц1ш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forfeiter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃanapt͡sʼʃʼ"
  },
  {
    "word": "ахъщэжъгъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small money",
        "examples": []
      },
      {
        "meaning": "coin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃaʐʁaj"
  },
  {
    "word": "ахъщэбэдзэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stock market",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃabad͡zar"
  },
  {
    "word": "ахъщэзэблэхъущ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Exchange office, bank",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃazablaχʷən"
  },
  {
    "word": "ахъщэдэфый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "A coin toss, a coin flip.",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃadafəj"
  },
  {
    "word": "ахъщэбэг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "inflation",
        "examples": []
      },
      {
        "meaning": "(finance) inflation",
        "examples": [],
        "tags": [
          "finance"
        ]
      }
    ],
    "type": "noun",
    "ipa": "aːχʃabaɣ"
  },
  {
    "word": "ахъщалъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wallet, purse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχɕaːɬ"
  },
  {
    "word": "ахъщапс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "miser, stingy person",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːχʃaːps"
  },
  {
    "word": "ахъщашъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "capital",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "aːχʃaːʂħ"
  },
  {
    "word": "пщэрыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "servant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕarəħ"
  },
  {
    "word": "пщэрыхьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chef (in a restaurant)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕarəħaːkʷʼa"
  },
  {
    "word": "плъыжьыбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "red blood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬəʑəbz"
  },
  {
    "word": "л1ыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "valiant",
        "examples": []
      },
      {
        "meaning": "hero",
        "examples": []
      }
    ],
    "synonyms": [
      "л1ыхъужъ"
    ],
    "type": "adj",
    "ipa": "ɬʼəχʷ"
  },
  {
    "word": "л1ыхъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "valiant",
        "examples": []
      },
      {
        "meaning": "hero",
        "examples": []
      }
    ],
    "synonyms": [
      "л1ыхъу"
    ],
    "type": "adj",
    "ipa": "ɬʼəχʷəʐ"
  },
  {
    "word": "нэпкъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bank, shore, coast, beach",
        "examples": [
          {
            "sentence": "Ащ пцэжъые горэ ылъэгъугъ псы '''нэпкъым''' псым хэхьажьын ымэлъэк1эу 1улъэу",
            "translation": "He saw a fish that was laying '''in the coast''' of the water that was unable to return to the water."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "napqə"
  },
  {
    "word": "к1ол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ек1ол1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jakʷʼaɬʼan"
  },
  {
    "word": "ш1ык1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ык1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ык1э"
    ],
    "cognate": "щ1ык1э",
    "type": "noun",
    "ipa": "ʃʼəkʲʼa"
  },
  {
    "word": "гъунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "border, edge, brink, brim, fringe",
        "examples": [
          {
            "sentence": "Хьасэ '''гъун'''",
            "translation": "'''Border''' of the field."
          },
          {
            "sentence": "Мэз '''гъунэм''' дэжь щылъ",
            "translation": "He is lying near the '''border''' of the forest."
          },
          {
            "sentence": "Пцэжъыер псы '''гъунэм''' къек1ол1э",
            "translation": "The fish is approaching '''the edge''' of the water."
          },
          {
            "sentence": "Саусырыкъо мэз '''гъунэм''' тыгъужъ ныкъуал1э горэ 1улъэу ылъэгъугъ",
            "translation": "Sosruko saw a half dead wolf laying '''on the edge''' of the forest."
          }
        ]
      },
      {
        "meaning": "outskirts (the edges or areas around a city or town)",
        "examples": [
          {
            "sentence": "Чылэ '''гъун'''",
            "translation": "'''Outskirts''' of the village"
          }
        ]
      },
      {
        "meaning": "end",
        "examples": [
          {
            "sentence": "1офым '''гъунэ''' фэхъугъ",
            "translation": "It became the '''end''' of the work for him."
          }
        ]
      }
    ],
    "synonyms": [
      "к1эух",
      "гъунапкъэ"
    ],
    "cognate": "нэз",
    "type": "noun",
    "ipa": "ʁʷəna"
  },
  {
    "word": "щабзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bow",
        "examples": [
          {
            "sentence": "'''Щабзэк1э''' Саусырыкъо еуи бгъэжъыр иук1ыгъ",
            "translation": "Sosruko shoot the eagle '''with a bow''' and killed it."
          }
        ]
      }
    ],
    "synonyms": [
      "к1уснэщаб"
    ],
    "type": "noun",
    "ipa": "ɕaːbza"
  },
  {
    "word": "гъомылапхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "food products",
        "examples": [
          {
            "sentence": "Саусырыкъо '''гъомылапхъэу''' ы1ыгъым щыщхэр тыгъужъым ритыгъэх",
            "translation": "Sosruko gave the wolf some of '''the food''' he had."
          }
        ]
      },
      {
        "meaning": "nutritional material",
        "examples": []
      },
      {
        "meaning": "foodstuffs",
        "examples": []
      }
    ],

    "cognate": "гъуэмылапхъэ",
    "type": "noun",
    "ipa": "ʁʷaməlaːpχa"
  },
  {
    "word": "ныкъуал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak",
        "examples": []
      },
      {
        "meaning": "half dead",
        "examples": [
          {
            "sentence": "Саусырыкъо мэз гъунэм тыгъужъ '''ныкъуал1э''' горэ 1улъэу ылъэгъугъ",
            "translation": "Sosruko saw a '''half dead''' wolf laying on the edge of the forest."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "nəqʷaːɬʼa"
  },
  {
    "word": "языфагу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"азыфагу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jːzəfaːɡʷ"
  },
  {
    "word": "гъэпщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to inflate, to cause something to become bigger in a round shape (usually by air)",
        "examples": [
          {
            "sentence": "К1алэм бэлунэр '''егъэпщы'''",
            "translation": "The boy '''is inflating''' the balloon."
          },
          {
            "sentence": "Бэлунэр башэу '''угъэпщымэ''' къэот",
            "translation": "'''If you inflate''' the balloon too much it will explode."
          },
          {
            "sentence": "Уилъакъо сыкъеони '''сгъэпщыт'''",
            "translation": "I will hit your leg '''and swell it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁapɕən"
  },
  {
    "word": "гъэпшъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tire, to exhaust",
        "examples": [
          {
            "sentence": "К1алэм '''сегъэпшъы'''",
            "translation": "The boy '''is exhausting me'''."
          },
          {
            "sentence": "Л1ыжъым къы1отагъ зэк1эм '''тыгъэпшъыгъ'''",
            "translation": "All the things the old-man told '''exhausted us'''."
          },
          {
            "sentence": "Мо к1алэхэр бэрэ сыгъэчъэнхи ''сыгъэпшъыщтых''",
            "translation": "I will make those boys run a lot '''and will tire them'''."
          },
          {
            "sentence": "Чэщым '''сагъэпшъыгъэу''' унэм сыкъехьэжьы",
            "translation": "At night I return home '''while they tired me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁapʂən"
  },
  {
    "word": "мэпщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пщын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mapɕən"
  },
  {
    "word": "къапщыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sack",
        "examples": []
      }
    ],
    "synonyms": [
      "щэуалэ",
      "дзыо"
    ],
    "type": "noun",
    "ipa": "qaːpɕəq"
  },
  {
    "word": "гъэш1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cherish, to caress, to pamper",
        "examples": [
          {
            "sentence": "Якъубэм Юсыфэр анахь '''ыгъэш1уагъ'''",
            "translation": "Jacob '''cherished''' Joseph the most."
          },
          {
            "sentence": "Джанэ уцышъохэр анахь '''сэгъаш1ох'''",
            "translation": "I '''cherish''' the green shirts the most."
          },
          {
            "sentence": "Томым ык1алэ зыш1у '''егъэш1о'''",
            "translation": "Tom '''is''' really '''cherishing''' his son."
          }
        ]
      },
      {
        "meaning": "to spare something (for the future)",
        "examples": [
          {
            "sentence": "Ахъщэ '''умыгъаш1оу''' ч1эф",
            "translation": "Waste money '''without sparing it'''."
          },
          {
            "sentence": "К1алэм псэу и1эр '''егъаш1о'''",
            "translation": "The boy '''spares''' the water he got."
          },
          {
            "sentence": "Ахъщэ сэ '''сэгъаш1о''' унэ сышэфын пае",
            "translation": "'''I am saving''' money to buy a house"
          }
        ]
      }
    ],
    "synonyms": [
      "едэхэш1эн"
    ],
    "type": "verb",
    "ipa": "ʁaʃʷʼan"
  },
  {
    "word": "пэгъок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to greet someone",
        "examples": [
          {
            "sentence": "Тихьак1эхэр унэм къэсыхэмэ '''япэгъок1'''",
            "translation": "When our guests arrive our house, '''greet them'''."
          },
          {
            "sentence": "Мэзым сыхэлъадэм нэгъук1ыцэжъ '''къэспэгъок1эгъ'''",
            "translation": "When I ran into the forest, a witch '''greeted me'''."
          },
          {
            "sentence": "Чылэ къэтык1охьэу '''къэтпэгъок1ыгъэх''' тиныбджэгъухэр",
            "translation": "When we were walking around in the village '''we were greeted by''' our friends."
          }
        ]
      }
    ],
    "synonyms": [
      "пэгъок1ьын"
    ],
    "type": "verb",
    "ipa": "paʁʷat͡ʃən"
  },
  {
    "word": "пэгъок1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пэгъок1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пэгъок1ын"
    ],
    "type": "verb",
    "ipa": "paʁʷakʲən"
  },
  {
    "word": "к1апэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fat tail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaːpa"
  },
  {
    "word": "1уш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wise",
        "examples": []
      },
      {
        "meaning": "clever, smart, intelligent",
        "examples": []
      }
    ],
    "cognate": "акъыл",
    "type": "adj",
    "ipa": "ʔʷəʃ"
  },
  {
    "word": "ухъумэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take care of, to safeguard, to watch over",
        "examples": [
          {
            "sentence": "А шхынэр сэ '''къэшъуухъумэнэу''' къышъостыгъагъ",
            "translation": "I gave you that foot '''so that you will keep it safe'''. (Said to plural)"
          }
        ]
      },
      {
        "meaning": "to protect",
        "examples": [
          {
            "sentence": "хэгъэгур ухъумэн",
            "translation": "Protect the country."
          },
          {
            "sentence": "ынапэ ыухъумэн",
            "translation": "Protect your face (avoid shame)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wχʷəman"
  },
  {
    "word": "еугъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"угъоин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawəʁʷajən"
  },
  {
    "word": "угъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather",
        "examples": [
          {
            "sentence": "К1алэм мыжъохэр '''еугъоих'''",
            "translation": "The boy '''gathers''' rocks."
          },
          {
            "sentence": "К1алэхэр '''къэугъоихи''' тыджэгущт",
            "translation": "'''Gather''' the boys and we will play."
          },
          {
            "sentence": "Хасэм итхьэматэхэр '''зэрэугъоих'''",
            "translation": "The council's leaders '''are gathering'''."
          },
          {
            "sentence": "К1элэц1ык1ухэр '''къэугъоих'''",
            "translation": "'''Gather''' the children here."
          }
        ]
      },
      {
        "meaning": "to collect",
        "examples": [
          {
            "sentence": "Мыжъо фыжьыхэр '''сэугъоих'''",
            "translation": "'''I collect''' white stones."
          }
        ]
      }
    ],
    "synonyms": [
      "къэугъоин",
      "зэк1эугъоен",
      "зэхэугъоен",
      "щыпын"
    ],
    "type": "verb",
    "ipa": "wəʁʷajən"
  },
  {
    "word": "къэрэгъул",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guard",
        "examples": [
          {
            "sentence": "'''Къэрэгъулэхэр''' пчъэм 1утых",
            "translation": "'''The guards''' are standing near the door."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaraʁʷəl"
  },
  {
    "word": "чылапхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seed",
        "examples": [
          {
            "sentence": "К1алэм '''чылапхъэхэр''' ч1ыгум хет1эх",
            "translation": "The boy implants '''the seeds'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəlaːpχ"
  },
  {
    "word": "есэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get used to",
        "examples": [
          {
            "sentence": "К1алэр '''есагъ''' пчэдыжьым пасэу къэущыжьнэу",
            "translation": "The boy '''got used to''' wake early on the morning."
          },
          {
            "sentence": "Сизакъоу ск1онэу '''сесагъ'''",
            "translation": "'''I got used to''' go alone."
          },
          {
            "sentence": "Мощтэу ушхэу '''земыгъас'''",
            "translation": "'''Don't get used to''' eat like that."
          },
          {
            "sentence": "Бэрэ чъи '''уесэжьыт'''",
            "translation": "Run a lot '''and you will get used to it'''."
          },
          {
            "sentence": "Мы чъы1эм '''сесэ'''",
            "translation": "'''I am getting used to''' this cold."
          }
        ]
      },
      {
        "meaning": "to close",
        "examples": [
          {
            "sentence": "Пчъэр '''есэ'''",
            "translation": "The door '''closes'''."
          },
          {
            "sentence": "Пчъэр '''есэрэп'''",
            "translation": "The door '''does not close'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jasan"
  },
  {
    "word": "де1этаен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deʔataːjan"
  },
  {
    "word": "дебзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut with, to split with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "debzən"
  },
  {
    "word": "дэугощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to distribute with, to divide with,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dawɡʷaɕən"
  },
  {
    "word": "угощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гощын\"",
        "examples": []
      }
    ],

    "cognate": "хуэгуэшын",
    "type": "verb",
    "ipa": "wəɡʷaɕən"
  },
  {
    "word": "егук1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1унк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɡʷət͡ʃan"
  },
  {
    "word": "гун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷən"
  },
  {
    "word": "егун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crush, to mash, to pound, to squash",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɡʷən"
  },
  {
    "word": "дегун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to squash with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deɡʷən"
  },
  {
    "word": "дегупшысэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to think with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deɡʷəpʃəsan"
  },
  {
    "word": "дегъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to flip over, to turn over with, to turn with, to twist with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deʁazan"
  },
  {
    "word": "гъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dehydrate; to dry out; to remove water content from an object, plant or a living thing",
        "examples": [
          {
            "sentence": "Уцым псы есымтэу '''сыгъэгъогъ'''",
            "translation": "Without giving the plant water, '''I dried it'''."
          },
          {
            "sentence": "Бэнанэ башэрэ шыбгъэлъымэ '''угъэгъут'''",
            "translation": "If you leave a banana for too long '''you will rot it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʁʷən"
  },
  {
    "word": "мэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁʷən"
  },
  {
    "word": "мэгъушъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъушъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁʷʂən"
  },
  {
    "word": "тэгъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down on",
        "examples": [
          {
            "sentence": "К1алэр п1эм '''тэгъолъхьагъ'''",
            "translation": "The boy '''lied down on''' is bed."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁʷaɬħaːn"
  },
  {
    "word": "мэгъолъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъолъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁʷaɬən"
  },
  {
    "word": "ч1эгъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down under",
        "examples": [
          {
            "sentence": "Чъыгы ч1эгъым '''сыч1эгъолъхьэ'''",
            "translation": "'''I am lying down under''' is tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʁʷaɬħaːn"
  },
  {
    "word": "хэгъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down among",
        "examples": [
          {
            "sentence": "К1алэр мэзым '''хэгъолъхьагъ'''",
            "translation": "The boy '''was laying down in''' the forest."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʁʷaɬħaːn"
  },
  {
    "word": "дэгъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down inside another object",
        "examples": [
          {
            "sentence": "Дракулэ пхъэмбаим '''дэгъолъхьэ'''",
            "translation": "Dracula '''lays down inside''' the coffin."
          }
        ]
      },
      {
        "meaning": "to lay down in",
        "examples": [
          {
            "sentence": "К1алэр щагум '''дэгъолъхьагъ'''",
            "translation": "The boy '''laid down in''' the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁʷaɬħaːn"
  },
  {
    "word": "1огъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down on",
        "examples": [
          {
            "sentence": "Къалэ тхьамышк1ыхэмэ ц1ыфыхэр гъогум '''1огъуалъхьэх'''",
            "translation": "In poor cities people '''lay down near''' the streets."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁʷaɬħaːn"
  },
  {
    "word": "игъолъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down on",
        "examples": [
          {
            "sentence": "К1алэр унэм '''игъолъхьагъ'''",
            "translation": "The boy '''laid down in''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁʷaɬħaːn"
  },
  {
    "word": "дегъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэгъэбылъхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "deʁabəɬħan"
  },
  {
    "word": "ч1егъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide under",
        "examples": [
          {
            "sentence": "К1алэм 1анэ ч1эгъым '''зч1егъэбылъхьэ'''",
            "translation": "The boy '''is hiding''' under the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼeʁabəɬħan"
  },
  {
    "word": "хегъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide in",
        "examples": [
          {
            "sentence": "Псым мыжъохэр '''хэзгъэбылъхьагъэх'''",
            "translation": "'''I hide''' the rocks in the water."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xeʁabəɬħan"
  },
  {
    "word": "ригъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide inside",
        "examples": [
          {
            "sentence": "Ар унэм '''игъэбылъхь'''",
            "translation": "'''Hide''' that inside the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "riʁabəɬħan"
  },
  {
    "word": "тегъэбылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide on",
        "examples": [
          {
            "sentence": "К1алэм унашъхьэм джэгуалъэр '''тегъэбылъхьагъ'''",
            "translation": "The boy '''hid''' the toy on the roof."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "teʁabəɬħan"
  },
  {
    "word": "джак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "caller",
        "examples": []
      },
      {
        "meaning": "spinner",
        "examples": []
      },
      {
        "meaning": "Yarn maker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːkʷʼa"
  },
  {
    "word": "джанкъылъыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Djanqilish (Circassian game)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːnəɬəɕ"
  },
  {
    "word": "джасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "knife (a kind of old knife)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːsa"
  },
  {
    "word": "джарэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and so",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːraw"
  },
  {
    "word": "джаргъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "obstinate, stubborn",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒaːrʁan"
  },
  {
    "word": "джау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "at that, there",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːw"
  },
  {
    "word": "джаузэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and so",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːwza"
  },
  {
    "word": "джаущтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "and so",
        "examples": []
      },
      {
        "meaning": "like that",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːwəɕtaw"
  },
  {
    "word": "джашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bald",
        "examples": []
      },
      {
        "meaning": "bare",
        "examples": []
      },
      {
        "meaning": "poor",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒaːʃʷa"
  },
  {
    "word": "джащыгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джащгъум\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒaːɕəʁʷəm"
  },
  {
    "word": "джынапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "devil (Jinn)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒənaːpt͡sʼa"
  },
  {
    "word": "джынджыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Djenjef (an Circassian mythological horse),",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒənd͡ʒəf"
  },
  {
    "word": "джыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cotton, hair, wool, Yarn material",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒənə"
  },
  {
    "word": "джынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jinn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəna"
  },
  {
    "word": "джынэузэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sanitarium",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒənawzaɕ"
  },
  {
    "word": "джырыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "graceless, insolent, rude",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒərəqʷ"
  },
  {
    "word": "джырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "present",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəra"
  },
  {
    "word": "джырэблагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "In a while, Since a short time, soon",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒərablaːr"
  },
  {
    "word": "джырэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "at present",
        "examples": []
      },
      {
        "meaning": "for the time being",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒərat͡ʃʼa"
  },
  {
    "word": "джыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Mowing (grass)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəħaːn"
  },
  {
    "word": "джыхьынэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəħənam"
  },
  {
    "word": "джэгогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "playing mate; playmate",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaɡʷaʁʷ"
  },
  {
    "word": "джэгуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "actor",
        "examples": []
      },
      {
        "meaning": "player (One who plays any game or sport.)",
        "examples": []
      },
      {
        "meaning": "player (One who plays on a musical instrument.)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaɡʷaːkʷʼa"
  },
  {
    "word": "джэгуп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stadium",
        "examples": []
      },
      {
        "meaning": "playground",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaɡʷəpʼa"
  },
  {
    "word": "джагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hated, despised",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒaːʁʷa"
  },
  {
    "word": "уджэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hate, to dislike",
        "examples": [
          {
            "sentence": "К1алэр мые '''еуджэгъу'''",
            "translation": "The boy '''dislikes''' apple."
          },
          {
            "sentence": "Ащ сэ '''сеуджэгъу'''",
            "translation": "He '''hates me'''."
          },
          {
            "sentence": "'''Усыуджэгъунэу''' уфая?",
            "translation": "Do you want me '''to hate you?'''"
          },
          {
            "sentence": "К1алэм Томэр '''ыуджэгъу'''",
            "translation": "The boy '''hates''' Tom."
          }
        ]
      }
    ],
    "synonyms": [
      "джэгъун"
    ],
    "cognate": "ужэгъун",
    "type": "verb",
    "ipa": "wəd͡ʒaʁʷən"
  },
  {
    "word": "еуджэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уджэгъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawd͡ʒaːʁʷən"
  },
  {
    "word": "джэгъол1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "despised husband or man",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaʁʷaɬʼ"
  },
  {
    "word": "джэдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spinach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒad͡z"
  },
  {
    "word": "джэлъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gasp, to pant",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaɬən"
  },
  {
    "word": "джэлыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be confused, to be disturbed",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaɬəʁʷan"
  },
  {
    "word": "джэндын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall, to slip, to trip",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒandən"
  },
  {
    "word": "джэнчыдз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "diviner, fortune teller",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒant͡ʃəd͡z"
  },
  {
    "word": "джэнджэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "advice, consultation",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒand͡ʒaʃ"
  },
  {
    "word": "джэныкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fireplace",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒanəqʷ"
  },
  {
    "word": "джэрыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to loiter, to ramble, to wander",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒarəwan"
  },
  {
    "word": "джэрыуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "loiterer, rambler, tramp",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒarəwaːkʷʼa"
  },
  {
    "word": "джэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bright color",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒafə"
  },
  {
    "word": "к1оц1ыщыхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to enwrap, to envelope, to wrap",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼat͡sʼəɕəħan"
  },
  {
    "word": "шъэо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъао\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1алэ"
    ],
    "cognate": "щауэ",
    "type": "noun",
    "ipa": "ʂawa"
  },
  {
    "word": "копкъышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"копкъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷapqəʂħa"
  },
  {
    "word": "псыхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to temper (to heat-treat)",
        "examples": []
      },
      {
        "meaning": "to temper, to harden, to inure",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "psəħan"
  },
  {
    "word": "плъышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guide, mark, similar, similarity",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬəʃʷa"
  },
  {
    "word": "пкъышъолы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "body",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pqəʃʷalə"
  },
  {
    "word": "шъабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soft",
        "examples": []
      },
      {
        "meaning": "meek",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂaːba"
  },
  {
    "word": "лэгъупэкъопс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rainbow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laʁʷəpaʕʷaps"
  },
  {
    "word": "лэгъупышху",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "great copper boiler",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laʁʷəpəʃxʷ"
  },
  {
    "word": "ш1ош1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hypothesis",
        "examples": []
      }
    ],

    "cognate": "ф1эщ",
    "type": "noun",
    "ipa": "ʃʷʼaʃʷ"
  },
  {
    "word": "ш1ош1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ошъын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ошъын"
    ],
    "type": "verb",
    "ipa": "ʃʷaʃʷən"
  },
  {
    "word": "ш1ош1у мэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to believe",
        "examples": [
          {
            "sentence": "К1алэм и1орэ пшъашъэм '''иш1ош1у мэхъу'''",
            "translation": "The girl '''is believing''' in what the boy tells."
          },
          {
            "sentence": "Къэп1орэ '''сиш1ош1у мэхъу'''",
            "translation": "'''I am believing''' in what you say."
          },
          {
            "sentence": "Къэп1орэ к1алэм '''иш1ош1у мэхъурэп'''",
            "translation": "The boy '''doesn't believe''' in what I say."
          },
          {
            "sentence": "Сэ Тхьэ '''сиш1ош1у мэхъу'''",
            "translation": "'''I believe''' in God."
          },
          {
            "sentence": "А к1алэм еп1ок1упэрэ '''иш1ош1у мэхъу'''",
            "translation": "That boy '''believes''' everything you told him."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ошъ хъун"
    ],
    "type": "verb",
    "ipa": "ʃʷaʃʷ χʷən"
  },
  {
    "word": "ш1ош1ухъуныгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "faith",
        "examples": [
          {
            "sentence": "'''Ш1ош1ухъунгъэ''' уи1эн фае",
            "translation": "You need to have a '''faith'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ошъхъуныгъэ"
    ],
    "type": "noun",
    "ipa": "ʃʷʼaʃʷʼχʷənʁa"
  },
  {
    "word": "тэкуон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scream at, to yell at",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэм '''тэкуо'''",
            "translation": "The boy '''is yelling''' at the girl."
          },
          {
            "sentence": "К1алэр '''къэстэкуо'''",
            "translation": "The boy '''is yelling at me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "takʷəwan"
  },
  {
    "word": "мэкуон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"куон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "məkʷəwan"
  },
  {
    "word": "пшъэшъэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "little girl",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʂaʂaʐəja"
  },
  {
    "word": "куон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to yell, to shout, to speak really loud",
        "examples": [
          {
            "sentence": "Пшъашъэр '''мэкуо'''",
            "translation": "The girl '''is yelling'''."
          },
          {
            "sentence": "Л1ыр '''къэкуорэ''' шъо1урэба?",
            "translation": "Don't you (plural) hear the man's '''shouting'''?"
          },
          {
            "sentence": "'''Умыкуу''', сиянэ къэбгъэушынэу уфая",
            "translation": "'''Don't shout''', do you wanna awake my mom?"
          },
          {
            "sentence": "'''Умыкуу''', сиянэ къэбгъэушынэу уфая",
            "translation": "'''Don't shout''', do you wanna awake my mom?"
          },
          {
            "sentence": "Къыгъэжъ, ситхьак1умэ '''укъекуо'''",
            "translation": "Idiot, you are screaming to my ear."
          },
          {
            "sentence": "К1алэхэмэ '''укуоми''' я1урэба?",
            "translation": "The boys aren't hearing you '''even when you shout?'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "kʷəwan"
  },
  {
    "word": "куогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the distance that a humans voice travels",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəwaʁʷ"
  },
  {
    "word": "хэгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to allow/force someone to leave from a group",
        "examples": []
      },
      {
        "meaning": "to decrease, to lower (amount)",
        "examples": [
          {
            "sentence": "Мыжъо къэпхьырэм '''хэгъэк1'''",
            "translation": "'''Decrease''' the amount of rocks you are bringing."
          },
          {
            "sentence": "Псэу мафэм узешъорэ '''хэбгъэк1ын''' фае",
            "translation": "You need '''to low''' the amount of water you are drinking."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʁat͡ʃən"
  },
  {
    "word": "хьазыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ready",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ħaːzər"
  },
  {
    "word": "шъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷəjan"
  },
  {
    "word": "мэшъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃʷəjan"
  },
  {
    "word": "шъуялъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whistle (device)",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуял",
      "шъуил"
    ],
    "type": "noun",
    "ipa": "ʃʷəjaːɬa"
  },
  {
    "word": "хэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xakʲʼən"
  },
  {
    "word": "нашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blue-eyed",
        "examples": [
          {
            "sentence": "КIалэ '''нашхъом''' мэк1агъэ",
            "translation": "The boy '''with the blue eyes''' went."
          },
          {
            "sentence": "Пшъашъэм инит1у '''нашхъох'''",
            "translation": "The girl's two eyes are '''blue eyes'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "naːʃχʷa"
  },
  {
    "word": "к1элэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "childhood",
        "examples": []
      },
      {
        "meaning": "youth",
        "examples": []
      }
    ],

    "cognate": "щ1элэгъу",
    "type": "noun",
    "ipa": "t͡ʃalaʁʷ"
  },
  {
    "word": "тфый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ew (an expression of disgusted).",
        "examples": [
          {
            "sentence": "'''Тфый!''' еплъ узэрэкъэлъагъорэ",
            "translation": "'''Tfiy!''' look what you look like."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "tfəj"
  },
  {
    "word": "амэрмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"амырмэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːmarma"
  },
  {
    "word": "амырми",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "even if not (can be used like either way)",
        "examples": [
          {
            "sentence": "Уиунэ тигъэс, '''амэрми''' тыздэк1он шы1эп",
            "translation": "Let us stay in your house, '''even if not''' there is nowhere we can go.."
          },
          {
            "sentence": "Нек1о фылымыр тыублэщт, '''амэрми''' к1алэр еплъынэу фэещтэп",
            "translation": "Let's start the film, the boy won't want to watch it '''either way'''.."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːmarmi"
  },
  {
    "word": "амру",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "or rather",
        "examples": [
          {
            "sentence": "Уиунэ тигъэс '''амру''' удэк1рагъуа?",
            "translation": "Let us stay in your house '''or rather''' do you want to go out?"
          },
          {
            "sentence": "Сыд ушхытэ? наша '''амру''' мыя?",
            "translation": "What you gonna eat? watermelon '''or rather''' apple?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːmru"
  },
  {
    "word": "фэсакъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to save",
        "examples": []
      },
      {
        "meaning": "to spare",
        "examples": []
      },
      {
        "meaning": "to be careful",
        "examples": [
          {
            "sentence": "'''Зфэсакъ''', чъыгым укъет1эрэхыт",
            "translation": "'''Be careful''', you will fall down from the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fasaːqən"
  },
  {
    "word": "зыфэгъэсакъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be careful",
        "examples": [
          {
            "sentence": "К1алэм '''зыфигъэсакъэу''' гъогум блэк1ы",
            "translation": "The boy passes the road '''while being careful'''."
          },
          {
            "sentence": "'''зыфэгъэсакъ'''",
            "translation": "'''Be careful'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəfaʁasaːqən"
  },
  {
    "word": "дэхьащхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ridicule, to mock, to make fun of; to make a fool of (someone)",
        "examples": [
          {
            "sentence": "ЗэкIэ ащ '''дэхьащхы'''",
            "translation": "everybody '''makes fun of''' him."
          },
          {
            "sentence": "'''Укъыздэхьащха'''?",
            "translation": "'''Are you kidding''' me?"
          }
        ]
      }
    ],

    "cognate": "дыхьэшхын",
    "type": "verb",
    "ipa": "daħaːɕxən"
  },
  {
    "word": "дэхьыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to charm, to fascinate",
        "examples": []
      },
      {
        "meaning": "to infatuate",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daħəxən"
  },
  {
    "word": "ебгын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to curse someone; to damn someone; to wish something horrible for someone",
        "examples": [
          {
            "sentence": "Л1ыжьыр '''къыобгыгъ'''",
            "translation": "The old man '''cursed you'''."
          },
          {
            "sentence": "Л1ыр к1алэм ыгъэчъыерэпи '''ебгыгъ'''",
            "translation": "The man '''is damned''' the boy because he does not let him sleep."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jabɣən"
  },
  {
    "word": "хьарып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːrəpʼ"
  },
  {
    "word": "къашыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ladle",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэны1у"
    ],
    "type": "noun",
    "ipa": "qaːʃəq"
  },
  {
    "word": "пхъэны1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ladle",
        "examples": []
      }
    ],
    "synonyms": [
      "къашыкъ"
    ],
    "type": "noun",
    "ipa": "pχanəʔʷ"
  },
  {
    "word": "къашыкъхъурай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ladle with holes",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːʃəqχʷəraːj"
  },
  {
    "word": "лъэбакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэубакъу\"",
        "examples": []
      }
    ],

    "cognate": "лъэбакъуэ",
    "type": "noun",
    "ipa": "ɬabaːqʷ"
  },
  {
    "word": "лъэубакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "step",
        "examples": []
      }
    ],

    "cognate": "лъэбакъуэ",
    "type": "noun",
    "ipa": "ɬawbaːqʷ"
  },
  {
    "word": "теуцогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "degree (unit of temperature or angle)",
        "examples": []
      },
      {
        "meaning": "radius",
        "examples": []
      },
      {
        "meaning": "Spoke (wheel)",
        "examples": []
      },
      {
        "meaning": "stage",
        "examples": []
      },
      {
        "meaning": "step",
        "examples": []
      },
      {
        "meaning": "Wheel axle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tewt͡sʷaʁʷ"
  },
  {
    "word": "къэлэдэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "townsman",
        "examples": [
          {
            "sentence": "К1алэр '''къэлэдэс'''",
            "translation": "The young man is '''townsman'''."
          }
        ]
      },
      {
        "meaning": "citizen (resident of a city or town)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaɮadas"
  },
  {
    "word": "шъутыпс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъутыпц1э\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃʷətəpsʼa"
  },
  {
    "word": "къэгъэуцун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stop someone/something",
        "examples": []
      },
      {
        "meaning": "to arrest",
        "examples": [
          {
            "sentence": "Полисым '''укъигъэуцут'''",
            "translation": "The police '''gonna arrest you'''."
          },
          {
            "sentence": "Полисым к1алэр '''къигъэуцогъ'''",
            "translation": "The police '''arrested''' the boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁawt͡sʷən"
  },
  {
    "word": "мэуцун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уцун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawt͡sʷən"
  },
  {
    "word": "къэк1охьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэк1ухьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qakʷʼaħən"
  },
  {
    "word": "къыдэк1охьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыдэк1ухьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qədakʷʼaħən"
  },
  {
    "word": "хэпк1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill a liquid onto another liquid.",
        "examples": [
          {
            "sentence": "Санэ псым '''хапкьэмэ''' егъэплъыжьыт",
            "translation": "If you spill grape on water it will make it red."
          },
          {
            "sentence": "Шхъуант1эрэ гъожьырэ '''зэхапкьэхэмэ''' уцышъоу мэхъут",
            "translation": "'''If you spill''' yellow and blue '''into each other''' it will turn to green."
          },
          {
            "sentence": "Псыхъ псым '''хапкьэмэ''' мэуш1оит",
            "translation": "'''If you spill''' mud on water it will make it dirty."
          }
        ]
      }
    ],
    "synonyms": [
      "хэпк1эн"
    ],
    "type": "verb",
    "ipa": "xapkʲan"
  },
  {
    "word": "хэгъэузык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone feel pain or to hurt (In general)",
        "examples": [
          {
            "sentence": "'''Къэсхэбгъэузык1ы'''",
            "translation": "'''You are hurting me'''."
          },
          {
            "sentence": "К1алэм '''хэбэгъэузык1ы'''",
            "translation": "'''You are hurting''' the boy"
          },
          {
            "sentence": "Л1ы лъэшым '''хэбгъэузык1ышъутэп'''",
            "translation": "'''You can't hurt''' the strong man."
          }
        ]
      }
    ],
    "synonyms": [
      "хэгъэузык1ьын"
    ],
    "type": "verb",
    "ipa": "xaʁawzet͡ʃən"
  },
  {
    "word": "хэгъэузык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэгъэузык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хэгъэузык1ын"
    ],
    "type": "verb",
    "ipa": "xaʁawzekʲən"
  },
  {
    "word": "хьэдэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьадэгъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħadaʁʷ"
  },
  {
    "word": "нэзэжъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Asian",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nazaʒʷ"
  },
  {
    "word": "хьацэгъуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "miser, greed",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьацэ"
    ],
    "type": "adj",
    "ipa": "ħaːt͡saʁʷəj"
  },
  {
    "word": "тхьацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "miser, stingy",
        "examples": []
      }
    ],
    "synonyms": [
      "хьацэгъуй",
      "хьэцэ1ук1"
    ],
    "type": "adj",
    "ipa": "tħaːt͡sa"
  },
  {
    "word": "зэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fight",
        "examples": [
          {
            "sentence": "К1алэр Томым '''езао'''",
            "translation": "The boy '''is fighting''' Tom. (transitive)"
          },
          {
            "sentence": "'''Укъысэзэорагъуа?'''",
            "translation": "'''Do you wanna fight me?'''. (transitive)"
          },
          {
            "sentence": "Охэр '''къысэзэотых'''",
            "translation": "Those '''will fight with me''' Tom. (transitive)"
          },
          {
            "sentence": "К1алэм '''уезэошъута'''",
            "translation": "'''Can you fight''' the boy? (transitive)"
          },
          {
            "sentence": "Дунай зао ят1унэмк1э Джармынрэ Урысыемрэ '''зэзэуагъэх'''",
            "translation": "In World War 2 Germany and Russian '''fought each other'''. (transitive)"
          },
          {
            "sentence": "Л1ыр заом '''шэзао'''",
            "translation": "The man '''is fighting''' in the war. (intransitive)"
          },
          {
            "sentence": "Сик1алэ '''мэзэонэу''' дэк1эгъ",
            "translation": "My son went out '''to fight'''. (intransitive)"
          },
          {
            "sentence": "К1алэхэр '''мэзэорагъох'''",
            "translation": "The boys '''want to fight'''. (intransitive)"
          },
          {
            "sentence": "Удэ к1элит1у '''зэзаох'''",
            "translation": "There two boys '''are fighting each other'''. (intransitive)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zawan"
  },
  {
    "word": "мэзэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mazawan"
  },
  {
    "word": "езэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jazawan"
  },
  {
    "word": "мэбзэин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бзэен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mabzajən"
  },
  {
    "word": "мэбыбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"быбын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mabəbən"
  },
  {
    "word": "мэбэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бэнэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mabanan"
  },
  {
    "word": "гоуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stand besides, to stand on the side of",
        "examples": [
          {
            "sentence": "'''Шъукъэсгоуцуи''' заом тыхэхьэщт тызэгъусэу",
            "translation": "'''Stand besides me''' and we will go to war together."
          },
          {
            "sentence": "Нахьык1эр нахьыжъым исэмэгук1э '''гоуцоныр''' адыгэ хабз",
            "translation": "It is a Circassian custom for a younger '''to stand''' in the older's left."
          }
        ]
      },
      {
        "meaning": "to stop besides, to stop on the side of",
        "examples": [
          {
            "sentence": "Машинэм '''къыгоуцу'''",
            "translation": "'''Stop besides''' the car."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷawt͡sʷan"
  },
  {
    "word": "чъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цу"
    ],
    "type": "noun",
    "ipa": "t͡ʃʷə"
  },
  {
    "word": "цуманэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) idiot, dumb fuck",
        "examples": [
          {
            "sentence": "'''Уцумана'''? уатэ къэхь къыос1уи отыч къэсфэбэхьа?",
            "translation": "Are you an '''idiot''', I told you to bring a hammer and you brought me an ax?"
          },
          {
            "sentence": "'''Цуманэр''' тиунэ къэк1уагъ",
            "translation": "'''The idiot''' came to my house."
          }
        ],
        "tags": [
          "vulgar"
        ]
      },
      {
        "meaning": "(literally vulgar) dick of an ox",
        "examples": [],
        "tags": [
          "literally vulgar"
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡sʷəmaːn"
  },
  {
    "word": "чъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цужъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цужъ"
    ],
    "type": "noun",
    "ipa": "t͡ʃʷəʐ"
  },
  {
    "word": "цужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "huge ox",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʷəʐ"
  },
  {
    "word": "к1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tail",
        "examples": [
          {
            "sentence": "Кьэтыум '''ик1ьэ''' утэмыпкь",
            "translation": "Don't jump/step on the cat's [[tail]]."
          }
        ]
      },
      {
        "meaning": "seed",
        "examples": [
          {
            "sentence": "Ят1эм '''к1ьэхэр''' халъхьэх",
            "translation": "Put '''the seeds''' in the dirt."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) (vulgar slang) dick (penis)",
        "examples": [],
        "tags": [
          "Kfar Kama dialect",
          "vulgar",
          "slang"
        ]
      }
    ],

    "cognate": "к1э",
    "type": "noun",
    "ipa": "kʲʼa"
  },
  {
    "word": "убэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tamp, to make smooth",
        "examples": [
          {
            "sentence": "К1алэм ят1эр '''еубэ'''",
            "translation": "The boy '''is tamping''' the dirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəban"
  },
  {
    "word": "мэубэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"убэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawban"
  },
  {
    "word": "еубэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"убэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawban"
  },
  {
    "word": "уш1оин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dirty",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəʃʷajən"
  },
  {
    "word": "езбэрэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"езбырэу\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jazbaraw"
  },
  {
    "word": "езбырэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "by heart (knowing completely)",
        "examples": [
          {
            "sentence": "К1алэм '''езбырэу''' тхылъэр зегъаш1э",
            "translation": "The boy learning the book '''by heart'''."
          },
          {
            "sentence": "Орэдэр '''езбырэу''' ош1а?",
            "translation": "Do you know the song '''by heart?'''"
          },
          {
            "sentence": "Уинамбэр '''езбырэу''' зэгъаш1",
            "translation": "Learn your number '''by heart'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jazbəraw"
  },
  {
    "word": "урыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a Russian (person)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wərəs"
  },
  {
    "word": "фэдэкъабз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very identical, exactly the same thing",
        "examples": [
          {
            "sentence": "Томым '''уфэдэкъабзэу''' укъэлъагъо",
            "translation": "You look '''very identical to''' Tom."
          },
          {
            "sentence": "Мы уатэм '''фэдэкъабзэ''' си1",
            "translation": "I have a hammer '''identical to''' this one."
          },
          {
            "sentence": "Хым ук1онымрэ есып1эм ук1онымрэ '''зэфэдэкъабзэх'''",
            "translation": "Going to the sea and going to the pool '''are just like each other'''."
          },
          {
            "sentence": "Уик1алэрэ сэ сик1алэрэ '''зэфэдэкъабзэхэба?'''",
            "translation": "Your son and my son '''are identical right?'''"
          },
          {
            "sentence": "Докторым сык1омэ тгъуасэ '''фэдэкъабз''' къыси1отэр",
            "translation": "If I go to the doctor, he will say to me '''the exactly same thing''' he said yesterday."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "fadaqaːbz"
  },
  {
    "word": "егъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to force someone do something (usually unwillingly)",
        "examples": [
          {
            "sentence": "Ныом к1алэр '''регъэзы''' ишхын ышхынэу",
            "translation": "The old-woman '''forces''' the boy to eat her dinner."
          },
          {
            "sentence": "К1алэр '''емыгъэз''' мэмык1орагъомэ",
            "translation": "'''Don't force''' the boy if he doesn't want to go."
          },
          {
            "sentence": "'''Сыкъырегъэзы''' 1эш1у сышхынэу",
            "translation": "'''It forces me''' to eat sweet."
          },
          {
            "sentence": "К1алэр '''рагъэзэгъ''' мэш1к1эенэу",
            "translation": "'''They forced''' the boy to do trouble."
          },
          {
            "sentence": "К1алэр '''ебгъэсышъутэп''' иш1энэу зыфамыер",
            "translation": "'''You can't force''' the boy to do something he doesn't want to do."
          }
        ]
      },
      {
        "meaning": "to have an irrational need or irresistible urge to perform some action, often despite negative consequences",
        "examples": [
          {
            "sentence": "Ешъоным '''сыкъырегъэзы'''",
            "translation": "'''I have an urge''' to drinking."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁatən"
  },
  {
    "word": "егъэбэджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to knock off (To bump or hit so that something falls off)",
        "examples": [
          {
            "sentence": "Л1ым чъыгыхэр '''регъэбэджых'''",
            "translation": "The man '''is knocking off''' the trees."
          },
          {
            "sentence": "'''Егъэбэдж''' чъыгыр",
            "translation": "'''Knock off''' the tree."
          },
          {
            "sentence": "К1алэм пхъэнт1эк1ур '''ригъэбэджэгъ'''",
            "translation": "The boy '''knocked off''' the chair."
          },
          {
            "sentence": "К1алэм '''сыригъэбэджэгъ'''",
            "translation": "The boy '''knocked me off'''."
          },
          {
            "sentence": "Пхъэнт1эк1ухэр '''ешъумыгъэбэджыхэу''' шъуаблэк1",
            "translation": "Pass the chairs '''without knocking them off'''. (Said to plural)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁabad͡ʒən"
  },
  {
    "word": "чъэныр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "race",
        "examples": [
          {
            "sentence": "К1алэм чъэныр '''ригъэжьагъ'''",
            "translation": "The boy '''started''' the race."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃanər"
  },
  {
    "word": "егъэжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something start up/start",
        "examples": [
          {
            "sentence": "Л1ым '''регъажьэ''' филмыр",
            "translation": "The man '''is starting up''' the film."
          },
          {
            "sentence": "'''Егъажь''' машинэр",
            "translation": "'''Start up''' the car."
          },
          {
            "sentence": "Машинэр '''ебгъэжьэшъурэба?'''",
            "translation": "'''Can't you start up''' the car?"
          },
          {
            "sentence": "К1алэм чъэныр '''ригъэжьагъ'''",
            "translation": "The boy '''started''' the race."
          }
        ]
      },
      {
        "meaning": "to initiate",
        "examples": [
          {
            "sentence": "Саусырыкъо заор '''ригъэжьагъ'''",
            "translation": "Susroku '''has initiated''' the battle."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁaʑan"
  },
  {
    "word": "зыщыдзыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move away from",
        "examples": [
          {
            "sentence": "К1алэм '''зыщыдзыегъ''' дэпкъым",
            "translation": "The boy '''moved away''' from the wall."
          },
          {
            "sentence": "Сомпютэрым '''зыщыдзый'''",
            "translation": "'''Move away''' from the computer."
          },
          {
            "sentence": "Машинэ уфымэ гъогум ибгъу '''зыщыдзый'''",
            "translation": "When you drive a car '''move away''' from the road's side."
          }
        ]
      },
      {
        "meaning": "to avoid",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəɕəd͡zəjan"
  },
  {
    "word": "зыщыгъэгъупшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to try to forget",
        "examples": [
          {
            "sentence": "К1алэм '''зыщыгъэгъупшы''' еп1ок1упэрэ",
            "translation": "The boy '''is trying to forget''' the thing you told him."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəɕəʁaʁʷəpʃən"
  },
  {
    "word": "зыщыгъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take off oneself clothes, to undress oneself",
        "examples": [
          {
            "sentence": "К1алэм иджанэ '''зыщыгъэзы'''",
            "translation": "The boy '''taking off''' his shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəɕəʁaʁʷəpʃən"
  },
  {
    "word": "щыгъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take off (clothes)",
        "examples": [
          {
            "sentence": "К1алэм иджанэ '''щыгъэзы'''",
            "translation": "The boy '''taking off''' his shirt."
          },
          {
            "sentence": "Уицуакъэхэр '''щыгъэзых'''",
            "translation": "'''Take off''' your shoes."
          },
          {
            "sentence": "Л1ыр унэм къехьэжьым ищыгъыныхэр '''щыгъэзыхи''' п1эм хэгъолъхьагъэ",
            "translation": "When the man came back to home '''he took off''' his clothes and lied on the bet."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəɕəʁaʁʷəpʃən"
  },
  {
    "word": "щыгъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clothing",
        "examples": [
          {
            "sentence": "'''Шыгъын''' дахэгорэ уи1а?",
            "translation": "Do you have some beautiful '''clothing'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕəʁən"
  },
  {
    "word": "япэрэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"апэрэу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jaːparaw"
  },
  {
    "word": "япэрэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "first",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jaːpara"
  },
  {
    "word": "шыдфэдиз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how much",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьапш"
    ],
    "type": "adv",
    "ipa": "ʃədfadiz"
  },
  {
    "word": "неущы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tomorrow",
        "examples": [
          {
            "sentence": "'''Неущы''' тк1от йуропа",
            "translation": "'''Tomorrow''' we will go to Europe."
          },
          {
            "sentence": "'''Неущы''' пш1ы синыбжьыт",
            "translation": "'''Tomorrow''' I will be ten years old."
          }
        ]
      }
    ],
    "synonyms": [
      "нэущы"
    ],
    "cognate": "пщэдей",
    "type": "adv",
    "ipa": "newɕə"
  },
  {
    "word": "арымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"армэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːrəma"
  },
  {
    "word": "хъои",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plenty",
        "examples": [
          {
            "sentence": "Шхынэр '''хъоеу''' щы1.",
            "translation": "There is '''plenty''' of food."
          },
          {
            "sentence": "Шхыныр '''хъои'''.",
            "translation": "There is '''plenty''' of food."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "χʷajə"
  },
  {
    "word": "ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1ап1э\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːpʼa"
  },
  {
    "word": "дунае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "world",
        "examples": [
          {
            "sentence": "Ц1ыфэу '''дунаем''' тетыхэ",
            "translation": "The people that are '''in the world'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "dunaːja"
  },
  {
    "word": "дунай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дунае\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dunaːj"
  },
  {
    "word": "тучан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shop",
        "examples": [
          {
            "sentence": "'''Тучанэм''' к1о",
            "translation": "Go '''to the shop'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "tut͡ʃaːn"
  },
  {
    "word": "ц1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(slang) awesome, nice",
        "examples": [
          {
            "sentence": "Уиджанэ '''ц1ап1э'''",
            "translation": "Your shirt '''is nice'''."
          }
        ],
        "tags": [
          "slang"
        ]
      },
      {
        "meaning": "mean, cruel",
        "examples": [
          {
            "sentence": "Л1ыжъыр '''ц1ап1э'''",
            "translation": "The old man '''is mean'''."
          }
        ]
      },
      {
        "meaning": "evil",
        "examples": []
      },
      {
        "meaning": "awful (Usually said to kids)",
        "examples": [
          {
            "sentence": "У к1элэцык1ухэр '''ц1ап1эх'''",
            "translation": "Those kids '''are awful'''."
          }
        ]
      }
    ],

    "cognate": "бзаджэ",
    "type": "adj",
    "ipa": "t͡sʼaːpʼa"
  },
  {
    "word": "дзэк1ол1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soldier",
        "examples": []
      }
    ],
    "synonyms": [
      "дзак1о",
      "дзэл1ы"
    ],
    "type": "noun",
    "ipa": "d͡zakʷʼaɬʼə"
  },
  {
    "word": "дзак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rookie soldier",
        "examples": []
      }
    ],
    "synonyms": [
      "дзэл1ы",
      "дзэк1ол1ы"
    ],
    "type": "noun",
    "ipa": "d͡zaːkʷʼa"
  },
  {
    "word": "гъэпсык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпсык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэпсык1ын"
    ],
    "type": "verb",
    "ipa": "ʁapsəkʲʼən"
  },
  {
    "word": "гъэлъэп1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something expensive",
        "examples": [
          {
            "sentence": "Л1ым джэгуалъэхэр '''егъэлъап1эх'''",
            "translation": "The man '''is making''' the toys '''expensive'''."
          }
        ]
      },
      {
        "meaning": "to honor",
        "examples": [
          {
            "sentence": "Л1ым и хьак1эхэ '''егъэлъап1эх'''",
            "translation": "The man '''honors''' his guests."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɬapʼan"
  },
  {
    "word": "л1ыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bravery, courage",
        "examples": [
          {
            "sentence": "Сэ '''л1ыгъэ''' къэсхэлъ",
            "translation": "I am '''brave''' (I have '''bravery''')."
          },
          {
            "sentence": "Саусырыкъо заом '''л1ыгъэшхо''' щызэрихьагъ",
            "translation": "Susroku carried out '''great bravery''' in the battle."
          }
        ]
      },
      {
        "meaning": "manliness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼəʁa"
  },
  {
    "word": "л1ыгъэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barely",
        "examples": [
          {
            "sentence": "К1алэм '''л1ыгъэк1э''' къештэ анэ инэр",
            "translation": "The boy '''barely''' holds up the large table."
          }
        ]
      },
      {
        "meaning": "With great difficulty",
        "examples": [
          {
            "sentence": "К1алэм '''л1ыгъэк1э''' мыжъор мэу къихьэгъ",
            "translation": "The boy '''with great difficulty''' brought the stone."
          },
          {
            "sentence": "'''Л1ыгъэк1э''' у1узгъэк1ыну уфая?",
            "translation": "Do you want me to make you go away '''by force'''?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ɬʼəʁat͡ʃʼa"
  },
  {
    "word": "дэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэи\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "daj"
  },
  {
    "word": "хэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take something/someone from among others",
        "examples": [
          {
            "sentence": "Мые '''хэх'''",
            "translation": "'''Take''' an apple. (In case the apple is among other things like other fruits for example)"
          },
          {
            "sentence": "К1алэр хым хэ1аби '''хихэгъ''' мыжъо",
            "translation": "The boy put his hands into the sea and '''took''' a rock."
          },
          {
            "sentence": "Къэцым мыжъор '''хэпхышъурэба?'''",
            "translation": "'''Can't you take''' the rock from the thorn."
          }
        ]
      },
      {
        "meaning": "to choose, to pick, to select",
        "examples": [
          {
            "sentence": "Уигъусэхэр '''къыхэхых'''",
            "translation": "'''Pick''' your friends."
          },
          {
            "sentence": "Ущ '''хэхк1э''' еш1а?",
            "translation": "Does he know '''how to pick'''?"
          },
          {
            "sentence": "Гъогу янахь дэер '''хэпхыгъэ'''",
            "translation": "'''You chose''' the worst road."
          },
          {
            "sentence": "Сыфаек1упэр к1алэхэмэ '''къыхахых'''",
            "translation": "The boys '''are picking''' what i want."
          },
          {
            "sentence": "К1алэм '''хихырагъоп''' сыд тыш1этэ.",
            "translation": "The boy '''doesn't want to pick''' what we gonna do."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaxən"
  },
  {
    "word": "хегъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to allow/force/let/make someone (to) take something/someone",
        "examples": [
          {
            "sentence": "Л1ым мые зналъи '''хэсигъэхрэп'''",
            "translation": "The man '''is not allowing me to take''' a single apple."
          },
          {
            "sentence": "К1алэм матэм '''хегъэх''' мые",
            "translation": "'''Let''' the boy '''take''' an apple from the basket."
          }
        ]
      },
      {
        "meaning": "to allow/force/let/make someone (to) choose or pick",
        "examples": [
          {
            "sentence": "Л1ым л1ыгъэк1э '''хэсигъэхыт'''",
            "translation": "The man with great difficulty '''is trying to make me pick'''."
          },
          {
            "sentence": "К1алэмэ зафае '''хагъэх'''",
            "translation": "'''Let''' the boys '''pick''' what they want."
          },
          {
            "sentence": "К1алэм пшъашъэм '''хырегъэхы''' сыд яшхытэ.",
            "translation": "The boy '''letting''' the girl '''choose''' what they gonna eat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaxən"
  },
  {
    "word": "хихын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xixən"
  },
  {
    "word": "хэ1эбэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put one self's hands into, to touch deeply",
        "examples": [
          {
            "sentence": "К1алэр псым '''хэ1абэ'''",
            "translation": "The boy '''is putting his hands into''' the water."
          },
          {
            "sentence": "К1алэр хым '''хэ1аби''' хихэгъ мыжъо",
            "translation": "The boy '''put his hands into''' the sea and took a rock."
          },
          {
            "sentence": "Шхыным '''ухэм1аб'''",
            "translation": "'''Don't touch''' the food."
          },
          {
            "sentence": "Ул1ымэ къэцым '''хэ1аб'''",
            "translation": "If you are a man '''put your hands into''' the thorn."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʔaban"
  },
  {
    "word": "тэ1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to touch",
        "examples": [
          {
            "sentence": "Анэм уи1э шъоик1э '''утэмы1'''",
            "translation": "'''Don't touch''' the table with your dirty hands."
          },
          {
            "sentence": "Гъуч1ы фабэм к1алэр '''та1эм''' и1э къистэгъ",
            "translation": "When the boy '''touched''' the hot iron, it burned his hands."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʔan"
  },
  {
    "word": "дэуахьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prayer (A request)",
        "examples": [
          {
            "sentence": "Зыщыщэми '''дэуахьык1э''' фэлъа1о.",
            "translation": "He prays for the ones that he belong to."
          },
          {
            "sentence": "Иунагъо '''дэуахьык1э''' фэлъа1о.",
            "translation": "He prays for his family."
          },
          {
            "sentence": "Уиятэ '''дэуахьы''' къыфэхь",
            "translation": "Pray for your father."
          }
        ]
      }
    ],
    "synonyms": [
      "дыухьэ"
    ],
    "type": "noun",
    "ipa": "dawaːħə"
  },
  {
    "word": "щыщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be a part of a group of some kind, to be belong to a group",
        "examples": [
          {
            "sentence": "Томэр Адыгэмэ '''ящыщ'''.",
            "translation": "'''He is one of''' the Adyghe people."
          },
          {
            "sentence": "К1алэр къашъохэмэ '''ящыщагъэ'''.",
            "translation": "The boy '''was belong to''' the dancers."
          },
          {
            "sentence": "К1алэр къалэм '''щыщ'''.",
            "translation": "The boy '''is belong to''' the city."
          },
          {
            "sentence": "Шъыпкъэу '''укъэтщыщэмэ''' къэтдак1у.",
            "translation": "'''If you are''' truly '''one of us''' come with us."
          },
          {
            "sentence": "Мыер пхъэшъхьэмышъхьэхэмэ '''ящыщ'''.",
            "translation": "The apple '''is belong to''' the fruits."
          },
          {
            "sentence": "'''Зыщыщэми''' дэуахьык1э фэлъа1о.",
            "translation": "He prays for '''the ones that he belong to'''."
          },
          {
            "sentence": "У к1алэм ишэн зыгорэми '''щыщэп'''.",
            "translation": "That boy's behavior '''doesn't belong''' to anything."
          },
          {
            "sentence": "У к1алэр '''къэтщыщ''', ау у пшъашъэр '''къэтщыщэп'''.",
            "translation": "That boy '''is one of us''', but that girl '''is not one of us'''."
          },
          {
            "sentence": "У л1ыр шъуук1, Ор къытэзаохэмэ '''ящыщ'''.",
            "translation": "Kill that man (said to plural), he '''is one of''' the people that are attacking us."
          },
          {
            "sentence": "'''Шъусщыщэнэу''' сыфе.",
            "translation": "I wanna '''be one of you guys'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəɕan"
  },
  {
    "word": "мэмэхъэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be disgusted",
        "examples": [
          {
            "sentence": "Пшъашъэр '''мэмэхъашэ'''.",
            "translation": "The girl '''is disgusted'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mamaχaʃan"
  },
  {
    "word": "гъэмэхъэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disgust someone",
        "examples": [
          {
            "sentence": "К1алэм '''сегъэмэхъашэ'''.",
            "translation": "The boy '''is disgusting me'''."
          },
          {
            "sentence": "'''Сэбэгъэмэхъашэ'''.",
            "translation": "'''You are disgusting me'''."
          },
          {
            "sentence": "К1алэм пшъашъэр '''егъэмэхъашэ''' иджанэ шъоихэмк1э.",
            "translation": "The boy '''is disgusting''' the girl with his dirty clothes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁamaχaʃan"
  },
  {
    "word": "ут1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэут1эт1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wətʼatʼan"
  },
  {
    "word": "мэут1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1эт1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wətʼatʼan"
  },
  {
    "word": "мэт1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1эт1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matʼatʼan"
  },
  {
    "word": "мэт1ат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dripping",
        "examples": [
          {
            "sentence": "Зыфэсакъ шъоур '''мэт1ат1э'''.",
            "translation": "Be careful, the honey is '''dripping'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "matʼaːtʼa"
  },
  {
    "word": "тет1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip (a sticky substances) on",
        "examples": [
          {
            "sentence": "Шъоур анэм '''тет1ат1э'''.",
            "translation": "The honey '''is dripping on''' the table."
          },
          {
            "sentence": "К1элэц1ык1ур шхэу иджанэ '''тет1ат1э''' шъоу.",
            "translation": "The boy '''drips''' honey '''on''' his shirt while eating."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajtʼatʼan"
  },
  {
    "word": "гъэт1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip (sticky substances like honey, cake and color liquid)",
        "examples": [
          {
            "sentence": "'''Умыгъэт1ат1''' шхынэр.",
            "translation": "'''Don't drip''' the food."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁatʼatʼan"
  },
  {
    "word": "тэгъэт1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тегъэт1эт1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁatʼatʼan"
  },
  {
    "word": "пытхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear off (forcefully) something from something",
        "examples": [
          {
            "sentence": "Л1ым чъыгым икъуамэхэр '''петхъых'''",
            "translation": "The man '''tears off''' the tree's branches."
          },
          {
            "sentence": "IэкIэ шэкIым '''питхъын''' фае хъугъэ",
            "translation": "(S)he had to '''tear off''' the cloth using his hands."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pətχən"
  },
  {
    "word": "къудыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pull, to stretch",
        "examples": [
          {
            "sentence": "К1алэм к1апсэр '''екъудыи'''",
            "translation": "The boy '''is pulling''' the rope."
          },
          {
            "sentence": "К1апсэр башэу '''укъудыимэ''' зэпытхъыт",
            "translation": "'''if you pull''' the rope too much it tear."
          },
          {
            "sentence": "Пчъэр '''къудыйи''' ихь",
            "translation": "'''Pull''' the door and enter."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qʷədəjən"
  },
  {
    "word": "екъудыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къудыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qʷədəjən"
  },
  {
    "word": "прэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэпрэзэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "prazan"
  },
  {
    "word": "гъэпрэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone dizzy",
        "examples": [
          {
            "sentence": "К1алэр '''сэгъэпразэ'''",
            "translation": "'''I am making''' the boy '''dizzy'''."
          },
          {
            "sentence": "Щэнаут уепэмымэ '''угъэпрэзэщт'''",
            "translation": "If you smell the poison '''it will dizzy you'''."
          },
          {
            "sentence": "'''Сэбэгъэпразэ'''",
            "translation": "'''You are making me dizzy'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaprazan"
  },
  {
    "word": "мэц1эшъутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1эшъутэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼaʃʷtan"
  },
  {
    "word": "щыхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щыхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕəħan"
  },
  {
    "word": "гъэкуон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone scream,yell,shout",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''егъэкуо'''",
            "translation": "The boy '''is making''' the girl '''scream'''."
          },
          {
            "sentence": "'''Сыкъэмгъэкуу'''",
            "translation": "'''Don't make me shout'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁakʷəwan"
  },
  {
    "word": "удж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dance",
        "examples": []
      }
    ],
    "synonyms": [
      "угь",
      "----"
    ],
    "type": "noun",
    "ipa": "wəd͡ʒ"
  },
  {
    "word": "угь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"удж\"",
        "examples": []
      }
    ],

    "cognate": "удж",
    "type": "noun",
    "ipa": "wəɡʲ"
  },
  {
    "word": "ф1эжъыуф1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэжъыуф\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "fˠaʐəwfˠ"
  },
  {
    "word": "гукъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have a sudden thought or idea",
        "examples": [
          {
            "sentence": "Зыгори '''сыгукъэк1ырэп'''",
            "translation": "'''I can't think of''' any '''ideas'''."
          },
          {
            "sentence": "'''Сыгукъэк1ыгъ''' хым тыхэхьанэу",
            "translation": "'''I suddenly thought''' about '''the idea of''' us entering the sea."
          },
          {
            "sentence": "Сыд '''угукъэк1ыгъэ''' ори псы чъы1эм ухэхьанэу",
            "translation": "What '''were you thinking''' entering the cold water."
          },
          {
            "sentence": "К1алэм '''ыгукъэк1ыгъ''' игъусэ пшъашъэ ш1ухьафтын фырищэфынэу",
            "translation": "The boy '''suddenly thought''' about buying a present for his girlfriend."
          }
        ]
      }
    ],
    "synonyms": [
      "гукъэк1ьын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqat͡ʃʼən"
  },
  {
    "word": "гукъэк1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suddenly remember",
        "examples": [
          {
            "sentence": "К1алэм '''ыгукъэк1ыжьыгъ''' маш1ор зэримыгъэк1осагъэр",
            "translation": "The boy '''suddenly remembered''' he didn't extinguished the fire."
          },
          {
            "sentence": "Мо к1алэр '''угукъэк1ыжьа'''?",
            "translation": "'''Do you remember''' that boy?"
          },
          {
            "sentence": "К1алэм пшъашъэр '''ыгукъэк1ыжьырэп'''",
            "translation": "The boy '''doesn't remember''' the girl."
          },
          {
            "sentence": "Зыгори '''сыгукъэк1ыжьырэп'''",
            "translation": "'''I can't remember''' anything."
          },
          {
            "sentence": "Чэщым '''угукъэк1ыжьыгъа''' хым тхэхьанэу?",
            "translation": "'''Do you suddenly remembered''' in the night that we should enter the sea?"
          }
        ]
      }
    ],
    "synonyms": [
      "гукъэк1ьыжьын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqat͡ʃʼəʑən"
  },
  {
    "word": "игу хэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гухэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəɡʷə xat͡ʃən"
  },
  {
    "word": "ш1ухьафтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "present, gift",
        "examples": []
      }
    ],
    "synonyms": [
      "тыны"
    ],
    "type": "noun",
    "ipa": "ʃʷʼəħaːftən"
  },
  {
    "word": "гъэзэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to return (from where you came)",
        "examples": [
          {
            "sentence": "Уиунэ '''гъэзэжь'''",
            "translation": "'''Return''' to your home."
          },
          {
            "sentence": "Л1ым елъэгъум зэрэблэмк1ышъущтэ '''зигъэзэжьыгъ'''",
            "translation": "When the man saw that he can't pass,'''he returned'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁazaʑən"
  },
  {
    "word": "мэк1одын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1одын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "makʷʼadən"
  },
  {
    "word": "джыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be rolling",
        "examples": [
          {
            "sentence": "Мыжъор '''мэджыджэ'''",
            "translation": "The rock is '''rolling'''."
          }
        ]
      }
    ],
    "synonyms": [
      "джын"
    ],
    "type": "verb",
    "ipa": "mad͡ʒəd͡ʒan"
  },
  {
    "word": "мэджыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джыджэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "джыджэн",
      "джын"
    ],
    "type": "verb",
    "ipa": "mad͡ʒəd͡ʒan"
  },
  {
    "word": "еджыджэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to roll to/from",
        "examples": [
          {
            "sentence": "Мыжъор 1уашъхьэм '''еджыджэхы'''",
            "translation": "The rock is '''rolling from''' the hill."
          },
          {
            "sentence": "1эгуаор '''еджыджэхэгъ''' гъогум",
            "translation": "The ball '''rolled to''' the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jad͡ʒəd͡ʒan"
  },
  {
    "word": "гъэджыджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to roll",
        "examples": [
          {
            "sentence": "1эгуаор '''къэгъэджыдж'''",
            "translation": "'''Roll''' the ball '''to me'''."
          },
          {
            "sentence": "1эгуаор '''гъэджыдж'''",
            "translation": "'''Roll''' the ball."
          },
          {
            "sentence": "Мыжъо джадэр '''умгъэджыдж'''",
            "translation": "'''Don't roll''' the big rock."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁad͡ʒəd͡ʒan"
  },
  {
    "word": "стыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burned",
        "examples": [
          {
            "sentence": "Шхынэр '''стыгъэу''' къысэбэта?",
            "translation": "Do you give me the food '''burned'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "stəʁa"
  },
  {
    "word": "к1ы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "top",
        "examples": [
          {
            "sentence": "Унэм '''ик1ы1у''' тэфагъ мыжъор",
            "translation": "The rock fell on '''the top''' of the house."
          }
        ]
      },
      {
        "meaning": "surface",
        "examples": [
          {
            "sentence": "Хым '''ик1ы1у''' тэт",
            "translation": "He is on '''the surface of''' the sea."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼəʔʷ"
  },
  {
    "word": "хьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to carry to somewhere/someone",
        "examples": [
          {
            "sentence": "К1алэм пхъэр '''ехьы'''",
            "translation": "The boy '''carries''' the wood."
          },
          {
            "sentence": "К1алэм мыжъохэр хым '''ехьых'''",
            "translation": "The boy '''carries''' the rocks to the sea."
          },
          {
            "sentence": "Мыер сиятэ '''фэсахьы'''",
            "translation": "'''I am carrying''' the apple '''for''' my father."
          },
          {
            "sentence": "Мыер '''хьы'''",
            "translation": "'''Take''' the apple."
          },
          {
            "sentence": "Хьабыхэр '''ухьын''' фаех",
            "translation": "You need '''to take''' the pills."
          },
          {
            "sentence": "К1алэм 1анэ иныр '''фэхьырэп'''",
            "translation": "The boy '''can't carry''' the huge table."
          },
          {
            "sentence": "Иусыфыр Мысыр '''яхьыгъ'''",
            "translation": "They '''took''' Joseph to Egypt."
          },
          {
            "sentence": "'''Къэсыфэхь''' псы, уишы '''фэхь''' шхын",
            "translation": "'''Bring me''' water and '''bring''' food to your brother."
          },
          {
            "sentence": "Шыдым бэу '''фэхьыт'''",
            "translation": "The donkey '''can carry''' a lot."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħən"
  },
  {
    "word": "плъэк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to try out something",
        "examples": [
          {
            "sentence": "Мыер '''плъэк1у''' 1эш1умэ",
            "translation": "'''Try''' the apple if it's testy."
          },
          {
            "sentence": "Бэнанэ '''сыплъэк1унэу''' сыфай",
            "translation": "'''I wanna try''' a banana."
          },
          {
            "sentence": "К1алэм к1эхэр '''ыплъэк1ун''' фаех",
            "translation": "The boy need '''to try''' new things."
          }
        ]
      },
      {
        "meaning": "to check, to examine",
        "examples": [
          {
            "sentence": "Машинэу къэсхьыгъэр '''плъэк1у'''",
            "translation": "'''Check''' the car I brought."
          },
          {
            "sentence": "'''Тыкъауплъэк1у''' тыхьазырмэ",
            "translation": "'''They are examining us''' if we are ready."
          },
          {
            "sentence": "'''Ауплъэк1ущт''' мащинэ мэлажьэмэ",
            "translation": "'''They are examine''' if the car works."
          }
        ]
      },
      {
        "meaning": "to test",
        "examples": [
          {
            "sentence": "К1алэм '''еплъэк1у''' мащинэр мэлажьэмэ",
            "translation": "The boy '''is testing''' of the car is working."
          },
          {
            "sentence": "Мы дунаемк1э тхьэм пстэумк1и '''теплъэк1у'''",
            "translation": "In this world, God '''tests''' us all."
          }
        ]
      }
    ],
    "synonyms": [
      "ушэтын"
    ],
    "type": "verb",
    "ipa": "pɬakʷʼən"
  },
  {
    "word": "джыхэпк1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэпк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxapkʲʼan"
  },
  {
    "word": "къэсшъхьэпк1ьэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэсшъхьэпк1этын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qasʂħapkʲʼatən"
  },
  {
    "word": "гъэл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kill someone",
        "examples": [
          {
            "sentence": "Чэтыор уфызмэ '''угъэл1эщт'''",
            "translation": "If you squeeze the cat '''you will kill it'''."
          },
          {
            "sentence": "Л1ым ипыйхэр '''егъал1эх'''",
            "translation": "The man '''is killing''' his enemies."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɬʼan"
  },
  {
    "word": "арти",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thus, as a result, so, and that's why, and because of that",
        "examples": [
          {
            "sentence": "К1алэм ылъакъо зэпик1эгъ, '''арти''' иунэ тхьэмафэ илъэгъ",
            "translation": "The boy broke his leg, '''thus''' he was lying down in his home for a week."
          },
          {
            "sentence": "Джыхашъо ц1ашъутэ, '''арти''' шъумычъ",
            "translation": "The floor is slip, '''so''' don't run (said to plural)"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːrti"
  },
  {
    "word": "мэп1эрк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1эрк1ьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mapʼarkʲən"
  },
  {
    "word": "гъэп1эрк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэп1орк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapʼarkʲən"
  },
  {
    "word": "уфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swing",
        "examples": [
          {
            "sentence": "К1алэм чатэр '''еуфы'''",
            "translation": "The boy '''is swinging''' the sword."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəfən"
  },
  {
    "word": "гъэзалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wheel",
        "examples": []
      },
      {
        "meaning": "steering wheel",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁazaːɬa"
  },
  {
    "word": "мэдэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэ1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "madaʔʷan"
  },
  {
    "word": "едэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to listen to",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''едэ1у'''",
            "translation": "The boy '''listens to''' the man."
          },
          {
            "sentence": "К1алэр музикэ '''едэ1урэп'''",
            "translation": "The boy '''does not listens to''' music."
          },
          {
            "sentence": "Къас1орэм '''къедэ1у'''",
            "translation": "'''listen''' to what I say."
          },
          {
            "sentence": "У л1ым '''уемыдэ1у'''",
            "translation": "Don't '''listen''' to that man."
          },
          {
            "sentence": "Моу зэ '''къэда1у'''",
            "translation": "'''Listen''' for a moment."
          },
          {
            "sentence": "Ц1ыфыхэр ощхым '''едэ1ух '''",
            "translation": "The people '''are listening to''' the rain."
          }
        ]
      },
      {
        "meaning": "to listen and obey to",
        "examples": [
          {
            "sentence": "Дзэмк1э дзэл1ыхэмэ ятхьэматэ '''едэ1ух'''",
            "translation": "In the military, the soldiers '''are obeying''' their caption."
          },
          {
            "sentence": "К1алэр '''къысэдэ1урэп'''",
            "translation": "The boy '''doesn't obey me'''."
          },
          {
            "sentence": "Уанахьыжъымэ '''уадэ1ун''' фае",
            "translation": "'''You must listen''' to your elders."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jadaʔʷən"
  },
  {
    "word": "гъэдэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone listen to",
        "examples": [
          {
            "sentence": "К1алэр '''къысэгъэдэ1у'''",
            "translation": "'''Make''' the boy '''listen to me'''."
          },
          {
            "sentence": "Сик1алэхэр музикэм '''есэгъэдэ1ух'''",
            "translation": "'''I make''' my boys '''listen''' to the music."
          },
          {
            "sentence": "К1алэр л1ым '''егъэдэ1у''' сыкъэк1ожьыфэ",
            "translation": "'''Make''' the boy '''listen''' to the man until I return."
          },
          {
            "sentence": "Джаз '''семыгъэдэ1у'''",
            "translation": "'''Don't make me listen to''' jazz."
          }
        ]
      },
      {
        "meaning": "to make someone listen and obey to",
        "examples": [
          {
            "sentence": "Зыгорэм мо к1элэц1ык1ур '''ыгъэдэ1он''' фае",
            "translation": "Someone need '''to make''' that boy '''listen'''."
          },
          {
            "sentence": "Л1ым ык1алэхэр '''ыгъэдэ1ошъухэрэп'''",
            "translation": "The man '''cant' make''' his children '''obey him'''."
          },
          {
            "sentence": "К1алэр '''къысэгъэдэ1у'''",
            "translation": "'''Make''' the boy '''obey me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁadaʔʷən"
  },
  {
    "word": "егъэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone hear something",
        "examples": [
          {
            "sentence": "К1алэм ишы '''регъэдэ1у''' музикэ",
            "translation": "The boy '''make''' his brother '''hear''' music."
          },
          {
            "sentence": "Сик1алэхэр музикэм '''есэгъэда1ух'''",
            "translation": "'''I make''' my boys '''listen''' to the music."
          },
          {
            "sentence": "Уимакъэ '''сэмыгъэ1у'''",
            "translation": "'''Don't make me hear''' your voice."
          },
          {
            "sentence": "Уиныбджэгъумэ уиорэд '''ягъэ1у'''",
            "translation": "'''Make''' your friends '''hear''' your song."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁaʔʷən"
  },
  {
    "word": "к1эхъопсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to long for, to miss someone (To feel the absence of someone or something)",
        "examples": [
          {
            "sentence": "Пшъашъэр иянэ '''к1эхъопсы'''",
            "translation": "The girl '''misses''' her mother."
          },
          {
            "sentence": "Пшъашъэм к1алэр '''к1эхъопсы'''",
            "translation": "The boy '''is missing''' the girl."
          },
          {
            "sentence": "Сиянэ '''сык1эхъопсыжьы'''",
            "translation": "'''I miss''' my mother."
          },
          {
            "sentence": "Уиныбджэгъухэмэ '''уак1эхъопса?'''",
            "translation": "'''Do you miss''' your friends."
          },
          {
            "sentence": "Гонахьыхэмэ '''уак1эмыхъопс'''",
            "translation": "'''Don't miss''' doing sinful actions/stuff."
          },
          {
            "sentence": "'''Шъуск1эхъопсыжьэгъ'''",
            "translation": "'''I miss you''' (Said to plural)."
          },
          {
            "sentence": "Ащ ишхын '''сык1эхъопсырэп'''",
            "translation": "'''I don't miss''' his food."
          },
          {
            "sentence": "'''Уск1эхъопсыщт'''",
            "translation": "'''I gonna miss you'''."
          },
          {
            "sentence": "Ик1алэхэр '''к1эхъопсыгъэх'''",
            "translation": "His boys '''missed him'''."
          }
        ]
      },
      {
        "meaning": "to have a dream to look forward to",
        "examples": [
          {
            "sentence": "Летчикы хъуным ар '''кIэхъопсы'''",
            "translation": "he dreams of becoming a pilot."
          }
        ]
      },
      {
        "meaning": "to envy",
        "examples": []
      }
    ],
    "synonyms": [
      "ехъопсэн"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaχʷapsəːn"
  },
  {
    "word": "къыкъок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къок1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къокьын"
    ],
    "type": "verb",
    "ipa": "qəqʷat͡ʃʼən"
  },
  {
    "word": "къок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come out (from a corner or from a hidden spot)",
        "examples": [
          {
            "sentence": "Пшъашъэр къурнэм '''къок1эгъ'''",
            "translation": "The girl '''came out''' from the corner."
          },
          {
            "sentence": "'''Шъукъыкъок1ыжь'''",
            "translation": "'''Come out!''' (said to plural)."
          },
          {
            "sentence": "Ау '''къыкъок1ы'''",
            "translation": "'''Come out''' from there."
          },
          {
            "sentence": "К1алэм зигъэбылъи '''къок1ыжьэгъ'''",
            "translation": "The boy hid '''and came out'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къок1ьын"
    ],
    "type": "verb",
    "ipa": "qʷat͡ʃʼən"
  },
  {
    "word": "къок1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къок1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къок1ын"
    ],
    "type": "verb",
    "ipa": "qʷakʲʼən"
  },
  {
    "word": "1ук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go away",
        "examples": [
          {
            "sentence": "'''1ук1ы 1ук1ы''' мыу",
            "translation": "'''Go away go away''' from here."
          },
          {
            "sentence": "Пшъашъэр чъыгым '''1ук1ыжьэгъ'''",
            "translation": "The girl '''gone away''' from the tree."
          },
          {
            "sentence": "Мыу '''шъу1ук1ы'''",
            "translation": "'''Go away!''' from here (said to plural)."
          },
          {
            "sentence": "К1алэр анэм '''1ук1ыщтэп'''",
            "translation": "The boy '''will not go away''' from the table."
          },
          {
            "sentence": "Л1ыжъыхэр '''1ук1ых'''",
            "translation": "The old men '''are going away'''."
          }
        ]
      }
    ],
    "synonyms": [
      "1укьын"
    ],
    "type": "verb",
    "ipa": "ʔʷat͡ʃʼən"
  },
  {
    "word": "1укьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go away",
        "examples": [
          {
            "sentence": "'''1укьы 1укьы''' мыу",
            "translation": "'''Go away go away''' from here."
          },
          {
            "sentence": "Пшъашъэр чъыгым '''1укьыжьэгъ'''",
            "translation": "The girl '''gone away''' from the tree."
          },
          {
            "sentence": "Мыу '''шъу1укьы'''",
            "translation": "'''Go away!''' from here (said to plural)."
          },
          {
            "sentence": "К1алэр анэм '''1укьыщтэп'''",
            "translation": "The boy '''will not go away''' from the table."
          },
          {
            "sentence": "Л1ыжъыхэр '''1укьых'''",
            "translation": "The old men '''are going away'''."
          }
        ]
      }
    ],
    "synonyms": [
      "1ук1ын"
    ],
    "type": "verb",
    "ipa": "ʔʷakʲən"
  },
  {
    "word": "1угъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone go away",
        "examples": [
          {
            "sentence": "Пшъашъэр к1алэр '''1уегъэк1ы''' чъыгым",
            "translation": "The girl '''makes''' the boy '''go away''' from the tree."
          },
          {
            "sentence": "'''Сы1ушъугъэк1ышъутэп'''",
            "translation": "'''You can't make me go away''' (said to plural)."
          },
          {
            "sentence": "К1алэр анэм '''1уагъэк1ыну''' фаех л1ыжъыхэр",
            "translation": "The old men want '''to make''' the boy '''go away''' from the table."
          },
          {
            "sentence": "Л1ыгъэк1э '''у1узгъэк1ыну''' уфая?",
            "translation": "Do you want '''me to make you go away''' by force?"
          },
          {
            "sentence": "'''сы1убгъэк1ымэ''' анэм",
            "translation": "'''If you make me go away from''' the table."
          },
          {
            "sentence": "Бэрэ укуомэ л1ыжъыхэр '''1убгъэк1ыжьтых'''",
            "translation": "If you scream a lot, '''you will make''' the old men '''leave'''."
          }
        ]
      }
    ],
    "synonyms": [
      "1угъэкьын"
    ],
    "type": "verb",
    "ipa": "ʔʷaʁat͡ʃʼən"
  },
  {
    "word": "зупсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shave oneself",
        "examples": [
          {
            "sentence": "К1алэм '''зеупсы'''",
            "translation": "The boy '''is shaving himself'''."
          },
          {
            "sentence": "'''Зыупсы'''",
            "translation": "'''Shave yourself'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəwəpsən"
  },
  {
    "word": "1уашъхьэмафэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mountain r/Caucasus",
        "examples": [],
        "tags": [
          "place",
          "mountain"
        ]
      }
    ],

    "cognate": "1уащхьэмахуэ",
    "type": "adj",
    "ipa": "ʔʷaːʂħamaːfa"
  },
  {
    "word": "лъэужь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "what again",
        "examples": [
          {
            "sentence": "'''лъэужь''' уис1э?",
            "translation": "'''what''' is your name '''again'''?"
          },
          {
            "sentence": "'''лъэужь''' уш1эрэ?",
            "translation": "'''what''' are you doing '''again'''?"
          },
          {
            "sentence": "'''лъэужь''' у1орэ?",
            "translation": "'''what''' are you saying '''again'''?"
          }
        ]
      }
    ],
    "type": "pron",
    "ipa": "ɬawəʑ"
  },
  {
    "word": "гуш1уагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуш1уагъо\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʃʷʼaːʁa"
  },
  {
    "word": "нысащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wedding",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəsaːɕa"
  },
  {
    "word": "нысэщэджэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Marriage party",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəːsaɕad͡ʒaɡʷ"
  },
  {
    "word": "тхьэ1офыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "angel",
        "examples": []
      }
    ],
    "cognate": "тхьэ1ухуд",
    "type": "adj",
    "ipa": "/tħaʔʷafət/"
  },
  {
    "word": "хъэрен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swing",
        "examples": []
      }
    ],
    "synonyms": [
      "кьэрхъон",
      "к1эрхъон"
    ],
    "type": "noun",
    "ipa": "χarajn"
  },
  {
    "word": "пщынао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "accordionist, a person that plays accordion",
        "examples": []
      }
    ],

    "cognate": "пщынауэ",
    "type": "noun",
    "ipa": "pɕənaːwa"
  },
  {
    "word": "пщынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "accordion",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕəna"
  },
  {
    "word": "хьэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dog (Shapsug dialect)",
        "examples": []
      }
    ],

    "cognate": "хьэ",
    "type": "noun",
    "ipa": "ħaʐ"
  },
  {
    "word": "енэкъокъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to argue with someone",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''енэкъокъу'''",
            "translation": "The boy '''is arguing with''' the girl."
          },
          {
            "sentence": "К1алэр сэ '''къысэнэкъокъу'''",
            "translation": "The boy '''is arguing with me'''."
          },
          {
            "sentence": "К1алэр бэрэ сишымэ '''ян''' къас1орэ ш1э",
            "translation": "The boy '''argued''' a lot '''with''' my brothers."
          },
          {
            "sentence": "К1алэр пшъашъэм '''емынэкъокъоу''' блэк1ыгъэ",
            "translation": "The boy '''without arguing with''' the girl passed."
          },
          {
            "sentence": "Ахэр енэу '''къэнэкъуакъохэнахь'''",
            "translation": "They always '''argue'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "janaqʷaqʷan"
  },
  {
    "word": "мэц1эц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1эц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "с1эс1эн"
    ],
    "type": "verb",
    "ipa": "mat͡sʼat͡sʼan"
  },
  {
    "word": "тэц1эц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тец1эц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тэс1эс1эн"
    ],
    "type": "verb",
    "ipa": "tat͡sʼat͡sʼan"
  },
  {
    "word": "мэс1эс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"с1эс1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1эц1эн"
    ],
    "type": "verb",
    "ipa": "masʼasʼan"
  },
  {
    "word": "тэс1эс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тэц1эц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тэц1эц1эн"
    ],
    "type": "verb",
    "ipa": "tasʼasʼan"
  },
  {
    "word": "ц1эц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to yell",
        "examples": [
          {
            "sentence": "Л1ыр '''мэц1ац1э'''",
            "translation": "The man'''is yelling'''."
          },
          {
            "sentence": "Тхьэматэр '''мэц1ац1эу''' 1ут",
            "translation": "The leader '''is''' standing there '''yelling'''."
          }
        ]
      }
    ],
    "synonyms": [
      "с1эс1эн"
    ],
    "type": "verb",
    "ipa": "t͡sʼat͡sʼan"
  },
  {
    "word": "с1эс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1эц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэц1эц1эн"
    ],
    "type": "verb",
    "ipa": "sʼasʼan"
  },
  {
    "word": "гъэпц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to deceive someone",
        "examples": [
          {
            "sentence": "Пшъашъэм к1алэр '''егъэпц1ы'''",
            "translation": "The girl '''is deceiving''' the boy."
          },
          {
            "sentence": "К1алэм '''уегъэпц1ы'''",
            "translation": "The boy '''is deceiving you'''."
          },
          {
            "sentence": "Пшъашъэм '''земыгъэпц1'''",
            "translation": "'''Don't let''' the girl '''deceive you'''."
          },
          {
            "sentence": "К1алэр '''къагъэпц1агъ'''",
            "translation": "The boy '''was deceived'''."
          },
          {
            "sentence": "'''Уагъэпц1эщт''' к1алэхэмэ",
            "translation": "The boys '''will deceive you'''."
          },
          {
            "sentence": "К1алэр '''угъэпц1эшъущтэп'''",
            "translation": "'''You can't deceive''' the boy."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэпс1эн"
    ],
    "type": "verb",
    "ipa": "ʁapt͡sʼən"
  },
  {
    "word": "пц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie",
        "examples": [
          {
            "sentence": "Пшъашъэр '''мэпц1ы'''",
            "translation": "The girl '''is lying'''."
          },
          {
            "sentence": "К1алэр иянэ '''фэпц1ыгъ'''",
            "translation": "The boy '''lied''' to his mother."
          },
          {
            "sentence": "К1алэр бэрэ '''мэпц1ы'''",
            "translation": "The boy '''is lying''' a lot."
          }
        ]
      }
    ],
    "synonyms": [
      "пс1ын",
      "пц1ы усын"
    ],
    "type": "verb",
    "ipa": "pt͡sʼən"
  },
  {
    "word": "мэпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пц1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пс1ын"
    ],
    "type": "verb",
    "ipa": "mapt͡sʼən"
  },
  {
    "word": "фэпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie to someone",
        "examples": [
          {
            "sentence": "Пшъашъэм к1алэр '''фэпц1ы'''",
            "translation": "The girl '''is lying to''' the boy."
          },
          {
            "sentence": "К1алэм '''уфэпц1ы'''",
            "translation": "'''You are lying to''' the boy."
          },
          {
            "sentence": "Пшъашъэр '''зыфэпц1ыжьы'''",
            "translation": "The girl '''is lying to herself'''."
          },
          {
            "sentence": "К1алэр '''къафэпц1ы''' л1ымэ",
            "translation": "The boy '''is lying to''' the men."
          },
          {
            "sentence": "'''Уафэпц1ы'''",
            "translation": "'''You are lying to them'''"
          }
        ]
      }
    ],
    "synonyms": [
      "фэпс1ын"
    ],
    "type": "verb",
    "ipa": "fapt͡sʼən"
  },
  {
    "word": "ехъурэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anything else than, something additional than",
        "examples": [
          {
            "sentence": "К1алэм пц1ы '''ехъурэ''' къипч1ырэп",
            "translation": "The boy doesn't say '''anything''' except lies."
          },
          {
            "sentence": "Бэнанэ '''ехъурэ''' ушхырэба?",
            "translation": "Don't you eat '''anything but''' banana?"
          },
          {
            "sentence": "А к1алэм '''ехъурэм''' укъеджэшъутыгъэба?",
            "translation": "Couldn't you call '''anybody else''' then this boy?"
          },
          {
            "sentence": "Ащ '''ехъурэ''' к1алэм иш1эрэп",
            "translation": "The boy doesn't do '''anything but''' that."
          },
          {
            "sentence": "Футбалым '''ехъурэ''' сеплъырэп",
            "translation": "I don't watch '''anything but''' football."
          },
          {
            "sentence": "Ащ '''ехъурэгорэ''' тэгъаш1",
            "translation": "Let us do '''something different'''."
          },
          {
            "sentence": "К1алэм ихьэ закъо '''ехъурэ''' римыгъусэу ежьагъ",
            "translation": "The boy went off without anyone accompanying him expect his dog."
          }
        ]
      }
    ],
    "synonyms": [
      "щэхъу"
    ],
    "type": "adv",
    "ipa": "jaχʷra"
  },
  {
    "word": "пс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пц1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пц1ын"
    ],
    "type": "verb",
    "ipa": "psʼən"
  },
  {
    "word": "гъэпс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэпц1эн"
    ],
    "type": "verb",
    "ipa": "ʁapsʼən"
  },
  {
    "word": "фэпс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэпц1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "фэпц1ын"
    ],
    "type": "verb",
    "ipa": "fapsʼən"
  },
  {
    "word": "мэнэкъокъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэкъокъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "manaqʷaqʷan"
  },
  {
    "word": "дзуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shuck",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡zʷət͡sʷan"
  },
  {
    "word": "ч1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bottom",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼa"
  },
  {
    "word": "бгэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ебгын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jabɣən"
  },
  {
    "word": "лъэпапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tiptoe",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэпапс1э",
      "лъэпэпс1ий"
    ],
    "type": "noun",
    "ipa": "ɬapaːpt͡sʼa"
  },
  {
    "word": "1эбыцу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glove",
        "examples": []
      },
      {
        "meaning": "mitten",
        "examples": []
      }
    ],
    "synonyms": [
      "1эбычъу"
    ],
    "type": "noun",
    "ipa": "ʔabət͡sʷ"
  },
  {
    "word": "1эпапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fingertip",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпэпс1ий",
      "1эпапс1э"
    ],
    "type": "noun",
    "ipa": "ʔapaːpsʼa"
  },
  {
    "word": "нэугъэуп1эп1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "second",
        "examples": [
          {
            "sentence": "Зы такъикъыр '''нэугъэупIэпIэгъу''' тIокIищ мэхъу",
            "translation": "A minute has sixty '''seconds'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nawʁawpʼapʼaʁʷ"
  },
  {
    "word": "гъэуп1эп1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to blink",
        "examples": [
          {
            "sentence": "К1алэм ынэхэр бэрэ '''егъэуп1эп1ых'''",
            "translation": "The boy '''blinks''' a lot."
          }
        ]
      }
    ],
    "synonyms": [
      "уп1эп1эн"
    ],
    "type": "verb",
    "ipa": "ʁawpʼapʼan"
  },
  {
    "word": "уп1эп1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to blink",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэуп1эп1эн"
    ],
    "type": "verb",
    "ipa": "wpʼapʼan"
  },
  {
    "word": "пк1ат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пк1ант1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ант1э"
    ],
    "cognate": "пщ1ат1э",
    "type": "noun",
    "ipa": "pt͡ʃʼaːtʼa"
  },
  {
    "word": "гопэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be pleased with, to be glad that",
        "examples": [
          {
            "sentence": "Мы к1алэр '''сигуап'''",
            "translation": "I '''am pleased with''' this boy."
          },
          {
            "sentence": "'''Сигуапэп''' хъурэр",
            "translation": "I '''am not pleased with''' what is happening."
          },
          {
            "sentence": "Томым ишхын к1алэм '''ыгуапэп'''",
            "translation": "The boy '''is not pleased with''' Tom's dinner."
          },
          {
            "sentence": "Пшъашъэм '''ыгопагъ''' фэсщэфыгъэр",
            "translation": "The girl '''was pleased with''' what I bought for her."
          },
          {
            "sentence": "К1алэм '''ыгопагъэба''' зыдэк1уагъэр?",
            "translation": "'''Wasn't''' the boy '''pleased with''' where he went?"
          },
          {
            "sentence": "А л1ыжъыхэмэ '''агуапэп''' ядэжьык1э макъэ къэбгъэ1унэ",
            "translation": "Those old-men '''are not pleased with''' people making noise near them."
          }
        ]
      }
    ],
    "synonyms": [
      "к1эсэн",
      "ш1улъэгъун",
      "гу рихьан"
    ],
    "type": "verb",
    "ipa": "ɡʷapan"
  },
  {
    "word": "ч1итхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to accelerate",
        "examples": [
          {
            "sentence": "К1алэм '''ч1етхъу'''",
            "translation": "The boy '''is accelerating'''."
          },
          {
            "sentence": "К1алэм мащинэмк1э '''ч1итхъогъ'''",
            "translation": "The boy '''accelerated''' with the car."
          },
          {
            "sentence": "'''Ч1этхъу!'''",
            "translation": "'''Accelerate!'''"
          },
          {
            "sentence": "Мащинэм '''зч1итхъушъурэп'''",
            "translation": "The car '''can't accelerate'''"
          },
          {
            "sentence": "А к1алэхэмэ салъэгъугъэу '''зч1атхъугъэ'''",
            "translation": "The boys '''accelerated''' once they saw me."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼəjtχʷən"
  },
  {
    "word": "мэкъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maqən"
  },
  {
    "word": "хэкъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be totally frozen (Kfar Kama dialect)",
        "examples": [
          {
            "sentence": "Иныжъэр осым '''хэкъэхьэгъ'''",
            "translation": "The giant '''was frozen''' in the snow"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaqaħən"
  },
  {
    "word": "къын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get stupefied",
        "examples": []
      },
      {
        "meaning": "to get frozen with terror",
        "examples": []
      },
      {
        "meaning": "to turn into a stone",
        "examples": [
          {
            "sentence": "Медузэ еплъырэ '''къыщт'''",
            "translation": "Whoever looks at Medusa '''will turn into a stone'''."
          }
        ]
      },
      {
        "meaning": "to become petrified",
        "examples": []
      },
      {
        "meaning": "(Kfar Kama dialect) to be frozen",
        "examples": [
          {
            "sentence": "Хьэлыгъур '''мэкъы'''",
            "translation": "The bread '''is freezing'''."
          },
          {
            "sentence": "Чэщым '''сыкъэу''' сыхэт",
            "translation": "'''I am''' standing at the night '''freezing'''."
          },
          {
            "sentence": "К1алэр '''мэкъы''', чъы1эм хэт",
            "translation": "The boy '''is freezing''', he is in the cold."
          },
          {
            "sentence": "Хьэлэгъор '''къыгъа?'''",
            "translation": "'''Did''' the bread''' froze'''?"
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "verb",
    "ipa": "qən"
  },
  {
    "word": "гъэкъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone or something into a stone",
        "examples": [
          {
            "sentence": "Медузэ уеплъымэ '''уигъэкъыщт'''",
            "translation": "If you look at Medusa, '''she will make you a stone'''."
          }
        ]
      },
      {
        "meaning": "to freeze (Kfar Kama dialect)",
        "examples": [
          {
            "sentence": "Хьэлэгъор рифрыджыреторым '''егъэкъы'''",
            "translation": "The refrigerator '''is freezing''' the bread."
          },
          {
            "sentence": "Хьэлэгъор '''гъэкъы'''",
            "translation": "'''Freeze''' the bread."
          },
          {
            "sentence": "К1алэм хьэлэгъухэр '''егъэкъых'''",
            "translation": "The boy '''is freezing''' the breads."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaqən"
  },
  {
    "word": "псыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "island",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəɡʷə"
  },
  {
    "word": "анезечъыг",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mast",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "anajzajt͡ʃʷəɡ"
  },
  {
    "word": "кутлэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "harbour",
        "examples": []
      }
    ],
    "synonyms": [
      "бэгъуаз",
      "къухьэуцуп1э"
    ],
    "cognate": "кхъухьтедзап1э",
    "type": "noun",
    "ipa": "kʷətla"
  },
  {
    "word": "къошъол1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sailor, seaman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʂʷaɬʼə"
  },
  {
    "word": "хыблыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bay, inlet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəbləɡʷə"
  },
  {
    "word": "къошъоч1эгъаз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rudder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʂʷat͡ʃaʁaz"
  },
  {
    "word": "къошъомэзэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lighthouse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʂʷamazaʐəj"
  },
  {
    "word": "къошъофы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boatman",
        "examples": []
      }
    ],
    "synonyms": [
      "къошъоджы"
    ],
    "type": "noun",
    "ipa": "qʷaʂʷafə"
  },
  {
    "word": "къошъош1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shipwright, shipbuilder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʂʷaʃˈə"
  },
  {
    "word": "хыгъэхъунэныкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "half-island, peninsula",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəʁaχʷənanəqʷə"
  },
  {
    "word": "хырык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "foreign country, abroad, overseas",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xərət͡ʃ"
  },
  {
    "word": "къухьэт1ысып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anchorage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəħatʼəsəpʼ"
  },
  {
    "word": "хысыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wave crest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəsəd͡ʒ"
  },
  {
    "word": "сыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anvil",
        "examples": []
      },
      {
        "meaning": "crest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "səd͡ʒ"
  },
  {
    "word": "анезе",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "anajzaj"
  },
  {
    "word": "хыт1уал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bay, gulf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xətʷʼal"
  },
  {
    "word": "хыуай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "storm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xəwaj"
  },
  {
    "word": "гъурз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anchor",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷərz"
  },
  {
    "word": "зэок1окъуашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "galley",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zawkʷʼaqʷaʂʷə"
  },
  {
    "word": "псыкъефэх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "waterfall",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəqajfax"
  },
  {
    "word": "шыкъолэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "zebra",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəqʷalan"
  },
  {
    "word": "цэхь Фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цыхьэ ш1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цэхь тэлъхьэн",
      "цыхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "t͡saħ faʃʼən"
  },
  {
    "word": "цыхьэ фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trust someone",
        "examples": [
          {
            "sentence": "'''Цыхьэ къупФэсэш1ы'''",
            "translation": "'''I trust you.'''"
          },
          {
            "sentence": "Пшъашъэм '''цыхьэ Фыреш1ы''' к1алэм ахъчэ къыфихьнэу",
            "translation": "The girl '''trusts''' the boy to bring money for her."
          },
          {
            "sentence": "'''Цыхьэ къэсфэш1''' уик1алэ нэ1э тэсгъэтныу",
            "translation": "'''Trust me''' to protect your boy."
          },
          {
            "sentence": "'''Цыхьэ фаш1ырэп ''' к1алэм",
            "translation": "'''They don't trust''' the boy."
          },
          {
            "sentence": "К1алэм '''цыхьэ''' пшъашъэм '''фиш1эгъ'''",
            "translation": "The boy '''trusted''' the girl."
          }
        ]
      }
    ],
    "synonyms": [
      "цэхь Фэш1ын",
      "цэхь тэлъхьэн",
      "цыхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "t͡saħ faʃʼən"
  },
  {
    "word": "цэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trust",
        "examples": []
      }
    ],
    "synonyms": [
      "цыхьэ"
    ],
    "type": "noun",
    "ipa": "t͡saħ"
  },
  {
    "word": "зэуап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "battlefield",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zawaːpʼa"
  },
  {
    "word": "нэ1э тэгъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэ1э тегъэтын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "naʔa taʁatən"
  },
  {
    "word": "къыч1эгъэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыч1эгъэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qət͡ʃʼaʁakʲʼən"
  },
  {
    "word": "мэпсэлъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псэлъэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэгущы1эн"
    ],
    "type": "verb",
    "ipa": "mapsaɬan"
  },
  {
    "word": "къэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be remained, to be left",
        "examples": [
          {
            "sentence": "К1алэр изакъоу '''къэнагъ'''",
            "translation": "The boy '''was left''' alone."
          },
          {
            "sentence": "Бэнанэхэр '''къэнагъэх'''",
            "translation": "The bananas '''are left'''."
          },
          {
            "sentence": "Мые '''къэнагъ'''",
            "translation": "An apple '''was left'''."
          },
          {
            "sentence": "Псы '''къупфэнагъа?'''",
            "translation": "'''Do you have remained''' water?"
          },
          {
            "sentence": "Ары псы '''къэсфэнагъ'''",
            "translation": "Indeed, '''i still have''' water '''left'''."
          },
          {
            "sentence": "Пицэр '''къанэ'''",
            "translation": "The pizza '''is being remained'''."
          },
          {
            "sentence": "Мыщ '''укъэнэнэу''' пшIоигъуа?",
            "translation": "Do you want '''to stay/remain''' here?"
          },
          {
            "sentence": "Сизакъоу '''сыкъэнэнэу''' сшIоигъу",
            "translation": "'''I''' want '''to be left''' alone."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qanan"
  },
  {
    "word": "къэгъэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to leave something (To cause or allow (something) to remain as available)",
        "examples": [
          {
            "sentence": "К1алэм псы '''къегъанэ'''",
            "translation": "The boy '''is leaving some''' water."
          },
          {
            "sentence": "К1алэм псы '''къегъэнагъ'''",
            "translation": "The boy '''left some'''' water."
          },
          {
            "sentence": "Бэнанэхэр '''къэгъанэх'''",
            "translation": "'''Leave some of''' the bananas."
          },
          {
            "sentence": "Пицэр '''къэшъугъан'''",
            "translation": "'''Leave some of (said to plural)''' the pizza."
          },
          {
            "sentence": "К1алэхэмэ пицэр '''къагъанэрэп'''",
            "translation": "The boys '''are not leaving some of''' the pizza."
          },
          {
            "sentence": "Ары псы '''къэсгъэнагъ'''",
            "translation": "Indeed, '''i left some''' water."
          },
          {
            "sentence": "Ц1ыфы зы '''къэшъумыгъан'''",
            "translation": "'''Don't let''' any person '''remain'''."
          },
          {
            "sentence": "Пицэр '''къэмыгъанэу''' щхэ",
            "translation": "Eat the banana '''without leaving some of it'''."
          },
          {
            "sentence": "Бэнанэхэр '''къэшъумыгъанэхэу''' шъущх",
            "translation": "Eat the bananas '''without leaving some of them'''."
          }
        ]
      },
      {
        "meaning": "to leave someone, to leave alone someone",
        "examples": []
      },
      {
        "meaning": "to abandon someone",
        "examples": []
      }
    ],
    "synonyms": [
      "къыщыунэн"
    ],
    "type": "verb",
    "ipa": "qaʁanan"
  },
  {
    "word": "щык1эгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to need to",
        "examples": [
          {
            "sentence": "Л1ым уфэго1эн '''ищык1агъэп'''",
            "translation": "You '''don't need''' to worry about the man."
          },
          {
            "sentence": "К1алэм псы '''ищык1агъэ'''",
            "translation": "The boy '''needs''' water."
          },
          {
            "sentence": "К1алэм псы '''ищык1эгъагъ'''",
            "translation": "The boy '''needed''' water."
          },
          {
            "sentence": "Зыгори '''сыщык1агъэп'''",
            "translation": "'''I don't need''' anything."
          },
          {
            "sentence": "Л1ы тхьамык1ыхэмэ ахъышэ '''ящык1агъ'''",
            "translation": "The poor men '''needs''' money."
          },
          {
            "sentence": "'''Ищык1агъэп'''",
            "translation": "'''No need to'''."
          },
          {
            "sentence": "'''Усищык1агъ''' себгъэджэнэу",
            "translation": "'''I need you''' to study me."
          },
          {
            "sentence": "Ахэмэ ахъышэ '''ящык1агъэп'''",
            "translation": "They '''don't need''' money."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕət͡ʃʼaʁan"
  },
  {
    "word": "загъорэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sometimes",
        "examples": [
          {
            "sentence": "'''Загъорэ''' ныбэузым сегъэгумэкIы",
            "translation": "I '''sometimes''' have '''stomach aches'''."
          },
          {
            "sentence": "'''Загъорэ''' есып1эм сэк1о",
            "translation": "'''Sometimes''' i go to the pool."
          },
          {
            "sentence": "'''Загъорэ''' тэсэгъафэ '''загъорэ''' тэсыгъафэрэп",
            "translation": "'''Sometimes''' i hit '''sometimes''' i don't hit."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "zaːʁʷara"
  },
  {
    "word": "зегъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lean on something",
        "examples": [
          {
            "sentence": "К1алэр чъыгым '''зырегъэк1ы'''",
            "translation": "The boy '''leaning on''' the tree."
          },
          {
            "sentence": "Л1ыжъыр пхъэнт1эк1ум '''зырегъэк1ы'''",
            "translation": "The old man '''is leaning on''' the chair."
          },
          {
            "sentence": "Дэпкъы шъоим '''земыгъэк1''', укъиуш1оищт",
            "translation": "'''Don't lean on''' the dirty wall, it will make you dirty."
          },
          {
            "sentence": "1анэм '''земыгъэк1''', зэхэпк1ыщт",
            "translation": "'''Don't lean on''' the table, you will crash it."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zeʁat͡ʃʼən"
  },
  {
    "word": "курэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wheelbarrow",
        "examples": []
      },
      {
        "meaning": "baby carriage, stroller",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəraʐəja"
  },
  {
    "word": "ч1ыунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "basement",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wnaːʂħa"
  },
  {
    "word": "щынагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dangerous",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɕənaːʁʷ"
  },
  {
    "word": "къаблэу ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to approve, to grant something approval",
        "examples": [
          {
            "sentence": "Янэрэ ятэрэ '''къаблэу уаш1ытэп'''.",
            "translation": "Her father and mother '''won't approve you'''."
          },
          {
            "sentence": "Тэбытхык1мэ уитест '''къаблэу яш1ытэп'''.",
            "translation": "If you copy '''they won't approve''' your test."
          },
          {
            "sentence": "1офым '''къаблэу уаш1ымэ''' у1уагъэхьэт.",
            "translation": "'''If you get approved''' for the work they will hire you."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaːblaw ʃʼən"
  },
  {
    "word": "ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to build",
        "examples": [
          {
            "sentence": "Л1ым унэ '''ш1ы'''",
            "translation": "The man '''builds''' a house.."
          }
        ]
      },
      {
        "meaning": "to make",
        "examples": [
          {
            "sentence": "'''сэш1ы''' ар",
            "translation": "'''I am making''' that."
          },
          {
            "sentence": "'''ш1ы''' мыщтэу",
            "translation": "'''Make it''' like this."
          },
          {
            "sentence": "К1алэм '''еш1ы''' шхын",
            "translation": "The boy '''is making''' a food."
          },
          {
            "sentence": "Джырэк1э зыгорэ '''сэш1ы'''.",
            "translation": "'''I am making''' something for now."
          }
        ]
      },
      {
        "meaning": "to produce",
        "examples": [
          {
            "sentence": "1офымк1э гъуч1ыхэр '''аш1ых'''",
            "translation": "'''They produce''' irons in the work."
          },
          {
            "sentence": "Джэгъогъум нахь бэ Джэгъогъу '''къеш1ы'''",
            "translation": "Hate '''produces''' more hate."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃˈən"
  },
  {
    "word": "тыгъэзегъэуп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beach",
        "examples": [
          {
            "sentence": "'''Тыгъэзегъэуп1эм''' сыщ",
            "translation": "Drive me to '''the beach'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "təʁazeʁawpʼa"
  },
  {
    "word": "удзуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shuck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wd͡zʷət͡sʷan"
  },
  {
    "word": "лъэбэкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэубакъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬabaqʷ"
  },
  {
    "word": "пхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sow",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pχan"
  },
  {
    "word": "хьаку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "oven",
        "examples": []
      },
      {
        "meaning": "stove",
        "examples": []
      }
    ],

    "cognate": "хьэку",
    "type": "noun",
    "ipa": "ħaːkʷ"
  },
  {
    "word": "ц1элъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1энлъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼaɬan"
  },
  {
    "word": "мэц1элъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1элъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼaɬan"
  },
  {
    "word": "ец1эшъутэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slide on something",
        "examples": [
          {
            "sentence": "К1алэр сылайдым '''ец1эшъутэхы'''",
            "translation": "The boy '''is sliding''' the slide."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jat͡sʼaʃʷtaxən"
  },
  {
    "word": "цохъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slip/slide (on a surface that have a fraction like dirt, mud, wooden table and etc.) (Usually objects)",
        "examples": [
          {
            "sentence": "Мащинэ '''мэцуахъо'''",
            "translation": "The car '''is slipping'''."
          },
          {
            "sentence": "Джипэ ят1эм хэтэу '''мэцуахъо'''",
            "translation": "The jeep while being on the dirt '''is slipping'''."
          }
        ]
      }
    ],
    "synonyms": [
      "чъохъон"
    ],
    "type": "verb",
    "ipa": "t͡sʷaχʷan"
  },
  {
    "word": "мэцохъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цохъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʷaχʷan"
  },
  {
    "word": "чъохъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цохъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "цохъон"
    ],
    "type": "verb",
    "ipa": "t͡ʃʷaχʷan"
  },
  {
    "word": "фитын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be up for, to be willing to participate in",
        "examples": [
          {
            "sentence": "О '''уфита''' мы фылымым теплъэу?",
            "translation": "'''Are you up for''' watching this film."
          },
          {
            "sentence": "Мы узы 1аем '''сфитыжьрэп'''",
            "translation": "'''I am not up for''' this awful disease."
          },
          {
            "sentence": "Иджы тызэонэу '''тфитэп'''",
            "translation": "Right now '''we are not up for''' war."
          }
        ]
      },
      {
        "meaning": "to be allowed; to",
        "examples": [
          {
            "sentence": "Сэ сыкъызык1эмык1уагъэр '''сыфитыгъэпышъ''' ары.",
            "translation": "I didn’t come because '''I was not allowed to'''."
          },
          {
            "sentence": "Ар машинэм ичъыихьан '''фитэп'''.",
            "translation": "'''He is not allowed''' to sleep in the car. ."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fəjtən"
  },
  {
    "word": "нэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "light",
        "examples": []
      }
    ],
    "synonyms": [
      "нэфнэ",
      "нэфын"
    ],
    "cognate": "нэху",
    "type": "noun",
    "ipa": "nafə"
  },
  {
    "word": "тхылъыщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bookstore",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəɬəɕa"
  },
  {
    "word": "къефэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall down from",
        "examples": [
          {
            "sentence": "Зфэсакъ, чъыгым '''укъефэхыт'''",
            "translation": "Be careful, '''you will fall down''' from the tree."
          },
          {
            "sentence": "Ахэр перэшут япылъэу уашъом '''къефэхых'''",
            "translation": "They '''are falling''' from the sky with a parachute."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qajfaxən"
  },
  {
    "word": "къет1эрэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall down from",
        "examples": [
          {
            "sentence": "'''Зфэсакъ''', чъыгым укъет1эрэхыт",
            "translation": "'''Be careful''', you will fall down from the tree."
          },
          {
            "sentence": "К1алэм лъэгъуем '''къет1эрэхэгъ'''",
            "translation": "The boy '''fell down''' from the ladder."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qajtʼaraxən"
  },
  {
    "word": "къетк1ухын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip from",
        "examples": [
          {
            "sentence": "Псэр '''къетк1ухы'''",
            "translation": "The water '''is dripping'''."
          },
          {
            "sentence": "Псэр анэм '''къетк1ухы'''",
            "translation": "The water '''is dripping''' from the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qetkʷʼəxən"
  },
  {
    "word": "тэк1утэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тек1утэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "takʷʼətan"
  },
  {
    "word": "тек1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill liquid on",
        "examples": [
          {
            "sentence": "Псы '''къуптеск1эт'''",
            "translation": "'''I will spill''' water '''on you'''."
          },
          {
            "sentence": "К1алэм мащинэм псы '''тек1э'''",
            "translation": "The boy '''is spilling''' water on the car."
          },
          {
            "sentence": "К1алэхэмэ псы '''зтак1эжьы'''",
            "translation": "The boys '''are spilling''' water on each other."
          }
        ]
      }
    ],
    "synonyms": [
      "тек1ьэн"
    ],
    "type": "verb",
    "ipa": "tajt͡ʃʼan"
  },
  {
    "word": "тек1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тек1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тек1эн"
    ],
    "type": "verb",
    "ipa": "takʲʼan"
  },
  {
    "word": "хьадэпчэмы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "turtle",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэнчэмы1у"
    ],
    "cognate": "шылъэгу",
    "type": "noun",
    "ipa": "ħaːdapt͡ʃaməʔʷ"
  },
  {
    "word": "гухэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get hurt (emotionally)",
        "examples": [
          {
            "sentence": "'''Сыгу хэбгъэк1ыгъ'''",
            "translation": "'''You made me hurt'''."
          },
          {
            "sentence": "К1алэр '''ыгу хэк1ы'''",
            "translation": "The boy '''is getting hurt'''."
          },
          {
            "sentence": "К1алэр '''ыгу хэк1ыгъ''' сехъоным",
            "translation": "The boy '''got hurt''' when I sweared at him."
          },
          {
            "sentence": "К1алэм джэгуалъэр убэтагъэу ылъэгъумэ '''ыгу хэк1ыщт'''",
            "translation": "If the boy sees the broken toy '''he will get hurt'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəxat͡ʃʼən"
  },
  {
    "word": "гухэгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone upset, to make someone sad",
        "examples": [
          {
            "sentence": "К1алэр '''игу хэсэгъэк1ы'''",
            "translation": "'''I am upsetting''' the boy."
          },
          {
            "sentence": "К1алэм '''игу хэмыгъэк1'''",
            "translation": "'''Don't make''' the boy '''sad'''."
          },
          {
            "sentence": "'''Сигу хэбэгъэк1ы'''",
            "translation": "'''You are making me sad'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəxaʁat͡ʃʼən"
  },
  {
    "word": "1онтагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "curve, crooked (Set at an angle, not vertical or square)",
        "examples": [
          {
            "sentence": "Чъыгэр '''1онтагъ'''",
            "translation": "The tree '''is crooked'''."
          }
        ]
      },
      {
        "meaning": "curve, crooked (Not straight; having one or more bends or angles)",
        "examples": [
          {
            "sentence": "'''1онтагъэу''' отхэ",
            "translation": "You write '''crookedly'''."
          },
          {
            "sentence": "Уихъурай '''1онтагъ'''",
            "translation": "Your circle '''is crooked'''."
          }
        ]
      },
      {
        "meaning": "incorrect, wrong",
        "examples": [
          {
            "sentence": "'''1онтагъ''' къап1орэр",
            "translation": "What you're saying '''is wrong'''"
          },
          {
            "sentence": "Джэуап '''1онтагъ''' епдзыгъэр",
            "translation": "You signed the '''wrong''' answer."
          },
          {
            "sentence": "Джэуап '''1онтагъ''' л1ым уптымэ, угъэгуабжьыщт",
            "translation": "If you give the '''wrong''' answer to the man, he will get angry."
          }
        ]
      }
    ],
    "synonyms": [
      "1урт1агъ"
    ],
    "type": "adj",
    "ipa": "ʔʷantaːʁ"
  },
  {
    "word": "гъушъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dry (something that should not be wet such as clothes, roads, body parts); to dry up",
        "examples": [
          {
            "sentence": "Джанэр '''гъушъыгъ'''",
            "translation": "The shirt '''dried out'''."
          },
          {
            "sentence": "Джанэр '''мэгъушъы'''",
            "translation": "The shirt '''is drying out'''."
          },
          {
            "sentence": "'''Сыгъушъыгъ'''",
            "translation": "'''I am dehydrated'''."
          },
          {
            "sentence": "Гык1ыгъэхэр '''гъушъынэу''' ежьагъэх",
            "translation": "The laundries are starting '''to dry out'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁʷəʂən"
  },
  {
    "word": "елъэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэпэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɬapawan"
  },
  {
    "word": "лъэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stumble (over)",
        "examples": [
          {
            "sentence": "Джэгуалъэхэмэ зыгорэ '''ялъэпэощт'''",
            "translation": "Someone '''will stumble into''' the toys."
          },
          {
            "sentence": "Мы гъуч1ыр 1ух, к1элэцык1ухэр '''къылъэпэотщтых'''",
            "translation": "Take this iron away, the kids '''will stumble it'''."
          },
          {
            "sentence": "Сык1оу 1анэ цык1ум '''селъэпэуагъ'''",
            "translation": "While going '''I stumbled into''' the small table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬapawan"
  },
  {
    "word": "есэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сэпэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jasapawan"
  },
  {
    "word": "сэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dust (To remove dust; to clean by removing dust)",
        "examples": [
          {
            "sentence": "Бзылъфыгъым джанэр '''есэпао'''",
            "translation": "The woman '''dusts''' the shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬapawan"
  },
  {
    "word": "ц1эп1эжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very mean, very cruel, evil, villain, vile",
        "examples": [
          {
            "sentence": "Мы фылымк1э хэт '''ц1эп1эжъэр?'''",
            "translation": "In this film who is '''the bad guy'''?"
          },
          {
            "sentence": "Л1ыжъыр '''ц1эп1эжъ'''",
            "translation": "The old man '''is very mean'''."
          },
          {
            "sentence": "'''Ц1эп1эжъ''' укъэсдэмыгуща1",
            "translation": "'''You vile''' don't speak to me."
          }
        ]
      },
      {
        "meaning": "very naughty (Usually said to kids)",
        "examples": [
          {
            "sentence": "У к1элэцык1ухэр '''ц1эп1эжъых'''",
            "translation": "Those kids '''are really bad'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡sʼapʼaʐ"
  },
  {
    "word": "хэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pull someone from, to take someone out of, to drag someone out of",
        "examples": [
          {
            "sentence": "Уишы псым '''хэщ'''",
            "translation": "'''Pull''' your brother from the water."
          },
          {
            "sentence": "1эгуаор псынэм '''къыхащыжьэгъ'''",
            "translation": "They '''pulled''' the ball '''from''' the well."
          },
          {
            "sentence": "Мэзым '''сыхэщ'''",
            "translation": "'''Take me out of''' the forest."
          },
          {
            "sentence": "Хъулъфыгъэ мэк1одыгъэр зыгорэм мэзым '''херэщыжь'''",
            "translation": "Someone '''bring''' the man that was lose in the forest."
          },
          {
            "sentence": "Ц1ыфымэ к1алэр '''къахэщ'''",
            "translation": "'''Drag out''' the boy from the people."
          }
        ]
      },
      {
        "meaning": "to exempt someone from",
        "examples": []
      },
      {
        "meaning": "to stick out (to be noticeable, to be prominent)",
        "examples": [
          {
            "sentence": "Ц1ыфымэ к1алэ шъхьэц гъожьы зи1эр '''къахэщы'''",
            "translation": "The boy with the blond hair '''is noticeable''' among the people."
          },
          {
            "sentence": "1эгуау плъыжьэр 1эгуау фыжьыхэм '''къахэщы'''",
            "translation": "The red ball '''sticks out among''' the white balls."
          },
          {
            "sentence": "Уцыхэм '''уакъхэщы'''",
            "translation": "'''You stick out''' in the plants."
          }
        ]
      },
      {
        "meaning": "to start having a certain habit",
        "examples": [
          {
            "sentence": "А шэн 1ае '''хэпщыжьыгъа?'''",
            "translation": "'''Did you started having''' that bad behaving '''habit again'''?"
          },
          {
            "sentence": "Сэ тутын сешъоу '''хэсщыжьэгъ'''",
            "translation": "'''I started''' smoking '''again'''."
          },
          {
            "sentence": "Видио джэгухэр '''хэсщыгъэх'''",
            "translation": "'''I started playing''' video games."
          },
          {
            "sentence": "Псы псынк1эу уешъоу '''къыхэмыщ'''",
            "translation": "'''Don't start having the habit of''' drinking water fast."
          },
          {
            "sentence": "'''Къыхэщы''' уиджанэ т1эк1у зэрэш1оир",
            "translation": "'''It is noticeable''' that your shirt is a bit dirty."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaɕən"
  },
  {
    "word": "хэщыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэщын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaɕəʑən"
  },
  {
    "word": "хэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take someone to",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр шым тигъатысхьи мэзым '''хищагъ'''.",
            "translation": "The young man sit the young woman on the horse and '''took''' her '''into''' the forest."
          }
        ]
      },
      {
        "meaning": "to drag someone into",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaɕan"
  },
  {
    "word": "мэкъамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "melody, tune",
        "examples": [
          {
            "sentence": "Бзыумэ '''ямэкъамэ''' дахэ",
            "translation": "The birds' '''tune''' is beautiful."
          }
        ]
      },
      {
        "meaning": "intonation",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maqaːma"
  },
  {
    "word": "махъсымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a Circassian alcoholic drink (Circassian boza)",
        "examples": []
      }
    ],
    "synonyms": [
      "бахъсымэ"
    ],
    "type": "noun",
    "ipa": "maːχsəma"
  },
  {
    "word": "къэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to explode",
        "examples": [
          {
            "sentence": "Гринедэр '''къэот'''",
            "translation": "The grande '''will explode'''."
          },
          {
            "sentence": "Унэр '''къао'''",
            "translation": "The house '''is exploding'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qawan"
  },
  {
    "word": "зэхэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall apart (from inside)",
        "examples": [
          {
            "sentence": "К1алэм унэу иш1ыгъэ легок1э '''зэхэуагъ'''",
            "translation": "The house the boy did using Lego '''fall apart'''."
          },
          {
            "sentence": "Унэр '''зэхао'''",
            "translation": "The house '''is falling apart'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qawan"
  },
  {
    "word": "хэутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to install",
        "examples": [
          {
            "sentence": "Сомпыютэрым фаилхэр '''хэутых'''",
            "translation": "'''Install''' the files to the computer."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawtən"
  },
  {
    "word": "зыщэ1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be patient",
        "examples": [
          {
            "sentence": "К1алэм '''Зищэ1эшъурэп'''",
            "translation": "The boy '''can't be patient'''."
          },
          {
            "sentence": "'''Зыща1'''",
            "translation": "'''Be patient'''."
          },
          {
            "sentence": "Дискэр къэрэсыба!, '''Зысщы1эшъурэп'''",
            "translation": "May the disk arrive already! '''I can't wait anymore'''."
          }
        ]
      },
      {
        "meaning": "to bear",
        "examples": [
          {
            "sentence": "К1алэм '''Зищэ1эшъурэп''', мэгъутхьан фае",
            "translation": "The boy '''can't bear it anymore''', he needs to pee."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəɕaʔan"
  },
  {
    "word": "фэщэ1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bear",
        "examples": [
          {
            "sentence": "'''Къупфэща1а''' мы мыжъо онтэгъухэр ухьыхэу?",
            "translation": "'''Do you bear''' those heavy stones you are carrying."
          },
          {
            "sentence": "Мы фабэм хэсэу тэщтэу к1алэм '''фэща1э'''?",
            "translation": "While he is in this heat, how '''he bears it'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faɕaʔan"
  },
  {
    "word": "шъуамбгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wide, big width",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуабгъо",
      "бгъо"
    ],
    "type": "adj",
    "ipa": "ʂʷaːmbʁʷaː"
  },
  {
    "word": "шъуамп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peel (The skin or outer layer of a fruit, vegetable, etc.)",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуап1э"
    ],
    "type": "noun",
    "ipa": "ʃʷʼaːmpʼa"
  },
  {
    "word": "гулъытэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "understanding",
        "examples": [
          {
            "sentence": "К1алэм афэдиз '''гулъытэ''' и1эп",
            "translation": "The boy doesn't have that much of '''understanding'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəɬəta"
  },
  {
    "word": "зи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nothing",
        "examples": [
          {
            "sentence": "К1алэм '''зи''' ымы1оу мак1оу ригъэкъогъ",
            "translation": "The boy without saying '''nothing''' continued to go."
          }
        ]
      },
      {
        "meaning": "nobody",
        "examples": [
          {
            "sentence": "'''Зи''' еджап1эм къэк1уагъэп",
            "translation": "'''Nobody''' came to school."
          }
        ]
      }
    ],
    "synonyms": [
      "зыци"
    ],
    "type": "adv",
    "ipa": "zəj"
  },
  {
    "word": "гукъэгъэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гукъэгъэк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гукъэгъэк1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqaʁakʲʼən"
  },
  {
    "word": "гукъэгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone remember something, to remind someone something",
        "examples": [
          {
            "sentence": "Пшъашъэм '''сгукъыгъэк1ы''' сышыпхъу",
            "translation": "The girl '''remind me''' my sister."
          },
          {
            "sentence": "Ушы '''ыгукъэгъэк1''' еджап1эм зэрэк1он фае",
            "translation": "'''Remind''' your brother that he needs to go to school."
          },
          {
            "sentence": "'''Сыгукъымыгъэк1''' ар",
            "translation": "'''Don't remind''' that."
          },
          {
            "sentence": "'''Сыгукъэгъэк1ыжь''' хэт о?",
            "translation": "'''Remind me''' who you are?"
          }
        ]
      }
    ],
    "synonyms": [
      "гукъэгъэкьын"
    ],
    "type": "verb",
    "ipa": "ɡʷəqaʁat͡ʃʼən"
  },
  {
    "word": "щыгъэгъупщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone forget",
        "examples": [
          {
            "sentence": "'''Зыщыгъэгъупщ''' ар",
            "translation": "'''Make yourself forget''' that."
          },
          {
            "sentence": "Л1ым ихъэбэр '''сыщигъэгъупщэгъ''' зэрэсык1ожьын фае",
            "translation": "The man's news '''made me forget''' that i need to return."
          },
          {
            "sentence": "К1алэр бэрэ мэгуща1эу '''пщигъэгъупщэгъа''' пш1эн фэягъэ?",
            "translation": "'''Did''' the boy '''made you forget''' what you needed to do because he was talking a lot?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəʁaʁʷəpʃən"
  },
  {
    "word": "гъэшъхъэгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэшъыхъыгъ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁaʂχəʁa"
  },
  {
    "word": "гъэшъхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lower something",
        "examples": [
          {
            "sentence": "Лъэоир '''егъэшъхъ'''",
            "translation": "'''Lower''' the ladder."
          },
          {
            "sentence": "'''Зышъугъэшъхъ'''",
            "translation": "'''Lower yourselves'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къегъэ1ыхын",
      "къегъэт1ыхын",
      "ет1упщыхьахын",
      "егъэхын"
    ],
    "type": "verb",
    "ipa": "ʁaʂəχən"
  },
  {
    "word": "згъэшъхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to duck, to bend, to crouch",
        "examples": [
          {
            "sentence": "'''Зыгъэшъхъ!'''",
            "translation": "'''Duck!'''."
          },
          {
            "sentence": "А к1алэм енэу сеомэ '''Зегъэшъхъы'''",
            "translation": "When i hit/shoot that boy he always '''ducks'''."
          },
          {
            "sentence": "Тэк1у '''зыгъэшъхъ''' сылъэгъунэу",
            "translation": "'''Crouch''' a bit that i can see."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zʁaʂχən"
  },
  {
    "word": "гъэшъыпкъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to confirm",
        "examples": []
      },
      {
        "meaning": "to fulfill a promise, to fulfill objective,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaʂəpqaʑən"
  },
  {
    "word": "мэщтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щтэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maɕtan"
  },
  {
    "word": "гъэщтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone fear/afraid/scared, to intimidate someone, to scare someone",
        "examples": [
          {
            "sentence": "Сэ пшъашъэр '''сэгъащтэ'''",
            "translation": "'''I am scares''' the girl."
          },
          {
            "sentence": "К1алэм ышы '''егъащтэ'''",
            "translation": "The boy '''makes''' his brother '''scared'''."
          },
          {
            "sentence": "Дэгъоу '''тыгъэщытагъэх'''",
            "translation": "'''We scared them''' good."
          },
          {
            "sentence": "'''Сымыгъащт''' мощтэу",
            "translation": "'''Don't scare me''' like that."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэщынэн"
    ],
    "type": "verb",
    "ipa": "ʁaɕtan"
  },
  {
    "word": "гъэщынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone fear/afraid/scared, to intimidate someone, to scare someone",
        "examples": [
          {
            "sentence": "Сэ пшъашъэр '''сэгъэщынэ'''",
            "translation": "'''I am scares''' the girl."
          },
          {
            "sentence": "К1алэм ышы '''егъэщынэ'''",
            "translation": "The boy '''makes''' his brother '''scared'''."
          },
          {
            "sentence": "Дэгъоу '''тыгъэщынагъэх'''",
            "translation": "'''We scared them''' good."
          },
          {
            "sentence": "'''Сымыгъэщын''' мощтэу",
            "translation": "'''Don't scare me''' like that."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэщтэн"
    ],
    "type": "verb",
    "ipa": "ʁaɕənan"
  },
  {
    "word": "1эпызын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slowly drop something unintentionally (from one's hand)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapəzən"
  },
  {
    "word": "1эпыт1эрын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something unintentionally (from one's hand)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapətʼarən"
  },
  {
    "word": "къезын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къизын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qezən"
  },
  {
    "word": "къизын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something from",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qizən"
  },
  {
    "word": "изын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something from",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəzən"
  },
  {
    "word": "1эпыгъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone drop something (from one's hand)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapəʁazən"
  },
  {
    "word": "1эпыгъэт1эрын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone drop something (from one's hand)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapəʁatʼarən"
  },
  {
    "word": "пызын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall off from",
        "examples": [
          {
            "sentence": "Мыехэр чъыгым '''пызых'''",
            "translation": "The apples '''are falling off''' from the tree."
          },
          {
            "sentence": "Мыер чъыгым '''пызы'''",
            "translation": "The apple '''is falling off''' from the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəzən"
  },
  {
    "word": "пыт1эрын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall off from",
        "examples": [
          {
            "sentence": "Мыехэр чъыгым '''пыт1эрых'''",
            "translation": "The apples '''are falling off''' from the tree."
          },
          {
            "sentence": "Мыер чъыгым '''къыпыт1эры'''",
            "translation": "The apple '''is falling off''' from the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pətʼarən"
  },
  {
    "word": "мыхьэнэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэхьанэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məħana"
  },
  {
    "word": "акъыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smart, intelligent",
        "examples": [
          {
            "sentence": "К1алэр '''акъыл'''.",
            "translation": "The boy '''is smart'''."
          },
          {
            "sentence": "Сэ '''сэ1акъыл, ''' о ук1уач1.",
            "translation": "'''I am smart''', you are strong."
          }
        ]
      },
      {
        "meaning": "brain",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔaːqəl"
  },
  {
    "word": "хъужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become healthy again, to become cured, to recover",
        "examples": [
          {
            "sentence": "Тыгъуасэ '''сыхъужьэгъ'''",
            "translation": "'''I was cured''' yesterday."
          },
          {
            "sentence": "К1алэр '''мэхъужьы'''",
            "translation": "The boy '''is becoming healthy'''."
          },
          {
            "sentence": "Тхьэмафэк1э '''ухъужьыщт'''",
            "translation": "'''You will be cured''' in a week."
          },
          {
            "sentence": "'''Ухъужьырагъомэ''' псы бэу ешъу",
            "translation": "'''If you want to be cured''' drink a lot water."
          },
          {
            "sentence": "Сыд мыр '''сыхъужьырэп'''",
            "translation": "What is this '''I am not becoming healthy again'''."
          }
        ]
      }
    ],

    "cognate": "хъужын",
    "type": "verb",
    "ipa": "χʷəʑən"
  },
  {
    "word": "мэхъужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъужьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maχʷəʑən"
  },
  {
    "word": "гъэхъужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone healthy again, to cure someone, to heal someone",
        "examples": [
          {
            "sentence": "Достор, '''сыпгъэхъужьышъущта?'''",
            "translation": "Doctor, '''can you cure me?'''"
          },
          {
            "sentence": "Досторым тыгъуасэ '''сыгъэхъужьыгъ'''",
            "translation": "The doctor '''cured me''' yesterday."
          },
          {
            "sentence": "Досторым к1алэр '''егъэхъужьы'''",
            "translation": "The doctor '''is curing''' the boy."
          },
          {
            "sentence": "Мы уцым '''угъэхъужьыщт'''",
            "translation": "This plant '''will cure''' you."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaχʷəʑən"
  },
  {
    "word": "зыш1ош1ужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be arrogant; to be haughty, to think full of oneself",
        "examples": [
          {
            "sentence": "К1алэр '''зыш1ош1ужьы'''",
            "translation": "The boy '''thinks full of himself'''."
          },
          {
            "sentence": "'''Узыш1омыш1ужьы'''",
            "translation": "'''Don't be haughty'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəʃʷʼəʃʷʼaʑən"
  },
  {
    "word": "1эш1эх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "easy",
        "examples": [
          {
            "sentence": "Тесты '''1эш1эх'''",
            "translation": "'''Easy''' test."
          },
          {
            "sentence": "К1алэм '''1эш1эхэу''' мащинэр иш1ыжьэгъ",
            "translation": "The young man fixed the car '''easily'''."
          },
          {
            "sentence": "Мы ливилэр '''1эш1эх'''",
            "translation": "This level '''is easy'''."
          },
          {
            "sentence": "К1алэм тестыр къыныщт иш1ош1огъ, ау '''1эш1эхэу''' къыч1эк1ыжьэгъ",
            "translation": "The boy thought the test gonna be hard, but it was '''easy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къолай"
    ],
    "cognate": "тынш",
    "type": "adj",
    "ipa": "ʔaʃʼax"
  },
  {
    "word": "къины",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hard, difficult",
        "examples": [
          {
            "sentence": "Тесты '''къины'''",
            "translation": "'''Hard''' test."
          },
          {
            "sentence": "К1алэм '''къинык1э''' мащинэр иш1ыжьэгъ",
            "translation": "The young man fixed the car '''with great difficulty'''."
          },
          {
            "sentence": "Мы ливилэр '''къины'''",
            "translation": "This level '''is hard'''."
          },
          {
            "sentence": "К1алэм тестыр '''къиныщт''' иш1ош1огъ, ау 1эш1эхэу къыч1эк1ыжьэгъ",
            "translation": "The boy thought the test '''gonna be hard''', but it was easy."
          }
        ]
      }
    ],
    "synonyms": [
      "хьылъэ"
    ],
    "cognate": "гугъу",
    "type": "adj",
    "ipa": "qəjnə"
  },
  {
    "word": "гугъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hope",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəʁa"
  },
  {
    "word": "гугъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hope for",
        "examples": [
          {
            "sentence": "Ащ и1оф ш1эхэу зэш1ок1ынк1э гугъэн фае, ау ш1эхэу зэш1ок1ыщтэп",
            "translation": "'''He''' need '''to hope for''' his job to be solved quickly, but it won't be solved quickly."
          }
        ]
      },
      {
        "meaning": "to rely on, to count on",
        "examples": []
      },
      {
        "meaning": "to put afford in",
        "examples": [
          {
            "sentence": "К1алэр иеджэн '''егугъэ''' экзамыным блэк1ышъун пае",
            "translation": "The boy '''puts afford in''' his studies in order for him to pass the exam successfully."
          }
        ]
      },
      {
        "meaning": "to strip, to peel, to skin, to flay, to flense",
        "examples": [
          {
            "sentence": "К1алэм натрыфыр '''егугъэ'''",
            "translation": "The boy '''strips''' the corn."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʁan"
  },
  {
    "word": "гугъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гугъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʁən"
  },
  {
    "word": "гугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гугъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʁʷan"
  },
  {
    "word": "гуегъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel sorry for; to feel pity for; to pity",
        "examples": [
          {
            "sentence": "Тхьэмык1эхэр '''сигуегъух'''.",
            "translation": "'''I feel sorry for''' the poor people."
          },
          {
            "sentence": "К1алэм чэтыор '''ыгуегъужьи''' ып1ыжьэгъ.",
            "translation": "The boy '''felt sorry for''' the cat and adopted him."
          },
          {
            "sentence": "Мо к1элэ мыгъор '''угуегъунэу''' щытэп.",
            "translation": "'''You can't feel sorry for''' that cruel child."
          },
          {
            "sentence": "К1алэхэмэ '''амыгуегъоу''' чэтыу щырэр чъы1эм хагъэнагъ.",
            "translation": "The boys '''without feeling pity for''' the cub cat left the cat in the out cold."
          }
        ]
      }
    ],
    "synonyms": [
      "шъхьасын",
      "гук1эгъу фэлъэгъун"
    ],
    "type": "verb",
    "ipa": "ɡʷəjaʁʷən"
  },
  {
    "word": "гугъуемыл1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "careless",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəʁʷeməlʼ"
  },
  {
    "word": "1эпы1эгъоу фэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to help (usually small help); to aid; to assist",
        "examples": [
          {
            "sentence": "'''1эпы1эгъоу укъысфэхъун''' плъэк1ыщта?",
            "translation": "'''Can you help me'''?"
          },
          {
            "sentence": "К1алэм еджэнымк1э '''1эпы1эгъоу фэхъу'''",
            "translation": "'''Help''' the boy with the studies."
          },
          {
            "sentence": "'''1эпы1эгъоу''' сыгорэ '''къэсфэхъущта'''?",
            "translation": "'''Can''' someone '''help me'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "1эпы1эн",
      "дэ1эпы1эн"
    ],
    "type": "verb",
    "ipa": "ʔapəʔaʁʷaw faχʷən"
  },
  {
    "word": "махь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "here take this",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "maːħ"
  },
  {
    "word": "егъуин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be throwing stuff at someone",
        "examples": [
          {
            "sentence": "К1алэр чэтыуым '''егъуи'''",
            "translation": "The boy '''is throwing (some object)''' at the cat."
          },
          {
            "sentence": "1алэр мыжъок1э чэтыум '''егъуйи'''",
            "translation": "The boy '''is throwing rocks''' at the cat."
          },
          {
            "sentence": "К1элэцык1ор джэгуалъэхэмк1э '''къысэгъуйи'''",
            "translation": "The little boy '''threw''' toys '''at me'''."
          },
          {
            "sentence": "'''Егъуй''' мощ",
            "translation": "'''Throw at''' that."
          },
          {
            "sentence": "'''Уемыгъуй'''",
            "translation": "'''Don't throw at him/she/it'''."
          },
          {
            "sentence": "К1алэр мыек1э '''къысэгъуегъ'''",
            "translation": "The boy '''threw''' an apple on me."
          },
          {
            "sentence": "К1алэр '''къэгъуе'''",
            "translation": "The boy '''is throwing (toward us)'''."
          },
          {
            "sentence": "'''Шъукъэмыгъуй'''",
            "translation": "'''Don't throw stuff (toward us)'''."
          },
          {
            "sentence": "'''Шъукъытэмыгъуй'''",
            "translation": "'''Don't throw stuff on us'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁʷəjən"
  },
  {
    "word": "ч1эрэп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(slang) I don't know",
        "examples": [],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼarap"
  },
  {
    "word": "джэуап етын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэуап тын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒawaːp jatən"
  },
  {
    "word": "къаблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "approval",
        "examples": [
          {
            "sentence": "Мыр '''къабла'''?",
            "translation": "Is this '''approval'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaːbla"
  },
  {
    "word": "1ьалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Abzakh dialect) boy",
        "examples": [],
        "tags": [
          "Abzakh dialect"
        ]
      },
      {
        "meaning": "(Abzakh dialect) young man",
        "examples": [],
        "tags": [
          "Abzakh dialect"
        ]
      }
    ],
    "synonyms": [
      "к1алэ"
    ],
    "cognate": "щ1алэ",
    "type": "noun",
    "ipa": "ʔʲaːla"
  },
  {
    "word": "мэчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʂan"
  },
  {
    "word": "ма1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Abzakh dialect) alternative form of \"мак1э\"",
        "examples": [],
        "tags": [
          "Abzakh dialect",
          "alternative_spelling"
        ]
      }
    ],
    "synonyms": [
      "т1эк1у"
    ],
    "type": "adv",
    "ipa": "maːʔʲa"
  },
  {
    "word": "гъэц1ыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something shine",
        "examples": [
          {
            "sentence": "Мащинэр '''къэгъэц1ыу'''",
            "translation": "'''Make''' the car '''shine'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡sʼəwan"
  },
  {
    "word": "мэц1ыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ыун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼəwan"
  },
  {
    "word": "ц1ыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэц1ыон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼəwan"
  },
  {
    "word": "нэ1э зтэгъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be careful",
        "examples": [
          {
            "sentence": "'''Уинэ1э тэгъэт''' маш1ор къыч1эмнэнэу",
            "translation": "'''Be careful''' the fire won't ignite."
          },
          {
            "sentence": "К1алэм '''инэ1э тимыгъэтэу''' къебэджыгъэ",
            "translation": "The boy '''without being careful''' fell on the ground."
          },
          {
            "sentence": "Л1ым '''инэ1э тигъэтэу''' к1апсэм рек1о",
            "translation": "The man '''being careful''' is walking on the rope."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naʔa zətaʁatən"
  },
  {
    "word": "сыда",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "why",
        "examples": [
          {
            "sentence": "'''Сыда''' укъэк1уагъэ?",
            "translation": "'''Why''' you came?"
          },
          {
            "sentence": "'''Сыда''' плъыжьы шъор уик1ас?",
            "translation": "'''Why''' you like the color red?"
          }
        ]
      }
    ],
    "synonyms": [
      "хьа",
      "шъыда",
      "щтэ"
    ],
    "type": "adv",
    "ipa": "sədaː"
  },
  {
    "word": "зыцый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыци\"",
        "examples": []
      }
    ],
    "synonyms": [
      "зи1"
    ],
    "type": "adv",
    "ipa": "zət͡səj"
  },
  {
    "word": "зыци",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nothing",
        "examples": [
          {
            "sentence": "К1алэм '''зыцый''' ымы1оу мак1оу ригъэкъогъ",
            "translation": "The boy without saying '''nothing''' continued to go."
          }
        ]
      },
      {
        "meaning": "zero",
        "examples": []
      }
    ],
    "synonyms": [
      "зи"
    ],
    "type": "adv",
    "ipa": "zət͡səj"
  },
  {
    "word": "зи1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зи\"",
        "examples": []
      }
    ],
    "synonyms": [
      "зыци"
    ],
    "type": "adv",
    "ipa": "zəjʔ"
  },
  {
    "word": "унагъобзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "language family",
        "examples": []
      }
    ],

    "cognate": "унагъуэбзэ",
    "type": "noun",
    "ipa": "wənaːʁʷabza"
  },
  {
    "word": "мэш1о1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1о1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃʷʼaʔʷən"
  },
  {
    "word": "гъэш1о1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ferment",
        "examples": []
      },
      {
        "meaning": "to marinate",
        "examples": []
      },
      {
        "meaning": "to pickle",
        "examples": []
      },
      {
        "meaning": "to make something rot",
        "examples": [
          {
            "sentence": "Бэнанэр к1алэм '''егъэш1о1у'''",
            "translation": "The boy is making the banana '''rot'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʃʷʼaʔʷən"
  },
  {
    "word": "гъэш1ык1ьэен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэш1ык1эен\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэш1ык1эен"
    ],
    "type": "verb",
    "ipa": "ʁaʃʼəkʲʼajan"
  },
  {
    "word": "чъыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1ыгу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼəɡʷə"
  },
  {
    "word": "ч1ылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1ылъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼəɬ"
  },
  {
    "word": "ч1ылъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "land",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1ыгу"
    ],
    "cognate": "щ1ылъэ",
    "type": "noun",
    "ipa": "t͡ʃʼəɬa"
  },
  {
    "word": "ч1элъэтык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall on the ground",
        "examples": [
          {
            "sentence": "Шыр '''ч1элъетыкьи''' к1алэр гозэгъ",
            "translation": "The horse '''slip''' and the boy fell from it."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaɬatəkʲən"
  },
  {
    "word": "мэц1этхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get a scratch",
        "examples": [
          {
            "sentence": "Мащинэр '''мэц1этхъогъ'''",
            "translation": "The car '''got a scratch'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼatχʷən"
  },
  {
    "word": "ец1этхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1этхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jat͡sʼatχʷən"
  },
  {
    "word": "ц1этхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch",
        "examples": [
          {
            "sentence": "Мащинэр '''оц1этхъу'''",
            "translation": "'''You are scratching''' the car."
          },
          {
            "sentence": "Чэтыум '''уикъыц1этхъущт'''",
            "translation": "The cat '''will scratch you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼatχʷən"
  },
  {
    "word": "ц1этхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sneeze",
        "examples": [
          {
            "sentence": "К1алэр '''мэц1этхы'''",
            "translation": "The boy '''is sneezing'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къэпсын"
    ],
    "type": "verb",
    "ipa": "t͡sʼatxən"
  },
  {
    "word": "мэц1этхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1этхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼatxən"
  },
  {
    "word": "гъэц1этхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sneeze",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''егъэц1этхы'''",
            "translation": "The boy '''is making''' the girl '''sneeze'''."
          },
          {
            "sentence": "Чэтыум ыцыхэмэ '''сагъэц1этхы'''",
            "translation": "The cat's hairs '''are making me sneeze'''."
          },
          {
            "sentence": "Чэтыум '''сегъэц1этхы'''",
            "translation": "The cat '''is making me sneeze'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡sʼatxən"
  },
  {
    "word": "мэп1эстхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1эстхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pʼasχan"
  },
  {
    "word": "еп1эстхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch someone (With fingernails or foot-nails)",
        "examples": [
          {
            "sentence": "чэтыур '''къысэп1астхъы'''",
            "translation": "The cat '''is scratching me'''."
          },
          {
            "sentence": "чэтыур к1алэм '''еп1эстхъы'''",
            "translation": "The cat '''is scratching''' the boy."
          },
          {
            "sentence": "чэтыур '''къоп1эстхъыт'''",
            "translation": "The cat '''will scratch you'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "japʼastχən"
  },
  {
    "word": "гъэцохъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something slip/slide (on a surface that have a fraction like dirt, mud, wooden table and etc.)",
        "examples": [
          {
            "sentence": "Зы апч 1анэмк1э '''къэгъэцуахъу'''",
            "translation": "'''Slide to me''' a glass on the table."
          },
          {
            "sentence": "К1алэм мащинэр гъогум '''егъэцуахъуи''' тет",
            "translation": "The boy '''is drifting''' his car on the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡sʷaχʷan"
  },
  {
    "word": "хьа1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "no",
        "examples": []
      }
    ],
    "synonyms": [
      "хьау"
    ],
    "type": "adv",
    "ipa": "ħaːʔ"
  },
  {
    "word": "гъэгумэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to affect deeply",
        "examples": []
      },
      {
        "meaning": "to make someone worry",
        "examples": [
          {
            "sentence": "Сиян сыд '''узигъэгумэк1ырэр?'''",
            "translation": "My mother what '''worries you'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "гъэго1эн"
    ],
    "type": "verb",
    "ipa": "ʁaɡʷəmat͡ʃən"
  },
  {
    "word": "гумэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be anxious/uneasy/worried (about), to become anxious (about)",
        "examples": [
          {
            "sentence": "Сиян '''мэгумэк1ы'''",
            "translation": "My mother '''worries'''."
          },
          {
            "sentence": "Ащ и1офхэр дэгъушъ '''гумэк1ын''' фаеп",
            "translation": "His works are good, he doesn't have '''to worry'''."
          }
        ]
      }
    ],
    "synonyms": [
      "мэго1эн"
    ],
    "type": "verb",
    "ipa": "ɡʷəmat͡ʃən"
  },
  {
    "word": "гумэк1ыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anxiety",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəmat͡ʃəʁʷ"
  },
  {
    "word": "гъэго1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone worry",
        "examples": [
          {
            "sentence": "Сиян, сыд '''узигъэгуа1эрэр?'''",
            "translation": "My mother, what '''worries you'''?"
          },
          {
            "sentence": "Мощтэу '''сымгъэгуа1'''",
            "translation": "'''Don't worry me''' like that."
          },
          {
            "sentence": "Сыда '''cэбэгъэгуа1э'''?",
            "translation": "Why '''you worry me'''?"
          },
          {
            "sentence": "Сыда уиянэ '''огъэгуа1э?'''",
            "translation": "Why '''you make''' your mother '''worry'''?"
          },
          {
            "sentence": "А к1алэм ипсэуагъэ '''сигъэго1агъ'''",
            "translation": "That boy's health condition '''worried me'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэгумэк1ын"
    ],
    "type": "verb",
    "ipa": "ʁaɡʷaʔan"
  },
  {
    "word": "го1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэго1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷaʔan"
  },
  {
    "word": "мэго1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гумэк1ын"
    ],
    "type": "verb",
    "ipa": "maɡʷaʔan"
  },
  {
    "word": "псэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whole, intact",
        "examples": [
          {
            "sentence": "Бэнанэр '''псэу'''",
            "translation": "The banana is '''whole'''."
          },
          {
            "sentence": "Мыер '''псаоу''' ух",
            "translation": "Finish the '''whole''' apple."
          },
          {
            "sentence": "Мащинэри '''псаоу''' къэбгъэнэшъугъэп",
            "translation": "You couldn't even left the car '''intact''' too."
          },
          {
            "sentence": "К1алэм ишхын '''псаоу''' къигъэнагъ",
            "translation": "The boy left his dinner '''whole'''."
          }
        ]
      },
      {
        "meaning": "fine, in good health, alright, on good condition",
        "examples": [
          {
            "sentence": "1эпшъэшъ! к1алэр '''псауа'''?",
            "translation": "Oh my god! Is the boy '''alright/fine'''?"
          },
          {
            "sentence": "'''Сыпсау ''' сэ",
            "translation": "'''I am fine'''."
          },
          {
            "sentence": "'''Упсэумэ ''' джары",
            "translation": "'''If you are alright/fine''' that's it."
          },
          {
            "sentence": "Мые анэхь '''псаур''' къахь",
            "translation": "Bring '''the finest''' apple."
          }
        ]
      },
      {
        "meaning": "(rare) health condition",
        "examples": [],
        "tags": [
          "rare"
        ]
      }
    ],
    "type": "adj",
    "ipa": "psaw"
  },
  {
    "word": "псэуагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "health condition",
        "examples": [
          {
            "sentence": "Сиянэ '''ипсэуагъэ''' сыфэгуа1э",
            "translation": "I worry for my mother's '''health condition'''."
          },
          {
            "sentence": "Л1ыжъым '''ипсэуагъэ''' дэгъоу шыт",
            "translation": "The old-man's '''health condition''' is good."
          }
        ]
      },
      {
        "meaning": "wholeness",
        "examples": []
      }
    ],
    "synonyms": [
      "псэу"
    ],
    "type": "noun",
    "ipa": "psawaːʁa"
  },
  {
    "word": "фэго1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэгу1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "фэгумэк1ын"
    ],
    "type": "verb",
    "ipa": "faɡʷaʔan"
  },
  {
    "word": "псынк1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "speed, velocity",
        "examples": [
          {
            "sentence": "А к1алэм '''ипсынк1агъэ''' бэ",
            "translation": "That boy's '''speed''' is a lot."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psət͡ʃʼaːʁa"
  },
  {
    "word": "гъэхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэгъэхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaχan"
  },
  {
    "word": "егъэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to teach",
        "examples": [
          {
            "sentence": "Адыгабзэ '''себгъэджэшъущта'''?",
            "translation": "'''Can you teach me''' Circassian."
          },
          {
            "sentence": "К1алэм пшъашъэр '''регъаджэ'''",
            "translation": "The boy '''is teaching''' the girl."
          },
          {
            "sentence": "К1алэм пшъашъэр тхылъым '''регъаджэ'''",
            "translation": "The boy '''is letting''' the girl '''read''' the book."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁad͡ʒan"
  },
  {
    "word": "дын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sew",
        "examples": [
          {
            "sentence": "Бзылъфыгъэм джанэ '''еды'''",
            "translation": "t=The woman '''sews''' a shirt."
          }
        ]
      }
    ],

    "cognate": "дэн",
    "type": "verb",
    "ipa": "dən"
  },
  {
    "word": "щэджагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noon",
        "examples": [
          {
            "sentence": "'''щэджагъом''' тк1от йуропа",
            "translation": "'''In the noon''', we will go to Europe."
          },
          {
            "sentence": "'''щэджагъом''' томэр къэк1уагъ",
            "translation": "'''In the noon''' Tom came."
          }
        ]
      }
    ],

    "cognate": "шэджагъуэ",
    "type": "noun",
    "ipa": "ɕad͡ʒaːʁʷa"
  },
  {
    "word": "иуж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "after him/it, next",
        "examples": [
          {
            "sentence": "Фылымым '''иуж''' тыдэк1ыщт",
            "translation": "'''After''' the movie let us go out."
          },
          {
            "sentence": "К1алэм '''иужы''' ит пшъашъэр",
            "translation": "'''After''' the boy standing the girl."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jəwəʒ"
  },
  {
    "word": "лъэкъуац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family name, surname",
        "examples": []
      }
    ],
    "synonyms": [
      "унэгъуац1э",
      "л1экъуац1э"
    ],
    "cognate": "л1экъуэц1э",
    "type": "noun",
    "ipa": "ɬaqʷaːt͡sʼa"
  },
  {
    "word": "лъэкъуас1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "name of clan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaqʷaːsʼa"
  },
  {
    "word": "пчыхьапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beginning of the evening",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃəħaːpa"
  },
  {
    "word": "ч1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ground",
        "examples": []
      }
    ],

    "cognate": "щ1ы",
    "type": "noun",
    "ipa": "t͡ʂʼə"
  },
  {
    "word": "к1ьэмгуе",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эмгуе\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эмгуе"
    ],
    "type": "noun",
    "ipa": "kʲʼamɡʷəja"
  },
  {
    "word": "к1эмгуе",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Temirgoy (Circassian tribe)",
        "examples": []
      }
    ],
    "synonyms": [
      "к1ьэмгуе"
    ],
    "type": "noun",
    "ipa": "t͡ʃʼamɡʷəja"
  },
  {
    "word": "шык1эпшынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Shkapshina (Circassian instrument)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəkʲapʃəna"
  },
  {
    "word": "маш1о гъэк1осалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fire extinguisher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maːʃʷʼa ʁakʷʼasaːɬa"
  },
  {
    "word": "с1ынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wet",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "sʼəna"
  },
  {
    "word": "зэол1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fighter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zawaɬʼ"
  },
  {
    "word": "зэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wait (for now)",
        "examples": [
          {
            "sentence": "'''Зэгу''' къэспаплъ",
            "translation": "'''Wait''' for me."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "zaɡʷə"
  },
  {
    "word": "къуапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "swarthy, dark-skinned",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷaːpt͡sʼa"
  },
  {
    "word": "къуапс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къуапц1э\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷaːpsʼa"
  },
  {
    "word": "лъэкъуапс1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэкъуапц1\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaqʷaːpsʼ"
  },
  {
    "word": "хэдзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to choose, to elect",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xad͡zən"
  },
  {
    "word": "чэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spleen, milt (organ)",
        "examples": []
      },
      {
        "meaning": "crack, crash, snap (sharp sound made when solid material breaks)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃa"
  },
  {
    "word": "ос1эбыцу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mitten",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wasʔabət͡sʷ"
  },
  {
    "word": "лъэпкъгъэк1од",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "genocide",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬapqʁakʷʼad"
  },
  {
    "word": "гъэк1одын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone or something disappear",
        "examples": [
          {
            "sentence": "Удым бзыур '''къигъэк1одыгъ'''",
            "translation": "The witch '''made''' the bird '''disappear'''."
          },
          {
            "sentence": "Удым '''зыкъигъэк1одыжьыгъ'''",
            "translation": "The witch '''disappeared himself'''."
          }
        ]
      },
      {
        "meaning": "to lose something, to make something lost (not to be able to find)",
        "examples": [
          {
            "sentence": "Си1унк1ыбзэр '''умыгъэк1од'''",
            "translation": "'''Don't lose''' my key."
          },
          {
            "sentence": "Сипа1о '''сыгъэк1одыжьыгъ'''",
            "translation": "'''I lost''' my hat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁakʷʼadən"
  },
  {
    "word": "хьэшъуты убэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэшъутыубэтэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ħaʃʷətə wəbatan"
  },
  {
    "word": "фызын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to squeeze",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "fəzən"
  },
  {
    "word": "зэпыдагъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(slang) barely, badly, awfully",
        "examples": [
          {
            "sentence": "К1алэр адыгэбзэ '''зэпыдагъэу''' мэгуща1э",
            "translation": "The boy '''barely''' speaks the Adyghe language."
          },
          {
            "sentence": "'''Зэпыдагъэу''' угьэгу",
            "translation": "You play '''badly'''."
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "adv",
    "ipa": "zapədaːʁaw"
  },
  {
    "word": "зэпыдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sew something to something",
        "examples": [
          {
            "sentence": "Бзылъфыгъэм джанэхэр '''зэпедэх'''",
            "translation": "The woman '''sews''' shirts."
          },
          {
            "sentence": "Бзылъфыгъэм джанэр '''зэпедэ'''",
            "translation": "The woman '''sews''' the shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapədan"
  },
  {
    "word": "шъхьэталъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэтелъхьэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħataːɬħa"
  },
  {
    "word": "шъхьэатынгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэ1атынгъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaʔaːtənʁa"
  },
  {
    "word": "шэпсэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to live in",
        "examples": [
          {
            "sentence": "Сэ къэфкъазым '''сышэпсэу'''",
            "translation": "'''I am living in''' the Caucasus."
          },
          {
            "sentence": "'''Тышэпсэунэу''' ч1ыпэ тыгъотын фае",
            "translation": "We need to find a place '''to live in'''."
          },
          {
            "sentence": "Исра1эл адыгэу '''шэпсэухэмэ''' адыгэбзэ лъэшэу яш1э",
            "translation": "The Circassians that '''live in''' Israel know the Adyghe language well."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃapsawən"
  },
  {
    "word": "гыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "powder",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɣənə"
  },
  {
    "word": "Даут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "David (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "dawət"
  },
  {
    "word": "Хьэо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Eve (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħawa"
  },
  {
    "word": "Хьарун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Harun (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "ħaːrəwn"
  },
  {
    "word": "Нарт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "male given name",
        "examples": [],
        "tags": [
          "person",
          "given_name",
          "masculine"
        ]
      },
      {
        "meaning": "(mythology) Nart",
        "examples": [],
        "tags": [
          "mythology"
        ]
      }
    ],
    "type": "name",
    "ipa": "naːrt"
  },
  {
    "word": "Нат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug) alternative form of \"нат\"",
        "examples": [],
        "tags": [
          "Shapsug",
          "alternative_spelling"
        ]
      }
    ],
    "type": "name",
    "ipa": "naːt"
  },
  {
    "word": "Къызбэч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Qzbek (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "qəzbaːt͡ʃ"
  },
  {
    "word": "Зэчэрые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Zakaria (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "zakʲarəja"
  },
  {
    "word": "пчы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spear",
        "examples": []
      },
      {
        "meaning": "pike",
        "examples": []
      },
      {
        "meaning": "lance",
        "examples": []
      }
    ],

    "cognate": "бжы",
    "type": "noun",
    "ipa": "pt͡ʃə"
  },
  {
    "word": "чъы1ыхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чхы1ан\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂəʔəxan"
  },
  {
    "word": "къик1ьэс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay eggs",
        "examples": [
          {
            "sentence": "Кьэтым к1ьак1ьэхэр '''къек1ьэс1ы'''",
            "translation": "The chicken '''is laying''' eggs."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəjkʲʼasʼən"
  },
  {
    "word": "хъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rag (piece of cloth)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χadan"
  },
  {
    "word": "пае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for",
        "examples": [
          {
            "sentence": "Ащ пае ащ ымыш1эн щы1эп",
            "translation": "'''For the sake of''' him, there's nothing he won't do."
          }
        ]
      },
      {
        "meaning": "in order to",
        "examples": [
          {
            "sentence": "Баеу ухъун '''пае''' акъыл уи1эн фае",
            "translation": "'''In order to''' become rich you must be smart."
          },
          {
            "sentence": "Ущхэн '''пае''', улэжэн фае.",
            "translation": "'''In order to''' eat, you must work."
          },
          {
            "sentence": "Iофыр шIэхэу ыгъэцэкIэным '''пае''' тхъытхъын фэягъэ",
            "translation": "He had to hurry '''in order to''' do the work quickly."
          }
        ]
      }
    ],
    "synonyms": [
      "фэш1",
      "фэш1к1э"
    ],
    "cognate": "папщ1э",
    "type": "conj",
    "ipa": "paːja"
  },
  {
    "word": "гъусэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be with, to be associate with someone",
        "examples": [
          {
            "sentence": "К1алэмрэ пшъашъэмрэ '''зэгъусэх'''",
            "translation": "The boy and the girl '''are together'''."
          },
          {
            "sentence": "К1алэм '''сыригъусэу''' былым тылъэхъу",
            "translation": "'''(Being) with''' the boy we are searching for fortune."
          },
          {
            "sentence": "Саусырыкъо зыгори '''ригъусагъэп'''",
            "translation": "Nobody '''was with''' Sosruko."
          },
          {
            "sentence": "Л1ыхэр '''зэгъусэхэу''' мэзым хахьэх",
            "translation": "The men '''(being) together''' went into the forest."
          },
          {
            "sentence": "Томыр сэ '''сигъус'''",
            "translation": "Tom '''is with me (accompanying me)'''."
          },
          {
            "sentence": "Дзэк1ол1ыхэр '''згъусэхэу''' къэттехьагъэх",
            "translation": "The soldiers attacked us '''together'''."
          },
          {
            "sentence": "'''Къысигъусэр''' к1уач1а?",
            "translation": "Is '''the person with me''' strong?"
          },
          {
            "sentence": "К1алэр Томым '''ригъусэу''' иунэ к1уагъэх",
            "translation": "The boy went '''with Tom''' to his house."
          },
          {
            "sentence": "Томымрэ Джекобымрэ заом '''згъусэхэу''' к1уагъэх",
            "translation": "Tom and Jecob gone to the war '''together'''."
          },
          {
            "sentence": "'''Укъысигъусэу''' былым къуздэлъыхъута?",
            "translation": "Will you search '''accompanying me''' for the treasure?"
          }
        ]
      }
    ],
    "synonyms": [
      "к1ыгъун"
    ],
    "type": "verb",
    "ipa": "ʁʷəsan"
  },
  {
    "word": "гъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁə"
  },
  {
    "word": "нэрыплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "binocular",
        "examples": [
          {
            "sentence": "'''Нэрыплъымк1э''' удэ еплъ мэзым",
            "translation": "'''Using the binocular''' look at the forest there."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "narəpɬ"
  },
  {
    "word": "нэриплъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэрыплъ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "narəjpɬ"
  },
  {
    "word": "зыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "so much",
        "examples": [
          {
            "sentence": "К1алэр къэсынэс '''зыб''' ригъэхьыра",
            "translation": "It's taking '''so''' much time until the boy"
          },
          {
            "sentence": "'''Зыб''' унэм иуасэ",
            "translation": "The house '''so much'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "zəb"
  },
  {
    "word": "нахьиу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "even more",
        "examples": [
          {
            "sentence": "Мащинэр '''нахьиу''' угъэсэкъатыт",
            "translation": "You will ruin the car '''even more'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naːħjəw"
  },
  {
    "word": "лъы къик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bleed",
        "examples": [
          {
            "sentence": "Силъакъо '''лъы къек1ы'''",
            "translation": "My leg '''is bleeding'''."
          }
        ]
      }
    ],
    "synonyms": [
      "лъы къикьын"
    ],
    "type": "verb",
    "ipa": "ɬə qəjt͡ʃʼən"
  },
  {
    "word": "къик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come out from",
        "examples": [
          {
            "sentence": "К1алэр унэм '''къек1ы'''",
            "translation": "The boy '''is coming out of''' the house."
          },
          {
            "sentence": "Унэм '''къик1'''",
            "translation": "'''Come out from''' the house."
          },
          {
            "sentence": "Сишы унэм '''къик1ыгъэп'''",
            "translation": "My brother '''didn't came out from''' the house."
          }
        ]
      },
      {
        "meaning": "to come from",
        "examples": [
          {
            "sentence": "Сэ Америкэм '''сыкъек1ы'''",
            "translation": "'''I am coming from''' America."
          },
          {
            "sentence": "Сэ есып1эм '''сыкъек1ыжьы'''",
            "translation": "'''I am coming back from''' the pool."
          }
        ]
      },
      {
        "meaning": "to come off as",
        "examples": [
          {
            "sentence": "Къэсфэгъэгъу 1аеу '''сыкъык1ыгъэмэ''', аргъэп синетгъэр.",
            "translation": "I'm sorry if I came horrible; that wasn't my intention."
          }
        ]
      },
      {
        "meaning": "to turn out being; to end up being",
        "examples": [
          {
            "sentence": "ШIухьафтынэу сикъэшъэн къыситыгъэр 1элъынэу '''къик1ыгъ'''",
            "translation": "The gift my lover gave me '''turned out''' to be a ring."
          },
          {
            "sentence": "Пшъашъэм джанэу щилъагъэр дахэу '''къик1ыгъ'''",
            "translation": "The dress the girl wear '''ended up being''' beautiful."
          },
          {
            "sentence": "Шхыныр 1эш1оу '''къек1ы'''",
            "translation": "The food '''turns out to be''' tasty"
          }
        ]
      }
    ],
    "synonyms": [
      "къик1ьын"
    ],
    "type": "verb",
    "ipa": "qəjt͡ʃʼən"
  },
  {
    "word": "мэгъэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэгъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʁaʁan"
  },
  {
    "word": "гъэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bloom",
        "examples": [
          {
            "sentence": "'''Мэгъагъэ''' тихэкур",
            "translation": "Our land '''is blooming'''."
          },
          {
            "sentence": "Къэгъагъэ '''мэгъагъэ'''",
            "translation": "The flower '''is blooming'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maʁaʁan"
  },
  {
    "word": "хьэжъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "puppy",
        "examples": []
      }
    ],

    "cognate": "хьэв",
    "type": "noun",
    "ipa": "ħaʐʷ"
  },
  {
    "word": "хьажъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэжъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "haːʐʷ"
  },
  {
    "word": "т1ок1эжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1уак1э\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʷʼat͡ʃʼaʐ"
  },
  {
    "word": "шъхьэнгъупчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьангъупчъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħanʁʷəptʂa"
  },
  {
    "word": "шыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horseman",
        "examples": []
      }
    ],

    "cognate": "шу",
    "type": "noun",
    "ipa": "ʃəw"
  },
  {
    "word": "пчэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пчъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃa"
  },
  {
    "word": "к1ашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ceiling",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaːʃʷa"
  },
  {
    "word": "к1ьашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ашъо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʲʼaːʃʷa"
  },
  {
    "word": "еубын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"убын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawbən"
  },
  {
    "word": "убын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to speak ill of, to gossip",
        "examples": [
          {
            "sentence": "К1алэр '''оубы'''",
            "translation": "You '''are gossiping on''' the boy."
          },
          {
            "sentence": "К1алэр Томэм '''еубы'''",
            "translation": "The boy '''is gossiping on''' Tom."
          },
          {
            "sentence": "К1алэр о '''къоубэу''' сэ1угъэ",
            "translation": "I heart the boy '''is gossiping on you'''."
          },
          {
            "sentence": "Л1ыжъэр мафэр зэрэ мафэу '''мэубэ'''",
            "translation": "The old-man '''is gossiping''' the whole day."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəbə"
  },
  {
    "word": "къамыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whip; lash",
        "examples": []
      }
    ],
    "synonyms": [
      "к1энлъэ",
      "к1эпщы"
    ],
    "type": "noun",
    "ipa": "qaːməɕ"
  },
  {
    "word": "к1ыхьэлыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very long",
        "examples": []
      },
      {
        "meaning": "very tall",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃʼəħaɮəħa"
  },
  {
    "word": "гъончэдж ч1эгъыч1элъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "underwear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷant͡ʃad͡ʒ t͡ʂʼaʁət͡ʂʼaɬ"
  },
  {
    "word": "гъэчэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spin",
        "examples": [
          {
            "sentence": "'''гъэчэрэз'''",
            "translation": "'''Spin'''."
          }
        ]
      },
      {
        "meaning": "to roll",
        "examples": [
          {
            "sentence": "1эгуаор к1алэм '''егъэчэрэзы'''",
            "translation": "The boy is '''rolling''' the ball."
          }
        ]
      }
    ],

    "cognate": "гъэджэрэзын",
    "type": "verb",
    "ipa": "ʁat͡ʃarazən"
  },
  {
    "word": "гъэпск1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shower, to bath, to lave",
        "examples": [
          {
            "sentence": "К1алэм '''зегъэпсык1ы'''",
            "translation": "the boy '''is taking a shower''' (the boy is showering himself)"
          },
          {
            "sentence": "Пшъашъэм ик1алэхэ '''егъэпсыык1ых'''",
            "translation": "the girl '''is showering''' her boys (children)"
          }
        ]
      }
    ],
    "synonyms": [
      "гъэпсык1ьын",
      "зыгъэпск1ын",
      "гъэпк1ын"
    ],
    "type": "verb",
    "ipa": "ʁapsət͡ʃʼən"
  },
  {
    "word": "зыгъэпск1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take a shower, to take a bath",
        "examples": [
          {
            "sentence": "К1алэм '''зегъэпск1ы'''",
            "translation": "The boy '''is taking a shower'''."
          },
          {
            "sentence": "Псынк1эу '''згъэпск1'''",
            "translation": "'''Take a shower''' fast."
          },
          {
            "sentence": "'''Зыщыбгъэпск1ышъунэу''' щыта?",
            "translation": "'''Can one take a shower''' there?"
          }
        ]
      }
    ],
    "synonyms": [
      "зыгъэпсык1ьын"
    ],
    "type": "verb",
    "ipa": "zʁapst͡ʃʼən"
  },
  {
    "word": "гъэпс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпс1эн\"",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "гъэпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпц1эн\"",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "джыхэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэпк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxapt͡ʃʼan"
  },
  {
    "word": "пкьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "card",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pkʲan"
  },
  {
    "word": "мэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mapt͡ʃʼan"
  },
  {
    "word": "къоладжэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "valley",
        "examples": [
          {
            "sentence": "Уинэ1э зытэгъэтыжь, '''къоладжым''' уимыш1ахэу уифэщт",
            "translation": "Take care of yourself (Be careful), you will fall into '''the valley''' accidentally."
          }
        ]
      },
      {
        "meaning": "chasm, abyss",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷalaːd͡ʒa"
  },
  {
    "word": "кьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэ\"",
        "examples": []
      }
    ],

    "cognate": "к1э",
    "type": "noun",
    "ipa": "kʲa"
  },
  {
    "word": "ц1ык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "little",
        "examples": [
          {
            "sentence": "к1элэ '''ц1ык1у'''",
            "translation": "'''Little''' boy."
          },
          {
            "sentence": "к1алэр '''ц1ык1у'''",
            "translation": "The boy ''''is little'''."
          }
        ]
      }
    ],
    "synonyms": [
      "жъгъэй"
    ],
    "type": "adj",
    "ipa": "t͡sʼəkʷʼ"
  },
  {
    "word": "цыджанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweater",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡səd͡ʒaːna"
  },
  {
    "word": "шъхьэджашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baldhead",
        "examples": []
      }
    ],
    "synonyms": [
      "къуй",
      "шъхьэкъуй"
    ],
    "type": "adj",
    "ipa": "ʂħad͡ʒaːʃʷ"
  },
  {
    "word": "фэжъыуф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very big",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "fˤaʐəwfˤ"
  },
  {
    "word": "нарт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(mythology) Nart",
        "examples": [],
        "tags": [
          "mythology"
        ]
      }
    ],
    "type": "name",
    "ipa": "naːrt"
  },
  {
    "word": "нат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(mythology) Nart",
        "examples": [],
        "tags": [
          "mythology"
        ]
      }
    ],
    "type": "name",
    "ipa": "naːt"
  },
  {
    "word": "тхьэр еон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be cursed",
        "examples": [
          {
            "sentence": "Л1ым '''тхьэр еуагъ'''",
            "translation": "The man '''is cursed'''."
          },
          {
            "sentence": "'''Тхьэр къысэуагъ'''",
            "translation": "'''I am cursed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tħar jawan"
  },
  {
    "word": "лъэсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unmounted, dismounted, someone that is going on foot",
        "examples": [
          {
            "sentence": "Еджап1эм '''лъэсык1э''' тыгъак1у",
            "translation": "Let us go to school '''on foot'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬasə"
  },
  {
    "word": "чэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэмы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃam"
  },
  {
    "word": "чэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэщы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕ"
  },
  {
    "word": "уц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уцы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wət͡s"
  },
  {
    "word": "мэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэлы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mal"
  },
  {
    "word": "шъухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъогъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хъопсэн",
      "шхъогъон",
      "ешъугъун",
      "шъугъон",
      "ехьагъушъугъун"
    ],
    "type": "verb",
    "ipa": "ʃʷəχʷaʁʷan"
  },
  {
    "word": "мэшъухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъухъогъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷəχʷaʁʷan"
  },
  {
    "word": "ешъухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъухъогъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ешъугъун"
    ],
    "type": "verb",
    "ipa": "jaʃʷəχʷaʁʷan"
  },
  {
    "word": "ешъугъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be envy of",
        "examples": [
          {
            "sentence": "Л1ыр баий ц1ыфыхэр '''ешъугъух'''",
            "translation": "Because the man is rich, people '''are envy of him'''."
          },
          {
            "sentence": "К1алэм '''уемышъугъу'''",
            "translation": "'''Don't be envy of''' the boy."
          },
          {
            "sentence": "Зыми ар '''ешъугъурэп'''",
            "translation": "He does '''not envy''' anyone."
          }
        ]
      }
    ],
    "synonyms": [
      "хъопсэн",
      "шхъогъон",
      "шъугъон",
      "ехьагъушъугъун",
      "шъухъогъон"
    ],
    "type": "verb",
    "ipa": "jaʃʷʁʷən"
  },
  {
    "word": "пщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become inflated, to become bigger in a round shape (usually by air)",
        "examples": [
          {
            "sentence": "Бэлунэр '''мэпщы'''",
            "translation": "The balloon '''is inflating'''."
          },
          {
            "sentence": "Бэлунэр '''пщыгъэ'''",
            "translation": "The balloon '''inflated'''."
          }
        ]
      },
      {
        "meaning": "to swell (Usually a specific spot)",
        "examples": [
          {
            "sentence": "К1алэр пхъэк1э силъакъо къуеом, силъакъо '''пщэгъэ'''",
            "translation": "When the boy struck my leg with a wood, my leg '''swell'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pɕən"
  },
  {
    "word": "к1ьэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эй\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эй"
    ],
    "type": "noun",
    "ipa": "kʲʼaj"
  },
  {
    "word": "гъымакъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cry noise",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁəmaːqa"
  },
  {
    "word": "гущы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk",
        "examples": [
          {
            "sentence": "К1алэр '''мэгущы1э'''",
            "translation": "The boy '''is talking'''."
          },
          {
            "sentence": "Адыгэбзэк1э '''огущы1а?'''",
            "translation": "'''Do you speak''' Circassian?"
          },
          {
            "sentence": "Ары Адыгэбзэк1э '''сэгушы1э'''",
            "translation": "Indeed, i am speaking Circassian."
          },
          {
            "sentence": "К1алэм '''сыдэгущы1э'''",
            "translation": "'''I am talking with''' the boy."
          },
          {
            "sentence": "Телефонымк1э '''сыгуща1эу укъэсдэмыгущы1'''",
            "translation": "'''While I am talking''' in the phone, '''don't talk with me'''."
          },
          {
            "sentence": "К1алэр телефонымк1э '''мэгущы1э''' непенэу",
            "translation": "The boy '''is talking''' in the phone the whole day."
          },
          {
            "sentence": "'''Усдэгущы1эмэ''' синэ къыч1аплъ",
            "translation": "'''When I am talking to you''' look into my eye."
          },
          {
            "sentence": "К1алэр мэук1ытэу пшъашъэм '''дэгущы1эшъурэп'''",
            "translation": "The boy '''can't talk to''' the girl while being shy."
          },
          {
            "sentence": "К1алэр '''мэгущы1энэ''' ик1ас",
            "translation": "The boy loves '''to talk'''."
          }
        ]
      }
    ],

    "cognate": "жы1эн",
    "type": "verb",
    "ipa": "maɡʷɕəʔan"
  },
  {
    "word": "нэш1ош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэш1ош1\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʃʷʼaʃʷʼ"
  },
  {
    "word": "къэмлан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "box",
        "examples": [
          {
            "sentence": "К1алэм '''къэмланхэр''' зэ1уихэгъ",
            "translation": "The boy opened '''the boxes'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пхъуант",
      "сандыкъ"
    ],
    "type": "noun",
    "ipa": "qamlaːn"
  },
  {
    "word": "еоой",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alas",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "jawawaj"
  },
  {
    "word": "лъэужьэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whatever",
        "examples": [
          {
            "sentence": "К1алэм '''лъэужьэу''' зиш1ыгъэми зыфаер къы1ук1эхьагъэп",
            "translation": "'''Whatever''' the boy has done, he didn't got what he wanted."
          }
        ]
      }
    ],
    "synonyms": [
      "сыдэу"
    ],
    "type": "pron",
    "ipa": "ɬawʑəməj"
  },
  {
    "word": "сыдэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "so",
        "examples": [
          {
            "sentence": "'''Сыдэу''' дахэ мы дунаир",
            "translation": "This world '''is so''' beautiful."
          }
        ]
      },
      {
        "meaning": "whatever",
        "examples": [
          {
            "sentence": "К1алэм '''сыдэу''' зиш1ыгъэми зыфаер къы1ук1эхьагъэп",
            "translation": "'''Whatever''' the boy has done, he didn't got what he wanted."
          }
        ]
      }
    ],
    "synonyms": [
      "лъэужьэу",
      "шъыдэу"
    ],
    "type": "adv",
    "ipa": "sədaw"
  },
  {
    "word": "ипашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "in front of",
        "examples": [
          {
            "sentence": "К1алэм унэм '''ипашъхьэ''' иуцуагъ",
            "translation": "The boy stood '''in front of''' the house."
          }
        ]
      }
    ],
    "type": "postp",
    "ipa": "jəpaːʂħa"
  },
  {
    "word": "къутамэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "branch",
        "examples": [
          {
            "sentence": "Чъыг '''къутамэхэр'''",
            "translation": "Tree branches."
          },
          {
            "sentence": "Бзыур '''къутамэм''' тес",
            "translation": "The bird sits on the branch."
          },
          {
            "sentence": "Узытес '''къутамэр''' къеуупкIэхын",
            "translation": "To cut down the branch you are sitting on."
          }
        ]
      },
      {
        "meaning": "bough",
        "examples": []
      }
    ],
    "synonyms": [
      "куамэ",
      "къуамэ"
    ],
    "cognate": "гуанэ",
    "type": "noun",
    "ipa": "qʷətaːma"
  },
  {
    "word": "щызымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boot",
        "examples": []
      }
    ],
    "synonyms": [
      "щазымэ"
    ],
    "type": "noun",
    "ipa": "ɕəzəma"
  },
  {
    "word": "т1этэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disband",
        "examples": []
      },
      {
        "meaning": "to unleash",
        "examples": []
      },
      {
        "meaning": "to unbutton",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tʼatan"
  },
  {
    "word": "к1элэеджак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pupil",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaɮajad͡ʒaːkʷʼa"
  },
  {
    "word": "к1элэегьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1элэеджак1о\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼalajaɡʲaːkʷʼa"
  },
  {
    "word": "шъхьахын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be lazy, to be unwilling to do work or make an effort",
        "examples": [
          {
            "sentence": "К1алэр '''мэшъхьахы'''",
            "translation": "The boy '''is being lazy'''."
          },
          {
            "sentence": "'''Сэшъхьахы''' тучанэм сык1онэ",
            "translation": "'''I am being lazy''' to go to the shop."
          }
        ]
      }
    ],

    "cognate": "щхьэхын",
    "type": "verb",
    "ipa": "ʂħaːxən"
  },
  {
    "word": "чъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sleep",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂəja"
  },
  {
    "word": "гуш1уагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "joy, happiness",
        "examples": []
      },
      {
        "meaning": "(Shapsug dialect) funny",
        "examples": [],
        "tags": [
          "Shapsug dialect"
        ]
      }
    ],
    "cognate": "гуф1агъуэ",
    "type": "noun",
    "ipa": "ɡʷəʃʷʼaːʁʷa"
  },
  {
    "word": "гушхо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brave",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəʃxʷa"
  },
  {
    "word": "гуш1уапк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "present",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəʃʷʼapt͡ʃʼa"
  },
  {
    "word": "гущы1ап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "preface",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəʃʷʼap"
  },
  {
    "word": "гъаблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hunger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaːbɮa"
  },
  {
    "word": "гъажъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "millet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaːʒʷa"
  },
  {
    "word": "гъалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "paint",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁaːɮa"
  },
  {
    "word": "гъогогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fellow traveler",
        "examples": []
      },
      {
        "meaning": "time; instance",
        "examples": [
          {
            "sentence": "'''Гъогогъу''' заулэрэ кIуагъэ, ау ригъотагъэп",
            "translation": "He went many '''times''', but he didn't find it in it."
          },
          {
            "sentence": "'''ГъогогъуитIо''' лъигъэкIуагъ",
            "translation": "He send him '''two times'''"
          },
          {
            "sentence": "'''Гъогогъуищэ''' шъуиунэ сыкъэк1уагъ",
            "translation": "I came to your house '''three times'''"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaɡʷaʁʷ"
  },
  {
    "word": "гъогумаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "have a nice day",
        "examples": []
      },
      {
        "meaning": "have a nice trip",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "ʁʷaɡʷmaːf"
  },
  {
    "word": "гъогын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to roar, to growl",
        "examples": [
          {
            "sentence": "Мышъэр '''мэгъогы'''",
            "translation": "The bear '''roars'''."
          },
          {
            "sentence": "Аслъаныр '''гъогынэу''' зежьэк1э бэрэ '''мэгъогы'''",
            "translation": "When the lion start '''roaring''', it '''roars''' a lot."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaɣən"
  },
  {
    "word": "гъоз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "steam",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaz"
  },
  {
    "word": "гъомыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "food",
        "examples": []
      }
    ],

    "cognate": "гъуэмыл",
    "type": "noun",
    "ipa": "ʁʷaməɮ"
  },
  {
    "word": "гъуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sleeve",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaːpa"
  },
  {
    "word": "гъунд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gadfly",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷənd"
  },
  {
    "word": "гъук1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blacksmith",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷət͡ʃʼa"
  },
  {
    "word": "го1ул1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to nail down something (to attach with a nail) on the side",
        "examples": [
          {
            "sentence": "Пхъэм пхъэмбгъу '''го1ул1эн''' фае",
            "translation": "'''To nail down''' a board on a wood."
          }
        ]
      },
      {
        "meaning": "to sweep something on the side",
        "examples": [
          {
            "sentence": "П1этехъор бгъузэшъ зыгорэ '''го1ул1эн''' фае",
            "translation": "Someone need to '''sweep''' the blanked to make it narrow."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʔʷəɬʼan"
  },
  {
    "word": "джэдыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fur coat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒadəɡʷ"
  },
  {
    "word": "дзыо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bag; sack",
        "examples": []
      }
    ],
    "synonyms": [
      "къапщыкъ",
      "щэуалэ"
    ],
    "type": "noun",
    "ipa": "d͡zəwa"
  },
  {
    "word": "дзэпщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "warlord",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡zapɕə"
  },
  {
    "word": "кулъэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brake",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəɬaʃʷ"
  },
  {
    "word": "купк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "core",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷəpt͡ʃʼ"
  },
  {
    "word": "къаигъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stubborn",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qaːjəʁa"
  },
  {
    "word": "къакъыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barn",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːqər"
  },
  {
    "word": "къамыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "reed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːməɮ"
  },
  {
    "word": "къарыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strength; force",
        "examples": []
      }
    ],
    "synonyms": [
      "к1уач1э"
    ],
    "type": "noun",
    "ipa": "qaːrəw"
  },
  {
    "word": "къогъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "corner",
        "examples": [
          {
            "sentence": "К1алэр '''къогъом''' къохьагъэ",
            "translation": "The boy went '''to the corner'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qʷaʁʷa"
  },
  {
    "word": "къохьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go to the corner of",
        "examples": [
          {
            "sentence": "К1алэр къогъом '''къохьагъэ'''",
            "translation": "The boy '''went to the corner'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qʷaħan"
  },
  {
    "word": "къохьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "west",
        "examples": []
      }
    ],
    "synonyms": [
      "тыгъэкъохьап1"
    ],
    "type": "noun",
    "ipa": "qʷaħaːpʼ"
  },
  {
    "word": "къок1ып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "east",
        "examples": []
      }
    ],
    "synonyms": [
      "тыгъэкъок1ып1"
    ],
    "type": "noun",
    "ipa": "qʷat͡ʃʼəpʼa"
  },
  {
    "word": "къуалъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bribe",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːɬħa"
  },
  {
    "word": "къуладжэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "watercourse; riverbed",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəɮaːd͡ʒa"
  },
  {
    "word": "къутыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bowery",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷətər"
  },
  {
    "word": "к1ай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ash tree",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaːj"
  },
  {
    "word": "к1эш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to harness",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʃʼan"
  },
  {
    "word": "к1ожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to return (there)",
        "examples": [
          {
            "sentence": "К1алэр уиунэ '''мэк1ожьы'''",
            "translation": "The boy '''is returning''' to your house."
          },
          {
            "sentence": "К1алэр '''мэк1ожьы'''",
            "translation": "The boy '''is returning'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "kʷʼaʑən"
  },
  {
    "word": "къэк1ожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to return (here), to return back",
        "examples": [
          {
            "sentence": "К1алэр уиунэ '''къэк1ожьы'''",
            "translation": "The boy '''is returning back''' to your house."
          },
          {
            "sentence": "К1алэр '''къэк1ожьы'''",
            "translation": "The boy '''is returning back here'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qakʷʼaʑən"
  },
  {
    "word": "к1оч1адж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak, fragile",
        "examples": []
      }
    ],
    "synonyms": [
      "къарыунчъэ"
    ],
    "cognate": "махэ",
    "type": "adj",
    "ipa": "kʷʼaːt͡ʂʼaːd͡ʒ"
  },
  {
    "word": "к1уадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "impassable roads, flooded roads",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷʼaːd͡ʒ"
  },
  {
    "word": "к1уап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crowded place",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷʼaːpʼa"
  },
  {
    "word": "лъык1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move away a bit",
        "examples": [
          {
            "sentence": "'''Шъулъык1уат'''",
            "translation": "'''Move away''' (said to plural)"
          },
          {
            "sentence": "Т1эк1у '''лъык1отии''' укъынэсыщт",
            "translation": "'''Move away''' a bit and you will reach it"
          },
          {
            "sentence": "Т1эк1у '''лъык1оти''' сыблэгъэк1",
            "translation": "'''Move''' a bit and let me pass."
          },
          {
            "sentence": "'''Шъулъык1уат шъулъык1уат''' тиблэшъугъэк1",
            "translation": "'''Move away move away''' let us pass. (said to plural)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬəkʷʼatan"
  },
  {
    "word": "къылъык1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move near a bit",
        "examples": [
          {
            "sentence": "'''Шъукъылъык1уат'''",
            "translation": "'''Come near''' (said to plural)"
          },
          {
            "sentence": "Типыйхэр '''къылъык1уатэх'''",
            "translation": "Our enemies '''are moving near'''."
          },
          {
            "sentence": "Телевижыным '''къылъык1уати''' нахь дэгъэу улъэгъущт",
            "translation": "'''Move near''' the television and you will see better."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəɬəkʷʼatan"
  },
  {
    "word": "к1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to move",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼatan"
  },
  {
    "word": "лъагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trail",
        "examples": []
      },
      {
        "meaning": "path, way, road",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaːʁʷa"
  },
  {
    "word": "п1астэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "porridge",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʼaːsta"
  },
  {
    "word": "п1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pʼatən"
  },
  {
    "word": "п1этедзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coverlet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʼated͡za"
  },
  {
    "word": "п1этехъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed sheet",
        "examples": []
      },
      {
        "meaning": "blanket",
        "examples": []
      }
    ],

    "cognate": "п1этепхъуэ",
    "type": "noun",
    "ipa": "pʼateχʷa"
  },
  {
    "word": "п1оны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squama",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʷʼanə"
  },
  {
    "word": "п1упк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alimony",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʷʼənt͡ʃʼa"
  },
  {
    "word": "т1ыргъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ripen, to mature",
        "examples": [
          {
            "sentence": "Нашэр '''т1ыргъуагъэ'''",
            "translation": "The melon '''was ripen'''."
          }
        ]
      }
    ],
    "synonyms": [
      "т1ыгъон"
    ],
    "type": "verb",
    "ipa": "tʼərʁʷan"
  },
  {
    "word": "т1уазэх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1уазэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "т1олъфэныкъох"
    ],
    "type": "noun",
    "ipa": "tʷʼaːzax"
  },
  {
    "word": "т1уак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gorge, ravine, canyon",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʷʼaːt͡ʃʼa"
  },
  {
    "word": "т1урыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tʷʼərəs"
  },
  {
    "word": "т1урыт1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pair",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʷʼərətʷʼ"
  },
  {
    "word": "т1урыт1у къашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pair dance",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʷʼərətʷʼ qaːʃʷa"
  },
  {
    "word": "т1уанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rival (female)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʷʼaːna"
  },
  {
    "word": "ет1уанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ет1анэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1анэ"
    ],
    "type": "adv",
    "ipa": "jatʷʼaːna"
  },
  {
    "word": "хъишъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "legend",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħəjʂ"
  },
  {
    "word": "хъыбый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "χəbəj"
  },
  {
    "word": "хъызмэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "household",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χəzmat"
  },
  {
    "word": "хъырцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peach",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χərt͡sa"
  },
  {
    "word": "хъытыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seine",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χətəw"
  },
  {
    "word": "хъыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jay",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χəw"
  },
  {
    "word": "хъяр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "joy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χəjaːr"
  },
  {
    "word": "хъярк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "good bye",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "χəjaːrt͡ʃʼa"
  },
  {
    "word": "хъок1ыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shabby",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "χʷat͡ʃʼəʁ"
  },
  {
    "word": "хъоршэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sly",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷarʃar"
  },
  {
    "word": "хъот",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snowstorm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷat"
  },
  {
    "word": "хъоу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shallow gully",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷaw"
  },
  {
    "word": "хъохъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toast (a proposed salutation (e.g. to say \"cheers\"))",
        "examples": [
          {
            "sentence": "хъохъу къэ1он",
            "translation": "To make toast for."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəʃʷʼaːʁʷa"
  },
  {
    "word": "хъуат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ravine",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷaːt"
  },
  {
    "word": "хъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to happen",
        "examples": [
          {
            "sentence": "ары '''мэхъугъэ'''",
            "translation": "That's what '''happened'''."
          },
          {
            "sentence": "'''мэхъугъэр''' сыд уэш1а?",
            "translation": "'''Do''' you know what '''happened?'''"
          },
          {
            "sentence": "сыд '''мэхъугъэр'''?",
            "translation": "What '''happened'''?"
          },
          {
            "sentence": "ау ук1омэ дэйы '''рэхъутэр'''",
            "translation": "If you go there, '''the thing that will happen''' will be bad."
          },
          {
            "sentence": "ар '''махъутэп'''",
            "translation": "That '''will not happen.'''"
          }
        ]
      },
      {
        "meaning": "to become, to turn to",
        "examples": [
          {
            "sentence": "илъэсипл1ык1э л1ыу '''сыхъут'''",
            "translation": "In ten years '''I will become''' a man."
          },
          {
            "sentence": "дахэу '''ухъурагъуа?'''",
            "translation": "'''Do you want to become''' pretty."
          },
          {
            "sentence": "гъожьырэ шъухъуант1эрэ зэхэбхьагъэхэмэ уыушъоу '''мэхъут'''",
            "translation": "If you mix yellow and blue, '''I will become''' green."
          },
          {
            "sentence": "шхъуант1эрэ гъожьырэ '''зэхапк1эмэ''' уцышъоу мэхъут",
            "translation": "'''If you spill''' yellow and blue '''into each other''' it will turn to green."
          }
        ]
      },
      {
        "meaning": "to feel itchy",
        "examples": [
          {
            "sentence": "'''Сэхъу'''",
            "translation": "'''I am feeling itchy'''"
          },
          {
            "sentence": "Cныбэ '''мэхъу'''",
            "translation": "my stomach '''itch's'''"
          },
          {
            "sentence": "Уемыт1эхъу ащ '''хъуми'''",
            "translation": "Don't scratch that '''even if it's itchy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "хъупц1ын"
    ],
    "type": "verb",
    "ipa": "maχʷən"
  },
  {
    "word": "къэкъыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaqəjən"
  },
  {
    "word": "чыян",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sharp (Able to cut easily)",
        "examples": [
          {
            "sentence": "Чатэр '''чыян '''",
            "translation": "The sword '''is sharp'''."
          },
          {
            "sentence": "Шъэжъыер '''чыян '''",
            "translation": "The knife '''is sharp'''."
          },
          {
            "sentence": "Апч убытагъэ '''чыяни''' укъиу1эщт",
            "translation": "Because the broken window is '''sharp''', it will wound you."
          },
          {
            "sentence": "Уинэ1э зытэгъэт, апч убытагъэр '''чыянэ'''",
            "translation": "Be careful, the broken window '''is sharp'''."
          }
        ]
      },
      {
        "meaning": "dishing",
        "examples": []
      }
    ],
    "synonyms": [
      "чан"
    ],
    "type": "adj",
    "ipa": "t͡ʃəjaːan"
  },
  {
    "word": "къэбэкъао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "volcano",
        "examples": []
      }
    ],

    "cognate": "къэбэкъауэ",
    "type": "noun",
    "ipa": "qabaqaːwa"
  },
  {
    "word": "чылыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "church",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəɮəs"
  },
  {
    "word": "1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "table",
        "examples": []
      },
      {
        "meaning": "table (three-legged)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːna"
  },
  {
    "word": "1ае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly",
        "examples": [
          {
            "sentence": "Цыфы '''1ае'''",
            "translation": "'''Ugly''' man."
          }
        ]
      },
      {
        "meaning": "bad-tasting, bad taste, distasteful",
        "examples": [
          {
            "sentence": "Шхын '''1ае'''",
            "translation": "'''Bad tasting''' food."
          },
          {
            "sentence": "Сиянэ шхын '''1ае''' непэ къиш1эгъ",
            "translation": "My mother made a '''bad tasting''' food today."
          }
        ]
      },
      {
        "meaning": "indecent",
        "examples": [
          {
            "sentence": "К1алэр '''1аеу''' мэгущы1э.",
            "translation": "The boy is speaking '''rudely'''."
          },
          {
            "sentence": "Уижьэ гущ1э '''1аехэр''' къыдэмыкъэк1.",
            "translation": "Don't say '''rude''' words."
          },
          {
            "sentence": "К1алэм '''1аеу''' зеш1ы.",
            "translation": "The boy is acting '''rude'''."
          },
          {
            "sentence": "'''1аеу''' къысэплъы л1ыр.",
            "translation": "The man is looking at me '''indecently'''."
          }
        ]
      },
      {
        "meaning": "disgusting",
        "examples": [
          {
            "sentence": "Жьыр '''1аеу''' къэлъагъо",
            "translation": "The vomit looks '''disgusting'''."
          }
        ]
      },
      {
        "meaning": "nasty",
        "examples": []
      },
      {
        "meaning": "awful; horrible",
        "examples": [
          {
            "sentence": "'''1ае''' хъугъэр",
            "translation": "What happened is '''horrible'''."
          },
          {
            "sentence": "ц1ыфы  '''1аежъ'''",
            "translation": "'''Awful''' person."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔaːja"
  },
  {
    "word": "асэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1асэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔaːsa"
  },
  {
    "word": "унак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "new house",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wənaːt͡ʃʼa"
  },
  {
    "word": "къэлэмдалъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pencil case",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qalamdaːɬħa"
  },
  {
    "word": "къэлэм далъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэлэмдалъхьэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qalam daːɬħa"
  },
  {
    "word": "тхыпкъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "letter",
        "examples": []
      }
    ],
    "synonyms": [
      "хьарыф",
      "----"
    ],
    "type": "noun",
    "ipa": "txəpqa"
  },
  {
    "word": "хьарыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "letter",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːrəf"
  },
  {
    "word": "тхыпкъылъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alphabet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəpqəɬa"
  },
  {
    "word": "тхыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "writing",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəʁa"
  },
  {
    "word": "тхыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "literary",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəbza"
  },
  {
    "word": "тхыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "story",
        "examples": []
      },
      {
        "meaning": "narrative",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəda"
  },
  {
    "word": "пшысэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fairy tale",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʃəsa"
  },
  {
    "word": "щы1эц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noun",
        "examples": []
      }
    ],
    "synonyms": [
      "пкъыгъуац1э",
      "----"
    ],
    "type": "noun",
    "ipa": "ɕəʔat͡sʼa"
  },
  {
    "word": "щы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there is or are; there exist(s); to exist",
        "examples": [
          {
            "sentence": "'''щы1''' икъун бэнанэ",
            "translation": "There is enough bananas."
          },
          {
            "sentence": "бэнанэ '''щы1''', мые '''щы1эп'''",
            "translation": "'''There are''' bananas, '''there aren't''' apples."
          },
          {
            "sentence": "дракон '''щы1эп'''",
            "translation": "There is no dragon."
          },
          {
            "sentence": "драконхэ '''щы1эхэп'''",
            "translation": "There are no dragons."
          },
          {
            "sentence": "зэманым '''щы1агъ''' л1ыхъужъыхэр",
            "translation": "In the past '''there were''' heroes."
          }
        ]
      },
      {
        "meaning": "to be in a certain location; to be at",
        "examples": [
          {
            "sentence": "тучаным джы '''сыщы1'''",
            "translation": "'''I am at''' the shop."
          },
          {
            "sentence": "синыбджэгъу яунэ '''сыщы1'''",
            "translation": "'''I am at''' my friend's house."
          },
          {
            "sentence": "Америкэм '''сыщы1агъ'''",
            "translation": "'''I was in''' America."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕəʔan"
  },
  {
    "word": "пщыл1ыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slavery",
        "examples": []
      }
    ],

    "cognate": "пщыл1ыгъуэ",
    "type": "noun",
    "ipa": "pɕəɬʼəʁʷa"
  },
  {
    "word": "1эпшэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clumsy",
        "examples": []
      }
    ],
    "synonyms": [
      "1эджэбыжэ"
    ],
    "type": "adj",
    "ipa": "ʔapʃaχʷ"
  },
  {
    "word": "1эджэбыжэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clumsy",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпшэхъу"
    ],
    "type": "adj",
    "ipa": "ʔad͡ʒabəʒa"
  },
  {
    "word": "1эпэ1ас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skillful",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔapaʔaːs"
  },
  {
    "word": "пл1энэбзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pyramid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬʼanabzə"
  },
  {
    "word": "пл1эмыепкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cube",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬʼaməjapq"
  },
  {
    "word": "лъэрымыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pregnant",
        "examples": []
      }
    ],
    "synonyms": [
      "гъумы",
      "лъэшыдж"
    ],
    "type": "adj",
    "ipa": "ɬarəməħ"
  },
  {
    "word": "лъэрмыхьы хъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become pregnant",
        "examples": []
      }
    ],
    "synonyms": [
      "гъумэу хъун"
    ],
    "type": "verb",
    "ipa": "ɬarməħə χʷən"
  },
  {
    "word": "пл1эмый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "square",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɬʼaməj"
  },
  {
    "word": "зэпэзэдакъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rectangle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zapazadaːq"
  },
  {
    "word": "щэный",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "triangle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕanəj"
  },
  {
    "word": "тэгъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тегъогъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "taʁʷaʁʷan"
  },
  {
    "word": "къокъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to croak, to ribbit",
        "examples": [
          {
            "sentence": "Хант1аркъор '''мэкъуакъо'''",
            "translation": "The frog '''is croaking'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qʷaqʷan"
  },
  {
    "word": "чэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheerful; joyful",
        "examples": [
          {
            "sentence": "К1алэр '''чэфы'''",
            "translation": "The boy '''is cheerful'''."
          },
          {
            "sentence": "'''Учэфы''' непэ",
            "translation": "'''You are cheerful''' today."
          }
        ]
      },
      {
        "meaning": "pleased",
        "examples": [
          {
            "sentence": "1офэу сш1эгъэмк1э '''учэфа'''?",
            "translation": "'''Are you pleased''' with the word I done?"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃafə"
  },
  {
    "word": "кьэфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэфы\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "kʲafə"
  },
  {
    "word": "гъогу маф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "have a nice day",
        "examples": []
      }
    ],

    "cognate": "гъуэгу махуэ",
    "type": "intj",
    "ipa": "ʁʷaɡʷ maːf"
  },
  {
    "word": "чэупчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gate",
        "examples": [
          {
            "sentence": "К1алэр '''чэупчъэм''' блэк1ышъурэп.",
            "translation": "The boy is not capable of passing '''the gate'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къэлапчъ"
    ],
    "type": "noun",
    "ipa": "t͡ʃawəpt͡ʂa"
  },
  {
    "word": "кьэупчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэупчъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэлапчъ"
    ],
    "type": "noun",
    "ipa": "kʲawəpt͡ʂa"
  },
  {
    "word": "к1оц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "inside",
        "examples": [
          {
            "sentence": "Унэм '''ик1оц1ы''' ихь",
            "translation": "Go '''inside''' the house."
          },
          {
            "sentence": "Унэр '''ик1оц1ык1э''' ялагъ",
            "translation": "They painted the horse '''from inside'''."
          },
          {
            "sentence": "Гъуанэм '''ик1оц1ы''' идз мыжъохэр",
            "translation": "Throw the rocks '''inside''' the hole."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "kʷʼat͡sʼə"
  },
  {
    "word": "пэтырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "while",
        "examples": [
          {
            "sentence": "К1алэ мэк1о '''пэтырэ''' илъэгъогъ иныбджэгъухэр",
            "translation": "'''While''' the boy was going, he saw his friends."
          },
          {
            "sentence": "Л1ым хьантхъупсы ешъо '''пэтырэу''' хилъэгъуагъ бадзэ",
            "translation": "'''While''' the man was drinking soup, he saw a fly in it."
          },
          {
            "sentence": "Чэщым учъэ '''пэтырэу''' чъы1э къупхэхьэни усмэджэщт",
            "translation": "'''While''' you are running at night, you will get a cold and will become sick."
          },
          {
            "sentence": "Унэм сзэкъик1ы '''пэтырэу''' къещхэу къиблагъ",
            "translation": "'''While''' I was getting out the house, its started raining."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "patəra"
  },
  {
    "word": "ц1эры1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "famous",
        "examples": [
          {
            "sentence": "Сиятэ пщынао '''ц1эры1у'''",
            "translation": "My father is '''a famous''' accordionist."
          }
        ]
      }
    ],
    "synonyms": [
      "с1эры1у"
    ],
    "type": "adj",
    "ipa": "t͡sʼarəʔʷ"
  },
  {
    "word": "гъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъуин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁʷajən"
  },
  {
    "word": "къегъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather",
        "examples": [
          {
            "sentence": "К1алэм мы1эрысхэр '''къегъоих'''",
            "translation": "The boy '''gathers''' apples."
          },
          {
            "sentence": "Пщынэ макъэм ц1ыфыхэр '''къегъоих'''",
            "translation": "The sound of the accordion '''gathers''' people."
          },
          {
            "sentence": "'''Шъукъызэгъоий''' сырэу укъэуцу",
            "translation": "'''Gather (said to plural)''' and stand in line."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qajʁʷajən"
  },
  {
    "word": "къэгъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъуин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaʁʷajən"
  },
  {
    "word": "игъоин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather",
        "examples": [
          {
            "sentence": "К1алэм мы1эрысхэр '''игъоих'''",
            "translation": "The boy '''gathers''' apples."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʁʷajən"
  },
  {
    "word": "нэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fast",
        "examples": [
          {
            "sentence": "К1алэм '''енэк1ы'''",
            "translation": "The boy '''is fasting'''."
          },
          {
            "sentence": "'''Унэк1ынэ''' къина?",
            "translation": "Is it hard '''to fast'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "нэ1ьын"
    ],
    "type": "verb",
    "ipa": "nat͡ʃʼən"
  },
  {
    "word": "пхэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пхэндж\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пхэндж"
    ],
    "cognate": "пхэнж",
    "type": "adj",
    "ipa": "pxad͡ʒ"
  },
  {
    "word": "къэрал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "country",
        "examples": []
      },
      {
        "meaning": "state",
        "examples": []
      }
    ],
    "synonyms": [
      "хэгъэгу",
      "----"
    ],
    "type": "noun",
    "ipa": "qaraːɮ"
  },
  {
    "word": "гурыгъэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone understand",
        "examples": [
          {
            "sentence": "Тэщтэу математика '''къыбгурысгъэ1он'''?",
            "translation": "How '''can I make you understand''' math?"
          },
          {
            "sentence": "К1элэегъаджэм дэгъоу '''сыгурыгъэ1уагъ''' тызеджэрэр",
            "translation": "The teacher '''made me understand''' well what we are studying."
          },
          {
            "sentence": "'''Къызгуригъэ1ошъугъ''' сыд хъугъэр",
            "translation": "'''He successfully made me understand''' what going on."
          }
        ]
      }
    ],
    "synonyms": [
      "гурыгъэ1уэн"
    ],
    "type": "verb",
    "ipa": "ɡʷərəʔʷan"
  },
  {
    "word": "унагъоц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family name, last name",
        "examples": []
      }
    ],

    "cognate": "унагъуэц1э",
    "type": "noun",
    "ipa": "wnaːʁʷat͡sʼa"
  },
  {
    "word": "коил",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "village dweller, villager",
        "examples": [
          {
            "sentence": "'''Тикоилмэ''' яш1э адыгэбзэ",
            "translation": "'''Our villagers''' know Adyghe language."
          },
          {
            "sentence": "'''Тикоилхэ''' адыгэх",
            "translation": "'''Our villagers''' are Circassians."
          }
        ]
      }
    ],
    "synonyms": [
      "чылэдэс"
    ],
    "cognate": "жылэдэс",
    "type": "noun",
    "ipa": "kʷajəl"
  },
  {
    "word": "нэдэлъфыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mother tongue, first language",
        "examples": [
          {
            "sentence": "Си '''нэдэлъфыбзэ''' адыгэбзэ",
            "translation": "My '''mother tongue''' is Circassian."
          }
        ]
      }
    ],

    "cognate": "анэдэлъхубзэ",
    "type": "noun",
    "ipa": "nadaɬfəbza"
  },
  {
    "word": "ригъэчъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэчъэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁat͡ʂaxən"
  },
  {
    "word": "щыгуш1ук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rejoice for someone or something",
        "examples": [
          {
            "sentence": "К1алэр и шы и нысащэ '''щыгуш1ук1ы'''",
            "translation": "The boy '''is rejoicing for''' his brother's wedding."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) to laugh",
        "examples": [
          {
            "sentence": "К1алэр сэ '''къэсщыгуш1ук1ы'''",
            "translation": "The boy '''is laughing''' at me."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "synonyms": [
      "щыгуш1ук1ьын"
    ],
    "cognate": "щыгуф1ык1ын",
    "type": "verb",
    "ipa": "ʃəɡʷəʃʷʼt͡ʃʼən"
  },
  {
    "word": "сэмэркъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "joke",
        "examples": [
          {
            "sentence": "К1алэм '''сэмэркъэоу''' мэутэшъуагъэм фэдэу зиш1эгъ",
            "translation": "The boy made himself look like a drink '''jokingly'''."
          },
          {
            "sentence": "'''Сэмэркъэу''' уш1эрэба",
            "translation": "Don't you know a '''joke'''."
          },
          {
            "sentence": "'''Сэмэркъэук1э''' усэзэуагъэнахь шъыпкъэоп",
            "translation": "I fought you '''jokingly''' not for real."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "samarqaw"
  },
  {
    "word": "фыц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to compress, to press forcefully something against a solid thing",
        "examples": [
          {
            "sentence": "Мыщ пхъэхэр '''ефыц1ых'''",
            "translation": "This thing '''compress''' woods."
          },
          {
            "sentence": "К1алэм бадзэр '''ифыц1эгъ'''",
            "translation": "The boy '''compressed''' the fly."
          },
          {
            "sentence": "Мащинэ къуптапк1ьэмэ '''уифыц1ыт'''",
            "translation": "If the car will run over you, it '''will compress''' you."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fət͡sʼən"
  },
  {
    "word": "дэфыц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to compress inside something, to press forcefully something against a solid thing",
        "examples": [
          {
            "sentence": "'''Сыдэбэфыц1э'''",
            "translation": "'''You are compressing me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dafət͡sʼan"
  },
  {
    "word": "мэц1ыц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ыц1ын\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mat͡sʼət͡sʼən"
  },
  {
    "word": "ц1ыц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crumple",
        "examples": [
          {
            "sentence": "К1алэм тхылъып1эр '''ец1ыц1ы'''",
            "translation": "The boy is '''crumpling''' the paper."
          }
        ]
      },
      {
        "meaning": "to compress something, to press forcefully something against a solid thing",
        "examples": [
          {
            "sentence": "К1алэм и1эр пчъэм '''дэц1ыц1агъ'''",
            "translation": "The boy's arm '''was compressed''' by the door."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼət͡sʼən"
  },
  {
    "word": "нэ1уасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a familiar person, a person one personally knows, acquaintance",
        "examples": [
          {
            "sentence": "У к1алэу зыфап1орэр '''тинэ1уас'''",
            "translation": "That boy you are referring to '''is our acquaintance'''."
          },
          {
            "sentence": "А к1алэр мафэ къэсы сиунэ къак1о, '''синэ1уас''' ар хъужьэгъ",
            "translation": "That boy is coming to my house everyday, he became '''my acquaintance'''."
          },
          {
            "sentence": "зы '''нэ1уасэ''' горэ шъуи1а мащинэхэ иш1ыжьыхэу?",
            "translation": "Do you (plural) have one '''acquaintance''' that fixes cars?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naʔʷaːsa"
  },
  {
    "word": "зфэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to refer to",
        "examples": [
          {
            "sentence": "Тары '''зфап1орэр'''?",
            "translation": "What are '''referring to'''?"
          },
          {
            "sentence": "К1алэм '''зфи1уагъэ''' цуакъэр къэсхьэгъ",
            "translation": "I brought the shoe the boy '''referred to'''."
          },
          {
            "sentence": "'''Зфас1орэр''' а к1алэрэп",
            "translation": "'''What I am referring to''' is not that boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zfaʔʷan"
  },
  {
    "word": "ш1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to do again",
        "examples": [
          {
            "sentence": "Унэ угъэуагъэр '''ш1ыжь'''",
            "translation": "'''Do again''' the house you destroyed"
          },
          {
            "sentence": "К1алэм мафэ къэсы азэ '''еш1ыжьы'''",
            "translation": "The boy '''is doing''' the same thing everyday"
          }
        ]
      },
      {
        "meaning": "to fix",
        "examples": [
          {
            "sentence": "Томым компыютэрхэр '''еш1ыжьы'''",
            "translation": "Tom '''is fixing''' computers."
          },
          {
            "sentence": "Уимащинэ '''къагъэш1ыжь'''",
            "translation": "'''Make them fix''' your car."
          },
          {
            "sentence": "Мащинэхэ '''ш1ыжьык1э''' сыш1эрэп",
            "translation": "I don't know '''how to fix''' cars."
          },
          {
            "sentence": "Яунэ '''къэсфэш1ыжьышъугъэп''' сыш1эрэп",
            "translation": "'''I couldn't fix''' their house."
          },
          {
            "sentence": "зы нэ1уасэ горэ шъуи1а мащинэхэ '''иш1ыжьыхэу'''?",
            "translation": "Do you (plural) have one familiar '''that fixes''' cars?"
          }
        ]
      }
    ],
    "synonyms": [
      "гъэтэрэзын"
    ],
    "type": "verb",
    "ipa": "ʃˈəʑən"
  },
  {
    "word": "шъуашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "national costume",
        "examples": [
          {
            "sentence": "Адыгэ '''шъуашэхэр'''",
            "translation": "Circassian '''national costumes'''"
          }
        ]
      }
    ],

    "cognate": "фащэ",
    "type": "noun",
    "ipa": "ʃʷaːʃa"
  },
  {
    "word": "Бытырбыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "city c/Russia",
        "examples": [
          {
            "sentence": "'''Бытырбыф''' сщыщ.",
            "translation": "I am from '''Saint Petersburg'''."
          }
        ],
        "tags": [
          "place",
          "city"
        ]
      }
    ],

    "cognate": "Бытырбыху",
    "type": "name",
    "ipa": "batərbəf"
  },
  {
    "word": "сэмэркъэуш1ыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "comedian",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "samarqawʃʼəɮ"
  },
  {
    "word": "мэжъутхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жъутхэн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maʒʷətxan"
  },
  {
    "word": "жъутхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ужъунтхэн\"",
        "examples": []
      }
    ],

    "cognate": "убжьытхэн",
    "type": "noun",
    "ipa": "maʒʷətxan"
  },
  {
    "word": "тэжъутхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"теужъутхэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "taʒʷətxan"
  },
  {
    "word": "ш1ош1ухъунгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ош1ухъуныгъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼaʃʷʼχʷənʁa"
  },
  {
    "word": "ш1ошъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ошъ хъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ош1ун"
    ],
    "type": "verb",
    "ipa": "ʃʷaʂən"
  },
  {
    "word": "ш1ошъхъуныгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "faith",
        "examples": [
          {
            "sentence": "'''Ш1ошъхъуныгъэ''' уи1эн фае",
            "translation": "You need to have a '''faith'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ош1ухъуныгъэ"
    ],
    "type": "noun",
    "ipa": "ʃʷʼaʃʷʼχʷənʁa"
  },
  {
    "word": "ш1ошъ хъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to believe",
        "examples": [
          {
            "sentence": "К1алэм и1орэ пшъашъэм '''иш1ошъ мэхъу'''",
            "translation": "The girl '''is believing''' in what the boy tells."
          },
          {
            "sentence": "Къэп1орэ '''сиш1ошъ мэхъу'''",
            "translation": "'''I am believing''' in what you say."
          },
          {
            "sentence": "Къэп1орэ к1алэм '''иш1ошъ мэхъурэп'''",
            "translation": "The boy '''doesn't believe''' in what I say."
          },
          {
            "sentence": "Сэ Тхьэ '''сиш1ошъ мэхъу'''",
            "translation": "'''I believe''' in God."
          },
          {
            "sentence": "А к1алэм еп1ок1упэрэ '''иш1ошъ мэхъу'''",
            "translation": "That boy '''believes''' everything you told him."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ош1у мэхъун",
      "ш1ошъыхъун"
    ],
    "type": "verb",
    "ipa": "ʃʷaʂ χʷən"
  },
  {
    "word": "пак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "since the time",
        "examples": [
          {
            "sentence": "Еджап1эм сщеджэ '''пак1о''', тхылъмэ саджэжьгъэп.",
            "translation": "Since the time I studied at school, I didn't read the books."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "paːkʷʼa"
  },
  {
    "word": "прилъэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pay a deep attention to something without leaving it",
        "examples": [
          {
            "sentence": "Фылымым '''сиприлъэшъуагъ'''",
            "translation": ""
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "prəjɬaʃʷan"
  },
  {
    "word": "кьэпчъэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэупчъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэлапчъ"
    ],
    "type": "noun",
    "ipa": "kʲapt͡ʂaʔʷ"
  },
  {
    "word": "ш1ык1ай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mischievous, naughty, prankish",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃʼət͡ʃʼaːj"
  },
  {
    "word": "гъэупыс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэуп1ыц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэупыц1эн"
    ],
    "type": "noun",
    "ipa": "ʁawpsʼan"
  },
  {
    "word": "гъэупыц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэуп1ыц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэупыц1эн"
    ],
    "type": "noun",
    "ipa": "ʁawpsʼan"
  },
  {
    "word": "щкъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to know",
        "examples": [
          {
            "sentence": "'''Сэщкъэ''' ар",
            "translation": "'''I know''' that."
          },
          {
            "sentence": "Урысыбзэ '''сыщкъэрэп'''",
            "translation": "'''I don't know''' the Russian language."
          },
          {
            "sentence": "'''Сэщкъэ''' къысэп1уагъэ",
            "translation": "'''I know''' what you told me."
          },
          {
            "sentence": "'''Сымыщкъэрэ''' а к1алэм къуи1уагъэхэр",
            "translation": "'''I don't know''' the thing that boy told you."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1эн"
    ],
    "cognate": "ц1ыхун",
    "type": "verb",
    "ipa": "ɕqan"
  },
  {
    "word": "лъыплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to watch over, to keep an eye on",
        "examples": [
          {
            "sentence": "Мащинэм '''къылъыплъ'''",
            "translation": "'''Watch over''' the car."
          },
          {
            "sentence": "Мэзымк1э шак1уит1ур '''зэлъыплъэжьых'''",
            "translation": "In the forest the two hunters '''are watching over each other'''."
          },
          {
            "sentence": "Си к1элэц1ык1у '''къулъыплъэшъута'''?",
            "translation": "'''Can you keep an eye on''' my little boy?."
          },
          {
            "sentence": "Уишы '''къылъыплъэгу'''",
            "translation": "'''Keep an eye on''' your brother '''for now'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬəpɬan"
  },
  {
    "word": "ешъутырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъутырын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃʷtərəh"
  },
  {
    "word": "мэшъутырэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъутырын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʃʷtərəh"
  },
  {
    "word": "шъутырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kick",
        "examples": [
          {
            "sentence": "К1алэр мыжъом '''ешъутыры'''",
            "translation": "The boy '''is kicking''' the rock."
          },
          {
            "sentence": "Футболымк1э '''зэшъутырхэу''' хэтых",
            "translation": "In soccer '''they are kicking each other'''."
          },
          {
            "sentence": "'''Къусэмышъутырба'''",
            "translation": "'''Don't kick me'''."
          },
          {
            "sentence": "'''Ешъутыр''' 1эгуаом",
            "translation": "'''Kick''' the ball."
          },
          {
            "sentence": "'''Уамышъутыр''' мыжъохэмэ",
            "translation": "'''Don't kick''' the rocks."
          },
          {
            "sentence": "А к1алэр '''къошъутырыщт'''",
            "translation": "That boy '''gonna kick you'''."
          },
          {
            "sentence": "К1алэм ишъуты '''ч1эшъутыр'''",
            "translation": "'''Kick''' the boy in the butt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətəran"
  },
  {
    "word": "шъутырэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kick around",
        "examples": [
          {
            "sentence": "К1алэм упэмыблагъ, '''зешъутырэхьыжьы'''",
            "translation": "Don't get close to the boy, '''he is kicking around'''."
          },
          {
            "sentence": "К1элэц1ык1ухэр '''мэшъутырэхьыжьхэу''' щагум ч1этых",
            "translation": "The little boys '''are kicking around''' in the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətəraħən"
  },
  {
    "word": "мэшъутырэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъутырэхьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃʷtəraħən"
  },
  {
    "word": "п1ысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to fuck",
        "examples": [
          {
            "sentence": "К1алэм адрэр '''ып1ысыгъ'''",
            "translation": "t=The young man '''fucked''' the other one."
          },
          {
            "sentence": "'''Усып1ысыщт''' иджы сыкъэпгъэгуабжымэ",
            "translation": "t='''I gonna screw you''' if you now gonna make me angry."
          },
          {
            "sentence": "Ар 1аеу '''къыдап1ысэхьыгъ'''",
            "translation": "t=They '''screwed him''' so bad."
          },
          {
            "sentence": "'''Cэп1ыс''' а к1алэм ы1орэ",
            "translation": "t='''Screw''' what that boy says."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "pʼəsən"
  },
  {
    "word": "п1ысыгъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) screwed, fucked",
        "examples": [
          {
            "sentence": "Ар '''п1ысыгъэжъ'''",
            "translation": "He is '''fucked'''."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "adj",
    "ipa": "pʼəsəʁaʐ"
  },
  {
    "word": "дэгъэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone stuck",
        "examples": [
          {
            "sentence": "Пчъэм '''сыдэбэгъанэ'''",
            "translation": "'''You are making me stuck''' in the door."
          }
        ]
      },
      {
        "meaning": "(vulgar slang) to fuck, to have sexual intercourse with",
        "examples": [
          {
            "sentence": "'''Удэзгъэнэщт''' иджы сыкъэпгъэгуабжымэ",
            "translation": "'''I gonna fuck you''' if you now gonna make me angry."
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁanan"
  },
  {
    "word": "тэш1ык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тэш1ык1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "тэш1ык1ын"
    ],
    "type": "verb",
    "ipa": "taʃʼəkʲʼən"
  },
  {
    "word": "тэш1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sculpture based on",
        "examples": [
          {
            "sentence": "Артистым мыжъок1э л1ым '''теш1ык1ы'''",
            "translation": "The artist '''is sculpturing''' the man using a stone."
          }
        ]
      },
      {
        "meaning": "(vulgar slang) to masturbate watching someone",
        "examples": [
          {
            "sentence": "К1алэр фылым ш1оимэ '''ятеш1ык1ы'''",
            "translation": "The boy '''is masturbating on''' dirty films."
          },
          {
            "sentence": "Тэщтэу ущ '''тэшъош1ык1ы'''",
            "translation": "How '''are you (plural) masturbating on''' that."
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "synonyms": [
      "тэш1ык1ьын"
    ],
    "type": "verb",
    "ipa": "taʃʼət͡ʃʼən"
  },
  {
    "word": "хьэшъуты убат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэшъутыубат\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʃʷətə wəbaːt"
  },
  {
    "word": "к1апсэрык1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tightrope walker, funambulist",
        "examples": []
      }
    ],

    "cognate": "к1апсэрык1уэ",
    "type": "noun",
    "ipa": "t͡ʃʼaːpsarəkʷʼa"
  },
  {
    "word": "зэ1эхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэ1ыхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaħan"
  },
  {
    "word": "зэ1эгъэхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зы1эгъэхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaʁaħan"
  },
  {
    "word": "к1эшъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Standard) to suck",
        "examples": [],
        "tags": [
          "Standard"
        ]
      },
      {
        "meaning": "(Standard) (vulgar) to perform fellatio",
        "examples": [],
        "tags": [
          "Standard",
          "vulgar"
        ]
      },
      {
        "meaning": "(Shapsug dialect) to suck (in the only sense of: to draw something into the mouth by inhaling)",
        "examples": [],
        "tags": [
          "Shapsug dialect"
        ]
      },
      {
        "meaning": "to drain by extracting water",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʃʷən"
  },
  {
    "word": "ш1о1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rot",
        "examples": [
          {
            "sentence": "Шныхэр '''мэш1о1огъ'''",
            "translation": "The food '''was rotten'''."
          },
          {
            "sentence": "Бэнанэ башэрэ шыбгъэлъымэ '''мэш1о1ущт'''",
            "translation": "If you leave a banana for too long '''it will rot'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼaʔʷən"
  },
  {
    "word": "ишъуты ипхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar slang) to use something too much exaggeratedly, to use the hell out of",
        "examples": [
          {
            "sentence": "Мащинэр къэлал, '''ишъуты ипхэгъ'''",
            "translation": "Leave the car, '''you used the hell out of it'''"
          },
          {
            "sentence": "Сомпыютэрым '''ишъуты ипхыжьта'''",
            "translation": "'''Are you gonna use the hell out of''' the computer?"
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətə lan"
  },
  {
    "word": "ишъуты исагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) the hell with it (literary: It is penetrated in his buttocks.)",
        "examples": [
          {
            "sentence": "'''Уишъуты исагъ'''",
            "translation": "'''Fuck you'''!"
          },
          {
            "sentence": "А к1алэм '''ишъуты исагъ'''",
            "translation": "'''The hell with''' that boy!"
          }
        ],
        "tags": [
          "vulgar"
        ]
      },
      {
        "meaning": "(vulgar idiomatic) fuck it (An expression of frustration.)",
        "examples": [
          {
            "sentence": "'''Ишъуты исагъ''', фылымым нахь бэрэ сеплъышъущтэп",
            "translation": "'''Fuck it''', I can't continue watching the movie any longer."
          }
        ],
        "tags": [
          "vulgar",
          "idiomatic"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətə lan"
  },
  {
    "word": "к1ьэ тримылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar slang) to not care, to give a shit about",
        "examples": [
          {
            "sentence": "'''к1ьэ къуптэсылъхьэрэп'''",
            "translation": "'''I don't give a shit on you'''"
          },
          {
            "sentence": "А к1алэм '''к1ьэ тралъхьагъэп'''",
            "translation": "'''They didn't gave shit to''' that boy."
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "kʼa triməɬħan"
  },
  {
    "word": "исэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to penetrate into (inside)",
        "examples": [
          {
            "sentence": "Гъуанэм пхъэ к1алэм '''ресэ'''",
            "translation": "The boy '''is penetrating''' the wood '''into''' the hole."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəsan"
  },
  {
    "word": "зэк1эк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to walk backward, to go backward",
        "examples": [
          {
            "sentence": "'''Зэк1ак1у'''",
            "translation": "'''Go backward'''."
          },
          {
            "sentence": "К1алэр '''зэк1ак1о'''",
            "translation": "The boy '''is walking backward'''."
          },
          {
            "sentence": "Мащинэ '''зэк1эк1ошъурэп'''",
            "translation": "The car '''can't run backward'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼakʷʼan"
  },
  {
    "word": "зэк1эплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look back",
        "examples": [
          {
            "sentence": "'''Зэк1аплъ'''",
            "translation": "'''Look back'''."
          },
          {
            "sentence": "'''Узэк1эмыплъ'''",
            "translation": "'''Don't look back'''."
          },
          {
            "sentence": "'''Узэк1эмыплъэу''' чъэ",
            "translation": "Run '''without looking back'''."
          },
          {
            "sentence": "К1алэр '''зэк1аплъэ'''",
            "translation": "The boy '''is looking back'''."
          },
          {
            "sentence": "К1алэр '''зэк1аплъэм''' силъэгъогъ",
            "translation": "When the boy '''looked back''' he saw me."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼakʷʼan"
  },
  {
    "word": "1эбэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reach out, to extend one's hand(s) forward.",
        "examples": [
          {
            "sentence": "'''Къэ1аби''' си1э къаубыт уемыт1ырэхыгъэу",
            "translation": "'''Reach out and ''' hold my hand before you fall."
          },
          {
            "sentence": "К1алэр и1э ш1оихэмк1э '''мэ1абэ'''",
            "translation": "The boy '''is reaching out''' with his dirty hands."
          }
        ]
      },
      {
        "meaning": "to reach out and touch",
        "examples": [
          {
            "sentence": "Сишъхьэц '''укъытемы1аб'''",
            "translation": "'''Don't touch''' my hair."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔaban"
  },
  {
    "word": "лъы1эбэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to try to reach for something, to attempt to reach for",
        "examples": [
          {
            "sentence": "Л1ыжъэр ибэщ '''лъы1эбэшъурэп'''",
            "translation": "The old man '''can't reach''' his stick."
          },
          {
            "sentence": "К1алэр мыем '''лъэ1абэ'''",
            "translation": "The boy '''is trying to reach''' the apples."
          },
          {
            "sentence": "Ремот контролым '''сылъэ1абэ''' сыщысэу",
            "translation": "'''I am trying to reach''' the remote control while sitting."
          },
          {
            "sentence": "Сишъхьэц '''къулъымы1аб'''",
            "translation": "'''Don't try to touch''' my hair."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬəʔaban"
  },
  {
    "word": "егъэчъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone/something slide down",
        "examples": [
          {
            "sentence": "К1алэм псыр гъогум '''регъэчъэхы'''",
            "translation": "The boy '''slides down''' the water from the road."
          }
        ]
      },
      {
        "meaning": "to speak bad on someone and hurt his reputation",
        "examples": [
          {
            "sentence": "Тхьэл1ык1ум яц1ыфымэ ятхьэхэр '''ригъэчъэхыгъэх'''",
            "translation": "The prophet '''spoke bad''' on the gods of his people."
          },
          {
            "sentence": "К1алэр '''езгъэчъэхыгъ'''",
            "translation": "I '''spoke bad''' to the boy."
          }
        ]
      },
      {
        "meaning": "to roast someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁat͡ʂaxən"
  },
  {
    "word": "гъэчъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ригъэчъэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁat͡ʂaxən"
  },
  {
    "word": "ет1эрэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall (from something)",
        "examples": [
          {
            "sentence": "К1алэр унашъхьэм '''ет1эрэхэгъ'''",
            "translation": "The boy '''fell''' from the roof."
          },
          {
            "sentence": "Унашъхьэм '''ует1эрэхнэу''' уфая?",
            "translation": "Do you want '''to fall''' from the roof?."
          },
          {
            "sentence": "'''Уесгъэт1эрэхыт''' унашъхьэм",
            "translation": "'''I will make you fall''' from the roof."
          },
          {
            "sentence": "К1алэр дыущ '''ет1эрахы'''",
            "translation": "The boy '''is falling''' from that thing."
          },
          {
            "sentence": "Фэсакъ!, унашъхьэм утэц1эшъутык1ыни '''укъует1эрэхыщт'''",
            "translation": "Careful!, you will slip over the root and '''fall from it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jatʼaraxən"
  },
  {
    "word": "зэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "all",
        "examples": [
          {
            "sentence": "Пшъашъэ '''зэк1эм''' уанахьы дах",
            "translation": "You are the most beautiful '''among all''' the girls."
          },
          {
            "sentence": "Синыбджэгъу '''зэк1эм''' сыкъеджэщт непчыхьэ",
            "translation": "I will call '''all''' my friends tonight."
          },
          {
            "sentence": "Адыгэ '''зэк1а''' къэк1уагъэр?",
            "translation": "The people that came were '''all''' Circassians?."
          },
          {
            "sentence": "Дыджы '''зэак1''' к1алэм къыхьыгъэр",
            "translation": "'''All''' the things the boy brought are bitter."
          }
        ]
      }
    ],
    "synonyms": [
      "зэужэу",
      "пстэури"
    ],
    "type": "det",
    "ipa": "zat͡ʃʼa"
  },
  {
    "word": "1эгьы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a lot",
        "examples": [
          {
            "sentence": "'''1эгьы''' мэхъу",
            "translation": "'''A lot of things''' are happening."
          },
          {
            "sentence": "'''1эгьы''' иш1агъ ащ",
            "translation": "He did '''A lot of things'''."
          },
          {
            "sentence": "К1алэм '''1эгьи''' е1о",
            "translation": "The boy '''is saying a lot of things'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʔaɡʲə"
  },
  {
    "word": "нэ1эбэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to successfully reach for (usually with the hands)",
        "examples": [
          {
            "sentence": "К1алэ к1ыхьэр чъыгым мыеу пытым '''нэ1эбэсы'''",
            "translation": "The long boy '''is reaching''' the apple on the tree."
          },
          {
            "sentence": "К1элэц1ык1ор 1анэм '''нэ1эбэсырэп'''",
            "translation": "The little boy '''is not reaching''' the table."
          },
          {
            "sentence": "Унашъхьэм '''унэ1эбэсышъущтэп'''",
            "translation": "'''You can't reach''' the roof."
          },
          {
            "sentence": "К1алэм илъакъок1э ремот контыролым '''нэ1эбэсын''' е1о",
            "translation": "The boy '''is''' trying '''to reach''' the remote control with his feet."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naʔabasən"
  },
  {
    "word": "епэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stroke",
        "examples": [
          {
            "sentence": "Пшъашъэр чэтыум '''епаш1э'''",
            "translation": "The girl '''is stroking''' the cat."
          },
          {
            "sentence": "Чэтыу сымаджэм '''уемыпаш1'''",
            "translation": "'''Don't stroke''' the sick cat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "japaʃʼan"
  },
  {
    "word": "пэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"епэш1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "paʃʼan"
  },
  {
    "word": "напк1э тэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bless",
        "examples": [
          {
            "sentence": "К1алэм иянэ '''напк1э фэтехы'''",
            "translation": "The boy '''is blessing for''' his mother."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naːpt͡ʃʼa taxən"
  },
  {
    "word": "язырэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "one of them",
        "examples": [
          {
            "sentence": "Мые '''язырэ''' горэ къыхэх",
            "translation": "Pick '''one of''' the apple."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jaːzəra"
  },
  {
    "word": "мэуц1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уц1элэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawt͡sʼalan"
  },
  {
    "word": "къэгъэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cause, to make it occur, to make it so that",
        "examples": [
          {
            "sentence": "Сэ фылым теплъышъунэу '''къэзгъэфыщт'''",
            "translation": "I will '''cause it''' so we could watch a film."
          },
          {
            "sentence": "Пшъашъэм сы1ук1энэу '''къэбгъэфышъущта'''?",
            "translation": "'''Can you cause it''' so that I could meet the girl?"
          },
          {
            "sentence": "К1элэ мыгъохэмэ '''къагъэфыщт''' Томэр бэнанэм къытэц1эшъутык1ынэу.",
            "translation": "The mean boys '''will cause it''' so Tom will slip over the banana"
          },
          {
            "sentence": "Дзак1ом к1эрахъок1э еуагъ дзак1ом ик1эрахъо къыдихынэу '''фимыгъафэу'''",
            "translation": "He shot the soldier with a gun before the soldier had the change to draw his gun."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁafən"
  },
  {
    "word": "къэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cause, to occur, to be so that",
        "examples": [
          {
            "sentence": "Тэущтэу '''къэфышъущт''' к1элэегъаджэм тызрегъэджэн фаер имыш1эу",
            "translation": "How '''can it be caused''' so that the teacher know what to teach us."
          },
          {
            "sentence": "Непэ къещхынэу '''къифагъ''' мафэ купэмк1э",
            "translation": "'''It happens to be''' raining today from all the days."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qafən"
  },
  {
    "word": "тэгъэц1эшъутык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone slip over a",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм егук1и джыхашъом '''къитигъэц1эшъутык1эгъ'''",
            "translation": "The boy pushed the girl and '''made her slip over''' the surface."
          },
          {
            "sentence": "Шэйтаным гъогу тэрэзым '''утигъэц1эшъутык1ыщт'''",
            "translation": "The devil '''will slip you from''' the right path."
          }
        ]
      }
    ],
    "synonyms": [
      "тэгъэц1эшъутык1ьын"
    ],
    "type": "verb",
    "ipa": "taʁat͡sʼaʃʷtət͡ʃʼən"
  },
  {
    "word": "тэц1эшъутык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slip over a",
        "examples": [
          {
            "sentence": "К1алэр джыхашъо ц1ышъутэм '''тэц1эшъутык1эгъ'''",
            "translation": "The boy '''slipped over''' the slippery surface."
          },
          {
            "sentence": "Фэсакъ!, унашъхьэм '''утэц1эшъутык1ыни''' укъует1эрэхыщт",
            "translation": "Careful!, '''you will slip over''' the root and fall from it."
          },
          {
            "sentence": "К1элэ мыгъохэмэ къагъэфыщт Томэр бэнанэм '''къытэц1эшъутык1ынэу'''",
            "translation": "The mean boys will arrange it so Tom '''will slip over''' the banana"
          }
        ]
      }
    ],
    "synonyms": [
      "тэц1эшъутык1ьын"
    ],
    "type": "verb",
    "ipa": "tat͡sʼaʃʷtət͡ʃʼən"
  },
  {
    "word": "тэц1эшъутык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тэц1эшъутык1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tat͡sʼaʃʷtəkʲʼən"
  },
  {
    "word": "мэш1ухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъогъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maʃʷʼəχʷəʁʷan"
  },
  {
    "word": "ш1ухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъогъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хъопсэн"
    ],
    "type": "verb",
    "ipa": "ʃʷʼəχʷəʁʷan"
  },
  {
    "word": "еш1ухъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъогъон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʃʷʼəχʷəʁʷan"
  },
  {
    "word": "шъхьэфитынгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "freedom",
        "examples": [
          {
            "sentence": "'''Шъхьафитынгъэ''' си1эба сыфаер сыш1энэу",
            "translation": "Don't I have the '''freedom''' to do as i like."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂħafitnəʁa"
  },
  {
    "word": "къэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to arrive",
        "examples": [
          {
            "sentence": "Къалэм '''укъэсыгъа?'''",
            "translation": "'''Have you arrived''' to the city?"
          },
          {
            "sentence": "Уиунэ '''сыкъэсымэ''' къик1",
            "translation": "'''When I arrive''' to your house come out."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qasən"
  },
  {
    "word": "блын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "блэн",
      "----"
    ],
    "type": "verb",
    "ipa": "blən"
  },
  {
    "word": "щэчын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to measure weight",
        "examples": [
          {
            "sentence": "К1алэм 79 килограм '''къещэчы'''",
            "translation": "The boy '''weights''' 79 kilograms"
          },
          {
            "sentence": "Мы мыехэмэ 5 килограм '''къащэчырэр'''",
            "translation": "These apples '''weight''' 5 kilograms."
          },
          {
            "sentence": "Зыб '''къэпщэчырэр'''",
            "translation": "'''You are weighing''' so much."
          }
        ]
      }
    ],
    "synonyms": [
      "щэкьын",
      "къэщэкьын"
    ],
    "type": "verb",
    "ipa": "ɕat͡ʃən"
  },
  {
    "word": "щэкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэчын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕakʲən"
  },
  {
    "word": "къэщэкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэчын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaɕakʲən"
  },
  {
    "word": "къэщэчын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэчын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaɕat͡ʃən"
  },
  {
    "word": "мэзпчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deer",
        "examples": []
      },
      {
        "meaning": "roebuck",
        "examples": []
      },
      {
        "meaning": "mountain goat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazpt͡ʃan"
  },
  {
    "word": "мэк1ьыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэк1ыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "makʲʼəjən"
  },
  {
    "word": "къыч1эгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to invent something",
        "examples": [
          {
            "sentence": "Хэт телефонэр '''къэзч1игъэк1ыгъэр?'''",
            "translation": "Who '''invented''' the telephone?"
          },
          {
            "sentence": "Зыгорэ к1эу '''къыч1эгъэк1ы'''",
            "translation": "'''Invent''' something new."
          }
        ]
      },
      {
        "meaning": "to make someone get out from under something",
        "examples": [
          {
            "sentence": "К1алэм ишы унэ ч1эгъым '''къыч1егъэк1ы'''",
            "translation": "The boy '''is making''' his brother '''come out under''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qət͡ʃʼaʁakʲʼən"
  },
  {
    "word": "къэсшъхьэпк1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get up quickly",
        "examples": [
          {
            "sentence": "К1алэм сытэкуом '''къэсшъхьэпк1этэгъ'''",
            "translation": "When I yelled at the boy '''he got up quickly'''."
          },
          {
            "sentence": "'''къэсшъхьэпк1эти''' еджап1эм к1о",
            "translation": "'''Get up quickly''' and go to school."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qasʂħapkʲʼatən"
  },
  {
    "word": "сшъхьэпк1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэсшъхьэпк1этын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "sʂħapkʲʼatən"
  },
  {
    "word": "к1ыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scream, to shout out really loudly",
        "examples": [
          {
            "sentence": "Пшъашъэр '''мэк1ыи'''",
            "translation": "The girl '''is screaming'''."
          },
          {
            "sentence": "Тэщтэу '''ок1ыи'''",
            "translation": "How '''you scream'''?."
          },
          {
            "sentence": "Пшъэшъэ ц1ык1ухэмэ яфэдэ '''мэк1ыегъ'''",
            "translation": "(S)he '''screamed''' like a little girl."
          }
        ]
      }
    ],
    "synonyms": [
      "к1ьыин"
    ],
    "type": "verb",
    "ipa": "mat͡ʃʼəjən"
  },
  {
    "word": "мэк1ыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʃʼəjən"
  },
  {
    "word": "к1ьэлъыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1элъыон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1элъыон"
    ],
    "type": "verb",
    "ipa": "kʲʼaɬəwən"
  },
  {
    "word": "к1элъыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scoff someone",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''к1элъыо'''",
            "translation": "The boy '''is scoffing''' at the man."
          },
          {
            "sentence": "К1алэр къак1уи '''къэтк1элъыо'''",
            "translation": "The boy came and '''started scoffing us'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1ьэлъыон"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaɬəwən"
  },
  {
    "word": "т1эрэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэт1эрэхьын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "мэт1эрэхьые"
    ],
    "type": "verb",
    "ipa": "matʼaraħən"
  },
  {
    "word": "мэт1эрэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to roll around",
        "examples": [
          {
            "sentence": "1эгуаор '''мэт1эрэхьэу''' щагум дэлъ",
            "translation": "The ball is in the yard '''rolling around'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "matʼaraħən"
  },
  {
    "word": "шъхьэпразэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dizzy",
        "examples": [
          {
            "sentence": "'''Ушъхьэпраза?'''",
            "translation": "'''Are you dizzy'''?"
          },
          {
            "sentence": "К1алэр '''шъхьэпраза'''",
            "translation": "The boy is '''dizzy'''"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʂħapraːza"
  },
  {
    "word": "1эпык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эпык1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapəkʲʼən"
  },
  {
    "word": "1эпы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to help",
        "examples": []
      }
    ],
    "synonyms": [
      "дэ1эпы1эн"
    ],
    "type": "verb",
    "ipa": "ʔapəʔan"
  },
  {
    "word": "дэ1эпы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to help; to aid; to assist",
        "examples": [
          {
            "sentence": "Л1ыжъым '''дэ1эпы1'''",
            "translation": "'''Help''' the old man."
          },
          {
            "sentence": "О '''укъэсдэ1эпы1эшъута'''?",
            "translation": "'''Can you help me?'''."
          },
          {
            "sentence": "К1алэр сэ '''къэсдэ1эпы1э'''",
            "translation": "The boy '''is helping me'''."
          },
          {
            "sentence": "К1алэр л1ым '''дэ1эпы1э'''",
            "translation": "The boy '''is helping''' the man."
          },
          {
            "sentence": "Сиятэ '''сыдэ1эпы1эн''' фай",
            "translation": "I need '''to help''' my father."
          },
          {
            "sentence": "Непэ сиятэ и1офк1э '''сыдэ1эпы1эрагъу'''",
            "translation": "Today '''I want to help''' my father with his work."
          }
        ]
      }
    ],
    "synonyms": [
      "1эпы1эн",
      "1эпы1эгъоу фэхъун"
    ],
    "type": "verb",
    "ipa": "daʔapəʔan"
  },
  {
    "word": "1эпык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to evade",
        "examples": [
          {
            "sentence": "Уич1ыфэ утын фаем о '''уш1о1эпык1ышъутэп'''",
            "translation": "'''You can't evade''' the dept you need to pay."
          },
          {
            "sentence": "А к1элэ 1эежъым '''сы1эпык1эгъ'''",
            "translation": "'''I evaded''' that annoying boy."
          }
        ]
      },
      {
        "meaning": "to not be able to hold the pee",
        "examples": [
          {
            "sentence": "'''Къэс1эпык1ы''', псыунэм сихьан фай",
            "translation": "'''I can't hold my pee''', I need to enter the restroom."
          },
          {
            "sentence": "'''Къумы1эпык1ыгъэу''' псыунэм шыгъутхь",
            "translation": "'''Before you release your pee (by not being to hold it anymore)''' go to the toilet to pee."
          }
        ]
      }
    ],
    "synonyms": [
      "1эпык1ьын"
    ],
    "type": "verb",
    "ipa": "ʔapət͡ʃʼən"
  },
  {
    "word": "нэк1э рик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gaze exaggeratedly at someone, to stare really intently or really earnestly at someone",
        "examples": [
          {
            "sentence": "Сэхъуанэм '''нэк1э сырак1эгъ''' л1ыжъыхэмэ",
            "translation": "When I cursed the old men '''stared at me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "nat͡ʃʼa rəjt͡ʼən"
  },
  {
    "word": "1эзэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "treatment",
        "examples": [
          {
            "sentence": "Л1ы у1агъэм '''1эзэгъу''' ищык1агъ",
            "translation": "The wounded man needs '''treatment'''."
          }
        ]
      },
      {
        "meaning": "medicine",
        "examples": [
          {
            "sentence": "'''1эзэгъу''' уцыхэр",
            "translation": "'''Drug''' plants."
          }
        ]
      },
      {
        "meaning": "healthy",
        "examples": [
          {
            "sentence": "Тхьэм к1алэр '''1эзэгъоу''' ерэш1",
            "translation": "May god make the boy '''healthy'''."
          }
        ]
      },
      {
        "meaning": "healing partner; healing body; healing friend",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔazəʁʷ"
  },
  {
    "word": "узын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэузын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəzən"
  },
  {
    "word": "улэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэулэлэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəlalan"
  },
  {
    "word": "улъыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get rusted",
        "examples": [
          {
            "sentence": "Гъуч1ыр '''улъыигъ'''",
            "translation": "The iron '''became rusted'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəɬəjən"
  },
  {
    "word": "мэулъыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"улъыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mawəɬəjan"
  },
  {
    "word": "мэулэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hang and bounce (freely)",
        "examples": [
          {
            "sentence": "Уицуакъэ илъапсэхэр '''мэулалэх'''",
            "translation": "Your shoelaces '''are bouncing'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawəlalan"
  },
  {
    "word": "зэтэухьэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be closed from all sides",
        "examples": [
          {
            "sentence": "Мафэ дахэм к1алэр яунэ '''зэтэухьэлагъи''' ис",
            "translation": "In the beautiful day the boy is sitting in the house '''closed in all sides'''."
          },
          {
            "sentence": "Мы унэр '''зэтэухьэлагъ'''",
            "translation": "This house '''is closed from all sides'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zatawħalan"
  },
  {
    "word": "унэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэунэхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wənaχʷən"
  },
  {
    "word": "епхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "japxən"
  },
  {
    "word": "к1эгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эджэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaɡʲan"
  },
  {
    "word": "к1эджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to announce",
        "examples": []
      },
      {
        "meaning": "to declare openly",
        "examples": []
      },
      {
        "meaning": "to inform widely about",
        "examples": [
          {
            "sentence": "Ти дин '''шъук1адж'''",
            "translation": "'''Inform widely about''' our religion (said to plural)."
          },
          {
            "sentence": "К1алэм къищэти иныбджэгъухэ '''фэк1аджэх'''",
            "translation": "Because the young man gonna merry, his friends are '''informing widely about it for him'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼad͡ʒan"
  },
  {
    "word": "мэук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be killed",
        "examples": [
          {
            "sentence": "Л1ыр '''мэук1эгъ'''",
            "translation": "The man '''was killed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "mawt͡ʃʼən"
  },
  {
    "word": "мэтхытхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхытхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "matxətxən"
  },
  {
    "word": "тхытхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shiver (to tremble or shake, especially when cold or frightened.)",
        "examples": [
          {
            "sentence": "Л1ыр '''мэтхытхы'''",
            "translation": "The man '''is shivering'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "txətxən"
  },
  {
    "word": "гъоч1эгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cave",
        "examples": [
          {
            "sentence": "Л1ыр '''гъоч1эгъым''' ч1эс",
            "translation": "The man is sitting in '''the cave'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁʷat͡ʂʼaʁ"
  },
  {
    "word": "хэуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to step on liquid",
        "examples": [
          {
            "sentence": "Псыжъым '''ухауцо'''!",
            "translation": "'''You are stetting inside''' the mud!"
          },
          {
            "sentence": "К1алэр псым '''хауцо'''",
            "translation": "The boy '''is stepping on''' the water."
          },
          {
            "sentence": "Псым '''ухэмыуцоу''' блэк1",
            "translation": "Pass the water without '''stepping in it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawt͡sʷən"
  },
  {
    "word": "тэуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to step on",
        "examples": [
          {
            "sentence": "Пхъэм '''утэмыуцоу''' блэк1",
            "translation": "Pass the wood without '''stepping on it'''."
          },
          {
            "sentence": "1анэм '''утэмыуцу'''",
            "translation": "'''Don't step on''' the table."
          },
          {
            "sentence": "Хьамбылыум '''сытэуцагъ'''",
            "translation": "'''I stepped on''' the worm."
          },
          {
            "sentence": "К1алэр джэгуалъэхэмэ имыш1ахэу '''ятауцо'''",
            "translation": "The boy accidentally '''is stepping''' on the toys."
          },
          {
            "sentence": "К1алэм ицуакъэхэр псыжъык1э ш1оихэу унэм иджыхашъо '''тауцо'''",
            "translation": "The boy '''is stepping''' on the house's floor with his dirty mud shoes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tawt͡sʷən"
  },
  {
    "word": "хьа1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Abzakh dialect) guest",
        "examples": [],
        "tags": [
          "Abzakh dialect"
        ]
      }
    ],
    "synonyms": [
      "хьак1э"
    ],
    "cognate": "хьащ1э",
    "type": "noun",
    "ipa": "ħaːʔʲa"
  },
  {
    "word": "зэтэу1ок1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fold",
        "examples": [
          {
            "sentence": "Тхылъып1э '''зэтэу1уак1'''",
            "translation": "'''Fold''' the paper."
          },
          {
            "sentence": "К1алэм тхылъып1э '''зэтеу1уак1э'''",
            "translation": "The boy '''is folding''' the paper."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zatawʔʷat͡ʃʼan"
  },
  {
    "word": "зэтэлъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stack",
        "examples": [
          {
            "sentence": "Тхылъыхэр '''зэталъхьэх'''",
            "translation": "'''Stack''' the books."
          },
          {
            "sentence": "Л1ым пхъэхэр '''зэтелъхьэх'''",
            "translation": "The man '''is stacking''' the woods."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zataɬħan"
  },
  {
    "word": "фэгъэпсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to point to, to aim for",
        "examples": [
          {
            "sentence": "К1эрагъор '''къэсфэмыгъэпс'''",
            "translation": "'''Don't aim''' the gun '''at me'''."
          },
          {
            "sentence": "Ц1ыфыхэмэ уи1эхъуамбэ '''яфэмыгъэпс'''",
            "translation": "'''Don't point''' your hand on people."
          },
          {
            "sentence": "Л1ым щэбзащэр мыем '''фигъэпсэгъ'''",
            "translation": "The man '''pointed''' the arrow to the apple."
          },
          {
            "sentence": "Снайперым риохэмэ тэрэзэу '''ягъэпсын''' фае",
            "translation": "The people that shot with a sniper need '''to aim''' correctly."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faʁapsən"
  },
  {
    "word": "к1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ек1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼən"
  },
  {
    "word": "фэшъуаш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "depends on",
        "examples": [
          {
            "sentence": "Ар ц1ыфэу 1офыр зиш1эрэм '''фэшъуаш'''",
            "translation": "It '''depends on''' the person that does the job."
          }
        ]
      },
      {
        "meaning": "have it coming (appropriate penalty); deserve",
        "examples": [
          {
            "sentence": "Ащ '''фэшъуаш''', тестым ухэпц1ахьэмэ ухадзыщт",
            "translation": "'''He had it coming''', if you cheat on the test you will be kicked-out."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faʃʷaːʃ"
  },
  {
    "word": "къырегъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mean",
        "examples": [
          {
            "sentence": "Сыд '''къуебгъэхьырэ'''?",
            "translation": "What '''do you mean'''?"
          },
          {
            "sentence": "'''Къезгъэхьырэр''' мары",
            "translation": "This is '''what I mean'''."
          },
          {
            "sentence": "Ащ '''къыригъэхьыгъэр''' уеджэнэр ары",
            "translation": "'''What he meant was''' for us to study."
          },
          {
            "sentence": "Арэп '''къезгъэхьырэр'''",
            "translation": "That's not '''what I mean'''."
          }
        ]
      },
      {
        "meaning": "someone to make/allow someone else to bring a",
        "examples": [
          {
            "sentence": "Сик1алэ псы к1элэц1ык1умэ '''къаригъэхьыщт'''",
            "translation": "My boy '''will make''' the children '''to bring''' water."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəreʁaħən"
  },
  {
    "word": "зыгъэсамбырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make oneself patient, to content oneself to wait if necessary; not losing one self's temper while waiting.",
        "examples": [
          {
            "sentence": "Зэ '''зыгъэсамбыр''', мары тк1ощт",
            "translation": "'''Be patient''' for a moment, we will go soon."
          },
          {
            "sentence": "К1алэм '''зыгъэсамбырэу''' паплъэ",
            "translation": "The boy is waiting '''while being patient'''."
          },
          {
            "sentence": "А к1алэм семыонэ '''зэсэгъэсамбыры'''",
            "translation": "'''I am making myself patient''' not to hit that boy."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэбыяун"
    ],
    "type": "verb",
    "ipa": "zəʁasaːmbərən"
  },
  {
    "word": "зэтэлъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be stacked",
        "examples": [
          {
            "sentence": "Тхылъыхэр '''зэтэлъых'''",
            "translation": "The books '''are stacked'''"
          },
          {
            "sentence": "Пхъэхэр '''зэтэлъыхэу''' щагум дэтых",
            "translation": "The woods are in the yard '''stacked'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zataɬən"
  },
  {
    "word": "ащ шъхьэк1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьак1э\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːɕ ʂħakʲʼa"
  },
  {
    "word": "зэхэдзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to distinguish between",
        "examples": [
          {
            "sentence": "Чэтыу хъумрэ бзымрэ '''зэхэсэдзых'''",
            "translation": "'''I distinguish between''' the male cat and female cat."
          },
          {
            "sentence": "Шъохэ '''зэхэбдзыхэрэба?'''",
            "translation": "'''Don't you distinguish between''' the colors?."
          }
        ]
      },
      {
        "meaning": "to separate into groups, to segregate",
        "examples": [
          {
            "sentence": "К1алэхэмрэ пшъашъэхэмрэ '''зэхэдзых'''",
            "translation": "'''Separate''' the boys and girls '''into groups'''."
          },
          {
            "sentence": "Л1ым зырзырэу '''тызэхидзэгъ'''",
            "translation": "The man '''separated''' us '''into''' singles."
          },
          {
            "sentence": "Т1урыт1оу '''зэхэдзыгъэхэу''' л1ыхэр мэзым хахьэх",
            "translation": "The men are entering the forest '''separated into''' pairs."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxad͡zən"
  },
  {
    "word": "зэпыдзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to separate into singles",
        "examples": [
          {
            "sentence": "Л1ым зырзырэу '''тызэпидзэгъ'''",
            "translation": "The man '''separated''' us '''into''' singles."
          },
          {
            "sentence": "Ц1ыфыхэр '''зэпыдзых'''",
            "translation": "'''Separate''' the people '''to singles'''."
          }
        ]
      },
      {
        "meaning": "to isolate",
        "examples": [
          {
            "sentence": "Л1ыр '''зэпыдзэгъ'''",
            "translation": "The man '''is isolated'''."
          },
          {
            "sentence": "Исландияр хэгъэгу '''зэпыдзэгъ'''",
            "translation": "Iceland is '''an isolated''' country."
          },
          {
            "sentence": "X гъэбылъгъэр '''зэпыдзыгъэу''' къэгъан",
            "translation": "Leave the X variable '''isolated'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapəd͡zən"
  },
  {
    "word": "зэхэдзышъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be able to tell the difference between, to be able to distinguish",
        "examples": [
          {
            "sentence": "Чэтыу хъумрэ бзымрэ '''зэхэсэдзышъух'''",
            "translation": "'''I can tell difference between''' the male cat and female cat."
          },
          {
            "sentence": "К1элэц1ык1ум цыгъорэ шъуаемрэ '''зэхедзышъух'''",
            "translation": "The little boy '''can tell the difference between''' a mouse and a rat."
          },
          {
            "sentence": "Ц1ыфыхэр щы1эх шъохэр '''зэхамыдзышъухэу'''",
            "translation": "There are people that '''can't tell the difference''' between the colors."
          }
        ]
      },
      {
        "meaning": "to be able to separate into groups, to be able to segregate",
        "examples": [
          {
            "sentence": "Тэ ш1ош1ухъуныгъэ зи1эхэмк1э '''тызэхадзышъутэп'''",
            "translation": "'''They can't separate us''' the believes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxad͡zəʃʷən"
  },
  {
    "word": "зэпык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пык1ьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapəkʲʼən"
  },
  {
    "word": "зэпык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break",
        "examples": [
          {
            "sentence": "К1алэм джэгуалъэр '''зэпик1эгъ'''",
            "translation": "The boy '''broke''' the toy."
          },
          {
            "sentence": "Пхъэр '''зэпык1эгъ'''",
            "translation": "The wood '''is broken'''."
          }
        ]
      },
      {
        "meaning": "alternative form of \"пык1ын\" (to divorce; to separate from)",
        "examples": []
      }
    ],
    "synonyms": [
      "пык1ын"
    ],
    "type": "verb",
    "ipa": "zapət͡ʃʼən"
  },
  {
    "word": "пыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pick from a hanging state (like a shirt, fruit on tree etc.)",
        "examples": [
          {
            "sentence": "Щыгъын пылъап1эм '''къыпых''' джанэр",
            "translation": "'''Pick''' the shirt from the coat hanger."
          }
        ]
      },
      {
        "meaning": "to unplug",
        "examples": [
          {
            "sentence": "USB-эр сомпыютэрым '''къыпых'''",
            "translation": "'''Unplug''' the USB from the computer."
          }
        ]
      },
      {
        "meaning": "to take away (knowledge, behavior) from someone",
        "examples": [
          {
            "sentence": "К1элэ бзаджэм зи '''къыпыпхыштэп'''",
            "translation": "'''You won't take''' anything '''from''' the evil kid."
          },
          {
            "sentence": "Еджэным бэ '''къыпыпхышъущт'''",
            "translation": "'''You can take''' a lot '''from''' studying."
          },
          {
            "sentence": "К1алэм иныбджэгъухэм яшэн 1аехэр '''къыаприхыгъэх'''",
            "translation": "The boy '''took''' the bad behaviors '''from''' his friends."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəxən"
  },
  {
    "word": "тэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get stuck from something sticky, to become or remain attached; to adhere, to stick",
        "examples": [
          {
            "sentence": "Си1э 1анэм '''тэпк1агъ'''",
            "translation": "My arm '''got stuck on''' the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tapt͡ʃʼan"
  },
  {
    "word": "гущ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alas (Used to express sorrow, regret, compassion or grief.)",
        "examples": []
      },
      {
        "meaning": "expresses a wish",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "ɡʷəɕ"
  },
  {
    "word": "ш1ойбэхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very dirty (covered with or containing unpleasant substances such as dirt or grime or mud)",
        "examples": [
          {
            "sentence": "Унэр '''ш1ойбэхь'''",
            "translation": "The house '''is really dirty'''."
          },
          {
            "sentence": "'''Ш1ойбэхьэу''' ухъогъ",
            "translation": "You became '''really dirty'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʃʷʼajbaħ"
  },
  {
    "word": "лъежэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chase after",
        "examples": [
          {
            "sentence": "'''Къуслъемыж'''",
            "translation": "'''Don't chase after me'''."
          },
          {
            "sentence": "К1алэм '''лъеж''', сэри '''къэслъеж'''",
            "translation": "'''Chase after''' the boy and '''chase after me''' as well."
          },
          {
            "sentence": "К1алэр зэгуабжым '''къэтлъежагъ'''",
            "translation": "When the boy got mad '''he chased after us'''."
          },
          {
            "sentence": "К1элэц1ык1ухэр '''зэлъежэжьых'''",
            "translation": "The little kids '''are chasing each other'''."
          },
          {
            "sentence": "Иджы '''услъежэщт'''",
            "translation": "Now '''I gonna chase after you'''."
          },
          {
            "sentence": "К1элэц1ык1умэ '''салъежэрагъоп'''",
            "translation": "'''I don't wanna chase after''' the kids."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬajʒan"
  },
  {
    "word": "лъычъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run after",
        "examples": [
          {
            "sentence": "'''Къуслъымычъ'''",
            "translation": "'''Don't run after me'''."
          },
          {
            "sentence": "Мащинэм '''улъымычъ'''",
            "translation": "'''Don't run after''' the car."
          },
          {
            "sentence": "'''Къэслъычъи''' мые къустыщт",
            "translation": "'''Run after me''' and I will give you an apple."
          },
          {
            "sentence": "К1элэц1ык1ухэр '''зэлъэчъэжых'''",
            "translation": "The little kids '''are running after each other'''."
          },
          {
            "sentence": "К1элэц1ык1у мыгъомэ '''уалъымычъ'''",
            "translation": "'''Don't run after''' the mean kids."
          }
        ]
      },
      {
        "meaning": "to chase",
        "examples": []
      }
    ],
    "synonyms": [
      "лъежьэн"
    ],
    "type": "verb",
    "ipa": "ɬət͡ʂan"
  },
  {
    "word": "лъэгъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw at someone",
        "examples": [
          {
            "sentence": "К1алэр мыжъок1э '''къэслъэгъуе'''",
            "translation": "The boy '''is throwing at me''' rocks."
          },
          {
            "sentence": "К1алэхэмэ '''уалъымыгъуй'''",
            "translation": "'''Don't throw things at''' the boys."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬaʁʷəjan"
  },
  {
    "word": "гъуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэгъуен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁʷəjan"
  },
  {
    "word": "зэгок1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэгок1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "зэгок1ын"
    ],
    "type": "verb",
    "ipa": "zaɡʷatkʲʼən"
  },
  {
    "word": "нэхъут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lead (tack), lead line, lead rope, a rope used to lead an animal such as a horse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naχʷət"
  },
  {
    "word": "хъыжъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hurry",
        "examples": [
          {
            "sentence": "О '''ухъыжъа'''?",
            "translation": "'''Are''' you '''hurrying'''?"
          },
          {
            "sentence": "К1алэр '''мэхъыжъэ'''",
            "translation": "The boy '''is hurrying up'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тхъытхъын",
      "гузэжъон",
      "ш1эхын"
    ],
    "type": "verb",
    "ipa": "χəʐan"
  },
  {
    "word": "мэс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"с1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "sʼən"
  },
  {
    "word": "къэс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"с1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "sʼən"
  },
  {
    "word": "ц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to take a shit,",
        "examples": [
          {
            "sentence": "К1алэр '''мэц1ы'''",
            "translation": "The boy '''is taking a shit'''."
          },
          {
            "sentence": "'''Укъэмыц1'''",
            "translation": "'''Don't shit'''."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼən"
  },
  {
    "word": "с1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "sʼən"
  },
  {
    "word": "мэц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡sʼən"
  },
  {
    "word": "къэц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼən"
  },
  {
    "word": "ч1эс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эц1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼasʼan"
  },
  {
    "word": "ч1эц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to take a shit under",
        "examples": [
          {
            "sentence": "Хьэр унэ ч1эгъым '''ч1эц1агъ'''",
            "translation": "The dog '''took a shit under''' the house."
          }
        ],
        "tags": [
          "vulgar"
        ]
      },
      {
        "meaning": "(vulgar slang) to withdraw, to chicken out",
        "examples": [
          {
            "sentence": "Фылым теплъытэу к1алэр '''къыч1эц1агъ'''",
            "translation": "While we were going to watch a movie, the boy '''withdraw'''."
          },
          {
            "sentence": "К1алэр чъыгым къепк1эщтыгъ, ау '''къыч1эц1агъ'''",
            "translation": "The boy was going to jump off the tree, but he '''chicken out'''."
          }
        ],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼat͡sʼan"
  },
  {
    "word": "ч1эгъэц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to scare the shit out of, to make someone feel very frightened",
        "examples": [
          {
            "sentence": "Фылым уигъэшынэным к1алэр '''къыч1игъэц1агъ'''",
            "translation": "The harrow movie '''scared the shit out of''' the boy."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼat͡sʼan"
  },
  {
    "word": "ч1эгъэс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эгъэц1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʁasʼan"
  },
  {
    "word": "гъэ1орыш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to govern, to rule, to manage",
        "examples": [
          {
            "sentence": "Шыр гъэсагъэу '''бгъэIорышIэн'''.}}",
            "translation": ""
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaʔʷarʃʼan"
  },
  {
    "word": "иен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эен\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эен",
      "----"
    ],
    "type": "verb",
    "ipa": "jajən"
  },
  {
    "word": "тыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "present, gift",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ухьафтын",
      "----"
    ],
    "type": "noun",
    "ipa": "tənə"
  },
  {
    "word": "джэнэпылъап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coat hanger, clothes hanger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒanapəɬaːpʼ"
  },
  {
    "word": "пылъап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hanger",
        "examples": []
      }
    ],
    "synonyms": [
      "пылъалъэ"
    ],
    "type": "noun",
    "ipa": "pəɬaːpʼ"
  },
  {
    "word": "зыохьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go apeshit, to get out of control due to anger or excitement",
        "examples": [
          {
            "sentence": "К1алэм '''зеохьыжьы'''",
            "translation": "The boy '''is going apeshit'''."
          },
          {
            "sentence": "Цум т1асхьэхэмэ '''зеохьыжьы'''",
            "translation": "If people sit down on an ox '''it gets out of control'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəwaħən"
  },
  {
    "word": "дэлъып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "closet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "daɬəpʼa"
  },
  {
    "word": "гъэлак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "house painter",
        "examples": [
          {
            "sentence": "'''Гъэлак1ом''' дэпкъыр егъалэ.",
            "translation": "The '''house painter''' colors the wall."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁaɮaːkʷʼa"
  },
  {
    "word": "къэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grave",
        "examples": []
      }
    ],
    "synonyms": [
      "хъэ"
    ],
    "cognate": "кхъэ",
    "type": "noun",
    "ipa": "qa"
  },
  {
    "word": "къэхалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "graveyard",
        "examples": []
      }
    ],
    "synonyms": [
      "хъэхалъэ"
    ],
    "type": "noun",
    "ipa": "qaxaːɬa"
  },
  {
    "word": "жьыдзап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air force base",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəd͡zəpʼa"
  },
  {
    "word": "жьыдзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air force",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəd͡zəpʼa"
  },
  {
    "word": "ш1уфэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "greetings",
        "examples": []
      }
    ],

    "cognate": "ф1эхъус",
    "type": "intj",
    "ipa": "ʃʷʼəfas"
  },
  {
    "word": "к1эк1ьащын",
    "lang": "Adyghe",
    "definitions": [],
    "type": "noun",
    "ipa": "t͡ʃʼakʲʼaːɕən"
  },
  {
    "word": "шъхьэныкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dumb",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħanəqʷa"
  },
  {
    "word": "цолъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уцолъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʷaɬən"
  },
  {
    "word": "уцэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to masticate; to chew, to munch",
        "examples": []
      }
    ],
    "synonyms": [
      "упэш1ын",
      "гъэунэшк1ун"
    ],
    "type": "verb",
    "ipa": "wət͡saʔʷən"
  },
  {
    "word": "гъэунэшк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chew",
        "examples": [
          {
            "sentence": "Дэшхынэр '''сэгъэунэшк1у'''",
            "translation": "'''I am chewing''' the food."
          },
          {
            "sentence": "Сакъызэр дахэу '''угъэунэшк1у'''",
            "translation": "'''Chew''' the gum with good manners."
          }
        ]
      }
    ],
    "synonyms": [
      "уцэ1ун",
      "упэш1ын"
    ],
    "type": "verb",
    "ipa": "ʁawnaʃkʷʼən"
  },
  {
    "word": "жьы къэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to breathe",
        "examples": []
      }
    ],
    "synonyms": [
      "фэпшэн"
    ],
    "type": "verb",
    "ipa": "ʑə qaɕan"
  },
  {
    "word": "щыш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to happen to someone",
        "examples": [
          {
            "sentence": "'''Къэсщыш1эгъ''' ар",
            "translation": "That '''happened to me'''."
          },
          {
            "sentence": "Ащтэу '''къупщыш1ынэу''' уфая?",
            "translation": "Do you want that '''to happen to you'''?."
          },
          {
            "sentence": "Ащтэу к1алэм '''щэрэмыщ1'''",
            "translation": "'''May''' that '''not happen to''' the boy."
          },
          {
            "sentence": "К1алэр зыфэмсакъэмэ 1аегорэ '''щыш1ыщт'''",
            "translation": "If the boy won't be cautious something bad '''will happen to him'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəʃˈən"
  },
  {
    "word": "1елъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1элъын\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔajɬən"
  },
  {
    "word": "нахь къигъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to prefer",
        "examples": [
          {
            "sentence": "Сэ псыр '''нахь къесэгъэк1ы''' Колам",
            "translation": "'''I prefer''' water than cola."
          },
          {
            "sentence": "Адыгэбзэк1э тыгущы1энэ '''нахь къетэгъэк1ы'''",
            "translation": "'''We prefer''' speaking Circassian."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naːħ qəjʁakʼən"
  },
  {
    "word": "шъхьэк1афэ фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to respect",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħat͡ʃʼaːfa"
  },
  {
    "word": "шъхьэк1афэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "respect",
        "examples": []
      }
    ],
    "synonyms": [
      "лъытэныгъ",
      "шъхьэк1эфэныгъ"
    ],
    "type": "noun",
    "ipa": "ʂħat͡ʃʼaːfa"
  },
  {
    "word": "рэзэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэгъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "razaʁan"
  },
  {
    "word": "гъэрэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone satisfied, to please someone",
        "examples": [
          {
            "sentence": "Сыд '''узимыгъэзагъэрэ?'''",
            "translation": "What '''doesn't make you satisfied?'''"
          },
          {
            "sentence": "К1алэр '''угъэрэзэшъущта?'''",
            "translation": "'''Can you satisfied''' the boy?"
          },
          {
            "sentence": "К1алэм пшъашъэр '''игъэрэзагъ'''",
            "translation": "The boy '''satisfied''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁarazan"
  },
  {
    "word": "хэнэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to quit doing something, to get out of the habit (of), to break oneself of the habit (of)",
        "examples": [
          {
            "sentence": "Сэ тутын сешъоу '''хэснэжьыгъ'''",
            "translation": "'''I quit''' smoking cigarettes."
          },
          {
            "sentence": "А шэн 1аер '''хэнэжь'''",
            "translation": "'''Quit''' that bad behaving."
          },
          {
            "sentence": "Уи1эхъуамбэмэ уацэкъэу '''хэбнэжьын''' фай",
            "translation": "'''You must quit''' biting your teeth."
          },
          {
            "sentence": "'''Хэмынэжь''' ащтэу пш1эу",
            "translation": "'''Don't quit''' doing that."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xanaʑən"
  },
  {
    "word": "хэгъэгэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone quit doing something",
        "examples": [
          {
            "sentence": "Сэ тутын сешъоу '''сыхагъэнэжьэгъ'''",
            "translation": "'''They made me quit''' smoking."
          },
          {
            "sentence": "А шэн 1аер к1алэм '''хезгъэнэжьышт'''",
            "translation": "'''I will make''' that boy '''quit''' that bad behaving."
          },
          {
            "sentence": "си1эхъуамбэмэ зыгорэ тэсфэхьынэу щы1а зэрэсацакъэрэр '''хэсигъэжьынэу'''",
            "translation": "Is there something that I can smear on my fingers that '''will make me quit''' biting them."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʁanaʑən"
  },
  {
    "word": "хэгъэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make or find a partner for a romantic relationship.",
        "examples": [
          {
            "sentence": "Пшъашъэгорэ '''къыхэпхэщын''' фай",
            "translation": "'''You''' need '''to find''' a girlfriend."
          },
          {
            "sentence": "Гъусэ к1алэ дэхьэ '''къыхэпхэщыгъэба'''?",
            "translation": "'''Have you''' still '''not made''' a boyfriend?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaʁaɕən"
  },
  {
    "word": "нэпэшъоон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slap someone's face",
        "examples": [
          {
            "sentence": "'''Къысэнэпэшъуаох'''",
            "translation": "'''They are slapping me'''."
          },
          {
            "sentence": "К1алэм '''енэпэшъуао'''",
            "translation": "He/She '''is slapping''' the boy."
          }
        ]
      }
    ],
    "synonyms": [
      "нэпэон",
      "нэгушъо етын"
    ],
    "cognate": "нэпэфэуэн",
    "type": "verb",
    "ipa": "napaʃʷawan"
  },
  {
    "word": "лъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sharpen, to whet",
        "examples": [
          {
            "sentence": "ощыр '''лъын'''",
            "translation": "'''To sharpen''' the axe."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэчанын",
      "----"
    ],
    "type": "verb",
    "ipa": "ɬən"
  },
  {
    "word": "хэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to thrust into, to plunge into, to stick into",
        "examples": [
          {
            "sentence": "Шъэжъыер пц1эжъыем '''хэмыс'''",
            "translation": "'''Don't stick''' the fish with the knife."
          },
          {
            "sentence": "Чатэр '''хас''' ц1ыфым",
            "translation": "'''Thrust''' the sword into the person."
          }
        ]
      },
      {
        "meaning": "to place something into forcefully",
        "examples": [
          {
            "sentence": "Топыр джэгуалъэхэм к1алэм '''ахисагъ'''",
            "translation": "The boy '''placed''' the ball '''into''' the toys '''forcefully'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xasan"
  },
  {
    "word": "къэщыпын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pick up stuff, to gather stuff (usually by picking up, by raking up or by sweeping)",
        "examples": [
          {
            "sentence": "Мыжъохэр '''къэщып'''",
            "translation": "'''Pick up''' the rocks."
          },
          {
            "sentence": "К1алэм мыехэр '''къещыпых'''",
            "translation": "The boy '''is picking up''' the apples."
          },
          {
            "sentence": "Мыхэр '''къэмыщыпыгох'''",
            "translation": "'''Don't pick''' the apples '''for now'''"
          },
          {
            "sentence": "Бащэ '''къэпщыпнэу''' щы1эр",
            "translation": "There is to much '''to gather'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaɕəpən"
  },
  {
    "word": "фэш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for",
        "examples": [
          {
            "sentence": "Сиянэ '''фэш1''' тучанэм сызфак1орэ",
            "translation": "It is '''for''' my mother I am going to the shop."
          }
        ]
      },
      {
        "meaning": "because of",
        "examples": [
          {
            "sentence": "К1алэм '''фэш1''' пшъашъэр зыфэгъырэр",
            "translation": "It is '''because of''' the boy that the girl is crying."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "faʃʼ"
  },
  {
    "word": "фэш1ык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэш1к1э\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "faʃʼət͡ʃʼa"
  },
  {
    "word": "къэхьыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) whore",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaħəpa"
  },
  {
    "word": "лъэшъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drag something around",
        "examples": [
          {
            "sentence": "К1алэм 1анэр '''къелъэшъу'''",
            "translation": "The boy '''is dragging''' the table here."
          },
          {
            "sentence": "Тэ '''утлъэшъужьын''' фая?",
            "translation": "Do '''we''' need '''to drag you around'''?"
          },
          {
            "sentence": "'''Сымылъэшъуба''', сэ сизакъоу сыкъэк1ощт",
            "translation": "'''Don't drag me around''', I will come by myself."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬaʃʷən"
  },
  {
    "word": "рилъэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drag inside",
        "examples": [
          {
            "sentence": "Гъуанэ ш1уц1эм ик1оц1 '''урилъэшъощт'''",
            "translation": "A black hole '''will drag you inside'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "rəjɬaʃʷan"
  },
  {
    "word": "тхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear something off",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tχən"
  },
  {
    "word": "1эпэт1эрын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эпыт1эрын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapətʼarən"
  },
  {
    "word": "блэпк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump pass; to jump over",
        "examples": [
          {
            "sentence": "Псыхъом '''блэпк1'''",
            "translation": "'''Jump pass''' the river."
          },
          {
            "sentence": "Пхъэм к1алэр '''блэпк1ы'''",
            "translation": "The boy '''is pass jumping''' the wood."
          },
          {
            "sentence": "Унэм о '''ублэпк1ын''' лъэк1ыщтэп",
            "translation": "You can't '''jump over''' the house"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blapt͡ʃʼən"
  },
  {
    "word": "блэпк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блэпк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blaptkʲʼən"
  },
  {
    "word": "зэблэкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pass something",
        "examples": [
          {
            "sentence": "Гъогор к1алэм '''зэблекьы'''",
            "translation": "The boy '''is passing''' the road"
          },
          {
            "sentence": "Мы хэгъэгор сэхьатиблыгьэ '''зэблэпкьышъут'''",
            "translation": "'''You can pass''' the country with seven hours."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blapkʲən"
  },
  {
    "word": "гу е1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуе1ун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷjaʔʷən"
  },
  {
    "word": "шъхьэпэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to exceed",
        "examples": [
          {
            "sentence": "К1алэм ик1эхьыгъэр метерым '''шъхьэпэк1ы'''",
            "translation": "The boy's height '''exceeds''' a meter."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʂħapat͡ʃʼən"
  },
  {
    "word": "шъхьэпэк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэпэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħapakʲʼən"
  },
  {
    "word": "гык1ыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "laundry",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɣət͡ʃʼəʁ"
  },
  {
    "word": "егъэусыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэусыен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawsəjan"
  },
  {
    "word": "гъэусыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lean an object on something",
        "examples": [
          {
            "sentence": "Пчъэр дэпкъым к1алэм '''регъэусые'''",
            "translation": "The boy '''leas''' the door '''on''' the wall."
          },
          {
            "sentence": "1анэм '''земыгъэусый'''",
            "translation": "'''Don't lay''' on the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawsəhan"
  },
  {
    "word": "зэрихьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to commit, to do, to carry out",
        "examples": []
      }
    ],
    "synonyms": [
      "зэрихьан"
    ],
    "type": "verb",
    "ipa": "zariħan"
  },
  {
    "word": "дэзэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to treat someone like",
        "examples": [
          {
            "sentence": "К1алэм дахэу ц1ыфыхэр '''дэзак1ох'''",
            "translation": "The people '''are treating''' the boy nicely."
          },
          {
            "sentence": "1аеу к1алэм '''удэмызак1у'''",
            "translation": "'''Don't tread''' the boy badly."
          },
          {
            "sentence": "уц1ап1и 1аеу '''къыпдэзак1ох'''",
            "translation": "Because you are mean, '''they are treating you''' bad."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dazakʷʼan"
  },
  {
    "word": "зэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to act like, to behave like",
        "examples": [
          {
            "sentence": "К1алэр дахэу '''мэзак1о'''",
            "translation": "The boy '''is acting''' nicely."
          },
          {
            "sentence": "1аеу к1алэр '''мэзак1орэп'''",
            "translation": "The boy '''doesn't behave''' bad."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zakʷʼan"
  },
  {
    "word": "мэзэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэк1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zakʷʼan"
  },
  {
    "word": "хьа",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Abzakh dialect) why",
        "examples": [
          {
            "sentence": "'''Хьа''' укъэк1уагъэ?",
            "translation": "'''Why''' you came?"
          },
          {
            "sentence": "'''Хьа''' плъыжьы шъор уик1ас?",
            "translation": "'''Why''' you like the color red?"
          },
          {
            "sentence": "'''Хьа''' сыд шы1а?",
            "translation": "'''Why''' what there is?"
          }
        ],
        "tags": [
          "Abzakh dialect"
        ]
      }
    ],
    "synonyms": [
      "шъыда",
      "сыда",
      "щтэ",
      "----"
    ],
    "type": "adv",
    "ipa": "ħaː"
  },
  {
    "word": "хьаджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grind",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ħaːd͡ʒən"
  },
  {
    "word": "ыкъудыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to last",
        "examples": [
          {
            "sentence": "Фылымым бащэ '''ыкъудыигъ'''",
            "translation": "The movie '''lasted''' too much."
          },
          {
            "sentence": "Фылымым тхьапщ '''ыкъудыищтэр'''?",
            "translation": "For how much the movie '''will last'''?"
          }
        ]
      },
      {
        "meaning": "to pull",
        "examples": [
          {
            "sentence": "К1алэм к1апсэр '''ыкъудыи'''",
            "translation": "The boy '''is pulling''' the rope."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "əqʷədəjən"
  },
  {
    "word": "гъолагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъолагъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷaɮaːʁ"
  },
  {
    "word": "зэхэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collapse something",
        "examples": [
          {
            "sentence": "К1алэм анэр '''зэхик1ыгъ'''",
            "translation": "The boy '''collapsed''' the table."
          },
          {
            "sentence": "Унэр '''зэхэмык1'''",
            "translation": "Don't '''collapse''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxat͡ʃʼən"
  },
  {
    "word": "инатэу ихьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become very stubborn",
        "examples": [
          {
            "sentence": "К1алэр '''инатэу ихьагъ'''",
            "translation": "The boy '''became very stubborn'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jənaːtaw jəħan"
  },
  {
    "word": "ук1ыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "modesty, humility",
        "examples": [
          {
            "sentence": "[[шъуашэ",
            "translation": "Шъуашэмэ]] [[анахь]] [[дахэ]]р '''укIыт'''"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "wət͡ʃʼət"
  },
  {
    "word": "Джантый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Janty (name)",
        "examples": []
      }
    ],
    "type": "name",
    "ipa": "d͡ʒaːntəj"
  },
  {
    "word": "лъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "capability",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬat͡ʃʼn"
  },
  {
    "word": "лъэк1ь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэк1\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬakʼʲ"
  },
  {
    "word": "унэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to begin to whirl/spin (to the point of dizziness)",
        "examples": [
          {
            "sentence": "к1алэр '''мэуназэ'''",
            "translation": "The boy '''is getting dizzy'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wnazan"
  },
  {
    "word": "унэ1ут",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "servant; house serf; menial",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wənaʔʷət"
  },
  {
    "word": "мыст",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "uh (Space filler or pause during conversation)",
        "examples": [
          {
            "sentence": "Ащ иц1агъэ, '''мыст''', Аслъан",
            "translation": "His name was, '''uh''', Aslan."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "məst"
  },
  {
    "word": "къэгъэнэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to save",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''къигъэгэжьэгъ'''",
            "translation": "The boy '''saved''' the girl."
          },
          {
            "sentence": "К1алэр '''къэгъэнэжь'''",
            "translation": "'''Save''' the boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁanaʑən"
  },
  {
    "word": "мэсэкъатын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become out of order, to be ruined (usually machines)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "masaqaːtən"
  },
  {
    "word": "гъэсэкъатын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ruin something (usually machines), to defect",
        "examples": [
          {
            "sentence": "машинэр '''угъэсэкъатыгъ'''",
            "translation": "'''You ruined''' the car."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁasaqaːtən"
  },
  {
    "word": "фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to do something for someone",
        "examples": [
          {
            "sentence": "К1алэм унэ сэ '''фэсэш1ы'''",
            "translation": "'''I am doing''' a house '''for''' the boy."
          }
        ]
      },
      {
        "meaning": "to close something (to move so that an opening is closed)",
        "examples": [
          {
            "sentence": "К1алэм Радиор '''феш1ы'''",
            "translation": "The boy '''closes''' the radio."
          },
          {
            "sentence": "Хьэнэфые '''фэш1'''",
            "translation": "'''Close''' the water pipe."
          },
          {
            "sentence": "Шъутелефонхэ '''фэшъуш1ых'''",
            "translation": "'''Close''' your phones (said to plural)."
          }
        ]
      }
    ],
    "synonyms": [
      "игъэсэн",
      "зэфэш1ын"
    ],
    "cognate": "хуэщ1ын",
    "type": "verb",
    "ipa": "faʃʼən"
  },
  {
    "word": "егъэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone used to something",
        "examples": [
          {
            "sentence": "К1алэр '''егъас''' гъутхьэмэ ы1эхэр ылъэк1ыжьынэу",
            "translation": "'''Make''' the boy '''used to''' washing his hands when he urinates."
          }
        ]
      },
      {
        "meaning": "alternative form of \"гъэсэн\"",
        "examples": []
      },
      {
        "meaning": "alternative form of \"игъэсэн\"",
        "examples": []
      },
      {
        "meaning": "to close something",
        "examples": [
          {
            "sentence": "Пчъэр к1алэм '''регъасэ'''",
            "translation": "The boy '''closes''' the door."
          },
          {
            "sentence": "Пчъэр '''егъас'''",
            "translation": "'''Close''' the door."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁasan"
  },
  {
    "word": "изэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fully",
        "examples": [
          {
            "sentence": "Къаом псы '''изэу''' ыгъахъу",
            "translation": "Fill the bucket '''fully''' with water."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jəzaw"
  },
  {
    "word": "щэмэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scythe",
        "examples": []
      }
    ],
    "synonyms": [
      "щэмэгь"
    ],
    "cognate": "шэмэдж",
    "type": "noun",
    "ipa": "ɕamaɡʲʼ"
  },
  {
    "word": "зэхэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collapse",
        "examples": [
          {
            "sentence": "Унэр '''къызэхафэ'''",
            "translation": "The house is '''collapsing'''."
          }
        ]
      }
    ],

    "cognate": "зэхэхуэн",
    "type": "verb",
    "ipa": "zaxafan"
  },
  {
    "word": "зэхэутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break down from inside",
        "examples": [
          {
            "sentence": "Унэр '''къызэхэуты'''",
            "translation": "The house is '''breading down from inside'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxawtən"
  },
  {
    "word": "дэтэкъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw stuff into somewhere",
        "examples": [
          {
            "sentence": "Пхъэхэр моу '''дэтакъох'''",
            "translation": "'''Throw''' the woods here."
          },
          {
            "sentence": "Дэлъып1эм джанэхэр '''дэтэкъожьын'''",
            "translation": "'''Throw''' the clothes '''into''' the closet."
          },
          {
            "sentence": "К1алэм гъуч1ыхэр щагум '''детакъох'''",
            "translation": "The boy '''is throwing''' the irons '''into''' the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dataqʷan"
  },
  {
    "word": "дэтэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw stuff from somewhere",
        "examples": [
          {
            "sentence": "Пхъэхэр моу '''къыдэтакъух'''",
            "translation": "'''Throw''' the woods '''from''' here."
          },
          {
            "sentence": "Дэлъып1эм джанэхэр '''къыдэтэкъужьын'''",
            "translation": "'''Throw''' the clothes '''from''' the closet."
          },
          {
            "sentence": "К1алэм гъуч1ыхэр щагум '''къыдетакъух'''",
            "translation": "The boy '''is throwing''' the irons '''from''' the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dataqʷən"
  },
  {
    "word": "пщык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пчык1э\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕət͡ʃʼa"
  },
  {
    "word": "едзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mark",
        "examples": [
          {
            "sentence": "Х хьэрыфк1э '''едзыгъэр'''",
            "translation": "'''The marked''' in the letter x."
          },
          {
            "sentence": "Ц1эхэр '''едзых'''",
            "translation": "'''Mark''' the names."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jad͡ʒən"
  },
  {
    "word": "фыжьыбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bright skinned",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "fəʑəbz"
  },
  {
    "word": "ц1этхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1этхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼatχən"
  },
  {
    "word": "сымэджэдэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very sick",
        "examples": []
      }
    ],
    "synonyms": [
      "сымэгьэдэй"
    ],
    "type": "adj",
    "ipa": "səmad͡ʒadaj"
  },
  {
    "word": "псы тэгъэчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit or expel water at",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "psə taʁat͡ʂən"
  },
  {
    "word": "хэк1утэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spill something inside a liquid",
        "examples": [
          {
            "sentence": "Мыепсым псы '''къыхэк1ут'''",
            "translation": "'''Spill''' water on the apple-juice."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xakʷʼətan"
  },
  {
    "word": "шъхьэм къихьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "something to come to the mind",
        "examples": [
          {
            "sentence": "К1алэм '''ишъхьэ къихьагъ''' еджэнэу дэгъу",
            "translation": "Studying good '''came''' to the boy's '''mind'''."
          },
          {
            "sentence": "'''Сишъхьэ къихьагъ''' унэ сыш1ынэу",
            "translation": "'''It came to my mind''' to build a house."
          },
          {
            "sentence": "Сыд '''яшъхьэ къихьагъэр'''",
            "translation": "What '''came to their minds'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajt͡ʃʼan"
  },
  {
    "word": "къыщык1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lack something, to have something missing that is required",
        "examples": [
          {
            "sentence": "Доктор, к1алэм псы '''къыщэк1э'''",
            "translation": "Doctor, the boy '''lacks''' water."
          },
          {
            "sentence": "У л1ым ишъхьэ '''къыщэк1э'''",
            "translation": "That man '''have something missing''' in his head."
          },
          {
            "sentence": "'''Укъысщэк1э'''",
            "translation": "'''I need you'''."
          },
          {
            "sentence": "Зыгорэ '''къыпщык1эмэ''' къыса1у",
            "translation": "'''If you need something that is lacking''' tell me."
          },
          {
            "sentence": "Шхын '''къэтщэк1э'''",
            "translation": "'''We lack''' food."
          },
          {
            "sentence": "Л1ым зыгори '''къыщэк1эрэп'''",
            "translation": "The man '''doesn't lack''' anything."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəɕət͡ʃʼan"
  },
  {
    "word": "нынэп1эс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нынэп1ос\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nənapʼas"
  },
  {
    "word": "нынэп1ос",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stepmother",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nənapʷʼas"
  },
  {
    "word": "зэтелъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stack, to put objects on each other",
        "examples": [
          {
            "sentence": "К1алэм тхылъхэр '''зэтелъхьэх'''",
            "translation": "The boy '''is stacking''' the books."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zatajɬħan"
  },
  {
    "word": "тек1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to overcome (To win or prevail in some sort of battle, contest, etc)",
        "examples": [
          {
            "sentence": "К1алэр '''къэстек1о'''",
            "translation": "The boy '''is overcoming''' me."
          }
        ]
      },
      {
        "meaning": "to become greater than something else",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэмартын",
      "гъэпщэн"
    ],
    "type": "verb",
    "ipa": "tajkʷʼan"
  },
  {
    "word": "фэл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to die for something or someone",
        "examples": [
          {
            "sentence": "Ц1ыф горэхэр яш1ош1ухъунгъэ '''фэл1эщтых'''",
            "translation": "t=Some people '''will died''' for their belies."
          },
          {
            "sentence": "Ц1ыфэр '''зыфэл1агъэр''' узым фэш1",
            "translation": "t='''The reason''' the person '''died was''' because of the illness."
          }
        ]
      },
      {
        "meaning": "(slang) to love something or someone very much",
        "examples": [
          {
            "sentence": "Сэ шхын 1эш1умэ '''сафал1э'''",
            "translation": "t='''I love''' sweet foods."
          },
          {
            "sentence": "К1алэр пшъашъэм '''фал1э'''",
            "translation": "t=The boy '''loves''' the girl."
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "faɬʼan"
  },
  {
    "word": "зэгощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stretch",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaɡʷaɕən"
  },
  {
    "word": "ч1ышъутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run and climb up",
        "examples": [
          {
            "sentence": "К1алэр чъыгым '''ч1ышъутэгъ'''",
            "translation": "t=The boy '''ran and climbed up''' the tree."
          }
        ]
      },
      {
        "meaning": "to run off upwards, to flee upwards",
        "examples": [
          {
            "sentence": "К1алэр 1ушъхьэм '''ч1ышъуты'''",
            "translation": "t=The boy '''is running up''' the mound."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼəʃʷtən"
  },
  {
    "word": "ч1эшъутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run off/flea from under something",
        "examples": [
          {
            "sentence": "К1алэр унэ ч1эгъым '''къы1эшъуты'''",
            "translation": "t=The boy '''running away''' from under the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼaʃʷtən"
  },
  {
    "word": "дыхьэщхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дэхьащхын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гуш1он"
    ],
    "cognate": "дыхьэшхын",
    "type": "verb",
    "ipa": "dəħaɕxən"
  },
  {
    "word": "щыдыхьэщхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щыдэхьащхын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "щыгуш1ук1ьын"
    ],
    "cognate": "щыдыхьэшхын",
    "type": "verb",
    "ipa": "ɕədəħaɕxən"
  },
  {
    "word": "щыдэхьащхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to laugh at someone",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''щыдэхьащхы'''",
            "translation": "The boy '''is laughing at''' the girl."
          },
          {
            "sentence": "'''Укъызщыдэхьащха?'''",
            "translation": "'''Are you laughing at me?'''"
          }
        ]
      }
    ],

    "cognate": "щыдыхьэшхын",
    "type": "verb",
    "ipa": "ɕədəħaɕxən"
  },
  {
    "word": "гупсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гупшысэн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəpsan"
  },
  {
    "word": "фэшъуашэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "appropriately/accordingly as one deserves; deservingly",
        "examples": [
          {
            "sentence": "Къинэу мэлажьэрэм '''фэшъуашэу''' ахъщэ къыратыщт",
            "translation": "One that works hard will get money '''appropriately'''"
          },
          {
            "sentence": "К1алэр '''фэшъуашэу''' къеожьыгъэх",
            "translation": "They hit the boy '''accordingly as he deserves'''."
          },
          {
            "sentence": "Уасэу утырэм '''фэшъуашэу''' къэпщэфырэр дэгъущт",
            "translation": "The thing you buy will be good '''accordingly''' on the price you pay."
          }
        ]
      },
      {
        "meaning": "accordingly",
        "examples": [
          {
            "sentence": "Пый дзэр зэрэзак1орэм '''фэшъуашэу''' шъори шъухъый",
            "translation": "You (pl.) move '''accordingly''' to how the enemy army behaves."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "faʃʷaːʃaw"
  },
  {
    "word": "иш1уагъэ ек1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"иш1уагъэ ек1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəʃʷʼaːʁa jakʲʼən"
  },
  {
    "word": "иш1уагъэ ек1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to benefit from it",
        "examples": [
          {
            "sentence": "К1алэр '''иш1уагъэ къигъэк1ыщтэп'''",
            "translation": "'''You will not benefit''' from the boy."
          },
          {
            "sentence": "Бэрэ уеджэмэ '''иш1уагъэ къыигъэк1ыщт'''",
            "translation": "Studying a lot '''will benefit you'''."
          },
          {
            "sentence": "К1алэм тхылъым '''иш1уагъэ ек1ы'''",
            "translation": "The boy '''is benefiting from ''' the book."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʃʷʼaːʁa jat͡ʃʼən"
  },
  {
    "word": "тэгъэпсэхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to set",
        "examples": [
          {
            "sentence": "Сэхьатыр щым '''тэгъэпсахь'''",
            "translation": "'''Set''' the clock to three."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "taʁapsaħaːn"
  },
  {
    "word": "к1оц1ылъхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼat͡sʼəɬħan"
  },
  {
    "word": "ридзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"едзын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "rəjd͡ʒən"
  },
  {
    "word": "къозын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be dropped behind something",
        "examples": []
      },
      {
        "meaning": "to appear suddenly and surprisingly",
        "examples": [
          {
            "sentence": "К1алэр '''къозыгъ'''",
            "translation": "The boy '''appeared suddenly and surprisingly'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qʷazən"
  },
  {
    "word": "гъэщтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to freeze",
        "examples": [
          {
            "sentence": "Псыр к1алэм '''егъэщты'''",
            "translation": "The boy '''is freezing''' the water."
          },
          {
            "sentence": "Хьэлэгъор '''гъэщты'''",
            "translation": "'''Freeze''' the bread."
          },
          {
            "sentence": "К1алэм хьэлэгъухэр '''егъэщтых'''",
            "translation": "The boy '''is freezing''' the breads."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɕtən"
  },
  {
    "word": "мэщтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щтын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maqən"
  },
  {
    "word": "хэщтыхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be totally frozen",
        "examples": [
          {
            "sentence": "Иныжъэр осым '''хэщтыхьыгъ'''",
            "translation": "The giant '''was frozen''' in the snow"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaqaħən"
  },
  {
    "word": "щтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be frozen",
        "examples": [
          {
            "sentence": "Хьэлэгъор '''мэкъы'''",
            "translation": "The bread '''is freezing'''."
          },
          {
            "sentence": "Чэщым '''сыкъэу''' сыхэт",
            "translation": "'''I am''' standing at the night '''freezing'''."
          },
          {
            "sentence": "К1алэр '''мэкъы''', чъы1эм хэт",
            "translation": "The boy '''is freezing''', he is in the cold."
          },
          {
            "sentence": "Хьэлэгъор '''мэкъыгъа?'''",
            "translation": "'''Did''' the bread''' froze'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕtən"
  },
  {
    "word": "тыкъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Adam's apple",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "təqən"
  },
  {
    "word": "к1эгъэгъожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone regret",
        "examples": [
          {
            "sentence": "к1алэм '''сык1игъэгъожьыгъ'''",
            "translation": "The boy '''made me regret it'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʁaʁʷaʑən"
  },
  {
    "word": "иджы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джы\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jəd͡ʒə"
  },
  {
    "word": "к1ьэрыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "worthless",
        "examples": [
          {
            "sentence": "Мащинэ '''к1ьэрыт'''",
            "translation": "'''Bad''' car."
          },
          {
            "sentence": "Пчъэр '''к1ьэрытэу''' хъугъэ",
            "translation": "The door became '''bad'''."
          },
          {
            "sentence": "Ц1ыф '''к1ьэрыт'''",
            "translation": "'''Worthless''' person."
          },
          {
            "sentence": "Гонахьхэр '''умыгъэк1ьэрытых'''",
            "translation": "'''Don't make''' sins '''worthless''' (Don't take sins lightly)"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "kʲʼarət"
  },
  {
    "word": "ш1угъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "goodness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼəʁa"
  },
  {
    "word": "дэигъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "badness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dajəʁa"
  },
  {
    "word": "дэк1оцэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put or push someone or an object forcefully against something",
        "examples": [
          {
            "sentence": "Мы тхылъ к1эр удэ тхылъ жъымэ '''ядэк1уац'''",
            "translation": "'''Put''' this new book there '''against''' the old books."
          },
          {
            "sentence": "Дэпкъым '''сыдэбэк1уацэ'''",
            "translation": "'''You are pushing me against''' the wall."
          },
          {
            "sentence": "Тхылъхэр '''зэдэк1оцагъэх'''",
            "translation": "The books '''are compressed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dakʷʼat͡san"
  },
  {
    "word": "сапэм ч1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go to waste,",
        "examples": [
          {
            "sentence": "К1алэр еш1агъэ купэр '''сапэм ч1ык1ыгъ'''",
            "translation": "All the things the boy did '''gone to waste'''."
          },
          {
            "sentence": "Узеджагъ купэ '''сапэм ч1ык1ыщт''' экзаменым укъимык1ымэ",
            "translation": "All your studies '''will go to waste''' if you don't pass the exam."
          },
          {
            "sentence": "Л1ым тхьэмафэм гонахьэу ылэжьыгъэхэмэ ипсапэ купэ '''сапэм ч1ыгъэк1ыгъэх'''",
            "translation": "The sins the man did in the week, '''wasted''' all the good deeds he done."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "saːpam t͡ʃʼəkʲʼən"
  },
  {
    "word": "джыхэлъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэлъэдэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxaɬadan"
  },
  {
    "word": "джыхэк1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэк1отэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaxakʷʼatan"
  },
  {
    "word": "джыхэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxawan"
  },
  {
    "word": "джыхэупс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэупц1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxawpsʼan"
  },
  {
    "word": "хэупс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэупц1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xawpsʼan"
  },
  {
    "word": "хэупц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw an object against something forcefully",
        "examples": [
          {
            "sentence": "К1алэм апчыр дэпкъым '''хиупц1и''' икъутагъ",
            "translation": "The boy '''smashed''' the glass '''against''' the wall and broke it to pieces."
          }
        ]
      },
      {
        "meaning": "to stain forcefully, to make a mark on a surface or a piece of clothing forcefully",
        "examples": [
          {
            "sentence": "Шъохэр фыжьы дэпкъым '''хэмыупц1эх'''",
            "translation": "'''Don't stain''' the colors '''on''' the white wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xawpt͡sʼan"
  },
  {
    "word": "къутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break (can be repaired)",
        "examples": [
          {
            "sentence": "К1алэм 1анэр '''ыкъутагъ'''",
            "translation": "The boy '''broke''' the table."
          },
          {
            "sentence": "Ушхымэ уинэк1ы '''ыкъутэщт'''",
            "translation": "If you eat '''it will break''' your fest."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qʷətan"
  },
  {
    "word": "джыхэхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жэхэхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒəxaħan"
  },
  {
    "word": "жэхэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collide with, to smash into",
        "examples": [
          {
            "sentence": "К1алэр дэпкъым '''жэхэхьагъ'''!",
            "translation": "The boy '''collided with''' the wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʒaxaħan"
  },
  {
    "word": "шъхьацышъо мэтэджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have chills",
        "examples": [
          {
            "sentence": "'''Сишъхьацышъо огъэтэджы'''",
            "translation": "'''You give me the chills'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʂħaːt͡səʃʷa matad͡ʒən"
  },
  {
    "word": "дыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become sore (sore muscle)",
        "examples": [
          {
            "sentence": "С1э '''дыигъ'''",
            "translation": "My arm '''became aching'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dəjən"
  },
  {
    "word": "утэцэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to numb, to tingle (body-part); to feel a prickling or mildly stinging sensation",
        "examples": [
          {
            "sentence": "С1э '''утэцагъ'''",
            "translation": "My arm '''tingled'''."
          }
        ]
      }
    ],
    "synonyms": [
      "утэбжьэн"
    ],
    "type": "noun",
    "ipa": "wətat͡san"
  },
  {
    "word": "-к1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) A person that does an action indicated by the root verb; used to form an agent noun.",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "suffix",
    "ipa": "kʷʼa"
  },
  {
    "word": "шэхьэтэу къытеуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шыхьатэу теуцон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃaħataw qətajwt͡sʷan"
  },
  {
    "word": "мыхъумыбз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hermaphrodite",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məχʷəməbz"
  },
  {
    "word": "шъхьэк1э къэ1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to say something clearly while being fully self aware",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħat͡ʃʼa qaʔʷan"
  },
  {
    "word": "хьэлъэбакъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэубакъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaɬawbaːq"
  },
  {
    "word": "хьэлъэбакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэубакъу\"",
        "examples": []
      }
    ],

    "cognate": "лъэбакъуэ",
    "type": "noun",
    "ipa": "ħaɬawbaːq"
  },
  {
    "word": "1ук1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эхьан\"",
        "examples": [
          {
            "sentence": "К1алэр л1ым '''1ук1эхьагъ'''",
            "translation": "The boy '''caught up with''' the man."
          },
          {
            "sentence": "Нэхь псынч1эу сычъэмэ '''ус1ук1эхьэщт'''",
            "translation": "If I will run faster '''I will overtake''' you."
          },
          {
            "sentence": "К1элэц1к1ур '''къыс1ук1эхьашъурэп'''",
            "translation": "The little boy '''can't catch up''' with me."
          },
          {
            "sentence": "Ливилк1э Томэр '''къыс1ук1эхьагъ'''",
            "translation": "Tom '''caught up and surpassed''' me with the level."
          },
          {
            "sentence": "Ливилк1э Томым '''сы1ук1эхьагъ'''",
            "translation": "'''I caught up and surpassed''' Tom with the level."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷət͡ʃʼaħaːn"
  },
  {
    "word": "тел1эхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall in love with someone",
        "examples": []
      }
    ],
    "synonyms": [
      "тенэц1эхьын"
    ],
    "type": "noun",
    "ipa": "tajɬʼaħən"
  },
  {
    "word": "тенэц1эхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall in love with someone",
        "examples": []
      }
    ],
    "synonyms": [
      "тел1эхьын"
    ],
    "type": "noun",
    "ipa": "tajnat͡sʼaħən"
  },
  {
    "word": "пыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have the smell of; to emit smell",
        "examples": [
          {
            "sentence": "Зыгъэпсык1и, мэ 1эш1у '''къызпыгъэу'''",
            "translation": "Take a shower and '''make yourself smell''' good."
          },
          {
            "sentence": "К1алэм шъумэ '''пэу'''",
            "translation": "The boy '''has''' a bad smell."
          },
          {
            "sentence": "К1алэм гъомэ 1эш1у '''пэу'''",
            "translation": "The boy '''has''' a good smell."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəwən"
  },
  {
    "word": "гъомэ 1эш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "great smell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷama ʔaʃʷʼ"
  },
  {
    "word": "1отэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tell",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔʷatan"
  },
  {
    "word": "тхьэ 1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to swear to god",
        "examples": [
          {
            "sentence": "'''Тхьэ сэ1о''' сыш1эрэп",
            "translation": "'''I swear to god''', I don't know"
          },
          {
            "sentence": "'''Тхьэ къа1у''' зэрэумыш1эрэр",
            "translation": "'''Swear to god''', that you don't know."
          },
          {
            "sentence": "Делэк1э ар '''тхьэ пфэс1он'''",
            "translation": "'''I swear''' he is a fool."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tħa ʔʷan"
  },
  {
    "word": "дэхьащх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "laugh",
        "examples": []
      }
    ],

    "cognate": "дыхьэшх",
    "type": "noun",
    "ipa": "daħaːɕx"
  },
  {
    "word": "щхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to laugh",
        "examples": [
          {
            "sentence": "К1алэр '''мэщхы'''",
            "translation": "The boy '''is laughing'''."
          },
          {
            "sentence": "О лъэшэу '''узэрэщхырэр'''' – арэущтэу лъэшыщэу '''умыщх!'''",
            "translation": "You '''laugh''' so loudly – '''don’t laugh''' so loudly!"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕxən"
  },
  {
    "word": "гъэпхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэпхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁapxən"
  },
  {
    "word": "егъэпхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to relate to",
        "examples": [
          {
            "sentence": "Шъхьэнгъупчъыр зэрэубэтагъыр сиянэ '''къысегъэпхы'''",
            "translation": "My mother '''is relates me to''' how the window was broken."
          },
          {
            "sentence": "Тыгъуасэ хъугъэмрэ непэ хъугъэмрэ '''зэбгъэпхышъущтыхэп'''",
            "translation": "'''You can't relate''' what happened yesterday '''to''' what happened today."
          },
          {
            "sentence": "Къап1орэ т1ур '''зэгъэпхыгъэхэп'''",
            "translation": "The two things you say '''are not related'''."
          }
        ]
      },
      {
        "meaning": "to tie to",
        "examples": [
          {
            "sentence": "Матиматикэр физикам '''епгъэпхышъущт'''",
            "translation": "'''You can tie''' mathematics '''to''' physics."
          },
          {
            "sentence": "Л1ыр зэрэгумэк1ырэ '''есэгъэпхы''' яунэ щыхъурэм",
            "translation": "'''I tie''' the man's sadness '''to''' what happens in his house."
          },
          {
            "sentence": "Уишъхьэ узы '''емыгъэпх''' хьабэу ущыхырэм, ар учъыерэпи ары.",
            "translation": "'''Don't tie''' your headache to the pills you're eating, it is because you aren't sleeping."
          }
        ]
      },
      {
        "meaning": "to make someone depends on",
        "examples": [
          {
            "sentence": "Уцы бащэ ушхымэ, уесэни, '''уегъэпхыгъэу''' ухъущт",
            "translation": "If you eat too much plants, you will get used to it, and you will get '''depends on it'''."
          }
        ]
      },
      {
        "meaning": "to tie up something/someone to something",
        "examples": [
          {
            "sentence": "К1алэм 1анэм к1апсэк1э '''сыригъэпхыгъ'''",
            "translation": "The boy '''tied me up''' to the table using a rope."
          },
          {
            "sentence": "Хьэр пхъэм к1апсэк1э '''егъэпхыгъ'''",
            "translation": "The dog '''is tied up''' to the wood."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁapxən"
  },
  {
    "word": "чэщтеу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nightmare",
        "examples": []
      },
      {
        "meaning": "sleep paralysis",
        "examples": []
      }
    ],

    "cognate": "жэщтеу",
    "type": "noun",
    "ipa": "t͡ʃaɕtajw"
  },
  {
    "word": "чэщтао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэщтеу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaɕtaːwa"
  },
  {
    "word": "къихын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to earn something in a contest / competition",
        "examples": [
          {
            "sentence": "К1алэм 100 акзамыным '''къырихыгъ'''",
            "translation": "The boy '''earned''' 100 in the exam."
          },
          {
            "sentence": "Мэдале дышъэ '''къисхыгъ'''",
            "translation": "'''I won''' a gold medal."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəjxən"
  },
  {
    "word": "къэгъэхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to earn",
        "examples": [
          {
            "sentence": "1офымк1э ахъщэ '''къагъахъэ'''",
            "translation": "People '''earn''' money from work."
          },
          {
            "sentence": "1офымк1э ахъщэ бэ '''къэсэгъахъэ'''",
            "translation": "'''I earn''' a lot of money in work."
          },
          {
            "sentence": "Л1ым ахъщэ бэ '''къегъахъэ'''",
            "translation": "The man '''is earning''' a lot of money."
          },
          {
            "sentence": "Ахъщэ '''къэсгъэхъагъ'''",
            "translation": "'''I earned''' money."
          },
          {
            "sentence": "Дышъэхэ '''сэгъахъэх'''",
            "translation": "'''I am earning''' golds."
          },
          {
            "sentence": "'''Къэсфэгъахъ''' 1элъын дышъэ",
            "translation": "'''Earn me''' a golden ring."
          },
          {
            "sentence": "Ахъщэ '''къэсфэгъэхъагъэп'''",
            "translation": "'''I couldn't earn''' money."
          },
          {
            "sentence": "Тхьэм игубжь '''угъэхъэщт''' гонахь улыжьымэ",
            "translation": "If you do sin '''you will earn''' god's anger."
          },
          {
            "sentence": "К1алэм гонахь '''егъахъэ'''",
            "translation": "The boy '''is earning''' a sin (The boy is sinning)."
          }
        ]
      },
      {
        "meaning": "to get ahold of a certain possession",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaʁaχan"
  },
  {
    "word": "ц1ыфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "morality",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʼəfəʁa"
  },
  {
    "word": "псэонгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "health",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psawanʁa"
  },
  {
    "word": "къиухын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to end",
        "examples": [
          {
            "sentence": "Фылымыр '''къиухыгъ'''",
            "translation": "The film '''ended'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəjwxən"
  },
  {
    "word": "кок1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lap",
        "examples": [
          {
            "sentence": "К1элэц1ык1ор и янэ и '''кок1ы''' исэгъ",
            "translation": "The little boy was sitting in his mother's '''lap'''."
          }
        ]
      },
      {
        "meaning": "midst",
        "examples": [
          {
            "sentence": "Илъэсым '''икок1ык1э''' уеджагъа?",
            "translation": "Did you studied '''in the midst''' of the year?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "kʷat͡ʃʼ"
  },
  {
    "word": "гуп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "facade",
        "examples": []
      },
      {
        "meaning": "front",
        "examples": [
          {
            "sentence": "Унэ '''гупэм''' къэгъагъэхэр 1утых",
            "translation": "There are flowers '''in front of''' the house."
          },
          {
            "sentence": "Унэм '''ыгупи''' ык1ыби фыжьыбзэу гъэлагъэ",
            "translation": "The house's '''front''' and back are colored in white."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəp"
  },
  {
    "word": "гупц1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kindhearted",
        "examples": []
      },
      {
        "meaning": "open-hearted",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəpt͡sʼaːna"
  },
  {
    "word": "пшъэшъэжъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "little girl",
        "examples": []
      }
    ],

    "cognate": "хъыджэбз",
    "type": "noun",
    "ipa": "pʂaʂaʐəj"
  },
  {
    "word": "тыгъуасэнахьыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тыгъоснахьыпэ\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "tʁʷasanaħpa"
  },
  {
    "word": "пыупк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut off (hair, tree)",
        "examples": [
          {
            "sentence": "К1алэм хьалыгъу такъыр '''пеупк1ы'''",
            "translation": "The boy '''cuts''' a piece of bread."
          },
          {
            "sentence": "К1алэм '''пеупк1ы''' чъыгыр",
            "translation": "The boy '''is cutting off''' the tree."
          },
          {
            "sentence": "Сшъхьэц '''пысыук1ыщт''' т1эк1умаш1эмэ",
            "translation": "'''I will cut''' my hair soon."
          },
          {
            "sentence": "Слъэбжъанэхэр '''пысыупк1ын''' фаех",
            "translation": "'''I''' need '''to cut''' my toenails."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəwpt͡ʃʼən"
  },
  {
    "word": "нэчыхьытх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nakahitkh (marriage registration ceremony)",
        "examples": [
          {
            "sentence": "'''нэчыхьытх''' непэ щы1",
            "translation": "There is '''a nakahitkh''' today."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nat͡ʃəħətx"
  },
  {
    "word": "нэкьыхьытх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэчыхьытх\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʲəħətx"
  },
  {
    "word": "мэцыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цыгъон\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mat͡səʁʷan"
  },
  {
    "word": "ецыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цыгъон\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jat͡səʁʷan"
  },
  {
    "word": "цыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bite (small bite)",
        "examples": [
          {
            "sentence": "цыгъоц1ык1ор '''къысэцыгъо'''",
            "translation": "The little mouse '''is biting me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡səʁʷan"
  },
  {
    "word": "щыгъэ1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to let someone or something to exist",
        "examples": []
      },
      {
        "meaning": "to let someone or something to live",
        "examples": [
          {
            "sentence": "К1алэм тыгъужъхэр ыук1ыхи яшыр ц1ык1ухэр '''щыгъэ1агъэх'''.",
            "translation": "The boy killed the wolfs, and '''let''' their little cubs '''to live'''."
          },
          {
            "sentence": "Фылымымк1э л1ыр '''щагъа1эрэп'''.",
            "translation": "In the film, '''they do not left''' the man '''to live'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕəʔan"
  },
  {
    "word": "лэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cultivate",
        "examples": []
      },
      {
        "meaning": "to practice (To perform or observe in a habitual fashion)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɮaʑən"
  },
  {
    "word": "дэк1огъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "marriageable age for woman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dakʷʼaʁʷ"
  },
  {
    "word": "къэщэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "marriageable age for man",
        "examples": []
      },
      {
        "meaning": "the time of marriage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaɕaʁʷ"
  },
  {
    "word": "дзап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "base",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡zaːpʼa"
  },
  {
    "word": "къы1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəʔaxən"
  },
  {
    "word": "гузэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "center",
        "examples": [
          {
            "sentence": "Чылэ '''гузэгу'''",
            "translation": "'''Center of''' the village."
          }
        ]
      },
      {
        "meaning": "middle",
        "examples": [
          {
            "sentence": "Мэфэ '''гузэгу'''",
            "translation": "'''Middle of''' the day."
          },
          {
            "sentence": "Сэ '''ыгузэгук1э''' сыщысынэу сыфай",
            "translation": "I want to sit '''in the middle'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пчэгу"
    ],
    "type": "noun",
    "ipa": "ɡʷəzaɡʷ"
  },
  {
    "word": "пчэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "middle (centre, midpoint)",
        "examples": [
          {
            "sentence": "Мэфэ '''пчэгу'''",
            "translation": "'''Middle of''' the day."
          }
        ]
      },
      {
        "meaning": "circle area",
        "examples": []
      }
    ],
    "synonyms": [
      "гузэгу",
      "гупч",
      "утыку"
    ],
    "type": "noun",
    "ipa": "pt͡ʃaɡʷ"
  },
  {
    "word": "унашъо фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bring to attention for someone",
        "examples": [
          {
            "sentence": "К1алэм о '''уиунашъо къэсфиш1ыгъ'''",
            "translation": "The boy '''mentioned you to me'''."
          },
          {
            "sentence": "Л1ыжъым '''иунашъо фэмыш1''' к1алэм",
            "translation": "'''Do not mention''' the old man '''to the''' boy."
          },
          {
            "sentence": "К1алэм '''иунашъо фэш1''' зэрэеджэн фаер",
            "translation": "'''Mention to''' the boy that he needs to study."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wənaːʃʷa faʃʼən"
  },
  {
    "word": "унашъо ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"иунашъо ш1ын\"",
        "examples": []
      },
      {
        "meaning": "to order, to make arrangements, to see to it (that)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wənaːʃʷa ʃʼən"
  },
  {
    "word": "щытхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to compliment, to praise",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''къыщэтхъу'''",
            "translation": "The boy '''is praising''' the girl."
          },
          {
            "sentence": "Ц1ыфыхэр '''къэсщэтхъух'''",
            "translation": "People '''are praising me'''."
          }
        ]
      },
      {
        "meaning": "to brag about",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕətχʷən"
  },
  {
    "word": "къыч1эгъэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reveal; to uncover; to show and display that which was hidden.",
        "examples": [
          {
            "sentence": "Л1ыр зэрэбзаджэр '''къыч1эзгъэщыгъ'''",
            "translation": "'''I revealed''' that the man is evil."
          }
        ]
      },
      {
        "meaning": "to expose",
        "examples": [
          {
            "sentence": "Л1ыр хэпц1ахьэу '''къыч1агъэщыгъ''' джэгумк1э",
            "translation": "'''They exposed''' the man for cheating in the game."
          },
          {
            "sentence": "К1алэм и1эхэ '''къыч1егъэщых'''",
            "translation": "The boy '''exposes''' his arms."
          }
        ]
      },
      {
        "meaning": "to find out",
        "examples": [
          {
            "sentence": "К1алэм иятэ хэт '''къыч1эзгъэщыгъ'''",
            "translation": "'''I found out''' who is the boy's father."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qət͡ʂʼaʁaɕən"
  },
  {
    "word": "къыч1эщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be exposed",
        "examples": [
          {
            "sentence": "Л1ыр и ныбэ '''къыч1эщы'''",
            "translation": "The man's belly '''is exposed'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qət͡ʂʼaɕən"
  },
  {
    "word": "лъэкъопц1анэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a kick for stumbling someone, a kick to trip over someone",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaqʷapt͡sʼaːna"
  },
  {
    "word": "къы1эпхъотэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to snatch",
        "examples": [
          {
            "sentence": "К1алэм джэгуалъэр пшъашъэм '''къы1ипхъотагъ'''",
            "translation": "The boy '''snatched''' the toy from the girl."
          },
          {
            "sentence": "К1алэм джэгуалъэр '''къэс1епхъотэ'''",
            "translation": "The boy '''snatched''' the toy '''from me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəʔapχʷatan"
  },
  {
    "word": "1эпхъотэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къы1эпхъотэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔapχʷatan"
  },
  {
    "word": "лэгъунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "room",
        "examples": []
      }
    ],
    "synonyms": [
      "пэщ",
      "----"
    ],
    "type": "noun",
    "ipa": "ɮaʁʷna"
  },
  {
    "word": "фегъэпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to care for",
        "examples": [
          {
            "sentence": "К1алэм '''сыфегъапэ'''",
            "translation": "'''I care for''' the boy."
          },
          {
            "sentence": "К1алэр пшъашъэм '''фегъапэ'''",
            "translation": "The boy '''cares for''' the girl."
          },
          {
            "sentence": "К1алэр иянэ тхьэмык1эм '''фегъапэ'''",
            "translation": "The boy '''cares''' for his poor mother."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fajʁapan"
  },
  {
    "word": "1э къэ1этын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to surrender",
        "examples": [
          {
            "sentence": "'''Си1э къэсэ1эты'''",
            "translation": "'''I surrender'''."
          }
        ]
      },
      {
        "meaning": "to give up",
        "examples": [
          {
            "sentence": "К1алэр емыджэшъоу зэхъум '''и1э къи1этыгъ'''",
            "translation": "When the boy couldn't study, '''he gave up'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔa qaʔatən"
  },
  {
    "word": "хъэдэн ц1ынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χadan t͡sʼəna"
  },
  {
    "word": "к1эрэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whirl, to twirl",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼarawan"
  },
  {
    "word": "къуапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "angle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaːpa"
  },
  {
    "word": "пакъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pug nose",
        "examples": []
      },
      {
        "meaning": "snub-nosed",
        "examples": []
      },
      {
        "meaning": "bluntnose",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːq"
  },
  {
    "word": "гъэш1эгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэш1эгъоны\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэш1эгъоны"
    ],
    "cognate": "гъэщ1эгъуэн",
    "type": "adj",
    "ipa": "ʁaʃʼaəʁʷan"
  },
  {
    "word": "сагъындакъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quiver (case for holding arrows)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːʁəndaːq"
  },
  {
    "word": "гуща1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гущы1э\"",
        "examples": []
      }
    ],

    "cognate": "псалъэ",
    "type": "noun",
    "ipa": "ɡʷəɕaːʔa"
  },
  {
    "word": "е1эзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to help someone medically",
        "examples": [
          {
            "sentence": "Докторыр ц1ыфыхэмэ '''я1азэ'''",
            "translation": "The doctor helps people medically."
          },
          {
            "sentence": "Докторыр сэ '''къысэ1азэ'''",
            "translation": "The doctor helps me medically."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʔazan"
  },
  {
    "word": "пкъыгъуац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noun",
        "examples": []
      }
    ],

    "cognate": "щы1эц1э",
    "type": "noun",
    "ipa": "pqəʁʷaːt͡sʼ"
  },
  {
    "word": "ц1эпапк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pronoun",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʼapaːpt͡ʃʼ"
  },
  {
    "word": "хэлъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie somewhere within a mass (gas, solid or liquid)",
        "examples": [
          {
            "sentence": "пIэм '''хэлъын'''",
            "translation": "'''to lie''' in bed."
          }
        ]
      },
      {
        "meaning": "to be situated",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaɬən"
  },
  {
    "word": "гъэкощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to relocate",
        "examples": []
      },
      {
        "meaning": "to move something to somewhere else",
        "examples": [
          {
            "sentence": "К1алэм 1анэр '''егъэкощы'''",
            "translation": "The boy '''is moving''' the table ('''to somewhere else''')."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁakʷaɕən"
  },
  {
    "word": "кощын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to migrate",
        "examples": []
      },
      {
        "meaning": "to nomadize (to be a nomad)",
        "examples": []
      },
      {
        "meaning": "to move to another place",
        "examples": [
          {
            "sentence": "Л1ыр '''мэкощы'''",
            "translation": "The man '''is moving to another place'''."
          }
        ]
      },
      {
        "meaning": "to roam from place to place",
        "examples": []
      },
      {
        "meaning": "to be relocated",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷaɕən"
  },
  {
    "word": "ук1ьэпхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1эпхъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wkʲʼapχan"
  },
  {
    "word": "зэребылэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "as one pleases",
        "examples": [
          {
            "sentence": "К1алэм '''зэребылэу''' унэр еш1ы",
            "translation": "The boy is building the house '''as he pleases'''."
          },
          {
            "sentence": "'''Узэребылэу''' к1о",
            "translation": "Go '''as you please'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "zarajbəɮaw"
  },
  {
    "word": "пыкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пычын\"",
        "examples": []
      }
    ],

    "cognate": "пычын",
    "type": "verb",
    "ipa": "pəkʲən"
  },
  {
    "word": "1апл1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hug",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːpɬʼa"
  },
  {
    "word": "жэхэк1оцэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smash",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒaxakʷʼat͡san"
  },
  {
    "word": "щае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tea",
        "examples": []
      }
    ],
    "synonyms": [
      "щаибжъэ"
    ],
    "cognate": "шае",
    "type": "noun",
    "ipa": "ɕaːja"
  },
  {
    "word": "шхъуат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъуант1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэушхъонтын"
    ],
    "type": "noun",
    "ipa": "ʃχʷaːtʼa"
  },
  {
    "word": "джыхашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэхашъо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒəxaːʃʷa"
  },
  {
    "word": "гущы1эрые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "talkative, a person that tend to talk a lot",
        "examples": [
          {
            "sentence": "Сишы '''гущы1эрые'''",
            "translation": "My brother is '''talkative'''."
          }
        ]
      },
      {
        "meaning": "loquacious",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1ал",
      "1оржъор",
      "жэмы1ан",
      "жэмачыу",
      "шъхьарыгущы1"
    ],
    "type": "adj",
    "ipa": "ɡʷəɕəʔarəja"
  },
  {
    "word": "1эпхъощ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clumsy, a person that tend to drop stuff",
        "examples": [
          {
            "sentence": "Зэрэсы'''1эпхъощ'''ыр",
            "translation": "I am so '''clumsy'''."
          },
          {
            "sentence": "Сиянэ '''1эпхъощ''', и1ыгъэ купэр къы1эпэзы",
            "translation": "My mother is '''clumsy''', she drops everything she holds."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔapχʷaɕ"
  },
  {
    "word": "щызэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "in parallel",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ɕəzaw"
  },
  {
    "word": "гъэкъэркъэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tremble, to quake, to shake",
        "examples": [
          {
            "sentence": "К1алэр къэсыщти '''сыгъэкъэркъэтагъ'''",
            "translation": "I hold the kid and shook him."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaqarqatan"
  },
  {
    "word": "къэркъэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tremble, to quake, to shake",
        "examples": [
          {
            "sentence": "Чъыгор '''мэкъэркъатэ'''",
            "translation": "The earth '''is trembling'''."
          },
          {
            "sentence": "К1алэр къэсыщти '''сыгъэкъэркъэтагъ'''",
            "translation": "I hold the kid and shook him."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qarqatan"
  },
  {
    "word": "ч1э1отык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1э1отык1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼaʔʷatəkʲʼən"
  },
  {
    "word": "ч1э1отык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bewray, to reveal, to divulge, to make something known (especially a secret)",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэм ыш1агъэхэр '''къыч1и1утык1ыгъэх''' к1элэегъаджэм ыпашъхьэк1э",
            "translation": "The boy '''revealed''' the girl's doings in front of the teacher."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼaʔʷatət͡ʃʼən"
  },
  {
    "word": "къытэгъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to repeat",
        "examples": [
          {
            "sentence": "Къэп1уагъэм '''къытэгъэзэжь'''",
            "translation": "'''Repeat''' what you said."
          },
          {
            "sentence": "Фылымым бащэрэ '''къэзтегъэзэжьы'''",
            "translation": "The movie '''repeats itself''' too much."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qətaʁazan"
  },
  {
    "word": "къыхэущык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыхэущык1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəxawɕəkʲʼən"
  },
  {
    "word": "къыхэущык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wake up from something (usually a dream)",
        "examples": [
          {
            "sentence": "К1алэр пщыхьым къыхэущык1ыгъ",
            "translation": "The boy '''awoke''' from a dream."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəxawɕət͡ʃʼən"
  },
  {
    "word": "ришъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ишъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "rjəʃʷən"
  },
  {
    "word": "ишъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drink up liquid from inside something",
        "examples": [
          {
            "sentence": "Псы тас '''ишъун'''",
            "translation": "'''To drink up''' the water inside the mug."
          }
        ]
      },
      {
        "meaning": "to drink up; to finish one's drink.",
        "examples": [
          {
            "sentence": "К1алэм щэр '''решъу'''",
            "translation": "The boy '''is drinking up''' the milk."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʃʷən"
  },
  {
    "word": "ишъук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drink liquid from inside something fully",
        "examples": [
          {
            "sentence": "Махъщэм '''ришъук1ыгъ''' псынэр",
            "translation": "The camel '''drank''' the (whole) well."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʃʷət͡ʃʼn"
  },
  {
    "word": "хигъэгъощэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mislead someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xiʁaʁʷaɕan"
  },
  {
    "word": "нэ1аплъэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "towel",
        "examples": [
          {
            "sentence": "'''нэ1аплъэк1''' уи1а?",
            "translation": "Do you have a '''towel'''?"
          }
        ]
      }
    ],
    "synonyms": [
      "1эплъэк1"
    ],
    "cognate": "нэ1аплъэщ1",
    "type": "noun",
    "ipa": "naʔaːpɬat͡ʃʼ"
  },
  {
    "word": "л1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(archaic or dialectal) what (intangible)",
        "examples": [],
        "tags": [
          "archaic",
          "dialectal"
        ]
      }
    ],

    "cognate": "л1о",
    "type": "pron",
    "ipa": "ɬʼaw"
  },
  {
    "word": "шъыд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сыд\"",
        "examples": []
      }
    ],
    "synonyms": [
      "л1эу",
      "сыд"
    ],
    "cognate": "сыт",
    "type": "pron",
    "ipa": "ʂəd"
  },
  {
    "word": "шъыда",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сыда\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хьа",
      "сыда",
      "щтэ"
    ],
    "type": "adv",
    "ipa": "ʂədaː"
  },
  {
    "word": "щтэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъыда\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хьа",
      "сыда"
    ],
    "type": "adv",
    "ipa": "ɕta"
  },
  {
    "word": "хэк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shove",
        "examples": [
          {
            "sentence": "К1алэм хьацэр ят1эм '''хек1о'''",
            "translation": "The boy '''is shoving''' the shovel into the dirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xakʷʼan"
  },
  {
    "word": "шъыдгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сыдигъо\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʂədʁʷa"
  },
  {
    "word": "шъыдигъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сыдигъо\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʂədʁʷa"
  },
  {
    "word": "сыдигъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "when",
        "examples": [
          {
            "sentence": "'''сыдигъо''' къэсыщт к1алэр?",
            "translation": "'''When''' will the boy arrive?"
          }
        ]
      }
    ],
    "synonyms": [
      "шъыдгъо"
    ],
    "type": "adv",
    "ipa": "sədəjʁʷa"
  },
  {
    "word": "шъыдэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сыдэу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʂədaw"
  },
  {
    "word": "енэпэшъоон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэпэшъоон\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "janapaʃʷawan"
  },
  {
    "word": "нэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slap someone's face",
        "examples": [
          {
            "sentence": "'''Къысэнэпаох'''",
            "translation": "'''They are slapping me'''."
          },
          {
            "sentence": "К1алэм '''енэпао'''",
            "translation": "(s)he '''is slapping''' the boy."
          }
        ]
      }
    ],
    "synonyms": [
      "нэпэшъоон",
      "нэгушъо етын"
    ],
    "cognate": "нэпэуэн",
    "type": "verb",
    "ipa": "napawan"
  },
  {
    "word": "енэпэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэпэон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "нэпэшъоон"
    ],
    "cognate": "нэпэуэн",
    "type": "verb",
    "ipa": "napawan"
  },
  {
    "word": "ишъхьэк1э къи1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэк1э къэ1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħat͡ʃʼa qaʔʷan"
  },
  {
    "word": "ышъхьэк1э къы1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэк1э къэ1он\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħat͡ʃʼa qaʔʷan"
  },
  {
    "word": "хэхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to increase (intransitive)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaχʷan"
  },
  {
    "word": "хэгъэхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to increase (transitive)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʁaχʷan"
  },
  {
    "word": "къэраб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grey haired",
        "examples": []
      },
      {
        "meaning": "dandelion",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qaraːb"
  },
  {
    "word": "ш1ык1эен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to misbehave; to get naughty",
        "examples": [
          {
            "sentence": "'''Умыш1ык1ай'''.",
            "translation": "'''Don't misbehave'''."
          },
          {
            "sentence": "К1алэр '''мэш1ык1ае'''.",
            "translation": "The boy '''misbehaves'''."
          },
          {
            "sentence": "Шъэожъыем '''ш1ык1эеныр''' ишэн",
            "translation": "It is the boy's behavior to '''get naughty'''."
          }
        ]
      },
      {
        "meaning": "to frolic, to play about, to fool around, to get up to monkey tricks",
        "examples": []
      }
    ],
    "synonyms": [
      "бзэджэн"
    ],
    "type": "verb",
    "ipa": "ʃʼət͡ʃʼajan"
  },
  {
    "word": "модэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "over there (Visible)",
        "examples": [
          {
            "sentence": "'''Модэ''' тыжъугъак1у",
            "translation": "Let us go '''there'''."
          },
          {
            "sentence": "'''Модэ''' плъэгъугъа ящэрэр?",
            "translation": "Did you see what they're selling '''there'''?"
          }
        ]
      },
      {
        "meaning": "(colloquial) Used to gain one's attention before making an inquiry or suggestion or when changing the topic of the conversation.",
        "examples": [
          {
            "sentence": "'''Модэ''', сыунэ тык1ожьымэ нахь ш1ущтыба",
            "translation": "'''Say''', ain't it would be better to return to my house?"
          },
          {
            "sentence": "'''Модэ''', фылым к1эр плъэгъугъа?",
            "translation": "'''Say''', did you saw the new movie?"
          }
        ],
        "tags": [
          "colloquial"
        ]
      }
    ],
    "type": "adv",
    "ipa": "mawda"
  },
  {
    "word": "шъыпкъэмк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "actually, in truth",
        "examples": [
          {
            "sentence": "'''шъыпкъэмк1э''', фылым к1эр афэдизэу дэгъоп къыса1уагъ",
            "translation": "'''Actually''', I was told that the new movie is not that good."
          },
          {
            "sentence": "'''шъыпкъэмк1э''', тиджагъа тимыджагъа, экзамыным тыблэк1ыщтыпи тыблэк1ыщтэп",
            "translation": "'''In truth''', either we study or not, we won't pass the exam."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʂəpqamt͡ʃʼa"
  },
  {
    "word": "хэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to choose, to select, to pick out",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xadan"
  },
  {
    "word": "хэгъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone select",
        "examples": []
      },
      {
        "meaning": "to give someone the opportunity to choose",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʁadan"
  },
  {
    "word": "гъэбэдзэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to exceed in what oneself is doing",
        "examples": [
          {
            "sentence": "К1алэм '''ыгъэбэдзэжьыгъ''' и экзамынымхэмк1э",
            "translation": "The boy '''exceeded''' with his exams."
          },
          {
            "sentence": "К1алэм и джанэк1э '''ыгъэбэдзэжьэу''' чэщдэсым къэк1уагъ",
            "translation": "The boy came to the party '''exceeding''' with his shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁabad͡zaʑən"
  },
  {
    "word": "мэзагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "moonlight",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mazaːʁʷa"
  },
  {
    "word": "шъао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lad",
        "examples": []
      },
      {
        "meaning": "teenager",
        "examples": []
      }
    ],

    "cognate": "щауэ",
    "type": "noun",
    "ipa": "ʂaːwa"
  },
  {
    "word": "гъэпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ыгъэпэн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "əʁapan"
  },
  {
    "word": "ыгъэпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to care",
        "examples": [
          {
            "sentence": "К1алэр '''ыгъапэрэп''' экзамыным",
            "translation": "The boy '''does not care for''' the exam."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "əʁapan"
  },
  {
    "word": "зэк1эчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run backward",
        "examples": [
          {
            "sentence": "Кур '''зэк1ачъэ''' гъогум",
            "translation": "The carriage '''runs backward''' on the road."
          },
          {
            "sentence": "Мащинэм мыжъо ч1алъхь '''зэк1эмычъэн''' пае",
            "translation": "Put rock under the car so '''it won't run backward'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼat͡ʂan"
  },
  {
    "word": "зэк1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be pushed back",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zat͡ʂaħan"
  },
  {
    "word": "зэк1эхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэк1эхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼaħan"
  },
  {
    "word": "лъэк1ап1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэк1ап1э\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬat͡ʃaːpʼa"
  },
  {
    "word": "лъэк1ьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэк1ап1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэк1ап1э"
    ],
    "cognate": "лъэнк1ап1э",
    "type": "noun",
    "ipa": "ɬakʲʼaːpʼa"
  },
  {
    "word": "шъхьак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "however, although, yet",
        "examples": [
          {
            "sentence": "Сык1омэ сш1оигъу '''шъхьак1э''', 1офым сыхэк1ын слъэк1ырэп",
            "translation": "I want to go, '''however''' I can't leave work."
          },
          {
            "sentence": "ХьакIэр ежьэжьын ыIуи шъхьакIэм, бысымым ригъэжьэжьыгъэп",
            "translation": "I guest tried to run away, '''however''' the host didn't allow him."
          }
        ]
      }
    ],
    "synonyms": [
      "шъхьэк1ьэ"
    ],
    "cognate": "щхьэк1э",
    "type": "adv",
    "ipa": "ʂħaːt͡ʃʼa"
  },
  {
    "word": "фэш1к1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "because of",
        "examples": [
          {
            "sentence": "К1алэм '''фэш1ык1э''' пшъашъэр мэгъы",
            "translation": "The girl is crying '''because of''' the boy."
          },
          {
            "sentence": "Узым '''фэш1к1э''' л1ыжъыр ыл1ык1ыгъ",
            "translation": "'''Because of''' the illness, the old man died."
          }
        ]
      },
      {
        "meaning": "for",
        "examples": [
          {
            "sentence": "Сиянэ '''фэш1к1э''' тучаным сэк1о",
            "translation": "I am going to the shop '''for''' my mother."
          },
          {
            "sentence": "Мэфэк1ым '''фэш1к1э''' джэнэ к1эхэр пщэфын фай",
            "translation": "'''For''' the holiday, you must buy new clothes."
          }
        ]
      }
    ],
    "synonyms": [
      "пае",
      "фэш1"
    ],
    "cognate": "папщ1э",
    "type": "conj",
    "ipa": "faʃʼət͡ʃʼa"
  },
  {
    "word": "зэдзэк1ак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "interpreter",
        "examples": []
      },
      {
        "meaning": "translator",
        "examples": [
          {
            "sentence": "Сэ '''сызэдзэкIакIу'''.",
            "translation": "I am '''a translator'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "zad͡zat͡ʃʼaːkʷʼa"
  },
  {
    "word": "къыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to burp",
        "examples": [
          {
            "sentence": "К1алэр '''къэкъыегъ'''",
            "translation": "The boy '''burped'''."
          },
          {
            "sentence": "'''Укъэмыкъый'''",
            "translation": "'''Don't burp'''."
          }
        ]
      },
      {
        "meaning": "to hiccup",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəjən"
  },
  {
    "word": "къэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burp",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajə"
  },
  {
    "word": "къэин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaqəjən"
  },
  {
    "word": "ук1ытапх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a shy person",
        "examples": [
          {
            "sentence": "К1алэр '''ук1ытапх'''",
            "translation": "The boy '''is a shy person'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ук1ьытапх"
    ],
    "type": "adj",
    "ipa": "wt͡ʃʼətaːpx"
  },
  {
    "word": "ук1ьытапх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1ытапх\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ук1ытапх"
    ],
    "type": "adj",
    "ipa": "wkʲʼətaːpx"
  },
  {
    "word": "ук1ытапхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ук1ытапх\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ук1ьытапх"
    ],
    "type": "adj",
    "ipa": "wt͡ʃʼətaːpx"
  },
  {
    "word": "щтапхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coward",
        "examples": []
      }
    ],
    "synonyms": [
      "щынапхэ",
      "къэрэбгъэ"
    ],
    "type": "adj",
    "ipa": "ɕtaːpxa"
  },
  {
    "word": "щытапхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щтапхэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "щынапхэ",
      "къэрэбгъэ"
    ],
    "type": "adj",
    "ipa": "ɕtaːpxa"
  },
  {
    "word": "къэрэбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэрабгъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "щынапхэ",
      "щтапхэ"
    ],
    "type": "adj",
    "ipa": "qarabʁa"
  },
  {
    "word": "щынапхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coward",
        "examples": []
      }
    ],
    "synonyms": [
      "щтапхэ",
      "къэрэбгъэ"
    ],
    "type": "adj",
    "ipa": "ɕənaːpxa"
  },
  {
    "word": "лъы1эсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reach for",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɬəʔasən"
  },
  {
    "word": "шэнычъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a naughty person, a person with a bad behavior",
        "examples": []
      }
    ],

    "cognate": "щэныншэ",
    "type": "adj",
    "ipa": "ʃant͡ʂa"
  },
  {
    "word": "орэдус",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "composer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wradwəs"
  },
  {
    "word": "пц1ы усын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie",
        "examples": [
          {
            "sentence": "бзылъфыгъэм '''пц1ы еусы'''",
            "translation": "The women '''is lying'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пц1ын"
    ],
    "type": "verb",
    "ipa": "pt͡sʼə wsən"
  },
  {
    "word": "пц1ыус",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "liar",
        "examples": []
      }
    ],
    "synonyms": [
      "пц1ырый"
    ],
    "type": "noun",
    "ipa": "pt͡sʼəwəs"
  },
  {
    "word": "орэды1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "singer",
        "examples": [
          {
            "sentence": "к1алэр '''орэды1у'''",
            "translation": "The boy is a '''singer'''."
          }
        ]
      }
    ],
    "synonyms": [
      "орэда1у"
    ],
    "cognate": "уэрэджы1ак1уэ",
    "type": "noun",
    "ipa": "waradəʔʷ"
  },
  {
    "word": "орэды1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sing",
        "examples": [
          {
            "sentence": "бзылъфыгъэр '''орэды1о'''",
            "translation": "The women '''is singing'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "waradəʔʷan"
  },
  {
    "word": "гъэпщтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to boil (milk)",
        "examples": [
          {
            "sentence": "К1алэм щэр '''егъэпщты'''",
            "translation": "The boy '''is boiling''' the milk."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁapɕtən"
  },
  {
    "word": "пщтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be boiled (milk)",
        "examples": [
          {
            "sentence": "щэр '''мэпщты'''",
            "translation": "The milk '''is boiling'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pɕtən"
  },
  {
    "word": "зэпыгъэчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to split",
        "examples": [
          {
            "sentence": "К1алэм лыр ныкъо ныкъоу '''зэпегъэчъы'''",
            "translation": "The boy '''splits''' the meat in half."
          },
          {
            "sentence": "Гъуч1ыр къинэ '''зэпыбгъэчъынэ'''",
            "translation": "It is hard to '''split''' the iron '''in half'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapəʁat͡ʂən"
  },
  {
    "word": "течын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to peal",
        "examples": [
          {
            "sentence": "К1алэм мы1эрысэм ищъо '''зэтечы'''",
            "translation": "The boy '''is peeling''' the apple."
          }
        ]
      }
    ],
    "synonyms": [
      "текьын"
    ],
    "type": "verb",
    "ipa": "tajt͡ʃən"
  },
  {
    "word": "текьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"течын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "течын"
    ],
    "type": "verb",
    "ipa": "tajkʲən"
  },
  {
    "word": "зэхэгъэшъухъон",
    "lang": "Adyghe",
    "definitions": [],
    "type": "verb",
    "ipa": "zaxaʁaʃʷəχʷan"
  },
  {
    "word": "зэхэгъэтэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear something to pieces",
        "examples": [
          {
            "sentence": "Лэжьак1охэмэ унэр '''зэхагъэтэкъогъ'''",
            "translation": "The workers '''torn''' the house '''into pieces'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxaʁataqʷən"
  },
  {
    "word": "зэпыутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break (A part of something), to fracture",
        "examples": [
          {
            "sentence": "Къупышъхэр '''зэпыутыгъ'''",
            "translation": "The bone '''was fractured'''."
          },
          {
            "sentence": "1анэм ылъакъо к1алэм '''зэпиутыгъ'''",
            "translation": "The boy '''broke''' the table's leg."
          },
          {
            "sentence": "Мыжъор адзи ч1ыгум тефэм '''зэпыутыгъ'''",
            "translation": "When the rock was thrown and hit the ground, '''it fractured'''."
          }
        ]
      },
      {
        "meaning": "to break in two",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapəwtən"
  },
  {
    "word": "шъыпкъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "truthfully",
        "examples": [
          {
            "sentence": "К1алэр '''шъыпкъэу''' къэсдэгущы1агъ",
            "translation": "The boy talked to me '''truthfully'''."
          }
        ]
      },
      {
        "meaning": "seriously",
        "examples": [
          {
            "sentence": "'''Шъыпкъэу''' къэсдэгущы1",
            "translation": "Talk to me '''seriously'''."
          },
          {
            "sentence": "'''Уишъыпкъэу''' лаж",
            "translation": "Work '''seriously'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ʂəpqaw"
  },
  {
    "word": "тэдрэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "each",
        "examples": [
          {
            "sentence": "'''Тэдрэз''' лъэпкъым быракъ и1",
            "translation": "'''Each''' nation has a flag."
          },
          {
            "sentence": "'''Тэдрэз''' к1алэм т1эк1у ахъщэ ет",
            "translation": "Give '''each''' kid a bit of money."
          }
        ]
      }
    ],
    "type": "det",
    "ipa": "tadraz"
  },
  {
    "word": "1аджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "many",
        "examples": [
          {
            "sentence": "'''1аджи''' ш1агъи, еджап1эм мак1охэу щытыгъэхэп",
            "translation": "'''Long''' time ago, they weren't going to school."
          },
          {
            "sentence": "Ц1ыф '''Iаджи''' къызэ1ук1агъ",
            "translation": "I met with '''many''' people."
          },
          {
            "sentence": "Ащ '''1аджи''' ылъэгъугъ",
            "translation": "He saw '''many''' things."
          }
        ]
      }
    ],
    "synonyms": [
      "1эгьэ"
    ],
    "type": "adj",
    "ipa": "ʔaːd͡ʒa"
  },
  {
    "word": "1эгьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1аджэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaɡʲa"
  },
  {
    "word": "щысэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "example",
        "examples": [
          {
            "sentence": "'''Щысэ''' техын",
            "translation": "To take '''an example''' from someone."
          },
          {
            "sentence": "'''Щысэ''' егъэлъэгъун",
            "translation": "To show '''an example'''."
          },
          {
            "sentence": "'''Щысэ''' къэгъэхьын",
            "translation": "To bring '''an example'''."
          }
        ]
      }
    ],

    "cognate": "щапхъэ",
    "type": "noun",
    "ipa": "ɕəsa"
  },
  {
    "word": "ушыгун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chop",
        "examples": [
          {
            "sentence": "К1алэм нэшэбэгур '''еушыгу'''",
            "translation": "The boy '''is chopping''' the cucumber."
          }
        ]
      }
    ],

    "cognate": "ущыгун",
    "type": "noun",
    "ipa": "wəɕəɡʷən"
  },
  {
    "word": "мэ1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shield",
        "examples": [
          {
            "sentence": "Ашъор щыгъ, '''мэ1ур''' ы1ыгъ",
            "translation": "He wears an armor, he holds a shield."
          }
        ]
      },
      {
        "meaning": "fully filled",
        "examples": [
          {
            "sentence": "Ощхым фэш1ык1э гъогур псы '''мэ1у'''",
            "translation": "Duo to the rain the road is '''fully filled with''' water."
          },
          {
            "sentence": "Тиунэ шхын '''мэ1у'''",
            "translation": "Out house '''is full with''' food."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "maʕʷ"
  },
  {
    "word": "пстэури",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "all",
        "examples": []
      }
    ],
    "synonyms": [
      "псэори"
    ],
    "cognate": "псори",
    "type": "adv",
    "ipa": "pstawri"
  },
  {
    "word": "псэори",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "all",
        "examples": []
      }
    ],
    "synonyms": [
      "пстэури"
    ],
    "cognate": "псори",
    "type": "adv",
    "ipa": "psawari"
  },
  {
    "word": "гук1ае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "demand, request",
        "examples": []
      },
      {
        "meaning": "sorrow",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷət͡ʃʼaːja"
  },
  {
    "word": "ыгузэгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гузэгу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гузэгу"
    ],
    "type": "noun",
    "ipa": "əɡʷəzaɡʷ"
  },
  {
    "word": "ш1эпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be sure, to be certain",
        "examples": [
          {
            "sentence": "'''I am sure''' that he’ll come",
            "translation": "Сэ сэш1апэ ар къызэрэк1ощтыр."
          },
          {
            "sentence": "'''Are you sure''' the boy will come?",
            "translation": "'''Ош1эпа''' к1алэр зэрэкъэк1ощтыр?"
          }
        ]
      }
    ],
    "synonyms": [
      "ыш1эпэн"
    ],
    "type": "verb",
    "ipa": "ʃʼapan"
  },
  {
    "word": "ыш1эпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1эпэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ыш1эпэн"
    ],
    "type": "verb",
    "ipa": "əʃʼapan"
  },
  {
    "word": "гъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time",
        "examples": [
          {
            "sentence": "Ар '''игъом''' къэкIуагъа? Ар '''игъом''' къэкIошъугъэп.",
            "translation": "Did he come '''on time'''? He could not come '''on time'''."
          },
          {
            "sentence": "Сыда о '''игъом''' укъызыкIэмыкIошъугъэр?",
            "translation": "Why could you not come '''on time'''?"
          },
          {
            "sentence": "'''Игъом''' сыкъызыкIэмыкIошъугъэр автобусхэр зекIощтыгъэхэпышъ ары.",
            "translation": "I could not come '''on time''' because there were no buses."
          }
        ]
      },
      {
        "meaning": "(of an animal that has hair) red-haired, red-headed",
        "examples": [
          {
            "sentence": "Шыгъо",
            "translation": "Red horse."
          },
          {
            "sentence": "Чэмыгъо",
            "translation": "Red cow."
          },
          {
            "sentence": "Гъом тесэу блэкIыгъ.",
            "translation": "He passed while on the red animal."
          }
        ],
        "tags": [
          "of an animal that has hair"
        ]
      }
    ],
    "synonyms": [
      "уахътэ",
      "лъэхъан"
    ],
    "cognate": "п1алъэ",
    "type": "noun",
    "ipa": "ʁʷa"
  },
  {
    "word": "къэц1ыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэц1ыон\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qat͡sʼəwan"
  },
  {
    "word": "1эмал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "method, means, way",
        "examples": [
          {
            "sentence": "'''1эмал''' гъотын",
            "translation": "Find '''an opportunity'''."
          },
          {
            "sentence": "'''1эмал''' етын",
            "translation": "Give '''an opportunity'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔamaːl"
  },
  {
    "word": "мыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "over here",
        "examples": [
          {
            "sentence": "'''Мыдэ''' къак1у",
            "translation": "Come '''over here'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "məda"
  },
  {
    "word": "къэк1орэ гъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "next year",
        "examples": [
          {
            "sentence": "'''къэк1орэ гъэм''' тк1от йуропа",
            "translation": "'''Next year''' we will go to Europe."
          },
          {
            "sentence": "'''къэк1орэ гъэм''' пш1ы синыбжьыт",
            "translation": "'''Next year''' I will be ten years old."
          },
          {
            "sentence": "'''къэк1орэ гъэм''' томэр къэк1от",
            "translation": "'''Near year''' Tom will come."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "qakʷʼara ʁa"
  },
  {
    "word": "тэщгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "when",
        "examples": [
          {
            "sentence": "'''Тэщгъум''' тыдэк1ыщт?",
            "translation": "'''When''' we go out?"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "taɕʁʷəm"
  },
  {
    "word": "мыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "this year",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "maʁa"
  },
  {
    "word": "умыармэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "if not (if not the result will be)",
        "examples": [
          {
            "sentence": "Нахь псынк1эу тыгъачъ, '''умыармэ''' тучанэ ригъэсэжьыщт тынэмысыгъэу",
            "translation": "Let us run faster, '''if not''' we will reach the shop when it's closed."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "wməaːrma"
  },
  {
    "word": "армэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "if that, if so",
        "examples": [
          {
            "sentence": "'''Армэ''' л1ым ыш1агъэр, хьапсым ч1агъэт1ысхьэн фай",
            "translation": "'''If that''' what the man did, they need to put him in jail."
          },
          {
            "sentence": "'''Армэ''' п1орэр, шъофым къещхыщти тыхэшъущтэп",
            "translation": "'''If that''' what you say, we can't enter the field duo to the rail."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːrma"
  },
  {
    "word": "амэрми",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"амырми\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːmarmi"
  },
  {
    "word": "амырмэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "if not (can be used like or else)",
        "examples": [
          {
            "sentence": "Нахь псынк1эу тыгъачъ, '''амэрмэ''' тучанэ къыфишъыжьыгъэу тынэсыщт",
            "translation": "Let us run faster, '''if not''' we will reach the shop when it's closed already."
          },
          {
            "sentence": "Едж дэгъоу, '''амэрмэ''' тестыр къыпфэш1ыщтэп",
            "translation": "Study good, '''if not''' you will not be capable of doing the test."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːmarma"
  },
  {
    "word": "а нахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "more than that",
        "examples": [
          {
            "sentence": "Мы пшъашъэм '''а нахь''' дахэ щы1а?",
            "translation": "Is there a girl '''more''' beautiful '''than her'''?"
          }
        ]
      },
      {
        "meaning": "rather",
        "examples": [
          {
            "sentence": "К1алэр к1уагъэп, '''а нахь''' чъагъэ",
            "translation": "The boy didn't go, '''rather''' he ran."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aː naːħ"
  },
  {
    "word": "ар нахьыеу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "more like",
        "examples": [
          {
            "sentence": "К1алэр к1уагъэп, '''ар нахьыеу''' чъагъэ",
            "translation": "The boy didn't go, '''more like''' he ran."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːr naːħəjaw"
  },
  {
    "word": "арыми",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"арми\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːrəmi"
  },
  {
    "word": "иджыри",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "still",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jəd͡ʒəri"
  },
  {
    "word": "ш1ок1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lose something",
        "examples": [
          {
            "sentence": "К1алэр ахъщэ бэ '''ш1ок1уагъ'''",
            "translation": "The boy '''lost''' a lot of money."
          }
        ]
      },
      {
        "meaning": "to go against someone's will or interest",
        "examples": [
          {
            "sentence": "Сик1алэ чэщдэсым '''къэсш1ок1уагъ'''",
            "translation": "My boy '''went''' to the party '''against my will'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼakʷʼan"
  },
  {
    "word": "гъойщай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lightly ill; lightly sick; unwell",
        "examples": [
          {
            "sentence": "К1алэр '''гъойщай'''",
            "translation": "The boy is '''sick'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʁʷajɕaːj"
  },
  {
    "word": "е1орыш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1орыш1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʔʷarʃʼan"
  },
  {
    "word": "1орыш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to obey",
        "examples": [
          {
            "sentence": "К1алэр щъузым '''е1уарыш1э'''",
            "translation": "The boy '''obeys''' to the woman."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷarʃʼan"
  },
  {
    "word": "1эдэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "polite",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔadab"
  },
  {
    "word": "нэгуихыгъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nice",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naɡʷixəʁaw"
  },
  {
    "word": "щыгъозэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be aware of",
        "examples": [
          {
            "sentence": "'''СызэрэщыгъуазэмкIэ''', ар мыщ щэпсэу.",
            "translation": "'''As far as I know'''', he lives here."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəʁʷazan"
  },
  {
    "word": "гъэпсэфыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "break for resting",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁapsafəʁʷa"
  },
  {
    "word": "къыч1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to invent or come up with an idea or concept.",
        "examples": [
          {
            "sentence": "Ньютон физика '''къыч1ихыгъ'''",
            "translation": "Newton '''invented''' physics."
          }
        ]
      }
    ],
    "synonyms": [
      "къэгупшысын"
    ],
    "type": "verb",
    "ipa": "qət͡ʃʼaxən"
  },
  {
    "word": "мык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "here",
        "examples": [
          {
            "sentence": "Ар зыдэщы1эр '''мык1э''' нахь ак1эп",
            "translation": "He is rather '''here''', not there."
          },
          {
            "sentence": "'''Мык1э''' мамыр, зи уаий щы1эп",
            "translation": "It is peaceful '''here''', there is no bad weathers."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "mət͡ʃʼa"
  },
  {
    "word": "щэрыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "accurate; well-aimed, sharp (shooter)",
        "examples": [
          {
            "sentence": "шэкIо щэрыу",
            "translation": "accurate hunter."
          }
        ]
      },
      {
        "meaning": "graphic, picturesque, vivid (image-bearing)",
        "examples": [
          {
            "sentence": "гущыIэ щэрыу",
            "translation": "vivid word."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕarəw"
  },
  {
    "word": "шъо течъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "color to wear off",
        "examples": [
          {
            "sentence": "Цуакъэм ишъо течъыгъ",
            "translation": "The shoe's color wore off."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷa hajt͡ʂən"
  },
  {
    "word": "течъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a liquid to slide off above something",
        "examples": []
      },
      {
        "meaning": "to surpass; to overtake",
        "examples": [
          {
            "sentence": "К1алэр джэгумк1э къэстечъыгъ",
            "translation": "The boy overtook me in the game."
          }
        ]
      },
      {
        "meaning": "to outrun, to [outdistance",
        "examples": [
          {
            "sentence": "Руслъан Асхьадэ течъыгъ",
            "translation": "Ruslan outran Askhad in the race."
          }
        ]
      }
    ],
    "synonyms": [
      "ыпэ ишъын",
      "к1ок1э тек1ын"
    ],
    "type": "verb",
    "ipa": "thajt͡ʂən"
  },
  {
    "word": "иупк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cut down",
        "examples": [
          {
            "sentence": "К1алэм чъыгыр '''риупк1ыгъ'''",
            "translation": "The boy '''cut down''' the tree."
          },
          {
            "sentence": "Чъыгыр сэрэ орэ '''итыупк1ыщт'''",
            "translation": "You and I '''gonna cut down''' the tree."
          },
          {
            "sentence": "Чъыгыр '''иупк1ын'''",
            "translation": "'''To cut down''' a tree."
          },
          {
            "sentence": "Чъыгышхор '''имыупк1'''",
            "translation": "'''Don't cut down''' the big tree."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jəwpt͡ʃʼən"
  },
  {
    "word": "ны1эп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "only (No more than; just)",
        "examples": [
          {
            "sentence": "Зы маф '''ны1эп''' сызеджагъэр",
            "translation": "I have '''only''' studied for a day."
          },
          {
            "sentence": "Зы чъыгы исыпк1ыгъэ '''ны1эп'''",
            "translation": "I '''only''' cut down one tree."
          },
          {
            "sentence": "Оры '''ны1эп''' ащ цыхьэ зыфиш1ырэр",
            "translation": "He trusts '''only''' you."
          },
          {
            "sentence": "Зы илъэс '''ны1эп''' теш1агъэр",
            "translation": "'''Only''' one year passed."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "nəʔap"
  },
  {
    "word": "ны1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ны1эп\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "nəʔa"
  },
  {
    "word": "жьэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ежьэжьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʑaʑən"
  },
  {
    "word": "еджэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ежьэжьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaʑən"
  },
  {
    "word": "ежьэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run away",
        "examples": [
          {
            "sentence": "К1алэр пшъашъэм '''фежьэжьы'''",
            "translation": "The boy is '''running away from''' the girl."
          },
          {
            "sentence": "Зэк1эм сыкъелъэгъум, '''сежьэжьыгъ''' псынк1эу",
            "translation": "When everyone say me, '''I ran away''' quickly."
          },
          {
            "sentence": "Хьак1эр '''ежьэжьын''' ы1уи шъхьак1эм, бысымым '''ригъэжьэжьыгъэп'''",
            "translation": "Because the guest tried '''to run away''', the owner didn't let him."
          }
        ]
      },
      {
        "meaning": "to escape from",
        "examples": [
          {
            "sentence": "Л1ыр хьапсым '''ежьэжьыгъ'''",
            "translation": "The man '''escaped from''' prison."
          }
        ]
      },
      {
        "meaning": "to head back",
        "examples": []
      },
      {
        "meaning": "to resume",
        "examples": [
          {
            "sentence": "Филымым '''егъэжьэжь'''",
            "translation": "'''Resume''' the film."
          },
          {
            "sentence": "1офш1эныр '''ежьэжьыгъ'''",
            "translation": "The work '''is resumed'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1э1эжьын",
      "к1э1эн"
    ],
    "type": "verb",
    "ipa": "jaʑaʑən"
  },
  {
    "word": "ш1уежьэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run away from",
        "examples": [
          {
            "sentence": "Бланэр тыгъужъым '''ш1уежьэжьы'''",
            "translation": "The deer '''runs away from''' the wolf."
          },
          {
            "sentence": "Бланэр тыгъужъымэ '''аш1уежьэжьышъугъ'''",
            "translation": "The deer '''successfully ran away from''' the wolves."
          },
          {
            "sentence": "К1алэр '''къэсш1уежьэжьын''' е1о",
            "translation": "The boy tried '''to run away from me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼajʑaʑən"
  },
  {
    "word": "зифэпэжьыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get dressed, to dress up",
        "examples": [
          {
            "sentence": "ЛIэу зызгъэпскIыгъэм '''зифэпэжьыгъ'''",
            "translation": "The man that showered, got dressed up."
          },
          {
            "sentence": "Ар джы шъойцыежьэп, '''зифэпэжьыгъ'''",
            "translation": "Now, he is not poorly dressed anymore, he dressed up."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəjfapaʑəʁ"
  },
  {
    "word": "шъойцый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "someone poorly dressed",
        "examples": [
          {
            "sentence": "Ар джы '''шъойцыежьэп''', зифэпэжьыгъ",
            "translation": "Now, he is not poorly dressed anymore, he dressed up."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʃʷajt͡səj"
  },
  {
    "word": "фэпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dress someone",
        "examples": [
          {
            "sentence": "Сабыир '''фэпэн'''",
            "translation": "'''To dress''' a baby."
          },
          {
            "sentence": "Пшъашъэм сабыир '''ефапэ'''",
            "translation": "The girl '''is dressing''' the baby."
          },
          {
            "sentence": "ЛIэу зызгъэпскIыгъэм '''зифэпэжьыгъ'''",
            "translation": "The man that showered, '''dressed himself up'''."
          }
        ]
      }
    ],

    "cognate": "хуэпэн",
    "type": "verb",
    "ipa": "fapan"
  },
  {
    "word": "ш1эхэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "quickly",
        "examples": [
          {
            "sentence": "'''Ш1эхэу''' 1офыр зэш1ок1ыгъэ",
            "translation": "The work was done '''quickly'''."
          },
          {
            "sentence": "'''Ш1эхэу''' к1уагъэ",
            "translation": "He went '''quickly'''."
          },
          {
            "sentence": "Ащ и1оф '''ш1эхэу''' зэш1ок1ынк1э гугъэн фае, ау '''ш1эхэу''' зэш1ок1ыщтэп",
            "translation": "He hope for his job to be solved '''quickly''', but it won't be solved '''quickly'''."
          }
        ]
      }
    ],
    "synonyms": [
      "псынк1эу"
    ],
    "cognate": "щ1эхыу",
    "type": "adv",
    "ipa": "ʃʼaxaw"
  },
  {
    "word": "гъозадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lost",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷazaːd͡ʒ"
  },
  {
    "word": "зэпырык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pass",
        "examples": [
          {
            "sentence": "К1алэм гъогур '''зэпырек1ы'''",
            "translation": "The boy '''passes''' the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapərət͡ʃʼən"
  },
  {
    "word": "пхэндж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrong, incorrect",
        "examples": [
          {
            "sentence": "Тэ гъогу пхэндж тызытехьагъэр.",
            "translation": "We’re on the wrong road."
          }
        ]
      },
      {
        "meaning": "twisted",
        "examples": []
      }
    ],
    "synonyms": [
      "пхэдж",
      "мытэрэз",
      "тэрэзыдж"
    ],
    "cognate": "пхэнж",
    "type": "adj",
    "ipa": "pxand͡ʒ"
  },
  {
    "word": "пцыкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ski",
        "examples": [
          {
            "sentence": "Пцыкъомэ уатетышъуа?",
            "translation": "Do you ski?"
          },
          {
            "sentence": "Мыщ пцыкъохэр бэджэндэу пштэнхэ плъэкIыщта?",
            "translation": "Can one rent skis here?"
          },
          {
            "sentence": "УдэкIоен хъумэ пцыкъорыкIо лифтым уетIысхьа?",
            "translation": "Do you take the ski lift to the top?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pt͡səqʷa"
  },
  {
    "word": "лъэрычъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skates",
        "examples": []
      },
      {
        "meaning": "skateboard",
        "examples": []
      },
      {
        "meaning": "running shoes",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬarət͡ʂ"
  },
  {
    "word": "лъэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump up",
        "examples": []
      },
      {
        "meaning": "to take off; to take flight",
        "examples": []
      }
    ],
    "synonyms": [
      "быбын",
      "пк1эн",
      "----"
    ],
    "type": "verb",
    "ipa": "ɬatan"
  },
  {
    "word": "хъугъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that's enough, leave it be, oh well (An expression of mild disappointment or resignation)",
        "examples": [
          {
            "sentence": "'''Хъугъэ''', ыуж ик1",
            "translation": "'''That's enough''', leave it be."
          },
          {
            "sentence": "'''Хъугъэ''', нек1о тык1ожьыщт",
            "translation": "'''That's enough''', let's return."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "χʷəʁa"
  },
  {
    "word": "зэгьэм",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "immediately",
        "examples": [
          {
            "sentence": "'''зэгьэм''' к1о",
            "translation": "Go '''immediately'''."
          },
          {
            "sentence": "'''зэгьэм''' еу пхъэм",
            "translation": "Hit the wood '''immediately'''"
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "zaɡʲam"
  },
  {
    "word": "уцэкун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become dull, to dull (lose a sharp edge)",
        "examples": [
          {
            "sentence": "Щэмэджыр мэуцэку",
            "translation": "the plait '''is becoming dulled'''."
          },
          {
            "sentence": "Шъэжъыер '''зэуцэкук1э''', нэмк1ыр шъэжъый къэхь",
            "translation": "'''When''' the knife '''becomes dull''', bring a different knife."
          }
        ]
      }
    ],

    "cognate": "удзэгун",
    "type": "verb",
    "ipa": "wət͡sakʷən"
  },
  {
    "word": "джын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spin",
        "examples": []
      }
    ],
    "synonyms": [
      "джыджэн"
    ],
    "type": "verb",
    "ipa": "d͡ʒən"
  },
  {
    "word": "джэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еджэн\"",
        "examples": []
      }
    ],

    "cognate": "еджэн",
    "type": "verb",
    "ipa": "d͡ʒan"
  },
  {
    "word": "щыгугъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to rely on, to count on",
        "examples": [
          {
            "sentence": "ишъэогъу щыгугъын",
            "translation": "To rely on a friend."
          }
        ]
      },
      {
        "meaning": "to hope for",
        "examples": [
          {
            "sentence": "IэпыIэгъум щыгугъын",
            "translation": "To count on help."
          }
        ]
      },
      {
        "meaning": "to anticipate for",
        "examples": []
      }
    ],
    "synonyms": [
      "гугъэн"
    ],
    "type": "verb",
    "ipa": "ɕəɡʷəʁən"
  },
  {
    "word": "чэфыныпхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shroud (the dress for the dead; a winding sheet.)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃafənəpχ"
  },
  {
    "word": "машэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pit",
        "examples": []
      },
      {
        "meaning": "grave",
        "examples": []
      }
    ],

    "cognate": "мащэ",
    "type": "noun",
    "ipa": "maːʃa"
  },
  {
    "word": "ц1ыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shine, to glitter, to sparkle, to flash",
        "examples": [
          {
            "sentence": "Дышъэр '''мэц1ыо'''",
            "translation": "The gold '''shines'''."
          },
          {
            "sentence": "Жъуагъохэр '''мэц1ыох'''",
            "translation": "The stars '''are shining'''."
          },
          {
            "sentence": "'''Ц1ыурэ''' пстэури дышъэп",
            "translation": "All the things that '''shine''' are not gold."
          }
        ]
      }
    ],
    "synonyms": [
      "жъыун",
      "лыдын",
      "ц1ыон"
    ],
    "cognate": "ц1уун",
    "type": "verb",
    "ipa": "t͡sʼəwən"
  },
  {
    "word": "гугъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гугъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʁən"
  },
  {
    "word": "ш1ош1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to think that, to suppose; to hypothesize; to reckon",
        "examples": [
          {
            "sentence": "К1алэм чъы1агъ '''еш1ош1ы'''",
            "translation": "The boy '''thinks''' it's cold."
          },
          {
            "sentence": "'''Сыш1ош1ырэмк1э''' мэзым тхэхьан фае",
            "translation": "'''I think''' we need to enter the forest."
          },
          {
            "sentence": "Ар '''сыш1ош1ырэп'''",
            "translation": "'''I don't think''' that."
          },
          {
            "sentence": "А к1алэм сынахь к1уач1 '''сыш1ош1эгъ'''",
            "translation": "'''I thought''' i was stronger then him."
          },
          {
            "sentence": "К1алэм тестыр къыныщт '''иш1ош1эгъ''', ау 1эш1эхэу къыч1эк1ыжьэгъ",
            "translation": "The boy '''thought''' the test gonna be hard, but it was easy."
          }
        ]
      },
      {
        "meaning": "to see as, to be perceived as",
        "examples": [
          {
            "sentence": "Сабыеу '''къыпш1ош1ы'''",
            "translation": "It is '''seems like''' a child '''for you'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ш1ош1ун"
    ],
    "cognate": "ф1эщ1ын",
    "type": "verb",
    "ipa": "ʃʷaʃʼən"
  },
  {
    "word": "утхъоен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mold; to become moldy; mold to grow upon",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wətχʷajan"
  },
  {
    "word": "ш1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hurry up, to hasten",
        "examples": []
      }
    ],
    "synonyms": [
      "тхъытхъын",
      "гузэжъон",
      "хъыжъэн"
    ],
    "type": "verb",
    "ipa": "ʃʼaxən"
  },
  {
    "word": "тхъытхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hurry",
        "examples": [
          {
            "sentence": "Iофыр шIэхэу ыгъэцэкIэным пае '''тхъытхъын''' фэягъэ",
            "translation": "He had '''to hurry''' in order to do the work quickly."
          }
        ]
      }
    ],
    "synonyms": [
      "гузэжъон",
      "ш1эхын",
      "хъыжъэн"
    ],
    "type": "verb",
    "ipa": "ɡʷəzaʒʷan"
  },
  {
    "word": "гу1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to worry",
        "examples": [
          {
            "sentence": "Сиянэ '''мэгу1э'''",
            "translation": "My mother '''worries'''."
          },
          {
            "sentence": "Лъыжъым ыкъо заом мак1ом, '''гу1эу''' шысыгъ",
            "translation": "When the old-man's son went to war, he was sitting '''worried'''."
          },
          {
            "sentence": "'''Умыгуа1''', экзамыныр 1эш1эхэу къыч1эк1ыжьын",
            "translation": "'''Don't worry''', the exam will [probably] turn out to be easy."
          },
          {
            "sentence": "Ц1ык1у ц1ык1у, '''сэбэгъэгу1э'''",
            "translation": "Slowly slowly, '''you're making me worry'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəʔan"
  },
  {
    "word": "нэмк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэмык1\"",
        "examples": []
      }
    ],

    "cognate": "нэмыщ1",
    "type": "adj",
    "ipa": "namt͡ʃʼ"
  },
  {
    "word": "самбыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "patient",
        "examples": []
      },
      {
        "meaning": "calm",
        "examples": []
      },
      {
        "meaning": "quiet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saːmbər"
  },
  {
    "word": "самбырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to calm down",
        "examples": []
      },
      {
        "meaning": "to be patient",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "saːmbərən"
  },
  {
    "word": "естыл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stick to the (hot) surface as a result of burning",
        "examples": [
          {
            "sentence": "Шхыныр '''естыл1агъ'''",
            "translation": "The food '''was stuck (from burning)'''."
          },
          {
            "sentence": "Щыуан нэзым '''естылIагъ'''",
            "translation": "'''It stuck (from burning)''' on edge of the pot."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jastəɬʼan"
  },
  {
    "word": "пшэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nettle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʃasan"
  },
  {
    "word": "тегъэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зытегъэк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajʁat͡ʃʼan"
  },
  {
    "word": "зытегъэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lean over",
        "examples": [
          {
            "sentence": "Дэпкъым к1алэм '''зытегъак1э'''",
            "translation": "The boy '''leans himself on''' the wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zətajʁat͡ʃʼan"
  },
  {
    "word": "пхэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back (body), rear",
        "examples": []
      },
      {
        "meaning": "buttock",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуты"
    ],
    "cognate": "пхэ",
    "type": "noun",
    "ipa": "pxat͡ʃʼ"
  },
  {
    "word": "ыгупэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гупэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "əɡʷəpan"
  },
  {
    "word": "гупэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гопэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "əɡʷəpan"
  },
  {
    "word": "1эк1эк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to let someone get away or run away (inadvertently)",
        "examples": [
          {
            "sentence": "К1алэр хьэмэ '''а1эк1эк1ыгъ'''",
            "translation": "The boy '''got away''' from the dogs."
          },
          {
            "sentence": "Шъукъэсыубытмэ '''шъукъыс1эк1эк1ыщтып'''",
            "translation": "If I caught you (pl.) '''you won't get away from me'''."
          },
          {
            "sentence": "Пц1ы к1алэм '''1эк1эк1ыгъ'''.",
            "translation": "The boy '''let''' a lie '''slip away inadvertently'''"
          }
        ]
      },
      {
        "meaning": "to break out of somebody's hands",
        "examples": []
      },
      {
        "meaning": "to miss out inadvertently",
        "examples": [
          {
            "sentence": "К1алэм фылымыр '''1эк1эк1ыгъ'''",
            "translation": "The boy '''missed out''' the film '''incidentally'''."
          },
          {
            "sentence": "К1алэм уахътэр '''1эк1эк1ыгъ'''",
            "translation": "The boy '''lost''' time '''incidentally'''"
          }
        ]
      },
      {
        "meaning": "to skip over",
        "examples": []
      }
    ],
    "synonyms": [
      "1эк1эк1ьын"
    ],
    "cognate": "1эщ1эк1ын",
    "type": "verb",
    "ipa": "ʔat͡ʃʼat͡ʃʼən"
  },
  {
    "word": "1эк1эзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something unintentionally (from one's hand)",
        "examples": [
          {
            "sentence": "Мы1эрысхэр '''къып1эк1эзых'''",
            "translation": "'''You are dropping''' the apples."
          }
        ]
      }
    ],

    "cognate": "1эщ1эзын",
    "type": "verb",
    "ipa": "ʔat͡ʃʼazən"
  },
  {
    "word": "1эк1эук1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to kill somebody inadvertently",
        "examples": [
          {
            "sentence": "Зыфэсакъ, ус1ук1эмэ '''укъыс1эк1эук1эхьащт'''",
            "translation": "Be careful!, if we meet '''I might kill you inadvertently'''\"."
          }
        ]
      }
    ],

    "cognate": "1эщ1эук1эн",
    "type": "verb",
    "ipa": "ʔat͡ʃʼawt͡ʃʼan"
  },
  {
    "word": "нэмык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "different, another (not the same)",
        "examples": [
          {
            "sentence": "'''НэмыкI''' Iоф иI",
            "translation": "He has '''another''' job."
          },
          {
            "sentence": "'''НэмыкI''' к1ал",
            "translation": "'''Another''' boy."
          },
          {
            "sentence": "'''Нэмык1ыр''' джэмышх къысэт",
            "translation": "Give me '''another''' spoon."
          },
          {
            "sentence": "Къак1орэм '''нэмык1ыр''' пак1э тыкъак1у",
            "translation": "Let us go to a '''different''' place next time."
          }
        ]
      }
    ],
    "synonyms": [
      "фэшъхьаф"
    ],
    "cognate": "нэмыщ1",
    "type": "adj",
    "ipa": "namət͡ʃʼ"
  },
  {
    "word": "тегъэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to leave an object or someone on somewhere",
        "examples": [
          {
            "sentence": "К1алэм и ахъщэ 1анэм '''къытыгъэнагъ'''",
            "translation": "The boy '''left''' his money on the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁanan"
  },
  {
    "word": "тенэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become stuck (trapped and unable to move)",
        "examples": []
      },
      {
        "meaning": "to stay, remain (should have the prefix къы- /qə-/)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajnan"
  },
  {
    "word": "ш1энгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1эныгъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʼanʁa"
  },
  {
    "word": "сэлам ехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to greet",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "salaːm jaxən"
  },
  {
    "word": "къорылъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandson",
        "examples": []
      }
    ],

    "cognate": "къуэрылъху",
    "type": "noun",
    "ipa": "qʷarəɬf"
  },
  {
    "word": "зэхэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stretch",
        "examples": []
      }
    ],

    "cognate": "зэхэшын",
    "type": "verb",
    "ipa": "zaxaɕən"
  },
  {
    "word": "нэчыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "marriage contract",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nat͡ʃəħ"
  },
  {
    "word": "нысэпхъапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нысхъапэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəsapχaːpa"
  },
  {
    "word": "стыл1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"естыл1эн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "stəɬʼan"
  },
  {
    "word": "нэк1ьышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэк1ышъо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʼəʃʷa"
  },
  {
    "word": "нэгушъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naɡʷəʃʷa"
  },
  {
    "word": "нэгушъо етын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to slap someone's cheek",
        "examples": [
          {
            "sentence": "Пшъашъэм '''нэгушъо''' к1алэм '''ритыгъ'''",
            "translation": "The girl '''slapped''' the boy; literary: the girl '''give a slap''' to the boy."
          }
        ]
      }
    ],
    "synonyms": [
      "нэпэон",
      "нэпэшъоон"
    ],
    "cognate": "нэпэфэуэн",
    "type": "verb",
    "ipa": "naɡʷəʃʷa jatən"
  },
  {
    "word": "ошъолъапсэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horizon",
        "examples": [
          {
            "sentence": "Л1ыр '''ошъолъапсэм''' к1уагъэ",
            "translation": "The man went to the '''horizon distance'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "waʃʷaɬaːpsa"
  },
  {
    "word": "нэк1ышъо етын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэгушъо етын\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʼəʃʷa"
  },
  {
    "word": "нэк1ышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэгушъо\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nakʼəʃʷa"
  },
  {
    "word": "нэгушъоутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slap (to the cheek)",
        "examples": [
          {
            "sentence": "'''нэгушъоутын''' уфая?",
            "translation": "Do you want '''a slap'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naɡʷəʃʷawtən"
  },
  {
    "word": "емын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"емынэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jamən"
  },
  {
    "word": "къэутхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to splash water",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qawətxən"
  },
  {
    "word": "теутхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"теутхэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajawətxən"
  },
  {
    "word": "пэ1о1ожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whine against someone disagreeably",
        "examples": [
          {
            "sentence": "К1алэр к1элэегъаджэм '''пэ1о1ожьы'''",
            "translation": "The boy '''argues with''' the teacher."
          },
          {
            "sentence": "'''Укъыспэмы1о1ожь'''",
            "translation": "'''Don't argue with me'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "paʔʷaʔʷaʑən"
  },
  {
    "word": "егъэлыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grossly exaggerate,",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁaɮəjan"
  },
  {
    "word": "гъэлыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to single (an animal), to scorch",
        "examples": [
          {
            "sentence": "Чэтыр '''гъэлыгъон'''}}",
            "translation": ""
          },
          {
            "sentence": "Мэлышъхьэр '''гъэлыгъон''' фае}}",
            "translation": ""
          },
          {
            "sentence": "Лъакъохэр '''гъэлыгъонхэ''' фае}}",
            "translation": ""
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁaɮəʁʷan"
  },
  {
    "word": "гъэ1орт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to curve, to bend",
        "examples": [
          {
            "sentence": "Къины гъуч1ы '''пгъэ1орт1эныр'''",
            "translation": "It's hard to '''bend''' an iron."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэ1онт1эн"
    ],
    "type": "verb",
    "ipa": "ʁaʔʷartʼan"
  },
  {
    "word": "гъэ1онт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to curve, to bend",
        "examples": [
          {
            "sentence": "Къины гъуч1ы '''пгъэ1онт1эныр'''",
            "translation": "It's hard to '''bend''' an iron."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэ1орт1эн"
    ],
    "type": "verb",
    "ipa": "ʁaʔʷantʼan"
  },
  {
    "word": "теуп1эп1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spank",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajwpʼapʼan"
  },
  {
    "word": "теутхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to splash water on; to splatter on",
        "examples": [
          {
            "sentence": "Псы '''къэстемыутх'''",
            "translation": "'''Don't splash''' water '''on me'''."
          },
          {
            "sentence": "Псы '''къэстебэутхэ'''",
            "translation": "'''You are splashing''' water '''on me'''."
          },
          {
            "sentence": "Джэхашъом псы '''теутхэн'''",
            "translation": "'''To splash''' water '''on''' the floor."
          },
          {
            "sentence": "К1элэц1ык1ум 1анэм псы '''теутхагъ'''",
            "translation": "The little boy '''splashed''' water '''on''' the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tjawətxən"
  },
  {
    "word": "теутэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sprinkle, To cause (a substance) to fall in fine drops (for a liquid substance) or small pieces (for a solid substance)",
        "examples": [
          {
            "sentence": "хьаджыгъэр 1анэм '''теутэн'''",
            "translation": "'''To sprinkle''' flour on the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajwətan"
  },
  {
    "word": "теутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to knock down",
        "examples": [
          {
            "sentence": "Чэмым бжъакъор '''теутын'''",
            "translation": "'''To knock down''' horns from a cow"
          }
        ]
      },
      {
        "meaning": "to hit with a projectile (bow, rock, bullet)",
        "examples": [
          {
            "sentence": "К1эрахъок1э умыш1ахэу ц1ыфым '''тебыутыщт'''",
            "translation": "'''You will hit''' the person by mistake with the gun."
          },
          {
            "sentence": "Мыжъор дзи, '''теутын''' 1о чъыгым",
            "translation": "Throw a rock, and try '''hitting''' the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajwətən"
  },
  {
    "word": "фэдэ къэгъэчъыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "exactly like, identical in all forms",
        "examples": [
          {
            "sentence": "Сишы мощ '''фэдэ къэгъэчъыгъ'''",
            "translation": "My brother '''is exactly like''' that."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "fada qaʁat͡ʂəʁ"
  },
  {
    "word": "фэдэ къэгъэчъыгъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "identically in all forms",
        "examples": [
          {
            "sentence": "Сишы мощ '''фэдэ къэгъэчъыгъэу''' мак1о",
            "translation": "My brother walks '''exactly like''' that."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "fada qaʁat͡ʂəʁaw"
  },
  {
    "word": "теунэхъухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be ruined due to (beset with unfortunate circumstances that seem difficult or impossible to overcome)",
        "examples": [
          {
            "sentence": "К1алэр алкохолым '''теунэхъухьагъ'''",
            "translation": "The boy '''got ruined due to''' alcohol."
          },
          {
            "sentence": "Л1ыр къумарым '''теунэхъухьагъ'''",
            "translation": "The man '''got ruined due to''' gambling."
          },
          {
            "sentence": "Пачъыхьыр и былым '''теунэхъухьагъ'''",
            "translation": "The king '''got ruined due to''' his fortune."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajnaχʷəħaːn"
  },
  {
    "word": "ыунэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ruin",
        "examples": [
          {
            "sentence": "Пшъашъэм к1алэр '''еунэхъу'''",
            "translation": "The girl '''is ruining''' the boy."
          },
          {
            "sentence": "К1алэм мащинэр '''еунэхъогъ'''",
            "translation": "The boy'''is ruined''' the car."
          },
          {
            "sentence": "'''Уунэхъогъ'''",
            "translation": "'''You are ruined'''."
          },
          {
            "sentence": "Алкохолк1э '''уунэхъушт'''",
            "translation": "'''You will be ruined''' because of alcohol."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawnaχʷʁn"
  },
  {
    "word": "тхьэм емыд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "God forbid, may god not allow it",
        "examples": [
          {
            "sentence": "'''Тхьэм емыд''', к1алэр мэмысмэджэнэу",
            "translation": "'''God forbid''', the boy from getting sick."
          }
        ]
      }
    ],
    "type": "phrase",
    "ipa": "tħam jaməd"
  },
  {
    "word": "сыунэхъугъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "I am ruined (beset with unfortunate circumstances that seem difficult or impossible to overcome)",
        "examples": []
      }
    ],
    "type": "phrase",
    "ipa": "səwnaχʷəʁ"
  },
  {
    "word": "пэщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leadership",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paɕam"
  },
  {
    "word": "пэк1эрэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пык1эрэплъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pət͡ʃʼarapɬən"
  },
  {
    "word": "пык1эрэплъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look after",
        "examples": [
          {
            "sentence": "К1алэр и шыпхъу '''пык1эрэплъы'''",
            "translation": "The boy '''is looking after''' his sister."
          },
          {
            "sentence": "К1алэц1ык1ум '''пык1эрэплъ'''",
            "translation": "'''Look after''' the little boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pət͡ʃʼarapɬən"
  },
  {
    "word": "тек1утэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip on (To let fall in drops), to spill small amount of liquid on",
        "examples": [
          {
            "sentence": "Анэм псы '''тэбэк1утэ!'''",
            "translation": "'''You are dripping''' water on the table!"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajkʷʼətan"
  },
  {
    "word": "шъхьэ1ужъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "idiot (literary: wide head)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħaʔʷəʐʷ"
  },
  {
    "word": "уи1оф ш1оп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "your work is not well (Used to express: You will be in a really problematic situation)",
        "examples": [
          {
            "sentence": "Къак1орэ экзамыным ублэмык1ымэ, '''уи1оф ш1оп'''",
            "translation": "If you don't pass the upcoming exam, '''your work is not well'''."
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "wiʔʷaf ʃʷʼap"
  },
  {
    "word": "1оф хэдзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put someone in a troublesome situation",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔʷaf xad͡zan"
  },
  {
    "word": "егъэтыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "locked",
        "examples": [
          {
            "sentence": "Пчъэр '''егъэтыгъ'''",
            "translation": "The door '''is locked'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaʁatəʁ"
  },
  {
    "word": "нао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a bit (few) (Abzakh dialect)",
        "examples": [
          {
            "sentence": "1елэм шхын '''нао''' ышхыгъэ",
            "translation": "The boy ate '''a bit of''' food."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naːwa"
  },
  {
    "word": "зэхэш1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make out, to discern",
        "examples": [
          {
            "sentence": "Хъурэр джэгумк1э '''зэхэсыш1ык1ырэп'''",
            "translation": "'''I can't make out''' what happening in the game."
          },
          {
            "sentence": "К1алэм ытхыгъэр к1элэегъаджэм '''зэхыш1ык1ырэп'''",
            "translation": "The teacher '''can't make out''' what the boy wrote."
          },
          {
            "sentence": "К1алэм итхак1э '''зэхэсэш1ык1ышъу'''",
            "translation": "'''I can make out''' the boy's writing."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaxaʃʼət͡ʃʼən"
  },
  {
    "word": "гъэчэрэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to twist",
        "examples": []
      },
      {
        "meaning": "to whirl, to spin",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡ʃaraʁʷən"
  },
  {
    "word": "гъэкьэрэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэчэрэгъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁakaraʁʷən"
  },
  {
    "word": "лые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "extra (beyond what is due, usual, expected, or necessary); superfluous, excess, spare",
        "examples": [
          {
            "sentence": "билет '''лые''' горэ шъуиIэба?.",
            "translation": "Do you have an '''extra''' ticket?"
          },
          {
            "sentence": "Ар '''лые''' хъугъэ",
            "translation": "He/she/it became '''extra'''."
          },
          {
            "sentence": "ЦIыф '''лые''' щыIэп",
            "translation": "There is no '''extra''' person."
          }
        ]
      },
      {
        "meaning": "harm, damage",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɮəja"
  },
  {
    "word": "чэщыбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaʃəbsəw"
  },
  {
    "word": "1ушъэшъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whisper",
        "examples": [
          {
            "sentence": "ащ '''Iушъэшъэныр''' икIас",
            "translation": "He likes '''to whisper'''."
          }
        ]
      }
    ],

    "cognate": "1ущэщэн",
    "type": "verb",
    "ipa": "ʔʷəʂaʂan"
  },
  {
    "word": "тхъурыжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dumpling",
        "examples": []
      }
    ],
    "synonyms": [
      "тхъужъ"
    ],
    "type": "noun",
    "ipa": "tχʷərəʐ"
  },
  {
    "word": "1эпс-лъэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "simple, basic",
        "examples": [
          {
            "sentence": "Бгырыпх '''Iэпс-лъэпс''' ыбгы илъ",
            "translation": "A '''simple''' belt is on his waist."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔʷaps ɬaps"
  },
  {
    "word": "нацэ1уцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rough in appearance",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "natsaʔʷtsa"
  },
  {
    "word": "къэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get irritated",
        "examples": [
          {
            "sentence": "'''Сыкъэхы'''",
            "translation": "'''I am getting irritated'''."
          },
          {
            "sentence": "К1алэр '''къэхы'''",
            "translation": "The boy '''is getting irritated'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaxən"
  },
  {
    "word": "къэгъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to irritate",
        "examples": [
          {
            "sentence": "'''Сыкъэбэгъэхы'''",
            "translation": "'''You are irritating me'''."
          },
          {
            "sentence": "К1алэм '''сыкъегъэхы'''",
            "translation": "The boy '''is irritating me'''."
          },
          {
            "sentence": "Уи1оф жъажъэу зэрапш1эрэм '''сыкъегъэхы'''",
            "translation": "The slow way you're doing your work '''is irritating me'''."
          },
          {
            "sentence": "К1алэм пшъашъэр упч1ыхэмк1э '''егъэхы'''",
            "translation": "The boy '''is irritating''' the girl with the questions."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁaxən"
  },
  {
    "word": "ек1ошъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sneak up to",
        "examples": [
          {
            "sentence": "Шак1ор бланэм '''ек1уашъэ'''",
            "translation": "The hunter '''is sneaking up to''' the deer."
          },
          {
            "sentence": "К1алэр '''къысэк1уашъэ''' сымыш1эу",
            "translation": "The boy '''is sneaking me''' without me knowing."
          },
          {
            "sentence": "Уи1оф жъажъэу зэрапш1эрэм '''сыкъегъэхы'''",
            "translation": "The slow way you're doing your work '''is irritating me'''."
          }
        ]
      }
    ],

    "cognate": "ек1уэщэн",
    "type": "verb",
    "ipa": "jakʷʼaʂan"
  },
  {
    "word": "мэк1ошъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sneak up",
        "examples": [
          {
            "sentence": "Шак1ор '''мэк1уашъэ'''",
            "translation": "The hunter '''is sneaking up'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "makʷʼaʂan"
  },
  {
    "word": "к1ошъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэк1ошъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼaʂan"
  },
  {
    "word": "ош1э дэмыш1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "suddenly",
        "examples": [
          {
            "sentence": "К1алэр '''ошIэ-дэмышIэу''' унэм къихьагъ",
            "translation": "The boy '''suddenly''' entered the house."
          }
        ]
      },
      {
        "meaning": "unexpectedly",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "waʃʼa daməʃʼaw"
  },
  {
    "word": "ош1эдэмыш1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ош1э дэмыш1эу\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "waʃʼa daməʃʼaw"
  },
  {
    "word": "тхьэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьэет\"",
        "examples": []
      }
    ],

    "cognate": "насып",
    "type": "noun",
    "ipa": "thatən"
  },
  {
    "word": "тхьэет",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "luck",
        "examples": []
      }
    ],

    "cognate": "насып",
    "type": "noun",
    "ipa": "thajat"
  },
  {
    "word": "гъолагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъолагъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaɮaːʁ"
  },
  {
    "word": "ыш1эу ыш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to do intentionally",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "əʃʼaw əʃʼan"
  },
  {
    "word": "хьайнап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьайнапэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħajənaːp"
  },
  {
    "word": "хьайнапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shame",
        "examples": [
          {
            "sentence": "'''Хьаинап''' уш1эрэба",
            "translation": "Don't you know '''Shame'''?."
          }
        ]
      },
      {
        "meaning": "disgrace",
        "examples": [
          {
            "sentence": "'''Хьаинап''' пц1анэу удэк1нэ",
            "translation": "'''It's disgrace''' to go out naked."
          },
          {
            "sentence": "'''Хьаинапэ''' къэхьын",
            "translation": "to be '''dishonored'''."
          },
          {
            "sentence": "'''Хьаинапэ''' шIын",
            "translation": "to '''shame'''."
          }
        ]
      }
    ],

    "cognate": "хьейнапэ",
    "type": "noun",
    "ipa": "ħajənaːp"
  },
  {
    "word": "напэр тепхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shame",
        "examples": [
          {
            "sentence": "О уиятэ и '''папэр темыхы'''",
            "translation": "'''Don't shame''' your father."
          }
        ]
      }
    ],
    "synonyms": [
      "хьайнапэу ш1ын"
    ],
    "type": "verb",
    "ipa": "naːpar tajxən"
  },
  {
    "word": "хьайнапэу ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shame",
        "examples": [
          {
            "sentence": "О уиятэ '''хьайнапэу умыш1'''",
            "translation": "'''Don't shame''' your father."
          }
        ]
      }
    ],
    "synonyms": [
      "папэр тепхын"
    ],
    "type": "verb",
    "ipa": "naːpar tajxən"
  },
  {
    "word": "щэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap",
        "examples": [
          {
            "sentence": "Шхыныр '''щэхь'''",
            "translation": "'''Wrap''' the food."
          },
          {
            "sentence": "К1алэм и шхын '''ещэхьы'''",
            "translation": "The boy '''wraps''' hisfood."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕaħən"
  },
  {
    "word": "тец1эц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to yell at",
        "examples": [
          {
            "sentence": "Л1ыр к1алэм '''тэц1ац1э'''",
            "translation": "The man'''is yelling at''' the boy."
          },
          {
            "sentence": "Тхьэматэр '''къэтэц1ац1эу''' 1ут",
            "translation": "The leader '''is''' standing there '''yelling at us'''."
          },
          {
            "sentence": "'''Укъэстэмыц1ац1'''",
            "translation": "'''Don't yell at me'''."
          },
          {
            "sentence": "Л1ыжъыр зигъэгуабжьырым '''тэц1ац1э'''",
            "translation": "The old-man '''is yelling at''' anybody that makes him angry."
          },
          {
            "sentence": "'''Шъустэц1эц1энэу''' шъуфая?",
            "translation": "Do you (plural) want '''me to yell at you (plural)'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тэс1эс1эн"
    ],
    "type": "verb",
    "ipa": "tajt͡sʼat͡sʼan"
  },
  {
    "word": "щыгъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shut up; to stop talking; to shush",
        "examples": [
          {
            "sentence": "К1алэм '''щегъэты'''",
            "translation": "The boy '''shuts up'''."
          },
          {
            "sentence": "'''Щыгъэт'''",
            "translation": "'''Shut up'''."
          },
          {
            "sentence": "К1алэм '''щигъэтырэп'''",
            "translation": "The boy '''does not shut up'''."
          },
          {
            "sentence": "Сэ '''щэсэгъэты'''",
            "translation": "'''I shut up'''."
          }
        ]
      }
    ],
    "synonyms": [
      "зэ1унэн",
      "----"
    ],
    "type": "verb",
    "ipa": "ɕəʁatən"
  },
  {
    "word": "зэ1унэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shut up; to stop talking; to shush",
        "examples": [
          {
            "sentence": "'''Зэ1ун'''",
            "translation": "'''Shut up'''."
          },
          {
            "sentence": "Сабыир '''зэ1унэжьи''' быяугъэ",
            "translation": "The child '''shushed''' and became quite."
          }
        ]
      }
    ],

    "cognate": "щыгъэтын",
    "type": "verb",
    "ipa": "zaʔʷənan"
  },
  {
    "word": "ч1эщык1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to elope; to steal a girl for engagement (Circassian tradition)",
        "examples": [
          {
            "sentence": "Пшъашъэр '''ч1эбэщык1ьы'''",
            "translation": "'''You are stealing (engaging)''' the girl."
          },
          {
            "sentence": "Дэхэосыр '''ч1ащык1ьыгъ'''",
            "translation": "Daxawas was '''stolen (engaged)'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tʂaɕəkʼən"
  },
  {
    "word": "хэш1агъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "related (Standing in relation or connection)",
        "examples": [
          {
            "sentence": "физикарэ математикэрэ '''зэхэш1агъэх'''",
            "translation": "Physics and mathematics '''are related to each other'''."
          },
          {
            "sentence": "ар тызеджэрэм '''хэш1агъэп'''",
            "translation": "That is '''not related''' to the thing we are studying."
          },
          {
            "sentence": "мо к1алэр сэ унагъок1э '''къэсхэш1агъэп'''",
            "translation": "That boy '''is not related''' to me by family."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "xaʃʼaːʁ"
  },
  {
    "word": "хэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to relate someone/something to",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "xaʃʼan"
  },
  {
    "word": "егъэпхыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "related",
        "examples": [
          {
            "sentence": "О къап1орэр '''егъэпхыгъэп''' тэ тикъэбэрэу тыкъыстегущы1эрэр",
            "translation": "What you are saying '''is not related''' to our conversation."
          },
          {
            "sentence": "К1алэр зэрэзак1орэр иныбджэгъумэ '''яхэпхыгъ'''",
            "translation": "The way the boy acts '''is related''' to his friends."
          },
          {
            "sentence": "Мо к1алэр сэ '''къысэгъэпхыгъэп''' зыгорэк1и",
            "translation": "That boy '''is not related to me''' in any way."
          },
          {
            "sentence": "А пшъашъэр о '''къыогъэпхыгъэп'''",
            "translation": "That girl '''is not related to you'''."
          },
          {
            "sentence": "Физикарэ математикэрэ '''зэгъэпхыгъэх'''",
            "translation": "Physics and mathematics are '''related to''' each other'''."
          },
          {
            "sentence": "Машинэм и фэбагъэ и шъо '''егъэпхыгъ'''",
            "translation": "The car's heat '''is related to''' its color."
          }
        ]
      },
      {
        "meaning": "tied to",
        "examples": [
          {
            "sentence": "Л1ыр зэрэгумэк1ырэ '''егъэпхыгъ''' яунэ щыхъурэм",
            "translation": "The man's sadness '''is tied to''' what happens in his house."
          },
          {
            "sentence": "физикар Матиматикэм '''егъэпхыгъ'''",
            "translation": "Physics '''is tied to''' mathematics."
          },
          {
            "sentence": "Уишъхьэ узы '''егъэпхыгъэп''' хьабэу ущыхырэм, ар учъыерэпи ары.",
            "translation": "Your headache '''is not tied to''' the pills you're eating, it is because you aren't sleeping."
          }
        ]
      },
      {
        "meaning": "depends on",
        "examples": [
          {
            "sentence": "Университетэу узык1ошъущтэр '''егъэпхыгъ''' еджап1эм итестыхэу уш1ыхэрэмэ",
            "translation": "The university you're going to go '''depends on''' the tests you do in school."
          },
          {
            "sentence": "Ахъщэу 1офымк1э къыуатыщтэр '''егъэпхыгъ''' шъыд фэдиз сыхьат узылажьэрэр",
            "translation": "The money they gonna give you in work '''depends''' how many hours you work."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "xaʃʼaːʁ"
  },
  {
    "word": "фикъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suffice, to be sufficient / enough; to last out",
        "examples": [
          {
            "sentence": "К1алэр мы1эрыс т1у фэпхьымэ '''фикъущт'''",
            "translation": "Two apples '''will be enough for''' the boy."
          },
          {
            "sentence": "Непэ тызэрэуагъэр '''къэсфекъу'''",
            "translation": "The amount we fought today '''is enough for me'''."
          },
          {
            "sentence": "Шхынэу сышхыгъэр '''къэсфикъурэп''', джыри сэлак1э",
            "translation": "The amount of food I ate '''is not enough for me''', I am still hungry."
          },
          {
            "sentence": "Пачъыхьым зыгори '''фикъужьрэп'''",
            "translation": "Nothing '''is enough for''' the king."
          },
          {
            "sentence": "Экзамыным къисхыгъэр сэ '''къэсфекъу'''",
            "translation": "What I got in the exam '''is enough for me'''"
          },
          {
            "sentence": "Ахъщэу къыостыгъэр '''зыфигъэкъужь'''",
            "translation": "'''Be enough''' with the money I gave you."
          }
        ]
      },
      {
        "meaning": "to be superior in strength; to prevail",
        "examples": [
          {
            "sentence": "Л1ыр ипый фикъурэп",
            "translation": "The man '''can't prevail against''' his enemy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "fəjqʷən"
  },
  {
    "word": "нэхай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sullen, gloomy",
        "examples": [
          {
            "sentence": "ЛIы нэхай",
            "translation": "Gloomy man."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "naxaːj"
  },
  {
    "word": "гъэупэпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something sharp, to sharp",
        "examples": [
          {
            "sentence": "К1алэм къэлэмыр '''егъэупэпц1ы'''",
            "translation": "The boy '''is sharping''' the pencil."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawpapt͡sʼən"
  },
  {
    "word": "упэпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something sharp, to sharp",
        "examples": [
          {
            "sentence": "К1алэм къэлэмыр '''еупэпц1ы'''",
            "translation": "The boy '''is sharping''' the pencil."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wpapt͡sʼən"
  },
  {
    "word": "зыгок1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "by something",
        "examples": [
          {
            "sentence": "К1алэм мыжъор ыдзыгъ '''зыгок1э'''",
            "translation": "The boy threw the rock '''with something'''."
          }
        ]
      },
      {
        "meaning": "by someone",
        "examples": []
      },
      {
        "meaning": "by any chance",
        "examples": [
          {
            "sentence": "'''Зыгорэк1э''' плъэгъугъа сишы?",
            "translation": "Did you see my brother '''by any chance'''?"
          }
        ]
      }
    ],
    "type": "intj",
    "ipa": "zəɡʷarat͡ʃʼa"
  },
  {
    "word": "мыщыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saltless, something that is not salty",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "məɕəʁʷ"
  },
  {
    "word": "ифын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to banish/kick out/eject/expel someone out from inside something",
        "examples": [
          {
            "sentence": "Сиянэрэ сиятэрэ унэм '''сыкъырафыгъ'''",
            "translation": "My mother and father '''kicked me out of''' the house."
          },
          {
            "sentence": "Л1ым к1алэр унэм '''рифыгъ''' 1аеу мэзак1оу щытыгъи ары",
            "translation": "The man '''ejected''' the boy '''from''' the house because he was behaving inappropriately."
          },
          {
            "sentence": "Унэм '''усфыщт''' умыуцумэ",
            "translation": "'''I will kick you out of''' the house if you don't stop."
          }
        ]
      },
      {
        "meaning": "(slang) to win against someone dominantly or overwhelmingly",
        "examples": [
          {
            "sentence": "Джэгумк1э '''исфыгъ''' сишы",
            "translation": "In the game, '''I won against''' my brother '''dominantly'''"
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəfən"
  },
  {
    "word": "нэмы1эк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "so much that",
        "examples": [
          {
            "sentence": "Бэрэ сычъагъэ '''нэмы1эк1э''' сыкъэмэхы пэтыгъ",
            "translation": "I ran '''so much that''' I almost fainted."
          },
          {
            "sentence": "Сысмэджэгъагъэ '''нэмы1эк1э''' гъолъып1эм мэфищэ сыкъыхэк1ыгъэп",
            "translation": "I was sick '''so much that''' I didn't get up from bed for three days."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naməʔat͡ʃʼa"
  },
  {
    "word": "къэмэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to faint, to pass out",
        "examples": [
          {
            "sentence": "Бэрэ сычъагъэ нэмы1эк1э '''сыкъэмэхы''' пэтыгъ",
            "translation": "I ran so much that I almost '''fainted'''."
          },
          {
            "sentence": "Мы мафэм джыри такъэ сыхэтымэ, '''сыкъэмэхыщт'''",
            "translation": "If I stay one more minute in this heat, I will pass out."
          },
          {
            "sentence": "Л1ыр ащ инахэ хауи '''ыгъэмэхыгъ'''",
            "translation": "The man hit him in the face and '''made him pass out'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qamaxən"
  },
  {
    "word": "к1одыжьыгъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1одыгъош1у\"",
        "examples": []
      }
    ],

    "cognate": "к1уэдыгъуаф1э",
    "type": "adj",
    "ipa": "kʷʼadəʑəʁʷaʃʷʼ"
  },
  {
    "word": "-гъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "easy",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "ʁʷaʃʷʼ"
  },
  {
    "word": "к1одыгъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "something or someone that is easy to lose",
        "examples": []
      },
      {
        "meaning": "something or someone that is easy to extinc",
        "examples": []
      }
    ],

    "cognate": "к1уэдыгъуаф1э",
    "type": "adj",
    "ipa": "kʷʼadəʁʷaʃʷʼ"
  },
  {
    "word": "шъхьэк1э трехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be insane",
        "examples": [
          {
            "sentence": "'''шъхьэк1э утреха?'''}}.",
            "translation": ""
          },
          {
            "sentence": "к1алэр '''шъхьэк1э трехы'''}}.",
            "translation": ""
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʂħat͡ʃʼa trajxən"
  },
  {
    "word": "шъхьэ къы1эпык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lost one's mind; to become mad; to go crazy; to lose it",
        "examples": [
          {
            "sentence": "'''шъхьэ къып1эпык1ыгъа?'''",
            "translation": "Did you gone mad?"
          },
          {
            "sentence": "к1алэм '''ышъхьэ къы1эпык1ыгъ'''",
            "translation": "The boy gone mad."
          }
        ]
      }
    ],
    "synonyms": [
      "ишъо ик1ын"
    ],
    "type": "verb",
    "ipa": "ʂħa qəʔapət͡ʃʼən"
  },
  {
    "word": "ишъо ик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lost one's mind; to become mad; to go crazy; to lose it",
        "examples": [
          {
            "sentence": "'''уишъо уик1ыгъа?'''",
            "translation": "Did you gone mad?"
          },
          {
            "sentence": "к1алэр '''ишъо ик1ыгъ'''",
            "translation": "The boy gone mad."
          }
        ]
      }
    ],
    "synonyms": [
      "шъхьэ къы1эпык1ын"
    ],
    "type": "verb",
    "ipa": "jəʃʷa jət͡ʃʼən"
  },
  {
    "word": "енэгуягъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "probably, very likely",
        "examples": [
          {
            "sentence": "Непэ къещхынкIэ '''енэгуягъо'''",
            "translation": "It is gonna rain today '''very likely'''."
          },
          {
            "sentence": "А Iофыр кIыхьэ-лыхьэ хъункIэ '''енэгуягъо'''",
            "translation": "That work will become really long '''probably'''."
          },
          {
            "sentence": "'''Енэгуягъо''', сэ сит1ысып1э утес",
            "translation": "I think you’re sitting in '''my seat'''."
          },
          {
            "sentence": "Ар неущы къэкIонкIэ '''енэгуягъо'''",
            "translation": "He will '''probably ''' come tomorrow."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "janaɡʷəjaːʁʷa"
  },
  {
    "word": "енэгуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "probably, very likely",
        "examples": [
          {
            "sentence": "Непэ къещхынкIэ '''енэгуй'''",
            "translation": "It is gonna rain today '''very likely'''."
          },
          {
            "sentence": "А Iофыр кIыхьэ-лыхьэ хъункIэ '''енэгуй'''",
            "translation": "That work will become really long '''probably'''."
          },
          {
            "sentence": "'''Енэгуй''', сэ сит1ысып1э утес",
            "translation": "I think you’re sitting in '''my seat'''."
          },
          {
            "sentence": "Ар неущы къэкIонкIэ '''енэгуй'''",
            "translation": "He will '''probably ''' come tomorrow."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "janaɡʷəj"
  },
  {
    "word": "енэгуен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to assume, to suppose",
        "examples": [
          {
            "sentence": "Непэ къещхынкIэ '''сенэгуй'''",
            "translation": "'''I assume''' it is gonna rain today."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "janaɡʷəjan"
  },
  {
    "word": "шъуатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alcohol drink",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷaːta"
  },
  {
    "word": "фигъэпэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фегъэпэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "fəjʁapan"
  },
  {
    "word": "къегъэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make it fall snow, to make it snow",
        "examples": [
          {
            "sentence": "Тхьэм '''къырегъэсы'''",
            "translation": "God '''makes it snow'''."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) to exaggerate",
        "examples": [
          {
            "sentence": "К1алэм '''къырегъэсы'''",
            "translation": "The boy '''is exaggerating'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "verb",
    "ipa": "qeʁasən"
  },
  {
    "word": "къэучъы1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become cold",
        "examples": [
          {
            "sentence": "Чэщым '''къэучъы1ы'''",
            "translation": "In the night '''it becomes cold'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qawtʂəʔən"
  },
  {
    "word": "къэгъэучъы1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make it cold",
        "examples": [
          {
            "sentence": "Тхьэм дунаер '''къегъэучъы1ы'''",
            "translation": "God '''makes''' the world '''cold'''."
          }
        ]
      },
      {
        "meaning": "(Kfar Kama dialect) to be naughty",
        "examples": [
          {
            "sentence": "К1алэм '''къыгъэучъы1ы'''",
            "translation": "The boy '''is being naughty'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁawtʂəʔən"
  },
  {
    "word": "пыц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk shit about someone, to speak ill of someone",
        "examples": [
          {
            "sentence": "к1алэм '''пэц1эх'''",
            "translation": "'''They are talking shit about''' the boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pətsʼan"
  },
  {
    "word": "хэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour (liquid) into",
        "examples": [
          {
            "sentence": "К1алэм чъыгым псы '''хек1э'''",
            "translation": "t=The boy '''pours''' water into the tree."
          },
          {
            "sentence": "Уишхын ешъон '''хэмык1'''.",
            "translation": "t='''Don't pour''' water into your food."
          },
          {
            "sentence": "Маш1ом газ '''хапк1эмэ''', нахь къызч1эстыщт.",
            "translation": "t='''If you pour''' gas into fire, it will blaze more."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xat͡ʃʼan"
  },
  {
    "word": "хэк1ьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хэк1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xakʲʼan"
  },
  {
    "word": "къэбэр и1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be aware",
        "examples": [
          {
            "sentence": "К1алэр унэм ихьагъ унэр зием '''къэбэр имы1эу'''",
            "translation": "t=The entered the house '''without''' the owner of the house '''being aware'''."
          },
          {
            "sentence": "Чылэмк1э л1агъэмк1э '''къэбэр уи1а'''?",
            "translation": "t='''Are you aware''' of the person that died in the village."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "χabar jəʔan"
  },
  {
    "word": "зыгъэгусэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take offense, to be offended, to feel hurt, to resent",
        "examples": [
          {
            "sentence": "К1элэц1ык1ум '''зегъэгусэ''' иянэ 1эш1ухэр къыритрэхэпи ары",
            "translation": "t=The little boys '''resented''' because his mother doesn't give him sweets."
          },
          {
            "sentence": "К1алэм сехъоным '''зигъэгусагъ'''",
            "translation": "t=The boy '''took offense''' when I cursed him."
          },
          {
            "sentence": "'''Зымыгъэгус''', ори уисырэ къэсыщт",
            "translation": "t='''Don't feel hurt''', your turn will arrive."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəʁəɡʷəsan"
  },
  {
    "word": "зегъэш1ужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reconcile between, to restore a friendly relationship",
        "examples": [
          {
            "sentence": "Сэ к1алэмрэ пшъашъэмрэ '''зэсгъэш1ужьыгъэх'''",
            "translation": "t='''I reconciled between''' the boy and the girl."
          },
          {
            "sentence": "Сиянэ сэрэ сишырэ '''тызигъэш1ужьыгъ'''",
            "translation": "t=My mother '''reconciled between''' me with my brother."
          },
          {
            "sentence": "К1алэм зэныджэгъухэр '''зегъэш1ужьых'''",
            "translation": "t=The boy '''reconciles between''' the friends."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zajʁaʃʷʼəʑən"
  },
  {
    "word": "зэш1ожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reconcile oneself with, to restore a friendly relationship between oneself with someone else, to forgive or smooth over an argument or fight",
        "examples": [
          {
            "sentence": "Сэ о '''усэш1ужьы'''",
            "translation": "t='''I am reconciling with you'''."
          },
          {
            "sentence": "К1алэм пшъашъэм '''еш1ужьыгъ'''",
            "translation": "t=The boy '''reconciled with''' the girl."
          },
          {
            "sentence": "К1алэр тэ '''къытэш1ужьыщтэп''' етш1агъэм фэш1ык1э",
            "translation": "t=The boy '''won't reconcile with us''' because of what we did to him."
          },
          {
            "sentence": "Мэфэк1ым '''шъузэш1ужьын''' фай",
            "translation": "t=In the holiday '''you''' need to '''reconcile with each other'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʃʷʼəʑən"
  },
  {
    "word": "мэфэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "holiday",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mafatʃʼ"
  },
  {
    "word": "шъэджашъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "huge, vast, enormous",
        "examples": [
          {
            "sentence": "Хьэ '''шъэджашъ'''",
            "translation": "Huge '''dog'''."
          },
          {
            "sentence": "Ц1ыфы '''шъэджашъэр'''",
            "translation": "The enormous '''man'''."
          }
        ]
      }
    ],
    "synonyms": [
      "джадэ",
      "шъэгьашъ"
    ],
    "type": "adj",
    "ipa": "ʂad͡ʒaːʂ"
  },
  {
    "word": "дэхэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "probably",
        "examples": [
          {
            "sentence": "К1алэр дучаным к1уагъэу '''дэхэт'''",
            "translation": "The boy '''probably''' went to the shop."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "daxat"
  },
  {
    "word": "ч1эк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "probably",
        "examples": [
          {
            "sentence": "К1алэр дучаным к1уагъэу '''ч1эк1ын'''",
            "translation": "The boy '''probably''' went to the shop."
          }
        ]
      }
    ],
    "synonyms": [
      "ч1эк1ьын",
      "1ек1ын"
    ],
    "type": "adv",
    "ipa": "t͡ʂʼat͡ʃʼən"
  },
  {
    "word": "ч1эк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1эк1ын"
    ],
    "type": "adv",
    "ipa": "t͡ʂʼakʼən"
  },
  {
    "word": "1ек1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ч1эк1ьын",
      "ч1эк1ын"
    ],
    "type": "adv",
    "ipa": "ʔʲat͡ʃʼən"
  },
  {
    "word": "теон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hit toward the top of",
        "examples": [
          {
            "sentence": "УатэкIэ к1алэр 1анэм '''тео'''",
            "translation": "The boy '''is hitting''' the top of the table with a hammer."
          }
        ]
      },
      {
        "meaning": "to knock",
        "examples": [
          {
            "sentence": "К1алэр пчъэм '''теуагъ'''",
            "translation": "The boy '''knocked''' the door."
          }
        ]
      },
      {
        "meaning": "to pat",
        "examples": [
          {
            "sentence": "Сиянэ сишъхьэ '''къытео'''",
            "translation": "My mother '''is patting''' my head."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajwan"
  },
  {
    "word": "шхак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "eater",
        "examples": []
      },
      {
        "meaning": "a person that eats a lot",
        "examples": []
      },
      {
        "meaning": "a person that really enjoys eating",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxːkʷʼa"
  },
  {
    "word": "дежъыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"дежъыон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dajʐəwan"
  },
  {
    "word": "хьалы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wedge",
        "examples": [
          {
            "sentence": "Пхъэ хьал",
            "translation": "Wooden wedge."
          },
          {
            "sentence": "ГъучI хьал",
            "translation": "Iron wedge."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaːlə"
  },
  {
    "word": "мэчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʃan"
  },
  {
    "word": "кьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʰan"
  },
  {
    "word": "чэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be cracked",
        "examples": [
          {
            "sentence": "1анэр '''мэчагъ'''",
            "translation": "The table '''is cracked'''."
          },
          {
            "sentence": "Апчыр '''мачэ'''",
            "translation": "The glass '''is cracking'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃan"
  },
  {
    "word": "гъэчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crack",
        "examples": [
          {
            "sentence": "Апчыр к1алэм '''ыгъэчагъ'''",
            "translation": "The boy '''cracked''' the glass."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡ʃan"
  },
  {
    "word": "зэпыкьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэпычын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapəkʰən"
  },
  {
    "word": "пыдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sew something to something",
        "examples": [
          {
            "sentence": "Бзылъфыгъэм 1уданыр джанэм '''педэ'''",
            "translation": "t=The woman '''sews''' the string to the shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pədan"
  },
  {
    "word": "нэмысыпэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "before it reaches a time",
        "examples": [
          {
            "sentence": "Бзылъфыгъэр унэм '''къэмысыпэу''', унэр шъугъэкъабз",
            "translation": "Before the women arrives, clear (pl.) the house."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "naməsəpaw"
  },
  {
    "word": "пыук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыупк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəwt͡ʃʼən"
  },
  {
    "word": "щыгъын пылъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coat hanger, peg (device used to hang up coats, shirts, etc)",
        "examples": [
          {
            "sentence": "'''Щыгъын пылъап1эм''' къыпых джанэр",
            "translation": "Pick the shirt from '''the coat hanger'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɕəʁən pəɬaːpʼa"
  },
  {
    "word": "пылъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пылъап1\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пылъалъэ"
    ],
    "type": "noun",
    "ipa": "pəɬaːpʼa"
  },
  {
    "word": "щэгьэгъоуж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щэджэгъоуж\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaɡʲaʁʷaʒ"
  },
  {
    "word": "мылъфыкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unbegotten",
        "examples": []
      },
      {
        "meaning": "nonuterine son",
        "examples": []
      },
      {
        "meaning": "stepchild, stepson",
        "examples": []
      }
    ],

    "cognate": "мылъхукъуэ",
    "type": "noun",
    "ipa": "məɬfəqʷa"
  },
  {
    "word": "лъыфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъфыгъэ\"",
        "examples": []
      }
    ],

    "cognate": "лъыхугъэ",
    "type": "noun",
    "ipa": "ɬfəʁa"
  },
  {
    "word": "лъфыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "child delivery, childbirth",
        "examples": []
      },
      {
        "meaning": "child",
        "examples": []
      },
      {
        "meaning": "begotten",
        "examples": []
      }
    ],
    "cognate": "лъыхугъэ",
    "type": "noun",
    "ipa": "ɬfəʁa"
  },
  {
    "word": "к1элэ-гъуал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "child",
        "examples": []
      }
    ],

    "cognate": "щ1элэ-гъуал",
    "type": "noun",
    "ipa": "t͡ʃʼala ʁʷaːɮa"
  },
  {
    "word": "папк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for",
        "examples": []
      }
    ],

    "cognate": "папщ1э",
    "type": "conj",
    "ipa": "paːpt͡ʃʼa"
  },
  {
    "word": "ич1ып1эк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "instead of",
        "examples": [
          {
            "sentence": "к1алэр мак1о пшъашъэм '''ич1ып1эк1э'''",
            "translation": "The boy went '''instead''' of the girl."
          },
          {
            "sentence": "Мощ '''ич1ып1эк1э''' мы1эрыпс къэхь",
            "translation": "'''Instead''' of that bring apple."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jət͡ʂʼəpʼat͡ʃʼa"
  },
  {
    "word": "гъэунэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to enrich, to make rich",
        "examples": [
          {
            "sentence": "ДэIэпыIагъэу гъэунэн фае",
            "translation": "One need to enrich helpfully."
          }
        ]
      },
      {
        "meaning": "to do good to someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawənən"
  },
  {
    "word": "къэгъэгъунэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to guard someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaʁaʁaʁʷənan"
  },
  {
    "word": "нэ1э тегъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to protect",
        "examples": [
          {
            "sentence": "'''Уинэ1э къыптэзгъэтыщт'''",
            "translation": "'''I will protect you'''."
          },
          {
            "sentence": "Шыумэ '''нэ1э къэстагъэты'''",
            "translation": "The horsemen '''are protecting me'''."
          }
        ]
      },
      {
        "meaning": "to look after",
        "examples": [
          {
            "sentence": "К1алэр ишы '''инэ1э тегъэты'''",
            "translation": "The boy '''looks after''' his brother."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "naʔa taʁatən"
  },
  {
    "word": "к1эен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smear",
        "examples": [
          {
            "sentence": "кIашъор кIэен",
            "translation": "smear the ceiling"
          }
        ]
      },
      {
        "meaning": "to daub all over",
        "examples": []
      }
    ],
    "synonyms": [
      "еин"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼajan"
  },
  {
    "word": "еин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эен\"",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эен"
    ],
    "type": "verb",
    "ipa": "jajən"
  },
  {
    "word": "к1эегъулъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shay away",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼajaʁʷəɬən"
  },
  {
    "word": "к1эух",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "end",
        "examples": [
          {
            "sentence": "Мазэм '''ик1эух'''",
            "translation": "'''End of''' the forest."
          }
        ]
      },
      {
        "meaning": "closing, conclusive",
        "examples": [
          {
            "sentence": "'''К1эух''' псалъэ",
            "translation": "'''Closing''' word."
          }
        ]
      },
      {
        "meaning": "(literally) tail end (the last part of a period of time, event, or situation; the concluding or final part)",
        "examples": [],
        "tags": [
          "literally"
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼawəx"
  },
  {
    "word": "адэк1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there (place)",
        "examples": [
          {
            "sentence": "Ц1ыфыхэм '''адэк1ом''' к1уагъэх",
            "translation": "The people went there."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aːdakʷʼa"
  },
  {
    "word": "к1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to overtake someone and surpass him, to catch up with someone",
        "examples": [
          {
            "sentence": "К1алэр ыпэ итым '''кIэхьан'''",
            "translation": "The boy '''to catch up with''' the one in front of him."
          },
          {
            "sentence": "IофышIэ пэрытым '''кIэхьан'''",
            "translation": "'''To catch up with''' an advanced worker."
          },
          {
            "sentence": "Шыр адрэ шым кIэхьанкIэ тIэкIу нахь иIэжьэп",
            "translation": "'''To catch up with''' an advanced worker."
          }
        ]
      }
    ],
    "synonyms": [
      "1ук1эхьан"
    ],
    "cognate": "щ1эхьэн",
    "type": "verb",
    "ipa": "t͡ʃʼəħaːn"
  },
  {
    "word": "ч1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go under something",
        "examples": [
          {
            "sentence": "К1алэц1ык1ур 1анэ ч1эгъым '''ч1ахьэ'''",
            "translation": "The little boys '''goes under''' the table."
          }
        ]
      }
    ],

    "cognate": "щ1эхьэн",
    "type": "verb",
    "ipa": "t͡ʂʼəħan"
  },
  {
    "word": "1эк1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to receive, to gain, to take possession of, to get ahold of",
        "examples": [
          {
            "sentence": "К1алэм шхын бэ '''1эк1эхьан''' фай",
            "translation": "The boy needs '''to receive''' a lot food."
          },
          {
            "sentence": "Видио-джэгумк1э '''къэс1эк1эхьагъ''' чатэ",
            "translation": "In the video game, '''I got a hold of''' a sword."
          },
          {
            "sentence": "Л1ым ахъщ '''1эк1ахьэрэп'''",
            "translation": "The man '''does not receive''' money."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔət͡ʃʼaħaːn"
  },
  {
    "word": "къы1эк1эхьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эк1эхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəʔat͡ʃʼaħaːn"
  },
  {
    "word": "1эк1эгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone get ahold of something, to make someone receive something",
        "examples": [
          {
            "sentence": "К1алэм ар '''къызы1эк1егъэхьан''' фай",
            "translation": "The boy needs '''to make himself get it'''."
          }
        ]
      },
      {
        "meaning": "to supply",
        "examples": [
          {
            "sentence": "цIыфмэ товархэр аIэкIэгъэхьаных",
            "translation": "'''Supply''' goods to the people."
          },
          {
            "sentence": "заводым оборудованиякIэ '''IэкIэгъэхьан'''",
            "translation": "'''Supply''' the factory with new equipment"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔət͡ʃʼaħaːn"
  },
  {
    "word": "1эч1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эк1эхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔat͡ʃʼaħaːn"
  },
  {
    "word": "тегощэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to distribute between, to divide between, to split between",
        "examples": [
          {
            "sentence": "Л1ым ич1ыгу ик1алэхэм '''ятегощагъ'''",
            "translation": "The man '''divided''' his land to his boys."
          },
          {
            "sentence": "Нанэм чоколадэр исабыймэ зэфэдизэу '''ятегощагъ'''",
            "translation": "The mother '''distribute''' the chocolate equally '''between''' her children."
          },
          {
            "sentence": "IофшIэныр IофышIэмэ '''атегощэн'''",
            "translation": "'''To split''' work '''among''' employees."
          },
          {
            "sentence": "Нек1о 1офыр тэ тыт1умк1э '''зэтэтегощэщт'''",
            "translation": "Let's '''split''' the work '''among us'''."
          },
          {
            "sentence": "Псэу у1ыгъыр тэрэзэу  къэгъагъэхэм '''атепыгощэн''' фай",
            "translation": "'''You''' need '''to distribute''' the water you have '''between''' the flowers."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷaɕən"
  },
  {
    "word": "гырзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to moan, to groan",
        "examples": []
      },
      {
        "meaning": "to suffer, to languish",
        "examples": []
      }
    ],
    "synonyms": [
      "щэ1ун"
    ],
    "type": "verb",
    "ipa": "ɣərzən"
  },
  {
    "word": "щэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to moan, to groan",
        "examples": [
          {
            "sentence": "Сымаджэм '''щэ1уныр''' ихабз",
            "translation": "It is the sick's habit '''to groan'''."
          }
        ]
      },
      {
        "meaning": "to suffer, to languish",
        "examples": []
      }
    ],
    "synonyms": [
      "гырзын"
    ],
    "type": "verb",
    "ipa": "ɕaʔʷən"
  },
  {
    "word": "зэужэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "all",
        "examples": [
          {
            "sentence": "Пшъашъэ '''зэужэуым''' уанахьы дах",
            "translation": "You are the most beautiful '''among all''' the girls."
          },
          {
            "sentence": "Синыбджэгъу '''зэужэум''' сыкъеджэщт непчыхьэ",
            "translation": "I will call '''all''' my friends tonight."
          }
        ]
      }
    ],
    "synonyms": [
      "зэк1э",
      "пстэури"
    ],
    "type": "det",
    "ipa": "zawʒaw"
  },
  {
    "word": "пс1ашъухъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пц1ашхъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пц1ашхъо"
    ],
    "type": "noun",
    "ipa": "psˤʼaːʃʷχʷa"
  },
  {
    "word": "ет1апц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chernozem, black soil",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1апс1э"
    ],
    "type": "noun",
    "ipa": "jatʼaːpt͡sʼa"
  },
  {
    "word": "ет1апс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ет1апц1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1апц1э"
    ],
    "type": "noun",
    "ipa": "jatʼaːpt͡sʼa"
  },
  {
    "word": "шъутыпц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bare naked, bare-assed, bare-bottomed, a person that his buttock/butt is visible",
        "examples": [
          {
            "sentence": "К1алэр '''шъутыпц1э'''",
            "translation": "The boy '''is bare naked'''."
          },
          {
            "sentence": "'''Шъутыпц1эу''' къэпк1охьымэ укъагъэуцут",
            "translation": "'''If you''' go around '''half naked''' they gonna arrest you."
          }
        ]
      },
      {
        "meaning": "butt-naked",
        "examples": []
      }
    ],
    "synonyms": [
      "шъутыпс1э"
    ],
    "type": "adj",
    "ipa": "ʃʷətəpsʼa"
  },
  {
    "word": "шъхьапц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bareheaded, a person without any cover on the head like headgear, hat and veil.",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьапс1э"
    ],
    "type": "noun",
    "ipa": "ʂħaːpt͡sʼa"
  },
  {
    "word": "шъхьапс1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьапц1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьапц1э"
    ],
    "type": "noun",
    "ipa": "ʂħaːpt͡sʼa"
  },
  {
    "word": "ц1энлъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crawl",
        "examples": []
      },
      {
        "meaning": "to walk dragging oneself",
        "examples": []
      }
    ],
    "synonyms": [
      "пшын"
    ],
    "type": "verb",
    "ipa": "t͡sʼaɬan"
  },
  {
    "word": "гъолагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъонлагъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷaɮaːʁa"
  },
  {
    "word": "гъонлагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skinny, slim (generally beyond what looks beautiful)",
        "examples": [
          {
            "sentence": "Ц1ыф '''гъонлагъу'''",
            "translation": "A '''slim''' person."
          },
          {
            "sentence": "'''гъонлагъужъэр'''",
            "translation": "'''The very skinny guy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъолагъэ"
    ],
    "type": "adj",
    "ipa": "ʁʷanɮaːʁa"
  },
  {
    "word": "хьамлашк1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːmɮaːʃkʷʼ"
  },
  {
    "word": "к1элэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaɮaχʷ"
  },
  {
    "word": "к1элэхъуц1ык1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "little boy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaɮaχʷt͡sʼəkʷʼ"
  },
  {
    "word": "чэзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time",
        "examples": [
          {
            "sentence": "Нэшэ-хъырбыдзым ичэзыу",
            "translation": "It is '''time''' the watermelon."
          },
          {
            "sentence": "Сыдрэ Iофыми чэзыу иI",
            "translation": "There is '''time''' for every work."
          }
        ]
      },
      {
        "meaning": "turn",
        "examples": [
          {
            "sentence": "Ащ ичэзыу ыхьыгъах",
            "translation": "He took them on his '''turn'''."
          }
        ]
      }
    ],
    "synonyms": [
      "уахътэ",
      "игъу"
    ],
    "type": "noun",
    "ipa": "t͡ʃazəw"
  },
  {
    "word": "игъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time",
        "examples": [
          {
            "sentence": "щэджэгъуашхэм игъу",
            "translation": "Launch time."
          },
          {
            "sentence": "игъо ифэн",
            "translation": "to be in time."
          }
        ]
      },
      {
        "meaning": "good mood",
        "examples": [
          {
            "sentence": "цIыфым '''игъоуи''' игъуаджэуи къыхэкIы",
            "translation": "a person has a '''good mood''' and a bad one."
          }
        ]
      }
    ],
    "synonyms": [
      "уахътэ",
      "чэзыу"
    ],
    "type": "noun",
    "ipa": "jəʁʷa"
  },
  {
    "word": "игъуадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misfortune time",
        "examples": [
          {
            "sentence": "Игъуаджэ къехъулIагъ",
            "translation": "A '''misfortune''' happened to him."
          }
        ]
      },
      {
        "meaning": "accident",
        "examples": [
          {
            "sentence": "Игъуаджэ хъугъэ",
            "translation": "An '''accident''' has happened."
          }
        ]
      }
    ],
    "synonyms": [
      "тхьамык1агъу",
      "насыпынчъэ",
      "тхьамык1эгъо",
      "гъэны",
      "мыгъо"
    ],
    "type": "noun",
    "ipa": "jəʁʷaːd͡ʒ"
  },
  {
    "word": "тхьамык1агъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misfortune time; miserable time",
        "examples": [
          {
            "sentence": "ТхьамыкIагъо къыщышIыгъ",
            "translation": "A '''misfortune''' has happened to him."
          }
        ]
      }
    ],
    "synonyms": [
      "игъуадж",
      "гъэны",
      "насыпынчъэ",
      "тхьамык1эгъо"
    ],
    "type": "noun",
    "ipa": "tħaːmət͡ʃʼaːʁʷ"
  },
  {
    "word": "пчъэ и1эп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there is no solution",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "pt͡ʂa jəʔap"
  },
  {
    "word": "гущы1анчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wordless",
        "examples": []
      },
      {
        "meaning": "speechless",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəɕəʔant͡ʂʼa"
  },
  {
    "word": "тхак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "writer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txaːkʷʼa"
  },
  {
    "word": "п1ысак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fucker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʼəsaːkʷʼa"
  },
  {
    "word": "жьыдзак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "air force soldier",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʑəd͡zaːkʷʼa"
  },
  {
    "word": "-п1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to nouns) location",
        "examples": [],
        "tags": [
          "added to nouns"
        ]
      }
    ],
    "type": "suffix",
    "ipa": "pʼa"
  },
  {
    "word": "зэ1ук1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "place of meeting",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zaʔʷət͡ʃʼaːpʼa"
  },
  {
    "word": "зэ1ук1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to meet-up",
        "examples": []
      },
      {
        "meaning": "to have a meeting or an appointment",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔʷət͡ʃʼan"
  },
  {
    "word": "дэлъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay inside another object",
        "examples": [
          {
            "sentence": "къэлэмыр '''дэлъ''' тхылъым",
            "translation": "The pencil '''is inside''' the book."
          }
        ]
      },
      {
        "meaning": "to lay inside a defined area",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daɬən"
  },
  {
    "word": "щагум ик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to urinate",
        "examples": []
      }
    ],
    "synonyms": [
      "гъутхьан"
    ],
    "type": "verb",
    "ipa": "ɕaːɡʷəm jət͡ʃʼən"
  },
  {
    "word": "гъутхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to urinate; to piss",
        "examples": [
          {
            "sentence": "'''мэгъутхьэ''' к1алэр",
            "translation": "The boy '''urinates'''."
          },
          {
            "sentence": "Хьэр '''щэгъутхьэ''' щагум",
            "translation": "The dog '''urinates''' in the yard."
          }
        ]
      }
    ],
    "synonyms": [
      "щагум ик1ын"
    ],
    "type": "verb",
    "ipa": "ʁʷətħaːn"
  },
  {
    "word": "лачэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ланчъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laːt͡ʃa"
  },
  {
    "word": "гьэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэнчы\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гьэч",
      "джэнчы"
    ],
    "cognate": "джэш",
    "type": "noun",
    "ipa": "ɡʲaʃ"
  },
  {
    "word": "гьэч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэнчы\"",
        "examples": []
      }
    ],
    "synonyms": [
      "джэнчы",
      "гьэш"
    ],
    "cognate": "джэш",
    "type": "noun",
    "ipa": "ɡʲat͡ʃ"
  },
  {
    "word": "зак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"занк1э\"",
        "examples": []
      }
    ],
    "synonyms": [
      "занк1э"
    ],
    "cognate": "занщ1э",
    "type": "adj",
    "ipa": "zaːt͡ʃʼa"
  },
  {
    "word": "лъэой",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "staircase",
        "examples": []
      },
      {
        "meaning": "ladder",
        "examples": []
      }
    ],
    "synonyms": [
      "дэк1ояп1э",
      "лъэугъуае"
    ],
    "type": "noun",
    "ipa": "ɬawaj"
  },
  {
    "word": "а т1эк1ум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "that place",
        "examples": [
          {
            "sentence": "А т1эк1ум къыщещхы",
            "translation": "It rains there."
          },
          {
            "sentence": "Л1ыр а т1эк1ум рэк1уагъ",
            "translation": "The man went there."
          },
          {
            "sentence": "А т1эк1ум умык1у",
            "translation": "Don't go there."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "aː tʼakʷʼəm"
  },
  {
    "word": "гъозышъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misty",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷazəʃʷ"
  },
  {
    "word": "гъозыпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "soda",
        "examples": []
      }
    ],

    "cognate": "гъуэзыпс",
    "type": "noun",
    "ipa": "ʁʷazəps"
  },
  {
    "word": "уцолъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Shapsug dialect) to suck, to suckle (in the only sense of: To work the lips and tongue on (an object) to extract moisture or nourishment; to absorb (something) in the mouth.)",
        "examples": [
          {
            "sentence": "К1алэм шэкэрхафэр '''ецолъы'''",
            "translation": "The boy '''is sucking''' the lollipop."
          },
          {
            "sentence": "К1алэц1ык1ум иянэ ибыдз '''еуцолъы'''",
            "translation": "The little boy '''sucks''' from his mother's breast."
          }
        ],
        "tags": [
          "Shapsug dialect"
        ]
      },
      {
        "meaning": "(vulgar) to perform fellatio",
        "examples": [
          {
            "sentence": "'''Къэсцолъ'''",
            "translation": "'''Blow me'''."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "wət͡ʃʷaɬən"
  },
  {
    "word": "унэгъуац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family name, surname",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэкъуац1э",
      "л1экъуац1э"
    ],
    "cognate": "л1экъуэц1э",
    "type": "noun",
    "ipa": "wənaʁʷat͡sʼa"
  },
  {
    "word": "псышъуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a tool to store drinkable liquid in (such as jug, glass, cup, mug and bowl)",
        "examples": []
      }
    ],

    "cognate": "псыфалъэ",
    "type": "noun",
    "ipa": "psəʃʷaːɬa"
  },
  {
    "word": "цыз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squirrel",
        "examples": []
      }
    ],
    "synonyms": [
      "чъыгышъхьэцыз"
    ],
    "type": "noun",
    "ipa": "t͡səz"
  },
  {
    "word": "чъыгышъхьэцыз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squirrel",
        "examples": []
      }
    ],
    "synonyms": [
      "цыз"
    ],
    "type": "noun",
    "ipa": "t͡ʂəɣəʂħat͡səz"
  },
  {
    "word": "хэстыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be burned inside an area with mass",
        "examples": [
          {
            "sentence": "Лавэм '''хэстыхьан'''",
            "translation": "'''To be burned inside''' a lava."
          },
          {
            "sentence": "Газым '''хэстыхьан'''",
            "translation": "'''To be burned inside''' a gas."
          }
        ]
      },
      {
        "meaning": "to be burned in fire",
        "examples": [
          {
            "sentence": "Маш1ом '''хэстыхьан'''",
            "translation": "'''To be burned in''' fire."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xastəħan"
  },
  {
    "word": "лыдын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to light up; to begin to shine",
        "examples": [
          {
            "sentence": "Жъуагъор '''мэлыды'''",
            "translation": "The star '''is shining'''."
          },
          {
            "sentence": "'''Ц1ыурэ''' пстэури дышъэп",
            "translation": "All the things that '''shine''' are not gold."
          }
        ]
      },
      {
        "meaning": "to shine a little, to shine sometimes",
        "examples": []
      }
    ],
    "synonyms": [
      "жъыун",
      "ц1ыун"
    ],
    "type": "verb",
    "ipa": "ɮədən"
  },
  {
    "word": "ныбачъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a person that has diarrhea",
        "examples": [
          {
            "sentence": "К1алэр '''ныбачъ'''",
            "translation": "The boy '''has diarrhea'''."
          },
          {
            "sentence": "'''Сыныбачъ'''",
            "translation": "'''I have diarrhea'''."
          },
          {
            "sentence": "'''Уныбачъа?'''",
            "translation": "'''Do you have diarrhea'''?"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "nəbaːt͡ʂ"
  },
  {
    "word": "пихын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to emit",
        "examples": [
          {
            "sentence": "къэгъагъэм гъомэ 1эш1у '''къыпехы'''",
            "translation": "The flower '''emits''' great smell."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pjəxən"
  },
  {
    "word": "пыпсык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spark (fire)",
        "examples": []
      }
    ],
    "synonyms": [
      "пыпсык1ьын"
    ],
    "type": "verb",
    "ipa": "pəpsət͡ʃʼən"
  },
  {
    "word": "тетхык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to copy (writing) from",
        "examples": [
          {
            "sentence": "к1алэм '''тетхык1ы''' пшъашъэм",
            "translation": "the boy '''copies''' from the girl."
          },
          {
            "sentence": "мэу '''тэтхык1'''",
            "translation": "'''Copy''' from here."
          },
          {
            "sentence": "тестымк1э '''титхык1рэ''' ч1агъэк1ыжьыт",
            "translation": "In the test, the one that '''copies''' will leave."
          },
          {
            "sentence": "тестымк1э '''къэстэтхык1'''",
            "translation": "In the test '''copy''' from me."
          },
          {
            "sentence": "'''тэсыхык1ышъурэп'''",
            "translation": "'''I can't copy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "тэтхык1ьын"
    ],
    "type": "verb",
    "ipa": "tatxət͡ʃʼən"
  },
  {
    "word": "мэш1уач",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "spark of fire",
        "examples": []
      },
      {
        "meaning": "match (to ignite fire with)",
        "examples": []
      },
      {
        "meaning": "torch",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maʃʷʼaːt͡ʃ"
  },
  {
    "word": "сырныч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "match",
        "examples": []
      }
    ],
    "synonyms": [
      "мэш1ощыхъу"
    ],
    "type": "adj",
    "ipa": "sərnət͡ʃ"
  },
  {
    "word": "мэш1ощыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "match",
        "examples": []
      }
    ],
    "synonyms": [
      "сырныч"
    ],
    "type": "adj",
    "ipa": "sərnət͡ʃ"
  },
  {
    "word": "пэпсы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snot",
        "examples": []
      },
      {
        "meaning": "(a piece of) snivel",
        "examples": []
      }
    ],
    "synonyms": [
      "пэлэлэжъ",
      "пэшын"
    ],
    "cognate": "пэщын",
    "type": "noun",
    "ipa": "papsə"
  },
  {
    "word": "пэлэлэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snot",
        "examples": []
      }
    ],
    "synonyms": [
      "пэпсы"
    ],
    "type": "noun",
    "ipa": "palalaʐ"
  },
  {
    "word": "пылэлын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to droop, to hang down, to dangle",
        "examples": []
      }
    ],
    "synonyms": [
      "къелэлэхын",
      "къыпылэлын",
      "къыпыщын"
    ],
    "type": "verb",
    "ipa": "pəɮaɮən"
  },
  {
    "word": "къыпылэлын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пылэлын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къелэлэхын",
      "пылэлын",
      "къыпыщын"
    ],
    "type": "verb",
    "ipa": "qəpəɮaɮən"
  },
  {
    "word": "къелэлэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to droop, to hang down, to dangle",
        "examples": []
      }
    ],
    "synonyms": [
      "пылэлын",
      "къыпылэлын",
      "къыпыщын"
    ],
    "type": "verb",
    "ipa": "qajɬaɬaxən"
  },
  {
    "word": "лэлэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"мэлэлэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "lalan"
  },
  {
    "word": "къыпыщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stick out",
        "examples": [
          {
            "sentence": "латыр кIыхьащэшъ, къьшэщы",
            "translation": "Lat is long and sticks out"
          }
        ]
      }
    ],
    "synonyms": [
      "пылэлын",
      "къыпылэлын",
      "къелэлэхын"
    ],
    "type": "verb",
    "ipa": "qəpəɕən"
  },
  {
    "word": "къеулэлэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къелэлэхын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пылэлын",
      "къыпылэлын",
      "къыпыщын"
    ],
    "type": "verb",
    "ipa": "qajɬaɬaxən"
  },
  {
    "word": "-хы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) designates action done downward",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "suffix",
    "ipa": "xə"
  },
  {
    "word": "пэшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "snot",
        "examples": []
      },
      {
        "meaning": "(a piece of) snivel",
        "examples": []
      }
    ],
    "synonyms": [
      "пэлэлэжъ",
      "пэпсы"
    ],
    "cognate": "пэщын",
    "type": "noun",
    "ipa": "paʃən"
  },
  {
    "word": "зыпэгъотхьажьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыпэгъутхьажьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəpaʁʷatħaʑən"
  },
  {
    "word": "зыпэгъэплъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone wait for oneself",
        "examples": [
          {
            "sentence": "К1алэм джанэ щылъэфэ '''тызыпыгъэплъагъ'''",
            "translation": "The boy '''made us wait for him''' until he wore clothes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəpaʁapɬan"
  },
  {
    "word": "пхъэчыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a piece of wood",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъапэ",
      "пхъэжъые"
    ],
    "type": "noun",
    "ipa": "pχat͡ʃəpa"
  },
  {
    "word": "пхъэжъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a piece of wood",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэчыпэ",
      "пхъапэ"
    ],
    "type": "noun",
    "ipa": "pχaʐəj"
  },
  {
    "word": "1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thing, object",
        "examples": []
      },
      {
        "meaning": "bundle",
        "examples": []
      },
      {
        "meaning": "a handful of; an armful of",
        "examples": [
          {
            "sentence": "Мые '''1ап1э''' матэм къих",
            "translation": "Take '''a handful of''' apples from the basket."
          },
          {
            "sentence": "К1алэм былым '''1ап1э''' и1",
            "translation": "The boy has '''a handful of''' fortune."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔaːpʼa"
  },
  {
    "word": "1апл1э ещэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hug",
        "examples": []
      }
    ],
    "synonyms": [
      "1апл1э фэш1ын"
    ],
    "type": "noun",
    "ipa": "ʔaːpɬʼa jaɕat͡ʃʼən"
  },
  {
    "word": "1апл1э фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hug",
        "examples": []
      }
    ],
    "synonyms": [
      "1апл1э ещэк1ын"
    ],
    "type": "noun",
    "ipa": "ʔaːpɬʼa faʃʼən"
  },
  {
    "word": "пхыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sheaf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pxər"
  },
  {
    "word": "к1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hemp",
        "examples": []
      }
    ],

    "cognate": "щ1эн",
    "type": "noun",
    "ipa": "t͡ʃʼan"
  },
  {
    "word": "мэхап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "place of weakness, location that has weakness",
        "examples": []
      },
      {
        "meaning": "weak spot",
        "examples": [
          {
            "sentence": "Зэуак1ом '''имэхап1э''' еон",
            "translation": "To hit the fighter's '''weak spot'''."
          },
          {
            "sentence": "Саусырыкъо икопкъит1у ары '''имэхап1э'''р",
            "translation": "Sosruko's two thighbone's are his '''weak spot'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "maxaːpʼa"
  },
  {
    "word": "мэхагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fragility, frailness, frailty",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maxaːʁ"
  },
  {
    "word": "мэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэмэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maxən"
  },
  {
    "word": "копкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thighbone, femur",
        "examples": []
      }
    ],

    "cognate": "куэпкъ",
    "type": "noun",
    "ipa": "kʷapq"
  },
  {
    "word": "мыпытэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fragile",
        "examples": [
          {
            "sentence": "псэолъэ мыпыт",
            "translation": "fragile construction."
          }
        ]
      }
    ],

    "cognate": "махэ",
    "type": "adj",
    "ipa": "məpəta"
  },
  {
    "word": "псэолъэш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "builder",
        "examples": [
          {
            "sentence": "псэолъэшI куп",
            "translation": "team of builders."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psawaɬaʃʼ"
  },
  {
    "word": "псэолъэш1ып1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "construction site, building site",
        "examples": [
          {
            "sentence": "псэолъэшIыпIэм Iоф щышIэн",
            "translation": "work at a construction site."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psawaɬaʃʼəpʼ"
  },
  {
    "word": "псэуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "structure",
        "examples": [
          {
            "sentence": "пхъэм хэшIыкIыгъэ '''псэуалъ'''",
            "translation": "A '''structure''' that was made from wood"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "psawaɬaʃʼəpʼ"
  },
  {
    "word": "шэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fabric",
        "examples": []
      }
    ],

    "cognate": "щэк1",
    "type": "noun",
    "ipa": "ʃat͡ʃʼ"
  },
  {
    "word": "мыхъатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "insignificant",
        "examples": []
      },
      {
        "meaning": "inessential",
        "examples": []
      },
      {
        "meaning": "unimportant",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "məχaːta"
  },
  {
    "word": "хъатэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "significant, meaningful, important",
        "examples": [
          {
            "sentence": "А Iофым '''хъатэ''' къикIыщтэп",
            "translation": "That work won't be '''significant'''."
          },
          {
            "sentence": "'''хъатэ''' пэмыхьын",
            "translation": "to not consider something '''meaningful'''"
          },
          {
            "sentence": "Ар Iоф '''хъатэп'''",
            "translation": "That is not a '''meaningful''' work."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "χaːta"
  },
  {
    "word": "мэхьанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "meaning",
        "examples": [
          {
            "sentence": "Мыщ '''мыхьэнэ''' и1эп.",
            "translation": "This has no '''meaning'''."
          },
          {
            "sentence": "Сыд мыщ '''ымыхьэнэ''' ош1а?",
            "translation": "Do you know what is '''the meaning''' of this?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "maħaːna"
  },
  {
    "word": "ч1эухъубэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эухъумэн\"",
        "examples": []
      }
    ],

    "cognate": "щ1эуфэн",
    "type": "verb",
    "ipa": "t͡ʂʼawχʷəban"
  },
  {
    "word": "ч1эухъумэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cover under something",
        "examples": [
          {
            "sentence": "сымаджэр чыхIэным '''чIэухъумэн'''",
            "translation": "'''To cover''' the sick with a blanket."
          },
          {
            "sentence": "хьадэр ч1ыгуи '''чIаухъумагъ'''",
            "translation": "'''They covered''' the body under the ground."
          }
        ]
      }
    ],

    "cognate": "щ1эуфэн",
    "type": "verb",
    "ipa": "t͡ʂʼawχʷəman"
  },
  {
    "word": "ч1эухъумэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "camouflage",
        "examples": []
      }
    ],

    "cognate": "щ1эуфэ",
    "type": "noun",
    "ipa": "t͡ʂʼawχʷəma"
  },
  {
    "word": "лагъымэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mine",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɮaːʁəma"
  },
  {
    "word": "ч1элъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put/place something under something",
        "examples": [
          {
            "sentence": "тхылъыр 1анэм чIилъхьагъ",
            "translation": "(S)he '''placed''' the book under the table."
          }
        ]
      },
      {
        "meaning": "to plant (a mine)",
        "examples": [
          {
            "sentence": "Лагъымэ '''чIилъхьи''' лъэмыджыр къыгъэуагъ",
            "translation": "(S)he '''planted''' a mine under bridge and destroyed it."
          },
          {
            "sentence": "лагъымэ '''чIэлъхьан'''",
            "translation": "To '''planted''' a mine."
          }
        ]
      },
      {
        "meaning": "to bury",
        "examples": [
          {
            "sentence": "хьадэр '''чIэлъхьан'''",
            "translation": "To '''bury''' a corpse."
          }
        ]
      }
    ],

    "cognate": "щ1элъхьэн",
    "type": "verb",
    "ipa": "t͡ʂʼaɬħan"
  },
  {
    "word": "джынэс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "until now",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒənas"
  },
  {
    "word": "гъэт1ылъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lay down something",
        "examples": [
          {
            "sentence": "Тхылъыр тэрэзэу '''гъэтIылъын''' фае",
            "translation": "You need '''to lay down''' the book correctly."
          },
          {
            "sentence": "Шъэжъыер '''гъэтIылъ''' укъимыу1агъэу",
            "translation": "'''Lay down''' the knife before it wounds you."
          }
        ]
      },
      {
        "meaning": "to lay by, to put on the side, to save (money for some purchase), to keep money for future plans",
        "examples": [
          {
            "sentence": "Лэжьыгъэр мыкIодынэу '''гъэтIылъын''' фае",
            "translation": "You need to '''save''' your salary in order it to be not wasted"
          },
          {
            "sentence": "Ахъщэ тIэкIу гъэтIылъын",
            "translation": "'''Save''' some money."
          }
        ]
      },
      {
        "meaning": "to bury",
        "examples": [
          {
            "sentence": "Хьадэр '''тыгъэтIылъын''' фае",
            "translation": "We need '''to bury''' the body."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁatʼəɬən"
  },
  {
    "word": "гъэт1ысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sit down",
        "examples": [
          {
            "sentence": "К1алэр гъэт1ыс",
            "translation": "'''Make''' the boy '''sit down'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁatʼəsən"
  },
  {
    "word": "гуигъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cause to fall, to crumble",
        "examples": []
      },
      {
        "meaning": "to heap round",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəjʁawən"
  },
  {
    "word": "джык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for the time being",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "d͡ʒət͡ʃʼa"
  },
  {
    "word": "к1эрык1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "again",
        "examples": []
      },
      {
        "meaning": "again (from the beginning, from scratch)",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "t͡ʃʼarət͡ʃʼaw"
  },
  {
    "word": "иунашъо ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mention someone to bring to attention; to bring to attention",
        "examples": [
          {
            "sentence": "К1алэм о '''уиунашъо къиш1ыгъ'''",
            "translation": "The boy '''mentioned you'''."
          },
          {
            "sentence": "Л1ыжъым '''иунашъо къэмыш1'''",
            "translation": "'''Do not mention''' the old man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəwənaːʃʷa ʃʼən"
  },
  {
    "word": "губгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vulgarity",
        "examples": []
      }
    ],

    "cognate": "емык1у",
    "type": "noun",
    "ipa": "ɡʷəbʁan"
  },
  {
    "word": "шъхьае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for",
        "examples": []
      }
    ],
    "synonyms": [
      "фэш1к1э",
      "пае"
    ],
    "cognate": "папщ1э",
    "type": "conj",
    "ipa": "ʂħaːja"
  },
  {
    "word": "фэ1аз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skillful",
        "examples": [
          {
            "sentence": "Ар IофшIакIэм фэIаз",
            "translation": "He is '''skillful''' with work doing."
          }
        ]
      }
    ],
    "synonyms": [
      "1азэ"
    ],
    "cognate": "хуэ1аз",
    "type": "adj",
    "ipa": "faʔaːz"
  },
  {
    "word": "шхъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to envy, To feel displeasure or hatred towards (someone) for their good fortune or possessions.",
        "examples": [
          {
            "sentence": "Ар к1алэм '''ешхъуагъо'''",
            "translation": "He '''envy''' the boy."
          },
          {
            "sentence": "К1алэр сэ сызэрэбаем фэш1ык1э '''къысэшхъуагъо'''",
            "translation": "The boy '''is envy of me''' because I am rich."
          },
          {
            "sentence": "Уи мащинэ к1э дахэм фэш1ык1э '''къыошхъуагъощтых'''",
            "translation": "Because of your beautiful new car '''they gonna envy you'''"
          },
          {
            "sentence": "К1алэр '''мэшхъуагъо'''",
            "translation": "The boy '''is envy'''."
          },
          {
            "sentence": "'''Ушхъуагъоу''' хъущтэп",
            "translation": "You can't '''get envy'''."
          },
          {
            "sentence": "Л1ыр баий ц1ыфыхэр '''ешхъуагъох'''",
            "translation": "Because the man is rich, people '''are envy of him'''."
          },
          {
            "sentence": "К1алэм '''уемышхъуагъу'''",
            "translation": "'''Don't be envy of''' the boy."
          }
        ]
      }
    ],
    "synonyms": [
      "хъопсэн",
      "ешъугъун",
      "шъугъон",
      "ехьагъушъугъун",
      "шъухъогъон"
    ],
    "type": "verb",
    "ipa": "ʃχʷaʁʷan"
  },
  {
    "word": "шъугъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ешъугъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷəʁʷən"
  },
  {
    "word": "ехьагъушъугъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шхъогъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хъопсэн",
      "шхъогъон",
      "ешъугъун",
      "шъугъон",
      "шъухъогъон"
    ],
    "type": "verb",
    "ipa": "jaħaːʁʷəʃʷəʁʷən"
  },
  {
    "word": "шъугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to envy, to be envious",
        "examples": []
      }
    ],
    "synonyms": [
      "хъопсэн",
      "шхъогъон",
      "ешъугъун",
      "ехьагъушъугъун",
      "шъухъогъон"
    ],
    "type": "verb",
    "ipa": "ʃʷʁʷan"
  },
  {
    "word": "ешъугъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъугъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "хъопсэн",
      "шхъогъон",
      "шъугъон",
      "ехьагъушъугъун",
      "шъухъогъон"
    ],
    "cognate": "ефыгъуэн",
    "type": "verb",
    "ipa": "jaʃʷʁʷən"
  },
  {
    "word": "1ахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "herdsman, herder",
        "examples": [
          {
            "sentence": "Iахъоу Iоф ешIэ",
            "translation": "He is doing a job as a herdsman."
          },
          {
            "sentence": "Iахъо кIуагъэ",
            "translation": "The herdsman went."
          },
          {
            "sentence": "Iахъо ежьагъ",
            "translation": "The herdsman began."
          }
        ]
      }
    ],
    "synonyms": [
      "хъуак1о"
    ],
    "cognate": "1ахъуэ",
    "type": "noun",
    "ipa": "ʔaχʷa"
  },
  {
    "word": "1эхъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "herd",
        "examples": [
          {
            "sentence": "Чэм '''Iэхъогъу'''",
            "translation": "'''Herd''' of cows"
          },
          {
            "sentence": "Iахъом '''Iэхъогъур''' чылэм дифыгъ",
            "translation": "The herdsman lead the '''herd''' out side the village."
          },
          {
            "sentence": "А чэмым нахь дэгъу Iэхъогъум хэтэп",
            "translation": "There is no better cow in '''the herd'''."
          }
        ]
      },
      {
        "meaning": "herdsman's companion",
        "examples": []
      }
    ],

    "cognate": "1эхъуэгъу",
    "type": "noun",
    "ipa": "ʔaχʷaʁʷ"
  },
  {
    "word": "хэк1одэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disappear in",
        "examples": [
          {
            "sentence": "К1алэр ц1ыфыхэм '''ахэк1одагъ'''",
            "translation": "The boy '''disappeared in the mass''' of people."
          }
        ]
      },
      {
        "meaning": "to get lost in",
        "examples": [
          {
            "sentence": "1эхъогъум чэмит1у хэк1одагъ",
            "translation": "Two cows '''got lost in''' the herd"
          },
          {
            "sentence": "К1алэр мэзым '''хэк1одагъ'''",
            "translation": "The boy '''got list in''' in the forest."
          },
          {
            "sentence": "1элъыныр уцыхэм '''яхэк1уагъ'''",
            "translation": "The ring '''got list in''' the grass."
          }
        ]
      },
      {
        "meaning": "to perish",
        "examples": [
          {
            "sentence": "Заом хэк1одэн",
            "translation": "'''To perish (die)''' in the war."
          }
        ]
      }
    ],
    "synonyms": [
      "хэк1уэдэн",
      "хэк1одэхьын"
    ],
    "type": "verb",
    "ipa": "xaʁʷaɕan"
  },
  {
    "word": "хэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) within some mass (can be any liquid, gas or solid)",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) through",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) among (people or objects)",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "xa"
  },
  {
    "word": "гъощэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lose one's way, to go astray, to get lost (by going the wrong way)",
        "examples": [
          {
            "sentence": "К1элэцIык1ухэр '''гъощагъэх'''",
            "translation": "The children '''got lost'''."
          },
          {
            "sentence": "Л1ыр идинк1э '''гъощагъ'''",
            "translation": "The man '''got astray''' with his religion"
          },
          {
            "sentence": "Л1ыр гъогумк1э '''гъощагъ'''",
            "translation": "The man '''got lost''' on the road"
          }
        ]
      }
    ],

    "cognate": "гъуэщэн",
    "type": "verb",
    "ipa": "ʁʷaɕan"
  },
  {
    "word": "тхьак1умк1ыхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rabbit",
        "examples": []
      },
      {
        "meaning": "bunny",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħakʷʼmat͡ʃʼəħ"
  },
  {
    "word": "ик1одык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disappear/vanish out of inside something",
        "examples": [
          {
            "sentence": "Тхьак1умк1ыхь па1ом '''ик1одык1ыгъ'''",
            "translation": "The rabbit '''vanished out of''' the hat."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəkʷʼadət͡ʃʼəm"
  },
  {
    "word": "хэк1одык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disappear/vanish out of something",
        "examples": [
          {
            "sentence": "Бжьыдзэ шъхьацым '''хэк1одык1ыгъ'''",
            "translation": "The flea '''vanished out of''' the hair."
          },
          {
            "sentence": "Iэхъогъум чэмитIу хэкIодыкIыгъ",
            "translation": "Two cows '''vanished out of''' the herd."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xakʷʼadət͡ʃʼəm"
  },
  {
    "word": "къеш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dance",
        "examples": [
          {
            "sentence": "К1алэр '''къеш1э'''",
            "translation": "The boy '''dances'''."
          }
        ]
      },
      {
        "meaning": "(rare) to play (a game)",
        "examples": [
          {
            "sentence": "К1алэр '''къеш1э'''",
            "translation": "The boy '''is playing'''."
          }
        ],
        "tags": [
          "rare"
        ]
      },
      {
        "meaning": "(rare) to play, to act (To act as the indicated role, especially in a performance)",
        "examples": [
          {
            "sentence": "Л1ыр филмымк1э докторэу '''къеш1э'''",
            "translation": "The man '''is playing''' as a doctor in the film."
          }
        ],
        "tags": [
          "rare"
        ]
      }
    ],
    "synonyms": [
      "еш1эн",
      "къэшъон"
    ],
    "cognate": "джэгун",
    "type": "verb",
    "ipa": "qajʃʼan"
  },
  {
    "word": "еш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dance",
        "examples": [
          {
            "sentence": "К1алэр '''еш1э'''",
            "translation": "The boy '''dances'''."
          }
        ]
      },
      {
        "meaning": "(rare) to play (a game)",
        "examples": [],
        "tags": [
          "rare"
        ]
      },
      {
        "meaning": "(rare) to play, to act (To act as the indicated role, especially in a performance)",
        "examples": [],
        "tags": [
          "rare"
        ]
      }
    ],
    "synonyms": [
      "къеш1эн",
      "къэшъон"
    ],
    "cognate": "ещ1эн",
    "type": "verb",
    "ipa": "jaʃʼan"
  },
  {
    "word": "тегъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to knock down something or someone off something",
        "examples": [
          {
            "sentence": "К1элэц1ык1ум тасыр 1анэм '''къытигъэзыгъ'''",
            "translation": "The little boy '''knocked down''' the glass off the table"
          }
        ]
      },
      {
        "meaning": "to make something fall off something",
        "examples": [
          {
            "sentence": "Умыхъый, шым '''сыкъытебгъэзыщт'''",
            "translation": "Don't move, '''you will make me fall off''' the horse."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁazən"
  },
  {
    "word": "къытегъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тегъэзын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qətajʁazən"
  },
  {
    "word": "тегъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to repeat",
        "examples": [
          {
            "sentence": "'''Къытегъэзи''' къедж тхылъым",
            "translation": "'''Repeat and''' read the book."
          },
          {
            "sentence": "Къэп1уагъэм '''къытегъэз''' джыри",
            "translation": "'''Repeat''' what you said again."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁazən"
  },
  {
    "word": "къытегъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тегъэзэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qətajʁazan"
  },
  {
    "word": "къек1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ек1эхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajt͡ʃʼaxəm"
  },
  {
    "word": "ек1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bring down something to the ground",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jat͡ʃʼaxəm"
  },
  {
    "word": "езэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be bored of",
        "examples": [
          {
            "sentence": "Изакъошъ '''езэщын''' фае",
            "translation": "He is alone, he must be '''bored'''."
          },
          {
            "sentence": "Тхылъ дэгъум иеджэн '''уезэщын''' плъэкIыщтэп",
            "translation": "You can't be '''bored''' with a good book reading."
          },
          {
            "sentence": "К1алэр '''езэщы''' мэджагунэм",
            "translation": "The boy '''is bored''' from playing."
          },
          {
            "sentence": "Томым яунэ ук1омэ '''уезэщыщт'''",
            "translation": "If you go to Tom's house '''you will get bored'''."
          },
          {
            "sentence": "К1алэм '''мэзащы'''",
            "translation": "The boy '''is bored'''."
          },
          {
            "sentence": "Сэ '''сэзащы'''",
            "translation": "'''I am bored'''."
          },
          {
            "sentence": "К1алэр '''мэзэщырэп'''",
            "translation": "The boy '''doesn't get bored'''."
          }
        ]
      },
      {
        "meaning": "to get tired by annoyance or frustration",
        "examples": [
          {
            "sentence": "К1элэц1ык1умэ амакъэ сэ1оу '''сезэщыгъ'''",
            "translation": "'''I am tired of''' hearing the children's voices."
          },
          {
            "sentence": "Мы 1оф къиным '''уезэщыгъа?'''",
            "translation": "'''Are you got tired of''' of this hard job?"
          }
        ]
      }
    ],

    "cognate": "езэшын",
    "type": "verb",
    "ipa": "jazaʃən"
  },
  {
    "word": "гъэзэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bore someone",
        "examples": [
          {
            "sentence": "'''Себыгъэзещы'''",
            "translation": "'''You are boring me'''."
          },
          {
            "sentence": "Филмым '''сригъэзэщыгъ'''",
            "translation": "The film '''bored me'''."
          }
        ]
      },
      {
        "meaning": "to make someone tired by annoying or frustrating",
        "examples": [
          {
            "sentence": "К1элэц1ык1ухэм '''сырагъэзэщыгъ''' ренэу ауш1оеу джэхашъор",
            "translation": "The little kids '''tired me''' because they always dirty the floor."
          },
          {
            "sentence": "Мы 1офым '''шъуригъэзэщыгъа?'''",
            "translation": "Did this job '''tire you'''? (said to plural)"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁazaʃən"
  },
  {
    "word": "тезын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall off something",
        "examples": [
          {
            "sentence": "К1элэц1ык1ур шым '''къытезыгъ'''",
            "translation": "The little boy '''fell off''' the horse."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajzən"
  },
  {
    "word": "ук1ак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "killer",
        "examples": []
      }
    ],
    "synonyms": [
      "мэхъаджэ",
      "л1ыук1"
    ],
    "type": "noun",
    "ipa": "wt͡ʃʼaːkʷʼa"
  },
  {
    "word": "лъыгъачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blood spilling",
        "examples": []
      },
      {
        "meaning": "massacre",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəʁaːt͡ʂa"
  },
  {
    "word": "егъэзыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "desperate",
        "examples": [
          {
            "sentence": "Л1ым '''егъэзыгъ''' 1офы зэрехьэ",
            "translation": "The man does a '''desperate''' job."
          },
          {
            "sentence": "'''Уегъэзыгъа''' ахъщэ къэсш1оптыгъунэу",
            "translation": "'''Are you desperate''' so you will steal money from me?"
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaʁatəʁ"
  },
  {
    "word": "ч1этэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to strew (on an ground), To distribute objects or pieces of something on the ground (especially in a random manner)",
        "examples": [
          {
            "sentence": "Л1ым картыхэр '''ч1етэкъух'''",
            "translation": "The man '''spread''' the cards '''on the group'''."
          }
        ]
      }
    ],

    "cognate": "щ1этэкъун",
    "type": "verb",
    "ipa": "t͡ʂʼataqʷən"
  },
  {
    "word": "итэкъохьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to strew / scatter (over an area), to distribute objects or pieces of something over an area (especially in a random manner)",
        "examples": [
          {
            "sentence": "Л1ым чылапхъэхэр ч1ыгум '''ритэкъохьыгъэх'''",
            "translation": "The man '''distributed''' the seeds '''over''' the ground."
          }
        ]
      }
    ],
    "synonyms": [
      "ипхъыхьан"
    ],
    "cognate": "щ1этэкъун",
    "type": "verb",
    "ipa": "jətaqʷaħən"
  },
  {
    "word": "игъэкъугъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "complete (with all parts included)",
        "examples": [
          {
            "sentence": "Си мащинэ '''игъэкъугъэ'''",
            "translation": "My car '''is complete'''."
          }
        ]
      },
      {
        "meaning": "complete (being accomplished in all matured attributes)",
        "examples": [
          {
            "sentence": "Ц1ыфы '''игъэкъугъэу''' щытын фай",
            "translation": "You need to be '''a complete''' person."
          },
          {
            "sentence": "Армэу ц1ыфым '''игъэкъугъэ''' ц1ыфы нэмык1 ылъэгъун лъэк1ырэп, ежь фэдэпи",
            "translation": "A lazy person cannot see a different '''complete''' person, because he's not like him."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "jəʁaqʷəʁa"
  },
  {
    "word": "шъыхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "reindeer",
        "examples": []
      },
      {
        "meaning": "deer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂəħa"
  },
  {
    "word": "шъуин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whistle",
        "examples": [
          {
            "sentence": "К1алэр '''мэшъуе'''",
            "translation": "The boy '''is whistling'''."
          },
          {
            "sentence": "Сэ '''сэшъуе'''",
            "translation": "'''I am whistling'''."
          },
          {
            "sentence": "К1алэр '''мэшъуешъу''', пшъашъэр '''мэшъуешъурэп'''",
            "translation": "The boy '''can whistle''', the girl '''can't whistle'''."
          },
          {
            "sentence": "Къэбэртайхэр '''мэшъуешъухэрэп'''",
            "translation": "The Kabardians '''can't whistle'''."
          }
        ]
      }
    ],

    "cognate": "фиин",
    "type": "verb",
    "ipa": "ʃʷəjan"
  },
  {
    "word": "шъуял",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуялъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуялъэ",
      "шъуил"
    ],
    "type": "noun",
    "ipa": "ʃʷəjaːɮ"
  },
  {
    "word": "шъуил",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъуялъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуял",
      "шъуялъэ"
    ],
    "type": "noun",
    "ipa": "ʃʷəjaːɮ"
  },
  {
    "word": "хъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shepherd, sheep herdsman",
        "examples": []
      }
    ],
    "synonyms": [
      "1ахъо"
    ],
    "type": "noun",
    "ipa": "χʷaːkʷʼa"
  },
  {
    "word": "нэгъуч1ыцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "witch",
        "examples": []
      }
    ],
    "synonyms": [
      "уды"
    ],
    "type": "noun",
    "ipa": "naʁʷt͡ʂʼət͡sa"
  },
  {
    "word": "ушхъухьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sorcerer",
        "examples": []
      },
      {
        "meaning": "wizard",
        "examples": []
      },
      {
        "meaning": "magician",
        "examples": []
      }
    ],
    "synonyms": [
      "уды"
    ],
    "type": "noun",
    "ipa": "wəʃχʷħaːkʷʼa"
  },
  {
    "word": "ушхъухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cast a spell on someone; to bewitch",
        "examples": []
      }
    ],

    "cognate": "ущхъухьын",
    "type": "verb",
    "ipa": "wəʃχʷħaːn"
  },
  {
    "word": "ныожъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "old hag",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəwaʐ"
  },
  {
    "word": "-жъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to nouns) freaking, bloody (intensifier), damn",
        "examples": [],
        "tags": [
          "added to nouns"
        ]
      },
      {
        "meaning": "(added to nouns) old",
        "examples": [],
        "tags": [
          "added to nouns"
        ]
      },
      {
        "meaning": "(added to adjectives) very",
        "examples": [],
        "tags": [
          "added to adjectives"
        ]
      }
    ],
    "type": "suffix",
    "ipa": "ʐə"
  },
  {
    "word": "т1эп1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be unstitched, to be torn at the seams",
        "examples": []
      }
    ],
    "synonyms": [
      "зэгот1эп1ык1ын",
      "----"
    ],
    "type": "verb",
    "ipa": "tʼsapʼən"
  },
  {
    "word": "гъэут1эп1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэт1эп1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawtʼapʼən"
  },
  {
    "word": "зэгот1эп1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be unstitched, to be torn at the seams",
        "examples": []
      }
    ],

    "cognate": "т1эп1ын",
    "type": "verb",
    "ipa": "zaɡʷatʼapʼət͡ʃʼən"
  },
  {
    "word": "п1онк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pop, to burst (to break from internal pressure)",
        "examples": []
      },
      {
        "meaning": "to bubble up from heat",
        "examples": [
          {
            "sentence": "Псыр '''мэп1эрк1ы'''",
            "translation": "The water '''is bubbling up'''."
          }
        ]
      }
    ],
    "synonyms": [
      "п1эрк1ьын"
    ],
    "cognate": "п1энк1ын",
    "type": "verb",
    "ipa": "pʷʼant͡ʃʼəm"
  },
  {
    "word": "п1эрк1ьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"п1онк1ын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "п1онк1ын"
    ],
    "cognate": "п1энк1ын",
    "type": "verb",
    "ipa": "pʼankʲʼəm"
  },
  {
    "word": "гъэп1орк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cause to burst",
        "examples": [
          {
            "sentence": "Псыр '''гъэп1орк1'''",
            "translation": "'''To burst''' the water."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁapʷʼant͡ʃʼən"
  },
  {
    "word": "зэгочын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be cracked",
        "examples": [
          {
            "sentence": "Пхъэр '''зэгочы'''",
            "translation": "The wood '''cracks'''."
          }
        ]
      },
      {
        "meaning": "to be broken off",
        "examples": []
      }
    ],
    "synonyms": [
      "п1эрэп1эрэн"
    ],
    "type": "verb",
    "ipa": "zaɡʷat͡ʃən"
  },
  {
    "word": "уты",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "iron (for pressing clothes)",
        "examples": []
      },
      {
        "meaning": "blow (from impact)",
        "examples": []
      }
    ],
    "synonyms": [
      "уты еуалъэ"
    ],
    "type": "noun",
    "ipa": "wtə"
  },
  {
    "word": "уты еон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to iron, to pass an iron over (clothing or some other item made of cloth) in order to remove creases.",
        "examples": [
          {
            "sentence": "Бзылъфыгъэр '''уты ео'''",
            "translation": "The mother '''irons'''."
          },
          {
            "sentence": "Бзылъфыгъэр джанэм '''уты еуагъ'''",
            "translation": "The mother '''ironed''' the shirt."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wtə jawan"
  },
  {
    "word": "лъыш1эжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vengeance",
        "examples": []
      },
      {
        "meaning": "revenge",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəʃʼaʑ"
  },
  {
    "word": "лъыш1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reverse",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɬəʃʼaʑən"
  },
  {
    "word": "лъыш1эжьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "revenger",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəʃʼaʑaːkʷʼa"
  },
  {
    "word": "губгъэн фэлъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hold a grudge against",
        "examples": [
          {
            "sentence": "К1алэм '''губгъэн къэсфелъэгъу'''",
            "translation": "The boy '''holds a grudge against me'''."
          },
          {
            "sentence": "'''Губгъэн къэсфэмылъэгъу'''",
            "translation": "'''Don't holds a grudge against me'''."
          }
        ]
      }
    ],
    "synonyms": [
      "губгъэн фэш1ын"
    ],
    "cognate": "губгъэн хуэщ1ын",
    "type": "verb",
    "ipa": "ɡʷəbʁan faɬaʁʷən"
  },
  {
    "word": "губгъэн фэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hold a grudge against",
        "examples": [
          {
            "sentence": "К1алэм '''губгъэн къэсфеш1ы'''",
            "translation": "The boy '''holds a grudge against me'''."
          },
          {
            "sentence": "'''Губгъэн къэсфэмыш1'''",
            "translation": "'''Don't hold a grudge against me'''."
          }
        ]
      }
    ],
    "synonyms": [
      "губгъэн фэлъэгъун"
    ],
    "cognate": "губгъэн хуэщ1ын",
    "type": "verb",
    "ipa": "ɡʷəbʁan faʃʼən"
  },
  {
    "word": "мэчэрэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чэрэгъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "mat͡ʃaraʁʷən"
  },
  {
    "word": "чэрэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be twisted",
        "examples": []
      },
      {
        "meaning": "to be whirled",
        "examples": [
          {
            "sentence": "Машинэр ежьэмэ щэрэхъхэр '''чэрэгъун''' фае",
            "translation": "When the car starts, the wheels must '''whirl'''."
          },
          {
            "sentence": "Щэрыхъыр псынк1эу '''мэчэрэгъу'''",
            "translation": "The wheel '''whirls''' fast."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃaraʁʷən"
  },
  {
    "word": "чэрэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spin",
        "examples": [
          {
            "sentence": "к1алэр '''мэк1рэзы'''",
            "translation": "The boy '''is spinning'''."
          }
        ]
      }
    ],
    "synonyms": [
      "мэкьэрэзын"
    ],
    "cognate": "мэджэрэзын",
    "type": "verb",
    "ipa": "t͡ʃarazən"
  },
  {
    "word": "щхэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "funny",
        "examples": [
          {
            "sentence": "Ащ къехъулIагъэр сыдэу '''щхэна'''! '''Щхэны''' ыкIи гъэны",
            "translation": "What happened to him is so '''funny'''! it is '''funny''' and misfortune."
          }
        ]
      },
      {
        "meaning": "laugh",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɕxanə"
  },
  {
    "word": "гъэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grief, distress, sadness",
        "examples": []
      },
      {
        "meaning": "misfortune, disaster",
        "examples": []
      }
    ],
    "synonyms": [
      "игъуадж",
      "тхьамык1агъу",
      "насыпынчъэ",
      "тхьамык1эгъо",
      "мыгъо"
    ],
    "type": "noun",
    "ipa": "ʁanə"
  },
  {
    "word": "гухэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sorrow, grief",
        "examples": []
      }
    ],
    "synonyms": [
      "гуао",
      "гукъау",
      "нэшхъэигъу"
    ],
    "cognate": "гухэщ1",
    "type": "noun",
    "ipa": "ɡʷəxat͡ʃʼ"
  },
  {
    "word": "гукъау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grief, distress, sadness",
        "examples": []
      },
      {
        "meaning": "misfortune, disaster",
        "examples": []
      }
    ],
    "synonyms": [
      "гухэк1",
      "нэшхъэигъу"
    ],
    "type": "noun",
    "ipa": "ɡʷəqaːw"
  },
  {
    "word": "нэшхъэигъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sorrow time, grief time",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "naʃχajəʁʷ"
  },
  {
    "word": "гуао",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misery",
        "examples": []
      }
    ],
    "synonyms": [
      "гухэк1",
      "гукъау",
      "нэшхъэигъу"
    ],
    "cognate": "гуауэ",
    "type": "noun",
    "ipa": "ɡʷaːwa"
  },
  {
    "word": "гъыбзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lament (A song expressing grief), a poem of lament, a song of lament",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁəbza"
  },
  {
    "word": "гуих",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "terrible, horrible, dreadful, awful",
        "examples": []
      },
      {
        "meaning": "fearful",
        "examples": []
      }
    ],
    "synonyms": [
      "щынагъо"
    ],
    "type": "adj",
    "ipa": "ɡʷəjəx"
  },
  {
    "word": "джэгъогъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to quarrel, to be at war with",
        "examples": []
      }
    ],

    "cognate": "жэгъуэгъун",
    "type": "verb",
    "ipa": "d͡ʒaʁʷəɡʷən"
  },
  {
    "word": "джагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hatred, hate",
        "examples": []
      },
      {
        "meaning": "evil wisher, illwisher",
        "examples": []
      }
    ],
    "synonyms": [
      "джэгъогъу"
    ],
    "cognate": "жагъуэ",
    "type": "noun",
    "ipa": "d͡ʒaʁʷ"
  },
  {
    "word": "джэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hate",
        "examples": [
          {
            "sentence": "А к1алэр сэ '''сиджагъу'''",
            "translation": "'''I hate''' that boy."
          }
        ]
      }
    ],
    "synonyms": [
      "фэмылъэгъун",
      "уджэгъун"
    ],
    "type": "verb",
    "ipa": "d͡ʒaʁʷən"
  },
  {
    "word": "иджэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"джэгъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒaʁʷən"
  },
  {
    "word": "фэмылъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dislike",
        "examples": [
          {
            "sentence": "А к1алэр сэ '''сыфэлъэгъурэп'''",
            "translation": "'''I dislike''' that boy."
          }
        ]
      },
      {
        "meaning": "to not be bearable",
        "examples": []
      }
    ],
    "synonyms": [
      "джэгъун"
    ],
    "type": "verb",
    "ipa": "faməɬaʁʷən"
  },
  {
    "word": "гъэтхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to amuse someone, to entertain someone",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэр '''егъатхъэ'''",
            "translation": "The boy '''is amusing''' the girl."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁatχan"
  },
  {
    "word": "тхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be amused, to be entertained",
        "examples": [
          {
            "sentence": "К1алэ '''матхъэ'''",
            "translation": "The boy '''is amused'''."
          }
        ]
      },
      {
        "meaning": "to be having fun",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁatχan"
  },
  {
    "word": "гъуч1ынэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wire",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷət͡ʂʼəna"
  },
  {
    "word": "гъуч1ы1унэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nail",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷət͡ʂʼəʔʷəna"
  },
  {
    "word": "лъежьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run after",
        "examples": []
      },
      {
        "meaning": "to chase",
        "examples": []
      }
    ],
    "synonyms": [
      "лъычъэн"
    ],
    "type": "verb",
    "ipa": "ɬajʑan"
  },
  {
    "word": "ежэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wait",
        "examples": []
      }
    ],
    "synonyms": [
      "пэплъэн"
    ],
    "type": "verb",
    "ipa": "jaʒan"
  },
  {
    "word": "сэнсэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pine, pine tree",
        "examples": [
          {
            "sentence": "'''Сэснэй''' чъыгыпкъыр зэгуахи пхъэмбгъу аш1ыгъ",
            "translation": "They opened the '''pine tree's''' tree trunk and made plank."
          }
        ]
      },
      {
        "meaning": "pine wood",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "sansaj"
  },
  {
    "word": "зэзэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fight",
        "examples": [
          {
            "sentence": "шъэожъыехэр зэзэуагъэх",
            "translation": "The boys '''are fighting'''."
          }
        ]
      },
      {
        "meaning": "to be in war with each other",
        "examples": [
          {
            "sentence": "хэгъэгухэр зэзаох",
            "translation": "The countries '''are in war with each other'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zazawan"
  },
  {
    "word": "шъхьэк1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьак1э\"",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ʂħakʲʼa"
  },
  {
    "word": "инапэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "by his face",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jənaːpat͡ʃʼa"
  },
  {
    "word": "зэрэкъэлъагъорэмк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "It appears; it seems; by the looks of things",
        "examples": [
          {
            "sentence": "'''зэрэкъэлъагъорэмк1э''' непэ къещхыщт",
            "translation": "'''By the looks of things''', it gonna rain today."
          }
        ]
      },
      {
        "meaning": "by how (s)he looks",
        "examples": []
      }
    ],
    "synonyms": [
      "инахэк1э"
    ],
    "type": "adv",
    "ipa": "zaraqaɬaːʁʷaramt͡ʃʼa"
  },
  {
    "word": "лэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "basin",
        "examples": []
      }
    ],
    "synonyms": [
      "лэгьэн"
    ],
    "type": "noun",
    "ipa": "ɮad͡ʒan"
  },
  {
    "word": "лэгьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лэджэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "лэджэн"
    ],
    "type": "noun",
    "ipa": "ɮaɡʲan"
  },
  {
    "word": "техъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cover with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajχʷan"
  },
  {
    "word": "псышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "liquid",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʃʷa"
  },
  {
    "word": "пкъыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thing",
        "examples": []
      },
      {
        "meaning": "matter",
        "examples": []
      },
      {
        "meaning": "an object",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psəʃʷa"
  },
  {
    "word": "гъэц1ырэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spray burst liquid on to (usually from a pile)",
        "examples": [
          {
            "sentence": "Къэгъагъэхэмэ псы '''атегъэц1ыр'''",
            "translation": "(S)he '''pours''' water into the flowers."
          },
          {
            "sentence": "К1алэм ч1ыгум '''тегъэц1ырэ'''",
            "translation": "The boy is '''spraying''' on the group."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡sʼəran"
  },
  {
    "word": "тегъэстык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to erase or remove something by burning on the surface",
        "examples": []
      }
    ],
    "synonyms": [
      "тегъэстык1ьын"
    ],
    "cognate": "тегъэсык1ын",
    "type": "verb",
    "ipa": "tajʁasət͡ʃʼən"
  },
  {
    "word": "тегъэстыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to burn something on the surface something",
        "examples": []
      }
    ],
    "synonyms": [
      "тегъэсыхьэн"
    ],
    "type": "verb",
    "ipa": "tajʁasəħaːn"
  },
  {
    "word": "инахэк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "It appears; it seems; by the looks of things",
        "examples": [
          {
            "sentence": "'''инахэк1э''' непэ къещхыщт",
            "translation": "'''By the looks of things''', it gonna rain today."
          }
        ]
      },
      {
        "meaning": "by the look of his face",
        "examples": []
      }
    ],
    "synonyms": [
      "зэрэкъэлъагъорэмк1э"
    ],
    "type": "adv",
    "ipa": "jənaːxat͡ʃʼa"
  },
  {
    "word": "хэлъэшъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drag something into a crowd",
        "examples": []
      },
      {
        "meaning": "to drag or pull something into an area of mass (usually liquid)",
        "examples": [
          {
            "sentence": "Архъожъэр псыцур псым '''хилъэшъуагъ'''",
            "translation": "The crocodile '''pulled''' the water buffalo into the water."
          },
          {
            "sentence": "Пщэхъо ц1ынэм '''ухилъэшъушъущт'''",
            "translation": "The quicksand '''can pull you in'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "xaɬaʃʷan"
  },
  {
    "word": "хьаплъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brown",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1агъо",
      "къахьэуашъо"
    ],
    "type": "adj",
    "ipa": "ħaːpɬə"
  },
  {
    "word": "къахьэуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brown",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1агъо",
      "хьаплъы"
    ],
    "cognate": "къэхьэуафэ",
    "type": "adj",
    "ipa": "qaːħawaːʃʷa"
  },
  {
    "word": "къэхьыуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къахьэуашъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1агъо",
      "хьаплъы"
    ],
    "type": "adj",
    "ipa": "qaħwaːʂʷa"
  },
  {
    "word": "къэхьыо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coffee",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaħwa"
  },
  {
    "word": "шъэгьашъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъэджашъ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъэджашъ"
    ],
    "type": "adj",
    "ipa": "ʂaɡʲaːʂ"
  },
  {
    "word": "зэуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fighter",
        "examples": []
      }
    ],
    "synonyms": [
      "зэуал1"
    ],
    "cognate": "зэуак1уэ",
    "type": "noun",
    "ipa": "zawaːkʷʼa"
  },
  {
    "word": "зэуал1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fighter",
        "examples": []
      }
    ],
    "synonyms": [
      "зэуак1о"
    ],
    "type": "noun",
    "ipa": "zawaːɬʼ"
  },
  {
    "word": "пылъалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hanger",
        "examples": []
      }
    ],
    "synonyms": [
      "пылъап1э"
    ],
    "type": "noun",
    "ipa": "pəɬaːɬa"
  },
  {
    "word": "гъэмартын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to beat/defeat",
        "examples": [
          {
            "sentence": "Типыйхэр '''тыгъэмартыщтых'''",
            "translation": "'''We will beat''' our enemies."
          },
          {
            "sentence": "К1алэм адрэр '''ыгъэмартыгъ'''",
            "translation": "The boy '''defeated''' the other."
          }
        ]
      }
    ],
    "synonyms": [
      "тек1он",
      "гъэпщэн"
    ],
    "type": "verb",
    "ipa": "ʁamaːrtən"
  },
  {
    "word": "ш1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to encircle",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʼəħaːn"
  },
  {
    "word": "к1оц1ыщыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap in",
        "examples": []
      }
    ],
    "synonyms": [
      "щыхьан",
      "къещэк1ын",
      "къещыхьэк1ын"
    ],
    "cognate": "к1уэц1ышыхьын",
    "type": "verb",
    "ipa": "kʷʼat͡sʼəɕəħaːn"
  },
  {
    "word": "щыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap",
        "examples": []
      },
      {
        "meaning": "to fit (on the body)",
        "examples": [
          {
            "sentence": "К1алэм джанэр '''щэхьэ'''",
            "translation": "The shirt '''fits''' the boy."
          }
        ]
      },
      {
        "meaning": "to scream or shout or yell angrily at",
        "examples": [
          {
            "sentence": "Сэшъык1аем сиянэ '''къэсщыхьагъ'''",
            "translation": "When I did trouble my mother '''screamed at me'''."
          },
          {
            "sentence": "Л1ыжъыхэр угъэгуабжыхэмэ '''къупщыхьэтых'''",
            "translation": "If you make the old-men angry '''they gonna shout at you'''."
          },
          {
            "sentence": "Уиятэ к1алэм '''щэхьэ'''",
            "translation": "Your father '''is shouting''' at the boy."
          },
          {
            "sentence": "'''Усщыхьэнэу''' уфая?",
            "translation": "Do you want me '''to shout at you?'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1оц1ыщыхьан"
    ],
    "type": "verb",
    "ipa": "ɕəħaːn"
  },
  {
    "word": "икъугъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "complete",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jəqʷəʁa"
  },
  {
    "word": "псау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псэу\"",
        "examples": []
      }
    ],

    "cognate": "псо",
    "type": "adj",
    "ipa": "psaːwa"
  },
  {
    "word": "шъхьак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grievance",
        "examples": []
      },
      {
        "meaning": "unfortunate problem",
        "examples": []
      },
      {
        "meaning": "humiliation",
        "examples": []
      },
      {
        "meaning": "offense",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːkʷʼa"
  },
  {
    "word": "зыл1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to commit suicide",
        "examples": [
          {
            "sentence": "Тхьэ сэ1о '''зысл1эжьынк1э'''",
            "translation": "I swear by God '''I shall kill myself'''."
          }
        ]
      }
    ],
    "synonyms": [
      "зыук1ыжьын"
    ],
    "cognate": "зыл1эжын",
    "type": "verb",
    "ipa": "zaːɬʼaʑən"
  },
  {
    "word": "епц1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break-promise, to violate (an oath, promise, agreement)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "japt͡sʼəʑən"
  },
  {
    "word": "ш1оигъоджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dislike greatly, to detest",
        "examples": [
          {
            "sentence": "Саусырыкъо зэрэк1эмыхьагъэр лъэшэу '''ш1оигъоджагъ'''",
            "translation": "Sosruko really '''disliked''' the fact that he didn't catch up with him."
          }
        ]
      },
      {
        "meaning": "to not want",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷajəʁʷad͡ʒan"
  },
  {
    "word": "зэплъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to glance back, to look back",
        "examples": [
          {
            "sentence": "Саусырыкъо къеджагъэхэми '''зэплъэк1ыгъэп''' ихэбзагъэпи",
            "translation": "Even though they called him, Sosruko '''didn't look back''' because it was not his custom."
          }
        ]
      },
      {
        "meaning": "to turn to look",
        "examples": [
          {
            "sentence": "'''Зэплъэк1и''' гъогум къыблэк1",
            "translation": "'''Turn and look''' when you pass the road."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapɬat͡ʃʼən"
  },
  {
    "word": "фэш1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be suitable for",
        "examples": [
          {
            "sentence": "Пшъашъэм цуакъэхэр '''фэш1ун'''",
            "translation": "The shoes '''fit''' the girl."
          },
          {
            "sentence": "Джанэ ц1ык1ур пщэрым '''фэш1урэп'''",
            "translation": "The tiny shirt '''is not suitable''' for the fat one."
          }
        ]
      }
    ],

    "cognate": "хуэф1",
    "type": "verb",
    "ipa": "faʃʷʼən"
  },
  {
    "word": "фэш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"фэш1у\"",
        "examples": []
      }
    ],

    "cognate": "хуэф1",
    "type": "verb",
    "ipa": "faʃʷʼə"
  },
  {
    "word": "пцум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "layer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃʷəm"
  },
  {
    "word": "к1ьыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1ыин\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʲʼəjən"
  },
  {
    "word": "одыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bell",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wadəd͡ʒən"
  },
  {
    "word": "гъэпсык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "structure",
        "examples": [
          {
            "sentence": "гущыIэухыгъэм '''игъэпсыкI'''",
            "translation": "'''The structure of''' the sentence."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʁapsət͡ʃʼ"
  },
  {
    "word": "насыпынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unluck, bad luck, luckless",
        "examples": []
      },
      {
        "meaning": "unlucky, luckless",
        "examples": [
          {
            "sentence": "ЦIыф '''насыпынчъ'''",
            "translation": "'''Unlucky''' person."
          }
        ]
      }
    ],
    "synonyms": [
      "мыгъо",
      "тхьамык1эгъо",
      "игъуадж",
      "гъэны"
    ],
    "type": "noun",
    "ipa": "naːsəpənt͡ʂ"
  },
  {
    "word": "-нчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "-less; lacking (something); without (something)",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "nt͡ʂ"
  },
  {
    "word": "1ашэнчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "armless, one that has no arms / weapons.",
        "examples": [
          {
            "sentence": "Дзак1охэр '''1ашэнчъэу''' зэуап1эм къыщаук1ыгъых",
            "translation": "The soldiers were killed in the battle field '''while being armless'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʔaːʃatnt͡ʂa"
  },
  {
    "word": "къарыунчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "weak, fragile (usually said about humans and living things)",
        "examples": [
          {
            "sentence": "Ар узым '''къарыунчъэ''' ышIыгъ",
            "translation": "The illness made him '''fragile'''."
          },
          {
            "sentence": "'''Къарыунчъэ''' хъугъэ",
            "translation": "He '''became weak'''."
          }
        ]
      }
    ],
    "synonyms": [
      "к1оч1адж"
    ],
    "cognate": "махэ",
    "type": "adj",
    "ipa": "qaːrəwənt͡ʂ"
  },
  {
    "word": "тхьамык1эгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тхьамык1агъу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "игъуадж",
      "гъэны"
    ],
    "type": "noun",
    "ipa": "tħaːmət͡ʃʼaːʁʷ"
  },
  {
    "word": "гыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reproach, to upbraid, to blame",
        "examples": [
          {
            "sentence": "КIалэм лIыжъыр '''егыин''' фаеу зекIуагъэ.",
            "translation": "The man had '''to reproach''' the boy."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɣəjan"
  },
  {
    "word": "к1элъэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to call for",
        "examples": []
      },
      {
        "meaning": "to ask for, to beg for",
        "examples": []
      }
    ],
    "synonyms": [
      "къеджэн"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaɬaʔʷən"
  },
  {
    "word": "къеджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to call for",
        "examples": []
      }
    ],
    "synonyms": [
      "к1элъэ1ун"
    ],
    "type": "verb",
    "ipa": "qajd͡ʒan"
  },
  {
    "word": "сэку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mane",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "sakʷ"
  },
  {
    "word": "нэк1ап",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(of hair) fringe, bang",
        "examples": []
      },
      {
        "meaning": "(horse's) forelock",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nat͡ʃʼaːp"
  },
  {
    "word": "гъэжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэжьэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁaʑan"
  },
  {
    "word": "жъгъау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expression for a loud sound",
        "examples": [
          {
            "sentence": "Мыжъор едзым '''жъгъау''' къыригъэ1уагъ.",
            "translation": "When he threw the rock, it made a '''loud sound'''."
          }
        ]
      }
    ],
    "synonyms": [
      "дыргу",
      "даргу"
    ],
    "type": "intj",
    "ipa": "ʒʁaːʷ"
  },
  {
    "word": "рсысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"сысын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "səsən"
  },
  {
    "word": "гъэсысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shake something",
        "examples": [
          {
            "sentence": "Чъыгыр '''гъэсысын'''",
            "translation": "'''To shake''' the tree."
          },
          {
            "sentence": "Саусырыкъо ишы ык1э '''егъэсысы'''",
            "translation": "Sosruko's horse '''is shaking''' his tail."
          },
          {
            "sentence": "Пхъэшъхьэ-мышъхьэр къыпыбгъэтэкъуным пае чъыгыр '''бгъэсысын''' фае",
            "translation": "In order to make the fruits fall down form the tree, you must '''shake it'''."
          }
        ]
      },
      {
        "meaning": "to swing",
        "examples": [
          {
            "sentence": "Хъэреныр '''гъэсысын'''",
            "translation": "'''To swing''' the swing."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁasəsən"
  },
  {
    "word": "пкъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "post, pole, pillar, column",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъэхат1э"
    ],
    "type": "noun",
    "ipa": "pqaw"
  },
  {
    "word": "къепщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"епщэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajpɕan"
  },
  {
    "word": "епщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to blow (air)",
        "examples": [
          {
            "sentence": "Жьыбгъэр '''къепщэмэ''' чъыг тхьапэхэр сысынэу рагъажьэ.",
            "translation": "When the wind '''blows''', the tree leaves start to shake."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jpɕan"
  },
  {
    "word": "щыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "young animal, baby animal, cub, new born animal, animal's child",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕər"
  },
  {
    "word": "пэсак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guard, watchman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pasaːkʷʼ"
  },
  {
    "word": "пэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to guard, to watch over",
        "examples": [
          {
            "sentence": "Тучаным '''пэсын'''",
            "translation": "'''To watch over''' the shop."
          }
        ]
      },
      {
        "meaning": "to be on the watch (for), to lie in wait (for), to waylay",
        "examples": [
          {
            "sentence": "Баджэм '''пэсын'''",
            "translation": "'''To be on the watch for''' the fox."
          }
        ]
      },
      {
        "meaning": "to anticipate for, to wait expecting for someone or something",
        "examples": [
          {
            "sentence": "Ц1ыфым '''спэсыгъ'''",
            "translation": "'''I expecting for''' the man."
          }
        ]
      },
      {
        "meaning": "to sit on something",
        "examples": [
          {
            "sentence": "1анэм '''пэсын'''",
            "translation": "'''To sit on''' the table."
          },
          {
            "sentence": "МашIом '''пэсын'''",
            "translation": "'''To sit near''' the fire."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pasən"
  },
  {
    "word": "тещыныхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be afraid that a certain thing will happen",
        "examples": [
          {
            "sentence": "бомбэр къэоным тещыныхьэ",
            "translation": "To fear from a bomb explosion."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajɕənəħaːn"
  },
  {
    "word": "нэджэ-1удж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly, hideous (displeasing to the eye; not aesthetically pleasing)",
        "examples": []
      },
      {
        "meaning": "freak",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nad͡ʒa ʔʷəd͡ʒ"
  },
  {
    "word": "щыщынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be afraid of",
        "examples": [
          {
            "sentence": "К1элэц1ык1ур жъалымхэм '''ащэщынэ'''",
            "translation": "The little boy '''is afraid of''' monsters."
          },
          {
            "sentence": "Л1ым '''сыщэщынэ'''",
            "translation": "'''I am afraid of''' the man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəɕənan"
  },
  {
    "word": "цыхьэ мыш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to distrust",
        "examples": [
          {
            "sentence": "Л1ым '''цыхьэ фэмыш1'''",
            "translation": "'''Don't trust''' the man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼəħa məʃʼən"
  },
  {
    "word": "цыхьэ ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trust",
        "examples": [
          {
            "sentence": "Л1ым '''цыхьэ фэш1'''",
            "translation": "'''Trust''' the man."
          }
        ]
      }
    ],
    "synonyms": [
      "цыхьэ фэш1ын"
    ],
    "type": "verb",
    "ipa": "t͡sʼəħa ʃʼən"
  },
  {
    "word": "псык1эу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псынк1эу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "псынк1эу"
    ],
    "type": "adv",
    "ipa": "psət͡ʃʼaw"
  },
  {
    "word": "лъэхэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spare someone of (for example: from a punishment or debt)",
        "examples": [
          {
            "sentence": "О непэ '''къыплъэхэнэщт'''",
            "translation": "'''I will spare you''' today."
          },
          {
            "sentence": "Ахъщэ сэ к1алэм '''къэслъэхинагъ'''",
            "translation": "The boy '''spared me from''' returning money."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɬaxanan"
  },
  {
    "word": "щыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to salt",
        "examples": [
          {
            "sentence": "гъомылапхъэр '''щыун'''",
            "translation": "'''To salt''' food'."
          }
        ]
      },
      {
        "meaning": "to get out of the habit (of), to break oneself of the habit (of)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕəwən"
  },
  {
    "word": "щынэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to abandon",
        "examples": [
          {
            "sentence": "Гонахьыхэр '''щынэжьын'''",
            "translation": "'''To abandon''' sins."
          },
          {
            "sentence": "Cишъузрэ сик1алэхэрэ '''щынэжьыгъэх'''",
            "translation": "'''I abandon''' my wife and kids."
          }
        ]
      },
      {
        "meaning": "to divorce from",
        "examples": [
          {
            "sentence": "Си шъуз '''сыщынэжьыгъ'''",
            "translation": "'''I divorced''' my wife."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕənaʑən"
  },
  {
    "word": "щысэхъук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get out of the habit (of), to break oneself of the habit (of)",
        "examples": []
      },
      {
        "meaning": "to lose the skill (of), to forget (how to)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕəsaχʷət͡ʃʼən"
  },
  {
    "word": "зэ1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be disarray, to be thrown into disorder",
        "examples": [
          {
            "sentence": "Унэр '''зэ1ыхьагъ'''",
            "translation": "The house '''was disarrayed'''."
          },
          {
            "sentence": "Сомпыютэрэр '''зэ1ыхьагъ'''",
            "translation": "The computer '''was disarrayed'''."
          }
        ]
      },
      {
        "meaning": "to become dysfunctional",
        "examples": []
      },
      {
        "meaning": "to freakout",
        "examples": []
      },
      {
        "meaning": "to lose one's sanity; to become insane",
        "examples": [
          {
            "sentence": "К1алэм иянэ  '''зэ1ыхьагъ''' унэр зэрэш1оир елъэгъум",
            "translation": "The mother of the boy '''freaked out''' when she saw how the house was dirty."
          }
        ]
      },
      {
        "meaning": "to get very confused",
        "examples": [
          {
            "sentence": "'''Сызэ1ыхьагъ'''",
            "translation": "'''I became confused'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʔaħan"
  },
  {
    "word": "зэ1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэ1ыхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaħan"
  },
  {
    "word": "зы1ыгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зы1эгъэхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaʁaħaːn"
  },
  {
    "word": "зы1эгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэ1ыгъэхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaʁaħaːn"
  },
  {
    "word": "шъхьасын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pity, to commiserate, to have mercy upon",
        "examples": []
      }
    ],
    "synonyms": [
      "гуегъун",
      "гук1эгъу фэлъэгъун"
    ],
    "type": "verb",
    "ipa": "ʂħaːsən"
  },
  {
    "word": "зэщыкъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break down (of a device, etc.)",
        "examples": [
          {
            "sentence": "Машинэр зэщыкъуагъ",
            "translation": "The car '''broke down'''."
          }
        ]
      },
      {
        "meaning": "to get exhausted",
        "examples": [
          {
            "sentence": "Л1ыр зэщыкъуагъ",
            "translation": "The man '''got exhausted'''."
          }
        ]
      },
      {
        "meaning": "to lose energy and become weak and feeble",
        "examples": []
      },
      {
        "meaning": "to deteriorate, to worsen, to become worse, to go bad",
        "examples": [
          {
            "sentence": "1офыр зэщэкъо",
            "translation": "The job '''worsen'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaɕəqʷan"
  },
  {
    "word": "къарыунчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къарыунчъэ\"",
        "examples": []
      }
    ],

    "cognate": "махэ",
    "type": "adj",
    "ipa": "qaːrəwənt͡ʂ"
  },
  {
    "word": "-нчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "-less; lacking (something); without (something)",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "nt͡ʂ"
  },
  {
    "word": "насыпынчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"насыпынчъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naːsəpənt͡ʂ"
  },
  {
    "word": "1ашэнчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1ашэнчъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔaːʃatnt͡ʂ"
  },
  {
    "word": "шэнынчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шэнычъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃant͡ʂa"
  },
  {
    "word": "шэнынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шэнычъэ\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃant͡ʂa"
  },
  {
    "word": "губжыпх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hot-tempered, quick-tempered, irascible",
        "examples": []
      }
    ],
    "synonyms": [
      "губжыгъош1у"
    ],
    "type": "adj",
    "ipa": "ɡʷəbʒəpx"
  },
  {
    "word": "губжыгъош1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hot-tempered, quick-tempered, irascible",
        "examples": []
      }
    ],
    "synonyms": [
      "губжыпх"
    ],
    "type": "adj",
    "ipa": "ɡʷəbʒəʁʷaʃʷʼ"
  },
  {
    "word": "губж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "anger",
        "examples": [
          {
            "sentence": "'''Губжым''' къыхэкIэу къэуплъыжьын",
            "translation": "He turned red with anger."
          },
          {
            "sentence": "'''Губжым''' хэтэу",
            "translation": "Rashly."
          },
          {
            "sentence": "'''Губжыр''' текъутэн",
            "translation": "Rashly to pounce on somebody."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəbʒ"
  },
  {
    "word": "ипшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get through, to wriggle through, to climb through, to squeeze through",
        "examples": [
          {
            "sentence": "Гъуанэм '''ипшын'''",
            "translation": "'''To squeeze''' through the hole."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəpʃən"
  },
  {
    "word": "фэпщэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to breathe",
        "examples": [
          {
            "sentence": "К1алэр '''мэфапщэ'''",
            "translation": "The boy '''is breathing'''."
          },
          {
            "sentence": "Пцэжъыер псы ч1эгъым '''хэфыпщэшъущт'''",
            "translation": "The fish '''can breathe''' under the water."
          },
          {
            "sentence": "Псы ч1эгъым '''ухыфэпщэшъутэп'''",
            "translation": "'''You will not be capable of breathing''' under the water."
          },
          {
            "sentence": "Бэрэ учъэмэ нэхь псынк1эу '''уфэпщэщт'''",
            "translation": "If you run a lot '''you will breathe''' faster."
          },
          {
            "sentence": "'''Фапщ'''",
            "translation": "'''Breath'''."
          }
        ]
      }
    ],
    "synonyms": [
      "жьы къэщэн"
    ],
    "type": "verb",
    "ipa": "fapʃan"
  },
  {
    "word": "фапщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "breathe",
        "examples": [
          {
            "sentence": "Псым '''фапшэ''' шыбгъотэп",
            "translation": "You will not find '''breathe''' in the water."
          }
        ]
      },
      {
        "meaning": "oxygen",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "faːpʃa"
  },
  {
    "word": "те1эбэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to touch the surface of something (repetitively)",
        "examples": [
          {
            "sentence": "1анэм у1э ш1оеу '''утемы1эбэхь'''",
            "translation": "'''Don't touch (the surface of)''' the table with your dirty hands."
          },
          {
            "sentence": "К1алэм 1анэм '''те1эбэхьы''' ы1э ш1оеу",
            "translation": "The boy '''touches (the surface of)''' the table while his hand is dirty."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʔabaħən"
  },
  {
    "word": "зэблэдзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cross",
        "examples": []
      },
      {
        "meaning": "to convert something into another thing",
        "examples": []
      },
      {
        "meaning": "to mix-up between (usually by confusion, mistake, muddle or misunderstanding)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zabɮad͡zən"
  },
  {
    "word": "хьэмашэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "penis",
        "examples": []
      }
    ],
    "synonyms": [
      "манэ",
      "----"
    ],
    "type": "noun",
    "ipa": "ħamaːʃa"
  },
  {
    "word": "нэшан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэшанэ\"",
        "examples": []
      }
    ],

    "cognate": "нэщэнэ",
    "type": "noun",
    "ipa": "naʃaːn"
  },
  {
    "word": "нэшанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sign, indication",
        "examples": [
          {
            "sentence": "гъатхэм '''инэшанэхэр'''",
            "translation": "'''The signs of''' sprint."
          }
        ]
      }
    ],

    "cognate": "нэщэнэ",
    "type": "noun",
    "ipa": "naʃaːn"
  },
  {
    "word": "зэпымыурэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "continuous",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "zapəməwra"
  },
  {
    "word": "зэпыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stop, to end",
        "examples": [
          {
            "sentence": "Ощхыр '''зэпыугъ'''",
            "translation": "The rain '''has ended'''."
          }
        ]
      },
      {
        "meaning": "(temporarily) to halt, to come to a halt, to pause",
        "examples": [
          {
            "sentence": "1офшIэныр '''зэпыугъ'''",
            "translation": "The work '''has paused'''."
          }
        ]
      }
    ],
    "synonyms": [
      "уцун",
      "зэтеуцон"
    ],
    "type": "verb",
    "ipa": "zapəwən"
  },
  {
    "word": "зэтеуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(temporarily) to halt, to come to a halt, to pause",
        "examples": [
          {
            "sentence": "Филымыр '''зэтеуцуагъ'''",
            "translation": "The film '''has paused'''."
          }
        ]
      },
      {
        "meaning": "to stand on each other",
        "examples": [
          {
            "sentence": "Ц1ыфыхэр '''зэтеуцуагъэх'''",
            "translation": "The people '''stood on each other'''."
          }
        ]
      }
    ],
    "synonyms": [
      "зэпыун"
    ],
    "cognate": "зэтеувэн",
    "type": "verb",
    "ipa": "zatajwt͡ʃʷan"
  },
  {
    "word": "теуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stop on",
        "examples": []
      },
      {
        "meaning": "to stand still on",
        "examples": []
      }
    ],

    "cognate": "теувэн",
    "type": "verb",
    "ipa": "tajdt͡ʃʷan"
  },
  {
    "word": "псэхал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(animate) carrion, offal (dead flesh; carcasses), dead (of animals)",
        "examples": [
          {
            "sentence": "Чэмыр '''псэхалIэ''' хъугъэ",
            "translation": "It became a cow '''carrion'''."
          },
          {
            "sentence": "Ежь шIуимыбзыгъэр '''псэхалI'''",
            "translation": "The one that was not butcher is '''a carrion'''."
          }
        ],
        "tags": [
          "animate"
        ]
      }
    ],

    "cognate": "псэхэл1э",
    "type": "noun",
    "ipa": "psaxaɬʼz"
  },
  {
    "word": "ш1обзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to knife, to carve up; to butcher",
        "examples": []
      },
      {
        "meaning": "to kill by cutting.",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼabzən"
  },
  {
    "word": "гъэбыяун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to calm, to quiet, to soothe",
        "examples": [
          {
            "sentence": "КIалэу гъырэр '''гъэбыяун''' фае",
            "translation": "The boy that is crying needs '''to calm down'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэ1эсэн",
      "зыгъэсамбырын"
    ],
    "type": "verb",
    "ipa": "ʁabəjaːn"
  },
  {
    "word": "гъэ1эсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tame",
        "examples": []
      },
      {
        "meaning": "to teach good manners, to teach good behavior",
        "examples": []
      },
      {
        "meaning": "to calm, to quiet, to soothe",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэбыяун",
      "гъэсэн"
    ],
    "type": "verb",
    "ipa": "ʁaʔasan"
  },
  {
    "word": "л1ыук1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "murderer",
        "examples": []
      }
    ],
    "synonyms": [
      "ук1ак1о",
      "мэхъаджэ"
    ],
    "type": "noun",
    "ipa": "maχaːd͡ʒa"
  },
  {
    "word": "мысэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guilty",
        "examples": []
      },
      {
        "meaning": "culprit",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "məsa"
  },
  {
    "word": "хэгущы1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suddenly speak up among a group of people",
        "examples": []
      },
      {
        "meaning": "to interrupt or break into a conversation by speaking",
        "examples": []
      },
      {
        "meaning": "to get a word in edgewise",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaɡʷəɕəʔaħaːn"
  },
  {
    "word": "хьэкъуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trough, waterer (device from which animals drink)",
        "examples": [
          {
            "sentence": "Былымхэр псы ешъохэу '''хьэкъуашъом''' кIэлъырытыгъэх",
            "translation": "The cattle were next to the '''trough'''."
          }
        ]
      },
      {
        "meaning": "tub",
        "examples": []
      }
    ],
    "synonyms": [
      "1анлъэ"
    ],
    "type": "noun",
    "ipa": "ħaqʷaːʃʷa"
  },
  {
    "word": "гу егъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуегъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын"
    ],
    "type": "verb",
    "ipa": "ɡʷə jaʁʷən"
  },
  {
    "word": "гум егъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуегъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын"
    ],
    "type": "verb",
    "ipa": "ɡʷm jaʁən"
  },
  {
    "word": "тегъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to divert",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajʁawən"
  },
  {
    "word": "техьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go on the surface of something",
        "examples": [
          {
            "sentence": "К1алэр унашъхьэм '''техьэ'''",
            "translation": "The boy '''is going up''' the roof."
          },
          {
            "sentence": "Джэхашъом '''утемыхь''' уицуакъэхэр шъоихэу",
            "translation": "'''Don't go on''' the floor with your shoes being dirty."
          },
          {
            "sentence": "Л1ыр сценэм '''къытехьагъ''' гущы1э къитынэу",
            "translation": "The man '''came up to''' the stage to give a speech."
          }
        ]
      },
      {
        "meaning": "to invade in order to attack",
        "examples": [
          {
            "sentence": "Урыс дзэхэр чылэм '''къытехьагъэх'''",
            "translation": "The Russian armies '''assaulted''' the village."
          },
          {
            "sentence": "'''Укъыстемыхь''' джы, сыхьазырэп",
            "translation": "'''Don't assault me (my base)''', I am not ready."
          }
        ]
      }
    ],
    "cognate": "техьэн",
    "type": "verb",
    "ipa": "tajħaːn"
  },
  {
    "word": "те-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) on something",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) on the surface of something",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "taj"
  },
  {
    "word": "ч1э-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) under something",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "t͡ʂʼa"
  },
  {
    "word": "дэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) inside another object",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) on to a defined area",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) between",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "da"
  },
  {
    "word": "пы-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) attached",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      },
      {
        "meaning": "(added to verbs) hanged",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "pə"
  },
  {
    "word": "бгъодэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) aside, besides",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "bʁʷada"
  },
  {
    "word": "го-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) aside, besides",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "ɡʷa"
  },
  {
    "word": "к1оц1ы-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) within inside",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "kʷʼat͡sʼə"
  },
  {
    "word": "шъхьадэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) above",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "t͡ʂħaːda"
  },
  {
    "word": "шъхьапыры-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) beyond",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "t͡ʂħaːpərə"
  },
  {
    "word": "жэхэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) directly onto (usually with force)",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "ʒaxa"
  },
  {
    "word": "жэдэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) toward the mouth",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "ʒada"
  },
  {
    "word": "лъэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) toward",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "ɬa"
  },
  {
    "word": "зэк1э-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) backward",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "zat͡ʃʼa"
  },
  {
    "word": "1у-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) around, near, by",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "ʔʷə"
  },
  {
    "word": "пэ1у-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) in front of something",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "paʔʷə"
  },
  {
    "word": "къо-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) behind",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "qʷa"
  },
  {
    "word": "блэ-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) pass, past",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "bɬa"
  },
  {
    "word": "и-",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(added to verbs) inside",
        "examples": [],
        "tags": [
          "added to verbs"
        ]
      }
    ],
    "type": "prefix",
    "ipa": "jə"
  },
  {
    "word": "ихьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go / inside something, to enter",
        "examples": [
          {
            "sentence": "К1алэр унэм '''ихьагъ'''",
            "translation": "The boy '''entered''' the house."
          },
          {
            "sentence": "Гъуанэм '''уимыхь'''",
            "translation": "'''Don't go inside''' the whole."
          },
          {
            "sentence": "Силъакъохэр сицуакъэ '''ихьэхэрэп'''",
            "translation": "My legs '''don't go inside''' my shoes."
          }
        ]
      }
    ],
    "synonyms": [
      "ихьэн"
    ],
    "type": "verb",
    "ipa": "jəħaːn"
  },
  {
    "word": "дэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go / enter a defined area",
        "examples": [
          {
            "sentence": "К1алэр щагум '''дэхьагъ'''",
            "translation": "The boy '''went inside''' the yard."
          }
        ]
      }
    ],
    "synonyms": [
      "дэхьэн"
    ],
    "type": "verb",
    "ipa": "daħaːn"
  },
  {
    "word": "телъэдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to attack with unfounded accusations",
        "examples": []
      },
      {
        "meaning": "to run toward; to rush at (usually to assault)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajɬadan"
  },
  {
    "word": "гъэпсэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone rest",
        "examples": [
          {
            "sentence": "Л1ым '''зегъэпсэфы''' 1офым икъынэужь",
            "translation": "The man '''is resting''' after work."
          },
          {
            "sentence": "Седжэгъахэмэ т1эк1у '''зысгъэпсэфынэу''' щагум сыкъик1ыщт",
            "translation": "After I done studying, I will come out to the yard '''to rest''' briefly."
          }
        ]
      }
    ],

    "cognate": "гъэпсэхун",
    "type": "verb",
    "ipa": "ʁapsafən"
  },
  {
    "word": "к1ыфы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pale",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃʼəfə"
  },
  {
    "word": "чъэк1ыгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grow pale, to turn white",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂat͡ʃʼəʁa"
  },
  {
    "word": "стаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ash",
        "examples": []
      }
    ],

    "cognate": "яжьэ",
    "type": "noun",
    "ipa": "staːf"
  },
  {
    "word": "гъоплъашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъоплъышъо\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷapɬəʂʷa"
  },
  {
    "word": "джан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "great",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "d͡ʒaːn"
  },
  {
    "word": "зыплъыхьап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zəpɬəħaːpʼa"
  },
  {
    "word": "зыплъыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to look around",
        "examples": []
      }
    ],

    "cognate": "зыплъыхьын",
    "type": "verb",
    "ipa": "zəpɬəħaːn"
  },
  {
    "word": "зыплъыхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыплъыхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəpɬəħən"
  },
  {
    "word": "шъыбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to block/seal a flow or a hole",
        "examples": [
          {
            "sentence": "К1алэм гъуанэр '''ышъыбыгъ'''",
            "translation": "The boy '''blocked''' the hole."
          },
          {
            "sentence": "Л1ыжъым илъынтфэхэр '''шъыбыгъэхи''', лъы тэрэзэу арик1ожьырэп",
            "translation": "Because the old man's veins '''got sealed''', blood doesn't flow them right."
          },
          {
            "sentence": "Гъогур '''шъыбыгъэ'''",
            "translation": "The road '''is blocked'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʂəbən"
  },
  {
    "word": "пхъуант",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trunk, box, chest",
        "examples": []
      }
    ],
    "synonyms": [
      "къэмлан"
    ],
    "type": "noun",
    "ipa": "pχʷaːnt"
  },
  {
    "word": "кушъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seat (car seat, bicycle seat)",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъуант"
    ],
    "type": "noun",
    "ipa": "kʷəʂħa"
  },
  {
    "word": "лъэкъорыгъачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bicycle",
        "examples": []
      }
    ],
    "synonyms": [
      "лъэрыгъачъэ",
      "кушъхьэфачъэ"
    ],
    "cognate": "лъакъуэрыгъажэ",
    "type": "noun",
    "ipa": "ɬaqʷarəʁaːt͡ʂa"
  },
  {
    "word": "лъэрыгъачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bicycle",
        "examples": []
      }
    ],
    "synonyms": [
      "кушъхьэфачъэ",
      "лъэкъорыгъачъэ"
    ],
    "cognate": "лъакъуэрыгъажэ",
    "type": "noun",
    "ipa": "ɬarəʁaːt͡ʂa"
  },
  {
    "word": "уты еуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"уты\"",
        "examples": []
      }
    ],
    "synonyms": [
      "уты"
    ],
    "type": "noun",
    "ipa": "wətə jawaːɬa"
  },
  {
    "word": "щыды",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "donkey",
        "examples": []
      }
    ],

    "cognate": "шыд",
    "type": "noun",
    "ipa": "ɕədə"
  },
  {
    "word": "мэкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hay",
        "examples": []
      }
    ],
    "synonyms": [
      "белэ"
    ],
    "type": "noun",
    "ipa": "maqʷ"
  },
  {
    "word": "хъык1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "organizer",
        "examples": []
      },
      {
        "meaning": "head of the a case",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χət͡ʃʼə"
  },
  {
    "word": "нэмык1ыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэмык1\"",
        "examples": []
      }
    ],
    "synonyms": [
      "нэмык1"
    ],
    "type": "adj",
    "ipa": "namət͡ʃʼər"
  },
  {
    "word": "уты егъэшхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone eat a blow",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wtə jaʁaʃxən"
  },
  {
    "word": "гъогуонэк1уныр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "journey",
        "examples": []
      }
    ],
    "synonyms": [
      "зек1о"
    ],
    "cognate": "гъуэгурык1уэныгъэ",
    "type": "noun",
    "ipa": "ʁʷaɡʷəwanakʷʼənər"
  },
  {
    "word": "зек1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "match",
        "examples": []
      },
      {
        "meaning": "trip, journey, travel",
        "examples": []
      }
    ],
    "synonyms": [
      "гъогуонэк1уныр"
    ],
    "type": "noun",
    "ipa": "zajkʷʼa"
  },
  {
    "word": "хэгъошъхьа1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "surrounding area (of a village or city)",
        "examples": []
      }
    ],
    "synonyms": [
      "хэгъуашъхьэ"
    ],
    "type": "noun",
    "ipa": "xaʁʷaʂħaʔʷ"
  },
  {
    "word": "хэгъуашъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "surrounding area (of a village or city)",
        "examples": []
      }
    ],
    "synonyms": [
      "хэгъошъхьа1у"
    ],
    "type": "noun",
    "ipa": "xaʁʷaːʂħa"
  },
  {
    "word": "л1эужьыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"л1эужыгъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "л1эужьыгъо"
    ],
    "type": "noun",
    "ipa": "ɬʼawʑəʁʷa"
  },
  {
    "word": "л1эужыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the thing one is made of",
        "examples": []
      },
      {
        "meaning": "kin (from which one is originated), origin kin",
        "examples": []
      },
      {
        "meaning": "type, variety",
        "examples": []
      }
    ],

    "cognate": "л1эужьыгъуэ",
    "type": "noun",
    "ipa": "ɬʼawʒəʁʷa"
  },
  {
    "word": "л1экъуац1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "family name, surname",
        "examples": []
      }
    ],
    "synonyms": [
      "унэгъуац1э"
    ],
    "cognate": "л1экъуэц1э",
    "type": "noun",
    "ipa": "ɬʼaqʷaːt͡sʼa"
  },
  {
    "word": "къыдэк1ухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыдэк1охьын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къыухьан"
    ],
    "type": "verb",
    "ipa": "qədakʷʼəħaːn"
  },
  {
    "word": "къэк1ухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to walk around",
        "examples": [
          {
            "sentence": "К1алэм чылэр '''къек1охьы'''",
            "translation": "The boy '''is walking around''' in the village."
          }
        ]
      },
      {
        "meaning": "to perambulate, to roam, to stroll, to ramble",
        "examples": [
          {
            "sentence": "Чэщым '''къэмык1охь'''",
            "translation": "'''Don't roam''' in the night."
          },
          {
            "sentence": "Чылэр '''къэпк1охьынэу''' уфая?",
            "translation": "Wanna '''ramble''' in the village?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qakʷʼəħaːn"
  },
  {
    "word": "л1эуж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "posterity",
        "examples": []
      },
      {
        "meaning": "descendants",
        "examples": []
      }
    ],

    "cognate": "л1эужь",
    "type": "noun",
    "ipa": "ɬʼawəʒ"
  },
  {
    "word": "лъэпкъэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fellow tribesman",
        "examples": []
      },
      {
        "meaning": "kinsman, a person from the same kin, kind or race",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬapq"
  },
  {
    "word": "корэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "circle surrounded by people, circle of people",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷaran"
  },
  {
    "word": "корэныгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the center of a circle surrounded by people, the center of circle of people",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷaranəɡʷ"
  },
  {
    "word": "хэкъутыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break/shatter an object in a certain area (like inside a battlefield)",
        "examples": [
          {
            "sentence": "Дзак1ом ипчы зэуап1эм '''хикъутыхьагъ'''",
            "translation": "The soldier '''broke''' his spear in the battlefield."
          }
        ]
      }
    ],

    "cognate": "хэкъутыхьын",
    "type": "verb",
    "ipa": "xaqʷətəħaːn"
  },
  {
    "word": "зэрихьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to commit; to perform",
        "examples": [
          {
            "sentence": "Л1ым егъэзыгъ 1офы '''зэрехьэ'''",
            "translation": "The man '''does''' a desperate job."
          },
          {
            "sentence": "Л1ым гонахьыхэр '''зэрехьэх'''",
            "translation": "The man '''is committing''' sins."
          },
          {
            "sentence": "Л1ым къымар '''зэрихьэу''' щытыгъ",
            "translation": "The man used to '''do''' gambling."
          },
          {
            "sentence": "Л1ым зинэ '''зэрихьагъ'''",
            "translation": "The man '''committed''' adultery."
          },
          {
            "sentence": "Ш1ухэр '''зэбхьан''' фаех нахь, дэихэрэп",
            "translation": "You need '''to do''' good deeds, not bad ones."
          },
          {
            "sentence": "Саусырыкъо заом л1ыгъэшхо '''щызэрихьагъ'''",
            "translation": "Susroku '''carried out''' great bravery in the battle."
          }
        ]
      }
    ],
    "synonyms": [
      "зэрихьэн"
    ],
    "type": "verb",
    "ipa": "zariħan"
  },
  {
    "word": "шъузабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "widow",
        "examples": []
      }
    ],

    "cognate": "фызабэ",
    "type": "noun",
    "ipa": "ʃʷəzaːba"
  },
  {
    "word": "шъхьач",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back of the head; occiput",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːt͡ʃ"
  },
  {
    "word": "1эчъэ-лъэчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become anxious / uneasy",
        "examples": []
      },
      {
        "meaning": "to run back and forth",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьачъэ-лъэчъэн"
    ],
    "type": "verb",
    "ipa": "ʔat͡ʂa ɬat͡ʂan"
  },
  {
    "word": "шъхьачъэ-лъэчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become anxious / uneasy",
        "examples": []
      },
      {
        "meaning": "to run back and forth",
        "examples": []
      }
    ],
    "synonyms": [
      "1эчъэ-лъэчъэн"
    ],
    "type": "adj",
    "ipa": "ʂħaːt͡ʂa ɬat͡ʂan"
  },
  {
    "word": "иш1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to encircle someone or something inside",
        "examples": [
          {
            "sentence": "Чэмыхэр л1ым хъагъэм '''риш1ыхьагъэх'''",
            "translation": "The man '''encircled''' the cows inside the fence."
          }
        ]
      },
      {
        "meaning": "to cage",
        "examples": [
          {
            "sentence": "К1алэм бзыур гъучI хъагъэм '''риш1ыхьагъ'''",
            "translation": "The boy '''caged''' the bird int he cage."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəʃʼəħaːn"
  },
  {
    "word": "иш1эхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"иш1ыхьан\"",
        "examples": []
      }
    ],
    "synonyms": [
      "иш1ыхьан"
    ],
    "type": "verb",
    "ipa": "jəʃʼaħaːn"
  },
  {
    "word": "щэхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "except, besides, anything else",
        "examples": [
          {
            "sentence": "К1алэм ихьэ закъо '''щэхъу''' к1ымыгъоу ежьагъ",
            "translation": "The boy went off without anyone accompanying him expect his dog."
          }
        ]
      }
    ],
    "synonyms": [
      "ехъурэ"
    ],
    "type": "adv",
    "ipa": "ɕaχʷ"
  },
  {
    "word": "к1ыгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to accompany, to escort",
        "examples": [
          {
            "sentence": "К1алэм ихьэ закъо щэхъу '''к1ымыгъоу''' ежьагъ",
            "translation": "The boy went off without anyone accompanying him expect his dog."
          },
          {
            "sentence": "Л1ыжъым изакъоу к1ощтэп, зыгорэ '''к1ыгъун''' фае",
            "translation": "The old man can't go alone, someone needs '''to escort''' him."
          }
        ]
      },
      {
        "meaning": "to live with",
        "examples": [
          {
            "sentence": "Шъузыр л1ым '''к1ыгъун''' ыдэрэп",
            "translation": "The woman doesn't accept '''living with''' the man."
          }
        ]
      }
    ],
    "synonyms": [
      "гъусэн"
    ],
    "cognate": "щ1ыгъун",
    "type": "verb",
    "ipa": "t͡ʃʼəʁʷən"
  },
  {
    "word": "1угъуашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smoky",
        "examples": []
      },
      {
        "meaning": "tinted, smoke-colored",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔʷəʁʷaʃʷa"
  },
  {
    "word": "шъотехьэ-тек1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "changeable color",
        "examples": []
      },
      {
        "meaning": "color shifting",
        "examples": [
          {
            "sentence": "Уашъор '''шъотехьэ-тек1'''",
            "translation": "The sky is '''shifting (in color)'''."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ʃʷatajħa-tat͡ʃʼ"
  },
  {
    "word": "къуджыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rough (road or area)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "qʷəd͡ʒər"
  },
  {
    "word": "къыухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go around",
        "examples": [
          {
            "sentence": "Псы хъураер '''къыухьи''' къэкIуагъ",
            "translation": "He '''went around''' the lake and came."
          }
        ]
      }
    ],
    "synonyms": [
      "къыдэк1ухьан"
    ],
    "type": "verb",
    "ipa": "qəwəħaːn"
  },
  {
    "word": "псы хъурай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"псыхъурай\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psə χʷəraːj"
  },
  {
    "word": "ш1уц1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blackness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼt͡sʼaːʁa"
  },
  {
    "word": "ихьисапын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to think, to believe, to suppose",
        "examples": [
          {
            "sentence": "университетым ч1эхьан ащ '''ихьисап'''",
            "translation": "'''he thinks''' to go to university."
          },
          {
            "sentence": "сыда ащ ышIэн '''ихьисапыр'''?",
            "translation": "what is '''he supposed''' to do?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəħisaːpən"
  },
  {
    "word": "фэшъхьаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "different",
        "examples": []
      }
    ],
    "synonyms": [
      "нэмык1"
    ],
    "type": "adj",
    "ipa": "faʂħaːf"
  },
  {
    "word": "техьэ-тек1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "changeable",
        "examples": []
      },
      {
        "meaning": "shifting often",
        "examples": []
      },
      {
        "meaning": "impermanent, inconstant",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tajħa-tat͡ʃʼ"
  },
  {
    "word": "ш1оигъуадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "undesirable, unwanted",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃʷajəʁʷaːd͡ʒ"
  },
  {
    "word": "лъэшэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "greatly",
        "examples": [
          {
            "sentence": "Саусырыкъо зэрэк1эмыхьагъэр '''лъэшэу''' ш1оигъоджагъ",
            "translation": "Sosruko '''greatly''' detested the fact that he didn't catch up with him."
          }
        ]
      },
      {
        "meaning": "powerfully",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "ɬaʃaw"
  },
  {
    "word": "лъэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъэшы\"",
        "examples": []
      }
    ],

    "cognate": "лъэщ",
    "type": "adj",
    "ipa": "ɬaʃ"
  },
  {
    "word": "ерагъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barely, with great difficulty",
        "examples": [
          {
            "sentence": "Л1ыжъыр ерагъэу '''мак1о'''",
            "translation": "The old man goes '''with great difficulty'''."
          },
          {
            "sentence": "Мы гурупым ерагъэу сыкъыхэхьэжьыгъ",
            "translation": "I '''barely''' came back to this group."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jaraːʁaw"
  },
  {
    "word": "ерагък1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barely, with great difficulty",
        "examples": [
          {
            "sentence": "Мы гурупым '''ерагък1э''' сыкъыхэхьэжьыгъ",
            "translation": "I '''barely''' came back to this group."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "jaraːʁt͡ʃʼa"
  },
  {
    "word": "къэгъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come back",
        "examples": [
          {
            "sentence": "Садэжь '''къэгъаз'''",
            "translation": "'''Return''' to me."
          }
        ]
      },
      {
        "meaning": "to turn something (toward the speaker)",
        "examples": [
          {
            "sentence": "К1алэм '''къигъазагъ''' телевидение",
            "translation": "The boy '''turned''' the television."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁazan"
  },
  {
    "word": "зэплъэк1ыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the time to look back",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zapɬat͡ʃʼəʁʷ"
  },
  {
    "word": "ифэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall into",
        "examples": []
      },
      {
        "meaning": "to have the opportunity or change to",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jəfan"
  },
  {
    "word": "къыфифэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall into for",
        "examples": []
      },
      {
        "meaning": "to have the opportunity or change to",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəfəjfan"
  },
  {
    "word": "т1аркъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bifurcation (A division into two branches.)",
        "examples": []
      },
      {
        "meaning": "furcation (A division into several branches.)",
        "examples": []
      },
      {
        "meaning": "forked",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tʼaːrqʷ"
  },
  {
    "word": "1ол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"е1ол1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔʷaɬʼan"
  },
  {
    "word": "е1ол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to express something concerning; to express something according to",
        "examples": [
          {
            "sentence": "Сыд филымым '''еп1уал1эрэр'''?",
            "translation": "What '''you say about''' the film?"
          },
          {
            "sentence": "Л1ым дэеу ыш1агъэхэм дэгъоп '''ес1ол1энэу''' си1эр",
            "translation": "I don't have something good '''to say concerning''' the bad things the man did."
          },
          {
            "sentence": "Л1ыжъым гущы1э ш1ух ны1эп '''ра1уал1эхэрэр'''",
            "translation": "Only good words '''are said about''' the old man."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʔʷaɬʼan"
  },
  {
    "word": "1ул1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sweep off",
        "examples": [
          {
            "sentence": "Саусырыкъо онэгум '''ри1ул1ыгъ'''",
            "translation": "'''He swept him''' from the horse back."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷəɬʼan"
  },
  {
    "word": "хьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go / enter / advance / move to",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэн"
    ],
    "type": "verb",
    "ipa": "ħaːn"
  },
  {
    "word": "хэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to join (a group, an organization or a company of people)",
        "examples": [
          {
            "sentence": "К1алэр ц1ыфыхэр '''ахахьэ'''",
            "translation": "The boy '''joins''' the people."
          }
        ]
      },
      {
        "meaning": "to go into / enter an area of mass (liquid, solid or gas), to go through",
        "examples": [
          {
            "sentence": "К1алэр мэзым '''хахьэ'''",
            "translation": "The boy '''enters''' the forest."
          },
          {
            "sentence": "К1алэр псым '''хэхьагъ'''",
            "translation": "The boy '''entered''' the water."
          },
          {
            "sentence": "Маш1ом '''ухэмыхь'''",
            "translation": "'''Don't go into''' the fire."
          },
          {
            "sentence": "Ц1ыфыхэр дэпкъым '''хэхьашъущтэп'''",
            "translation": "Humans '''can't go through''' the wall"
          }
        ]
      }
    ],
    "synonyms": [
      "хэхьэн"
    ],
    "type": "verb",
    "ipa": "xaħaːn"
  },
  {
    "word": "шъхьатеп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьатеп1о\"",
        "examples": []
      }
    ],
    "synonyms": [
      "п1о",
      "теп1о",
      "шъхьатеп1о",
      "шъхьэтэп1"
    ],
    "type": "noun",
    "ipa": "ʂħaːtepʼa"
  },
  {
    "word": "пыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to plug",
        "examples": [
          {
            "sentence": "USB-ыр '''пыхьагъ''' компьютерым",
            "translation": "The USB '''plugged into''' the computer."
          }
        ]
      },
      {
        "meaning": "to attach",
        "examples": [
          {
            "sentence": "Легохэр '''зэпыхьах'''",
            "translation": "The Legos are '''attaching with each other'''."
          }
        ]
      },
      {
        "meaning": "to start engaging with a certain field, subject or hobby",
        "examples": [
          {
            "sentence": "К1алэр спортым '''пыхьагъ'''",
            "translation": "The boy '''started to do''' sport."
          },
          {
            "sentence": "Еджэным '''упыхьан''' фай",
            "translation": "You need '''to start engaging with''' studies."
          }
        ]
      }
    ],
    "synonyms": [
      "пыхьэн"
    ],
    "type": "verb",
    "ipa": "pəħaːn"
  },
  {
    "word": "пэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "paħaːn"
  },
  {
    "word": "шъхьатеп1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lid (The top or cover of a container)",
        "examples": []
      }
    ],
    "synonyms": [
      "п1о",
      "теп1о",
      "шъхьэтэп1"
    ],
    "type": "noun",
    "ipa": "ʂħaːtepʷʼa"
  },
  {
    "word": "шъхьэтэп1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьатеп1о\"",
        "examples": []
      }
    ],
    "synonyms": [
      "п1о",
      "теп1о",
      "шъхьатеп1о"
    ],
    "type": "noun",
    "ipa": "ʂħaːtepʼa"
  },
  {
    "word": "теп1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lid (The top or cover of a container)",
        "examples": []
      }
    ],
    "synonyms": [
      "п1о",
      "шъхьатеп1о",
      "шъхьэтэп1"
    ],
    "type": "noun",
    "ipa": "tajpʷʼa"
  },
  {
    "word": "п1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lid (The top or cover of a container)",
        "examples": []
      }
    ],
    "synonyms": [
      "теп1о",
      "шъхьатеп1о",
      "шъхьэтэп1"
    ],
    "type": "noun",
    "ipa": "pʷʼa"
  },
  {
    "word": "гохьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go besides",
        "examples": [
          {
            "sentence": "К1алэр унэм '''гохьэ'''",
            "translation": "The boy '''goes besides''' the house."
          }
        ]
      }
    ],
    "synonyms": [
      "гохьэн",
      "бгъодэхьан"
    ],
    "type": "verb",
    "ipa": "ɡʷaħaːn"
  },
  {
    "word": "бгъодэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go besides",
        "examples": [
          {
            "sentence": "К1алэр унэм '''бгъодэхьэ'''",
            "translation": "The boy '''goes besides''' the house."
          }
        ]
      }
    ],
    "synonyms": [
      "бгъудэхьэн",
      "гохьан"
    ],
    "type": "verb",
    "ipa": "bʁʷadaaħaːn"
  },
  {
    "word": "1ухьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go near",
        "examples": [
          {
            "sentence": "К1алэр чъыгым '''1ухьагъ'''",
            "translation": "The boy '''went near''' the tree."
          }
        ]
      }
    ],
    "synonyms": [
      "1ухьэн"
    ],
    "type": "verb",
    "ipa": "ʔʷəħaːn"
  },
  {
    "word": "къохьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go behind",
        "examples": [
          {
            "sentence": "К1алэр чъыгым '''къохьагъ'''",
            "translation": "The boy '''went behind''' the tree."
          }
        ]
      }
    ],
    "synonyms": [
      "къуэхьэн"
    ],
    "type": "verb",
    "ipa": "qʷaħaːn"
  },
  {
    "word": "к1оц1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collide into",
        "examples": [
          {
            "sentence": "К1алэр дэпкъым '''к1оц1ыхьагъ'''",
            "translation": "The boy '''collide into''' the wall."
          }
        ]
      },
      {
        "meaning": "to penetrate into",
        "examples": [
          {
            "sentence": "Шэр пхъэм '''к1оц1ыхьан'''",
            "translation": "The bullet '''to penetrate into''' the wood."
          }
        ]
      },
      {
        "meaning": "to go within inside something",
        "examples": [
          {
            "sentence": "Тхьапш шхынэу '''къыпк1оц1ахьэрэр?'''",
            "translation": "How much food '''goes into you?'''"
          }
        ]
      }
    ],
    "synonyms": [
      "к1уэц1ыхьэн"
    ],
    "type": "verb",
    "ipa": "kʷʼat͡sʼəħaːn"
  },
  {
    "word": "шъхьадэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go over",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэдэхьэн"
    ],
    "type": "verb",
    "ipa": "ʂħaːdaħaːnn"
  },
  {
    "word": "мэлыхъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shepherd's companion",
        "examples": []
      }
    ],

    "cognate": "мэлыхъуэгъу",
    "type": "noun",
    "ipa": "maɮəχʷaʁʷ"
  },
  {
    "word": "пчыт1аркъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "forked spear",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃətʼaːrqʷ"
  },
  {
    "word": "сэшхо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sword",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "saʃxʷa"
  },
  {
    "word": "пелыуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "strongly built man, hero",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pajɮəwaːn"
  },
  {
    "word": "илыягъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "superfluous",
        "examples": []
      },
      {
        "meaning": "excessive",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jəɮəjaːʁ"
  },
  {
    "word": "ешхэ-ешъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "feast, banquet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaʃxa-jaʃʷ"
  },
  {
    "word": "ч1ып1алъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "site",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂʼəpʼaːɬa"
  },
  {
    "word": "шыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peak, peak top",
        "examples": []
      }
    ],

    "cognate": "щыгу",
    "type": "noun",
    "ipa": "ʃəɡʷ"
  },
  {
    "word": "гъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэхьын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʁaħən"
  },
  {
    "word": "шъыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mourning",
        "examples": []
      }
    ],

    "cognate": "щыгъуэ",
    "type": "noun",
    "ipa": "ʂəʁʷa"
  },
  {
    "word": "унашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "attention",
        "examples": []
      },
      {
        "meaning": "order (oral), command",
        "examples": []
      }
    ],

    "cognate": "унафэ",
    "type": "noun",
    "ipa": "wənaːʂʷa"
  },
  {
    "word": "-адж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bad; horrible",
        "examples": []
      },
      {
        "meaning": "dis- (Used as an intensifier of words with negative valence.)",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "aːd͡ʒ"
  },
  {
    "word": "щэдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stack",
        "examples": []
      },
      {
        "meaning": "sun",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕad͡ʒ"
  },
  {
    "word": "-гъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "time; time of",
        "examples": []
      },
      {
        "meaning": "a person in the same kind/group, a person that the same feature or attribute",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "ʁʷ"
  },
  {
    "word": "хьадэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "deathtime, time of death",
        "examples": [
          {
            "sentence": "Л1ыжъым '''ихьэдэгъу''' къэсэгъ",
            "translation": "The old man's '''death time''' has come."
          }
        ]
      }
    ],
    "synonyms": [
      "л1эгъу"
    ],
    "type": "noun",
    "ipa": "ħaːdaʁʷ"
  },
  {
    "word": "нэшхъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "melancholy, sad, sorrowful",
        "examples": []
      }
    ],
    "synonyms": [
      "чэфынчъэ"
    ],
    "type": "noun",
    "ipa": "naʃχaj"
  },
  {
    "word": "чэфынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grieve, sad, sorrowful",
        "examples": []
      }
    ],
    "synonyms": [
      "нэшхъэй"
    ],
    "type": "noun",
    "ipa": "t͡ʃafənt͡ʂa"
  },
  {
    "word": "чэфынчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be sad",
        "examples": []
      }
    ],
    "synonyms": [
      "нэшхъэин",
      "зэгожъын"
    ],
    "type": "verb",
    "ipa": "t͡ʃafənt͡ʂan"
  },
  {
    "word": "ушъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trot, to move at a gait between a walk and a canter.",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəʂən"
  },
  {
    "word": "гумы1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unpleasant",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəməʔʷ"
  },
  {
    "word": "тхьары1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vow, oath, swear in God",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħaːrəʔʷ"
  },
  {
    "word": "гоутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cleave",
        "examples": [
          {
            "sentence": "Чъыгыр шъомбгъуа1ошъ, тIэкIу гоутын фае хъущт'",
            "translation": "The tree is a bit too wide, we need '''to cleave''' it a bit."
          }
        ]
      },
      {
        "meaning": "to tear apart from something",
        "examples": [
          {
            "sentence": "Дзак1ом ы1э топ тафэм и1э '''гуиутыгъ'''",
            "translation": "When a cannot hit the soldier's arm '''it torn it apart [from his body''']."
          },
          {
            "sentence": "Топым л1ым ы1э '''гуиутыгъ'''",
            "translation": "The cannon '''torn apart''' the man's arm ['''from his body''']."
          },
          {
            "sentence": "К1алэм чъыгыр '''гуеуты'''",
            "translation": "The boy '''is tearing apart''' the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷawtən"
  },
  {
    "word": "шъхьак1о ехын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to humiliate, to abase",
        "examples": []
      },
      {
        "meaning": "to offend",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħaːkʷʼa jaxən"
  },
  {
    "word": "шъхьак1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to humiliate",
        "examples": []
      },
      {
        "meaning": "to offend",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħaːkʷʼən"
  },
  {
    "word": "щытхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "praise",
        "examples": []
      },
      {
        "meaning": "brag",
        "examples": []
      }
    ],
    "synonyms": [
      "дауш"
    ],
    "type": "noun",
    "ipa": "ɕətχʷ"
  },
  {
    "word": "шъорыш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be crafty, cunning, to sly",
        "examples": []
      },
      {
        "meaning": "to be foppish, to be dandy",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷarəʃʼən"
  },
  {
    "word": "шъорыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dandy, foppish, a man overly concerned with his appearance.",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʃʷarəʃʼ"
  },
  {
    "word": "шэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "behavior",
        "examples": []
      },
      {
        "meaning": "characteristic",
        "examples": []
      }
    ],

    "cognate": "щэн",
    "type": "noun",
    "ipa": "ʃan"
  },
  {
    "word": "зыпари",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nothing",
        "examples": []
      }
    ],
    "synonyms": [
      "зи"
    ],
    "type": "adv",
    "ipa": "zəpaːrəj"
  },
  {
    "word": "гухахъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "satisfaction",
        "examples": []
      },
      {
        "meaning": "pleasure",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəxaːχʷa"
  },
  {
    "word": "ечъэжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run away",
        "examples": []
      },
      {
        "meaning": "to leave quickly, to speed off",
        "examples": []
      },
      {
        "meaning": "to start running, to break into a run",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jat͡ʂaʑan"
  },
  {
    "word": "мытэрэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrong, incorrect",
        "examples": []
      }
    ],
    "synonyms": [
      "пхэндж",
      "тэрэзыдж"
    ],
    "type": "adj",
    "ipa": "mətaraz"
  },
  {
    "word": "тэрэзыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wrong, incorrect",
        "examples": []
      }
    ],
    "synonyms": [
      "мытэрэз",
      "пхэндж"
    ],
    "type": "adj",
    "ipa": "tarazəd͡ʒ"
  },
  {
    "word": "т1ыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1ыргъон\"",
        "examples": []
      }
    ],
    "synonyms": [
      "т1ыргъон"
    ],
    "type": "verb",
    "ipa": "tʼəʁʷan"
  },
  {
    "word": "тепсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to light, to light up, to illuminate",
        "examples": [
          {
            "sentence": "Тыгъэр чIыгум '''тепсэ'''",
            "translation": "the sun '''illuminates''' the earth."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajpsan"
  },
  {
    "word": "чэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shroud (dress for the dead)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃafən"
  },
  {
    "word": "щэгъэпц1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "prostokvasha, (thick) soured milk, sour clotted milk, amasi (sour milk)",
        "examples": []
      }
    ],
    "synonyms": [
      "щэгъэпс1агъэ"
    ],
    "type": "noun",
    "ipa": "ɕaʁapt͡sʼaːʁa"
  },
  {
    "word": "теуц1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scribble on, To write or draw carelessly on",
        "examples": [
          {
            "sentence": "К1алэ дысэр дэпкъым '''тэуц1алэ'''",
            "translation": "The naughty boy '''is scribbling''' on the wall."
          },
          {
            "sentence": "Тхылъып1эм '''тэуц1ал'''",
            "translation": "'''Scribble''' on the paper."
          },
          {
            "sentence": "Тхылъыым '''утэмыуц1ал'''",
            "translation": "'''Don't scribble''' on the book."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tawt͡sʼalan"
  },
  {
    "word": "уц1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scribble, To write or draw carelessly",
        "examples": [
          {
            "sentence": "К1алэр '''мэуц1алэ'''",
            "translation": "The boy '''is scribbling'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wət͡sʼalan"
  },
  {
    "word": "к1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to leave / go away / walk away / move away",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼən"
  },
  {
    "word": "1орыш1эджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disobey",
        "examples": [
          {
            "sentence": "К1алэр иянэ '''е1орыш1аджэ'''",
            "translation": "The boy '''disobeys''' his mother."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷarʃʼad͡ʒan"
  },
  {
    "word": "го1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "attractive",
        "examples": [
          {
            "sentence": "Пшъэшъэ '''го1у'''",
            "translation": "'''attractive''' girl."
          }
        ]
      },
      {
        "meaning": "pleasant, nice, cute",
        "examples": [
          {
            "sentence": "Ц1ыф '''го1у'''",
            "translation": "'''pleasant''' person."
          }
        ]
      }
    ],
    "synonyms": [
      "гохьы"
    ],
    "type": "adj",
    "ipa": "ɡʷaħə"
  },
  {
    "word": "лъэгъупхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beautiful (sight, view, visually)",
        "examples": []
      },
      {
        "meaning": "visually outstanding",
        "examples": [
          {
            "sentence": "Боу шы '''лъэгъупхъ'''",
            "translation": "Such an '''outstanding''' horse."
          },
          {
            "sentence": "Боу шыу '''лъэгъупхъэ'''",
            "translation": "Such an '''outstanding''' rider."
          }
        ]
      },
      {
        "meaning": "stately",
        "examples": [
          {
            "sentence": "Л1ы '''лъэгъупхъэ'''",
            "translation": "'''stately''' man."
          }
        ]
      }
    ],
    "type": "adj",
    "ipa": "ɬaʁʷəpχ"
  },
  {
    "word": "теплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "appearance, one's look, the look of someone or something, the way something or someone looks",
        "examples": [
          {
            "sentence": "К1алэ зэгъэфагъэм '''итаплъэ''' дахэ",
            "translation": "The arranged boy '''looks''' beautiful."
          },
          {
            "sentence": "К1алэм '''итаплъэк1э''' къэпш1эжьыт",
            "translation": "You will recognize the boy '''by his look'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "taːpɬa"
  },
  {
    "word": "теплъэш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handsome",
        "examples": []
      },
      {
        "meaning": "good-looking",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tajpɬaʃʷʼ"
  },
  {
    "word": "-дж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bad; horrible",
        "examples": []
      },
      {
        "meaning": "dis- (Used as an intensifier of words with negative valence.)",
        "examples": []
      }
    ],
    "type": "suffix",
    "ipa": "d͡ʒ"
  },
  {
    "word": "псынжъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mud",
        "examples": []
      },
      {
        "meaning": "dirt (stain or spot (on clothes etc))",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psənʐə"
  },
  {
    "word": "лъэшыдж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pregnant",
        "examples": []
      }
    ],
    "synonyms": [
      "гъумы",
      "лъэшыдж"
    ],
    "type": "adj",
    "ipa": "ɬaʃəd͡ʒ"
  },
  {
    "word": "шъуаджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ill-starred, ill-fated",
        "examples": []
      },
      {
        "meaning": "leprous",
        "examples": []
      }
    ],
    "synonyms": [
      "сэраджэ"
    ],
    "type": "adj",
    "ipa": "ʃʷaːd͡ʒa"
  },
  {
    "word": "сэраджэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "leprous",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуаджэ"
    ],
    "type": "adj",
    "ipa": "saraːd͡ʒa"
  },
  {
    "word": "чхы1ан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "blanket",
        "examples": []
      }
    ],

    "cognate": "шхы1эн",
    "type": "noun",
    "ipa": "t͡ʃxəʔaːn"
  },
  {
    "word": "шапхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "example",
        "examples": [
          {
            "sentence": "'''Шапхъэ''' техын",
            "translation": "To take '''an example''' from someone."
          },
          {
            "sentence": "'''Шапхъэ''' егъэлъэгъун",
            "translation": "To show '''an example'''."
          },
          {
            "sentence": "'''Шапхъэ''' къэхьын",
            "translation": "To bring '''an example'''."
          }
        ]
      }
    ],
    "synonyms": [
      "щысэ"
    ],
    "cognate": "щапхъэ",
    "type": "noun",
    "ipa": "ʃaːpχa"
  },
  {
    "word": "щыуан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kazan (a type of cauldron or cooking pot used in Central Asian cuisine)",
        "examples": []
      },
      {
        "meaning": "pot, cooker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəwaːn"
  },
  {
    "word": "чэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wattle fence",
        "examples": []
      },
      {
        "meaning": "farmstead",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaw"
  },
  {
    "word": "нэз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "edge",
        "examples": [
          {
            "sentence": "'''Нэзым''' 1ук1",
            "translation": "Go away from the '''edge'''."
          },
          {
            "sentence": "Щыуан '''нэзым''' естылIагъ",
            "translation": "It stuck (from burning) on '''edge''' of the pot."
          },
          {
            "sentence": "Чэу '''нэзым''' дэжь щылъ",
            "translation": "He lies next to the '''edge''' of the wattle fence."
          }
        ]
      }
    ],
    "synonyms": [
      "гъунапкъэ",
      "гъунэ",
      "цак1э",
      "----"
    ],
    "type": "noun",
    "ipa": "nazə"
  },
  {
    "word": "хьасэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bed (for plants), seedbed",
        "examples": [
          {
            "sentence": "бжьын '''хьас'''",
            "translation": "A '''seedbed''' of onions."
          }
        ]
      },
      {
        "meaning": "field",
        "examples": [
          {
            "sentence": "'''хьасэм''' Iоф щышIэн",
            "translation": "To work in the '''field'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaːsa"
  },
  {
    "word": "шхо1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bit (metal in horse's mouth)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxʷaʔʷ"
  },
  {
    "word": "дэхьэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "entrance",
        "examples": [
          {
            "sentence": "К1алэр куэй '''дэхьагъом''' къэспаплъэ",
            "translation": "The boy is waiting for me in the village's '''entrance'''."
          },
          {
            "sentence": "К1алэр '''дэхьагъом''' 1ут",
            "translation": "The boy stand near the '''entrance'''."
          }
        ]
      }
    ],
    "synonyms": [
      "дэхьап1э"
    ],
    "type": "noun",
    "ipa": "daħaːʁʷa"
  },
  {
    "word": "л1ы1о-л1ышъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "masculine appearance",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼəʔʷa-ɬʼəʃʷ"
  },
  {
    "word": "1энэгъунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Kfar Kama dialect) surrounding area (of a village or city)",
        "examples": [],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔanaʁʷəna"
  },
  {
    "word": "зыушъэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to keep silent, to be silent",
        "examples": [
          {
            "sentence": "К1алэм '''зеушъэфы'''",
            "translation": "The boy '''keeps his silent'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zawəʂafən"
  },
  {
    "word": "щымын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shumen (a word that is said to dogs)",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "zawəʂafən"
  },
  {
    "word": "делэ1о-делэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "imbecile, weak-minded, weak-headed",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "dajlaʔʷa-dajlaʃʷ"
  },
  {
    "word": "къэралыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэрал\"",
        "examples": []
      }
    ],

    "cognate": "къэрал",
    "type": "noun",
    "ipa": "qaraːɮəʁʷ"
  },
  {
    "word": "л1ышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "head of; chief",
        "examples": [
          {
            "sentence": "Къэралым илIышъхь",
            "translation": "'''Сhief''' of the state."
          }
        ]
      }
    ],
    "synonyms": [
      "1эшъхьэтет",
      "пащ"
    ],
    "type": "noun",
    "ipa": "ɬʼəʂħa"
  },
  {
    "word": "пащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chief",
        "examples": []
      },
      {
        "meaning": "leader",
        "examples": []
      }
    ],
    "synonyms": [
      "л1ышъхьэ",
      "1эшъхьэтет"
    ],
    "type": "noun",
    "ipa": "paːɕ"
  },
  {
    "word": "бгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wide, broad",
        "examples": []
      }
    ],
    "synonyms": [
      "шъуамбгъо"
    ],
    "type": "adj",
    "ipa": "bʁʷa"
  },
  {
    "word": "шыгъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "red-haired horse; dun horse",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəʁʷa"
  },
  {
    "word": "чэгый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thoroughbred",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃaɣəj"
  },
  {
    "word": "куашъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thigh skin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷʼaːʃʷa"
  },
  {
    "word": "куашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"куашъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "kʷʼaːʃʷa"
  },
  {
    "word": "хьэцэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dog hair",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħat͡sa"
  },
  {
    "word": "лъфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъфэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɬfən"
  },
  {
    "word": "къэлъфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лъфэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaɬfən"
  },
  {
    "word": "къэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bring something",
        "examples": [
          {
            "sentence": "Псы '''къэхь'''",
            "translation": "'''Bring''' water."
          },
          {
            "sentence": "Л1ым ахъщэ '''къэтфехьы'''",
            "translation": "The man '''brings''' us money."
          },
          {
            "sentence": "Сиятэ 1эш1у-1уш1ухэр '''къыхьыгъэх'''",
            "translation": "My father '''brought''' sweets."
          }
        ]
      },
      {
        "meaning": "to give birth, to deliver",
        "examples": [
          {
            "sentence": "Бзылъфыгъэм к1алэ '''къехьы'''",
            "translation": "The woman '''is giving birth to''' a boy."
          }
        ]
      },
      {
        "meaning": "to win the prize",
        "examples": [
          {
            "sentence": "Л1ым '''къихьыгъэ''' ахъщэ пчъагъэр",
            "translation": "The man '''has won''' money."
          }
        ]
      }
    ],
    "synonyms": [
      "лъфэн"
    ],
    "type": "verb",
    "ipa": "qaħən"
  },
  {
    "word": "1аш1у-1уш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1эш1у-1уш1у\"",
        "examples": []
      }
    ],
    "synonyms": [
      "1аш1у 1уш1у"
    ],
    "type": "noun",
    "ipa": "ʔaːʃʷʼə ʔʷəʃʷʼ"
  },
  {
    "word": "1эш1у-1уш1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweet (food)",
        "examples": [
          {
            "sentence": "'''1аш1у-1уш1ухэр'''",
            "translation": "'''sweets'''."
          },
          {
            "sentence": "'''1аш1у-1уш1ухэр''' зыщащэрэм сылъэхъу, торт сщэфыным пае.",
            "translation": "I’m looking for a '''confectionery''' to buy a cake."
          }
        ]
      }
    ],
    "synonyms": [
      "1аш1у 1уш1у"
    ],
    "type": "noun",
    "ipa": "ʔaːʃʷʼə ʔʷəʃʷʼ"
  },
  {
    "word": "егыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гыен\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaɣəjən"
  },
  {
    "word": "къэлъ-гулъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "high quality animal food",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaɬ.ɡʷəɬ"
  },
  {
    "word": "шхомлак1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rein, bridle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxʷamɮaːt͡ʃʼ"
  },
  {
    "word": "к1ырыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tug",
        "examples": [
          {
            "sentence": "Тутарыщыр шхомлак1эм '''къык1ырыуи''', шыр пхэк1ык1э къызэхигъэт1ысхьэу къызэтыригъэуцуагъ.",
            "translation": "Totrash '''tugged''' with the bridle, and he stopped the horse and made it sit down on its back."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼərəwən"
  },
  {
    "word": "къэнэхъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to survive; to live past a life-threatening event",
        "examples": [
          {
            "sentence": "К1алэр '''къэнэхъэжьыгъ'''",
            "translation": "The boy '''survive'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qanaχaʑən"
  },
  {
    "word": "пэгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пэгъуанэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пэгъуанэ"
    ],
    "type": "noun",
    "ipa": "paʁʷaːna"
  },
  {
    "word": "пэбзыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nasal septum, the bone between the two nose-holes",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pabzəd͡ʒən"
  },
  {
    "word": "къэрабгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coward",
        "examples": []
      }
    ],
    "synonyms": [
      "щынапхэ",
      "щтапхэ"
    ],
    "type": "adj",
    "ipa": "qaraːbʁa"
  },
  {
    "word": "шъхьас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mercy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːs"
  },
  {
    "word": "нэхаш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "modesty, humility (the quality of being modest)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naxaːʃ"
  },
  {
    "word": "ук1ытагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shamefulness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wət͡ʃʼətaːʁʷa"
  },
  {
    "word": "зэгъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be pleased",
        "examples": []
      },
      {
        "meaning": "to stop moving after being pleased",
        "examples": [
          {
            "sentence": "Сыд '''узагъэрэба?'''",
            "translation": "'''Aren't you pleased?'''"
          },
          {
            "sentence": "К1алэр пхъэнт1эк1ум '''загъэу''' исэп",
            "translation": "The boy is not sitting '''pleased''' in the chair."
          },
          {
            "sentence": "Шыр '''загъэрэп'''",
            "translation": "The horse '''is not pleased'''"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʁan"
  },
  {
    "word": "ныбжьи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "never (in my life)",
        "examples": [
          {
            "sentence": "'''Ныбжьи''' фэдэ амылъэгъугъэу машинэ ыш1ыгъ",
            "translation": "He built a car that was '''never''' seen before."
          }
        ]
      }
    ],
    "synonyms": [
      "егъаш1и"
    ],
    "type": "adv",
    "ipa": "nəbʑə"
  },
  {
    "word": "егъаш1и",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "never",
        "examples": []
      }
    ],
    "synonyms": [
      "ныбжьи"
    ],
    "type": "adv",
    "ipa": "jaʁaːʃʼəj"
  },
  {
    "word": "к1э1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run away, to make off, to flee",
        "examples": [
          {
            "sentence": "Шым '''к1е1эжьы'''.",
            "translation": "The horse '''runs away'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ежьэжьын",
      "к1э1эн"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʔaʑən"
  },
  {
    "word": "хэпэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to doze off, to get drowsy",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэукъэн"
    ],
    "type": "verb",
    "ipa": "xapazan"
  },
  {
    "word": "шъхьэукъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to doze off, to get drowsy",
        "examples": [
          {
            "sentence": "'''Шъхьэукъэнэу''' ежьагъ.",
            "translation": "He started '''to doze off'''."
          }
        ]
      }
    ],
    "synonyms": [
      "хэпэзэн"
    ],
    "type": "verb",
    "ipa": "ʂħawqan"
  },
  {
    "word": "къэхъушъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэкъушъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэкъушъун"
    ],
    "type": "verb",
    "ipa": "qaχʷəʃʷən"
  },
  {
    "word": "хъушъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къушъу\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χʷəʃʷ"
  },
  {
    "word": "к1эп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(plant) cannabis, hemp",
        "examples": [],
        "tags": [
          "plant"
        ]
      }
    ],

    "cognate": "щ1эп",
    "type": "noun",
    "ipa": "t͡ʃʼap"
  },
  {
    "word": "к1эпщы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horsewhip",
        "examples": []
      }
    ],
    "synonyms": [
      "к1энлъэ",
      "къамыщ"
    ],
    "type": "noun",
    "ipa": "t͡ʃʼapɕə"
  },
  {
    "word": "к1энлъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whip",
        "examples": []
      }
    ],
    "synonyms": [
      "к1эпщы",
      "къамыщ",
      "к1ьэнлъэ"
    ],
    "type": "noun",
    "ipa": "t͡ʃʼanɬa"
  },
  {
    "word": "к1эпщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"к1эпщы\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼapɕ"
  },
  {
    "word": "шъэджэшъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very huge",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂad͡ʒaːʂəʐ"
  },
  {
    "word": "лыгъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to singe, to scorch",
        "examples": [
          {
            "sentence": "Цыр лыгъуагъэ.",
            "translation": "The hair '''is scorched'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɮəʁʷan"
  },
  {
    "word": "тхыон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fear, to get afraid",
        "examples": []
      }
    ],
    "synonyms": [
      "щынэн",
      "щтэн"
    ],
    "type": "verb",
    "ipa": "txəwan"
  },
  {
    "word": "къилъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dash suddenly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəjɬən"
  },
  {
    "word": "илъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lie inside",
        "examples": []
      },
      {
        "meaning": "to stay for the night, to stay overnight",
        "examples": [
          {
            "sentence": "ХьакIэр унэм '''илъын''' фае хъугъэ",
            "translation": "The guest had to '''stay for the night'''."
          },
          {
            "sentence": "Тиунэ непэ '''илъ'''",
            "translation": "'''Stay overnight''' in my house today."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jəɬən"
  },
  {
    "word": "шыбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horse chest",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəbʁa"
  },
  {
    "word": "егъэол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something collide against something else",
        "examples": [
          {
            "sentence": "ЫмышIахэу пхъэр шъхьангъупчъэм '''ригъэуалIи''' апчыр хыригъэутыгъ",
            "translation": "'''He collided''' the wood '''into''' the window by mistake and broke the glass."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁawaɬʼan"
  },
  {
    "word": "ымыш1ахэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "by mistake; mistakenly",
        "examples": [
          {
            "sentence": "'''ЫмышIахэу''' пхъэр шъхьангъупчъэм ригъэуалIи апчыр хыригъэутыгъ",
            "translation": "He collided the wood into the window '''by mistake''' and broke the glass."
          },
          {
            "sentence": "'''Сымыш1ахэу''' сыкъутагъ 1анэр.",
            "translation": "I broke the table '''by mistake'''."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "əməʃʼaːxaw"
  },
  {
    "word": "шъхьэ ш1охын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to decapitate; to behead; to cut someone's head off.",
        "examples": [
          {
            "sentence": "Л1ым '''ышъхьэ ш1уахыгъ'''",
            "translation": "The man '''got decapitated'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃħa ʃʷaxən"
  },
  {
    "word": "гъэутхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make (water) muddy",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэуш1оркъын"
    ],
    "type": "verb",
    "ipa": "ʁawtχʷan"
  },
  {
    "word": "гъэуш1оркъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make (water) muddy",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэутхъон"
    ],
    "type": "verb",
    "ipa": "ʁawʃʷʼarqən"
  },
  {
    "word": "к1этхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shovel out, to rake out, to remove by shoveling or raking",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼatχʷən"
  },
  {
    "word": "хъопсащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "semen",
        "examples": []
      }
    ],
    "synonyms": [
      "лъфащэ",
      "щык1эпс"
    ],
    "type": "noun",
    "ipa": "χʷapsaːɕa"
  },
  {
    "word": "лъфащэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "semen",
        "examples": []
      }
    ],
    "synonyms": [
      "щык1эпс",
      "хъопсащэ"
    ],
    "type": "noun",
    "ipa": "ɬfaːɕa"
  },
  {
    "word": "къэгъэзэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "place of turning; turning zone",
        "examples": [
          {
            "sentence": "'''Къэгъэзэгъум''' ухьэмэ, джабгъом зеты",
            "translation": "When you enter '''the turning zone''', to right."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qaʁazaʁʷ"
  },
  {
    "word": "пк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "value; worth",
        "examples": [
          {
            "sentence": "К1алэм ащ '''пк1э''' фэш1ыгъэн фае",
            "translation": "The boy must [give] '''value''' to it."
          },
          {
            "sentence": "'''Ыпк1э''' ритыгъ",
            "translation": "To give its '''worth'''."
          },
          {
            "sentence": "'''Ыпк1эк1э''' езэгъыгъ",
            "translation": "He was satisfied with its '''worth'''."
          }
        ]
      },
      {
        "meaning": "significance, importance, meaningfulness",
        "examples": [
          {
            "sentence": "Ащ '''пк1э''' и1эп, къик1ын щы1эп",
            "translation": "It has no '''meaning''', it will not produce anything."
          }
        ]
      }
    ],
    "synonyms": [
      "пщ1э"
    ],
    "type": "noun",
    "ipa": "pt͡ʃʼa"
  },
  {
    "word": "хьаулый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "in vain",
        "examples": [
          {
            "sentence": "КIуагъэми '''хьаулый'''",
            "translation": "Even if he went, it '''is in vain'''."
          },
          {
            "sentence": "'''Хьаулыеу''' къин зэригъэлъэгъужьыгъ",
            "translation": "He made himself see hardship '''in vain'''."
          },
          {
            "sentence": "Сыд хэпIухьэми '''хьаулый'''",
            "translation": "Whatever you say about it, it '''is in vain'''."
          }
        ]
      }
    ],
    "synonyms": [
      "бедэхьау",
      "пк1энчъ"
    ],
    "type": "adv",
    "ipa": "ħaːwələj"
  },
  {
    "word": "шхонч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shotgun, musket, rifle",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxʷant͡ʃ"
  },
  {
    "word": "шхончау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shooter",
        "examples": []
      },
      {
        "meaning": "rifleman",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxʷant͡ʃaːw"
  },
  {
    "word": "пчъэшъхьа1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "threshold, doorstep",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʂaʂħaːʔ²"
  },
  {
    "word": "шъхьэк1эфэныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "respect",
        "examples": []
      }
    ],
    "synonyms": [
      "лъытэныгъ",
      "шъхьэк1афэ"
    ],
    "type": "noun",
    "ipa": "ʂħat͡ʃʼafanəʁ"
  },
  {
    "word": "лъытэныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "respect",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэк1афэ",
      "шъхьэк1эфэныгъ"
    ],
    "type": "noun",
    "ipa": "ɬətanəʁ"
  },
  {
    "word": "пк1энчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "idle, wasted, useless",
        "examples": []
      },
      {
        "meaning": "meaningless",
        "examples": []
      }
    ],
    "synonyms": [
      "хьаулый",
      "бедэхьау"
    ],
    "type": "adv",
    "ipa": "pt͡ʃʼant͡ʂ"
  },
  {
    "word": "1орыш1адж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "misbehave",
        "examples": [
          {
            "sentence": "к1элэц1ык1у '''1орыш1адж'''",
            "translation": "'''misbehave''' child."
          }
        ]
      },
      {
        "meaning": "disobedient, a person that does not obey",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔʷarəʃʼaːd͡ʒ"
  },
  {
    "word": "1алы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔaːɮə"
  },
  {
    "word": "зэк1эхьажьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become equal to; be be equalized",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼaħaːʑən"
  },
  {
    "word": "зэфэчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэпэчъэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zafat͡ʂan"
  },
  {
    "word": "зэпэчъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to compete with each other; to contend with each other",
        "examples": []
      },
      {
        "meaning": "to race with each other",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapat͡ʂan"
  },
  {
    "word": "зэпачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "competition",
        "examples": []
      },
      {
        "meaning": "race",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zapaːt͡ʂa"
  },
  {
    "word": "зэфачъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэпачъэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zafaːt͡ʂa"
  },
  {
    "word": "къухьэлъат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airplane",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэбыб",
      "ошъоку",
      "гъуч1ыбзыу"
    ],
    "type": "noun",
    "ipa": "qʷəħaɬaːt"
  },
  {
    "word": "гъуч1ыбзыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airplane",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэбыб",
      "ошъоку",
      "къухьэлъат"
    ],
    "type": "noun",
    "ipa": "ʁʷət͡ʂʼəbzəw"
  },
  {
    "word": "ошъоку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airplane",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэбыб",
      "къухьэлъат",
      "гъуч1ыбзыу"
    ],
    "type": "noun",
    "ipa": "waʃʷakʷ"
  },
  {
    "word": "къухьэбыб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "airplane",
        "examples": []
      }
    ],
    "synonyms": [
      "къухьэлъат",
      "ошъоку",
      "гъуч1ыбзыу"
    ],
    "type": "noun",
    "ipa": "qʷəħabəb"
  },
  {
    "word": "гъэунэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whirl/spin (to the point of dizziness)",
        "examples": [
          {
            "sentence": "щэнаутэу къы1уихырэм шъхьэр '''егъэуназэ'''",
            "translation": "The poison that emits from the area '''spins''' the head '''to dizziness'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁawnazan"
  },
  {
    "word": "тхыкъупшъхь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "backbone, spinal column, vertebral column, spine (set of bones which connected make up the spine and spinal column)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txəqʷəpʂħ"
  },
  {
    "word": "пыгъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break, to fracture",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəʁawən"
  },
  {
    "word": "зэпыгъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to call off (canceling an event or plan)",
        "examples": [
          {
            "sentence": "Нысащэ '''зэпыгъэун'''",
            "translation": "'''To call off''' a wedding."
          },
          {
            "sentence": "зэ1ук1эр '''зэпыгъэун'''",
            "translation": "'''To call off''' the meeting."
          }
        ]
      },
      {
        "meaning": "to interrupt, to break off, to sever, to discontinue, to cut short",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapəʁawən"
  },
  {
    "word": "дэк1оен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go upward",
        "examples": [
          {
            "sentence": "Унашъхьэм '''сыдэк1оени''' топыр къытесхын",
            "translation": "'''I will go up''' the roof and will take off the ball."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dakʷʼajan"
  },
  {
    "word": "дэкон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to forcefully push / cram / stuff something into an object",
        "examples": [
          {
            "sentence": "Джанэхэр дэлъып1эм '''дакох'''",
            "translation": "'''Cram''' the clothes into the closet."
          },
          {
            "sentence": "Сыд фэдиз шхынэу уижэ '''дапкорэр?'''",
            "translation": "How much food do '''you cram''' into your mouth?"
          }
        ]
      },
      {
        "meaning": "to tuck",
        "examples": [
          {
            "sentence": "к1алэм иджанэ игъончэдж '''дикуагъ'''",
            "translation": "The boy '''tucked''' his shirt into his trouser."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dakʷan"
  },
  {
    "word": "пэгъодз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "answer",
        "examples": [
          {
            "sentence": "Тестымк1э '''пэгъодз''' тэрэзэр къэшъугъэк1эхь.",
            "translation": "In the test circle the right '''answer'''."
          }
        ]
      }
    ],
    "synonyms": [
      "джэуап"
    ],
    "type": "noun",
    "ipa": "paʁʷad͡z"
  },
  {
    "word": "мэхъэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be fastidious/squeamish",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "maχaʃan"
  },
  {
    "word": "щымэхъэшэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be fastidious/squeamish (about)",
        "examples": [],
        "tags": [
          "about"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕəmaχaʃan"
  },
  {
    "word": "олъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to agitated",
        "examples": [
          {
            "sentence": "Хыр '''мэуалъэ'''",
            "translation": "The sea '''is agitated'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "waɬan"
  },
  {
    "word": "еджэнджэшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hesitate",
        "examples": [
          {
            "sentence": "К1алэр '''еджэнджэшы'''",
            "translation": "The boy '''is hesitating'''."
          },
          {
            "sentence": "Ар а Iофым '''еджэнджэшын''' фаеп, ащ зи хэлъ щыIэп",
            "translation": "He does not have '''to hesitate''' about that work, it is nothing to worry about."
          }
        ]
      },
      {
        "meaning": "to consult",
        "examples": [
          {
            "sentence": "Ар лIыжъым дэжь '''еджэнджэшынэу''' кIуагъэ",
            "translation": "He went to the old man '''to consult''' with him."
          }
        ]
      }
    ],
    "synonyms": [
      "ечэнджэшын",
      "ехъырхъышэн"
    ],
    "type": "verb",
    "ipa": "jad͡ʒand͡ʒanʃən"
  },
  {
    "word": "ечэнджэшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hesitate",
        "examples": [
          {
            "sentence": "К1алэр '''ечэнджэшы'''",
            "translation": "The boy '''is hesitating'''."
          }
        ]
      }
    ],
    "synonyms": [
      "еджэнджэшын",
      "ехъырхъышэн"
    ],
    "type": "verb",
    "ipa": "jat͡ʃand͡ʒaʃən"
  },
  {
    "word": "упч1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to doubt",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wpt͡ʂʼaʑən"
  },
  {
    "word": "чылэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fellow-villager",
        "examples": []
      },
      {
        "meaning": "fellow-resident",
        "examples": []
      }
    ],
    "synonyms": [
      "къоджэгъу"
    ],
    "type": "noun",
    "ipa": "t͡ʃəɮaʁʷ"
  },
  {
    "word": "къоджэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fellow-villager",
        "examples": []
      }
    ],
    "synonyms": [
      "чылэгъу"
    ],
    "type": "noun",
    "ipa": "t͡ʃəɮaʁʷ"
  },
  {
    "word": "гъугъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dry (plants; food)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʁʷəʁa"
  },
  {
    "word": "нэгъуцу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unfriendly, ungracious, impolite",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naʁʷət͡ʃʷ"
  },
  {
    "word": "фэмыф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lazy",
        "examples": []
      }
    ],
    "synonyms": [
      "армэу"
    ],
    "type": "adj",
    "ipa": "faməf"
  },
  {
    "word": "ерыуадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "idler, loafer, lazybones",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jarəwaːd͡ʒ"
  },
  {
    "word": "къэцы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thorn",
        "examples": []
      }
    ],
    "synonyms": [
      "панэ"
    ],
    "type": "noun",
    "ipa": "qat͡sə"
  },
  {
    "word": "1эпэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къопс\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къопс",
      "1эубытып1э"
    ],
    "type": "noun",
    "ipa": "ʔapaps"
  },
  {
    "word": "1эубытып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "handle",
        "examples": []
      },
      {
        "meaning": "knob",
        "examples": []
      }
    ],
    "synonyms": [
      "1эпэпс",
      "къопс"
    ],
    "type": "noun",
    "ipa": "ʔawbətəpʼa"
  },
  {
    "word": "лъэпс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shoelace",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaps"
  },
  {
    "word": "хэк1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "debris, rubbish, trash, garbage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xat͡ʃʼə"
  },
  {
    "word": "нэпэшъоутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "slap (to the face); slap in the face",
        "examples": [
          {
            "sentence": "'''нэпэшъоутын''' уфая?",
            "translation": "Do you want '''a slap'''?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "naɡʷəʃʷawtən"
  },
  {
    "word": "дыйшъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skin of the back part of the neck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "dəjʃʷa"
  },
  {
    "word": "нэпашъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cheek",
        "examples": []
      }
    ],
    "synonyms": [
      "нэгу"
    ],
    "cognate": "нэк1у",
    "type": "noun",
    "ipa": "napaːʃʷa"
  },
  {
    "word": "къегъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make out something as good",
        "examples": [
          {
            "sentence": "Ц1ыфымэ мы фылымыр '''къырагъэк1ы'''",
            "translation": "People '''make out''' this film '''as good'''."
          }
        ]
      },
      {
        "meaning": "to make someone/something come out from inside something",
        "examples": [
          {
            "sentence": "К1алэм къеджи унэм '''къигъэк1'''",
            "translation": "Call the boy and '''make him come out of''' the house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qajʁat͡ʃʼən"
  },
  {
    "word": "шъхьэпай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "playful",
        "examples": []
      },
      {
        "meaning": "reckless",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħapaːj"
  },
  {
    "word": "шъхьэубат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "nonsense",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħawbaːt"
  },
  {
    "word": "къэкъушъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fart",
        "examples": [
          {
            "sentence": "к1алэр '''къэкъушъугъ'''",
            "translation": "The boy '''farted'''"
          }
        ]
      }
    ],
    "synonyms": [
      "къэкъун"
    ],
    "cognate": "къэцыфын",
    "type": "verb",
    "ipa": "qaqʷəʃʷən"
  },
  {
    "word": "къэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fart",
        "examples": [
          {
            "sentence": "к1алэр '''къэкъогъ'''",
            "translation": "The boy '''farted'''"
          }
        ]
      }
    ],
    "synonyms": [
      "къэкъушъун"
    ],
    "cognate": "къэцыфын",
    "type": "verb",
    "ipa": "qaqʷən"
  },
  {
    "word": "сэнэхьат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "specialty, profession (that in which one specializes)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "sanaħaːt"
  },
  {
    "word": "пцатхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пцантхьэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пцантхьэ"
    ],
    "type": "noun",
    "ipa": "pt͡saːtħa"
  },
  {
    "word": "шъхьэм къиубытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make sense",
        "examples": []
      },
      {
        "meaning": "to comprehend",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħam qəjwbətən"
  },
  {
    "word": "гумы1ан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "restless, fidgety",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəməʔaːn"
  },
  {
    "word": "тегупшысык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to deflect/divert one's attention away from thinking about the important things",
        "examples": []
      },
      {
        "meaning": "to be distracted from thinking about the important things",
        "examples": []
      },
      {
        "meaning": "to underthink, to give insufficient thought or consideration to; fail to consider adequately.",
        "examples": []
      }
    ],
    "synonyms": [
      "тегупсысык1ын"
    ],
    "type": "verb",
    "ipa": "tajɡʷəpʃəsət͡ʃʼən"
  },
  {
    "word": "зегъэуджэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become loathsome / hated",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zajəʁawd͡ʒaʁʷən"
  },
  {
    "word": "итхагъэп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "there is no way (an expression of surprise and irritation)",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "jətxaːʁap"
  },
  {
    "word": "лъэуж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "track",
        "examples": [
          {
            "sentence": "тыгъужъым илъэужхэр",
            "translation": "wolf tracks"
          }
        ]
      },
      {
        "meaning": "footprint, footstep",
        "examples": []
      }
    ],
    "synonyms": [
      "ужы"
    ],
    "type": "verb",
    "ipa": "ɬawəʒ"
  },
  {
    "word": "щэмбар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bamboo",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "лъэк1оц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the inside of a human (insides, guts, intestines, innards)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬakʷʼat͡sʼ"
  },
  {
    "word": "итэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour out, to spill out (of bulk material, objects, or pieces, not liquid)",
        "examples": [
          {
            "sentence": "коцыр дзыом '''итэкъун'''",
            "translation": "To '''pour''' wheat '''out of''' the bag"
          }
        ],
        "tags": [
          "of bulk material, objects, or pieces, not liquid"
        ]
      }
    ],
    "type": "verb",
    "ipa": "jətaqʷən"
  },
  {
    "word": "хьамбар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "barn",
        "examples": []
      }
    ],
    "type": "noun"
  },
  {
    "word": "ипхъыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to strew / scatter (over an area), to distribute objects or pieces of something over an area (especially in a random manner)",
        "examples": []
      }
    ],
    "synonyms": [
      "итэкъохьын"
    ],
    "type": "verb",
    "ipa": "jəpχəħaːn"
  },
  {
    "word": "уц1ынын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get wet/soaked, to be soaking, to be sopping wet",
        "examples": [
          {
            "sentence": "щыгъыныр '''уцIыныгъэ'''",
            "translation": "'''To wet''' clothes"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wət͡sʼənən"
  },
  {
    "word": "ныкъо сыкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "half efficient",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "nəqʷa səqʷa"
  },
  {
    "word": "ныкъо сыкъоу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hardly",
        "examples": []
      },
      {
        "meaning": "half efficiently",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "nəqʷa səqʷaw"
  },
  {
    "word": "фэгъэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to point / direct something toward",
        "examples": []
      },
      {
        "meaning": "to turn something toward",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "faʁazan"
  },
  {
    "word": "ащ къыдак1оу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "moreover",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "aːɕ qədaːkʷʼaw"
  },
  {
    "word": "апхъурэлъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firstborn",
        "examples": []
      },
      {
        "meaning": "firstling",
        "examples": []
      }
    ],
    "synonyms": [
      "щыпэлъф"
    ],
    "type": "noun",
    "ipa": "aːpχʷəraɬf"
  },
  {
    "word": "япхъурэлъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firstborn",
        "examples": []
      },
      {
        "meaning": "firstling",
        "examples": []
      }
    ],
    "synonyms": [
      "щыпэлъф"
    ],
    "type": "noun",
    "ipa": "aːpχʷəraɬf"
  },
  {
    "word": "щыпэлъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "firstborn",
        "examples": []
      },
      {
        "meaning": "firstling",
        "examples": []
      }
    ],
    "synonyms": [
      "япхъурэлъф"
    ],
    "type": "noun",
    "ipa": "ɕəpaɬf"
  },
  {
    "word": "ц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) crap, shit",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡sʼə"
  },
  {
    "word": "техьагъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "malaria",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tajħaːʁʷ"
  },
  {
    "word": "чъы1ал1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cold (fever)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂəʔaːɬʼa"
  },
  {
    "word": "хъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "net",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χə"
  },
  {
    "word": "к1энэк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ridicule, to mock",
        "examples": []
      },
      {
        "meaning": "to make a little joke, to poke fun",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼanat͡ʃʼan"
  },
  {
    "word": "пыгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to infect someone else",
        "examples": [
          {
            "sentence": "К1элэ сымаджэм шъуемыбылагъ, узы '''къышъупигъэхьащт'''",
            "translation": "Don't approach the sick boy, he will infect you with an illness."
          }
        ]
      },
      {
        "meaning": "to connect someone with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəʁaħaːn"
  },
  {
    "word": "пшъэб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "back of the neck",
        "examples": []
      }
    ],
    "synonyms": [
      "дый"
    ],
    "type": "noun",
    "ipa": "pʂab"
  },
  {
    "word": "егъэуагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(slang) stoned, high on drugs, especially cannabis (weed)",
        "examples": [],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "adj",
    "ipa": "jaʁawaːʁ"
  },
  {
    "word": "оркъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noble, nobleman (a man of noble rank)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "warq"
  },
  {
    "word": "л1экъолъэш",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "noble clan",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaqʷaɬaʃ"
  },
  {
    "word": "л1акъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clan",
        "examples": []
      },
      {
        "meaning": "kind",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼaːqʷa"
  },
  {
    "word": "зешъок1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to consume a lot of liquid",
        "examples": []
      },
      {
        "meaning": "to get wasted; to get drunk",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zajʃʷat͡ʃʼəʑən"
  },
  {
    "word": "шхэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be full (after eating)",
        "examples": []
      },
      {
        "meaning": "to be saturated with food",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃxat͡ʃʼən"
  },
  {
    "word": "зыгъэшхэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to eat until one gets full; to eat plenty (of)",
        "examples": []
      },
      {
        "meaning": "to saturate oneself with food",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəʁaʃxat͡ʃʼən"
  },
  {
    "word": "ешъук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be full (after drinking)",
        "examples": []
      },
      {
        "meaning": "to be saturated with liquid",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʃʷət͡ʃʼən"
  },
  {
    "word": "зегъэшъук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drink until one gets full; to drink plenty (of)",
        "examples": []
      },
      {
        "meaning": "to saturate oneself with liquid",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zajʁaʃʷət͡ʃʼən"
  },
  {
    "word": "у1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wound",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wəʔaːʁa"
  },
  {
    "word": "чэмэдан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "suitcase, trunk",
        "examples": [
          {
            "sentence": "IапIэхэр чэмэданым дэлъхьаных",
            "translation": "To put things in a suitcase."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃamadaːn"
  },
  {
    "word": "пачъыхьагъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kingdom, realm",
        "examples": []
      },
      {
        "meaning": "reign",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːt͡ʂəħaːʁʷa"
  },
  {
    "word": "зэл1-зэшъуз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "married couple",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zaɬʼ zaʃʷəz"
  },
  {
    "word": "щэо-пл1эон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stagger, to reel, to sway on one's feet",
        "examples": []
      },
      {
        "meaning": "to rock (from time to time)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕawa pɬʼawan"
  },
  {
    "word": "нэпц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "false (untrue, not factual, wrong)",
        "examples": [
          {
            "sentence": "къэбар нэпцI",
            "translation": "False news."
          }
        ]
      },
      {
        "meaning": "fallacious (deceptive or misleading, mistaken)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "napt͡sʼə"
  },
  {
    "word": "къэбарыпц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "false news",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabaːrəpt͡sʼə"
  },
  {
    "word": "къэбар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "news, piece of news",
        "examples": []
      },
      {
        "meaning": "tidings",
        "examples": []
      },
      {
        "meaning": "narrative; story",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qabaːr"
  },
  {
    "word": "хъэбар",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэбар\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "χabaːr"
  },
  {
    "word": "гущы1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "interlocutor, talking partner",
        "examples": [
          {
            "sentence": "Ар боу гущыIэкIэ Iазэ, о ащ гущыIэгъу уфэхъущтэп.",
            "translation": "That one is good in speech, you won't be able to be his talking partner."
          },
          {
            "sentence": "Ар ащ гущыIэгъу фэхъугъэти гукъаоу иIэхэр къыриIуагъ.",
            "translation": "Because he became his talking buddy, he told him the misfortunes he have."
          }
        ]
      },
      {
        "meaning": "time for conversation; time for talk",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəɕəʔaʁʷ"
  },
  {
    "word": "тхэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to book; to fine (police)",
        "examples": [
          {
            "sentence": "полицием '''утхэхьыщт''' машинэ псынк1эу уфымэ",
            "translation": "The police '''will book you''' if you drive the car fast."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "txaħən"
  },
  {
    "word": "1офытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to send someone to bring something",
        "examples": [
          {
            "sentence": "Гъазет къыхьынэу шъэожъыер '''Iофытэн'''",
            "translation": "'''To send''' a kid to bring a newspaper."
          },
          {
            "sentence": "Сиянэ тучаным '''си1офытагъ''' сэлат къэсхьынэу",
            "translation": "My mother '''send me''' to the shop to bring salad."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷafətan"
  },
  {
    "word": "фэ1офытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go for someone to bring something",
        "examples": [
          {
            "sentence": "Сиянэ тучаным '''сыфэ1офытагъ''' сэлат къэсхьынэу",
            "translation": "'''I went''' to the shop for my mother to bring salad."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʔʷafətan"
  },
  {
    "word": "шык1эпшынэ къыфеон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to play Shkapshina for someone",
        "examples": []
      },
      {
        "meaning": "(slang) to complain to someone repeatedly (to the point of annoyance)",
        "examples": [
          {
            "sentence": "Шъузыр '''шык1эпшынэ къэсфео'''",
            "translation": "The woman '''is complaining to me'''."
          }
        ],
        "tags": [
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃət͡ʃʼapʃəna fajwan"
  },
  {
    "word": "гъэкъэбзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to clean",
        "examples": [
          {
            "sentence": "К1алэм унэр '''егъэкъабзэ'''",
            "translation": "The boy is '''cleaning''' the house."
          },
          {
            "sentence": "Унэр сэ '''сэгъэкъабзэ'''",
            "translation": "'''I am cleaning''' the house."
          },
          {
            "sentence": "'''Гъэкъэбз''' унэр",
            "translation": "'''Clean''' the house."
          }
        ]
      }
    ],
    "synonyms": [
      "укъэбзын"
    ],
    "type": "verb",
    "ipa": "ʁaqabzan"
  },
  {
    "word": "чэмы1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "byre (for cows)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaməʔʷa"
  },
  {
    "word": "хьасэтх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "borders of a field",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːsatx"
  },
  {
    "word": "ч1ыбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pergola, arbour, arcade, bower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːsatx"
  },
  {
    "word": "бгъашъухъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"бгъашхъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "бгъашхъо"
    ],
    "type": "noun",
    "ipa": "bʁaːʃʷχʷa"
  },
  {
    "word": "гузэжъопх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "impatient",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷzaʒʷapx"
  },
  {
    "word": "ш1уабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hurry, hasten",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʔaːba"
  },
  {
    "word": "ыш1уабэ мэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hurry, to be in a hurry, to hasten",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "əʃʷʔaːba maʃʼan"
  },
  {
    "word": "шъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to weave",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂan"
  },
  {
    "word": "хыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хыкъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "псыкъу"
    ],
    "cognate": "хыкхъуэ",
    "type": "noun",
    "ipa": "xəqʷə"
  },
  {
    "word": "к1эрак1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "well-dressed (of a person)",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡ʃʼaraːt͡ʃʼ"
  },
  {
    "word": "ушъорэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to overturn, to tip over, to capsize",
        "examples": []
      }
    ],
    "synonyms": [
      "еук1орэик1ын"
    ],
    "type": "verb",
    "ipa": "wəʃʷarat͡ʃʼən"
  },
  {
    "word": "еук1орэик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to overturn, to tip over, to capsize",
        "examples": []
      }
    ],
    "synonyms": [
      "ушъорэк1ын"
    ],
    "type": "verb",
    "ipa": "jawkʷʼarajət͡ʃʼən"
  },
  {
    "word": "ыпэ ишъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to surpass; to overtake",
        "examples": []
      },
      {
        "meaning": "to outrun, to outdistance",
        "examples": []
      }
    ],
    "synonyms": [
      "течъын",
      "к1ок1э тек1ын"
    ],
    "type": "verb",
    "ipa": "jəpa jəʂən"
  },
  {
    "word": "к1ок1э тек1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to outrun, to outdistance",
        "examples": []
      }
    ],
    "synonyms": [
      "течъын",
      "ыпэ ишъын"
    ],
    "type": "verb",
    "ipa": "kʷʼat͡ʃʼa tajt͡ʃʼən"
  },
  {
    "word": "хыкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dolphin",
        "examples": []
      }
    ],
    "synonyms": [
      "псыкъу"
    ],
    "cognate": "хыкхъуэ",
    "type": "noun",
    "ipa": "xəqʷa"
  },
  {
    "word": "ехъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "addition",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jaχʷ"
  },
  {
    "word": "1ушъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shore, coast, seaboard, littoral",
        "examples": []
      }
    ],
    "synonyms": [
      "хы1ушъо",
      "псы1ушъо"
    ],
    "type": "noun",
    "ipa": "ʔʷəʃʷa"
  },
  {
    "word": "хы1ушъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beach",
        "examples": []
      },
      {
        "meaning": "coast",
        "examples": []
      },
      {
        "meaning": "seaboard",
        "examples": []
      }
    ],
    "synonyms": [
      "1ушъо",
      "псы1ушъо",
      "хыгъунэ"
    ],
    "type": "noun",
    "ipa": "xəʔʷəʃʷa"
  },
  {
    "word": "хэгъэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dip",
        "examples": []
      },
      {
        "meaning": "to quench",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʁawan"
  },
  {
    "word": "къожъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boar",
        "examples": []
      },
      {
        "meaning": "warthog",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʐ"
  },
  {
    "word": "цыхьэмыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "distrustful, incredulous, mistrustful, suspicious",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡sʼəħaməʃʼ"
  },
  {
    "word": "нэбгыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "person (used when the amount of people in a sentence is important)",
        "examples": [
          {
            "sentence": "'''нэбгыр''' мак1о",
            "translation": "'''The person''' is going."
          },
          {
            "sentence": "машинэм '''нэбгищ''' ифэтэ",
            "translation": "Only '''three people''' will fit into the car."
          },
          {
            "sentence": "'''нэбгит1уу''' мэзым хэхьагъэх",
            "translation": "They entered the forest '''while they were two people'''."
          },
          {
            "sentence": "'''зынэбгым''' фэштэтэп анэ инэр",
            "translation": "'''One person''' can't lift the big table."
          },
          {
            "sentence": "хым хэхьэтых '''нэбгипш1ыу'''",
            "translation": "They gonna enter the sea '''while they are ten people'''."
          },
          {
            "sentence": "'''нэбгит1у''' тищк1агъэ",
            "translation": "We need '''two people'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "nabɣər"
  },
  {
    "word": "пхъурылъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "grandchildren (on the line of the daughter); one's daughter's children",
        "examples": []
      }
    ],

    "cognate": "пхъурылъху",
    "type": "noun",
    "ipa": "pχərəɬf"
  },
  {
    "word": "1унк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to push one another, to jostle",
        "examples": [
          {
            "sentence": "К1алэр '''е1унк1э''' 1анэм",
            "translation": "The boy '''pushes''' the table."
          },
          {
            "sentence": "Чъыгым '''къэсде1ук1'''",
            "translation": "'''Push''' the tree '''together with me'''."
          },
          {
            "sentence": "К1алэр Томым '''е1унк1агъ'''",
            "translation": "The boy '''pushed''' Tom."
          },
          {
            "sentence": "'''Сегъэ1унк1'''",
            "translation": "'''Let me push'''."
          },
          {
            "sentence": "К1алэм '''сыригъэ1унк1эрэп'''",
            "translation": "The boy '''doesn't let me push'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гук1эн",
      "е1унк1ын",
      "т1ыргун"
    ],
    "type": "verb",
    "ipa": "ʔʷənt͡ʃʼan"
  },
  {
    "word": "е1унк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1унк1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гук1эн",
      "1унк1эн",
      "т1ыргун"
    ],
    "type": "verb",
    "ipa": "jaʔʷənt͡ʃʼən"
  },
  {
    "word": "т1ыргун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to push, to shove, to thrust",
        "examples": []
      }
    ],
    "synonyms": [
      "е1унк1ын"
    ],
    "type": "verb",
    "ipa": "tʼərɡʷən"
  },
  {
    "word": "хьапк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gallop",
        "examples": []
      },
      {
        "meaning": "galop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːpt͡ʃʼ"
  },
  {
    "word": "къэлыдын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"лыдын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaɮədən"
  },
  {
    "word": "ц1ыотэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shine rapidly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼəwatan"
  },
  {
    "word": "пк1этэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to jump rapidly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pt͡ʃʼatan"
  },
  {
    "word": "нэбзый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ray, beam",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nabzəj"
  },
  {
    "word": "къуащэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crookedly",
        "examples": []
      },
      {
        "meaning": "awry",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "qʷaːɕaw"
  },
  {
    "word": "лъэныкъуащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "crooked, curved, wry, bent",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɬanəqʷaːɕ"
  },
  {
    "word": "шъхьанд",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bushes, shrubs, shrubbery",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːnd"
  },
  {
    "word": "псычэтыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drake, male duck",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psət͡ʃatəχʷ"
  },
  {
    "word": "тегъэпытахьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fix something forcefully upon",
        "examples": [
          {
            "sentence": "Гъуч1ы1унэ пхъэм к1алэм дэгъоу '''тегъэпытахьагъ'''",
            "translation": "The boy '''fixed''' the nail good on the wood."
          }
        ]
      },
      {
        "meaning": "to be strict",
        "examples": [
          {
            "sentence": "Сиянэ нэмазэхэм '''атегъэпытахьы'''",
            "translation": "My father '''is strict''' with the prayers."
          },
          {
            "sentence": "К1алэм хабзэм '''тегъэпытахьы'''",
            "translation": "The boy '''is strict''' with the customs."
          }
        ]
      },
      {
        "meaning": "to be punctilious",
        "examples": [
          {
            "sentence": "Чъые уахътэм '''тебгъэпытахь''' фай, неущы тэрэзэу укъэущынэу",
            "translation": "You need to be punctilious about your sleeping time, so you will wake up correctly tomorrow."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁapətaːħaːn"
  },
  {
    "word": "тегъэпсыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to aim at something",
        "examples": [
          {
            "sentence": "К1эрахъомк1э бланэм '''тегъэпысыхьан'''",
            "translation": "'''To aim''' on the deer with the gun."
          }
        ]
      },
      {
        "meaning": "to point at something",
        "examples": []
      },
      {
        "meaning": "to fix a clock",
        "examples": []
      },
      {
        "meaning": "to set (machines)",
        "examples": [
          {
            "sentence": "микроволновыйыр щэк1ым '''тегъэпысыхьан'''",
            "translation": "'''To set''' the microwave on 30."
          },
          {
            "sentence": "Къубылыр '''тегъэпысыхьан'''",
            "translation": "'''To set''' the bomb."
          }
        ]
      },
      {
        "meaning": "to plan on; to anticipate future actions based on",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajʁapsəħaːn"
  },
  {
    "word": "тедэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to patch; to mend by sewing on a piece or pieces of cloth, leather, or the like",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajdaʑən"
  },
  {
    "word": "тепхэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bandage",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tajpxa"
  },
  {
    "word": "тегъэпк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to glue on; to stick on",
        "examples": [
          {
            "sentence": "Маркэр конвертым '''тегъэпкIэн'''",
            "translation": "'''To stick''' the stamp on the envelope."
          }
        ]
      },
      {
        "meaning": "to patch",
        "examples": [
          {
            "sentence": "УIагъэм тепхэ '''тегъэпк1эн'''",
            "translation": "'''To patch''' a bandage upon a wound."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajəapt͡ʃʼan"
  },
  {
    "word": "тегъэтк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip on",
        "examples": [
          {
            "sentence": "Ешъоныр 1анэм '''тебгъэтк1о'''",
            "translation": "You're '''dripping''' the drink on the table."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁatkʷʼan"
  },
  {
    "word": "мэстэ гъуанэм ригъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to attempt to do something as perfect as possible",
        "examples": []
      }
    ],
    "type": "phrase",
    "ipa": "masta ʁʷaːnam rəjʁat͡ʃʼən"
  },
  {
    "word": "фэкъол1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "free peasant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "faqʷaɬʼ"
  },
  {
    "word": "гупч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "middle (centre, midpoint)",
        "examples": []
      }
    ],
    "synonyms": [
      "гузэгу",
      "пчэгу",
      "утыку"
    ],
    "type": "noun",
    "ipa": "ɡʷəpt͡ʃ"
  },
  {
    "word": "хьатыяк1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "festival manager",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːtəjaːkʷʼa"
  },
  {
    "word": "унэшъош1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "administrator, manager, steward (one who administers affairs)",
        "examples": []
      },
      {
        "meaning": "master of ceremonies, host",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wnaʃʷaʃʼə"
  },
  {
    "word": "1ахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "share; part; portion",
        "examples": [
          {
            "sentence": "Л1ым '''и1ахьэ''' ратыжьыгъ",
            "translation": "They gave the man '''his part of the portion'''."
          },
          {
            "sentence": "Л1ым '''1ахьэу''' хилъыхьагъэр бэ",
            "translation": "The man's '''contribution''' is a lot."
          },
          {
            "sentence": "Унагъом мэл заукIым, ягъунэгъу лIыжъым '''Iахьэ''' фарагъэхьыгъ",
            "translation": "When the family killed a sheep, they gave their neighbor man '''his share'''."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔaːħ"
  },
  {
    "word": "гущы1ал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "talkative, a person that tend to talk a lot",
        "examples": []
      },
      {
        "meaning": "loquacious",
        "examples": []
      }
    ],
    "synonyms": [
      "1оржъор",
      "гущы1эрые",
      "жэмы1ан",
      "жэмачыу",
      "шъхьарыгущы1"
    ],
    "type": "adj",
    "ipa": "ɡʷəɕəʔaːl"
  },
  {
    "word": "зыкъэш1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to recognize themselves",
        "examples": []
      },
      {
        "meaning": "to come to one's senses",
        "examples": [
          {
            "sentence": "Ц1ыфым '''зыкъиш1эжьыгъ'''",
            "translation": "The person '''come to one's senses'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəqaʃʼaʑən"
  },
  {
    "word": "гъэт1эп1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to unstitch, to tear at the seams",
        "examples": []
      },
      {
        "meaning": "to rip open (skin, a body part, etc.) with a sharp object",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁatʼapʼən"
  },
  {
    "word": "губзыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clever",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɡʷəbzəʁ"
  },
  {
    "word": "гык1алъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "laundry (machine)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɣət͡ʃʼaːɬa"
  },
  {
    "word": "шыхьат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "witness",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃəħaːt"
  },
  {
    "word": "шыхьатэу утеуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шыхьатэу теуцон\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃəħaːtaw wətajwət͡ʃʷan"
  },
  {
    "word": "нэш1у шыфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bless",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "naʃʷʼ ʃəfan"
  },
  {
    "word": "фэрэзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be pleased with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "farazan"
  },
  {
    "word": "к1э1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run away, to flee",
        "examples": [
          {
            "sentence": "Шым '''к1и1агъ'''.",
            "translation": "The horse '''fled'''."
          },
          {
            "sentence": "КIалэм хьэр зелъэгъум '''к1и1агъ'''.",
            "translation": "When the boy saw the dog, '''he fled'''."
          }
        ]
      }
    ],
    "synonyms": [
      "ежьэжьын",
      "к1э1эжьын"
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʔan"
  },
  {
    "word": "к1эк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour in",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼat͡ʼan"
  },
  {
    "word": "1ахьыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "relative (someone in the same family; someone connected by blood, marriage, or adoption)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːħəɮ"
  },
  {
    "word": "зэш1охын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to resolve tasks",
        "examples": [
          {
            "sentence": "Л1ым 1офыр псынк1эу '''хэш1уихыгъ'''",
            "translation": "The man '''resolved''' the work quickly."
          }
        ]
      },
      {
        "meaning": "to cope (with); to deal effectively with something, especially if difficult",
        "examples": []
      },
      {
        "meaning": "to dismantle stuff from each other",
        "examples": [
          {
            "sentence": "Л1ым к1апсэхэр '''хэш1уихыгъэх'''",
            "translation": "The man '''dismantled''' the ropes."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaʃʷʼaxən"
  },
  {
    "word": "зэпэк1эк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэпэч1эк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapat͡ʃʼat͡ʃʼən"
  },
  {
    "word": "ч1эгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to let out, to let go, to release, to set free",
        "examples": [
          {
            "sentence": "Л1ыр хьапсым '''къыч1агъэк1ыжьыгъ'''",
            "translation": "They '''set''' the math '''free''' from prison."
          }
        ]
      },
      {
        "meaning": "to let someone get out from under something",
        "examples": []
      },
      {
        "meaning": "to solve",
        "examples": [
          {
            "sentence": "К1алэм матэматикэ упч1ыр '''къык1игъэк1ыжьыгъ'''",
            "translation": "The boy '''solved''' the math question."
          }
        ]
      },
      {
        "meaning": "to let someone raise up",
        "examples": []
      },
      {
        "meaning": "to raise something upward; to elevate someone",
        "examples": []
      },
      {
        "meaning": "to invent something",
        "examples": [
          {
            "sentence": "Машинэ к1эу '''къыч1агъэк1ыгъэр''' плъэгъугъа?",
            "translation": "Did you see the new car '''they invented'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂʼaʁat͡ʃʼən"
  },
  {
    "word": "зэпэч1эк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be resolved",
        "examples": [
          {
            "sentence": "Л1ым и1офыхэр '''зэпэк1эк1ыгъэх'''",
            "translation": "The man's tasks were '''resolved'''."
          },
          {
            "sentence": "Аужырэм '''къэсфэпэк1эк1ыгъ'''",
            "translation": "In the end, it got '''resolved''' for me."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zapat͡ʂʼat͡ʃʼən"
  },
  {
    "word": "к1эгъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ч1эгъэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʁat͡ʃʼən"
  },
  {
    "word": "къэугупшысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make up, to think out, to devise, to contrive, to invent",
        "examples": [
          {
            "sentence": "джэгукIакIэ '''къэугупшысын'''",
            "translation": "come up with a new game."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qawɡʷəpʃəsən"
  },
  {
    "word": "гопэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stuffily (in a poorly-ventilated and close manner)",
        "examples": []
      },
      {
        "meaning": "sultriness, closeness, stuffiness, sultry / stuffy air",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷapaʁʷ"
  },
  {
    "word": "блэк1ыгъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "too, too much",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "bɮat͡ʃʼəʁaw"
  },
  {
    "word": "дэублэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to start with someone",
        "examples": [
          {
            "sentence": "Синныбджэгъу фылымыр '''дэсыублагъ'''",
            "translation": "'''I started''' the film '''with''' my friend."
          }
        ]
      },
      {
        "meaning": "to hit up; to flirt with; to approach and speak to (someone), seeking romance, love, sex, etc.",
        "examples": [
          {
            "sentence": "К1алэм '''къыдеублэ''' пшъашъэм",
            "translation": "The boy ''' is hitting up''' the girl."
          },
          {
            "sentence": "К1алэм '''къыздиублэн''' е1о",
            "translation": "The boy attempts '''to hit me up'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "dawəbɮan"
  },
  {
    "word": "лъэхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to clog",
        "examples": []
      },
      {
        "meaning": "to hobble",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɬaχan"
  },
  {
    "word": "къырик1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come on something; to come via",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qərəjkʷʼan"
  },
  {
    "word": "фэулэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to do/render a service, to do a good turn",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "fawɮawən"
  },
  {
    "word": "улэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get tired",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъын"
    ],
    "type": "verb",
    "ipa": "wɮawən"
  },
  {
    "word": "пшъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get tired, to get exhausted",
        "examples": [
          {
            "sentence": "К1алэр '''мэпшъэгъ'''",
            "translation": "The boy '''is exhausted'''."
          },
          {
            "sentence": "Сэ '''сыпшъыгъэп'''",
            "translation": "'''I''' am '''not exhausted'''."
          },
          {
            "sentence": "К1алэр псынк1эу '''мэпшъы'''",
            "translation": "The boy '''is getting tired''' fast."
          },
          {
            "sentence": "Чэщым '''сыпшъыгъэу''' унэм сыкъехьэжьы",
            "translation": "At night I return home '''tired'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pʂən"
  },
  {
    "word": "ехъырэхъышэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to doubt",
        "examples": [
          {
            "sentence": "Шъо пшъэрылъыр зэрэжъугъэцэк1эщтым '''сехъырэхъышэрэп'''",
            "translation": "'''I have no doubt''' that you will fulfill the obligation."
          },
          {
            "sentence": "'''Сехъырэхъышэ''', къысфэтхэна шъу1а.",
            "translation": "'''I doubt''' whether he’ll write to me."
          },
          {
            "sentence": "'''Сехъырэхъышэ''', сикъыщэна шъу1а.",
            "translation": "'''I doubt''' whether he’ll marry me."
          }
        ]
      },
      {
        "meaning": "to hesitate of",
        "examples": [
          {
            "sentence": "К1алэр '''ехъырэхъышэ''' мэзым хэхьанэу",
            "translation": "The boy '''is hesitating ''' to enter the forest."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaχəraχəʃan"
  },
  {
    "word": "гуцаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "suspicion",
        "examples": []
      }
    ],
    "synonyms": [
      "гурышхъу"
    ],
    "type": "noun",
    "ipa": "ɡʷət͡saːf"
  },
  {
    "word": "гурышхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "suspicion",
        "examples": []
      }
    ],
    "synonyms": [
      "гуцаф"
    ],
    "type": "noun",
    "ipa": "ɡʷərəʃχʷ"
  },
  {
    "word": "гуцафэ ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suspect",
        "examples": []
      }
    ],
    "synonyms": [
      "гурышхъу ш1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷət͡saːfa ʃʼən"
  },
  {
    "word": "гурышхъу ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to suspect",
        "examples": []
      }
    ],
    "synonyms": [
      "гуцафэ ш1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷərəʃχʷ ʃʼən"
  },
  {
    "word": "фэбае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hot air, hot weather, hot spell",
        "examples": []
      }
    ],
    "synonyms": [
      "жъоркъ"
    ],
    "type": "noun",
    "ipa": "fabaːja"
  },
  {
    "word": "дэхьыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to raise, to elevate, to take upwards",
        "examples": []
      },
      {
        "meaning": "to glorify someone, to elevate someone's reputation",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daħəjan"
  },
  {
    "word": "гъэстыныпхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fuel",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁastənəpχ"
  },
  {
    "word": "къутаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fragment",
        "examples": [
          {
            "sentence": "Апч '''къутаф'''",
            "translation": "Glass '''fragment'''."
          }
        ]
      },
      {
        "meaning": "wreckage, debris (scattered remains of something destroyed)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷətaːf"
  },
  {
    "word": "шъхъэи",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "small, tiny",
        "examples": []
      }
    ],
    "synonyms": [
      "жъгъэй"
    ],
    "cognate": "ц1ык1у",
    "type": "adj",
    "ipa": "ʂħajə"
  },
  {
    "word": "гъэмысэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to accuse, to charge, to blame",
        "examples": [
          {
            "sentence": "Ар тэрэзэу зек1уагъэпышъ, '''гъэмысэн''' фае",
            "translation": "He didn't behave correctly, he must be '''blamed'''."
          },
          {
            "sentence": "Лажьэ имы1эу агъэмысэн",
            "translation": "'''To blame''' someone without fault."
          }
        ]
      },
      {
        "meaning": "to condemn",
        "examples": []
      },
      {
        "meaning": "to convict",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaməsan"
  },
  {
    "word": "лажьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fault; blame",
        "examples": [
          {
            "sentence": "'''Лажьэ''' имыIэу агъэмысэн",
            "translation": "'''To blame''' someone without fault."
          },
          {
            "sentence": "К1алэм '''илажь''' апчыр зэрэубэтагъэр",
            "translation": "It is the boy's '''fault''' the glass broke."
          },
          {
            "sentence": "'''Силажьэп''' сэ",
            "translation": "It is '''not my fault'''."
          },
          {
            "sentence": "'''Уилажь''' хъугъэр",
            "translation": "It is '''your fault''' what happened."
          }
        ]
      },
      {
        "meaning": "fault, a defect",
        "examples": [
          {
            "sentence": "Кум зыгорэ '''илажьа'''?",
            "translation": "Is there a '''fault''' in the car?"
          },
          {
            "sentence": "'''Уилажь''' хъугъэр",
            "translation": "It is '''your fault''' what happened."
          }
        ]
      },
      {
        "meaning": "matter (condition)",
        "examples": [
          {
            "sentence": "Сыд '''уилажьэр'''?",
            "translation": "What is '''the matter''' with you?"
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ɮaːʑa"
  },
  {
    "word": "унэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to unload, to discharge",
        "examples": []
      },
      {
        "meaning": "to relieve",
        "examples": []
      },
      {
        "meaning": "to empty",
        "examples": [
          {
            "sentence": "Шъуилагъэхэр '''шъуунэк1ых'''",
            "translation": "'''Empty (pl.)''' your plates."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wənat͡ʃʼən"
  },
  {
    "word": "илъыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jerk (quick, often unpleasant tug or shake)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jəɬəʁʷ"
  },
  {
    "word": "гъэпщынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to punish",
        "examples": [
          {
            "sentence": "Машинэр зэрикъутагъэм пае шоферыр '''гъэпщынэн''' фае",
            "translation": "Because he wrecked the car, the driver need '''to be punished'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пшъэдэк1ыжь егъэхьын"
    ],
    "type": "verb",
    "ipa": "ʁapɕənan"
  },
  {
    "word": "пшъэдэк1ыжь егъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to punish",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэпщынэн"
    ],
    "type": "verb",
    "ipa": "pʂadat͡ʃʼəʑ jaʁaħən"
  },
  {
    "word": "пшъэдэк1ыжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "punishment",
        "examples": [
          {
            "sentence": "К1алэр '''пшъэдэк1ыжьым''' ш1ок1эгъ",
            "translation": "The boy avoided '''the punishment'''."
          }
        ]
      }
    ],
    "synonyms": [
      "пщынэжь"
    ],
    "type": "noun",
    "ipa": "pʂadat͡ʃəʑ"
  },
  {
    "word": "гъуазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guide",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷaːza"
  },
  {
    "word": "фитыныгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "right; claim",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "fəjtənəʁ"
  },
  {
    "word": "тхьэлъэ1уп1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "temple",
        "examples": []
      },
      {
        "meaning": "a place where people can gather up to pray to God",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħaɬaʔʷəpʼa"
  },
  {
    "word": "тхьэ сурэт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "idol",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tħa səwrat"
  },
  {
    "word": "мэш1отхъуабз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flame",
        "examples": [
          {
            "sentence": "'''Мэш1отхъуабзэм''' унэр зэрэпсаоу зэлъиштагъ",
            "translation": "'''The flames''' swept the whole house."
          }
        ]
      },
      {
        "meaning": "blaze",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maʃʷʼatχəaːbz"
  },
  {
    "word": "зэк1эугъоен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather, to collect",
        "examples": []
      },
      {
        "meaning": "to assemble",
        "examples": []
      }
    ],
    "synonyms": [
      "зэхэугъоен",
      "угъоин",
      "къэугъоин"
    ],
    "type": "verb",
    "ipa": "zat͡ʃʼawʁʷawan"
  },
  {
    "word": "зэхэугъоен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gather, to collect",
        "examples": []
      },
      {
        "meaning": "to assemble",
        "examples": []
      }
    ],
    "synonyms": [
      "зэк1эугъоен",
      "угъоин",
      "къэугъоин"
    ],
    "type": "verb",
    "ipa": "zaxawʁʷawan"
  },
  {
    "word": "итхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shovel out, to rake out, to remove by shoveling or raking",
        "examples": [
          {
            "sentence": "'''Итхъу''' ят1эр",
            "translation": "'''Shovel out''' the dirt."
          },
          {
            "sentence": "К1алэм ч1ыгор '''етхъу'''",
            "translation": "The boy '''is shoveling out''' the ground."
          }
        ]
      }
    ],

    "cognate": "къитхъун",
    "type": "verb",
    "ipa": "jətχʷən"
  },
  {
    "word": "къитхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shovel out, to rake out, to remove by shoveling or raking",
        "examples": []
      }
    ],

    "cognate": "итхъун",
    "type": "verb",
    "ipa": "qjətχʷən"
  },
  {
    "word": "хэ1алъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scoop",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xaʔaːɬ"
  },
  {
    "word": "гъэчанын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sharpen, to whet",
        "examples": [
          {
            "sentence": "Шъэжъыер '''гъэчанын''' фае",
            "translation": "The knife should be '''sharpened'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэучыен"
    ],
    "cognate": "лъын",
    "type": "verb",
    "ipa": "ʁat͡ʃaːnən"
  },
  {
    "word": "гъэучыен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэчанын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэчанын"
    ],
    "cognate": "лъын",
    "type": "verb",
    "ipa": "ʁawt͡ʃəjan"
  },
  {
    "word": "гъэушъэбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to soften",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁawʂabən"
  },
  {
    "word": "ныджы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sand bar, sand bank",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "nəd͡ʒə"
  },
  {
    "word": "уалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wave",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "псыор",
      "ор",
      "псыуалъэ",
      "хыуалъэ"
    ],
    "type": "noun",
    "ipa": "waːɬa"
  },
  {
    "word": "хыор",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sea wave",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "псыор",
      "уалъэ",
      "псыуалъэ",
      "хыуалъэ"
    ],
    "type": "noun",
    "ipa": "xəwar"
  },
  {
    "word": "псыор",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wave",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "ор",
      "уалъэ",
      "псыуалъэ",
      "хыуалъэ"
    ],
    "type": "noun",
    "ipa": "psəwar"
  },
  {
    "word": "хыуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sea wave",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "псыор",
      "уалъэ",
      "псыуалъэ"
    ],
    "type": "noun",
    "ipa": "xəwaːɬa"
  },
  {
    "word": "псыуалъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wave",
        "examples": []
      }
    ],
    "synonyms": [
      "хыор",
      "псыор",
      "уалъэ",
      "хыуалъэ"
    ],
    "type": "noun",
    "ipa": "psəəwaːɬa"
  },
  {
    "word": "чэндж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shallow",
        "examples": []
      }
    ],

    "cognate": "чэнж",
    "type": "adj",
    "ipa": "t͡ʃand͡ʒ"
  },
  {
    "word": "пшэхъоф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "white sand",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʃaχʷaf"
  },
  {
    "word": "уфэупц1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flexible, pliable, agile",
        "examples": []
      },
      {
        "meaning": "adaptable",
        "examples": []
      }
    ],
    "synonyms": [
      "лант1э"
    ],
    "type": "adj",
    "ipa": "wəfawpt͡sʼa"
  },
  {
    "word": "лант1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flexible, pliable, agile",
        "examples": []
      },
      {
        "meaning": "adaptable",
        "examples": []
      }
    ],
    "synonyms": [
      "уфэупц1э"
    ],
    "type": "adj",
    "ipa": "laːntʼa"
  },
  {
    "word": "щытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stand",
        "examples": []
      },
      {
        "meaning": "probably",
        "examples": [
          {
            "sentence": "К1алэр тучаным к1уагъэу '''щытын'''",
            "translation": "The boy '''probably''' went to the shop."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɕətən"
  },
  {
    "word": "ш1огъэш1эгъонын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to show interest in; to be interested in",
        "examples": []
      },
      {
        "meaning": "to wonder (at), to be amazed",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼaʁaʃʼaʁʷan"
  },
  {
    "word": "зыпэгъутхьажьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have the instance of urinating.",
        "examples": [
          {
            "sentence": "Cэ '''сызыпэгъотхьажьы'''",
            "translation": "'''I have the instance of urinating'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəpaʁʷatħaʑən"
  },
  {
    "word": "онджэкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flue",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wand͡ʒaq"
  },
  {
    "word": "хьэ1уц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "greenhorn",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ħaʔʷət͡sʼ"
  },
  {
    "word": "дэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go out",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dat͡ʼən"
  },
  {
    "word": "тхьэмыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "atheist",
        "examples": []
      }
    ],
    "synonyms": [
      "динынчъэ",
      "тхьанычъэ",
      "тхьэмыш1э"
    ],
    "type": "noun",
    "ipa": "tħaməda"
  },
  {
    "word": "тхьэмыш1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "atheist",
        "examples": []
      }
    ],
    "synonyms": [
      "динынчъэ",
      "тхьанычъэ",
      "тхьэмыдэ"
    ],
    "type": "noun",
    "ipa": "tħaməʃʼa"
  },
  {
    "word": "тхьанычъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "atheist",
        "examples": []
      }
    ],
    "synonyms": [
      "динынчъэ",
      "тхьэмыдэ",
      "тхьэмыш1э"
    ],
    "type": "noun",
    "ipa": "tħaːnət͡ʂʼa"
  },
  {
    "word": "динынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "atheist",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьэмыдэ",
      "тхьанычъэ",
      "тхьэмыш1э"
    ],
    "type": "noun",
    "ipa": "dənənt͡ʂʼa"
  },
  {
    "word": "гу къэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гукъэк1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷ əqat͡ʃʼən"
  },
  {
    "word": "гъэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grow hair",
        "examples": [
          {
            "sentence": "Л1ым ыжьак1э '''ыгъэк1ыгъ'''",
            "translation": "The man '''grew''' his beard."
          },
          {
            "sentence": "К1алэм ышъхьац к1ыхьэщэу '''ыгъэк1ыгъ'''",
            "translation": "The man '''grew''' his hair too much."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁat͡ʃʼən"
  },
  {
    "word": "къэгъэк1охьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to circle something; to surround something",
        "examples": [
          {
            "sentence": "Тиунэр къэцык1э '''къэгъэк1охьыгъ'''",
            "translation": "My house '''is surrounded''' with thorns."
          },
          {
            "sentence": "Джэуап тэрэзыр '''къэгъэк1охь'''",
            "translation": "'''Circle''' the right answer."
          },
          {
            "sentence": "Ц1ыфы дедэхэмк1э '''сыкъэгъэк1охьыгъ'''",
            "translation": "'''I am surrounded''' with foolish people."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qaʁakʷʼaħən"
  },
  {
    "word": "ичэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ибэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "jət͡ʃa"
  },
  {
    "word": "пщэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cloud",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pɕa"
  },
  {
    "word": "зэкъолъэдагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stuck together (ropes or shoe laces)",
        "examples": [
          {
            "sentence": "Уицокъэ лъапсэхэр '''зэкъолъэдагъэх'''",
            "translation": "Your '''shoe laces''' are stuck together."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zaqʷaɬadaːʁ"
  },
  {
    "word": "зэкъодзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tie, to bind",
        "examples": [
          {
            "sentence": "кIапсэм ыцыпэхэр зэкъодзэных",
            "translation": "'''To tie''' the ends of the rope."
          }
        ]
      },
      {
        "meaning": "to manufacture, to produce",
        "examples": []
      },
      {
        "meaning": "to make things stuck with each other (ropes or shoe laces)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaqʷad͡zan"
  },
  {
    "word": "къэгупшысын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to think up; to create in one's mind",
        "examples": []
      },
      {
        "meaning": "to come up with (an idea or concept)",
        "examples": []
      },
      {
        "meaning": "to invent",
        "examples": []
      }
    ],
    "synonyms": [
      "къыч1эхын"
    ],
    "type": "verb",
    "ipa": "qaɡʷəpʃəsən"
  },
  {
    "word": "пшъэшъэ гъусэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъусэ пшъашъэ\"",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэшъэгъуч"
    ],
    "type": "noun",
    "ipa": "pʂaʂa ʁʷəsa"
  },
  {
    "word": "к1элэ гъусэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъусэ к1алэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaɮa ʁʷəsa"
  },
  {
    "word": "гъусэ пшъашъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "girlfriend",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэшъэгъу"
    ],
    "type": "noun",
    "ipa": "ʁʷəsa pʂaːʂa"
  },
  {
    "word": "гъусэ к1алэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boyfriend",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷəsa t͡ʃʼaːɮa"
  },
  {
    "word": "упхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to trim",
        "examples": []
      },
      {
        "meaning": "to clip, to crop (usually of hair)",
        "examples": [],
        "tags": [
          "usually of hair"
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəpχʷan"
  },
  {
    "word": "1офыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "matter, issue, problem",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafəʁʷ"
  },
  {
    "word": "1офытхьабз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "undertaking, action, enterprise, measure (that which is undertaken)",
        "examples": []
      },
      {
        "meaning": "event",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafətħaːbz"
  },
  {
    "word": "шъхьэ убытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get wasted; to get very drunk or stoned",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʂħa wəbətən"
  },
  {
    "word": "пышхэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feast upon",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəʃxaħaːn"
  },
  {
    "word": "зэт1эхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scratch oneself (due to itchiness)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zatʼaχʷən"
  },
  {
    "word": "шыхьатэу теуцон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stand a witness to",
        "examples": [
          {
            "sentence": "Сэ '''шыхьатэу сыкъытеуцо''' мы к1алэм ар ыш1агъэк1э",
            "translation": "'''I stand as a witness''' that this boy did it."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃəħaːtaw tajwət͡ʃʷan"
  },
  {
    "word": "шъхьэщытхъужь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boaster, braggart; bragger",
        "examples": []
      }
    ],
    "synonyms": [
      "жьыгъэбыу"
    ],
    "type": "noun",
    "ipa": "ʂħaɕətəχəʑ"
  },
  {
    "word": "лъэсыбэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cane; walking stick",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬasəbaɕ"
  },
  {
    "word": "шъэогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "friend",
        "examples": []
      }
    ],
    "synonyms": [
      "ныбджэгъу"
    ],
    "cognate": "гъусэ",
    "type": "noun",
    "ipa": "ʂawaʁʷ"
  },
  {
    "word": "гуадз",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "secondary, minor, of less importance",
        "examples": []
      },
      {
        "meaning": "additional, supplementary",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷaːd͡z"
  },
  {
    "word": "щэуалэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bag; sack",
        "examples": []
      }
    ],
    "synonyms": [
      "къапщыкъ",
      "дзыо"
    ],
    "type": "noun",
    "ipa": "ɕawaːɮa"
  },
  {
    "word": "къыщыунэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыщынэн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэгъэнэн"
    ],
    "type": "verb",
    "ipa": "qəɕəwnan"
  },
  {
    "word": "къыщынэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to leave behind someone",
        "examples": []
      },
      {
        "meaning": "to abandon someone",
        "examples": []
      }
    ],
    "synonyms": [
      "къэгъэнэн"
    ],
    "type": "verb",
    "ipa": "qəɕəwnan"
  },
  {
    "word": "пшын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crawl",
        "examples": []
      },
      {
        "meaning": "to walk dragging oneself",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1энлъэн"
    ],
    "type": "verb",
    "ipa": "pʃən"
  },
  {
    "word": "ушъыбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъыбын\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂəbən"
  },
  {
    "word": "игъэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to close something (to move so that an opening is closed)",
        "examples": [
          {
            "sentence": "К1алэм пчъэ '''регъасэ'''",
            "translation": "The boy '''is closing''' the door."
          },
          {
            "sentence": "К1алэм шъхьаншъупчъэ '''ригъэсагъ'''",
            "translation": "The boy '''closed''' the window."
          }
        ]
      }
    ],
    "synonyms": [
      "фэш1ын"
    ],
    "type": "verb",
    "ipa": "jəʁasan"
  },
  {
    "word": "гъэуп1ыц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to close eyes",
        "examples": [
          {
            "sentence": "Унэхэр '''гъауп1ыц1эх'''",
            "translation": "'''Close''' your eyes."
          },
          {
            "sentence": "Унэхэр '''умыгъэуп1ыц1эх'''",
            "translation": "'''Don't close''' your eyes."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэуп1ыс1эн"
    ],
    "type": "verb",
    "ipa": "ʁawpʼət͡sʼan"
  },
  {
    "word": "гъэуп1ыс1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэуп1ыц1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэупыц1эн"
    ],
    "type": "noun",
    "ipa": "ʁawpsʼan"
  },
  {
    "word": "уп1ыц1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэуп1ыц1эн\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wpʼəsʼan"
  },
  {
    "word": "шъхьэкъуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baldhead",
        "examples": []
      }
    ],
    "synonyms": [
      "къуй",
      "шъхьэджашъу"
    ],
    "type": "adj",
    "ipa": "ʂħaqʷəj"
  },
  {
    "word": "къуй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bald",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэджашъу",
      "шъхьэкъуй"
    ],
    "type": "adj",
    "ipa": "qʷəj"
  },
  {
    "word": "шъхьэтехъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shawl (a square piece of cloth worn as a covering for the head, neck, and shoulders)",
        "examples": []
      },
      {
        "meaning": "veil",
        "examples": []
      },
      {
        "meaning": "headscarf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħatajχʷ"
  },
  {
    "word": "пшъэбы дэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to give a slap in the neck",
        "examples": []
      }
    ],
    "synonyms": [
      "дийшъо дэон"
    ],
    "type": "noun",
    "ipa": "pʂabə dawan"
  },
  {
    "word": "дийшъо дэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to give a slap in the neck",
        "examples": []
      }
    ],
    "synonyms": [
      "пшъэбы дэон"
    ],
    "type": "noun",
    "ipa": "dəjʃʷa dawan"
  },
  {
    "word": "пшъэбы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пшъэб\"",
        "examples": []
      }
    ],
    "synonyms": [
      "дый"
    ],
    "type": "noun",
    "ipa": "pʂabə"
  },
  {
    "word": "хъэбар къэпчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэбар къэпчъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "χabaːr qapt͡ʂən"
  },
  {
    "word": "къэбар къэпчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to have a casual conversation",
        "examples": [
          {
            "sentence": "Тихьак1э чэщыр зэрэчэщэу '''къэбар къыдэсэпчъыгъ''",
            "translation": "'''I had a conversation with''' my guest all night."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qabaːr qapt͡ʂən"
  },
  {
    "word": "къэпчъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пчъын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qapt͡ʂən"
  },
  {
    "word": "игъэхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to pour/fill (in) (liquid)",
        "examples": [
          {
            "sentence": "Псы апчым '''къэсфигъахъу'''",
            "translation": "'''Pour''' water into the glass '''for me'''."
          },
          {
            "sentence": "Псы бащэ апчым '''ебэгъахъо'''",
            "translation": "'''You pour''' too much water into the glass."
          },
          {
            "sentence": "Бензин кум '''игъэхъон'''",
            "translation": "'''To fill''' the car with gas."
          },
          {
            "sentence": "К1алэм бэнзин имашинэ '''регъахъо'''",
            "translation": "The boy '''is filling''' his car with gas."
          },
          {
            "sentence": "Апчым '''игъахъу''' псы",
            "translation": "'''Fill''' the glass with water."
          }
        ]
      },
      {
        "meaning": "to make something appear inside something",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "əʁaχʷan"
  },
  {
    "word": "уашхъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "heaven; sky",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "waːʃχʷa"
  },
  {
    "word": "хьае регъаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэе регъаф\"",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "ħaːja rajraːf"
  },
  {
    "word": "хьэе регъаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) screw this, fuck this",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "intj",
    "ipa": "ħaːja rajraːf"
  },
  {
    "word": "лыргъужъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "horny, callous",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "lərʁʷəʐ"
  },
  {
    "word": "зэ1упхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to begin to argue, to get into an argument with",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔʷəpχʷan"
  },
  {
    "word": "нэшхъэин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get sad, grieve",
        "examples": []
      }
    ],
    "synonyms": [
      "зэгожъын",
      "чэфынчъэн"
    ],
    "type": "verb",
    "ipa": "naʃχajən"
  },
  {
    "word": "къэпсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэпсын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэпсын"
    ],
    "type": "verb",
    "ipa": "qapsan"
  },
  {
    "word": "псын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэпсын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэпсын"
    ],
    "type": "verb",
    "ipa": "psən"
  },
  {
    "word": "псэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэпсын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэпсын"
    ],
    "type": "verb",
    "ipa": "psan"
  },
  {
    "word": "къэпсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to light; to illuminate; to provide light for when it is dark.",
        "examples": [
          {
            "sentence": "Ламбэ '''къэпсы'''",
            "translation": "The lamp '''is illuminating'''."
          }
        ]
      },
      {
        "meaning": "to sneeze",
        "examples": [
          {
            "sentence": "К1алэр '''къэпсы'''",
            "translation": "The boy '''sneezes'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къэблэн",
      "къэц1этхын"
    ],
    "type": "verb",
    "ipa": "qapsən"
  },
  {
    "word": "къы1упсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to light near; to illuminate near",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəʔʷəpsə"
  },
  {
    "word": "фэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be capable to do, to be able to do, to manage",
        "examples": [
          {
            "sentence": "К1алэм 1офы къины '''фэш1эщт'''",
            "translation": "The boy '''is capable of doing''' a hard work."
          },
          {
            "sentence": "Экзамыныр '''къэсфэш1ырэп'''",
            "translation": "'''I can't do''' the exam."
          }
        ]
      }
    ],
    "synonyms": [
      "лъэк1ын",
      "ипш1э къик1ын"
    ],
    "type": "verb",
    "ipa": "faʃʷan"
  },
  {
    "word": "ипш1э къик1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be capable of, to be able of",
        "examples": [
          {
            "sentence": "К1алэм 1офы къины ыш1эныр '''ипш1э къик1ыщт'''",
            "translation": "The boy '''is capable of''' doing a hard work."
          },
          {
            "sentence": "Мы экзамыныр '''сипш1э къик1ыщтэп'''",
            "translation": "'''I can't''' do this exam."
          }
        ]
      }
    ],
    "synonyms": [
      "лъэк1ын",
      "фэш1эн"
    ],
    "type": "verb",
    "ipa": "jəpʃʼa qəjt͡ʃʼən"
  },
  {
    "word": "гущы1эмпае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гущы1эм пае\"",
        "examples": []
      }
    ],
    "type": "prep_phrase",
    "ipa": "ɡʷəɕəʔampaːja"
  },
  {
    "word": "гущы1эм пае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for example",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1эм фа1оу"
    ],
    "type": "prep_phrase",
    "ipa": "ɡʷəɕəʔam paːja"
  },
  {
    "word": "гущы1эм фа1оу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "for example",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1эм пае"
    ],
    "type": "prep_phrase",
    "ipa": "ɡʷəɕəʔam faːʔʷaw"
  },
  {
    "word": "ук1эсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to like (the process of liking or falling in love)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wət͡ʃʼasan"
  },
  {
    "word": "гъынэнэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whinge, to whine",
        "examples": [
          {
            "sentence": "К1алэр '''мэгъынанэ'''",
            "translation": "The boy '''whines'''."
          },
          {
            "sentence": "'''Угъынэнэ''' зэпытыщта?",
            "translation": "Are you always '''gonna whine'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁənanan"
  },
  {
    "word": "пхъэтэпэмыхьы ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to ignore, to disregard (to deliberately pay no attention to), to igg",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pχatapaməħə ʃʼən"
  },
  {
    "word": "едэхэш1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to caress (touch, kiss or stroke lovingly)",
        "examples": []
      },
      {
        "meaning": "to fondle",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэш1он"
    ],
    "type": "verb",
    "ipa": "jadaxaʃʼan"
  },
  {
    "word": "убзэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еубзэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəbzan"
  },
  {
    "word": "епэсын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to consider somebody worthy of; to esteem worthy of",
        "examples": [
          {
            "sentence": "Л1ыр '''зепэсыжьырэп''' къыздэгущы1энэу",
            "translation": "The man '''doesn't consider himself ''' talking to me '''worthy'''."
          },
          {
            "sentence": "Сшы 1офымк1э а л1ым '''епэсырэп''', ащ пае 1аеу дэгущы1э",
            "translation": "My brother in work, '''don't consider''' that man '''worthy''', thus he talks to him rudely."
          }
        ]
      },
      {
        "meaning": "to have expectations of somebody to be; to view somebody in a particular way",
        "examples": [
          {
            "sentence": "'''Еспэсыгъэп''' л1ыр 1офымк1э ерагъэу къыч1эк1ынэу",
            "translation": "'''I did not expect''' the man to turn out to be weak in the work."
          },
          {
            "sentence": "А ц1ыфым бэ '''еспэсырэ'''",
            "translation": "'''I expect''' a lot from that person."
          },
          {
            "sentence": "Ащ 1офы дэгъу ыш1энэу '''еспэсырэп'''",
            "translation": "'''I do not expect''' that person to do a good job."
          },
          {
            "sentence": "Ара '''къысэппэсырэ'''? Ащ нахь дэгъоу укъызхэплъэрэба?",
            "translation": "Is that what '''you expect from me?''' Don't you view me as better than that?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "japasən"
  },
  {
    "word": "фэгъэшъошэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to consider somebody worthy of; to esteem worthy of",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "faʁaʃʷaʃan"
  },
  {
    "word": "къотэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "supporter, advocate, proponent, adherent",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷataʁʷ"
  },
  {
    "word": "гущы1э етын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to promise; to give one's word",
        "examples": [
          {
            "sentence": "'''Гущы1э къыосэты''' неущы филымым теплъыщт",
            "translation": "'''I promise you''' we will watch the film tomorrow."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəɕəʔa jatən"
  },
  {
    "word": "гущы1э тын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гущы1э етын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəɕəʔa tən"
  },
  {
    "word": "гущы1э ептын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гущы1э етын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷəɕəʔa japtən"
  },
  {
    "word": "ешъорый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alcoholic",
        "examples": []
      }
    ],
    "synonyms": [
      "ешъуак1о"
    ],
    "type": "noun",
    "ipa": "jaʃʷarəj"
  },
  {
    "word": "ащ пае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "because of that; for that",
        "examples": [
          {
            "sentence": "Хьак1э тиунэ къэк1ощт, '''ащ пае''' сыгъэзэжьын фай",
            "translation": "A guest will come to my house, '''because of that''' I need to return."
          }
        ]
      }
    ],
    "synonyms": [
      "ащ фэш1ык1э"
    ],
    "type": "adv",
    "ipa": "aːɕ paːja"
  },
  {
    "word": "ащ фэш1ык1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "because of that; for that",
        "examples": [
          {
            "sentence": "Хьак1э тиунэ къэк1ощт, '''ащ фэш1ык1э''' сыгъэзэжьын фай",
            "translation": "A guest will come to my house, '''because of that''' I need to return."
          }
        ]
      }
    ],
    "synonyms": [
      "ащ пае"
    ],
    "type": "adv",
    "ipa": "aːɕ faʃʼət͡ʃʼa"
  },
  {
    "word": "сыда п1омэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "because (for what cause, reason, or purpose)",
        "examples": [
          {
            "sentence": "Экзамыным бэрэ седжэщт, '''сыда п1омэ''' къиныщт",
            "translation": "I will study a lot for the exam, '''because''' it will be hard."
          }
        ]
      }
    ],
    "synonyms": [
      "зары"
    ],
    "type": "adv",
    "ipa": "sədaː pʔʷama"
  },
  {
    "word": "зары",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(dialectal) because (for what cause, reason, or purpose)",
        "examples": [
          {
            "sentence": "Экзамыным бэрэ седжэщт, '''зары''' къиныщт",
            "translation": "I will study a lot for the exam, '''because''' it will be hard."
          }
        ],
        "tags": [
          "dialectal"
        ]
      }
    ],
    "synonyms": [
      "сыда п1омэ"
    ],
    "type": "adv",
    "ipa": "zaːrə"
  },
  {
    "word": "шъхьацышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hair lash (head)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːt͡səʃʷa"
  },
  {
    "word": "зыгъэнэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to act spoiled",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəʁanad͡ʒan"
  },
  {
    "word": "гъэсэхъуджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spoil",
        "examples": [
          {
            "sentence": "К1алэр '''умыгъэсэхъудж'''",
            "translation": "'''Don't spoil''' the kid."
          },
          {
            "sentence": "К1алэр '''гъэсэхъуджэн''' фаеп",
            "translation": "You don't need to spoil the kid."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁasaχʷəd͡ʒan"
  },
  {
    "word": "к1охъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lose one's way, to go astray, to get lost",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "kʷʼaχʷən"
  },
  {
    "word": "плъэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to overlook, to miss",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pɬaχʷən"
  },
  {
    "word": "чъэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run the wrong way",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʂaχʷən"
  },
  {
    "word": "1охъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make a slip in speaking; to say something mistakenly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔʷaχʷən"
  },
  {
    "word": "1офынчъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "unemployed, jobless",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafənt͡ʂa"
  },
  {
    "word": "1оен",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to whine; to complain",
        "examples": []
      }
    ],
    "synonyms": [
      "тхьаусхэн"
    ],
    "type": "verb",
    "ipa": "ʔʷajan"
  },
  {
    "word": "къушъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fart",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəʃʷ"
  },
  {
    "word": "марджэ хъужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "far be it, it would be a desecration",
        "examples": [
          {
            "sentence": "Уизакъоу чэщым удэмык1 '''марджэ хъужьын'''",
            "translation": "'''Far be it''', don't go out alone in the night."
          },
          {
            "sentence": "'''Марджэ хъужьын''', 1офыр умыухыгъэу укъэмык1у",
            "translation": "'''Far be it''', don't came before you finish the job."
          }
        ]
      }
    ],
    "type": "phrase",
    "ipa": "maːrd͡ʒa χʷəʑən"
  },
  {
    "word": "л1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "death time, time of death",
        "examples": []
      }
    ],
    "synonyms": [
      "хьадэгъу"
    ],
    "type": "noun",
    "ipa": "ɬʼaʁʷ"
  },
  {
    "word": "тк1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be melted",
        "examples": [
          {
            "sentence": "мыжъор лавэм хабдзэмэ, мыжъор '''мэтк1ут'''",
            "translation": "If you throw the rock on the lava, the rock will melt."
          }
        ]
      }
    ],
    "synonyms": [
      "жъун"
    ],
    "type": "verb",
    "ipa": "tkʷʼən"
  },
  {
    "word": "хэк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "garbage; debris",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "xat͡ʃʼ"
  },
  {
    "word": "пхъэнк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sweep",
        "examples": [
          {
            "sentence": "Пшъашъэм джэхашъор '''ыпхъэнкIи''', хэкIыр ритэкъужьыгъ",
            "translation": "The girl '''swept''' the floor, and dropped the debris [in the can]."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pχant͡ʃʼən"
  },
  {
    "word": "пхъэхат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "post, pole, pillar, column",
        "examples": []
      }
    ],
    "synonyms": [
      "пкъэу"
    ],
    "type": "noun",
    "ipa": "pχaxaːtʼa"
  },
  {
    "word": "еуцол1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to stand near something",
        "examples": []
      },
      {
        "meaning": "to come to terms with; to give in",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawt͡ʃʷaɬʼan"
  },
  {
    "word": "тегущы1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk about",
        "examples": [
          {
            "sentence": "Л1ыхэр 1офым '''къытегущы1эхэу''' зэхэсыхыгъ",
            "translation": "I heard the men '''talk about''' the job."
          },
          {
            "sentence": "Еджэным ехъурэ '''укъэсфэтегущы1эрэп'''",
            "translation": "'''You are not talking about''' anything than the studies."
          },
          {
            "sentence": "Тыш1эщтым '''тыкъытегущы1эн''' фай",
            "translation": "'''We''' need '''to speak about''' what we gonna do."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajɡʷəɕəʔan"
  },
  {
    "word": "зэхэфыгъуае ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to complicate",
        "examples": []
      }
    ],
    "synonyms": [
      "зэхэлъэшъуагъэ ш1ын"
    ],
    "type": "verb",
    "ipa": "zaxafəʁʷaːja ʃʼən"
  },
  {
    "word": "зэхэлъэшъуагъэ ш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to complicate",
        "examples": []
      }
    ],
    "synonyms": [
      "зэхэфыгъуае ш1ын"
    ],
    "type": "verb",
    "ipa": "zaxaɬaʃʷaːʁa ʃʼən"
  },
  {
    "word": "зэ1ыгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to disarray",
        "examples": [
          {
            "sentence": "Си мащинэ '''зэ1эбгъахьагъ'''",
            "translation": "'''You disarrayed''' my car."
          },
          {
            "sentence": "К1алэм унэм '''зэ1эбгъахьагъ'''",
            "translation": "The boy '''disarrayed''' the house."
          }
        ]
      },
      {
        "meaning": "to make something cease to function; to make something dysfunctional",
        "examples": [
          {
            "sentence": "Сомпыютэрэр '''зы1игъэхьагъ'''",
            "translation": "'''Don't ruin''' my computer."
          }
        ]
      },
      {
        "meaning": "to really confuse someone",
        "examples": [
          {
            "sentence": "Зэ '''сызэ1ыбгъэхьагъ'''",
            "translation": "Wait, '''you confused me'''."
          }
        ]
      },
      {
        "meaning": "to make anarchy/chaos/mess",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaʁaħan"
  },
  {
    "word": "зэ1эгъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэ1ыгъэхьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʔaʁaħaːn"
  },
  {
    "word": "зэш1ок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to break something",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zaʃʷʼat͡ʃʼən"
  },
  {
    "word": "пчъаблэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "doorjamb",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʂaːbɮa"
  },
  {
    "word": "1орытх",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dictation; the process of speaking for someone else to write down the words",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷarətx"
  },
  {
    "word": "зэ1унын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэ1унэн\"",
        "examples": []
      }
    ],

    "cognate": "щыгъэтын",
    "type": "verb",
    "ipa": "zaʔʷənan"
  },
  {
    "word": "быяун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to calm down, to quiet down",
        "examples": [
          {
            "sentence": "Сабыир зэ1унэжьи '''быяугъэ'''",
            "translation": "The child shushed and '''became quite'''."
          }
        ]
      },
      {
        "meaning": "to stop, to cease, to quit",
        "examples": [
          {
            "sentence": "Жьыбгъэр '''быяунэу''' ежьагъ",
            "translation": "The wind started '''stopping'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bəjaːwʑəb"
  },
  {
    "word": "лъэбанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "puddle, pool",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬabaːna"
  },
  {
    "word": "фэкъулай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "master, skillful, skilled, competent",
        "examples": []
      }
    ],
    "synonyms": [
      "фэ1эпы1ас"
    ],
    "type": "noun",
    "ipa": "faqʷəɮaːj"
  },
  {
    "word": "фэ1эпы1ас",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "master, skillful, skilled, competent",
        "examples": []
      }
    ],
    "synonyms": [
      "фэкъулай"
    ],
    "type": "noun",
    "ipa": "faʔapəʔaːs"
  },
  {
    "word": "1офш1ак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "how to work; way of working; manner of working",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷafʃʼaːt͡ʃʼa"
  },
  {
    "word": "чырбыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"чырбыч\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃərbəɕ"
  },
  {
    "word": "чырбыч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brick",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃərbət͡ʃ"
  },
  {
    "word": "къэунэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mausoleum",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qawəna"
  },
  {
    "word": "къыпыун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"пыун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəpəwən"
  },
  {
    "word": "1эхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къы1ыхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔəxən"
  },
  {
    "word": "гок1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to go away from the side of something",
        "examples": [
          {
            "sentence": "'''Къысгок1'''.",
            "translation": "'''Go away from me'''."
          },
          {
            "sentence": "Унэм '''къыгок1и''' къак1у.",
            "translation": "'''Get away from the side''' of the house and come."
          }
        ]
      }
    ],
    "synonyms": [
      "бгъодэк1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷat͡ʃʼən"
  },
  {
    "word": "ехъонын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хъонэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaχʷanən"
  },
  {
    "word": "бжьэматэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "beehive",
        "examples": [
          {
            "sentence": "бжьа1ом '''бжьэматэхэр''' дэтых",
            "translation": "'''The beehives''' are in the apiary."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "bʑamaːta"
  },
  {
    "word": "1энат1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "office, post, appointment, position, job",
        "examples": [
          {
            "sentence": "Ар бжьэхъо 1аз, ау бжьэхъон '''1энат1эм''' фаеп",
            "translation": "He is an beekeeper expert, but he does not want to be in a beekeeping '''job'''."
          },
          {
            "sentence": "Л1ым '''си1энат1эр''' къыс1ыхыгъ",
            "translation": "The man '''took''' my position."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʔanaːtʼa"
  },
  {
    "word": "къы1ыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take away something from someone; to seize something",
        "examples": [
          {
            "sentence": "Л1ым къэлэмыр к1алэм '''къы1ырехы'''",
            "translation": "The man '''seizes''' the pencil from the boy."
          },
          {
            "sentence": "К1элэц1ык1ум шъэжъыер джыдэдэм '''къы1эх'''",
            "translation": "'''Take''' the knife '''away from''' the child's '''hands''' right away."
          },
          {
            "sentence": "К1алэм уатэр иятэ '''къы1ырихыгъ''' лэжьэнэу",
            "translation": "The boy '''took''' the hammer from his father to work."
          },
          {
            "sentence": "Рестораным лы '''къы1эх'''",
            "translation": "'''Take''' meat from the restaurant."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qəʔəxən"
  },
  {
    "word": "1ыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къы1ыхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʔəxən"
  },
  {
    "word": "джэнапхъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "material for dresses",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒanaːpχa"
  },
  {
    "word": "шъодэны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "thong (leather)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷadənə"
  },
  {
    "word": "пчэнышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "goatskin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pt͡ʃanəʃʷa"
  },
  {
    "word": "чэмышъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cowskin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaməʃʷa"
  },
  {
    "word": "к1эдэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sew a sole on, to sole (a shoe)",
        "examples": [
          {
            "sentence": "Чэмышъор цуакъэм '''к1адэ'''",
            "translation": "Cowskin is '''sole''' under a shoe."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼadan"
  },
  {
    "word": "шъэожъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "boy",
        "examples": [
          {
            "sentence": "'''шъэожъыем''' бзэджэныр ишэн",
            "translation": "It is the '''boy's''' behavior to get naughty."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "ʂawaʐəja"
  },
  {
    "word": "жъые",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"жъый\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʐəja"
  },
  {
    "word": "блэгъэзык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drive away someone",
        "examples": [
          {
            "sentence": "1эхъогъур '''блэгъэзык1ын'''",
            "translation": "'''To drive off''' the herd."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bɮaʁazət͡ʃʼən"
  },
  {
    "word": "блэдзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw across, to throw over; to throw past",
        "examples": [
          {
            "sentence": "К1алэм мыжъор унэм '''блидзыгъ'''",
            "translation": "The boy '''threw''' the rock '''over''' the house."
          },
          {
            "sentence": "Псыхъом мыжъор '''блэдз'''",
            "translation": "'''Throw''' the rock '''over''' the river."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blad͡zən"
  },
  {
    "word": "блэфын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drive across; to drive past",
        "examples": [
          {
            "sentence": "К1алэм мэлхэр псынэм '''блифыгъэх'''",
            "translation": "The boy '''drove''' the sheep '''past''' the well."
          }
        ]
      },
      {
        "meaning": "to exceed",
        "examples": [
          {
            "sentence": "Нормэм '''блифыгъ'''",
            "translation": "It '''exceeded''' the norm"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "bɮafən"
  },
  {
    "word": "блэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блехын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajxən"
  },
  {
    "word": "блихын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"блехын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "blajxən"
  },
  {
    "word": "блэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take (someone) from one place to another",
        "examples": [
          {
            "sentence": "К1алэр кум къырамыгъэкIэу яунэ '''блащыгъ'''",
            "translation": "Without letting the boy go, they '''went pass''' his house."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blajɕən"
  },
  {
    "word": "къэблэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to glow (a fire or a lamp)",
        "examples": [
          {
            "sentence": "Ламбэр дэгъоу '''къаблэ'''",
            "translation": "The lamp '''glows''' good."
          }
        ]
      }
    ],
    "synonyms": [
      "къэпсын"
    ],
    "type": "verb",
    "ipa": "qablan"
  },
  {
    "word": "блэбыбын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fly pass something",
        "examples": [
          {
            "sentence": "Унэм бзыур '''къэблэбыбыгъ'''",
            "translation": "The bird '''flew pass''' the house."
          },
          {
            "sentence": "Къухьэбыбым сыхьатитф ехьы хым '''блэбыбын''' пае",
            "translation": "The airplane takes five hours '''to fly pass''' the ocean."
          },
          {
            "sentence": "Къухьэбыбэу '''къыблэбыбыгъэр''' плъэгъугъа?",
            "translation": "Did you see the airplane that '''flew by'''?"
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "blabəbən"
  },
  {
    "word": "блэшъутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run past, to run through",
        "examples": [
          {
            "sentence": "конторам '''блэхъушъутын'''",
            "translation": "'''To run past''' the office."
          }
        ]
      }
    ],
    "synonyms": [
      "блэхъушъутын"
    ],
    "type": "verb",
    "ipa": "blaəʂʷtən"
  },
  {
    "word": "хьэлэбэлыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bustle, turmoil",
        "examples": []
      },
      {
        "meaning": "trouble",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaɮabaɮəq"
  },
  {
    "word": "мыхъо-мыш1агъэ зехьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make trouble",
        "examples": []
      },
      {
        "meaning": "to wrongdo",
        "examples": []
      }
    ],
    "synonyms": [
      "бырсырын"
    ],
    "type": "verb",
    "ipa": "məχʷa məʃʼaːʁa zajħaːn"
  },
  {
    "word": "къэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smell (a smell to approach)",
        "examples": [
          {
            "sentence": "Мэ 1эшIу '''къэунэу''' ежьагъ",
            "translation": "A smell started '''to smell'''."
          },
          {
            "sentence": "Унэм мэ 1ай '''къеу'''",
            "translation": "The house '''emits''' bad smell."
          },
          {
            "sentence": "Хым мэ '''къыхэу'''",
            "translation": "A smell '''is coming''' from the sea."
          },
          {
            "sentence": "Газым мэ 1ай '''къегъэу'''",
            "translation": "A gas '''emits''' bad smell."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qawən"
  },
  {
    "word": "чъыгыпкъы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tree trunk, treetrunk",
        "examples": [
          {
            "sentence": "Сэснэй '''чъыгыпкъыр''' зэгуахи пхъэмбгъу ашIыгъ",
            "translation": "They opened the pine tree's '''tree trunk''' and made plank."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂəɣəpqə"
  },
  {
    "word": "пхъэмбгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "board, plank",
        "examples": [
          {
            "sentence": "Сэснэй чъыгыпкъыр зэгуахи '''пхъэмбгъу''' аш1ыгъ",
            "translation": "They opened the pine tree's tree trunk and made '''plank'''."
          },
          {
            "sentence": "Сэснэй '''пхъэмбгъу'''",
            "translation": "Pine tree '''plank'''."
          },
          {
            "sentence": "Пхъэм '''пхъэмбгъу''' го1ул1эн фае",
            "translation": "To nail down '''a board''' on a wood."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "pχambʁʷ"
  },
  {
    "word": "угу егъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуегъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын"
    ],
    "type": "verb",
    "ipa": "ɡʷ jaʁən"
  },
  {
    "word": "гук1эгъу фэплъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гук1эгъу фэлъэгъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын",
      "гуегъун"
    ],
    "type": "verb",
    "ipa": "ɡʷət͡ʃʼaʁʷ fapɬaʁʷən"
  },
  {
    "word": "гук1эгъу фэлъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel sorry for, to feel pity for",
        "examples": [
          {
            "sentence": "К1алэм '''гук1эгъу фэсплъэгъугъ'''.",
            "translation": "'''I pitied''' the boy"
          }
        ]
      },
      {
        "meaning": "to have mercy upon",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын",
      "гуегъун"
    ],
    "type": "verb",
    "ipa": "ɡʷət͡ʃʼaʁʷ faɬaʁʷən"
  },
  {
    "word": "гук1эгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pity",
        "examples": [
          {
            "sentence": "К1алэм '''гук1эгъу''' фэсплъэгъугъ.",
            "translation": "To feel '''pity''' for the boy."
          }
        ]
      },
      {
        "meaning": "mercy",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷət͡ʃʼaʁʷ"
  },
  {
    "word": "гур егъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гуегъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьасын"
    ],
    "type": "verb",
    "ipa": "ɡʷər jaʁʷən"
  },
  {
    "word": "къэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to come from",
        "examples": [
          {
            "sentence": "Сэ Америкэ '''сыкъэк1ы'''",
            "translation": "'''I am from''' America."
          },
          {
            "sentence": "Жьыбгъэр хы пак1эм '''къэк1ы'''",
            "translation": "The wind '''is coming from''' the sea."
          },
          {
            "sentence": "Тыдэ '''укъэк1ыгъ'''?",
            "translation": "'''From''' where '''you came'''?"
          }
        ]
      },
      {
        "meaning": "to be grown (hair)",
        "examples": [
          {
            "sentence": "К1алэм ышъхьац бащэу '''къык1ыгъэ'''",
            "translation": "The boy's hair '''grew''' to much."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "qat͡ʃʼəm"
  },
  {
    "word": "гум рихьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу рихьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷərəjħən"
  },
  {
    "word": "гу рихьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to like",
        "examples": [
          {
            "sentence": "'''Сыгу рехьы''' къап1орэ",
            "translation": "'''I like''' what you're saying."
          },
          {
            "sentence": "Мыр '''шъугу рехьа?'''",
            "translation": "Do you like this? (said to plural)"
          },
          {
            "sentence": "К1алэм орэдыр '''ыгу рехьэ'''",
            "translation": "The boy '''is liking''' the song."
          },
          {
            "sentence": "Мы сурэтэр '''сгу рихьагъ''",
            "translation": "'''I liked''' this picture."
          }
        ]
      }
    ],
    "synonyms": [
      "к1эсэн",
      "ш1улъэгъун",
      "гопэн"
    ],
    "type": "verb",
    "ipa": "ɡʷə riħan"
  },
  {
    "word": "гурихьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу рихьан\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷərəjħən"
  },
  {
    "word": "гурихьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу рихьан\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɡʷərəjħən"
  },
  {
    "word": "ш1улъэгъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гу ш1у лъэгъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʃʷʼəɬaʁʷən"
  },
  {
    "word": "гу тепсэфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to in peace due to",
        "examples": [
          {
            "sentence": "Мощ '''сыгу тепсафэ'''",
            "translation": "'''I am in peace due to''' that person."
          },
          {
            "sentence": "Экзамынэу къытатыщтыр '''сыгу тепсафэрэп'''",
            "translation": "'''I am not in peace with''' the exam they gonna give us."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷə tapsafan"
  },
  {
    "word": "псынк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hurry up, to hasten",
        "examples": []
      }
    ],
    "synonyms": [
      "гузэжъон",
      "ш1эхын"
    ],
    "type": "verb",
    "ipa": "psənt͡ʃʼan"
  },
  {
    "word": "фэгу1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to worry for/about someone",
        "examples": [
          {
            "sentence": "Сиянэ ипсэуагъэ '''сыфэгуа1э'''",
            "translation": "'''I worry for''' my mother's health condition."
          },
          {
            "sentence": "Тыдэ ушы1агъ? '''Усфэгуа1эгъагъ'''",
            "translation": "Where were you? '''I was worried about you'''."
          },
          {
            "sentence": "Л1ым '''уфэго1эн''' ищык1агъэп",
            "translation": "'''You''' don't need '''to worry''' about the man."
          },
          {
            "sentence": "Шъо '''шъукъэсфэмыгуа1''', сэ тестым сыкъикъыт",
            "translation": "'''Don't worry about me (said to plural)''', i will pass the test."
          }
        ]
      }
    ],
    "synonyms": [
      "фэгумэк1ын"
    ],
    "type": "verb",
    "ipa": "faɡʷaʔan"
  },
  {
    "word": "е1унк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"1унк1эн\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гук1эн",
      "1унк1эн",
      "т1ыргун"
    ],
    "type": "verb",
    "ipa": "jaʔʷənt͡ʃʼən"
  },
  {
    "word": "тегъогъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(Kfar Kama dialect) to bark on",
        "examples": [
          {
            "sentence": "Хьэр к1алэм '''тэгъуагъо'''",
            "translation": "The dog '''is barking on''' the boy."
          },
          {
            "sentence": "Хьэр '''къэстэгъуагъо'''",
            "translation": "The dog '''is barking on us'''."
          }
        ],
        "tags": [
          "Kfar Kama dialect"
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁʷaʁʷan"
  },
  {
    "word": "табэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "frying pan (long-handled, shallow pan used for frying food)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "taːba"
  },
  {
    "word": "щыуаныжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cooking pot (traditionally made of cast iron)",
        "examples": [],
        "tags": [
          "traditionally made of cast iron"
        ]
      },
      {
        "meaning": "cast iron",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕəwaːnəʐ"
  },
  {
    "word": "ощык1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "axe handle, helve",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "waɕət͡ʃʼ"
  },
  {
    "word": "къиныгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hardship",
        "examples": []
      },
      {
        "meaning": "time of difficulty",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qəjnəʁʷ"
  },
  {
    "word": "тепщэч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "plate",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "tajpɕat͡ʃ"
  },
  {
    "word": "шъхьадж",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "everyone; each one; every",
        "examples": [
          {
            "sentence": "'''Шъхьадж''' и1офш1эн гъэнэфэн фае",
            "translation": "'''Evey one's''' assignment should be determined."
          },
          {
            "sentence": "'''Шъхьадж''' зыфэгъэсагъэм тетэу 1оф еш1э",
            "translation": "'''Evey one''' does the job the trained for."
          }
        ]
      }
    ],
    "type": "pron",
    "ipa": "ʂħaːd͡ʒ"
  },
  {
    "word": "фэгъэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to train someone for",
        "examples": [
          {
            "sentence": "К1алэр экзамыным '''фагъэсэ'''",
            "translation": "'''They train''' the boy '''for''' the exam."
          },
          {
            "sentence": "Дзак1охэр заом '''фагъэсэх'''",
            "translation": "'''They train''' the soldiers '''for''' the war."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "faʁasan"
  },
  {
    "word": "зыфэгъэсэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to train for; to practice] for",
        "examples": [
          {
            "sentence": "К1алэм экзамыным '''зыфегъасэ'''",
            "translation": "The boy '''practices''' for the exam."
          },
          {
            "sentence": "Шъхьадж '''зыфэгъэсагъэм''' тетэу 1оф еш1э",
            "translation": "Evey one does the job they '''trained for'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "zəfaʁasan"
  },
  {
    "word": "къумбыл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bomb",
        "examples": [
          {
            "sentence": "'''Къумбыл''' гъоч1эгъым ч1адзи къагъэуагъ",
            "translation": "They threw '''a bomb''' under the cave and made it explode."
          }
        ]
      }
    ],
    "type": "noun",
    "ipa": "qʷəmbəɮ"
  },
  {
    "word": "пэрыохъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "obstacle, impediment",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "parəwaχʷ"
  },
  {
    "word": "гъэсымэджэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sick",
        "examples": [
          {
            "sentence": "Жьыбгъэ чъы1эм '''угъэсымэджэщт'''",
            "translation": "The cold wind '''will make you sick'''."
          },
          {
            "sentence": "К1алэр псы ш1оим '''ыгъэсымэджагъ'''",
            "translation": "The dirty water '''made''' the boy '''sick'''."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэсмэгьэн"
    ],
    "type": "verb",
    "ipa": "ʁasmad͡ʒan"
  },
  {
    "word": "зэпыгъэчъыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smelt",
        "examples": []
      },
      {
        "meaning": "to weld; to join two materials (especially two metals) together by applying heat, pressure and filler, either separately or in any combination.",
        "examples": [
          {
            "sentence": "Л1ым гъуч1ыхэр '''зэпегъэчъыхьэх'''",
            "translation": "The man '''welds''' the metals."
          }
        ]
      }
    ],
    "synonyms": [
      "зэпыгъэжъэн",
      "зэпыгъэшхэн"
    ],
    "type": "verb",
    "ipa": "zapəʁat͡ʂəħaːn"
  },
  {
    "word": "зэпыгъэжъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smelt",
        "examples": []
      },
      {
        "meaning": "to weld; to join two materials (especially two metals) together by applying heat, pressure and filler, either separately or in any combination.",
        "examples": [
          {
            "sentence": "Л1ым гъуч1ыхэр '''зэпегъажъэ'''",
            "translation": "The man '''welds''' the metals."
          }
        ]
      }
    ],
    "synonyms": [
      "зэпыгъэчъыхьан",
      "зэпыгъэшхэн"
    ],
    "type": "verb",
    "ipa": "zapəʁaʐan"
  },
  {
    "word": "зэпыгъэшхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smelt",
        "examples": []
      },
      {
        "meaning": "to weld; to join two materials (especially two metals) together by applying heat, pressure and filler, either separately or in any combination.",
        "examples": [
          {
            "sentence": "Л1ым гъуч1ыхэр '''зэпегъашхэх'''",
            "translation": "The man '''welds''' the metals."
          }
        ]
      }
    ],
    "synonyms": [
      "зэпыгъэчъыхьан"
    ],
    "type": "verb",
    "ipa": "zapəʁaʃxan"
  },
  {
    "word": "пытэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to fall down successively or gradually from",
        "examples": [
          {
            "sentence": "Пхъэшъхьэмышъхьэр чъыгым '''пытэкъугъэх'''",
            "translation": "Fruits '''fell down from''' the tree."
          },
          {
            "sentence": "Чэтыум ыпкъы цыхэр '''къыпытэкъух'''",
            "translation": "Hairs '''drop down from''' the cat's body."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pətaqʷən"
  },
  {
    "word": "пыгъэтэкъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something to fall down successively or gradually from",
        "examples": [
          {
            "sentence": "Пхъэшъхьэмышъхьэр чъыгым л1ым '''пегъэтэкъугъэх'''",
            "translation": "The man '''makes''' the fruits '''fall down from''' the tree."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "pəʁataqʷən"
  },
  {
    "word": "телъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to put something on; to place something on",
        "examples": [
          {
            "sentence": "Нанэм шхыныхэр 1анэм '''къытелъхьагъэх'''",
            "translation": "Mom '''put''' the foods on the table."
          },
          {
            "sentence": "1офы бэу еджап1эмк1э '''къэсталъхьагъ'''",
            "translation": "'''They gave me''' a lot of tasks in school."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajɬħaːn"
  },
  {
    "word": "тхъэжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to become happy",
        "examples": [
          {
            "sentence": "К1алэр '''мэтхъэжьы'''",
            "translation": "The boy '''is becoming happy'''."
          },
          {
            "sentence": "К1алэр '''мэтхъэжьэгъ''' сыкъэк1уагъи",
            "translation": "The boy '''is was happy''' because I came."
          },
          {
            "sentence": "К1алэр Томым иунэ ши '''мэтхъэжьыт'''",
            "translation": "The boy '''is will become happy''' if you take him to Tom's house."
          },
          {
            "sentence": "К1алэр '''мэтхъэжьыжьрэп'''",
            "translation": "The boy '''is isn't becoming happy anymore'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tχaʑən"
  },
  {
    "word": "тегъэт1ысхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone sit down on",
        "examples": [
          {
            "sentence": "К1элэц1ык1ухэр '''атегъэт1ысхьэх''' пхъэнт1эк1ухэмэ",
            "translation": "'''Sit''' the children '''down on''' the chairs."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁatʼəsħaːn"
  },
  {
    "word": "т1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to be dripping (sticky substances like honey, cake and color liquid)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tʼatʼan"
  },
  {
    "word": "тэт1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"тет1эт1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tatʼatʼan"
  },
  {
    "word": "тегъэт1эт1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drip (sticky substance) on",
        "examples": [
          {
            "sentence": "Шхынэр '''темыгъэт1ат1эу''' шхы.",
            "translation": "Eat without '''dripping''' the food."
          },
          {
            "sentence": "К1элэц1ык1ум шъоур '''тегъэт1ат1э''' ч1ыгум.",
            "translation": "The little kid '''spills a bit of''' honey on the floor."
          },
          {
            "sentence": "Ушхырэм нахь бэ уиджанэ '''тебэгъэт1ат1э'''.",
            "translation": "'''You are spilling''' more food than you eat."
          },
          {
            "sentence": "К1алэм дэпкъэу ылэрэм нахь бэ шъо '''тегъэт1ат1э'''.",
            "translation": "The boy '''is spilling''' more color than he uses on the wall."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʁajtʼatʼan"
  },
  {
    "word": "гъэхьазырын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэухьазырын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ħaːzərən"
  },
  {
    "word": "гонахь гъэхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to sin",
        "examples": [
          {
            "sentence": "К1алэм '''гонахь егъахъэ'''",
            "translation": "The boy '''is sinning'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ɡʷanaːh ʁaχan"
  },
  {
    "word": "егъэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to deliver to, to send to",
        "examples": [
          {
            "sentence": "К1алэм письмо тхи пшъашъэм '''фигъэхьыгъ'''",
            "translation": "The boy wrote a letter and '''sent''' it to the girl."
          },
          {
            "sentence": "Джэуап '''угъэхьыжьыгъа?'''",
            "translation": "'''Did you send''' a response?"
          }
        ]
      },
      {
        "meaning": "to make someone deliver something to",
        "examples": [
          {
            "sentence": "К1алэм уатэр '''къезгъэхьыщт'''",
            "translation": "'''I will make''' the boy '''bring''' hammer."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁaħən"
  },
  {
    "word": "гъэтэрэзыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэтэрэзын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "гъэцэк1эжьын",
      "гъэтэрэзын",
      "ш1ыжьын"
    ],
    "type": "verb",
    "ipa": "ʁat͡sat͡ʃaʑən"
  },
  {
    "word": "тегъэц1ырэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spray burst liquid on to (usually from a pile)",
        "examples": [
          {
            "sentence": "Къэгъагъэхэмэ псы '''атегъэц1ыр'''",
            "translation": "(S)he '''pours''' water into the flowers."
          },
          {
            "sentence": "К1алэм ч1ыгум '''тегъэц1ырэ'''",
            "translation": "The boy is '''spraying''' on the group."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʁat͡sʼəran"
  },
  {
    "word": "гъэкьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэчэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁakʲan"
  },
  {
    "word": "гъэшъыхъыгъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "low; lowered",
        "examples": [
          {
            "sentence": "1анэр нахь '''гъэшъхъыгъэу''' ш1ы",
            "translation": "Make the table more '''low'''."
          },
          {
            "sentence": "1анэр '''гъэшъхъыгъ'''",
            "translation": "The table '''is low'''."
          }
        ]
      }
    ],
    "synonyms": [
      "лъхъанчэ"
    ],
    "type": "adj",
    "ipa": "ʁaʂχəʁa"
  },
  {
    "word": "лъхъанчэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "low",
        "examples": [
          {
            "sentence": "1анэр нахь '''лъхъанчэу''' ш1ы",
            "translation": "Make the table more '''low'''."
          },
          {
            "sentence": "1анэр '''лъхъанчэ'''",
            "translation": "The table '''is low'''."
          },
          {
            "sentence": "пхъэнт1эк1у '''лъхъанчэ''' ышIыгъ",
            "translation": "He made a '''low''' chair."
          }
        ]
      }
    ],
    "synonyms": [
      "гъэшъыхъыгъ"
    ],
    "type": "adj",
    "ipa": "ɬχaːnt͡ʃa"
  },
  {
    "word": "егъэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lower something",
        "examples": [
          {
            "sentence": "Лъэоир '''къегъэх'''",
            "translation": "'''Lower''' the ladder."
          },
          {
            "sentence": "'''Зежъугъэх'''",
            "translation": "'''Lower yourselves'''."
          }
        ]
      }
    ],
    "synonyms": [
      "къегъэ1ыхын",
      "къегъэт1ыхын",
      "ет1упщыхьахын",
      "гъэшъхъын"
    ],
    "type": "verb",
    "ipa": "jaʁaxən"
  },
  {
    "word": "ет1упщыхьахын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to lower something",
        "examples": []
      }
    ],
    "synonyms": [
      "къегъэ1ыхын",
      "къегъэт1ыхын",
      "гъэшъхъын",
      "егъэхын"
    ],
    "type": "verb",
    "ipa": "jatʷʼpɕəħaːxən"
  },
  {
    "word": "къегъэт1ыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something downward",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1упщыхьахын",
      "гъэшъхъын",
      "егъэхын"
    ],
    "type": "verb",
    "ipa": "qajʁatʼəxən"
  },
  {
    "word": "къегъэ1ыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop something downward",
        "examples": []
      }
    ],
    "synonyms": [
      "ет1упщыхьахын",
      "гъэшъхъын",
      "егъэхын"
    ],
    "type": "verb",
    "ipa": "qajʁatʼəxən"
  },
  {
    "word": "ещэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take someone downward (to a lower elevation)",
        "examples": [
          {
            "sentence": "Тучаным '''ещэх''' к1алэр",
            "translation": "'''Take''' the boy to the shop (in case the shop is in a lower elevation)."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaɕaxən"
  },
  {
    "word": "дыкъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to freeze, to congeal",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "dəqən"
  },
  {
    "word": "еук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to curse; to damn",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawət͡ʃʼən"
  },
  {
    "word": "сандыкъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "box",
        "examples": []
      }
    ],
    "synonyms": [
      "пхъуант",
      "къэмлан"
    ],
    "type": "noun",
    "ipa": "saːndəq"
  },
  {
    "word": "назэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "squint-eyed, cross-eyed",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "naːza"
  },
  {
    "word": "гъэсэпэтхыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "moral of the story",
        "examples": []
      },
      {
        "meaning": "instructive lesson",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁasapatxəda"
  },
  {
    "word": "лъэугъуае",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "staircase",
        "examples": []
      },
      {
        "meaning": "ladder",
        "examples": []
      }
    ],
    "synonyms": [
      "дэк1ояп1э",
      "лъэой"
    ],
    "type": "noun",
    "ipa": "ɬawʁʷaːja"
  },
  {
    "word": "ипэрэгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "last time; previously",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jəparaʁʷəm"
  },
  {
    "word": "къа1орэгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "next time",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "qaːkʷʼaʁʷəm"
  },
  {
    "word": "ипэгъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "last time; previously",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jəpaʁʷəm"
  },
  {
    "word": "къа1огъум",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "next time",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "qaːkʷʼaʁʷəm"
  },
  {
    "word": "псчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cough",
        "examples": []
      }
    ],
    "synonyms": [
      "пскэн",
      "пчэн"
    ],
    "type": "verb",
    "ipa": "pst͡ʃan"
  },
  {
    "word": "пчэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cough",
        "examples": []
      }
    ],
    "synonyms": [
      "пскэн",
      "псчэн"
    ],
    "type": "verb",
    "ipa": "pt͡ʃan"
  },
  {
    "word": "гъэпк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"гъэпск1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapt͡ʃʼən"
  },
  {
    "word": "мэфэл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "long time",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "mafaɮ"
  },
  {
    "word": "ехьыжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to carry (for a while; on foot)",
        "examples": []
      },
      {
        "meaning": "to start a trend",
        "examples": []
      }
    ],
    "synonyms": [
      "къехьыжьэн"
    ],
    "type": "verb",
    "ipa": "jaħəʑan"
  },
  {
    "word": "къехьыжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ехьыжьэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajħəʑan"
  },
  {
    "word": "к1эш1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to construct under",
        "examples": [
          {
            "sentence": "Л1ым пхъэ унэ чъыгы ч1эгъым '''к1иш1ыхьагъ'''",
            "translation": "The man '''constructed''' a wooden house under the tree."
          }
        ]
      },
      {
        "meaning": "to make under",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼaʃʼəħaːn"
  },
  {
    "word": "ш1агъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wonder",
        "examples": []
      },
      {
        "meaning": "wonderful, marvelous",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʼaːʁʷa"
  },
  {
    "word": "1ашк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "clod",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔaːʃt͡ʃʼ"
  },
  {
    "word": "ушэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to examine, to check, to verify, to test",
        "examples": []
      }
    ],
    "synonyms": [
      "плъэк1ун"
    ],
    "type": "verb",
    "ipa": "wəʃatən"
  },
  {
    "word": "дзон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to dam, to dam up",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "d͡ʒʷan"
  },
  {
    "word": "дэгъэтк1он",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to drop liquid into something",
        "examples": [
          {
            "sentence": "К1алэм пшъашъэм ытхьак1умэ псы фабэ т1эк1у '''дигъатк1о'''",
            "translation": "The boy is '''dropping''' little hot water '''into''' the girl's ear."
          },
          {
            "sentence": "Тхьак1умэм '''дэгъатк1у'''",
            "translation": "'''Drop''' (some liquid) '''into''' the ear."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁatkʷʼan"
  },
  {
    "word": "уцы такъыр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pill",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wət͡sə taːqər"
  },
  {
    "word": "пхъэмбай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "coffin",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχambaːj"
  },
  {
    "word": "дэгъэбылъхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to hide something in",
        "examples": [
          {
            "sentence": "К1алэм шагум джэгуалъэр '''дегъэбылъхьэ'''",
            "translation": "The boy '''is hiding''' the toy in the yard."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "daʁabəɬħan"
  },
  {
    "word": "фэдэгъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to reduce a debt or a punishment",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "fadaʁazən"
  },
  {
    "word": "хьадэгъущ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьадэгъущэ\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːdaʁʷəɕ"
  },
  {
    "word": "хьадэгъущэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "killing bullet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːdaʁʷəɕa"
  },
  {
    "word": "утхыпк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shake out",
        "examples": []
      },
      {
        "meaning": "to agitate a piece of cloth or other flexible material in order to remove dust, or to try to make it smooth and flat",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wətxəpt͡ʃʼən"
  },
  {
    "word": "дэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къыдэхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "daχʷən"
  },
  {
    "word": "къыдэхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to born with",
        "examples": [
          {
            "sentence": "К1алэр узым '''къыдэхъугъ'''",
            "translation": "The boy '''born with''' the illness."
          }
        ]
      },
      {
        "meaning": "to born alongside",
        "examples": []
      },
      {
        "meaning": "to work out; to result in a satisfactory situation; to turn out well",
        "examples": [
          {
            "sentence": "Л1ым 1офы к1эр  '''къыдэхъугъ'''",
            "translation": "The man's new job '''worked out for him'''."
          }
        ]
      },
      {
        "meaning": "to come true",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qədaχʷən"
  },
  {
    "word": "гъурк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "severe frost; hard frost; sharp frost; biting frost",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷərt͡ʃʼ"
  },
  {
    "word": "тауташ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "precipice, abyss, chasm",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "taːwtaːɕ"
  },
  {
    "word": "щайныч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kettle",
        "examples": []
      },
      {
        "meaning": "teapot",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕaːjnət͡ʃ"
  },
  {
    "word": "лэгъуп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "copper boiler",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "laʁʷəp"
  },
  {
    "word": "къошын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "jug, pitcher",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷaʃən"
  },
  {
    "word": "пхъэчай",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tub, vat",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχat͡ʃaːj"
  },
  {
    "word": "къещэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap",
        "examples": []
      },
      {
        "meaning": "to surround",
        "examples": []
      }
    ],
    "synonyms": [
      "къещыхьэк1ын",
      "к1оц1ыщыхьан"
    ],
    "type": "verb",
    "ipa": "qajɕat͡ʃʼən"
  },
  {
    "word": "къещыхьэк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to wrap",
        "examples": []
      },
      {
        "meaning": "to surround",
        "examples": []
      }
    ],
    "synonyms": [
      "къещэк1ын",
      "к1оц1ыщыхьан"
    ],
    "type": "verb",
    "ipa": "qajɕaħat͡ʃʼən"
  },
  {
    "word": "шык1адзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweat-cloth (for horse riding)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃət͡ʃʼaːd͡za"
  },
  {
    "word": "к1адзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sweat-cloth",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼaːd͡za"
  },
  {
    "word": "упк1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "felt",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "wəpt͡ʃʼa"
  },
  {
    "word": "лъэгонджэмышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "kneecap",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaɡʷand͡ʒaməʂħa"
  },
  {
    "word": "е1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to touch",
        "examples": []
      },
      {
        "meaning": "to push with might",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jaʔan"
  },
  {
    "word": "цак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "edge",
        "examples": []
      }
    ],

    "cognate": "нэз",
    "type": "noun",
    "ipa": "t͡saːt͡ʃʼa"
  },
  {
    "word": "цак1ьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"цак1э\"",
        "examples": []
      }
    ],

    "cognate": "нэз",
    "type": "noun",
    "ipa": "t͡saːkʲʼa"
  },
  {
    "word": "къэц1этхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1этхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qat͡sʼatxən"
  },
  {
    "word": "тец1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spread (on), to smear (with), to daub (with)",
        "examples": [
          {
            "sentence": "Хьалыгъу бзыгъэм шъоу '''тец1элэн'''",
            "translation": "'''To smear''' honey on a sliced bread."
          },
          {
            "sentence": "Хьалыгъум шъоу '''тец1элагъ'''",
            "translation": "The bread '''is smeared with''' honey."
          }
        ]
      }
    ],
    "synonyms": [
      "тефэхьын",
      "щыфэн"
    ],
    "type": "verb",
    "ipa": "tajt͡sʼaɮan"
  },
  {
    "word": "тефэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spread (on), to smear (with), to daub (with)",
        "examples": [
          {
            "sentence": "Хьалыгъу бзыгъэм шъоу '''тефэхьын'''",
            "translation": "'''To smear''' honey on a sliced bread."
          }
        ]
      }
    ],
    "synonyms": [
      "тец1элэн",
      "щыфэн"
    ],
    "type": "verb",
    "ipa": "tajfaħən"
  },
  {
    "word": "п1эшъхьагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "headboard",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʼaʂħaːʁ"
  },
  {
    "word": "хьэгъош1ыгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "activity",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʁʷaʃʼəʁʷ"
  },
  {
    "word": "гузэжъогъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "urgent",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɡʷəzaʒʷaʁʷ"
  },
  {
    "word": "елбэтэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "with lightning speed",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "jaɮbataw"
  },
  {
    "word": "щыфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spread (on), to smear (with), to daub (with)",
        "examples": [
          {
            "sentence": "Крем цуакъэм '''щыфэн'''",
            "translation": "'''To smear''' cream on the shoe."
          }
        ]
      }
    ],
    "synonyms": [
      "тефэхьын",
      "тец1элэн"
    ],
    "type": "verb",
    "ipa": "ɕəfan"
  },
  {
    "word": "еупц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to throw and smash an object against something forcefully",
        "examples": []
      },
      {
        "meaning": "to stain forcefully, to make a mark on a surface or a piece of clothing forcefully",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawpt͡sʼən"
  },
  {
    "word": "упц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"еупц1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wpt͡sʼən"
  },
  {
    "word": "джанщэрэхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "great wheel (often appears in Nart Sega stories)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "d͡ʒaːnɕaraχ"
  },
  {
    "word": "егъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to direct an object toward something that emits heat",
        "examples": [
          {
            "sentence": "У1э маш1ом '''егъэу'''",
            "translation": "'''Direct''' your arm '''toward''' the fire [to warm it up]."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁawən"
  },
  {
    "word": "зегъэун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to direct oneself toward something that emits heat",
        "examples": [
          {
            "sentence": "Маш1ом л1ым '''зырегъэу'''",
            "translation": "The man '''directs himself toward''' the fire [to warm up]."
          },
          {
            "sentence": "Тыгъэм '''зегъэун'''",
            "translation": "'''To direct oneself toward''' the sun [to warm up]."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "jaʁawən"
  },
  {
    "word": "ш1обзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cut",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃʷʼabzə"
  },
  {
    "word": "1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mouth",
        "examples": []
      },
      {
        "meaning": "opening, aperture, hole",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷə"
  },
  {
    "word": "хъупц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "itch",
        "examples": [
          {
            "sentence": "'''Хъупц1ым''' егъал1э",
            "translation": "'''The itch''' is killing him."
          }
        ]
      }
    ],
    "synonyms": [
      "шъохъу-т1ыхъу"
    ],
    "cognate": "хъу",
    "type": "noun",
    "ipa": "χʷəpt͡sʼ"
  },
  {
    "word": "зэт1эхъужьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зэт1эхъун\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zatʼaχʷəʑən"
  },
  {
    "word": "хъупц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to feel itchy",
        "examples": [
          {
            "sentence": "'''Сэхъупц1ы'''",
            "translation": "'''I am feeling itchy'''"
          },
          {
            "sentence": "Cныбэ '''мэхъупц1ы'''",
            "translation": "my stomach '''itch's'''"
          },
          {
            "sentence": "Уемыт1эхъу ащ '''хъупц1ыми'''",
            "translation": "Don't scratch that '''even if it's itchy'''."
          }
        ]
      }
    ],
    "synonyms": [
      "хъун"
    ],
    "type": "verb",
    "ipa": "χʷəpt͡sʼən"
  },
  {
    "word": "шъохъу-т1ыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "scabies, skin itch",
        "examples": []
      }
    ],
    "synonyms": [
      "хъупц1"
    ],
    "cognate": "хъу",
    "type": "noun",
    "ipa": "ʃʷaχʷ tʼəχʷ"
  },
  {
    "word": "къурбэкъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къурбэкъэй\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷərbaqəj"
  },
  {
    "word": "къурбэкъэй",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "larynx",
        "examples": []
      }
    ],
    "synonyms": [
      "къурбычый"
    ],
    "type": "noun",
    "ipa": "qʷərbaqaj"
  },
  {
    "word": "лъэк1эп1ащ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bow-legged; bowlegged, bandy-legged",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ɬat͡ʃʼapʼaːɕ"
  },
  {
    "word": "1анлъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "trough",
        "examples": []
      },
      {
        "meaning": "washing tub",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэкъуашъо"
    ],
    "type": "noun",
    "ipa": "ʔaːnɬa"
  },
  {
    "word": "хьилэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "guileful, insidious, perfidious, crafty, treacherous",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħəjla"
  },
  {
    "word": "тхьагъэпц1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dishonest",
        "examples": []
      },
      {
        "meaning": "munafiq",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "tħaːʁapt͡sʼ"
  },
  {
    "word": "зы1эк1эубытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get ahold of; to get hold of",
        "examples": []
      }
    ],
    "synonyms": [
      "убытын",
      "етхъон",
      "пхъотэн"
    ],
    "type": "verb",
    "ipa": "zəʔat͡ʃʼawbətan"
  },
  {
    "word": "етхъон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grasp, to grab, to seize, to snap, to snatch, to catch, to catch hold of",
        "examples": []
      }
    ],
    "synonyms": [
      "зы1эк1эубытэн",
      "убытын",
      "пхъотэн"
    ],
    "type": "verb",
    "ipa": "jatχʷan"
  },
  {
    "word": "пхъотэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to grasp, to grab, to seize, to snap, to snatch, to catch, to catch hold of",
        "examples": []
      }
    ],
    "synonyms": [
      "зы1эк1эубытэн",
      "етхъон",
      "убытын"
    ],
    "type": "verb",
    "ipa": "pχʷatan"
  },
  {
    "word": "уарзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "straw",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэуарзэ"
    ],
    "type": "noun",
    "ipa": "waːrza"
  },
  {
    "word": "хьэуарзэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "straw",
        "examples": []
      }
    ],
    "synonyms": [
      "уарзэ"
    ],
    "type": "noun",
    "ipa": "ħawaːrza"
  },
  {
    "word": "зыгъэпк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"зыгъэпск1ын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zʁapst͡ʃʼən"
  },
  {
    "word": "пскэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to cough",
        "examples": []
      }
    ],
    "synonyms": [
      "псчэн",
      "пчэн"
    ],
    "type": "verb",
    "ipa": "pskan"
  },
  {
    "word": "къэш1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къэш1эн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qaʃʼan"
  },
  {
    "word": "чэтапэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tip of a sword",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃataːpa"
  },
  {
    "word": "т1олъфэныкъох",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"т1олъфэныкъо\"",
        "examples": []
      }
    ],
    "synonyms": [
      "т1уазэх"
    ],
    "type": "noun",
    "ipa": "tʷʼaːzax"
  },
  {
    "word": "т1уазэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twin",
        "examples": [
          {
            "sentence": "Сэрэ сишырэ '''тыт1уаз'''",
            "translation": "Me and my brother are '''twins'''."
          },
          {
            "sentence": "Томрэ Джонрэ '''зэт1уазэх'''",
            "translation": "Tom and John are '''twins'''."
          }
        ]
      }
    ],
    "synonyms": [
      "т1олъфэныкъо"
    ],
    "type": "noun",
    "ipa": "tʷʼaːza"
  },
  {
    "word": "т1олъфэныкъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twin",
        "examples": [
          {
            "sentence": "Сэрэ сишырэ '''тыт1олъфэныкъу'''",
            "translation": "Me and my brother are '''twins'''."
          },
          {
            "sentence": "Томрэ Джонрэ '''т1олъфэныкъох'''",
            "translation": "Tom and John are '''twins'''."
          }
        ]
      }
    ],
    "synonyms": [
      "т1уазэ"
    ],
    "type": "noun",
    "ipa": "tʷʼaɬfanəqʷa"
  },
  {
    "word": "жъэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "i wish; if only",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "ʐaw"
  },
  {
    "word": "щыгъэгуфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to earn trust",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕəʁaɡʷəfan"
  },
  {
    "word": "ужъунтхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spit",
        "examples": [
          {
            "sentence": "'''Умыужъунтх'''",
            "translation": "'''Don't spit'''."
          },
          {
            "sentence": "К1алэр '''мэужъунтхэ'''",
            "translation": "The boy '''is spitting'''."
          },
          {
            "sentence": "К1алэр чыжьэу '''мэужъунтхэшъу'''",
            "translation": "The boy '''can spit''' far away."
          }
        ]
      }
    ],
    "synonyms": [
      "жъутхэн"
    ],
    "cognate": "убжьытхэн",
    "type": "noun",
    "ipa": "wəʒʷəntxan"
  },
  {
    "word": "теужъутхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"теужъунтхэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajʒʷəntxan"
  },
  {
    "word": "теужъунтхэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spit upon",
        "examples": [
          {
            "sentence": "'''Утемыужъунтх'''",
            "translation": "'''Don't spit on it'''."
          },
          {
            "sentence": "К1алэр мыжъом '''теужъунтхэ'''",
            "translation": "The boy '''is spitting''' on the rock."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "tajʒʷəntxan"
  },
  {
    "word": "щыпын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to collect",
        "examples": [
          {
            "sentence": "К1алэм мыжъохэр '''ешыпых'''",
            "translation": "The boy '''gathers''' rocks."
          }
        ]
      }
    ],
    "synonyms": [
      "къэугъоин",
      "зэк1эугъоен",
      "зэхэугъоен",
      "угъоин"
    ],
    "type": "verb",
    "ipa": "ɕəpən"
  },
  {
    "word": "жьыбгъэ зы1угъэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get oneself into fresh air",
        "examples": [
          {
            "sentence": "К1алэм '''жьыбгъэ зы1уигъэунэу''' щагум ик1ыгъ",
            "translation": "The boy '''to get fresh air''' went outside."
          }
        ]
      }
    ],
    "synonyms": [
      "жьыбгъэ зы1угъэхьан",
      "жьы зы1угъэхьан",
      "жьы зы1угъэон"
    ],
    "type": "verb",
    "ipa": "ʑəbʁa zəʔʷəʁawan"
  },
  {
    "word": "жьыбгъэ зы1угъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get oneself into fresh air",
        "examples": [
          {
            "sentence": "К1алэм '''жьыбгъэ зы1уигъэхьанэу''' щагум ик1ыгъ",
            "translation": "The boy '''to get fresh air''' went outside."
          }
        ]
      }
    ],
    "synonyms": [
      "жьыбгъэ зы1угъэон",
      "жьы зы1угъэхьан",
      "жьы зы1угъэон"
    ],
    "type": "verb",
    "ipa": "ʑəbʁa zəʔʷəʁaħaːn"
  },
  {
    "word": "жьы зы1угъэхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get oneself into fresh air",
        "examples": [
          {
            "sentence": "К1алэм '''жьы зы1уигъэхьанэу''' щагум ик1ыгъ",
            "translation": "The boy '''to get fresh air''' went outside."
          }
        ]
      }
    ],
    "synonyms": [
      "жьыбгъэ зы1угъэхьан",
      "жьыбгъэ зы1угъэон",
      "жьы зы1угъэон"
    ],
    "type": "verb",
    "ipa": "ʑə zəʔʷəʁaħaːn"
  },
  {
    "word": "жьы зы1угъэон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get oneself into fresh air",
        "examples": [
          {
            "sentence": "К1алэм '''жьы зы1уигъэунэу''' щагум ик1ыгъ",
            "translation": "The boy '''to get fresh air''' went outside."
          }
        ]
      }
    ],
    "synonyms": [
      "жьыбгъэ зы1угъэхьан",
      "жьы зы1угъэхьан",
      "жьыбгъэ зы1угъэон"
    ],
    "type": "verb",
    "ipa": "ʑə zəʔʷəʁawan"
  },
  {
    "word": "угъонын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make a hole into",
        "examples": [
          {
            "sentence": "К1алэм кум ищэрыхъ '''ыугъоныгъ'''",
            "translation": "The boy '''made holes''' in the car's wheels."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "wəʁʷanən"
  },
  {
    "word": "бзэгухь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gossiper; a person who gossips",
        "examples": []
      },
      {
        "meaning": "informer",
        "examples": []
      },
      {
        "meaning": "snitcher",
        "examples": []
      },
      {
        "meaning": "rat (someone that tells other's secrets to an authority or an enemy)",
        "examples": []
      }
    ],
    "synonyms": [
      "бзэгуит1у",
      "бзэгук1ыхь",
      "бзэгут1аркъу",
      "бзэгуибл"
    ],
    "type": "noun",
    "ipa": "bzaɡʷəħ"
  },
  {
    "word": "бзэгухьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to gossip",
        "examples": []
      },
      {
        "meaning": "to snitch",
        "examples": []
      },
      {
        "meaning": "to rat (to tell other's secrets to an authority or an enemy)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "bzaɡʷəħən"
  },
  {
    "word": "1ор1отэжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "rumor, talk, hearsay",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷarʔʷataʑ"
  },
  {
    "word": "1оржъор",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twaddler, babbler",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1ал",
      "гущы1эрые",
      "жэмы1ан",
      "жэмачыу",
      "шъхьарыгущы1"
    ],
    "type": "noun",
    "ipa": "ʔʷarʒʷar"
  },
  {
    "word": "шъхьарыгущы1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "twaddler, babbler",
        "examples": []
      }
    ],
    "synonyms": [
      "гущы1ал",
      "1оржъор",
      "гущы1эрые",
      "жэмы1ан",
      "жэмачыу"
    ],
    "type": "noun",
    "ipa": "ʂħaːrəɡʷəɕəʔ"
  },
  {
    "word": "ш1ошъыхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ш1ошъ хъун\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ш1ош1у мэхъун"
    ],
    "type": "verb",
    "ipa": "ʃʷaʂ χʷən"
  },
  {
    "word": "ащ рик1орэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "about that",
        "examples": [
          {
            "sentence": "'''Ащ рик1орэр''' сыш1эрэп",
            "translation": "'''About that''', I don't know."
          }
        ]
      }
    ],
    "type": "phrase",
    "ipa": "aːɕ rəjkʷʼarar"
  },
  {
    "word": "сыд рик1орэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "what about",
        "examples": [
          {
            "sentence": "'''Сыд рик1орэр''' к1алэм сыш1эрэп",
            "translation": "I don't know '''what about''' the boy."
          }
        ]
      }
    ],
    "type": "phrase",
    "ipa": "səd rəjkʷʼarar"
  },
  {
    "word": "лыджын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get tickled",
        "examples": [
          {
            "sentence": "к1алэр '''мэлыджы'''",
            "translation": "The boy '''is being tickled'''."
          },
          {
            "sentence": "'''сэлыджы'''",
            "translation": "'''I am being tickled'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "maləd͡ʒən"
  },
  {
    "word": "тегубжыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get angry over someone",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "tajɡʷəbʒəħaːn"
  },
  {
    "word": "псэлъыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bridegroom; fiancé",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psaɬəχʷ"
  },
  {
    "word": "къулбастэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "djinn",
        "examples": []
      },
      {
        "meaning": "ghost",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəlbaːsta"
  },
  {
    "word": "пшъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "collar",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pʂaːpʼa"
  },
  {
    "word": "псынэкъуау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "winch (for the well); windlass",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "psənaqʷaːw"
  },
  {
    "word": "щык1агъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "need",
        "examples": []
      },
      {
        "meaning": "lack, deficiency, shortage",
        "examples": []
      }
    ],
    "synonyms": [
      "дагъо",
      "нэц1эк1"
    ],
    "type": "noun",
    "ipa": "ɕət͡ʃʼaːʁa"
  },
  {
    "word": "нэц1эк1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lack, deficiency, deficit, shortage",
        "examples": []
      },
      {
        "meaning": "defect, drawback, flaw",
        "examples": []
      }
    ],
    "synonyms": [
      "щык1агъэ",
      "дагъо"
    ],
    "type": "noun",
    "ipa": "nat͡sʼat͡ʃʼ"
  },
  {
    "word": "лъэгуц",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "In front of the entrance, the entrance door",
        "examples": []
      },
      {
        "meaning": "porch",
        "examples": []
      }
    ],
    "synonyms": [
      "пчъэ1упэ"
    ],
    "type": "noun",
    "ipa": "ɬaɡʷət͡s"
  },
  {
    "word": "хъонал",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "someone that swears a lot; a vulgar person",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "χʷanaːɮ"
  },
  {
    "word": "пхъэтэкъэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "stump",
        "examples": []
      },
      {
        "meaning": "blockhead",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "pχataqaʐ"
  },
  {
    "word": "к1этыку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "narrow corner",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼatəkʷ"
  },
  {
    "word": "къызыщылъэтын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(unexpectedly) to jump (up, onto, out of), to leap (on, into, onto), to get up quickly",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəzəɕəɬatən"
  },
  {
    "word": "хэ1ун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to prick, to sting",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʔʷən"
  },
  {
    "word": "гъофы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "brass",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʁʷafə"
  },
  {
    "word": "ялыегъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bestest, very best; most best",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "jaːɮəjaʁa"
  },
  {
    "word": "пстэуми афэмыдэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "special, detached, distinct",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "pəstawməj aːmafəda"
  },
  {
    "word": "анахьэу щыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bestest, very best; most best",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "aːnaːħaw ɕət"
  },
  {
    "word": "къуе-мый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fruit",
        "examples": []
      }
    ],
    "synonyms": [
      "пк1ышъхьэмышъхьэ"
    ],
    "type": "noun",
    "ipa": "qʷəja-məj"
  },
  {
    "word": "зышъутыпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to strip",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəʃʷətəpsʼən"
  },
  {
    "word": "щыхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take off (clothes)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɕəxən"
  },
  {
    "word": "щхыпц1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smile",
        "examples": []
      }
    ],
    "synonyms": [
      "гуш1опс1ын",
      "1угуш1ук1ын"
    ],
    "type": "verb",
    "ipa": "ɕxəpt͡sʼən"
  },
  {
    "word": "гуш1опс1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smile",
        "examples": []
      }
    ],
    "synonyms": [
      "щхыпц1ын",
      "1угуш1ук1ын"
    ],
    "type": "verb",
    "ipa": "ɡʷəʃʷʼapsʼən"
  },
  {
    "word": "1угуш1ук1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smile",
        "examples": []
      }
    ],
    "synonyms": [
      "гуш1опс1ын",
      "щхыпц1ын"
    ],
    "type": "verb",
    "ipa": "ʔʷɡʷəʃʼʷət͡ʃʼən"
  },
  {
    "word": "щхыпц1ы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smile",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕxəpt͡sʼ"
  },
  {
    "word": "къурбычый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "larynx",
        "examples": []
      }
    ],
    "synonyms": [
      "къурбэкъэй"
    ],
    "type": "noun",
    "ipa": "qʷərbət͡ʃəj"
  },
  {
    "word": "ц1элэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to soil, to dirty, to stain",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡sʼalan"
  },
  {
    "word": "упк1эн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mow, to cut",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəpt͡ʃʼan"
  },
  {
    "word": "уфэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to bend, to crook",
        "examples": []
      },
      {
        "meaning": "to tilt, to bend",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wfan"
  },
  {
    "word": "ц1ык1уинт1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very little",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1ык1унау"
    ],
    "type": "adj",
    "ipa": "t͡sʼəkʷʼəjntʼa"
  },
  {
    "word": "ц1ык1унау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very little",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1ык1уинт1э"
    ],
    "type": "adj",
    "ipa": "t͡sʼəkʷʼənaːw"
  },
  {
    "word": "т1эк1унит1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very small amount",
        "examples": []
      }
    ],
    "synonyms": [
      "т1эк1унау"
    ],
    "type": "adj",
    "ipa": "tʼakʷʼəjntʼa"
  },
  {
    "word": "т1эк1унау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "very small amount",
        "examples": []
      }
    ],
    "synonyms": [
      "т1эк1унит1э"
    ],
    "type": "adj",
    "ipa": "tʼakʷʼənaːw"
  },
  {
    "word": "пыл1ык1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to die slowly",
        "examples": []
      },
      {
        "meaning": "to die from bleeding",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pəɬʼət͡ʃʼən"
  },
  {
    "word": "шыпын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"щыпын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "къэугъоин",
      "зэк1эугъоен",
      "зэхэугъоен",
      "угъоин"
    ],
    "type": "verb",
    "ipa": "ʃəpən"
  },
  {
    "word": "гъэшъхъэшъхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to smash something into smithereens",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaʂχaʂχan"
  },
  {
    "word": "къашыкъгъуанэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "skimmer",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qaːʃəqʁʷaːna"
  },
  {
    "word": "къыдэгъэчъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "drawer",
        "examples": []
      }
    ],

    "cognate": "къыдэгъэж",
    "type": "noun",
    "ipa": "qədaʁat͡ʂ"
  },
  {
    "word": "пэш1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mimic; to imitate",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "paʃʼəʑən"
  },
  {
    "word": "зыпэш1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to imitate oneself to",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəpaʃʼəʑən"
  },
  {
    "word": "ыц1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"ц1эжьын\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ц1эжьын"
    ],
    "type": "verb",
    "ipa": "ət͡sʼaʑən"
  },
  {
    "word": "ц1эжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to get wasted from alcohol",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      },
      {
        "meaning": "(vulgar) to shit",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "synonyms": [
      "ыц1эжьын"
    ],
    "type": "verb",
    "ipa": "t͡sʼaʑən"
  },
  {
    "word": "хьэшъутыубат",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) bullshit; horseshit (nonsense)",
        "examples": [
          {
            "sentence": "К1алэм '''хьэшъутыубат''' къи1уатэрэр",
            "translation": "The boy talks '''nonsense'''."
          },
          {
            "sentence": "'''Хьэшъуты убат''' ар",
            "translation": "That's [[bullshit]]"
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "noun",
    "ipa": "ħaʃʷətəwəbaːt"
  },
  {
    "word": "хьэшъутыубэтагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьэшъутыубат\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaʃʷətə wəbaːt"
  },
  {
    "word": "хьэшъутыубэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) to bullshit; to talk bullshit; to talk horseshit (to talk nonsense)",
        "examples": [
          {
            "sentence": "К1алэм '''хьэшъуты еубатэ'''",
            "translation": "The boy '''talks nonsense'''."
          },
          {
            "sentence": "Сыд мы '''хьэшъуты убатэ'''",
            "translation": "What is '''this nonsense'''."
          },
          {
            "sentence": "'''Хьэшъуты уубатэ'''",
            "translation": "'''You talk nonsense'''."
          }
        ],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħaʃʷətə wəbatan"
  },
  {
    "word": "шъхьэубэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to talk nonsense",
        "examples": [
          {
            "sentence": "К1алэм '''шъхьэубатэ'''",
            "translation": "The boy '''talks nonsense'''."
          },
          {
            "sentence": "Сыд мы '''шъхьэубатэ'''",
            "translation": "What is '''this nonsense'''."
          },
          {
            "sentence": "'''шъхьэ уубатэ'''",
            "translation": "'''You talk nonsense'''."
          }
        ]
      }
    ],
    "type": "verb",
    "ipa": "ħaʃʷətə wəbatan"
  },
  {
    "word": "шъхьэ убэтэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэубэтэн\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ħaʃʷətə wəbatan"
  },
  {
    "word": "шъхьэубэтагъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"шъхьэубат\"",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħawbaːt"
  },
  {
    "word": "хьэкуаку",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьакуако\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħakʷaːkʷ"
  },
  {
    "word": "хьакуако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vagina",
        "examples": []
      }
    ],
    "synonyms": [
      "хьэк1ут1э"
    ],
    "cognate": "гудэ",
    "type": "noun",
    "ipa": "ħakʷaːkʷa"
  },
  {
    "word": "хьэк1ут1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "vagina",
        "examples": []
      }
    ],
    "synonyms": [
      "хьакуако"
    ],
    "cognate": "гудэ",
    "type": "noun",
    "ipa": "ħakʷʼətʼa"
  },
  {
    "word": "зыфэгъэгусэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take offense (at), to be offended (by), to feel hurt (by), to resent",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zəfaʁaɡʷəsan"
  },
  {
    "word": "гъэпыин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make someone an enemy",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapəjən"
  },
  {
    "word": "хэгъозэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get addict to",
        "examples": []
      },
      {
        "meaning": "to adapt to",
        "examples": []
      },
      {
        "meaning": "to acclimatize",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "xaʁʷazan"
  },
  {
    "word": "нэпэтелъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mask",
        "examples": []
      }
    ],
    "synonyms": [
      "нэ1урыхъу"
    ],
    "type": "noun",
    "ipa": "napatajɬħa"
  },
  {
    "word": "шъхьэтелъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "helmet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħatajɬħa"
  },
  {
    "word": "нэ1урыхъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mask",
        "examples": []
      }
    ],
    "synonyms": [
      "нэпэтелъхьэ"
    ],
    "type": "noun",
    "ipa": "naʔʷərəχʷ"
  },
  {
    "word": "нэгъэц1у",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"нэгъэц1ыу\"",
        "examples": []
      }
    ],
    "synonyms": [
      "ахъщэ"
    ],
    "cognate": "ахъшэ",
    "type": "noun",
    "ipa": "naʁat͡sʼəw"
  },
  {
    "word": "зэлъежьэжь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "chase (A children's game where one player chases another)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "zajɬajʑaʑ"
  },
  {
    "word": "къан-къангъэбылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hide and seek (game)",
        "examples": []
      }
    ],
    "synonyms": [
      "кукунэгъэбылъ",
      "къангъэбылъ"
    ],
    "type": "noun",
    "ipa": "qaːn qaːnʁabəɬ"
  },
  {
    "word": "къангъэбылъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hide and seek (game)",
        "examples": []
      }
    ],
    "synonyms": [
      "кукунэгъэбылъ",
      "къан-къангъэбылъ"
    ],
    "type": "noun",
    "ipa": "qaːnʁabəɬ"
  },
  {
    "word": "гъэщын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to twist",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁaɕən"
  },
  {
    "word": "чынасыу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mercury",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃənaːsəw"
  },
  {
    "word": "шъхьэфэш1ужь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "mercenary, selfish, self-interested, greedy",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂħafaʃʷʼəʑ"
  },
  {
    "word": "шъхьэм иубытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to memorize",
        "examples": []
      }
    ],
    "synonyms": [
      "гум иубытэн"
    ],
    "type": "verb",
    "ipa": "ʂħam jəwbətan"
  },
  {
    "word": "гум иубытэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to memorize",
        "examples": []
      }
    ],
    "synonyms": [
      "шъхьэм иубытэн"
    ],
    "type": "verb",
    "ipa": "ɡʷəmm jəwbətan"
  },
  {
    "word": "лъэкур",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "undersized, shortish, dwarf",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬakʷər"
  },
  {
    "word": "ц1ык1ужъый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "tiny, wee",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡sʼəkʷʼəʐəj"
  },
  {
    "word": "чъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to harden",
        "examples": []
      },
      {
        "meaning": "to freeze",
        "examples": []
      }
    ],
    "synonyms": [
      "пытэ хъун"
    ],
    "type": "verb",
    "ipa": "t͡ʂən"
  },
  {
    "word": "пытэ хъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to harden",
        "examples": []
      }
    ],
    "synonyms": [
      "чъын"
    ],
    "type": "verb",
    "ipa": "pəta χʷən"
  },
  {
    "word": "зэпырычъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to run across",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "zapərət͡ʂən"
  },
  {
    "word": "зэфэш1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to close something (to move so that an opening is closed)",
        "examples": []
      }
    ],
    "synonyms": [
      "игъэсэн",
      "фэш1ын"
    ],
    "cognate": "хуэщ1ын",
    "type": "verb",
    "ipa": "zafaʃʼən"
  },
  {
    "word": "к1ышъо1от",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sponge",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼəʃʷaʔʷat"
  },
  {
    "word": "к1эзэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to shiver, to tremble",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʼazazən"
  },
  {
    "word": "цако",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "toothless",
        "examples": []
      },
      {
        "meaning": "insipid",
        "examples": []
      },
      {
        "meaning": "dull, blunt",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "t͡saːkʷa"
  },
  {
    "word": "цау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "expression of a hit/strike sound",
        "examples": []
      }
    ],
    "type": "intj",
    "ipa": "t͡saːw"
  },
  {
    "word": "лъабжъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hoof",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬaːbʐa"
  },
  {
    "word": "гъэпыутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to make something cheaper, to reduces the value of",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁapəwtən"
  },
  {
    "word": "шъырыт",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "humble",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʂərət"
  },
  {
    "word": "щыны",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pus",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɕənə"
  },
  {
    "word": "хьэк1ап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "hotel",
        "examples": []
      },
      {
        "meaning": "inn",
        "examples": []
      }
    ],
    "synonyms": [
      "хьак1эщ"
    ],
    "type": "noun",
    "ipa": "ħat͡ʃʼaːpʼa"
  },
  {
    "word": "шхап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "dining-room",
        "examples": []
      },
      {
        "meaning": "dining-hall",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʃxaːpʼa"
  },
  {
    "word": "щысып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "sitting room; living room",
        "examples": []
      }
    ],
    "synonyms": [
      "зэхэсып1э"
    ],
    "type": "noun",
    "ipa": "ɕəsəpʼa"
  },
  {
    "word": "зэхэсып1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "living room",
        "examples": []
      }
    ],
    "synonyms": [
      "щысып1э"
    ],
    "type": "noun",
    "ipa": "zaxasəpʼa"
  },
  {
    "word": "пэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "room",
        "examples": []
      }
    ],

    "cognate": "лэгъунэ",
    "type": "noun",
    "ipa": "paɕ"
  },
  {
    "word": "чылэпхъэеут машин",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "seeding-machine, sowing-machine",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃəɮapχajawət"
  },
  {
    "word": "мэкъу-мэщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "agricultural, farming",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maqʷ-maɕ"
  },
  {
    "word": "мэкъумэщыш1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "peasant",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maqʷmaɕəʃʼ"
  },
  {
    "word": "мэщы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "millet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "maɕ"
  },
  {
    "word": "тыгъоснахьыпэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "day before yesterday, two days ago",
        "examples": [
          {
            "sentence": "'''тыгъоснахьыпэ''' тк1уагъ йуропа",
            "translation": "'''2 days ago''', we went to Europe."
          },
          {
            "sentence": "'''тыгъоснахьыпэ''' пш1ы синыбжьэгъ",
            "translation": "'''2 days ago''' I was ten years old."
          },
          {
            "sentence": "'''тыгъоснахьыпэ''' томэр къэк1уагъ",
            "translation": "'''2 days ago''' Tom came."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "təʁʷasnaːħəp"
  },
  {
    "word": "еутын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spread (seeds, salt, etc.)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "jawtən"
  },
  {
    "word": "утысэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to spray dust",
        "examples": []
      },
      {
        "meaning": "grinding into dust, pulverizing, pulverization",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wətəsan"
  },
  {
    "word": "бзэджэрылъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bastard (unlawful child)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "bzad͡ʒarəɬf"
  },
  {
    "word": "ныбэшъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "potbellied; paunchy; big-bellied",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "nəbaʃʷ"
  },
  {
    "word": "хьэдэджадэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ghost, spectre, apparition, spook",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħadad͡ʒaːda"
  },
  {
    "word": "ныбжьыкъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shade",
        "examples": []
      }
    ],
    "synonyms": [
      "жьау"
    ],
    "type": "noun",
    "ipa": "nəbʑəqʷ"
  },
  {
    "word": "жьау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "shadow",
        "examples": []
      }
    ],
    "synonyms": [
      "ныбжьыкъу"
    ],
    "cognate": "жьауэ",
    "type": "noun",
    "ipa": "ʑaːwa"
  },
  {
    "word": "нэм къыпэш1ош1ыхьан",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to seem to the eye, to appear to the eye",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "nam qəpaʃʷʼaʃʼəħaːn"
  },
  {
    "word": "къыпэш1ош1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to seem to, to appear to",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qəpaʃʷʼaʃʼən"
  },
  {
    "word": "нэш1ош1",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "illusion",
        "examples": []
      },
      {
        "meaning": "a person that is pretending to be joyful",
        "examples": []
      }
    ],
    "synonyms": [
      "нэп1эхъ"
    ],
    "type": "noun",
    "ipa": "naʃʷʼaʃʼ"
  },
  {
    "word": "нэп1эхъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "illusion",
        "examples": []
      }
    ],
    "synonyms": [
      "нэш1ош1"
    ],
    "type": "noun",
    "ipa": "napʔaχ"
  },
  {
    "word": "жэ зэк1эк1ын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to yawn",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʒa zat͡ʃʼat͡ʃʼən"
  },
  {
    "word": "папыщ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pantofle, slipper, babouche, pump",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːpəɕ"
  },
  {
    "word": "пхъэпхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to crumble, to fall to pieces; to disintegrate",
        "examples": []
      },
      {
        "meaning": "to crunch",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "pχapχan"
  },
  {
    "word": "сымэджаплъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "patient visit",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "səmad͡ʒaːpɬa"
  },
  {
    "word": "ч1ып1эрыс",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "settler; local resident",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʂʼəpʼarəs"
  },
  {
    "word": "къыдэмыхъун",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to not work out; to not go accordingly to plan",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qədaməχʷən"
  },
  {
    "word": "шъхьашыгу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "the topmost part of the head (crown)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħaːʃəɡʷ"
  },
  {
    "word": "лъыхъуак1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "searcher",
        "examples": []
      },
      {
        "meaning": "seeker",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬəχʷaːkʷʼa"
  },
  {
    "word": "шъхьэпылъап1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "gallows, gibbet",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʂħapəɬaːpʼa"
  },
  {
    "word": "л1эгъуаб",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "widower",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ɬʼaʁʷaːb"
  },
  {
    "word": "ц1ыфышъхьэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "individual",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡sʼəfəʂħa"
  },
  {
    "word": "хьадагъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "burial; funeral",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːdaːʁa"
  },
  {
    "word": "къул",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "lash",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "qʷəɮ"
  },
  {
    "word": "1о",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "saying",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷa"
  },
  {
    "word": "хьабзы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "bitch (female dog)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːbzə"
  },
  {
    "word": "къэхьыпакъо",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whoreson",
        "examples": []
      }
    ],
    "synonyms": [
      "къэхьыпэрылъф"
    ],
    "type": "noun",
    "ipa": "qaħəpaːqʷa"
  },
  {
    "word": "къэхьыпэрылъф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "whoreson",
        "examples": []
      }
    ],
    "synonyms": [
      "къэхьыпакъо"
    ],
    "type": "noun",
    "ipa": "qaħəparəɬf"
  },
  {
    "word": "хьай ригъаф",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) shit; expression of failure.",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "intj",
    "ipa": "ħaːj rəjʁaːf"
  },
  {
    "word": "отэр",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "flock",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "watar"
  },
  {
    "word": "к1эпы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "cannabis, hemp",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃʼapə"
  },
  {
    "word": "гъэтхъын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to scutch, to swingle",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ʁatχən"
  },
  {
    "word": "шъхьи шъути и1эп",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar) meaningless",
        "examples": [],
        "tags": [
          "vulgar"
        ]
      }
    ],
    "type": "intj",
    "ipa": "ʂħəj ʃʷətəj jəʔap"
  },
  {
    "word": "рыгушхон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take pride",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "rəɡʷəʃxʷan"
  },
  {
    "word": "пацэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "fur, hair (animal hair or human body hair)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "paːt͡sa"
  },
  {
    "word": "ушыхьатын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to witness",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəʃəħaːtən"
  },
  {
    "word": "тхыдэжъ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "story",
        "examples": []
      },
      {
        "meaning": "tale",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "txədaʐ"
  },
  {
    "word": "шъуты къеон",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar slang) to butthurt; to overly annoyed or bothered by a perceived insult; needlessly offended",
        "examples": [],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətə qajwan"
  },
  {
    "word": "къехьэхын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to carry down",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajħaxən"
  },
  {
    "word": "къехэхьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"къехьэхын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "qajxaħən"
  },
  {
    "word": "регъэзын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"егъэзын\"",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "rajʁazen"
  },
  {
    "word": "1улэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "silly, stupid, foolish",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "ʔʷəɮa"
  },
  {
    "word": "1ул",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "pump (in horses)",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ʔʷəɮ"
  },
  {
    "word": "шъутым къик1ыжьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "(vulgar slang) to do a certain activity repetitively many times to the point that one gets bored or burned out from that activity",
        "examples": [],
        "tags": [
          "vulgar",
          "slang"
        ]
      }
    ],
    "type": "verb",
    "ipa": "ʃʷətəm qəjt͡ʃʼəʑən"
  },
  {
    "word": "нэнау",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baby",
        "examples": []
      }
    ],
    "synonyms": [
      "нану"
    ],
    "type": "noun",
    "ipa": "nanaːw"
  },
  {
    "word": "нану",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "baby",
        "examples": []
      }
    ],
    "synonyms": [
      "нэнау"
    ],
    "type": "noun",
    "ipa": "naːnəw"
  },
  {
    "word": "пчыхьэзэхахь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "party",
        "examples": []
      }
    ],
    "synonyms": [
      "чэщдэс"
    ],
    "type": "noun",
    "ipa": "pt͡ʃəħazaxaːħ"
  },
  {
    "word": "зы1уамылъхьажь",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "ugly",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "zəʔʷaːməɬħaːʑ"
  },
  {
    "word": "утэбжьэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to get numb; to tingle (body-part)",
        "examples": []
      }
    ],
    "synonyms": [
      "утэцэн"
    ],
    "type": "noun",
    "ipa": "wətabʑan"
  },
  {
    "word": "теплъэ ыубытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take shape",
        "examples": []
      },
      {
        "meaning": "to become good-looking",
        "examples": []
      }
    ],
    "synonyms": [
      "теплъэ къиубытын"
    ],
    "type": "noun",
    "ipa": "tajpɬa əwəbətən"
  },
  {
    "word": "теплъэ къиубытын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to take shape",
        "examples": []
      },
      {
        "meaning": "to become good-looking",
        "examples": []
      }
    ],
    "synonyms": [
      "теплъэ ыубытын"
    ],
    "type": "noun",
    "ipa": "tajpɬa qəjwəbətən"
  },
  {
    "word": "чабэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "Kaaba",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaːba"
  },
  {
    "word": "емлыч",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild (animal)",
        "examples": []
      }
    ],
    "synonyms": [
      "1эл"
    ],
    "type": "adj",
    "ipa": "emɮət͡ʃ"
  },
  {
    "word": "1эл",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "wild (animal)",
        "examples": []
      }
    ],
    "synonyms": [
      "емлыч"
    ],
    "type": "adj",
    "ipa": "ʔaɮ"
  },
  {
    "word": "псым фэдэу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "smoothly",
        "examples": []
      }
    ],
    "type": "adv",
    "ipa": "psəm fadaw"
  },
  {
    "word": "ныбэрый",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glutton",
        "examples": []
      },
      {
        "meaning": "gluttonous, voracious, one who eats excessively",
        "examples": []
      }
    ],
    "synonyms": [
      "бэшхы"
    ],
    "type": "noun",
    "ipa": "nəbarəj"
  },
  {
    "word": "бэшхы",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "glutton",
        "examples": []
      },
      {
        "meaning": "gluttonous, voracious, one who eats excessively",
        "examples": []
      }
    ],
    "synonyms": [
      "ныбэрый"
    ],
    "type": "noun",
    "ipa": "baʃxə"
  },
  {
    "word": "лъыхъожьын",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to mate (animals)",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "ɬəχʷaʑən"
  },
  {
    "word": "чэмыбгъэ",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "a cow that did not gave birth in the current year",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "t͡ʃaməbʁa"
  },
  {
    "word": "цунтхъэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to tear apart/asunder, to tear up",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "t͡ʃʷəntχan"
  },
  {
    "word": "лы1у-лы1оу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "piece by piece",
        "examples": [
          {
            "sentence": "Лыр '''лы1у-лы1оу''' ыупк1этагъ",
            "translation": "The meat '''is cut''' piece by piece."
          }
        ]
      }
    ],
    "type": "adv",
    "ipa": "ləʔʷ-ləʔʷaw"
  },
  {
    "word": "упк1этэн",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "to chop; to cut into pieces; to slice into pieces",
        "examples": []
      }
    ],
    "type": "verb",
    "ipa": "wəpt͡ʃʼatan"
  },
  {
    "word": "хьак1э-къуак1э",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "alternative form of \"хьак1экъуак1э\"",
        "examples": []
      }
    ],
    "type": "noun",
    "ipa": "ħaːt͡ʃʼa-qʷaːt͡ʃʼa"
  },
  {
    "word": "мынэрылъэгъу",
    "lang": "Adyghe",
    "definitions": [
      {
        "meaning": "invisible",
        "examples": []
      }
    ],
    "type": "adj",
    "ipa": "mənarəɬaʁʷ"
  }
]